
let project_fields = {
    title: {
        create: 'เพิ่มรถ',
        edit: 'แก้ไขรถ'
    },
    inputs: {
        divider_1: {
            input_type: 'divider',
            label: 'บริษัทลูกค้า',
            col_span: 23
        },
        client_id: {
            input_type: 'select',
            search_select: true,
            modelname: 'Client',
            label_key: 'fullname',
            placeholder: 'บริษัท',
            required: true,
            col_span: 23
        },
        divider_2: {
            input_type: 'divider',
            label: 'โปรเจกต์',
            col_span: 23
        },
        project_name: {
            input_type: 'text',
            placeholder: 'ชื่อโปรเจกต์',
            required: true,
            col_span: 11
        },
        project_type_id: {
            input_type: 'select',
            modelname: 'ProjectType',
            label_key: 'name',
            placeholder: 'ประเภทโปรเจกต์',
            // required: true,
            col_span: 11
        },
        product_type: {
            input_type: 'text',
            placeholder: 'ประเภทสินค้า',
            // required: true,
            col_span: 11
        },
        booking_type_id: {
            input_type: 'select',
            modelname: 'BookingType',
            label_key: 'name',
            placeholder: 'ประเภทงานขนส่ง',
            // required: true,
            col_span: 11
        },
    }
};

export default project_fields