<template>
    <div style="padding: 15px;height: 100vh">
        <div class="app-content">
            <a-card v-if="render_type == 'booking'" :title="this.business_a ? 'รายการรอวางบิล' : 'การวางบิล'">
                <a-row v-if="editable" style="margin: 1rem 0; justify-content: flex-end;">
                    <a-space>
                        <a-button @click="show_create_multiple_billing_modal(selectedRowKeys,1)" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            ตั้งเบิกจ่ายรถร่วม
                        </a-button>
                        <a-button  @click="show_create_multiple_billing_modal(selectedRowKeys,2)" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            สร้างใบวางบิลลูกค้า
                        </a-button>
                        <a-button v-if="this.businessTypeId != 2 && !this.business_a" :disabled="selectedRowKeys.length > 1 || selectedRowKeys.length == 0" @click="show_create_billing_modal(selectedRowKeys)" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            สร้างใบวางบิล
                        </a-button>
                        <a-button v-if="this.businessTypeId != 2 && !this.business_a" :disabled="selectedRowKeys.length == 0" @click="update_admin(selectedRowKeys)" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            Admin Confirm
                        </a-button>
                        <a-button v-if="this.businessTypeId != 2 && !this.business_a" :disabled="selectedRowKeys.length != 1" @click="gu_modal = true" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">download</span></template>
                            ออกใบค้ำประกัน
                        </a-button>
                        <!-- <a-button :disabled="selectedRowKeys.length != 1" @click="create_invoice(false, true)" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">download</span></template>
                            ออกใบแจ้งหนี้
                        </a-button>
                        <a-button :disabled="selectedRowKeys.length != 1" @click="create_delivery_note(false, true)"
                            style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">download</span></template>
                            ออกใบส่งมอบงาน
                        </a-button>
                        <a-button :disabled="selectedRowKeys.length != 1" @click="create_receipt(true)" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">download</span></template>
                            ออกใบเสร็จรับเงิน
                        </a-button> -->
                    </a-space>
                </a-row>

                <a-row v-if="business">
                    <a-col style="margin-right: 7px;">
                        <a-input-search v-model:value="search_booking_no" placeholder="ค้นหา Booking Number"
                            enter-button="ค้นหา" />
                    </a-col>
                    <!-- <a-col :span="5" style="margin-right: 7px;" >
                        <a-select style="width: 100%" 
                            v-model:value="search_client" 
                            placeholder="เลือกลูกค้า/บริษัท"
                            :options="company_list.map(({ id, fullname }) => ({ value: id, label: fullname }))"
                            :filter-option="filterOption"
                            show-search
                        >
                        </a-select>
                    </a-col> -->
                <a-col style="margin-right: 7px;">
                    <a-dropdown v-model:visible="company_filter_visible" :trigger="['click']">
                    <template #overlay>
                        <a-menu>
                        <a-menu-item key="1">
                            <span>1.บริษัทรถ</span>
                            <a-select
                            style="width: 280px"
                            v-model:value="search_car_company"
                            placeholder="บริษัทรถ"
                            >
                            <a-select-option
                                v-for="({ id, fullname }, i) in car_company_list"
                                :key="i"
                                :value="id"
                                >{{ fullname }}
                            </a-select-option>
                            </a-select>
                        </a-menu-item>
                        <a-menu-item key="2">
                            2.ลูกค้า/บริษัท
                            <a-select
                            style="width: 280px"
                            v-model:value="search_client"
                            placeholder="ลูกค้า/บริษัท"
                            >
                            <a-select-option
                                v-for="({ id, fullname }, i) in company_list"
                                :key="i"
                                :value="id"
                                >{{ fullname }}
                            </a-select-option>
                            </a-select>
                        </a-menu-item>
                        <a-menu-item key="3">
                            3.โปรเจกต์ลูกค้า
                            <a-select
                            style="width: 280px"
                            v-model:value="search_project_company"
                            placeholder="โปรเจกต์ลูกค้า"
                            >
                            <a-select-option
                                v-for="({  id, project_name }, i) in project_list"
                                :key="i"
                                :value="id"
                                >{{ project_name }}
                            </a-select-option>
                            </a-select>
                        </a-menu-item>
                        </a-menu>
                    </template>
                    <a-button style="width: 225px">
                        <div
                        v-if="!car_filter_title"
                        style="
                            display: flex;
                            justify-content: space-between;
                            gap: 13px;
                            width: 100%;
                        "
                        >
                        <span style="color: grey">ข้อมูลบริษัทรถ/ลูกค้า/โปรเจกต์</span>
                        <span
                            class="material-symbols-outlined"
                            style="font-size: 16px; color: grey"
                            >Home</span
                        >
                        </div>
                        <div
                        v-else
                        style="
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        "
                        >
                        {{ car_filter_title }}
                        </div>
                    </a-button>
                    </a-dropdown>
                </a-col>
                    <!-- <a-col :span="4">
                        <a-button @click="search_client = null" type="dashed">CLEAR</a-button>
                    </a-col> -->
                    <a-col>
                        <a-range-picker v-model:value="search_datetime" />
                    </a-col>
                    <a-col>
                        <a-button
                            @click="search_client = null, search_car_company = null, search_project_company = null, search_datetime = null, search_booking_no = null, selected_billing = null, search_biling_status = null, selectedRowKeys = []"
                            type="dashed">
                            CLEAR
                            </a-button>
                    </a-col>
                </a-row>
                <a-row  v-if="!business">
                    <a-col style="margin-right: 7px;">
                        <a-input-search v-model:value="search_booking_no" placeholder="ค้นหา Booking Number"
                            enter-button="ค้นหา" />
                    </a-col>
                    <!-- <a-col :span="8">
                        <a-select style="width: 100%" 
                            v-model:value="search_client" 
                            placeholder="เลือกลูกค้า/บริษัท"
                            :options="company_list.map(({ id, fullname }) => ({ value: id, label: fullname }))"
                            :filter-option="filterOption"
                            show-search
                        >
                        </a-select>
                    </a-col> -->
                    <a-col style="margin-right: 7px;">
                    <a-dropdown v-model:visible="company_filter_visible" :trigger="['click']">
                    <template #overlay>
                        <a-menu>
                        <a-menu-item key="1">
                            <span>1.บริษัทรถ</span>
                            <a-select
                            style="width: 280px"
                            v-model:value="search_car_company"
                            placeholder="บริษัทรถ"
                            >
                            <a-select-option
                                v-for="({ id, fullname }, i) in car_company_list"
                                :key="i"
                                :value="id"
                                >{{ fullname }}
                            </a-select-option>
                            </a-select>
                        </a-menu-item>
                        <a-menu-item key="2">
                            2.ลูกค้า/บริษัท
                            <a-select
                            style="width: 280px"
                            v-model:value="search_client"
                            placeholder="ลูกค้า/บริษัท"
                            >
                            <a-select-option
                                v-for="({ id, fullname }, i) in company_list"
                                :key="i"
                                :value="id"
                                >{{ fullname }}
                            </a-select-option>
                            </a-select>
                        </a-menu-item>
                        <a-menu-item key="3">
                            3.โปรเจกต์ลูกค้า
                            <a-select
                            style="width: 280px"
                            v-model:value="search_project_company"
                            placeholder="โปรเจกต์ลูกค้า"
                            >
                            <a-select-option
                                v-for="({  id, project_name }, i) in project_list"
                                :key="i"
                                :value="id"
                                >{{ project_name }}
                            </a-select-option>
                            </a-select>
                        </a-menu-item>
                        </a-menu>
                    </template>
                    <a-button style="width: 225px">
                        <div
                        v-if="!car_filter_title"
                        style="
                            display: flex;
                            justify-content: space-between;
                            gap: 13px;
                            width: 100%;
                        "
                        >
                        <span style="color: grey">ข้อมูลบริษัทรถ/ลูกค้า/โปรเจกต์</span>
                        <span
                            class="material-symbols-outlined"
                            style="font-size: 16px; color: grey"
                            >Home</span
                        >
                        </div>
                        <div
                        v-else
                        style="
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        "
                        >
                        {{ car_filter_title }}
                        </div>
                    </a-button>
                    </a-dropdown>
                </a-col>
                <a-col>
                    <a-range-picker v-model:value="search_datetime" />
                </a-col>
                <a-col :span="4">
                    <a-button @click="search_booking_no = null, search_client = null, search_car_company = null, search_project_company = null, search_datetime = null" type="dashed">CLEAR</a-button>
                </a-col>
                    <!-- <a-col>
                        <a-range-picker v-model:value="search_datetime" />
                    </a-col> -->
                    <!-- <a-col>
                        <a-button
                            @click="search_client = null, search_car_company = null, search_project_company = null, search_datetime = null, search_booking_no = null, selected_billing = null, search_biling_status = null, selectedRowKeys = []"
                            type="dashed">
                            CLEAR
                            </a-button>
                    </a-col> -->
                </a-row>
                <br>
                <a-row>
                    <a-table v-if="!this.business_a" rowKey="id" :columns="billing_columns" :data-source="filterBookingBillings(billable_bookings)" bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }"
                        :row-selection="{ type: 'checkbox', selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                        :pagination="{ total: pagination.total, current: pagination.onpage, pageSize: pagination.perPage }"
                        @change="handleTableChange"
                        >
                            <template #bodyCell="{ column, record }" >
                                <template v-if="(column.key == 'client')">
                                    {{ record.client?.fullname }}
                                </template>
                                <template v-else-if="(column.key == 'car_company')">
                                    {{ record.client.car_company?.fullname }}
                                </template>
                                <template v-else-if="column.key == 'booking_no'" >
                                    {{ record?.booking_no }}
                                </template> 
                                <template v-else-if="column.key == 'time_start' || column.key == 'time_end' ">
                                    <span class="tag">{{ render_date(record[column.key]) }} {{ render_time(record[column.key]) }}</span>
                                </template>
                                <template v-else-if="column.key === 'booking_car_types'">
                                    {{ sum_car_price(record, 'booking_car_types')?.toLocaleString() }}
                                </template>
                                <template v-else-if="column.key === 'extras'">
                                    {{ sum_car_price(record, 'extras')?.toLocaleString() }}
                                </template>
                                <template v-else-if="column.key === 'vat'">
                                    {{ Number((sum_car_price(record, 'vat')).toFixed(0))?.toLocaleString() }}
                                </template>
                                <template v-else-if="column.key === 'withholding'">
                                    {{ Number((sum_car_price(record, 'withholding')).toFixed(0))?.toLocaleString() }}
                                </template>
                                <template v-else-if="column.key === 'summary'">
                                    <!-- {{ (Number(record.total_price)+ (record.inc_vat? Number(( Number(record.total_price) - (record.extras.map(({ price, amount }) =>  Number(price) *  Number(amount)).reduce((a,b) =>  Number(a)+ Number(b),0))) * 0.07):0))?.toLocaleString() }} -->
                                    {{ Number((sum_car_price(record, 'total') ).toFixed(0))?.toLocaleString()  }}
                                </template>
                                <template v-else-if="column.key == 'total_price'">
                                    {{ Math.round(sum_car_price(record, 'total') - Number(record.discount) - (display_qt_setting(record, 'total_price') != null ? Number(display_qt_setting(record, 'total_price')) : 0)) == -0 ? 
                                        0
                                        :
                                        Math.round(sum_car_price(record, 'total') - Number(record.discount) - (display_qt_setting(record, 'total_price') != null ? Number(display_qt_setting(record, 'total_price')) : 0)).toLocaleString() }}
                                </template>
                                <template v-else-if="column.key == 'total_billing'">
                                    {{ Number((record.total_billing - (display_qt_setting(record, 'total_price') != null && display_qt_setting(record, 'pay_type') != null ? Number(display_qt_setting(record, 'total_price')) : 0)).toFixed(0))?.toLocaleString() }}
                                </template>
                                <template v-else-if="column.key === 'guarantee'">
                                    <a-tag v-if="record.guarantee" color="green">มี</a-tag>
                                    <a-tag v-else color="red">ไม่มี</a-tag>
                                </template>
                                <template v-else-if="column.key === 'discount'">
                                    {{ Math.floor(Number(record.discount) )?.toLocaleString() }}
                                </template>
                                <template v-else-if="column.key === 'id'">
                                    <div>
                                        <a @click="update_booking(record.id)"><span style="font-size: 14px"
                                                class="material-symbols-outlined">delete</span></a>
                                    </div>
                                </template>
                                <template v-else-if="column.key === 'admin_proved'">
                                    {{record.admin_proved? 'ยืนยันแล้ว':'ยังไม่ยืนยัน'}}
                                </template>
                                <template v-else-if="column.key === 'updated_at'">
                                    {{ render_date(record[column.key]) }}
                                </template>
                                <template v-else-if="column.key === 'paid_price'">
                                    {{ display_qt_setting(record, 'total_price') != null ? 
                                        (display_qt_setting(record, 'pay_type') != null ?
                                            (display_qt_setting(record, 'pay_type') == 3 ? 
                                                `${Number(display_qt_setting(record, 'price_percent')) * 100}% (${Math.round(display_qt_setting(record, 'total_price')).toLocaleString()})` 
                                                :
                                                display_qt_setting(record, 'total_price').toLocaleString()
                                            )
                                            : 0)
                                        : 0
                                     }}
                                     <!-- {{ display_qt_setting(record, 'pay_extend') != null ? display_qt_setting(record, 'pay_extend') : 0 }} -->
                                </template>
                                <template v-else>
                                    {{ record[column.key]?.toLocaleString()}}
                                </template>
                            </template>
                    </a-table>
                    <a-table v-if="this.business_a" rowKey="id" :columns="billing_columns" :data-source="filterBookingBillings(billable_bookings)" bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }"
                        :row-selection="{ type: 'checkbox', selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                        :pagination="{ total: pagination.total, current: pagination.onpage, pageSize: pagination.perPage }"
                        @change="handleTableChange"
                        >
                            <template #bodyCell="{ column, record }" >
                                <template v-if="(column.key == 'client')">
                                    {{ record.client?.fullname }}
                                </template>
                                <template v-else-if="(column.key == 'car_company')">
                                    {{ getCompanyName(record.car_company_id) }}
                                </template>
                                <template v-else-if="(column.key == 'customer_project')">
                                    {{ record.project && record.project.project_name ? record.project.project_name : '' }}
                                </template>
                                <template v-else-if="(column.key == 'selling_per_trip')">
                                    {{ Math.round(Number(record.selling_per_trip || 0)).toLocaleString() }}
                                </template>
                                <template v-else-if="(column.key == 'additional_selling')">
                                    {{ Math.round(Number(record.additional_selling || 0)).toLocaleString() }}
                                </template>
                                <template v-else-if="(column.key == 'return_cost')">
                                    {{ Math.round(Number(record.return_cost || 0)).toLocaleString() }}
                                </template>
                                <template v-else-if="(column.key == 'waiting_time_fee')">
                                    {{ Math.round(Number(record.waiting_time_fee || 0)).toLocaleString() }}
                                </template>
                                <template v-else-if="(column.key == 'overnight_charge')">
                                    {{ Math.round(Number(record.overnight_charge || 0)).toLocaleString() }}
                                </template>
                                <template v-else-if="(column.key == 'cancellation_fee')">
                                    {{ Math.round(Number(record.cancellation_fee || 0)).toLocaleString() }}
                                </template>
                                <template v-else-if="(column.key == 'car_expense')">
                                    <span v-if="record.company_3rd == 'บริษัทรถร่วม'">{{ Number(record.price_car).toLocaleString() }}</span>
                                    <span v-else>{{ carPayment(record) }}</span>
                                </template>
                                <template v-else-if="(column.key == 'gas_charge')">
                                    <span>{{ gasAmount(record).toLocaleString() }}</span>
                                </template>
                                <template v-else-if="(column.key == 'tollway')">
                                    <span>{{ tollwayAmount(record) }}</span>
                                </template>
                                <template v-else-if="(column.key == 'lift_things')">
                                    {{ Number(record.lift_things || 0).toLocaleString() }}
                                </template>
                                <template v-else-if="(column.key == 'driver_cost')">
                                    <span v-if="record.company_3rd == 'บริษัทรถร่วม'">0</span>
                                    <span v-else>{{ (record.drivers && record.drivers[0] && record.drivers[0].salary ? Math.round(record.drivers[0].salary / 26) : 0) }}</span>
                                </template>
                                <template v-else-if="(column.key == 'expenses')">
                                    <span>{{ Number(expensesAmount(record)).toLocaleString() }}</span>
                                </template>
                                <template v-else-if="(column.key == 'total_expense_car')">
                                    {{ Number(calculateTotalExpenseCar(record)).toLocaleString() }}
                                </template>
                                <template v-else-if="(column.key == 'total_billing')">
                                    {{ Number(calculateTotalBilling(record)).toLocaleString() }}
                                </template>
                                <template v-else-if="column.key == 'time_start'">
                                    <a-tag color="green">{{ render_datetime(record.time_start,record.time_product) }}</a-tag>
                                </template>
                                <template v-else-if="column.key == 'time_end'">
                                    <a-tag color="green">{{ render_datetime(record.time_end,record.time_product_finish) }}</a-tag>
                                </template>
                                <template v-else-if="column.key === 'booking_car_types'">
                                    {{ sum_car_price(record, 'booking_car_types')?.toLocaleString() }}
                                </template>
                                <template v-else-if="column.key === 'extras'">
                                    {{ sum_car_price(record, 'extras')?.toLocaleString() }}
                                </template>
                                <template v-else-if="column.key === 'vat'">
                                    {{ Number((sum_car_price(record, 'vat')).toFixed(0))?.toLocaleString() }}
                                </template>
                                <template v-else-if="column.key === 'withholding'">
                                    {{ Number((sum_car_price(record, 'withholding')).toFixed(0))?.toLocaleString() }}
                                </template>
                                <template v-else-if="column.key === 'summary'">
                                    <!-- {{ (Number(record.total_price)+ (record.inc_vat? Number(( Number(record.total_price) - (record.extras.map(({ price, amount }) =>  Number(price) *  Number(amount)).reduce((a,b) =>  Number(a)+ Number(b),0))) * 0.07):0))?.toLocaleString() }} -->
                                    {{ Number((sum_car_price(record, 'total') ).toFixed(0))?.toLocaleString()  }}
                                </template>
                                <template v-else-if="column.key == 'total_price'">
                                    {{ Math.round(sum_car_price(record, 'total') - Number(record.discount) - (display_qt_setting(record, 'total_price') != null ? Number(display_qt_setting(record, 'total_price')) : 0)) == -0 ? 
                                        0 : Math.round(sum_car_price(record, 'total') - Number(record.discount) - (display_qt_setting(record, 'total_price') != null ? Number(display_qt_setting(record, 'total_price')) : 0)).toLocaleString() }}
                                </template>
                                <template v-else-if="column.key == 'total_billing'">
                                    {{ Number((record.total_billing - (display_qt_setting(record, 'total_price') != null && display_qt_setting(record, 'pay_type') != null ? Number(display_qt_setting(record, 'total_price')) : 0)).toFixed(0))?.toLocaleString() }}
                                </template>
                                <template v-else-if="column.key == 'disbursement_car'">
                                    <span v-if="record.steppop1 == 1 && record.billings?.find((item)=>item.billing_action_id == 1)"> {{ Number(calculateTotalExpenseCar(record)).toLocaleString() }}</span>
                                    <span v-else>0</span>
                                </template>
                                <template v-else-if="column.key == 'customer_bill_amount'">
                                    <span v-if="record.steppop2 == 1 && record.billings?.find((item)=>item.billing_action_id == 2)">{{ Number(calculateTotalBilling(record)).toLocaleString() }}</span>
                                    <span v-else>0</span>
                                </template>
                                <template v-else-if="column.key == 'creator_invoice'">
                                    <a-tag v-if="record.steppop2 == 1 && record.billings?.find((item)=>item.billing_action_id == 2)" color="green">
                                        <div>{{ getSaleName(record.sale_id) }}</div>
                                        <div>{{ render_date(record.sale_stamp) }}</div>
                                    </a-tag>
                                </template>
                                <template v-else-if="column.key == 'creator_withdraw'">
                                    <a-tag v-if="record.steppop1 == 1 && record.billings?.find((item)=>item.billing_action_id == 1)" color="green">
                                        <div>{{ getSaleName(record.sale_id) }}</div>
                                        <div>{{ render_date(record.sale_stamp) }}</div>
                                    </a-tag>
                                </template>
                                <template v-else-if="column.key === 'guarantee'">
                                    <a-tag v-if="record.guarantee" color="green">มี</a-tag>
                                    <a-tag v-else color="red">ไม่มี</a-tag>
                                </template>
                                <template v-else-if="column.key === 'discount'">
                                    {{ Math.floor(Number(record.discount) )?.toLocaleString() }}
                                </template>
                                <template v-else-if="column.key === 'id'">
                                    <div>
                                        <a :class="{ disabled: record.steppop2 == 1 && record.billings?.find((item)=>item.billing_action_id == 2) 
                                                        || record.steppop1 == 1 && record.billings?.find((item)=>item.billing_action_id == 1) }" 
                                            @click="update_booking(record.id,record?.booking_cars[0])"><span style="font-size: 14px"
                                                class="material-symbols-outlined">delete</span></a>
                                    </div>
                                </template>
                                <template v-else-if="column.key === 'admin_proved'">
                                    {{record.admin_proved? 'ยืนยันแล้ว':'ยังไม่ยืนยัน'}}
                                </template>
                                <template v-else-if="column.key === 'updated_at'">
                                    {{ render_date(record[column.key]) }}
                                </template>
                                <template v-else-if="column.key === 'paid_price'">
                                    {{ display_qt_setting(record, 'total_price') != null ? 
                                        (display_qt_setting(record, 'pay_type') != null ?
                                            (display_qt_setting(record, 'pay_type') == 3 ? 
                                                `${Number(display_qt_setting(record, 'price_percent')) * 100}% (${Math.round(display_qt_setting(record, 'total_price')).toLocaleString()})` 
                                                :
                                                display_qt_setting(record, 'total_price').toLocaleString()
                                            )
                                            : 0)
                                        : 0
                                     }}
                                     <!-- {{ display_qt_setting(record, 'pay_extend') != null ? display_qt_setting(record, 'pay_extend') : 0 }} -->
                                </template>
                                <template v-else>
                                    {{ record[column.key]?.toLocaleString()}}
                                </template>
                            </template>
                    </a-table>
                </a-row>
            </a-card>

            <a-card v-else-if="render_type == 'billing'" :title="titleBilling">
                <a-space  v-if="!business && !isLogisticA" style="margin: 5px">
                    <a-col>
                        <a-input-search v-model:value="search_booking_no" placeholder="ค้นหา Billing NO. / Booking Number"
                            enter-button="ค้นหา" />
                    </a-col>
                    <a-col>
                        <a-select style="width: 28rem" v-model:value="search_client" placeholder="เลือกลูกค้า/บริษัท" :options="company_list.map(({ id, fullname }) => ({ value: id, label: fullname }))"
                        :filter-option="filterOption"
                        show-search>
                        </a-select>
                    </a-col>
                    <a-col>
                        <a-select style="width: 16rem" v-model:value="search_biling_status"
                            placeholder="เลือกสถานะของ billing">
                            <a-select-option v-for="{ name, id } in billing_statuses" :key="id" :value="id">{{ name }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col>
                        <a-select style="width: 12rem" v-model:value="search_biling_date_type"
                            placeholder="วันที่ออกบิล">
                            <a-select-option :key="1" value="releaseDate">วันที่ออกบิล</a-select-option>
                            <a-select-option :key="2" value="dueDate">วันที่ครบกำหนด</a-select-option>
                        </a-select>
                    </a-col>
                    <a-col>
                        <a-range-picker v-model:value="search_datetime" />
                    </a-col>
                    <a-col>
                        <a-button @click="search_client = null, search_datetime = null, search_booking_no = null,selected_billing = null, search_biling_status = null,search_biling_date_type = null" type="dashed">CLEAR</a-button>
                    </a-col>
                </a-space>
                <!-- <br> -->
                <a-row  v-if="business" style="margin: 1rem 0; justify-content: flex-end;" >
                    <a-space v-if="editable" style="width: 100%;justify-content: end">
                        <a-button @click="approve(selected_billing.id, 2)" :disabled="!(selected_billing && selected_billing.billing_status_id == 1)" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            ยืนยันบิล
                        </a-button>
                        <a-button @click="approve(selected_billing.id, 7)" :disabled="!(selected_billing && (selected_billing.billing_status_id == 2 || selected_billing.billing_status_id == 5 || selected_billing.billing_status_id == 6))" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            บังคับให้บิลเสร็จสิ้น
                        </a-button>
                        <a-button @click="create_invoice()" :disabled="!(selected_billing && (selected_billing.billing_status_id != 1 || selected_billing.billing_status_id == 6))" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            ออกใบแจ้งหนี้
                        </a-button>
                        <a-button :disabled="!(selected_billing && (selected_billing.billing_status_id != 1 || selected_billing.billing_status_id == 6))" @click="create_delivery_note()"
                            style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">download</span></template>
                            ออกใบส่งมอบงาน
                        </a-button>
                        <a-button :disabled="!(selected_billing && (selected_billing.billing_status_id != 1 || selected_billing.billing_status_id == 6))" @click="create_invoice_and_delivery_note()"
                            style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">download</span></template>
                            ออกใบส่งมอบงาน/ออกใบแจ้งหนี้
                        </a-button>
                        <a-button @click="create_receipt()" :disabled="!(selected_billing && (selected_billing.billing_status_id != 1 || selected_billing.billing_status_id == 6))" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            ออกใบเสร็จรับเงิน
                        </a-button>
                        <a-button  @click="approve(selected_billing.id, 5)" :disabled="!(selected_billing && (selected_billing.billing_status_id == 2 || selected_billing.billing_status_id == 6))" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            จัดส่งบิล
                        </a-button>
                    </a-space>
                </a-row>
                <a-space  v-if="business" >
                    <a-col>
                        <a-input-search v-model:value="search_booking_no" placeholder="ค้นหา Billing NO. / Booking Number"
                            enter-button="ค้นหา" />
                    </a-col>
                    <a-col>
                        <a-select style="width: 28rem" v-model:value="search_client" placeholder="เลือกลูกค้า/บริษัท" :options="company_list.map(({ id, fullname }) => ({ value: id, label: fullname }))"
                        :filter-option="filterOption"
                        show-search>
                        </a-select>
                    </a-col>
                    <a-col>
                        <a-select style="width: 16rem" v-model:value="search_biling_status"
                            placeholder="เลือกสถานะของ billing">
                            <a-select-option v-for="{ name, id } in billing_statuses" :key="id" :value="id">{{ name }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col>
                        <a-select style="width: 12rem" v-model:value="search_biling_date_type"
                            placeholder="วันที่ออกบิล">
                            <a-select-option :key="1" value="releaseDate">วันที่ออกบิล</a-select-option>
                            <a-select-option :key="2" value="dueDate">วันที่ครบกำหนด</a-select-option>
                        </a-select>
                    </a-col>
                    <a-col>
                        <a-range-picker v-model:value="search_datetime" />
                    </a-col>
                    <a-col>
                        <a-button @click="search_client = null, search_datetime = null, search_booking_no = null,selected_billing = null, search_biling_status = null,search_biling_date_type = null" type="dashed">CLEAR</a-button>
                    </a-col>
                </a-space>
                <br>
                <a-row  v-if="!business && !isLogisticA">
                    <a-space v-if="editable" style="width: 100%;justify-content: end">
                        <a-button @click="approve(selected_billing.id, 2)" :disabled="!(selected_billing && selected_billing.billing_status_id == 1)" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            ยืนยันบิล
                        </a-button>
                        <a-button @click="approve(selected_billing.id, 7)" :disabled="!(selected_billing && (selected_billing.billing_status_id == 2 || selected_billing.billing_status_id == 5 || selected_billing.billing_status_id == 6))" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            บังคับให้บิลเสร็จสิ้น
                        </a-button>
                        <a-button @click="create_invoice()" :disabled="!(selected_billing && (selected_billing.billing_status_id != 1 || selected_billing.billing_status_id == 6))" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            ออกใบแจ้งหนี้
                        </a-button>
                        <a-button :disabled="!(selected_billing && (selected_billing.billing_status_id != 1 || selected_billing.billing_status_id == 6))" @click="create_delivery_note()"
                            style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">download</span></template>
                            ออกใบส่งมอบงาน
                        </a-button>
                        <a-button :disabled="!(selected_billing && (selected_billing.billing_status_id != 1 || selected_billing.billing_status_id == 6))" @click="create_invoice_and_delivery_note()"
                            style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">download</span></template>
                            ออกใบส่งมอบงาน/ออกใบแจ้งหนี้
                        </a-button>
                        <a-button @click="create_receipt()" :disabled="!(selected_billing && (selected_billing.billing_status_id != 1 || selected_billing.billing_status_id == 6))" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            ออกใบเสร็จรับเงิน
                        </a-button>
                        <a-button  @click="approve(selected_billing.id, 5)" :disabled="!(selected_billing && (selected_billing.billing_status_id == 2 || selected_billing.billing_status_id == 6))" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            จัดส่งบิล
                        </a-button>
                    </a-space>
                </a-row>
                <br>
                <a-row  v-if="isLogisticA">
                    <a-space v-if="editable" style="width: 100%;justify-content: end">
                        <a-button @click="approve(selected_billing.id, 2)" :disabled="!(selected_billing && selected_billing.billing_status_id == 1)" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            ยืนยันบิล
                        </a-button>
                        <a-button @click="approve(selected_billing.id, 7)" :disabled="!(selected_billing && (selected_billing.billing_status_id == 2 || selected_billing.billing_status_id == 5 || selected_billing.billing_status_id == 6))" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            บังคับให้บิลเสร็จสิ้น
                        </a-button>
                        <a-button @click="openCoverDoc(selected_billing.id,'ใบปะหน้า')"  style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            สร้างใบปะหน้า
                        </a-button>
                        <a-button @click="exportCarExcel(selected_billing.id,2)"  style="display: flex" type="primary" size="large"
                            :disabled="selected_billing?.billing_action_id == 2">
                            <img
                                src="https://img.icons8.com/?size=100&id=3NeB_j4SNg2G&format=png&color=000000"
                                alt="vehicleRegis"
                                width="16"
                                height="16"
                                :style="{ margin: '0', filter: selected_billing?.billing_action_id != 2 ? 'invert(100%)' : 'invert(70%)' }"/>
                            ออกใบตั้งเบิกรถร่วม
                        </a-button>
                        <a-button @click="exportCarExcel(selected_billing.id,1)"  style="display: flex" type="primary" size="large"
                            :disabled="selected_billing?.billing_action_id == 1">
                            <img
                                src="https://img.icons8.com/?size=100&id=3NeB_j4SNg2G&format=png&color=000000"
                                alt="billAndDebt"
                                width="16"
                                height="16"
                                :style="{ margin: '0', filter: selected_billing?.billing_action_id != 1 ? 'invert(100%)' : 'invert(70%)' }"/>
                            ออกใบวางบิล/ใบแจ้งหนี้
                        </a-button>
                        <a-button v-if="businessTypeId === 4" @click="create_invoice()" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            ออกใบแจ้งหนี้
                        </a-button>
                        <a-button v-if="businessTypeId === 4" @click="create_receipt()" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">download</span></template>
                            ออกใบเสร็จรับเงิน
                        </a-button> 
                    </a-space>
                </a-row>
                <a-space  v-if="isLogisticA" style="margin: 5px">
                    <a-col>
                        <a-input-search v-model:value="search_booking_no" placeholder="ค้นหา Billing NO. / Booking Number"
                            enter-button="ค้นหา" />
                    </a-col>
                    <a-col>
                        <a-dropdown v-model:visible="billing_filter_visible" :trigger="['click']">
                        <template #overlay>
                            <a-menu>
                                <a-menu-item key="1">
                                    1.บริษัทรถ
                                    <a-select
                                    style="width: 250px"
                                    v-model:value="selected_company"
                                    placeholder="บริษัทรถ"
                                    >
                                        <a-select-option
                                            v-for="({ id, fullname }, i) in car_company_list"
                                            :key="i"
                                            :value="id"
                                            >{{ fullname }}
                                        </a-select-option>
                                    </a-select>
                                </a-menu-item>
                                <a-menu-item key="2">
                                    2.ลูกค้า/บริษัท
                                    <a-select
                                    style="width: 250px"
                                    v-model:value="search_client"
                                    placeholder="ลูกค้า/บริษัท"
                                    >
                                        <a-select-option
                                            v-for="({ id, fullname }, i) in company_list"
                                            :key="i"
                                            :value="id"
                                            >{{ fullname }}
                                        </a-select-option>
                                    </a-select>
                                </a-menu-item>
                                <a-menu-item key="3">
                                    3.โปรเจกต์ลูกค้า
                                    <a-select
                                    style="width: 250px"
                                    v-model:value="selected_project_id"
                                    placeholder="โปรเจกต์ลูกค้า"
                                    >
                                        <a-select-option
                                            v-for="({ id, project_name }, i) in project_list"
                                            :key="i"
                                            :value="id"
                                            >{{ project_name }}
                                        </a-select-option>
                                    </a-select>
                                </a-menu-item>
                                <a-menu-item key="1">
                                    4.ประเภทบิล
                                    <a-select
                                    style="width: 250px"
                                    v-model:value="selected_action"
                                    placeholder="เลือกประเภทบิล"
                                    >
                                        <a-select-option
                                            v-for="({ id, name }, i) in billing_action_list"
                                            :key="i"
                                            :value="id"
                                            >{{ name }}
                                        </a-select-option>
                                    </a-select>
                                </a-menu-item>
                                <a-menu-item key="5">
                                    <div style="margin-right: 5px;">
                                    5.สถานะของ billing
                                    </div>
                                    <a-select
                                    style="width: 250px"
                                    v-model:value="search_biling_status"
                                    placeholder="เลือกสถานะของ billing"
                                    >
                                        <a-select-option
                                            v-for="({ id, name }, i) in billing_statuses"
                                            :key="i"
                                            :value="id"
                                            >{{ name }}
                                        </a-select-option>
                                    </a-select>
                                </a-menu-item>
                            </a-menu>
                        </template>
                        <a-button style="width: 225px">
                            <div
                            v-if="!billing_filter_title"
                            style="
                                display: flex;
                                justify-content: space-between;
                                width: 100%;
                            "
                            >
                            <span style="color: grey">ข้อมูลบริษัทรถ/ลูกค้า/โปรเจกต์</span>
                            <span
                                class="material-symbols-outlined"
                                style="font-size: 16px; color: grey"
                                >home</span
                            >
                            </div>
                            <div
                            v-else
                            style="
                                display: flex;
                                justify-content: space-between;
                                width: 100%;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            "
                            >
                            {{ billing_filter_title }}
                            </div>
                        </a-button>
                        </a-dropdown>
                    </a-col>
                    <a-col>
                        <a-select style="width: 12rem" v-model:value="search_biling_date_type"
                            placeholder="วันที่ออกบิล">
                            <a-select-option :key="1" value="releaseDate">วันที่ออกบิล</a-select-option>
                            <a-select-option :key="2" value="dueDate">วันที่ครบกำหนด</a-select-option>
                        </a-select>
                    </a-col>
                    <a-col>
                        <a-range-picker v-model:value="search_datetime" />
                    </a-col>
                    <a-col>
                        <a-button @click="search_client = null, search_datetime = null, search_booking_no = null,selected_billing = null, search_biling_status = null,search_biling_date_type = null, selected_company = null, selected_project_id = null, search_biling_status= null, selected_action= null, car_filter_list= []" type="dashed">CLEAR</a-button>
                    </a-col>
                </a-space>
                <br>
                <br>
                <a-row>
                    <a-table rowKey="id" :columns="billing_column_render" :data-source="multi_billing_list(filterBillingsUp(billings))" bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }"
                        :row-selection="{ type: 'radio', onSelect: select_row }"
                        :pagination="{ total: pagination.total, current: pagination.onpage, pageSize: pagination.perPage }"
                        @change="handleTableChange"
                    >
                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key == 'issue_date' || column.key == 'expire_date'">
                                {{ render_date(record[column.key]) }}
                            </template>
                            <template v-else-if="column.key == 'time_start' || column.key == 'time_end'">
                                <span v-if="record.booking && record.booking.length > 0">
                                    <template v-for="(booking, index) in record.booking" :key="index">
                                        <span class="tag" v-if="booking && column.key && booking[column.key] != null">
                                            {{ render_date(booking[column.key]) }} {{ !isLogisticA ? render_time(booking[column.key]) 
                                                                                        : column.key == 'time_start'? render_time(booking.time_product)
                                                                                        : render_time(booking.time_product_finish) }}
                                        </span>
                                        <br v-if="index < record.booking.length - 1" />
                                    </template>
                                </span>
                            </template>
                            <template v-else-if="column.key == 'id'">
                                <a @click="edit_billing(record)">
                                    <span style="font-size: 18px" class="material-symbols-outlined">edit</span>
                                </a>
                                <a-divider type="vertical" />
                                <a @click="delete_billing(record.id)">
                                    <span style="font-size: 18px" class="material-symbols-outlined">delete</span>
                                </a>
                            </template>
                            <template v-else-if="((column.key == 'total_price') || (column.key == 'total_car_price')) && isLogisticA">
                                <span v-if="record.total_price && record.total_price.length > 0 && ((column.key == 'total_price' && record.billing_action_id == 2) || (column.key == 'total_car_price' && record.billing_action_id == 1))">
                                    <span class="tag">
                                        {{ Math.round(record.total_price.reduce((a, b) => Number(a) + Number(b), 0)).toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                                    </span>
                                </span>
                                <span v-else>
                                    <span class="tag">
                                        0
                                    </span>
                                </span>
                            </template>
                            <template v-else-if="column.key == 'total_price'">
                                <span v-if="record.total_price && record.total_price.length > 0">
                                    <span class="tag">
                                        {{ Math.round(record.total_price.reduce((a, b) => Number(a) + Number(b), 0)).toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                                    </span>
                                </span>
                            </template>
                            <template v-else-if="column.key == 'billing_status'">
                                {{ billingStatusText(record.billing_status_id, record.billing_status, record.expire_date) }}
                            </template>
                            <template v-else-if="column.key == 'billing_action_status'">
                                {{ billingActionText(record.billing_action_id, record) }}
                            </template>
                            <template v-else-if="column.key == 'billing_type'">
                                {{ record.billing_type }} {{ record.is_deposit ? '(มัดจำ)' : '' }}
                            </template>
                            <template v-else-if="(column.key == 'car_company')">
                                <span v-if="this.business_a && record.booking && record.booking.length > 0">
                                    <template v-for="(booking, index) in record.booking" :key="index">
                                        {{ booking?.car_company?.fullname }}
                                    <br v-if="index < record.booking.length - 1" />
                                    </template>
                                </span>
                                <span v-else-if="record.booking && record.booking.length > 0">
                                    <template v-for="(booking, index) in record.booking" :key="index">
                                        {{ booking?.client?.car_company?.fullname }}
                                        <br v-if="index < record.booking.length - 1" />
                                    </template>
                                </span>
                            </template>
                            <template v-else-if="column.key == 'client'">
                                <span v-if="record.booking && record.booking.length > 0">
                                    <template v-for="(booking, index) in record.booking" :key="index">
                                        {{ booking?.client?.fullname }}
                                        <br v-if="index < record.booking.length - 1" />
                                    </template>
                                </span>
                            </template>
                            <template v-else-if="column.key == 'booking_no'">
                                <span v-if="record.booking && record.booking.length > 0">
                                    <template v-for="(booking, index) in record.booking" :key="index">
                                        {{ booking?.booking_no }}
                                        <br v-if="index < record.booking.length - 1" />
                                    </template>
                                </span>
                            </template>
                            <template v-else-if="column.key == 'project_id'">
                                <span v-if="record.booking && record.booking.length > 0">
                                    <template v-for="(booking, index) in record.booking" :key="index">
                                        {{ getProjectName(booking?.project_id) }}
                                        <br v-if="index < record.booking.length - 1" />
                                    </template>
                                </span>
                            </template>
                            <template v-else-if="column.key == 'billing_payments'">
                                {{ record.billing_status_id == 7 ? Math.round(record.total_price.reduce((a, b) => Number(a) + Number(b), 0)).toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0 }}
                            </template>
                        </template>
                    </a-table>
                </a-row>
            </a-card>

            <a-card v-else-if="render_type == 'monthly-report'" :title="business_a ? 'รายการวางบิลและเบิกจ่าย' : 'รายงานประจำเดือน'">
                <a-row v-if="business" style="display: flex;margin: 1rem 0; justify-content: flex-end;">
                    <a-button @click="show_month_report = true" type="primary" size="large">
                        ออกรายงานประจำเดือน
                    </a-button>
                </a-row>
                <a-col v-if="!business" style="display: flex; justify-content: flex-end; margin-bottom: 1%">
                    <a-button @click="show_month_report = true" type="primary" size="large">
                        <img
                            src="https://img.icons8.com/?size=100&id=3NeB_j4SNg2G&format=png&color=000000"
                            alt="billAndDebt"
                            width="16"
                            height="16"
                            :style="{ margin: '0', filter: selected_billing?.billing_action_id != 1 ? 'invert(100%)' : 'invert(100%)' }"/>
                        {{ business_a ? 'ออกรายงานบัญชี' : 'ออกรายงานประจำเดือน' }}
                    </a-button>
                </a-col>
                <a-space style="margin-bottom: 22px">
                    <a-col>
                        <a-input-search v-model:value="search_booking_no" placeholder="ค้นหา Billing NO. / Booking Number"
                            enter-button="ค้นหา" />
                    </a-col>
                    <a-col>
                        <a-dropdown v-model:visible="billing_filter_visible" :trigger="['click']">
                        <template #overlay>
                            <a-menu>
                                <a-menu-item key="1">
                                    1.บริษัทรถ
                                    <a-select
                                    style="width: 250px"
                                    v-model:value="selected_company"
                                    placeholder="บริษัทรถ"
                                    >
                                        <a-select-option
                                            v-for="({ id, fullname }, i) in car_company_list"
                                            :key="i"
                                            :value="id"
                                            >{{ fullname }}
                                        </a-select-option>
                                    </a-select>
                                </a-menu-item>
                                <a-menu-item key="2">
                                    2.ลูกค้า/บริษัท
                                    <a-select
                                    style="width: 250px"
                                    v-model:value="search_client"
                                    placeholder="ลูกค้า/บริษัท"
                                    >
                                        <a-select-option
                                            v-for="({ id, fullname }, i) in company_list"
                                            :key="i"
                                            :value="id"
                                            >{{ fullname }}
                                        </a-select-option>
                                    </a-select>
                                </a-menu-item>
                                <a-menu-item key="3">
                                    3.โปรเจกต์ลูกค้า
                                    <a-select
                                    style="width: 250px"
                                    v-model:value="selected_project_id"
                                    placeholder="โปรเจกต์ลูกค้า"
                                    >
                                        <a-select-option
                                            v-for="({ id, project_name }, i) in project_list"
                                            :key="i"
                                            :value="id"
                                            >{{ project_name }}
                                        </a-select-option>
                                    </a-select>
                                </a-menu-item>
                                <a-menu-item key="1">
                                    4.ประเภทบิล
                                    <a-select
                                    style="width: 250px"
                                    v-model:value="selected_action"
                                    placeholder="เลือกประเภทบิล"
                                    >
                                        <a-select-option
                                            v-for="({ id, name }, i) in billing_action_list"
                                            :key="i"
                                            :value="id"
                                            >{{ name }}
                                        </a-select-option>
                                    </a-select>
                                </a-menu-item>
                                <a-menu-item key="5">
                                    <div style="margin-right: 5px;">
                                    5.สถานะของ billing
                                    </div>
                                    <a-select
                                    style="width: 250px"
                                    v-model:value="search_biling_status"
                                    placeholder="เลือกสถานะของ billing"
                                    >
                                        <a-select-option
                                            v-for="({ id, name }, i) in billing_statuses"
                                            :key="i"
                                            :value="id"
                                            >{{ name }}
                                        </a-select-option>
                                    </a-select>
                                </a-menu-item>
                            </a-menu>
                        </template>
                        <a-button style="width: 225px">
                            <div
                            v-if="!billing_filter_title"
                            style="
                                display: flex;
                                justify-content: space-between;
                                width: 100%;
                            "
                            >
                            <span style="color: grey">ข้อมูลบริษัทรถ/ลูกค้า/โปรเจกต์</span>
                            <span
                                class="material-symbols-outlined"
                                style="font-size: 16px; color: grey"
                                >home</span
                            >
                            </div>
                            <div
                            v-else
                            style="
                                display: flex;
                                justify-content: space-between;
                                width: 100%;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            "
                            >
                            {{ billing_filter_title }}
                            </div>
                        </a-button>
                        </a-dropdown>
                    </a-col>
                    <a-col>
                        <a-range-picker v-model:value="search_datetime" />
                    </a-col>
                    <a-col>
                        <a-button @click="search_client = null, search_datetime = null, search_biling_status = null, selected_company = null, search_client = null, selected_project_id = null, selected_action = null, search_biling_status = null" type="dashed">CLEAR</a-button>
                    </a-col>
                </a-space>
                <a-row>
                    <a-table rowKey="id" 
                            :columns="report_of_month" :data-source="filterBillingsUp(billings)"
                    bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                        <template #bodyCell="{ column, record }" >
                            <template v-if="column.key == 'client'">
                                {{ record.booking?.client?.fullname }}
                            </template>
                            <template v-if="column.key == 'expire_date'">
                                {{ render_date(record[column.key])}}
                            </template>
                            <template v-else-if="column.key == 'car_company'">
                                {{ record.booking?.car_company?.fullname }}
                            </template>
                            <template v-else-if="column.key == 'client_company'">
                                {{ record.booking?.client?.fullname }}
                            </template>
                            <template v-else-if="column.key == 'billing_action_id'">
                                {{ billingActionText(record.billing_action_id) }}
                            </template>
                            <template v-else-if="column.key == 'total_billing'">
                                {{ isLogisticA && record.billing_action_id == 2 ? Number(calculateTotalBilling(record.booking))?.toLocaleString() : isLogisticA? 0 : calculateTotalBilling(record.booking) }}
                            </template>
                            <template v-else-if="column.key == 'total_expense_car'">
                                {{ isLogisticA && record.billing_action_id == 1 ? Number(calculateTotalExpenseCar(record.booking))?.toLocaleString() : isLogisticA? 0 : calculateTotalExpenseCar(record.booking) }}
                            </template>
                            <template v-else-if="column.key == 'project'">
                                {{ getProjectName(record.booking?.project_id) }}
                            </template>
                            <template v-else-if="column.key == 'billing_payments'">
                                {{ record.billing_status_id == 7 ? isLogisticA && record.billing_action_id == 2
                                    ? Number(calculateTotalBilling(record.booking))?.toLocaleString() 
                                    : isLogisticA && record.billing_action_id == 1
                                    ? Number(calculateTotalExpenseCar(record.booking))?.toLocaleString() 
                                    : Math.round(Number(record.total_price))?.toLocaleString():0 }}
                            </template>
                            <template v-else-if="column.key == 'unpaid'">
                                {{ record.billing_status_id == 7 ? 0 
                                : isLogisticA && record.billing_action_id == 2
                                ? Number(calculateTotalBilling(record.booking))?.toLocaleString() 
                                : isLogisticA && record.billing_action_id == 1
                                ? Number(calculateTotalExpenseCar(record.booking))?.toLocaleString() 
                                :Math.round(Number(record.total_price))?.toLocaleString() }}
                            </template>
                            <template v-else-if="column.key == 'total_price'">
                                {{ Math.round(Number(record.total_price))?.toLocaleString() }}
                            </template>
                            <template v-else-if="column.key == 'bank_account'">
                                <span v-if="record.booking?.company_3rd == 'บริษัทรถร่วม'">
                                    -
                                </span>
                                <span v-else="record.booking?.company_3rd != 'บริษัทรถร่วม'">
                                    {{ getBankName(record.booking?.booking_cars[0]?.car?.car_company.bank_id) }} - {{ record.booking?.booking_cars[0]?.car?.car_company?.bank_account_no }} - {{ record.booking?.booking_cars[0]?.car?.car_company?.bank_account_name }}
                                </span>
                            </template>
                            <template v-else-if="column.key == 'paid_account'">
                                <span v-if="record.booking?.company_3rd == 'บริษัทรถร่วม'">
                                    {{ getBankName(record.booking?.booking_cars[0]?.car?.car_company.bank_id) }} - {{ record.booking?.booking_cars[0]?.car?.car_company?.bank_account_no }} - {{ record.booking?.booking_cars[0]?.car?.car_company?.bank_account_name }}
                                </span>
                                <span v-else="record.booking?.company_3rd != 'บริษัทรถร่วม'">
                                    -
                                </span>
                            </template>
                            <template v-else-if="column.key == 'booking_total_price'">
                                {{ Math.round(sum_car_price(record.booking, 'total') - Number(record.booking.discount) - (display_qt_setting(record.booking, 'total_price') != null ? Number(display_qt_setting(record.booking, 'total_price')) : 0)) == -0 ? 
                                        0
                                        :
                                        Math.round(sum_car_price(record.booking, 'total') - Number(record.booking.discount) - (display_qt_setting(record.booking, 'total_price') != null ? Number(display_qt_setting(record.booking, 'total_price')) : 0)).toLocaleString() }}
                            </template>
                            <template v-else-if="column.key == 'booking_unpaid'">
                                {{ (get_total_value(record.booking?.booking_car_types) - sum_payment(record.billing_payments))?.toLocaleString() }}
                            </template>
                            <template v-else-if="column.key == 'booking_no'" >
                                {{ record.booking?.booking_no }}
                            </template>
                            <template v-else-if="column.key == 'billing_status'" >
                                {{ billingStatusText(record.billing_status_id,record.billing_status,record.expire_date) }}
                            </template>
                            <template v-else>
                                {{ record[column.key]?.toLocaleString() }}
                            </template> 
                        </template>
                    </a-table>
                </a-row>
            </a-card>

            <a-card v-else-if="render_type == 'guarantee'" title="หลักค้ำประกันสัญญางาน">
                <a-space style="margin: 5px">
                    <a-col>
                        <a-input-search v-model:value="search_booking_no" placeholder="ค้นหา Billing NO. / Booking Number"
                            enter-button="ค้นหา" />
                    </a-col>
                    
                    <a-col>
                        <a-button @click="search_client = null, search_datetime = null, search_booking_no = null,selected_billing = null, search_biling_status = null" type="dashed">CLEAR</a-button>
                    </a-col>
                </a-space>
                <br>
                <a-row>
                    <a-space v-if="editable" style="width: 100%;justify-content: end">
                        <a-button @click="create_invoice(true)" :disabled="!selected_billing" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            ออกใบวางบิล
                        </a-button>
                        <a-button @click="create_receipt(false, true)" :disabled="!selected_billing" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            ออกใบเสร็จรับเงิน
                        </a-button>
                    </a-space>
                </a-row>
                <br>
                <a-row>
                    <a-table rowKey="id" :columns="[
                        {
                            title: 'Guarantee',
                            dataIndex: 'guarantee_no',
                            key: 'guarantee_no',
                            width: 125,
                            sorter: {
                                compare: (a, b) => a.billing_no.localeCompare(b.billing_no),
                                multiple: 3
                            },
                            align: 'center',
                        }, {
                            title: 'QTC',
                            dataIndex: 'booking_no',
                            key: 'booking_no',
                            width: 125,
                            sorter: {
                                compare: (a, b) => a.booking.booking_no.localeCompare(b.booking.booking_no),
                                multiple: 2
                            },
                            align: 'center',
                        }, {
                            title: 'วันที่สร้าง',
                            dataIndex: 'created_at',
                            key: 'created_at',
                            width: 125,
                            align: 'center',
                            sorter: {
                                compare: (a, b) => new Date(a.created_at) - new Date(b.created_at),
                                multiple: 1
                            },
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 60,
                            align: 'center',
                            fixed: 'right'
                        }
                    ]" :data-source="filtered_list(guarantee)" bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }"
                    :row-selection="{ type: 'radio', onSelect: select_row }">
                        <template #bodyCell="{ column, record }" >
                            <template v-if="column.key == 'id'" >
                                <a @click="delete_guarantee(record.id)">
                                    <span style="font-size: 18px" class="material-symbols-outlined">delete</span>
                                </a>
                            </template>
                            <template v-else-if="column.key == 'booking_no'" >
                                {{ record.booking?.booking_no }}
                            </template> 
                            <template v-else-if="column.key == 'created_at'" >
                                {{ render_date(record.created_at) }}
                            </template> 
                        </template>
                    </a-table>
                </a-row>
            </a-card>
        </div>

        <a-modal v-model:visible="gu_modal" title="สร้างใบค้ำประกันสัญญา" :footer="null">
            <a-form :model="create_gu_form" name="create_gu_form" layout="vertical">
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="ยอดเงินค้ำประกัน" name="amount" >
                            <a-input-number  v-model:value="create_gu_form.amount"  />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="ผู้สร้าง Billing" name="sale_id" >
                            <a-select v-model:value="create_gu_form.sale_id">
                                <a-select-option v-for="{ id, firstname, lastname } in sale_list" :value="id">{{ firstname + ' ' + lastname }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row justify="end">
                    <a-space>
                        <a-button key="back" @click="gu_modal = false">ปิด</a-button>
                        <a-button type="primary" @click="submit_create_gu()" :loading="loading">ตกลง</a-button>
                    </a-space>
                </a-row>
            </a-form>
        </a-modal>

        <a-modal v-model:visible="show_month_report" :title="this.business_a ? 'ออกรายงานบัญชี' : 'ออกรายงานประจำเดือน'" :footer="null">
          <a-form :model="month_report" layout="vertical" ref="formBillingMonth" @finish="submit_month_report()">
            <a-row justify="center">
              <a-col :span="20">
                <a-form-item label="ลูกค้า/บริษัท" :rules="[{ required: true, message: 'โปรดระบุลูกค้า/บริษัท' }]" name="month_client">
                    <a-select
                        v-model:value="month_report.month_client"
                        placeholder="เลือกลูกค้า/บริษัท"
                        style="width: 300px"
                        show-search
                    >
                        <a-select-option v-for="{fullname} in company_list" :value="fullname">{{fullname}}</a-select-option>
                    </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row justify="center">
              <a-col :span="20">
                <a-form-item :rules="typeBillingRules" name="month_status_bill">
                    <template #label>
                        <div style="display: flex; align-items: center;">
                            <span style="margin-right: 8px;">สถานะบิล</span>
                            <label class="inline-flex items-center" style="margin-bottom: 1px;">
                                <input
                                type="checkbox"
                                v-model="selectedTypeBill"
                                value="All"
                                @change="handleCheckboxChange"
                                class="form-checkbox text-blue-600"
                                style="width: 12px;height: 12px;"
                                />
                                <span style="margin-left: 5px; font-size: 13px;">ทั้งหมด</span>
                            </label>
                        </div>
                    </template>
                    <a-select
                        v-model:value="month_report.month_status_bill"
                        placeholder="เลือกสถานะของ Billing"
                        style="width: 300px"
                        show-search
                        :disabled="selectedTypeBill == true"
                    >
                        <a-select-option v-for="{id,name} in billing_statuses" :value="id">{{name}}</a-select-option>
                        <a-select-option :value="0" v-if="false">ทั้งหมด</a-select-option>
                    </a-select>                
                </a-form-item>
              </a-col>
            </a-row>
            <a-row justify="center">
              <a-col :span="20">
                <a-form-item label="วันที่เริ่ม" :rules="[{ required: true, message: 'โปรดระบุวันที่เริ่ม' }]" name="month_time_start">
                    <a-date-picker style="width: 300px;" v-model:value="month_report.month_time_start" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row justify="center">
              <a-col :span="20">
                <a-form-item label="วันที่สิ้นสุด" :rules="[{ required: true, message: 'โปรดระบุวันที่สิ้นสุด' }]" name="month_time_end">
                    <a-date-picker style="width: 300px;" v-model:value="month_report.month_time_end" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row justify="end">
                <a-button type="primary" html-type="submit">บันทึก</a-button>
            </a-row>
          </a-form>
        </a-modal>
        
        <a-modal v-model:visible="create_multiple_billing_modal" :title="this.steppop !== 1 ? 'สร้างใบวางบิลลูกค้า':'ตั้งเบิกจ่ายรถร่วม'" :footer="null">
            <a-form :model="create_multiple_billing_form" name="create_multiple_billing_form" autocomplete="off" layout="vertical"
                @finish="create_multiple_billings()"
            >
                <a-row v-if="steppop == 2">
                    <a-col :span="24">
                        <a-form-item label="ลูกค้า/บริษัท" name="company_id" :rules="[{ required: true, message: `โปรดเลือกลูกค้า/บริษัท` }]">
                            <a-select v-model:value="create_multiple_billing_form.company_id" @change="cal_multiple_bill" :disabled="disablefield">
                                <a-select-option v-for="{ id, fullname } in company_list" :value="id">{{ fullname }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row v-else-if="steppop == 1">
                    <a-col :span="24">
                        <a-form-item label="บริษัทรถ" name="car_company_id" :rules="[{ required: true, message: `โปรดเลือกบริษัทรถ` }]">
                            <a-select v-model:value="create_multiple_billing_form.car_company_id" @change="cal_multiple_bill" :disabled="disablefield">
                                <a-select-option v-for="{ id, fullname } in car_company_list" :value="id">{{ fullname }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item name="project" :rules="typeProjectRules">
                        <template #label>
                            <div style="display: flex; align-items: center;">
                            <span>โปรเจกต์</span>
                            <a-checkbox 
                                v-model:checked="isChecked" 
                                style="margin-left: 8px;"
                                @click="cal_multiple_bill"
                                @change="handleCheckboxProjectChange"
                                >ทั้งหมด</a-checkbox>
                            </div>
                        </template>
                        <a-select
                            style="width: 100%"
                            v-model:value="create_multiple_billing_form.project"
                            placeholder="โปรเจกต์ลูกค้า"
                            @change="cal_multiple_bill"
                            :disabled="isChecked || disablefield"
                            >
                                <a-select-option
                                    v-for="({ id, project_name }, i) in filterProjectByCarCom(project_list)"
                                    :key="i"
                                    :value="id"
                                    >{{ project_name }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="วันที่เริ่ม" name="from" :rules="[{ required: true, message: `โปรดเลือกวันที่เริ่ม` }]" style="width: 100%;">
                            <a-date-picker v-model:value="create_multiple_billing_form.from" placeholder="เลือกวันที่วันเวลารับ"
                                format="DD/MM/YYYY"
                                @change="cal_multiple_bill"
                                style="width: 100%;"
                                :disabled="disablefield"
                            ></a-date-picker>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="วันที่สิ้นสุด" name="to" :rules="[{ required: true, message: `โปรดเลือกวันที่สิ้นสุด` }]" style="width: 100%;">
                            <a-date-picker v-model:value="create_multiple_billing_form.to" placeholder="เลือกวันที่วันเวลาส่ง"
                                format="DD/MM/YYYY"
                                @change="cal_multiple_bill"
                                style="width: 100%;"
                                :disabled="disablefield"
                            ></a-date-picker>
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-divider orientation="left"> วิธีการแบ่งจ่ายเงิน</a-divider>
                <a-row justify="space-between">
                    <a-col :span="10">
                        <a-form-item :label="this.steppop !== 1 ? 'ยอดค้างชำระ' : 'ยอดตั้งเบิกจ่าย'" name="remaining_amount" disabled>
                            <a-input-number v-model:value="create_multiple_billing_form.remaining_amount" 
                                :formatter="formatBillingAmount"
                                :parser="parseBillingAmount"
                                disabled />
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item :label="this.steppop !== 1 ? 'ประเภทการจ่ายเงิน' : 'ประเภทการจ่าย'" name="bill_type" >
                            <a-select v-model:value="create_multiple_billing_form.bill_type" disabled>
                                <a-select-option :value="1">ราคาเต็ม</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row justify="space-between">
                    <a-col :span="10">
                        <a-form-item label="วันที่สร้างบิล" name="billing_date" :rules="[{ required: true, message: `โปรดเลือกวันที่ออกบิล` }]" >
                            <a-date-picker v-model:value="create_multiple_billing_form.billing_date" placeholder="เลือกวันที่ออกบิล"
                                format="DD/MM/YYYY"
                            ></a-date-picker>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item :label="this.steppop !== 1 ? 'จำนวนวันเครดิต' : 'จำนวนวันครบกำหนดจ่าย'" disabled name="credit_duration">
                            <a-input-number v-model:value="create_multiple_billing_form.credit_duration" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row justify="space-between" v-if="create_multiple_billing_form.bill_type >= 1">
                    <a-col v-if="create_multiple_billing_form.bill_type == 1" :span="24">
                        <a-form-item :label="this.steppop !== 1 ? 'จำนวนเงินราคาเต็ม':'จำนวนราคาเต็ม'" name="billing_amount" >
                            <a-input-number v-model:value="create_multiple_billing_form.billing_amount" 
                                :formatter="formatBillingAmount"
                                :parser="parseBillingAmount"
                                disabled />                   
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item :label="this.steppop !== 1 ?'ผู้สร้าง Billing':'ผู้สร้าง ใบตั้งเบิกจ่ายรถร่วม'" name="sale_id" :rules="[{ required: true, message: `โปรดเลือกผู้สร้าง Billing` }]">
                            <a-select v-model:value="create_multiple_billing_form.sale_id">
                                <a-select-option v-for="{ id, firstname, lastname } in sale_list" :value="id">{{ firstname + ' ' + lastname }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row justify="end">
                    <a-space>
                        <a-button key="back" @click="create_multiple_billing_modal = false">ปิด</a-button>
                        <a-button html-type="submit" type="primary" :loading="loading" 
                        >ตกลง</a-button>
                    </a-space>
                </a-row>
            </a-form>
        </a-modal>

        <a-modal v-model:visible="create_billing_modal" title="สร้างใบวางบิล" :footer="null">
            <a-form :model="create_billing_form" name="create_billing_form" autocomplete="off" layout="vertical"
                @finish="create_billings(1, create_billing_form.billing_amount)"
            >
                <a-divider orientation="left"> วิธีการแบ่งจ่ายเงิน</a-divider>
                <a-row justify="space-between">
                    <a-col :span="10">
                        <a-form-item label="ยอดค้างชำระ" name="remaining_amount" disabled>
                            <a-input-number v-model:value="select_remaining_amount" 
                                :formatter="formatBillingAmount"
                                :parser="parseBillingAmount"
                                disabled />
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="ประเภทการจ่ายเงิน" name="bill_type" :rules="[{ required: true, message: `โปรดเลือกวิธีการชำระเงิน` }]" >
                            <a-select v-model:value="create_billing_form.bill_type" @change="use_remaining_amount">
                                <a-select-option :value="1">ราคาเต็ม</a-select-option>
                                <a-select-option :value="2">จำนวน</a-select-option>
                                <a-select-option :value="3">เปอร์เซ็น</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row justify="space-between">
                    <a-col :span="10">
                        <a-form-item label="วันที่สร้างบิล" name="billing_date" :rules="[{ required: true, message: `โปรดเลือกวันที่ออกบิล` }]" >
                            <a-date-picker v-model:value="create_billing_form.billing_date" placeholder="เลือกวันที่ออกบิล"
                                format="DD/MM/YYYY"
                            ></a-date-picker>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="จำนวนวันเครดิต" disabled name="credit_duration">
                            <a-input-number v-model:value="create_billing_form.credit_duration" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row justify="space-between" v-if="create_billing_form.bill_type >= 1">
                    <a-col v-if="create_billing_form.bill_type == 1" :span="24">
                        <a-form-item label="จำนวนเงินราคาเต็ม" name="billing_amount" >
                            <template v-if="create_billing_form.bill_type == 1">
                                <a-input-number v-model:value="select_remaining_amount" 
                                :formatter="formatBillingAmount"
                                :parser="parseBillingAmount"
                                disabled />
                            </template>
                            <template v-else>
                                <a-input-number v-model:value="total_booking_car_types" disabled />
                            </template>                        
                        </a-form-item>
                    </a-col>
                    <a-col v-if="create_billing_form.bill_type == 2" :span="24">
                        <a-form-item label="จำนวนเงินที่ต้องการวางบิล" name="billing_amount" 
                        :rules="[{ validator: validateBillingAmount, trigger: 'change' }]">
                            <a-input-number v-model:value="create_billing_form.billing_amount" 
                            @change="calculate_bill_amount" 
                            />
                        </a-form-item>
                    </a-col>
                    <a-col v-if="create_billing_form.bill_type == 3" :span="10">
                        <a-form-item label="จำนวนเงินที่คำนวณได้" name="billing_amount" >
                            <a-input-number v-model:value="create_billing_form.billing_amount" 
                            :formatter="formatBillingAmount"
                            :parser="parseBillingAmount"
                            disabled />
                        </a-form-item>
                    </a-col>
                    <a-col v-if="create_billing_form.bill_type == 3" :span="12">
                        <a-form-item label="เปอร์เซ็นจ่ายเงิน" >
                            <a-select @change="recalculate_bill_amount" >
                                <a-select-option :value="0.1">10%</a-select-option>
                                <a-select-option :value="0.2">20%</a-select-option>
                                <a-select-option :value="0.3">30%</a-select-option>
                                <a-select-option :value="0.4">40%</a-select-option>
                                <a-select-option :value="0.5">50%</a-select-option>
                                <a-select-option :value="0.6">60%</a-select-option>
                                <a-select-option :value="0.7">70%</a-select-option>
                                <a-select-option :value="0.8">80%</a-select-option>
                                <a-select-option :value="0.9">90%</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="ผู้สร้าง Billing" name="sale_id" :rules="[{ required: true, message: `โปรดเลือกผู้สร้าง Billing` }]">
                            <a-select v-model:value="create_billing_form.sale_id">
                                <a-select-option v-for="{ id, firstname, lastname } in sale_list" :value="id">{{ firstname + ' ' + lastname }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row justify="end">
                    <a-space>
                        <a-button key="back" @click="create_billing_modal = false">ปิด</a-button>
                        <a-button html-type="submit" type="primary" :loading="loading" 
                        :disabled="(create_billing_form.billing_amount > select_total_remaining && create_billing_form.billing_amount < select_remaining_amount) || select_remaining_amount <= 0 
                         " >ตกลง</a-button>
                    </a-space>
                </a-row>
            </a-form>
        </a-modal>

        <a-modal v-model:visible="show_select_sale" :title="sale_title_modal[sale_bill_type]">
            <a-form layout="vertical">
                    <a-col>
                        <a-form-item :label="sale_title_modal[sale_bill_type]">
                        <a-checkbox v-model="auto_date" @change="autoDateChanged">แสดงวันที่อัตโนมัติ</a-checkbox>
                        <a-checkbox v-model="no_sale" @change="onNoSaleChanged">ไม่แสดงลายเซ็น</a-checkbox>
                        <a-checkbox v-model="no_stamp" @change="onNoStampChanged">ไม่แสดงตราประทับ</a-checkbox>
                        <br>
                        <a-checkbox v-model="create_paper_original" @change="createPaperOriginal" :disabled="create_paper_copy || create_paper_original_and_copy" >ต้นฉบับ</a-checkbox>
                        <a-checkbox v-model="create_paper_copy" @change="createPaperCopy" style="margin-left: 14.5%" :disabled="create_paper_original || create_paper_original_and_copy" >สำเนา</a-checkbox>
                        <a-checkbox v-model="create_paper_original_and_copy" @change="createPaperOriginalAndCopy" :disabled="create_paper_original || create_paper_copy " >ทั้งต้นฉบับ และ สำเนา</a-checkbox>
                        <a-select v-model:value="selected_sale_id" @change="onSaleChanged" :disabled="no_sale"
                            style="margin-top: 1rem;">
                            <a-select-option  v-for="{ id, firstname, lastname } in sale_list" :value="id" :key="id" >
                                {{ `${firstname} ${lastname}` }}
                            </a-select-option>
                        </a-select>
                            <a-image v-if="selected_sale?.signature" :src="selected_sale.signature" style="margin-top: 1rem; width: 400px;" />
                        </a-form-item>
                     </a-col>
            </a-form>
            <template #footer>
                <a-button key="back" @click="show_select_sale = false">ปิด</a-button>
                <a-button ref="saleSubmit" name="saleSubmit"  key="submit" type="primary">ตกลง</a-button>
            </template>
        </a-modal>

        <Invoice
            v-if="get_invoice"
            :selected_billing="selected_billing"
            :billed_amount="billed_amount(selected_billing)"
            :user_name="user_name"
            :no_stamp="no_stamp" 
            :auto_date="auto_date" 
            :create_paper_original="create_paper_original" 
            :create_paper_copy="create_paper_copy"
            :create_paper_original_and_copy="create_paper_original_and_copy"
            @close="get_invoice = false, selected_billing = JSON.parse(JSON.stringify(export_state)), this.get_booking()"
            :car_companies="car_companies[0]"
            :billing_type_id="create_billing_form.bill_type"
            :arrayBillingInQuotation="arrayBillingInQuotation"
        />

        <Receipt
            v-if="get_receipt"
            :selected_billing="selected_billing"
            :billed_amount="billed_amount(selected_billing)"
            :user_name="user_name"
            :no_stamp="no_stamp" 
            :auto_date="auto_date" 
            :create_paper_original="create_paper_original" 
            :create_paper_copy="create_paper_copy"
            :create_paper_original_and_copy="create_paper_original_and_copy"
            @close="get_receipt = false, selected_billing = JSON.parse(JSON.stringify(export_state)), this.get_booking()"
            :car_companies="car_companies[0]"
            :arrayBillingInQuotation="arrayBillingInQuotation"
        />

        <FakeReceipt
            v-if="get_fake_receipt"
            :selected_billing="selected_billing"
            :billed_amount="billed_amount(selected_billing)"
            :user_name="user_name"
            :no_stamp="no_stamp" 
            :auto_date="auto_date" 
            :create_paper_original="create_paper_original" 
            :create_paper_copy="create_paper_copy"
            :create_paper_original_and_copy="create_paper_original_and_copy"
            @close="get_fake_receipt = false, selected_billing = JSON.parse(JSON.stringify(export_state)), this.get_booking()"
            :car_companies="car_companies[0]"
        />

        <FakeInvoice
            v-if="get_fake_invoice"
            :selected_billing="selected_billing"
            :billed_amount="billed_amount(selected_billing)"
            :user_name="user_name"
            :no_stamp="no_stamp" 
            :auto_date="auto_date" 
            :create_paper_original="create_paper_original" 
            :create_paper_copy="create_paper_copy"
            :create_paper_original_and_copy="create_paper_original_and_copy"
            @close="get_fake_invoice = false, selected_billing = JSON.parse(JSON.stringify(export_state)), this.get_booking()"
            :car_companies="car_companies[0]"
        />

        <FakeDeliveryNote v-if="get_fake_delivery_note" :selected_billing="selected_billing" 
            :no_stamp="no_stamp" 
            :auto_date="auto_date" 
            :create_paper_original="create_paper_original" 
            :create_paper_copy="create_paper_copy"
            :create_paper_original_and_copy="create_paper_original_and_copy"
            :billed_amount="billed_amount(selected_billing)" :user_name="user_name"
            @close="get_fake_delivery_note = false, selected_billing = JSON.parse(JSON.stringify(export_state)), this.get_booking()" 
            :car_companies="car_companies[0]"
            />

        <DeliveryNote v-if="get_delivery_note" :selected_billing="selected_billing" 
            :no_stamp="no_stamp" 
            :auto_date="auto_date" 
            :create_paper_original="create_paper_original" 
            :create_paper_copy="create_paper_copy"
            :create_paper_original_and_copy="create_paper_original_and_copy"
            :billed_amount="billed_amount(selected_billing)" :user_name="user_name"
            @close="get_delivery_note = false, selected_billing = JSON.parse(JSON.stringify(export_state)), this.get_booking()" 
            :car_companies="car_companies[0]"
            :arrayBillingInQuotation="arrayBillingInQuotation"
            />

        <InvoiceAndDeliveryNote v-if="get_invoice_and_delivery_note" :selected_billing="selected_billing" 
            :no_stamp="no_stamp" 
            :auto_date="auto_date" 
            :create_paper_original="create_paper_original" 
            :create_paper_copy="create_paper_copy"
            :create_paper_original_and_copy="create_paper_original_and_copy"
            :billed_amount="billed_amount(selected_billing)" :user_name="user_name"
            @close="get_invoice_and_delivery_note = false, selected_billing = JSON.parse(JSON.stringify(export_state)), this.get_booking()" 
            :car_companies="car_companies[0]"
            :arrayBillingInQuotation="arrayBillingInQuotation"
            />
        <ExportPdf
            v-if="show_export_modal"
            :show_export_modal="show_export_modal"
            :booking_detail="booking_detail"
            :user_name="user_name"
            @afterClose="create_export_close"
        />
        <a-modal v-model:visible="showAlreadyModal" @ok="showAlreadyModal=false" >
            <div style="display: flex; flex-direction: column ;justify-items:center;align-items: center">
                <span class="material-symbols-outlined" style="font-size: 50px;margin-right: 4px;color:red;margin-bottom: 10px">Warning</span>
                <p v-if="check_already_create == 1">มีรายการที่ถูกตั้งเบิกจ่ายรถร่วมไปแล้ว กรุณาเลือกใหม่อีกครั้ง</p>
                <p v-else>มีรายการที่ถูกสร้างใบวางบิลลูกค้าไปแล้ว กรุณาเลือกใหม่อีกครั้ง</p>
            </div>
            <template #footer>
                <a-button type="primary" @click="showAlreadyModal=false">
                ตกลง
                </a-button>
            </template> 
        </a-modal> 
        <a-drawer v-model:visible="show_drawer" :title="drawer_title" :closable="true" :footer="null">
            <a-form :model="drawer_create_data" name="form" autocomplete="off" layout="vertical"
                @finish="drawer_create">
                <a-row>
                    <a-col :span="col_span"
                        v-for="({ placeholder, required, input_type, options, label_key, modelname, accept ,col_span}, key_name) in drawer_fields">
                        <a-form-item :label="placeholder" :name="key_name"
                            :rules="required ? [{ required, message: `โปรดระบุ ${placeholder}` }] : null">
                            <div v-if="input_type == 'select'">
                                <a-select v-model:value="drawer_create_data[key_name]"
                                    @change="(value) => handleOtherChange(value, key_name)" show-search
                                    :placeholder="placeholder">
                                    <a-select-option v-for="option in options" :value="option.value">
                                        {{ option.label }}
                                    </a-select-option>
                                </a-select>
                                <a-modal v-model:visible="showOtherModal" :title="`ระบุ${placeholder}อื่นๆ`"
                                    @ok="handleModalOk" @cancel="handleModalCancel">
                                    <a-input v-model:value="otherInput" :placeholder="`ระบุ${placeholder}อื่นๆ`" />
                                </a-modal>
                            </div>
                            <a-checkbox-group v-else-if="input_type === 'checkbox_result'"
                                v-model="drawer_create_data[key_name]" :placeholder="placeholder">
                                <a-checkbox value="ผ่าน">ผ่าน</a-checkbox>
                                <a-checkbox value="ไม่ผ่าน">ไม่ผ่าน</a-checkbox>
                            </a-checkbox-group>
                            <div v-else-if="input_type == 'file'"
                                style="display: flex; flex-direction: column; align-items: flex-start; gap:1rem">
                                <div>{{ label }}</div>
                                <a v-if="drawer_create_data[key_name]" :href="drawer_create_data[key_name]"
                                    target="_blank" rel="doc">
                                    {{ drawer_create_data[`${key_name}_name`] }}
                                </a>
                                <a-upload v-else v-model:file-list="fileList" method="GET" :show-upload-list="false"
                                    :accept="accept ? accept : '*/*'" @change="upload_files($event, key_name)">
                                    <div style="display: flex; flex-direction: column; align-items: center;">
                                        <p class="ant-upload-drag-icon">
                                            <span class="material-symbols-outlined"
                                                style="font-size: 5rem;">upload_file</span>
                                        </p>
                                        อัพโหลดไฟล์
                                    </div>
                                </a-upload>
                                <a-button danger type="text" @click="(drawer_create_data[key_name] = '')"
                                    style="padding: 0; height: 18px;" v-if="drawer_create_data[key_name]">
                                    <span class="material-symbols-outlined">close</span>
                                </a-button>
                            </div>
                            <div v-else-if="key_name == 'distance_1'">
                                <a-row :gutter="0">
                                    <a-col :span="19">
                                        <a-input
                                            v-model:value="drawer_create_data[key_name]"
                                            :placeholder="placeholder"
                                            :type="input_type"
                                            style="width: 100%;"
                                        />
                                    </a-col>
                                    <a-col :span="5" style="text-align: center;">
                                        <span> - </span>
                                    </a-col>
                                </a-row>
                            </div>
                            <div v-else-if="key_name == 'start_place'">
                                    <vue-google-autocomplete
                                        :ref="key_name + selectedIndex"
                                        :id="key_name + selectedIndex"
                                        classname="form-control"
                                        :placeholder="placeholder"
                                        v-on:input="
                                            drawer_create_data[key_name] = $event.target.value
                                        "
                                        v-on:placechanged="
                                        select_stop_name($event, key_name)
                                        "
                                        types="establishment"
                                        country="th"
                                    >
                                    </vue-google-autocomplete>
                            </div>
                            <a-input v-else-if="input_type != 'none'" v-model:value="drawer_create_data[key_name]"
                                :placeholder="placeholder" :type="input_type" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row justify="end">
                    <a-col>
                        <a-button html-type="submit" type="primary" size="large"
                            style="margin: 4px;text-align: right">
                            <span class="material-symbols-outlined"
                                style="font-size: 16px;margin-right: 4px">Save</span>
                            บันทึกข้อมูล
                        </a-button>
                    </a-col>
                </a-row>
            </a-form>
        </a-drawer>
    </div>
</template>

<script>
import BillingService from '../../api/BillingService';
import CarCompanyService from '../../api/CarCompanyService';
import SystemService from '../../api/SystemService';
import ReportService from "../../api/ReportService";
import UserService from '../../api/UserService';
import Invoice from '@/components/modals/invoice'
import Receipt from '@/components/modals/receipt'
import DeliveryNote from '@/components/modals/delivery_note'
import FakeReceipt from '@/components/modals/fake_receipt'
import FakeInvoice from '@/components/modals/fake_invoice'
import FakeDeliveryNote from '@/components/modals/fake_delivery_note'
import InvoiceAndDeliveryNote from '@/components/modals/invoice_and_delivery_note'
import { exportXLSXFile } from "@/components/helpers.js";
import * as xlsx from "xlsx-js-style";
import CompanyService from '../../api/CompanyService';
import ExportPdf from "../../components/modals/export_modal.vue";


import Utility from '../../utility'
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
dayjs.extend(buddhistEra)
import Swal from 'sweetalert2'
import { ref, toRaw } from 'vue';
import moment from 'moment';
import ProjectService from '../../api/ProjectService';

export default {
    name: 'manage',
    components: { Invoice, Receipt, FakeReceipt, FakeInvoice , FakeDeliveryNote, DeliveryNote , InvoiceAndDeliveryNote ,ExportPdf},
    props: {
        user_name: String,
    },
    data() {
        return {
            isChecked: false,
            showAlreadyModal: false,
            bank_list: [],
            arrayBillingInQuotation: [],
            isLogisticA: false,
            total_vat: 0,
            total_withHolding: 0,
            total_booking_car_types: 0,
            editable: Utility.get_permission_editable_with_key('billing'),
            company_list: [],
            project_list:[],
            search_client: null,
            search_car_company: null,
            search_project_company: null,
            selected_project_id:null,
            selected_company:null,
            selected_action:null,
            search_client:null,
            company_filter_visible: false,
            company_filter_list: [],
            disablefield: false,
            steppop:null,
            show_export_modal: false,
            booking_detail: null,
            billable_bookings: [],
            billings: [],
            guarantee: [],
            billing_payments: [],
            drawer_create_data:{},
            show_drawer:false,

            selectedRowKeys: [],
            status_options: [],
            billing_statuses: [],
            search_biling_status: null,
            business: false,

            /* Modal */
            loading: false,
            extras_modal: false,
            expenses_modal: false,
            create_billing_modal: false,
            create_multiple_billing_modal: false,
            billed_expenses: false,
            show_month_report: false,
            month_report : {
                month_time_start: null,
                month_time_end: null,
                month_client: null,
                month_status_bill: null,
            },
            selectedTypeBill:null,
            extra_list: [],
            users_list: [],

            selected_role: null,
            extras_list: [],
            sale_list: [],
            extra_id: null,
            price: null,
            business_a: false,
            extra_form: {
                extras_list: [],
                issue_date: null,
                expire_date: null,
            },
            create_billing_form: {
                remaining_amount: 0,
                billing_amount: 0,
                bill_type: null,
                billing_date: null,
                credit_duration: 0,
                sale_id: null,
            },
            create_multiple_billing_form: {
                company_id: null,
                car_company_id: null,
                project:null,
                from: null,
                to: null,
                remaining_amount: 0,
                billing_amount: 0,
                bill_type: 1,
                billing_date: null,
                credit_duration: 0,
                sale_id: null,
            },
            billing_portion: null,
            selected_billing: {
                car_companies: null,
            },
            search_datetime: null,
            search_booking_no: null,
            search_biling_status: null,
            search_biling_date_type: null,
            get_invoice: false,
            get_receipt: false,
            get_delivery_note: false,
            get_fake_receipt: false,
            get_fake_invoice: false,
            get_fake_delivery_note: false,
            get_invoice_and_delivery_note: false,

            create_gu_form: {},
            gu_modal: false,
            export_state: null,

            show_select_sale: false,
            selected_sale_id: null,
            selected_sale: null,
            no_sale: false,
            no_stamp: false,
            auto_date: false,
            create_paper_original: false,
            create_paper_copy: false,
            create_paper_original_and_copy: false,
            billing_filter_visible: false,
            billing_action_list:[
                {"id":1,"name":"ตั้งเบิกจ่ายรถร่วม"},
                {"id":2,"name":"ใบวางบิลลูกค้า"}, 
            ],
            sale_list: [],
            sale_title_modal: {
                invoice: 'ออกใบแจ้งหนี้',
                deliverynote: 'ออกใบส่งมอบงาน',
                invoiceanddeliverynote: 'ออกใบส่งมอบงาน/ออกใบแจ้งหนี้',
                receipt: 'ผู้ออกใบเสร็จรับเงิน',
                no_stamp: false,
                auto_date: false,
                create_paper_original: false,
                create_paper_copy: false,
                create_paper_original_and_copy: false,
            },
            sale_bill_type: null,
            pagination: {
                total: 0,
                onpage: 1,
                perPage: 10
            },
            select_total_remaining:0,
            select_total_car_remaining:0,
            select_remaining_amount: 0 ,
            select_remain: 0,
            select_extras_amount:0,
            select_discount_amount:0,
            car_companies : null,
            select_type_remaining: false,

            // multiple billing
            check_already_create: 0,
            multiple_remaining_amount: 0,
            multiple_billable_bookings: [],
            businessTypeId: 0,
            
        }
    },
    computed: {
        titleBilling(){
            if(this.isLogisticA){
                return 'จัดการวางบิลและเบิกจ่าย'
            }else{
                return 'บิลค้างชำระ'
            }
        },
        report_of_month() {
            const report_of_month = [
                {
                    title: 'Billing NO.',
                    dataIndex: 'billing_no',
                    key: 'billing_no',
                    width: 125,
                    sorter: {
                        compare: (a, b) => a.billing_no.localeCompare(b.billing_no),
                        multiple: 4
                    },
                    align: 'center',
                },,
                {
                    title: 'QTC',
                    dataIndex: 'booking_no',
                    key: 'booking_no',
                    width: 125,
                    sorter: {
                        compare: (a, b) => a.booking.booking_no.localeCompare(b.booking.booking_no),
                        multiple: 3
                    },
                    align: 'center',
                }, 
                {
                    title: 'วันที่ครบกำหนด',
                    dataIndex: 'expire_date',
                    key: 'expire_date',
                    width: 175,
                    sorter: {
                        compare: (a, b) => new Date(a.expire_date) - new Date(b.expire_date),
                        multiple: 2
                    },
                    align: 'center',
                }, {
                    title: 'สถานะบิล',
                    dataIndex: 'billing_status',
                    key: 'billing_status',
                    width: 125,
                    align: 'center',
                }, 
                {
                    title: 'ยอดที่ชำระแล้ว',
                    dataIndex: 'billing_payments',
                    key: 'billing_payments',
                    width: 125,
                    align: 'center',
                }, {
                    title: 'ยอดที่ค้างชำระ',
                    dataIndex: 'unpaid',
                    key: 'unpaid',
                    width: 125,
                    align: 'center',
                },
                {
                    title: 'บัญชีรับเงิน',
                    dataIndex: 'bank_account',
                    key: 'bank_account',
                    width: 200,
                    align: 'center',
                    // fixed: 'right'
                }
            ]
            if (!this.business_a) {
                report_of_month.splice(3, 0, {
                    title: 'ชื่อบริษัท',
                    dataIndex: 'client',
                    key: 'client',
                    width: 175,
                    align: 'center',
                })
                report_of_month.splice(6, 0, {
                    title: 'ยอดเต็มทั้งหมด',
                    dataIndex: 'booking_total_price',
                    key: 'booking_total_price',
                    width: 125,
                    align: 'center',
                })
                report_of_month.splice(7, 0, {
                    title: 'ยอดวางบิลรวม',
                    dataIndex: 'total_price',
                    key: 'total_price',
                    width: 125,
                    align: 'center',
                })
            }
            if (this.business_a) {
                report_of_month.splice(3, 0, {
                    title: 'บริษัทรถ',
                    dataIndex: 'car_company',
                    key: 'car_company',
                    width: 200,
                    align: 'center'
                })
                report_of_month.splice(4, 0, {
                    title: 'บริษัทลูกค้า',
                    dataIndex: 'client_company',
                    key: 'client_company',
                    width: 200,
                    align: 'center'
                })
                report_of_month.splice(5, 0, {
                    title: 'โปรเจกต์ลูกค้า',
                    dataIndex: 'project',
                    key: 'project',
                    width: 120,
                    align: 'center'
                })
                report_of_month.splice(7, 0, {
                    title: 'ประเภทบิล',
                    dataIndex: 'billing_action_id',
                    key: 'billing_action_id',
                    width: 120,
                    align: 'center'
                })
                report_of_month.splice(9, 0, {
                    title: 'ยอดรวมวางบิลลูกค้า',
                    dataIndex: 'total_billing',
                    key: 'total_billing',
                    width: 150,
                    align: 'center'
                })
                report_of_month.splice(10, 0, {
                    title: 'ยอดรวมค่ารถ',
                    dataIndex: 'total_expense_car',
                    key: 'total_expense_car',
                    width: 120,
                    align: 'center'
                })
                report_of_month.splice(16, 0, {
                    title: 'บัญชีนำจ่าย',
                    dataIndex: 'paid_account',
                    key: 'paid_account',
                    width: 200,
                    align: 'center',
                    // fixed: 'right'
                })
            }
            return report_of_month
        },
        billing_columns(){
            const billing_columns = [
                {
                    title: 'QTC',
                    dataIndex: 'booking_no',
                    key: 'booking_no',
                    width: 100,
                    sorter :{
                        compare: (a, b) => a.booking_no.localeCompare(b.booking_no),
                        multiple: 1,
                    },
                    align: 'center',
                }, {
                    title: 'วันเวลารับ',
                    dataIndex: 'time_start',
                    key: 'time_start',
                    width: 150,
                    align: 'center',
                }, {
                    title: 'วันเวลาส่ง',
                    dataIndex: 'time_end',
                    key: 'time_end',
                    width: 150,
                    align: 'center',
                }, {
                    title: 'บริษัทรถ',
                    dataIndex: 'car_company',
                    key: 'car_company',
                    width: 304,
                    align: 'left'
                }, {
                    title: 'ลูกค้า/บริษัท',
                    dataIndex: 'client',
                    key: 'client',
                    width: 304,
                    align: 'center',
                }, 
                {
                    title: 'โปรเจกต์ลูกค้า',
                    dataIndex: 'customer_project',
                    key: 'customer_project',
                    width: 100,
                    align: 'center'
                },
                // {
                //     title: 'วันที่ - เวลา (แก้ไข)',
                //     dataIndex: 'updated_at',
                //     key: 'updated_at',
                //     width: 150,
                //     sorter :{
                //         compare: (a, b) => a.updated_at.localeCompare(b.updated_at),
                //         multiple: 1,
                //     },
                //     align: 'center',
                // },
            ]
            if (this.businessTypeId !== 4) {
                billing_columns.splice(5, 0, {
                    title: 'วันที่ - เวลา (แก้ไข)',
                    dataIndex: 'updated_at',
                    key: 'updated_at',
                    width: 150,
                    sorter :{
                        compare: (a, b) => a.updated_at.localeCompare(b.updated_at),
                        multiple: 1,
                    },
                    align: 'center',
                })
            }
            if (!this.business_a) {
                billing_columns.splice(6, 0, {
                    title: 'หลักประกัน',
                    dataIndex: 'guarantee',
                    key: 'guarantee',
                    width: 100,
                    align: 'center'
                })
                billing_columns.splice(7, 0, {
                    title: 'รวมค่ารถ',
                    dataIndex: 'booking_car_types',
                    key: 'booking_car_types',
                    width: 125,
                    align: 'center'
                })
                billing_columns.splice(8, 0, {
                    title: 'ค่าใช้จ่ายเพิ่มเติม',
                    dataIndex: 'extras',
                    key: 'extras',
                    width: 125,
                    align: 'center'
                })
                billing_columns.splice(9, 0, {
                    title: 'ภาษีมูลค่าเพิ่ม',
                    dataIndex: 'vat',
                    key: 'vat',
                    width: 125,
                    align: 'center'
                })
                billing_columns.splice(10, 0, {
                    title: 'หัก ณ ที่จ่าย (1%)',
                    dataIndex: 'withholding',
                    key: 'withholding',
                    width: 125,
                    align: 'center'
                })
                billing_columns.splice(11, 0, {
                    title: 'ยอดเต็ม',
                    dataIndex: 'summary',
                    key: 'summary',
                    width: 100,
                    align: 'center'
                })
                billing_columns.splice(12, 0, {
                    title: 'ส่วนลด',
                    dataIndex: 'discount',
                    key: 'discount',
                    width: 100,
                    align: 'center'
                })
                billing_columns.splice(13, 0, {
                    title: 'ยอดเรียกเก็บทั้งหมด',
                    dataIndex: 'total_price',
                    key: 'total_price',
                    width: 100,
                    align: 'center',
                })
                billing_columns.splice(14, 0, {
                    title: 'ยอดที่กำลังดำเนินการเรียกเก็บ',
                    dataIndex: 'total_billing',
                    key: 'total_billing',
                    width: 84,
                    align: 'center',
                })
                billing_columns.splice(16, 0, ...(this.business ? [] : [
                    {
                    title: 'ผู้โดยสาร',
                    dataIndex: 'person_qty',
                    key: 'person_qty',
                    width: 100,
                    align: 'center',
                },
                ]))
                billing_columns.splice(17, 0, {
                    title: 'Admin Approved',
                    dataIndex: 'admin_proved',
                    key: 'admin_proved',
                    width: 100,
                    align: 'center',
                })
            }
            if (this.business_a) {
                billing_columns.splice(6, 0, {
                    title: 'ราคาวางบิลลูกค้า',
                    dataIndex: 'selling_per_trip',
                    key: 'selling_per_trip',
                    width: 120,
                    align: 'center'
                })
                billing_columns.splice(7, 0, {
                    title: 'ราคาวางบิลอื่นๆ',
                    dataIndex: 'additional_selling',
                    key: 'additional_selling',
                    width: 120,
                    align: 'center'
                })
                if (this.businessTypeId === 3 ){billing_columns.splice(8, 0, {
                    title: 'ชาร์จสินค้ากลับ',
                    dataIndex: 'return_cost',
                    key: 'return_cost',
                    width: 120,
                    align: 'center'
                })
                billing_columns.splice(9, 0, {
                    title: 'ชาร์จเพิ่มเสียเวลา',
                    dataIndex: 'waiting_time_fee',
                    key: 'waiting_time_fee',
                    width: 120,
                    align: 'center'
                })
                billing_columns.splice(10, 0, {
                    title: 'ชาร์จค่าค้างคืน',
                    dataIndex: 'overnight_charge',
                    key: 'overnight_charge',
                    width: 120,
                    align: 'center'
                })
                }
                billing_columns.splice(11, 0, {
                    title: 'ยกเลิกการใช้รถ',
                    dataIndex: 'cancellation_fee',
                    key: 'cancellation_fee',
                    width: 120,
                    align: 'center'
                })
                billing_columns.splice(12, 0, {
                    title: 'ราคาจ่ายรถ',
                    dataIndex: 'car_expense',
                    key: 'car_expense',
                    width: 120,
                    align: 'center'
                })
                billing_columns.splice(13, 0, {
                    title: 'ชาร์จค่าน้ำมัน',
                    dataIndex: 'gas_charge',
                    key: 'gas_charge',
                    width: 120,
                    align: 'center'
                })
                billing_columns.splice(14, 0, {
                    title: 'ค่าทางด่วน',
                    dataIndex: 'tollway',
                    key: 'tollway',
                    width: 120,
                    align: 'center'
                })
                billing_columns.splice(15, 0, {
                    title: 'ค่าแรงงาน',
                    dataIndex: 'lift_things',
                    key: 'lift_things',
                    width: 120,
                    align: 'center'
                })
                billing_columns.splice(16, 0, {
                    title: 'ค่าจ้างคนขับ',
                    dataIndex: 'driver_cost',
                    key: 'driver_cost',
                    width: 120,
                    align: 'center'
                })
                billing_columns.splice(17, 0, {
                    title: 'ค่าใช้จ่ายอื่นๆ',
                    dataIndex: 'expenses',
                    key: 'expenses',
                    width: 120,
                    align: 'center'
                })
                billing_columns.splice(18, 0, {
                    title: 'ยอดรวมค่ารถ',
                    dataIndex: 'total_expense_car',
                    key: 'total_expense_car',
                    width: 120,
                    align: 'center'
                })
                billing_columns.splice(19, 0, {
                    title: 'ยอดรวมวางบิลลูกค้า',
                    dataIndex: 'total_billing',
                    key: 'total_billing',
                    width: 120,
                    align: 'center'
                })
                billing_columns.splice(20, 0, {
                    title: 'ยอดกำลังตั้งเบิกจ่ายรถ',
                    dataIndex: 'disbursement_car',
                    key: 'disbursement_car',
                    width: 120,
                    align: 'center'
                })
                billing_columns.splice(21, 0, {
                    title: 'ยอดที่กำลังเรียกเก็บลูกค้า',
                    dataIndex: 'customer_bill_amount',
                    key: 'customer_bill_amount',
                    width: 120,
                    align: 'center'
                })
                if(this.businessTypeId === 4) {
                    billing_columns.splice(21, 0, {
                    title: 'วันที่ - เวลา (แก้ไข)',
                    dataIndex: 'updated_at',
                    key: 'updated_at',
                    width: 120,
                    align: 'center'
                })
                }
                billing_columns.splice(23, 0, {
                    title: 'ผู้สร้างใบแจ้งหนี้',
                    dataIndex: 'creator_invoice',
                    key: 'creator_invoice',
                    width: 120,
                    align: 'center'
                })
                billing_columns.splice(24, 0, {
                    title: 'ผู้สร้างใบตั้งเบิก',
                    dataIndex: 'creator_withdraw',
                    key: 'creator_withdraw',
                    width: 120,
                    align: 'center'
                })
            }
            billing_columns.push({
                title: 'ตัวเลือก',
                dataIndex: 'id',
                key: 'id',
                width: 65,
                align: 'center',
                fixed: 'right'
            })
            return billing_columns
        },
        billing_filter_title() {
            this.car_filter_list = [];
            if (this.search_car) {
                this.car_filter_list.push({
                    key: "search_car",
                    value: this.search_car,
                    label: `ทะเบียนรถ: ${
                    this.plate_no_list.find(
                        (searchCar) => searchCar.name == this.search_car
                    )?.name
                    }`,
                });
            }
            if (this.selected_company) {
                this.car_filter_list.push({
                    key: "selected_company",
                    value: this.selected_company,
                    label: `บริษัทรถ: ${
                    this.car_company_list.find(
                        (searchCar) => searchCar.id == this.selected_company
                    )?.fullname
                    }`,
                });
            }
            if (this.selected_project_id) {
                this.car_filter_list.push({
                    key: "selected_project_id",
                    value: this.selected_project_id,
                    label: `โปรเจกต์: ${
                    this.project_list.find(
                        (searchCar) => searchCar.id == this.selected_project_id
                    )?.project_name
                    }`,
                });
            }
            if (this.search_biling_status) {
                this.car_filter_list.push({
                    key: "search_biling_status",
                    value: this.search_biling_status,
                    label: `สถานะ billings: ${
                    this.billing_statuses.find(
                        (searchCar) => searchCar.id == this.search_biling_status
                    )?.name
                    }`,
                });
            }
            if(this.selected_action){
                this.car_filter_list.push({
                    key: "selected_action",
                    value: this.selected_action,
                    label: `ประเภทบิล: ${
                    this.billing_action_list.find(
                        (searchCar) => searchCar.id == this.selected_action
                    )?.name
                    }`,
                });
            }
            if(this.search_client){
                this.car_filter_list.push({
                    key: "search_client",
                    value: this.search_client,
                    label: `ลูกค้า: ${
                    this.company_list.find(
                        (searchCar) => searchCar.id == this.search_client
                    )?.fullname
                    }`,
                });
            }
    
            return this.car_filter_list.length
            ? this.car_filter_list.map(({ label }) => label).join(", ")
            : null;
        },
        billing_column_render(){
            const columnUsed = [
                        {
                            title: 'Billing NO.',
                            dataIndex: 'billing_no',
                            key: 'billing_no',
                            width: 125,
                            sorter: {
                                compare: (a, b) => a.billing_no.localeCompare(b.billing_no),
                                multiple: 4
                            },
                            align: 'center',
                        }, {
                            title: 'QTC',
                            dataIndex: 'booking_no',
                            key: 'booking_no',
                            width: 125,
                            sorter: {
                                compare: (a, b) => a.booking.booking_no.localeCompare(b.booking.booking_no),
                                multiple: 3
                            },
                            align: 'center',
                        }, {
                            title: 'วันเวลารับ',
                            dataIndex: 'time_start',
                            key: 'time_start',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'วันเวลาส่ง',
                            dataIndex: 'time_end',
                            key: 'time_end',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'บริษัทรถ',
                            dataIndex: 'car_company',
                            key: 'car_company',
                            width: 304,
                            align: 'left'
                        }, {
                            title: 'ลูกค้า',
                            dataIndex: 'client',
                            key: 'client',
                            width: 200,
                            align: 'center',
                        }, {
                            title: 'สถานะของบิล',
                            dataIndex: 'billing_action_status',
                            key: 'billing_action_status',
                            width: 135,
                            align: 'center',
                        }, {
                            title: 'สถานะ',
                            dataIndex: 'billing_status',
                            key: 'billing_status',
                            width: 135,
                            align: 'center',
                        }, {
                            title: 'ประเภทบิล',
                            dataIndex: 'billing_type',
                            key: 'billing_type',
                            width: 125,
                            align: 'center',
                        }, {
                            title: 'วันที่ออกบิล',
                            dataIndex: 'issue_date',
                            key: 'issue_date',
                            width: 125,
                            align: 'center',
                            sorter: {
                                compare: (a, b) => new Date(a.issue_date) - new Date(b.issue_date),
                                multiple: 1
                            }
                        }, {
                            title: 'วันที่ครบกำหนด',
                            dataIndex: 'expire_date',
                            key: 'expire_date',
                            width: 125,
                            align: 'center',
                            sorter: {
                                compare: (a, b) => new Date(a.expire_date) - new Date(b.expire_date),
                                multiple: 2
                            }
                        }, {
                            title: 'ยอดวางบิลรวม',
                            dataIndex: 'total_price',
                            key: 'total_price',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'ยอดที่ชำระแล้ว',
                            dataIndex: 'billing_payments',
                            key: 'billing_payments',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            align: 'center',
                            fixed: 'right'
                        }
                    ]
                    
            const columnAUsed = [
                        {
                            title: 'Billing NO.',
                            dataIndex: 'billing_no',
                            key: 'billing_no',
                            width: 135,
                            sorter: {
                                compare: (a, b) => a.billing_no.localeCompare(b.billing_no),
                                multiple: 4
                            },
                            align: 'center',
                        }, {
                            title: 'QTC',
                            dataIndex: 'booking_no',
                            key: 'booking_no',
                            width: 125,
                            sorter: {
                                compare: (a, b) => a.booking.booking_no.localeCompare(b.booking.booking_no),
                                multiple: 3
                            },
                            align: 'center',
                        }, {
                            title: 'วันเวลารับ',
                            dataIndex: 'time_start',
                            key: 'time_start',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'วันเวลาส่ง',
                            dataIndex: 'time_end',
                            key: 'time_end',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'บริษัทรถ',
                            dataIndex: 'car_company',
                            key: 'car_company',
                            width: 304,
                            align: 'left'
                        }, {
                            title: 'ลูกค้า',
                            dataIndex: 'client',
                            key: 'client',
                            width: 200,
                            align: 'center',
                        }, {
                            title: 'โปรเจกต์ลูกค้า',
                            dataIndex: 'project_id',
                            key: 'project_id',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'ประเภทบิล',
                            dataIndex: 'billing_action_status',
                            key: 'billing_action_status',
                            width: 135,
                            align: 'center',
                        }, {
                            title: 'สถานะ',
                            dataIndex: 'billing_status',
                            key: 'billing_status',
                            width: 135,
                            align: 'center',
                        }, {
                            title: 'ประเภทการจ่ายเงิน',
                            dataIndex: 'billing_type',
                            key: 'billing_type',
                            width: 125,
                            align: 'center',
                        }, {
                            title: 'วันที่ออกบิล',
                            dataIndex: 'issue_date',
                            key: 'issue_date',
                            width: 125,
                            align: 'center',
                            sorter: {
                                compare: (a, b) => new Date(a.issue_date) - new Date(b.issue_date),
                                multiple: 1
                            }
                        }, {
                            title: 'วันที่ครบกำหนด',
                            dataIndex: 'expire_date',
                            key: 'expire_date',
                            width: 125,
                            align: 'center',
                            sorter: {
                                compare: (a, b) => new Date(a.expire_date) - new Date(b.expire_date),
                                multiple: 2
                            }
                        }, {
                            title: 'ยอดรวมวางบิลลูกค้า',
                            dataIndex: 'total_price',
                            key: 'total_price',
                            width: 175,
                            align: 'center',
                        }, {
                            title: 'ยอดรวมค่ารถ',
                            dataIndex: 'total_car_price',
                            key: 'total_car_price',
                            width: 175,
                            align: 'center',
                        }, {
                            title: 'ยอดที่ชำระแล้ว',
                            dataIndex: 'billing_payments',
                            key: 'billing_payments',
                            width: 175,
                            align: 'center',
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            align: 'center',
                            fixed: 'right'
                        }
                    ]
            return this.isLogisticA? columnAUsed:columnUsed 
        },
        car_filter_title() {
        this.company_filter_list = [];
        if (this.search_car_company) {
          this.company_filter_list.push({
            key: "search_car_company",
            value: this.car_company_list,
            label: `บริษัทรถ: ${
              this.car_company_list.find(
                (car_company) => car_company.id == this.search_car_company
              )?.fullname
            }`,
          });
        }
        if (this.search_project_company) {
                this.company_filter_list.push({
                    key: "search_project_company",
                    value: this.search_project_company,
                    label: `โปรเจกต์: ${
                    this.project_list.find(
                        (searchCar) => searchCar.id == this.search_project_company
                    )?.project_name
                    }`,
                });
            }
        if (this.search_client) {
          this.company_filter_list.push({
            key: "search_client",
            value: this.company_list,
            label: `ลูกค้า/บริษัท: ${
              this.company_list.find(
                (company_list) => company_list.id == this.search_client
              )?.fullname
            }`,
          });
        }
        // if (this.search_project_company) {
        //   this.company_filter_list.push({
        //     key: "search_project_company",
        //     value: this.company_list,
        //     label: `โปรเจกต์ลูกค้า: ${
        //       this.company_list.find(
        //         (company_list) => company_list.id == this.company_list
        //       )?.name
        //     }`,
        //   });
        // }
            return this.company_filter_list.length
            ? this.company_filter_list.map(({ label }) => label).join(", ")
            : null;
        },
        render_type() {
            return this.$route.params.type
        },
        selected_sale() {
            return this.sale_list.find(sale => sale.id === this.selected_sale_id) || null;
        },
        typeBillingRules() {
            if (this.selectedTypeBill === false ||this.selectedTypeBill === null) {
                return [{ required: true, message: 'โปรดระบุสถานะของ Billing' }]; // Apply rule if 'SelectedCar'
            }
            return []; // No rules otherwise
        }, 
        typeProjectRules() {
            if (this.isChecked === false ||this.isChecked === null) {
                return [{ required: true, message: 'โปรดเลือกโปรเจกต์' }]; // Apply rule if 'SelectedCar'
            }
            return []; // No rules otherwise
        }, 
        handleCheckboxProjectChange(){
            if(this.isChecked == true){
                this.create_multiple_billing_form.project = null;
                this.cal_multiple_bill()
            }
        },
        handleCheckboxChange() {
        // When checkbox changes, manually trigger validation update
            this.$refs.formBillingMonth.clearValidate(['month_status_bill']);
            if (!this.selectedTypeBill) {
                this.$nextTick(() => {
                this.$refs.formBillingMonth.validateFields(['month_status_bill']);
                });
            }
        },
    },
    methods: {
        render_datetime(date,time) {
            const datetime = `${date.slice(0,10)} ${time.slice(11,16)}`
            return dayjs(datetime).format("DD/MM/YYYY HH:mm");
        },
        filterProjectByCarCom(projectList){
            if(this.steppop == 1 && this.create_multiple_billing_form.car_company_id != null){
                const billingList = this.billable_bookings;
                const projectInBilling = billingList.filter((item)=>{
                    return item.car_company_id == this.create_multiple_billing_form.car_company_id});
                const projectIds = [...new Set(projectInBilling.map(item => item.project_id))];
                const listFilter = projectList.filter((item)=>{
                    return projectIds.includes(item.id)
                })
                return [{ id: 0, project_name: 'ไม่มีโปรเจกต์' }, ...listFilter];
            }else if(this.steppop == 2 && this.create_multiple_billing_form.company_id != null){
                const listFilter = projectList.filter((item)=>{
                    return item.client_id == this.create_multiple_billing_form.company_id
                })
                return [{ id: 0, project_name: 'ไม่มีโปรเจกต์' }, ...listFilter];
            }
            return [{ id: 0, project_name: 'ไม่มีโปรเจกต์' }, ...projectList];
        },
        getCompanyName(company_id) {
            const company = this.car_companies.find(company => company.id === company_id);
            return company ? company.fullname : '';
        },
        getSaleName(sale_id) {
            const sale = this.sale_list.find(sale => sale.id === sale_id);
            return sale ? `${sale.firstname} ${sale.lastname}` : '';
        },
        getProjectName(project_id) {
            const project = this.project_list.find(project => project.id === project_id);
            return project ? project.project_name : '';
        },
        getBankName(bank_id) {
            const bank = this.bank_list.find(bank => bank.id === bank_id);
            return bank ? bank.name : '';
        },
        calculateTotalBilling(record) {
            let total;
            if(this.businessTypeId === 3) {
                const sellingPerTrip = Number(record.selling_per_trip || 0);
                const additionalSelling = Number(record.additional_selling || 0);
                const returnCost = Number(record.return_cost || 0);
                const waitingTimeFee = Number(record.waiting_time_fee || 0);
                const overnightCharge = Number(record.overnight_charge || 0);
                const cancellationFee = Number(record.cancellation_fee || 0);
                total = sellingPerTrip + additionalSelling + returnCost + waitingTimeFee + overnightCharge + cancellationFee
            } else {
                const sellingPerTrip = Number(record.selling_per_trip || 0);
                const additionalSelling = Number(record.additional_selling || 0);
                const cancellationFee = Number(record.cancellation_fee || 0);
                total = sellingPerTrip + additionalSelling + cancellationFee
            }
            
            return Math.round(total);
        },
        calculateTotalExpenseCar(record) {
            const carExpense = Number(record.price_car);
            const gasCharge = Number(this.gasAmount(record));
            const tollway = Number(this.tollwayAmount(record));
            const liftThings = Number(record.lift_things || 0);
            const driverCost = record.company_3rd == 'บริษัทรถร่วม' 
                ? 0 
                : (record.drivers && record.drivers[0] && record.drivers[0].salary 
                    ? Math.round(record.drivers[0].salary / 26) 
                    : 0);
            const expenses = Number(this.expensesAmount(record));

            const total = carExpense + gasCharge + tollway + liftThings + driverCost + expenses;
            return Math.round(total); // ปัดค่าขึ้น-ลงอัตโนมัติตามค่าทศนิยม
        },
        carPayment(record) {
            const priceCar = record.booking_cars[0]?.car?.car_payment 
                ? Math.round(Number(record.booking_cars[0].car.car_payment) / 26).toLocaleString() 
                : 0;
            return priceCar;
        },
        sum_car_expense(booking_cars) {
        return booking_cars
            .map(({ toll_ways, gas_refills, expenses, driver_cost, rental_price }) => {
            const tollWaysSum = Array.isArray(toll_ways)
                ? toll_ways.reduce((a, b) => a + (b.granted === 1 ? Number(b.paid_amount) || 0 : 0), 0)
                : 0;
            const gasRefillsSum = Array.isArray(gas_refills)
                ? gas_refills.reduce((a, b) => a + (b.granted === 1 ? Number(b.total_amount) || 0 : 0), 0)
                : 0;
            const expensesSum = Array.isArray(expenses)
                ? expenses.reduce((a, b) => a + (b.granted === 1 ? Number(b.total_payment) || 0 : 0), 0)
                : 0;
            const driverCost = Number(driver_cost) || 0;
            const rentalPrice = Number(rental_price) || 0;
            return tollWaysSum + gasRefillsSum + expensesSum + driverCost + rentalPrice;
            })
            .reduce((a, b) => a + b, 0);
        },
        expensesAmount(record) {
            if (!record?.booking_cars?.[0]?.expenses) {
                return "0";
            }
            return record.booking_cars[0].expenses
                .filter(extra => extra.granted == 1)
                .reduce((sum, extra) => sum + (parseFloat(extra.total_payment) || 0), 0)
        },
        tollwayAmount(record) {
            if (!record?.booking_cars?.[0]?.toll_ways) {
                return "0";
            }
            if (record.company_3rd != 'บริษัทรถร่วม') {
                return record.booking_cars[0].toll_ways
                    .filter(tollway => tollway.granted == 1)
                    .reduce((sum, tollway) => sum + (parseFloat(tollway.paid_amount) || 0), 0)
            }
            else {
                return 0
            }
        },
        gasAmount(record) {
            if (!record?.booking_cars?.[0]?.gas_refills) {
                return "0";
            }
            if (record.company_3rd != 'บริษัทรถร่วม') {
                return record.booking_cars[0].gas_refills
                .filter(refill => refill.granted == 1)
                .reduce((sum, refill) => sum + (parseFloat(refill?.total_amount) || 0), 0)
            }
            else {
                return 0
            }
        },
        async get_business_type(){
            try {
                const data = await UserService.get_user_profile();
                let result = await CompanyService.getBusinessId(data.company_id);
                this.businessTypeId = result.data;
                if (this.businessTypeId === 3 || this.businessTypeId === 4) {
                    this.business_a = true
                }
                return this.businessTypeId;
            } catch (error) {
                console.error('Error fetching business type:', error);
            }
        },
        async get_company() {
			const company = await UserService.get_user_profile();
            const businessType = await CompanyService.getBusinessId(company.company_id)
            if ( businessType.data === 2)
            {
                this.business = true
            }
            if( businessType.data === 3 || businessType.data === 4){
                this.isLogisticA = true
            }
        },
        create_export_close() {
            this.booking_detail = null;
            this.show_export_modal = false;
        },
        openCoverDoc(billings, alter_title) {
            this.show_export_modal = true;
            this.booking_detail = [];
            this.booking_detail.alter_title = alter_title;
        },
        async submit_month_report() {
            this.show_month_report = false;
            this.$message.loading({ content: "กำลังโหลด...", key: "loading" });
            const time_start = dayjs(this.month_report.month_time_start).format("YYYY-MM-DD");
            const time_end = dayjs(this.month_report.month_time_end).format("YYYY-MM-DD");
            if (this.selectedTypeBill == true) {
                this.month_report.month_status_bill = 0;
            }
            const { data } = await ReportService.month_report(this.month_report.month_client, this.month_report.month_status_bill, time_start, time_end);

            var headersColumn;
            if (this.business_a) {
                headersColumn = [
                    { header: "Billing NO.", key: "billingNo" },
                    { header: "QTC", key: "qtc" },
                    { header: "บริษัทรถ", key: "carCompanyName" },
                    { header: "บริษัทลูกค้า", key: "companyName" },
                    { header: "โปรเจกต์ลูกค้า", key: "projectName" },
                    { header: "วันที่ครบกำหนด", key: "expireDate" },
                    { header: "ประเภทบิล", key: "typeBilling" },
                    { header: "สถานะบิล", key: "statusBilling" },
                    { header: "ยอดรวมวางบิลลูกค้า", key: "totalPrice" },
                    { header: "ยอดรวมค่ารถ", key: "totalBookingPrice" },
                    { header: "ยอดที่ชำระแล้ว", key: "billingPayments" },
                    { header: "ยอดที่ค้างชำระ", key: "unPaid" },
                    { header: "บัญชีรับเงิน", key: "bankAccount" },
                    { header: "บัญชีนำจ่าย", key: "paidAccount" },
                ];
            } else {
                headersColumn = [
                    { header: "Billing NO.", key: "billingNo" },
                    { header: "QTC", key: "qtc" },
                    { header: "ชื่อบริษัท", key: "companyName" },
                    { header: "วันที่ครบกำหนด", key: "expireDate" },
                    { header: "สถานะบิล", key: "statusBilling" },
                    { header: "ยอดเต็มทั้งหมด", key: "totalPrice" },
                    { header: "ยอดวางบิลรวม", key: "totalBookingPrice" },
                    { header: "ยอดที่ชำระแล้ว", key: "billingPayments" },
                    { header: "ยอดที่ค้างชำระ", key: "unPaid" },
                    { header: "ยอดที่ค้างชำระจากยอดรวม", key: "bookingUnpaid" },
                    { header: "บัญชีรับเงิน", key: "bankAccount" },
                ];
            }

            const filteredData = data.map(item => {
                const filteredItem = {};
                headersColumn.forEach(header => {
                    if (item.hasOwnProperty(header.key)) {
                        filteredItem[header.key] = item[header.key];
                    }
                });
                return filteredItem;
            });

            const workBook = xlsx.utils.book_new();
            const workSheet = xlsx.utils.json_to_sheet(filteredData, { header: headersColumn.map(h => h.key) });
            const lastRow = data.length + 1;
            xlsx.utils.sheet_add_aoa(workSheet, [headersColumn.map(h => h.header)], { origin: 'A1' });

            let fillColor = { rgb: "E0ECF4" };
            headersColumn.forEach((header, index) => {
                let cell = workSheet[xlsx.utils.encode_cell({ c: index, r: 0 })];
                cell.s = {
                    fill: { fgColor: fillColor },
                    border: { bottom: { style: "thin" }, right: { style: "thin" }, left: { style: "thin" }, top: { style: "thin" } },
                    alignment: { horizontal: "center" }
                };
            });

            for (let row = 1; row < lastRow; row++) {
                for (let col = 0; col < headersColumn.length; col++) {
                    const cellAddress = xlsx.utils.encode_cell({ c: col, r: row });
                    let cell = workSheet[cellAddress];
                    if (!cell) {
                        cell = { v: "" };
                        workSheet[cellAddress] = cell;
                    }
                    if (headersColumn[col].key === "totalPrice" || headersColumn[col].key === "totalBookingPrice" || headersColumn[col].key === "billingPayments" || headersColumn[col].key === "unPaid" || headersColumn[col].key === "bookingUnpaid") {
                        cell.s = {
                            border: { bottom: { style: "thin" }, right: { style: "thin" }, left: { style: "thin" }, top: { style: "thin" } },
                            alignment: { wrapText: true, horizontal: "right", vertical: "top" } // Right align these cells
                        };
                    } else {
                        cell.s = {
                            border: { bottom: { style: "thin" }, right: { style: "thin" }, left: { style: "thin" }, top: { style: "thin" } },
                            alignment: { wrapText: true, horizontal: "center", vertical: "top" } // Center align other cells
                        };
                    }
                }
            }

            if (this.business_a) {
                workSheet['!cols'] = [
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 28 },
                    { wch: 15 },
                    { wch: 12 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 23 },
                    { wch: 23 },
                    { wch: 23 },
                    { wch: 35 },
                    { wch: 35 },
                ];
            } else {
                workSheet['!cols'] = [
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 28 },
                    { wch: 15 },
                    { wch: 12 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 23 },
                    { wch: 35 },
                ];
            }

            if (!workSheet['!ref']) {
                workSheet['!ref'] = xlsx.utils.encode_range({
                    s: { r: 0, c: 0 },
                    e: { r: lastRow + 1, c: headersColumn.length - 1 }
                });
            } else {
                const range = xlsx.utils.decode_range(workSheet['!ref']);
                range.e.r = lastRow + 1;
                workSheet['!ref'] = xlsx.utils.encode_range(range);
            }

            xlsx.utils.book_append_sheet(workBook, workSheet, "Report");
            xlsx.writeFile(workBook, "รายงานประจำเดือน.xlsx");
            this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
        },
        onSaleChanged(v) {
            this.selected_sale_id = v;
            this.selected_sale = v ? this.sale_list.find(x => x.id === v) : null;
            this.selected_billing.booking[0].sale = this.selected_sale;
        },
        onNoSaleChanged(checked) {
            this.no_sale = checked.target.checked;
            if (checked) {
                this.selected_sale_id = null;
                this.selected_billing.booking[0].sale = null;
            } 
        },
        onNoStampChanged(checked){
            this.no_stamp = checked.target.checked;
        },
        autoDateChanged(checked){
            this.auto_date = checked.target.checked;
        },
        createPaperOriginal(checked){
            this.create_paper_original = checked.target.checked;
        },
        createPaperCopy(checked){
            this.create_paper_copy = checked.target.checked;
        },
        createPaperOriginalAndCopy(checked){
            this.create_paper_original_and_copy = checked.target.checked;
        },
        handleTableChange(pagination, filters, sorter) {
            this.pagination.onpage = pagination.current
            this.pagination.perPage = pagination.pageSize;

            this.get_booking(this.render_type)
        },
        display_qt_setting(record, name) {
            try {
                const qtSetting = JSON.parse(record.qt_setting);
                return qtSetting && qtSetting[name] ? qtSetting[name] : null;
            } catch (error) {
                console.error("Invalid JSON in record.qt_setting:", error);
                return null; 
            }
        },
        filterBookingBillings(billing = []) {
            if (this.search_booking_no) {
                billing = billing.filter(( booking ) => booking?.booking_no.includes(this.search_booking_no));
            }
            if (this.search_client) {
                billing = billing.filter(({ client }) => client?.id == this.search_client);
            }
            if (this.search_car_company) {
                billing = billing.filter((booking) => booking?.car_company_id == this.search_car_company);
            }
            if (this.search_project_company) {
                billing = billing.filter(({ project }) => project?.id == this.search_project_company);
            }
            if (this.search_datetime) {
                const [time_start, time_end] = this.search_datetime.map(item => dayjs(item).format('YYYY-MM-DD'));
                billing = billing.filter(({ time_start: itemStart }) => {
                    const itemStartDate = dayjs(itemStart).format('YYYY-MM-DD');
                    return (
                        (time_start <= itemStartDate && itemStartDate <= time_end)
                    );
                });
            }
            // console.log("6666",billing)
            return billing.sort((a,b) => b.id - a.id);;
        },  
        get_total_value(booking_car_types = []) {
            return booking_car_types.map(({ price, quantity }) => price * quantity).reduce((a, b) => a + b, 0)
        },
        billed_amount(selected_billing) {
            var billed_amount = this.billings
                .filter(({ booking_id }) => booking_id == selected_billing.booking_id)
                .filter(({ id }) => id < selected_billing.id)
                .reduce((a,b) => a+b.total_price, 0)

            if (selected_billing.booking.billings) {
                billed_amount = selected_billing.booking.billings.reduce((a,b) => a+b.total_price, 0)
            }

            return billed_amount
        },
        withholding_type(car_company, type) {
            if (car_company) {
                const { withholding_id } = car_company
                if (withholding_id == 1) {
                    return type == 'text'? `(1%)`: 0.01
                } else if (withholding_id == 2) {
                    return type == 'text'? `(5%)`: 0.05
                } else {
                    return type == 'text'? `(3%)`: 0.03
                }
            }
        },billingStatusText(id,text,exp){
            const currentDate = dayjs();
            const searchDate = dayjs(exp);
            const leftDays = currentDate.diff(searchDate, 'day');
            if([7,8,9].includes(id)){
                return "จ่ายแล้ว"
            }else if(id == 6 && leftDays <= 30){
                return "ยังไม่จ่าย"
            }else if(id == 6 && leftDays > 30){
                return "เกินกำหนด"
            }else{
                return text
            }
        },
        billingActionText(id,d){
            if(id === 1){
                return "ตั้งเบิกจ่ายรถร่วม"
            } else{
                return "ใบวางบิลลูกค้า"
            }
        },
        sum_car_price({ discount, booking_car_types, extras = [], inc_vat, withholding, car_company}, type) {
            const sum_extras = Number(extras.map(({ amount, price }) => amount*price).reduce((a,b) => a+b, 0))
            // console.log("sum_extras",sum_extras)
            var total = booking_car_types.map(({ cost }) => Number(cost) || 0).reduce((a, b) => a + b, 0) + sum_extras;
            
            // console.log("total",total)
            const vat = inc_vat? (total - sum_extras) * 0.07 : 0
            const cal_withholding = withholding? (total - sum_extras) * 0.01 : 0
            // this.total_vat = vat;
            const withholding_type = this.withholding_type(car_company, 'number')
            // const with_holding = withholding? Number((Number(total) - Number(sum_extras)) * Number(withholding_type)) : 0

            const result = {
                total: Number(total) + Number(vat) - Number(cal_withholding),
                vat,
                // withholding: with_holding,
                discount: discount? discount : 0,
                booking_car_types: Number(total) - Number(sum_extras),
                extras: sum_extras,
                withholding: cal_withholding,
            }
            this.total_booking_car_types = Number(total) - Number(sum_extras);
            if (type === 'vat') {
                this.total_vat = vat;
            }
            if (type === 'withholding') {
                this.total_withHolding = cal_withholding;
            }
            
            return result[type]
        },
        submit_create_gu() {
            const booking_id = this.selectedRowKeys[0]
            const body = {
                ...this.create_gu_form,
                booking_id,
                company_id: JSON.parse(localStorage.getItem('companyData')).id
            }
            this.$message.loading({ content: 'Loading...', duration: 0, key: 'submit_create_gu' });
            SystemService.create_all('Guarantee', { data: [body]})
                .then(res => {
                    this.$message.success({ content: 'สร้างใบค้ำประกันสำเร็จ', key: 'submit_create_gu' });
                    this.gu_modal = false
                    this.get_booking()
                })
        },
        showSelectSale(bill_type) {
            this.show_select_sale = true;
            this.sale_bill_type = bill_type;
            this.onSaleChanged(this.selected_billing?.booking[0]?.sale?.id || null);
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const saleSubmit = this.$refs.saleSubmit.$el;
                    saleSubmit.addEventListener('click', () => {
                        if (this.selected_sale_id || this.no_sale) {
                            this.show_select_sale = false;
                            resolve();
                        }
                    });
                }, 100);
            });
        },
        async create_invoice(guarantee, fake) {
            
            if (!this.export_state) {
                this.export_state = JSON.parse(JSON.stringify(this.selected_billing))
            }
            
            if (fake) {
                const booking_id = this.selectedRowKeys[0]
                const target = this.billable_bookings.find(x => x.id == booking_id)
                const { inc_vat, withholding, car_company, extras, discount } = target
                const sum_extra = extras.map(({ price, amount }) => Number(price) * Number(amount)).reduce((a, b) => a + b, 0)
                // const sum = target.booking_car_types.map(({ cost }) => cost).reduce((a, b) => a + b, 0)
                let sum = 0;
                target.booking_car_types.forEach(({ cost }) => {
                    sum += Number(cost) || 0;
                });
                const car_cost_excluded = target.booking_car_types.map(({ cost, expenses }) => {
                    const sum_expenses = expenses.reduce((a, b) => a + ((Number(b.price) || 0) * (Number(b.amount) || 0)), 0);
                    return (Number(cost) || 0) - sum_expenses;
                }).reduce((a, b) => a + b, 0);
                
                this.selected_billing = { booking: target, company: target.company, stops: target.booking_cars.map(({ stops }) => stops).flat() }
                this.selected_billing.billing_no = target.billings[0]?.billing_no
                this.selected_billing.billing_payments = []
                this.selected_billing.inc_vat = inc_vat? sum * 0.07 : 0
                this.selected_billing.withholding = withholding? (car_cost_excluded * this.withholding_type(car_company)) : 0
                this.selected_billing.extra_sum = sum_extra
                this.selected_billing.total_price = (sum + (Number(this.selected_billing.inc_vat ?? 0) + Number(sum_extra ?? 0) - Number(discount ?? 0)))
                    // - this.selected_billing.withholding

                
                await this.showSelectSale('invoice')


                this.get_fake_invoice = true

            } else if (guarantee) {
                const { amount } = this.selected_billing
                this.selected_billing.company = this.selected_billing.booking.company
                this.selected_billing.total_price = amount
                this.selected_billing.is_guarantee = true
      
                await this.showSelectSale('invoice')
                this.get_invoice = true

            } else {
                const deposit_percent_list = [];
                this.selected_billing.inc_vat = 0
                this.selected_billing.withholding = 0
                this.selected_billing.booking.forEach((booking, index) => {
                    const { inc_vat, withholding, car_company, extras, discount, booking_car_types } = booking;
                    const total_price = Number(this.selected_billing.total_price[index]) || 0;
                    const sum_extra = extras.map(({ price, amount }) => (Number(price) || 0) * (Number(amount) || 0)).reduce((a, b) => a + b, 0);
                    const original_price = booking_car_types.map(({ cost }) => Number(cost) || 0).reduce((a, b) => a + b, 0);
                    const car_cost_excluded = booking_car_types.map(({ cost, expenses }) => {
                        const sum_expenses = expenses.reduce((a, b) => a + ((Number(b.price) || 0) * (Number(b.amount) || 0)), 0);
                        return (Number(cost) || 0) - sum_expenses;
                    }).reduce((a, b) => a + b, 0);
                    this.arrayBillingInQuotation = this.billings.filter((billing) => billing.booking_id == booking.id)
                                                        .sort((a, b) => a.id - b.id)
                    this.selected_billing.select_type_remaining = false;
                    let billingNoIndex = this.arrayBillingInQuotation.findIndex(
                        (billing) => billing.id === this.selected_billing.id)
                    if (billingNoIndex !== -1) {
                        let selectBiiling = this.arrayBillingInQuotation.slice(0, billingNoIndex + 1);
                        let selectBillingAmount = selectBiiling.reduce((sum, billing) => sum + parseFloat(billing.total_price), 0)
                        if( Math.round((original_price - Number(discount) + Number(sum_extra))+ (inc_vat? Number(0.07*Number(original_price)):0) - (withholding? Number(0.01*Number(original_price)):0)) - selectBillingAmount <= 0){
                            this.selected_billing.select_type_remaining = true;
                        }else{
                            this.selected_billing.select_type_remaining = false;
                        }
                    }
                    let deposit_percent = original_price === 0 ? 0 : 
                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && this.arrayBillingInQuotation[0].billing_status_id == 7 
                        && (this.selected_billing.billing_status_id == 2 || this.selected_billing.billing_status_id > 7) && inc_vat == 1 ?  
                        (total_price-sum_extra+(Number(discount)-((Number(original_price)*0.07)-Number(this.arrayBillingInQuotation[0].total_price)*0.07) || 0)) / original_price :

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && this.arrayBillingInQuotation[0].billing_status_id == 7 
                        && (this.selected_billing.billing_status_id == 2 || this.selected_billing.billing_status_id > 7) && withholding == 1 ? 
                        (total_price-sum_extra+(Number(discount)-((Number(original_price)*0.01)-Number(this.arrayBillingInQuotation[0].total_price)*0.01) || 0)) / original_price:

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && (this.selected_billing.billing_status_id ==2 || this.selected_billing.billing_status_id > 7) && inc_vat == 1 ? 
                        (total_price-sum_extra+(Number(discount)-(Number(original_price)*0.07) || 0)) / original_price  :

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && (this.selected_billing.billing_status_id ==2 || this.selected_billing.billing_status_id > 7) && withholding == 1 ? 
                        (total_price-sum_extra+(Number(discount)+(Number(original_price)*0.01) || 0)) / original_price:

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && (this.selected_billing.billing_status_id ==2 || this.selected_billing.billing_status_id > 7) && inc_vat != 1 ? 
                        (total_price-sum_extra+(Number(discount) || 0)) / original_price :

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && this.selected_billing.billing_status_id == 7 && inc_vat == 1? 
                        (((total_price- sum_extra +Number(discount))*100/107) || 0) / original_price :

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && this.selected_billing.billing_status_id == 7 && withholding == 1 ? 
                        (((total_price- sum_extra +Number(discount))*100/99) || 0) / original_price :

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && this.selected_billing.billing_status_id == 7 && inc_vat != 1 ? 
                        (total_price- sum_extra +(Number(discount)  || 0)) / original_price :

                        this.selected_billing.billing_type_id > 3 &&this.selected_billing.billing_status_id == 7 && inc_vat == 1? 
                        ((total_price+Number(discount))*100/107 || 0) / original_price :

                        this.selected_billing.billing_type_id > 3 &&this.selected_billing.billing_status_id == 7 && withholding == 1 ? 
                        ((total_price+Number(discount))*100/99 || 0) / original_price :

                        this.selected_billing.billing_type_id > 3 &&this.selected_billing.billing_status_id == 7 && inc_vat != 1 ? 
                        (total_price+(Number(discount) || 0)) / original_price : total_price / original_price;

                    const extra = Number(extras.map(({ amount, price }) => Number(amount) * Number(price)).reduce((a, b) => a + b, 0));
                    var total = booking_car_types.map(({ cost }) => Number(cost) || 0).reduce((a, b) => a + b, 0) + extra;

                    this.selected_billing.discount = (Number(discount) || 0) * deposit_percent;
                    this.selected_billing.extra_sum = sum_extra * deposit_percent
                    let new_inc_vat = inc_vat? (total - extra) * 0.07 : 0;
                    let new_withholding = (withholding? ((total - extra) * 0.01) : 0);

                    if(this.arrayBillingInQuotation[0].billing_status_id == 7 && (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && (this.selected_billing.billing_status_id == 2 || this.selected_billing.billing_status_id > 7) && withholding){
                        deposit_percent = (total_price-sum_extra+(((Number(original_price)*0.01)-(Number(Number(this.arrayBillingInQuotation[0].total_price)+Number(discount))*100/99)*0.01) || 0)) / original_price
                        new_withholding = withholding? ((Number(original_price)*0.01)-(Number(Number(this.arrayBillingInQuotation[0].total_price)+Number(discount))*100/99)*0.01):0
                    }else if(this.arrayBillingInQuotation[0].billing_status_id == 7 && (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && (this.selected_billing.billing_status_id == 2 || this.selected_billing.billing_status_id > 7) && inc_vat){
                        deposit_percent = (total_price-sum_extra-(((Number(original_price)*0.07)-(Number(Number(this.arrayBillingInQuotation[0].total_price)+Number(discount))*100/107)*0.07) || 0)) / original_price
                        new_inc_vat = inc_vat? ((Number(original_price)*0.07)-(Number(Number(this.arrayBillingInQuotation[0].total_price)+Number(discount))*100/107)*0.07):0
                    }

                    if (this.selected_billing.inc_vat) {
                        this.selected_billing.inc_vat += new_inc_vat;
                    } else {
                        this.selected_billing.inc_vat = new_inc_vat;
                    }

                    if (this.selected_billing.withholding) {
                        this.selected_billing.withholding += new_withholding;
                    } else {
                        this.selected_billing.withholding = new_withholding;
                    }

                    deposit_percent_list.push(deposit_percent);
                });
                this.selected_billing.deposit_percent = deposit_percent_list
                await this.showSelectSale('invoice')
                this.get_invoice = true
                setTimeout(() => {
                    this.get_invoice = false
                }, 1000);
                
            }

            // console.log({
            //     inc_vat: this.selected_billing.inc_vat,
            //     withholding: this.selected_billing.withholding,
            //     extra_sum: this.selected_billing.extra_sum,
            //     total_price: this.selected_billing.total_price,
            //     discount: this.selected_billing.booking.discount
            // });
        },
        async create_receipt(fake, guarantee) {
            if (!this.export_state) {
                this.export_state = JSON.parse(JSON.stringify(this.selected_billing))
            }
            
            if (fake) {
                const booking_id = this.selectedRowKeys[0]
                const target = this.billable_bookings.find(x => x.id == booking_id)
                const { inc_vat, withholding, car_company, extras, discount } = target
                const sum_extra = extras.map(({ price, amount }) => Number(price) * Number(amount)).reduce((a, b) => a + b, 0)
                // const sum = target.booking_car_types.map(({ cost }) => cost).reduce((a, b) => a + b, 0)
                let sum = 0;
                target.booking_car_types.forEach(({ cost }) => {
                    sum += Number(cost) || 0;
                });
                const car_cost_excluded = target.booking_car_types.map(({ cost, expenses }) => {
                    const sum_expenses = expenses.reduce((a, b) => a + ((Number(b.price) || 0) * (Number(b.amount) || 0)), 0);
                    return (Number(cost) || 0) - sum_expenses;
                }).reduce((a, b) => a + b, 0);

                this.selected_billing = { booking: target, company: target.company, stops: target.booking_cars.map(({ stops }) => stops).flat() }
                this.selected_billing.billing_no = target.billings[0]?.billing_no
                this.selected_billing.billing_payments = []
                this.selected_billing.inc_vat = inc_vat? sum * 0.07 : 0
                this.selected_billing.withholding = withholding? (car_cost_excluded * this.withholding_type(car_company)) : 0
                this.selected_billing.extra_sum = sum_extra
                this.selected_billing.total_price = (sum + (Number(this.selected_billing.inc_vat ?? 0) + Number(sum_extra ?? 0) - Number(discount ?? 0)))
                    // - this.selected_billing.withholding


                await this.showSelectSale('receipt')


                this.get_fake_receipt = true

            } else if (guarantee) {
                const { amount } = this.selected_billing
                this.selected_billing.company = this.selected_billing.booking.company
                this.selected_billing.total_price = amount
                this.selected_billing.is_guarantee = true
      
                await this.showSelectSale('receipt')
                this.get_receipt = true

            } else {
                const deposit_percent_list = [];
                this.selected_billing.inc_vat = 0
                this.selected_billing.withholding = 0
                this.selected_billing.booking.forEach((booking, index) => {
                    const { inc_vat, withholding, car_company, extras, discount, booking_car_types } = booking;
                    const total_price = Number(this.selected_billing.total_price[index]) || 0;
                    const sum_extra = extras.map(({ price, amount }) => (Number(price) || 0) * (Number(amount) || 0)).reduce((a, b) => a + b, 0);
                    const original_price = booking_car_types.map(({ cost }) => Number(cost) || 0).reduce((a, b) => a + b, 0);
                    const car_cost_excluded = booking_car_types.map(({ cost, expenses }) => {
                        const sum_expenses = expenses.reduce((a, b) => a + ((Number(b.price) || 0) * (Number(b.amount) || 0)), 0);
                        return (Number(cost) || 0) - sum_expenses;
                    }).reduce((a, b) => a + b, 0);
                    this.arrayBillingInQuotation = this.billings.filter((billing) => billing.booking_id == booking.id)
                                                        .sort((a, b) => a.id - b.id)
                    this.selected_billing.select_type_remaining = false;
                    let billingNoIndex = this.arrayBillingInQuotation.findIndex(
                        (billing) => billing.id === this.selected_billing.id)
                    if (billingNoIndex !== -1) {
                        let selectBiiling = this.arrayBillingInQuotation.slice(0, billingNoIndex + 1);
                        let selectBillingAmount = selectBiiling.reduce((sum, billing) => sum + parseFloat(billing.total_price), 0)
                        if( Math.round((original_price - Number(discount) + Number(sum_extra))+ (inc_vat? Number(0.07*Number(original_price)):0) - (withholding? Number(0.01*Number(original_price)):0)) - selectBillingAmount <= 0){
                            this.selected_billing.select_type_remaining = true;
                        }else{
                            this.selected_billing.select_type_remaining = false;
                        }
                    }
                    let deposit_percent = original_price === 0 ? 0 : 
                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && this.arrayBillingInQuotation[0].billing_status_id == 7 
                        && (this.selected_billing.billing_status_id == 2 || this.selected_billing.billing_status_id > 7) && inc_vat == 1 ?  
                        (total_price-sum_extra+(Number(discount)-((Number(original_price)*0.07)-Number(this.arrayBillingInQuotation[0].total_price)*0.07) || 0)) / original_price :

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && this.arrayBillingInQuotation[0].billing_status_id == 7 
                        && (this.selected_billing.billing_status_id == 2 || this.selected_billing.billing_status_id > 7) && withholding == 1 ? 
                        (total_price-sum_extra+(Number(discount)-((Number(original_price)*0.01)-Number(this.arrayBillingInQuotation[0].total_price)*0.01) || 0)) / original_price:

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && (this.selected_billing.billing_status_id ==2 || this.selected_billing.billing_status_id > 7) && inc_vat == 1 ? 
                        (total_price-sum_extra+(Number(discount)-(Number(original_price)*0.07) || 0)) / original_price  :

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && (this.selected_billing.billing_status_id ==2 || this.selected_billing.billing_status_id > 7) && withholding == 1 ? 
                        (total_price-sum_extra+(Number(discount)+(Number(original_price)*0.01) || 0)) / original_price:

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && (this.selected_billing.billing_status_id ==2 || this.selected_billing.billing_status_id > 7) && inc_vat != 1 ? 
                        (total_price-sum_extra+(Number(discount) || 0)) / original_price :

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && this.selected_billing.billing_status_id == 7 && inc_vat == 1? 
                        (((total_price- sum_extra +Number(discount))*100/107) || 0) / original_price :

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && this.selected_billing.billing_status_id == 7 && withholding == 1 ? 
                        (((total_price- sum_extra +Number(discount))*100/99) || 0) / original_price :

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && this.selected_billing.billing_status_id == 7 && inc_vat != 1 ? 
                        (total_price- sum_extra +(Number(discount)  || 0)) / original_price :

                        this.selected_billing.billing_type_id > 3 &&this.selected_billing.billing_status_id == 7 && inc_vat == 1? 
                        ((total_price+Number(discount))*100/107 || 0) / original_price :

                        this.selected_billing.billing_type_id > 3 &&this.selected_billing.billing_status_id == 7 && withholding == 1 ? 
                        ((total_price+Number(discount))*100/99 || 0) / original_price :

                        this.selected_billing.billing_type_id > 3 &&this.selected_billing.billing_status_id == 7 && inc_vat != 1 ? 
                        (total_price+(Number(discount) || 0)) / original_price : total_price / original_price;

                    const extra = Number(extras.map(({ amount, price }) => Number(amount) * Number(price)).reduce((a, b) => a + b, 0));
                    var total = booking_car_types.map(({ cost }) => Number(cost) || 0).reduce((a, b) => a + b, 0) + extra;

                    this.selected_billing.discount = (Number(discount) || 0) * deposit_percent;
                    this.selected_billing.extra_sum = sum_extra * deposit_percent
                    let new_inc_vat = inc_vat? (total - extra) * 0.07 : 0;
                    let new_withholding = (withholding? ((total - extra) * 0.01) : 0);

                    if(this.arrayBillingInQuotation[0].billing_status_id == 7 && (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && (this.selected_billing.billing_status_id == 2 || this.selected_billing.billing_status_id > 7) && withholding){
                        deposit_percent = (total_price-sum_extra+(((Number(original_price)*0.01)-(Number(Number(this.arrayBillingInQuotation[0].total_price)+Number(discount))*100/99)*0.01) || 0)) / original_price
                        new_withholding = withholding? ((Number(original_price)*0.01)-(Number(Number(this.arrayBillingInQuotation[0].total_price)+Number(discount))*100/99)*0.01):0
                    }else if(this.arrayBillingInQuotation[0].billing_status_id == 7 && (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && (this.selected_billing.billing_status_id == 2 || this.selected_billing.billing_status_id > 7) && inc_vat){
                        deposit_percent = (total_price-sum_extra-(((Number(original_price)*0.07)-(Number(Number(this.arrayBillingInQuotation[0].total_price)+Number(discount))*100/107)*0.07) || 0)) / original_price
                        new_inc_vat = inc_vat? ((Number(original_price)*0.07)-(Number(Number(this.arrayBillingInQuotation[0].total_price)+Number(discount))*100/107)*0.07):0
                    }

                    if (this.selected_billing.inc_vat) {
                        this.selected_billing.inc_vat += new_inc_vat;
                    } else {
                        this.selected_billing.inc_vat = new_inc_vat;
                    }

                    if (this.selected_billing.withholding) {
                        this.selected_billing.withholding += new_withholding;
                    } else {
                        this.selected_billing.withholding = new_withholding;
                    }

                    deposit_percent_list.push(deposit_percent);
                });
                this.selected_billing.deposit_percent = deposit_percent_list
                       
                await this.showSelectSale('receipt')
                this.get_receipt = true

                setTimeout(() => {
                    this.get_receipt = false
                }, 1000);
            }

            // console.log({
            //     inc_vat: this.selected_billing.inc_vat,
            //     withholding: this.selected_billing.withholding,
            //     extra_sum: this.selected_billing.extra_sum,
            //     total_price: this.selected_billing.total_price,
            //     discount: this.selected_billing.booking.discount
            // });
        },
        async create_delivery_note(guarantee, fake) {
            if (!this.export_state) {
                this.export_state = JSON.parse(JSON.stringify(this.selected_billing))
            }
            
            if (fake) {
                const booking_id = this.selectedRowKeys[0]
                const target = this.billable_bookings.find(x => x.id == booking_id)
                const { inc_vat, withholding, car_company, extras, discount } = target
                const sum_extra = extras.map(({ price, amount }) => Number(price) * Number(amount)).reduce((a, b) => a + b, 0)
                // const sum = target.booking_car_types.map(({ cost }) => cost).reduce((a, b) => a + b, 0)
                let sum = 0;
                target.booking_car_types.forEach(({ cost }) => {
                    sum += Number(cost) || 0;
                });
                const car_cost_excluded = target.booking_car_types.map(({ cost, expenses }) => {
                    const sum_expenses = expenses.reduce((a, b) => a + ((Number(b.price) || 0) * (Number(b.amount) || 0)), 0);
                    return (Number(cost) || 0) - sum_expenses;
                }).reduce((a, b) => a + b, 0);

                this.selected_billing = { booking: target, company: target.company, stops: target.booking_cars.map(({ stops }) => stops).flat() }
                this.selected_billing.billing_no = target.billings[0]?.billing_no
                this.selected_billing.billing_payments = []
                this.selected_billing.inc_vat = inc_vat? sum * 0.07 : 0
                this.selected_billing.withholding = withholding? (car_cost_excluded * this.withholding_type(car_company)) : 0
                this.selected_billing.extra_sum = sum_extra
                this.selected_billing.total_price = (sum + (Number(this.selected_billing.inc_vat ?? 0) + Number(sum_extra ?? 0) - Number(discount ?? 0)))
                    // - this.selected_billing.withholding


                await this.showSelectSale('deliverynote')


                this.get_fake_delivery_note = true

                setTimeout(() => {
                    this.get_fake_delivery_note = false
                }, 1000);

            } else if (guarantee) {
                const { amount } = this.selected_billing
                this.selected_billing.company = this.selected_billing.booking.company
                this.selected_billing.total_price = amount
                this.selected_billing.is_guarantee = true
      
                await this.showSelectSale('deliverynote')
                this.get_delivery_note = true

            } else {
                const deposit_percent_list = [];
                this.selected_billing.inc_vat = 0
                this.selected_billing.withholding = 0
                this.selected_billing.booking.forEach((booking, index) => {
                    const { inc_vat, withholding, car_company, extras, discount, booking_car_types } = booking;
                    const total_price = Number(this.selected_billing.total_price[index]) || 0;
                    const sum_extra = extras.map(({ price, amount }) => (Number(price) || 0) * (Number(amount) || 0)).reduce((a, b) => a + b, 0);
                    const original_price = booking_car_types.map(({ cost }) => Number(cost) || 0).reduce((a, b) => a + b, 0);
                    const car_cost_excluded = booking_car_types.map(({ cost, expenses }) => {
                        const sum_expenses = expenses.reduce((a, b) => a + ((Number(b.price) || 0) * (Number(b.amount) || 0)), 0);
                        return (Number(cost) || 0) - sum_expenses;
                    }).reduce((a, b) => a + b, 0);
                    this.arrayBillingInQuotation = this.billings.filter((billing) => billing.booking_id == booking.id)
                                                        .sort((a, b) => a.id - b.id)
                    this.selected_billing.select_type_remaining = false;
                    let billingNoIndex = this.arrayBillingInQuotation.findIndex(
                        (billing) => billing.id === this.selected_billing.id)
                    if (billingNoIndex !== -1) {
                        let selectBiiling = this.arrayBillingInQuotation.slice(0, billingNoIndex + 1);
                        let selectBillingAmount = selectBiiling.reduce((sum, billing) => sum + parseFloat(billing.total_price), 0)
                        if( Math.round((original_price - Number(discount) + Number(sum_extra))+ (inc_vat? Number(0.07*Number(original_price)):0) - (withholding? Number(0.01*Number(original_price)):0)) - selectBillingAmount <= 0){
                            this.selected_billing.select_type_remaining = true;
                        }else{
                            this.selected_billing.select_type_remaining = false;
                        }
                    }
                    let deposit_percent = original_price === 0 ? 0 : 
                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && this.arrayBillingInQuotation[0].billing_status_id == 7 
                        && (this.selected_billing.billing_status_id == 2 || this.selected_billing.billing_status_id > 7) && inc_vat == 1 ?  
                        (total_price-sum_extra+(Number(discount)-((Number(original_price)*0.07)-Number(this.arrayBillingInQuotation[0].total_price)*0.07) || 0)) / original_price :

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && this.arrayBillingInQuotation[0].billing_status_id == 7 
                        && (this.selected_billing.billing_status_id == 2 || this.selected_billing.billing_status_id > 7) && withholding == 1 ? 
                        (total_price-sum_extra+(Number(discount)-((Number(original_price)*0.01)-Number(this.arrayBillingInQuotation[0].total_price)*0.01) || 0)) / original_price:

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && (this.selected_billing.billing_status_id ==2 || this.selected_billing.billing_status_id > 7) && inc_vat == 1 ? 
                        (total_price-sum_extra+(Number(discount)-(Number(original_price)*0.07) || 0)) / original_price  :

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && (this.selected_billing.billing_status_id ==2 || this.selected_billing.billing_status_id > 7) && withholding == 1 ? 
                        (total_price-sum_extra+(Number(discount)+(Number(original_price)*0.01) || 0)) / original_price:

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && (this.selected_billing.billing_status_id ==2 || this.selected_billing.billing_status_id > 7) && inc_vat != 1 ? 
                        (total_price-sum_extra+(Number(discount) || 0)) / original_price :

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && this.selected_billing.billing_status_id == 7 && inc_vat == 1? 
                        (((total_price- sum_extra +Number(discount))*100/107) || 0) / original_price :

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && this.selected_billing.billing_status_id == 7 && withholding == 1 ? 
                        (((total_price- sum_extra +Number(discount))*100/99) || 0) / original_price :

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && this.selected_billing.billing_status_id == 7 && inc_vat != 1 ? 
                        (total_price- sum_extra +(Number(discount)  || 0)) / original_price :

                        this.selected_billing.billing_type_id > 3 &&this.selected_billing.billing_status_id == 7 && inc_vat == 1? 
                        ((total_price+Number(discount))*100/107 || 0) / original_price :

                        this.selected_billing.billing_type_id > 3 &&this.selected_billing.billing_status_id == 7 && withholding == 1 ? 
                        ((total_price+Number(discount))*100/99 || 0) / original_price :

                        this.selected_billing.billing_type_id > 3 &&this.selected_billing.billing_status_id == 7 && inc_vat != 1 ? 
                        (total_price+(Number(discount) || 0)) / original_price : total_price / original_price;

                    const extra = Number(extras.map(({ amount, price }) => Number(amount) * Number(price)).reduce((a, b) => a + b, 0));
                    var total = booking_car_types.map(({ cost }) => Number(cost) || 0).reduce((a, b) => a + b, 0) + extra;

                    this.selected_billing.discount = (Number(discount) || 0) * deposit_percent;
                    this.selected_billing.extra_sum = sum_extra * deposit_percent
                    let new_inc_vat = inc_vat? (total - extra) * 0.07 : 0;
                    let new_withholding = (withholding? ((total - extra) * 0.01) : 0);

                    if(this.arrayBillingInQuotation[0].billing_status_id == 7 && (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && (this.selected_billing.billing_status_id == 2 || this.selected_billing.billing_status_id > 7) && withholding){
                        deposit_percent = (total_price-sum_extra+(((Number(original_price)*0.01)-(Number(Number(this.arrayBillingInQuotation[0].total_price)+Number(discount))*100/99)*0.01) || 0)) / original_price
                        new_withholding = withholding? ((Number(original_price)*0.01)-(Number(Number(this.arrayBillingInQuotation[0].total_price)+Number(discount))*100/99)*0.01):0
                    }else if(this.arrayBillingInQuotation[0].billing_status_id == 7 && (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && (this.selected_billing.billing_status_id == 2 || this.selected_billing.billing_status_id > 7) && inc_vat){
                        deposit_percent = (total_price-sum_extra-(((Number(original_price)*0.07)-(Number(Number(this.arrayBillingInQuotation[0].total_price)+Number(discount))*100/107)*0.07) || 0)) / original_price
                        new_inc_vat = inc_vat? ((Number(original_price)*0.07)-(Number(Number(this.arrayBillingInQuotation[0].total_price)+Number(discount))*100/107)*0.07):0
                    }

                    if (this.selected_billing.inc_vat) {
                        this.selected_billing.inc_vat += new_inc_vat;
                    } else {
                        this.selected_billing.inc_vat = new_inc_vat;
                    }

                    if (this.selected_billing.withholding) {
                        this.selected_billing.withholding += new_withholding;
                    } else {
                        this.selected_billing.withholding = new_withholding;
                    }

                    deposit_percent_list.push(deposit_percent);
                });
                this.selected_billing.deposit_percent = deposit_percent_list

                await this.showSelectSale('deliverynote')
                this.get_delivery_note = true

                setTimeout(() => {
                    this.get_delivery_note = false
                }, 1000);
            }

            // console.log({
            //     inc_vat: this.selected_billing.inc_vat,
            //     withholding: this.selected_billing.withholding,
            //     extra_sum: this.selected_billing.extra_sum,
            //     total_price: this.selected_billing.total_price,
            //     discount: this.selected_billing.booking.discount
            // });
        },
        async create_invoice_and_delivery_note(guarantee, fake) {
            if (!this.export_state) {
                this.export_state = JSON.parse(JSON.stringify(this.selected_billing))
            }
            
            if (fake) {
                const booking_id = this.selectedRowKeys[0]
                const target = this.billable_bookings.find(x => x.id == booking_id)
                const { inc_vat, withholding, car_company, extras, discount } = target
                const sum_extra = extras.map(({ price, amount }) => Number(price) * Number(amount)).reduce((a, b) => a + b, 0)
                // const sum = target.booking_car_types.map(({ cost }) => cost).reduce((a, b) => a + b, 0)
                let sum = 0;
                target.booking_car_types.forEach(({ cost }) => {
                    sum += Number(cost) || 0;
                });
                const car_cost_excluded = target.booking_car_types.map(({ cost, expenses }) => {
                    const sum_expenses = expenses.reduce((a, b) => a + ((Number(b.price) || 0) * (Number(b.amount) || 0)), 0);
                    return (Number(cost) || 0) - sum_expenses;
                }).reduce((a, b) => a + b, 0);

                this.selected_billing = { booking: target, company: target.company, stops: target.booking_cars.map(({ stops }) => stops).flat() }
                this.selected_billing.billing_no = target.billings[0]?.billing_no
                this.selected_billing.billing_payments = []
                this.selected_billing.inc_vat = inc_vat? sum * 0.07 : 0
                this.selected_billing.withholding = withholding? (car_cost_excluded * this.withholding_type(car_company)) : 0
                this.selected_billing.extra_sum = sum_extra
                this.selected_billing.total_price = (sum + (Number(this.selected_billing.inc_vat ?? 0) + Number(sum_extra ?? 0) - Number(discount ?? 0)))
                    // - this.selected_billing.withholding


                await this.showSelectSale('invoiceanddeliverynote')


                this.get_invoice_and_delivery_note = true

            } else if (guarantee) {
                const { amount } = this.selected_billing
                this.selected_billing.company = this.selected_billing.booking.company
                this.selected_billing.total_price = amount
                this.selected_billing.is_guarantee = true
      
                await this.showSelectSale('invoiceanddeliverynote')
                this.get_invoice_and_delivery_note = true

            } else {
                const deposit_percent_list = [];
                this.selected_billing.inc_vat = 0
                this.selected_billing.withholding = 0
                this.selected_billing.booking.forEach((booking, index) => {
                    const { inc_vat, withholding, car_company, extras, discount, booking_car_types } = booking;
                    const total_price = Number(this.selected_billing.total_price[index]) || 0;
                    const sum_extra = extras.map(({ price, amount }) => (Number(price) || 0) * (Number(amount) || 0)).reduce((a, b) => a + b, 0);
                    const original_price = booking_car_types.map(({ cost }) => Number(cost) || 0).reduce((a, b) => a + b, 0);
                    const car_cost_excluded = booking_car_types.map(({ cost, expenses }) => {
                        const sum_expenses = expenses.reduce((a, b) => a + ((Number(b.price) || 0) * (Number(b.amount) || 0)), 0);
                        return (Number(cost) || 0) - sum_expenses;
                    }).reduce((a, b) => a + b, 0);
                    this.arrayBillingInQuotation = this.billings.filter((billing) => billing.booking_id == booking.id)
                                                        .sort((a, b) => a.id - b.id)
                    this.selected_billing.select_type_remaining = false;
                    let billingNoIndex = this.arrayBillingInQuotation.findIndex(
                        (billing) => billing.id === this.selected_billing.id)
                    if (billingNoIndex !== -1) {
                        let selectBiiling = this.arrayBillingInQuotation.slice(0, billingNoIndex + 1);
                        let selectBillingAmount = selectBiiling.reduce((sum, billing) => sum + parseFloat(billing.total_price), 0)
                        if( Math.round((original_price - Number(discount) + Number(sum_extra))+ (inc_vat? Number(0.07*Number(original_price)):0) - (withholding? Number(0.01*Number(original_price)):0)) - selectBillingAmount <= 0){
                            this.selected_billing.select_type_remaining = true;
                        }else{
                            this.selected_billing.select_type_remaining = false;
                        }
                    }
                    let deposit_percent = original_price === 0 ? 0 : 
                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && this.arrayBillingInQuotation[0].billing_status_id == 7 
                        && (this.selected_billing.billing_status_id == 2 || this.selected_billing.billing_status_id > 7) && inc_vat == 1 ?  
                        (total_price-sum_extra+(Number(discount)-((Number(original_price)*0.07)-Number(this.arrayBillingInQuotation[0].total_price)*0.07) || 0)) / original_price :

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && this.arrayBillingInQuotation[0].billing_status_id == 7 
                        && (this.selected_billing.billing_status_id == 2 || this.selected_billing.billing_status_id > 7) && withholding == 1 ? 
                        (total_price-sum_extra+(Number(discount)-((Number(original_price)*0.01)-Number(this.arrayBillingInQuotation[0].total_price)*0.01) || 0)) / original_price:

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && (this.selected_billing.billing_status_id ==2 || this.selected_billing.billing_status_id > 7) && inc_vat == 1 ? 
                        (total_price-sum_extra+(Number(discount)-(Number(original_price)*0.07) || 0)) / original_price  :

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && (this.selected_billing.billing_status_id ==2 || this.selected_billing.billing_status_id > 7) && withholding == 1 ? 
                        (total_price-sum_extra+(Number(discount)+(Number(original_price)*0.01) || 0)) / original_price:

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && (this.selected_billing.billing_status_id ==2 || this.selected_billing.billing_status_id > 7) && inc_vat != 1 ? 
                        (total_price-sum_extra+(Number(discount) || 0)) / original_price :

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && this.selected_billing.billing_status_id == 7 && inc_vat == 1? 
                        (((total_price- sum_extra +Number(discount))*100/107) || 0) / original_price :

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && this.selected_billing.billing_status_id == 7 && withholding == 1 ? 
                        (((total_price- sum_extra +Number(discount))*100/99) || 0) / original_price :

                        (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && this.selected_billing.billing_status_id == 7 && inc_vat != 1 ? 
                        (total_price- sum_extra +(Number(discount)  || 0)) / original_price :

                        this.selected_billing.billing_type_id > 3 &&this.selected_billing.billing_status_id == 7 && inc_vat == 1? 
                        ((total_price+Number(discount))*100/107 || 0) / original_price :

                        this.selected_billing.billing_type_id > 3 &&this.selected_billing.billing_status_id == 7 && withholding == 1 ? 
                        ((total_price+Number(discount))*100/99 || 0) / original_price :

                        this.selected_billing.billing_type_id > 3 &&this.selected_billing.billing_status_id == 7 && inc_vat != 1 ? 
                        (total_price+(Number(discount) || 0)) / original_price : total_price / original_price;

                    const extra = Number(extras.map(({ amount, price }) => Number(amount) * Number(price)).reduce((a, b) => a + b, 0));
                    var total = booking_car_types.map(({ cost }) => Number(cost) || 0).reduce((a, b) => a + b, 0) + extra;

                    this.selected_billing.discount = (Number(discount) || 0) * deposit_percent;
                    this.selected_billing.extra_sum = sum_extra * deposit_percent
                    let new_inc_vat = inc_vat? (total - extra) * 0.07 : 0;
                    let new_withholding = (withholding? ((total - extra) * 0.01) : 0);

                    if(this.arrayBillingInQuotation[0].billing_status_id == 7 && (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && (this.selected_billing.billing_status_id == 2 || this.selected_billing.billing_status_id > 7) && withholding){
                        deposit_percent = (total_price-sum_extra+(((Number(original_price)*0.01)-(Number(Number(this.arrayBillingInQuotation[0].total_price)+Number(discount))*100/99)*0.01) || 0)) / original_price
                        new_withholding = withholding? ((Number(original_price)*0.01)-(Number(Number(this.arrayBillingInQuotation[0].total_price)+Number(discount))*100/99)*0.01):0
                    }else if(this.arrayBillingInQuotation[0].billing_status_id == 7 && (this.selected_billing.billing_type_id == 5|| (this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining)) && (this.selected_billing.billing_status_id == 2 || this.selected_billing.billing_status_id > 7) && inc_vat){
                        deposit_percent = (total_price-sum_extra-(((Number(original_price)*0.07)-(Number(Number(this.arrayBillingInQuotation[0].total_price)+Number(discount))*100/107)*0.07) || 0)) / original_price
                        new_inc_vat = inc_vat? ((Number(original_price)*0.07)-(Number(Number(this.arrayBillingInQuotation[0].total_price)+Number(discount))*100/107)*0.07):0
                    }

                    if (this.selected_billing.inc_vat) {
                        this.selected_billing.inc_vat += new_inc_vat;
                    } else {
                        this.selected_billing.inc_vat = new_inc_vat;
                    }

                    if (this.selected_billing.withholding) {
                        this.selected_billing.withholding += new_withholding;
                    } else {
                        this.selected_billing.withholding = new_withholding;
                    }

                    deposit_percent_list.push(deposit_percent);
                });
                this.selected_billing.deposit_percent = deposit_percent_list
                
                await this.showSelectSale('invoiceanddeliverynote')
                this.get_invoice_and_delivery_note = true

                setTimeout(() => {
                    this.get_invoice_and_delivery_note = false
                }, 1000);
            }

            // console.log({
            //     inc_vat: this.selected_billing.inc_vat,
            //     withholding: this.selected_billing.withholding,
            //     extra_sum: this.selected_billing.extra_sum,
            //     total_price: this.selected_billing.total_price,
            //     discount: this.selected_billing.booking.discount
            // });
        },
        async update_booking(id,checkDel) {
            Swal.fire({
                title: 'คุณต้องการลบข้อมูลนี้ใช่หรือไม่?',
                text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
                cancelButtonText: 'ยกเลิก'
            }).then(async (result) => {
                this.$message.loading({ content: 'Loading...', duration: 0, key: 'update_booking' });
                if (result.isConfirmed && checkDel) {
                    await SystemService.update_all('Booking', { data: { billing_status: 0, finished: 0 } }, id);
                    await SystemService.update_all('BookingCar', { data: { status: 15 } }, checkDel.id);
                    this.$message.success({ content: 'ลบข้อมูลสำเร็จ', key: 'update_booking' });
                    await this.get_booking()
                }else if(result.isConfirmed){
                    await SystemService.update_all('Booking', { data: { billing_status: 0 } }, id);
                    this.$message.success({ content: 'ลบข้อมูลสำเร็จ', key: 'update_booking' });
                    await this.get_booking()
                }
            })
        },
        go_remove(id, modelname) {
            Swal.fire({
                title: 'คุณต้องการลบข้อมูลนี้ใช่หรือไม่?',
                text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
                cancelButtonText: 'ยกเลิก'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await SystemService.delete_all(modelname, id)
                    this.get_booking()
                    Swal.fire(
                        'ลบข้อมูลสำเร็จ!',
                        'ข้อมูลของคุณถูกลบแล้ว',
                        'success'
                    )
                }
            })
        },
        filtered_list (array) {
            const newArray = array.filter((item) => {
                if (this.search_booking_no) {
                    const bookingNoArray = Array.isArray(item.booking?.booking_no) 
                        ? item.booking.booking_no 
                        : [item.booking?.booking_no];
                    return bookingNoArray.includes(this.search_booking_no);
                } else if (this.search_client) {
                    return item.booking?.client_id == this.search_client
                } else if (this.search_biling_status) {
                    return item.billing_status_id == this.search_biling_status
                } else if(this.search_datetime){
                    const [time_start, time_end] = this.search_datetime.map(item => dayjs(item).startOf('day').unix())
                    if(this.search_biling_date_type == 'releaseDate'){
                        const issue = dayjs(item.issue_date).startOf('day').unix();
                        return  time_start <= issue && issue <= time_end;  
                    }else{
                        const expire = dayjs(item.expire_date).startOf('day').unix();
                        return  time_start <= expire && expire <= time_end;     
                    }
                }
                else {
                    return true
                }
            })
            return newArray
        },
        multi_billing_list (array) {
            return Object.values(array.reduce((acc, curr) => {
                const { 
                    admin_proved,
                    billing_no,
                    billing_payments,
                    billing_status,
                    billing_status_id,
                    billing_action_id,
                    billing_type,
                    billing_type_id,
                    booking,
                    booking_id,
                    company,
                    company_id,
                    created_at,
                    expire_date,
                    id,
                    project,
                    is_deposit,
                    issue_date,
                    total_price,
                    updated_at, } = curr;
                // If billing_no already exists in the accumulator
                if (acc[billing_no]) {
                    acc[billing_no].total_price.push(Number(total_price));  // Sum the total_price
                    acc[billing_no].booking.push(booking);    // Add booking_no to the list
                } else {
                    // If billing_no doesn't exist, create a new entry
                    acc[billing_no] = {
                        booking: [booking],  // Start with the first booking_no
                        total_price: [Number(total_price)],
                        billing_payments: [],
                        admin_proved,
                        billing_no,
                        billing_status,
                        billing_status_id,
                        billing_action_id,
                        billing_type,
                        billing_type_id,
                        booking_id,
                        company,
                        company_id,
                        created_at,
                        expire_date,
                        project,
                        id,
                        is_deposit,
                        issue_date,
                        updated_at,
                    };
                }
                return acc;
            }, {}));
        },
        filterBillingsUp(billing = []) {
            if (this.search_booking_no) {
                billing = billing.filter(( billBooking ) => {
                    return billBooking?.booking?.booking_no.includes(this.search_booking_no)||billBooking.billing_no.includes(this.search_booking_no)});
            }
            if (this.search_client) {
                billing = billing.filter(( billBooking ) => {
                    return billBooking.booking?.client?.id == this.search_client});
            }
            if(this.selected_action){
                billing = billing.filter(( billBooking ) => {
                    return billBooking?.billing_action_id == this.selected_action});
            }
            if(this.selected_company){
                billing = billing.filter(( billBooking ) => {
                    return billBooking?.booking?.car_company?.id == this.selected_company});
            }
            if(this.selected_project_id){
                billing = billing.filter(( billBooking ) => {
                    return billBooking?.booking.project_id == this.selected_project_id});
            }
            if(this.search_biling_status){
                billing = billing.filter((booking)=>{
                        const currentDate = dayjs();
                        const searchDate = dayjs(booking.expire_date);
                        const leftDays = currentDate.diff(searchDate, 'day');
                        if(this.search_biling_status == 3){
                            return [7,8,9].includes(booking.billing_status_id);
                        }else if(this.search_biling_status == 4){
                            return booking.billing_status_id == 6 && leftDays <=30
                        }else if(this.search_biling_status == 5){
                            return booking.billing_status_id == 6 && leftDays > 30
                        }else{
                            return booking.billing_status_id == this.search_biling_status
                        }
                    })
            }
            if (this.search_datetime) {
                const [time_start, time_end] = this.search_datetime.map(item => dayjs(item).startOf('day').unix())
                if(this.search_biling_date_type == 'releaseDate'){
                    billing = billing.filter(({ issue_date: issueDate }) => {
                        const issue = dayjs(issueDate).startOf('day').unix();
                        return (
                            (time_start <= issue && issue <= time_end)
                        );
                    });
                }else{
                    billing = billing.filter(({ expire_date: expireDate }) => {
                        const expire = dayjs(expireDate).startOf('day').unix();
                        return (
                            (time_start <= expire && expire <= time_end)
                        );
                    }); 
                }
            }
            return billing;
        },  
        filterOption (input, option) {
            return option.label.toUpperCase().indexOf(input.toUpperCase()) >= 0;
        },
        update_admin(id) {
            Swal.fire({
                title: `ยืนยันในฐานะ Admin ?`,
                text: "ยืนยันบิล!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ยืนยัน',
                cancelButtonText: 'ยกเลิก'
            }).then((result) => {
                if (result.isConfirmed) {
                    SystemService.update_all('Booking', { data: { admin_proved: 1 } }, id).then(res => {
                        if (res) {
                            this.init_modal_options()
                            this.init_company_list()
                            this.get_booking()
                            Swal.fire(
                                'สำเร็จ!',
                                'อัพเดทสถานะเป็น Admin สำเร็จ',
                                'success'
                            )
                        }
                    })
                }
            })
        },
        at_month(date) {
            return dayjs(date).format('MM/YYYY')
        },
        sum_payment(array) {
            return array.reduce((a, b) => a + (b['amount'] || 0), 0)
        },
        edit_billing(record) {
            this.$router.push(`/app/billing-payment/${record.id}`)
        },
        delete_guarantee (id) {
            Swal.fire({
                title: `คุณต้องการลบบิลนี้หรือไม่?`,
                text: "คุณจะไม่สามารถกู้คืนได้หากลบแล้ว!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ยืนยัน',
                cancelButtonText: 'ยกเลิก'
            }).then((result) => {
                if (result.isConfirmed) {
                    SystemService.delete_all('Guarantee', id)
                        .then(res => {
                            this.get_booking()
                            Swal.fire(
                                'ลบบิลเสร็จสิ้น!',
                                'ข้อมูลของคุณถูกลบแล้ว',
                                'success'
                            )
                        })
                }
            })
        },
        delete_billing(id) {
            Swal.fire({
                title: `คุณต้องการลบบิลนี้หรือไม่?`,
                text: "คุณจะไม่สามารถกู้คืนได้หากลบแล้ว!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ยืนยัน',
                cancelButtonText: 'ยกเลิก'
            }).then((result) => {
                if (result.isConfirmed) {
                    const delete_bill = this.billings.find(bill => bill.id == id)
                    const all_billing_no = this.billings.filter(bill => bill.billing_no == delete_bill.billing_no)
                    const deletePromises = all_billing_no.map(billing => {
                        const id = billing.id;
                        return SystemService.delete_all('Billing', id);
                    });

                    Promise.all(deletePromises)
                        .then(results => {
                            const allSuccessful = results.every(res => res); // Check if all results are successful
                            if (allSuccessful) {
                                this.$message.success('ลบบิลสำเร็จ');
                                this.get_booking();
                            }
                        })
                        .catch(error => {
                            this.$message.error('เกิดข้อผิดพลาดในการลบบิล');
                            console.error(error);
                        });
                    // SystemService.delete_all('Billing', id)
                    //     .then(res => {
                    //         this.get_booking()
                    //         Swal.fire(
                    //             'ลบบิลเสร็จสิ้น!',
                    //             'ข้อมูลของคุณถูกลบแล้ว',
                    //             'success'
                    //         )
                    //     })
                }
            })
        },
        approve(id, status) {
            const selectedRowBookingId = this.selected_billing.booking_id;
            // Filter the billings based on bill_status_id and booking_id
            const filteredBillings = this.billings.filter(billing => {
                return billing.booking_id === selectedRowBookingId;
            });
            const totalPrice = filteredBillings.reduce((sum, billing) => sum + Number(billing.total_price), 0);
            if ( status == 7 ) {
                if (totalPrice > 0 && this.selected_billing.billing_type_id == 6) {
                    status = 9
                } else {
                    if (this.selected_billing.billing_type_id == 5 || this.selected_billing.billing_type_id == 2) {
                        status = 7
                    } else {
                        status = 8
                    }
                }
                const bill_no = this.selected_billing.billing_no
                const all_billing_no = this.billings.filter(bill => bill.billing_no == bill_no)
                const updatePromises = all_billing_no.map(billing => {
                    const id = billing.id;
                    return SystemService.update_all('Billing', { data: { billing_status_id: status } }, id);
                });

                Promise.all(updatePromises)
                    .then(results => {
                        const allSuccessful = results.every(res => res); // Check if all results are successful
                        if (allSuccessful) {
                            this.$message.success('อัพเดทสถานะสำเร็จ');
                            this.get_booking();
                        }
                    })
                    .catch(error => {
                        this.$message.error('เกิดข้อผิดพลาดในการอัพเดทสถานะ');
                        console.error(error);
                    });
            } else {
                const bill_no = this.selected_billing.billing_no
                const all_billing_no = this.billings.filter(bill => bill.billing_no == bill_no)
                const updatePromises = all_billing_no.map(billing => {
                    const id = billing.id;
                    return SystemService.update_all('Billing', { data: { billing_status_id: status } }, id);
                });

                Promise.all(updatePromises)
                    .then(results => {
                        const allSuccessful = results.every(res => res); // Check if all results are successful
                        if (allSuccessful) {
                            this.$message.success('อัพเดทสถานะสำเร็จ');
                            this.get_booking();
                        }
                    })
                    .catch(error => {
                        this.$message.error('เกิดข้อผิดพลาดในการอัพเดทสถานะ');
                        console.error(error);
                    });
            }
            
        },
        select_row(selectedRowKeys) {
            this.selected_billing = selectedRowKeys
            this.billing_payments = this.billings.filter(({ booking_id }) => booking_id == selectedRowKeys.booking_id).map(({ billing_payments }) => billing_payments).flat()
        },
        render_time(datetime) {
            return dayjs(datetime).format('HH:mm')
        },
        render_date_billing(date,time) {
            if (!date || !time || typeof date !== "string" || typeof time !== "string") {
                return "-";
            }

            try {
                const datetime = `${date.slice(0, 10)} ${time.slice(11, 16)}`;
                return dayjs(datetime).format("DD/MM/YYYY HH:mm");
            } catch (error) {
                return "-";
            }
        },
        render_date(datetime) {
            return dayjs(datetime).format('DD/MM/YYYY')
        },
        show_name_from_id(array, id) {
            return array.find(el => el.id == id)?.name
        },
        add_extras() {
            this.extra_form.extras_list.push({
                extra_id: this.extra_id,
                price: this.price
            })
            this.extra_id = null
            this.price = 0
        },
        delete_table_el(target_list, index) {
            target_list.splice(index, 1)
        },
        recalculate_bill_amount(value) {
            this.select_remain = this.select_total_remaining
            const result = Number(((Number(this.select_total_car_remaining)) * Number(value)).toFixed(0))
            this.select_total_amount = Number((this.total_booking_car_types - result).toFixed(0))
            this.select_remain -= result
            this.create_billing_form.billing_amount = result

        },
        recalculate_multiple_bill_amount(value) {
            const result = Number(((Number(this.create_multiple_billing_form.remaining_amount)) * Number(value)).toFixed(0))
            this.create_multiple_billing_form.billing_amount = result

        },
        calculate_bill_amount() {
            this.select_remain = this.select_total_remaining
            this.select_remain -= this.create_billing_form.billing_amount
            if(this.create_billing_form.billing_amount == this.select_total_remaining
            ||this.create_billing_form.billing_amount == this.select_remaining_amount){
                this.select_remain = this.select_remaining_amount
                this.create_billing_form.billing_amount = this.select_remaining_amount
                this.create_billing_form.bill_type = 1
            }
        },
        show_create_billing_modal(selectedRowKeys) {
            let dataSelect = this.billable_bookings.filter((e)=> e.id == selectedRowKeys[0])
            let quotationVat
            if(JSON.parse(dataSelect[0].qt_setting)){
                quotationVat = JSON.parse(dataSelect[0].qt_setting).total_price
            }
            this.select_remaining_amount = Math.round((this.sum_car_price(dataSelect[0], 'booking_car_types') + 
                this.sum_car_price(dataSelect[0], 'extras') + this.sum_car_price(dataSelect[0], 'vat') - 
                this.sum_car_price(dataSelect[0], 'withholding') - Number(dataSelect[0].discount)) 
                - dataSelect[0].total_billing )
            this.select_extras_amount = this.sum_car_price(dataSelect[0], 'extras')
            this.select_remain = this.select_remaining_amount
            this.select_discount_amount = Number(dataSelect[0].discount)
            this.total_booking_car_types = this.sum_car_price(dataSelect[0], 'booking_car_types')
            this.select_total_car_remaining =  this.total_booking_car_types - dataSelect[0].total_billing
            this.select_total_remaining = Math.round(this.sum_car_price(dataSelect[0], 'booking_car_types') - dataSelect[0].total_billing)
            if(JSON.parse(dataSelect[0].qt_setting)&&!!JSON.parse(dataSelect[0].qt_setting).pay_amount){
                this.select_total_remaining -= (JSON.parse(dataSelect[0].qt_setting).pay_amount - JSON.parse(dataSelect[0].qt_setting).total_price)
            }
            this.create_billing_modal = true;
        },
        show_create_multiple_billing_modal(selectedRowKeys,step) {
            this.create_multiple_billing_modal = true;
            this.disablefield = false
            this.steppop = step
            this.clear_create_multiple_billing()
            if(selectedRowKeys.length > 0) {
                let dataSelect = this.billable_bookings.filter((e)=> selectedRowKeys.includes(e.id))
                let clientNames = dataSelect.map((e) => e.client.fullname);
                let carCompanyNames = dataSelect.map((e) => e.client.car_company_id ? e.client.car_company.fullname : null);
                let areCarCompanyNamesDifferent = new Set(carCompanyNames).size !== 1;
                let areClientNamesDifferent = new Set(clientNames).size !== 1;
                // console.log('999',dataSelect[0])
                this.disablefield = true 
                this.check_already_create = 0;
                if ((areClientNamesDifferent === false && step === 2) || (areCarCompanyNamesDifferent === false && step === 1)) {
                    let timeStart = dataSelect.reduce((min, current) => {
                        return (moment(current.time_start).isBefore(moment(min)) ? current.time_start : min);
                    }, dataSelect[0].time_start);

                    let timeEnd = dataSelect.reduce((max, current) => {
                        return (moment(current.time_end).isAfter(moment(max)) ? current.time_end : max);
                    }, dataSelect[0].time_end);

                    if (!moment.isMoment(timeStart) && !moment.isMoment(timeEnd)) {
                        timeStart = moment(timeStart);
                        timeEnd = moment(timeEnd);
                    }
                    if (step === 2) {
                        if (this.business_a) {
                            for(dataCheck of dataSelect){
                                if(dataCheck?.billings.some((blc)=>blc?.billing_action_id == 2)){
                                    this.check_already_create = 2
                                }
                            }
                            this.create_multiple_billing_form.company_id = dataSelect[0].client_id
                            this.create_multiple_billing_form.car_company_id = dataSelect[0].car_company_id;
                            const totalBillingCost = this.calculateTotalBilling(dataSelect[0]);
                            const totalBilledAmount = dataSelect[0]?.billings?.filter(billing => billing.booking_id === dataSelect[0].id)
                                .reduce((sum, billing) => sum + billing.total_price, 0) || 0;
                            this.create_multiple_billing_form.remaining_amount = totalBillingCost - totalBilledAmount
                            this.create_multiple_billing_form.billing_amount = this.create_multiple_billing_form.remaining_amount
                        } else {
                            this.create_multiple_billing_form.company_id = dataSelect[0].client_id
                        }
                    } if (step === 1) {
                        if (this.business_a) {
                            for(var dataCheck of dataSelect){
                                if(dataCheck?.billings?.some((blc)=>blc?.billing_action_id == 1)){
                                    this.check_already_create = 1
                                }
                            }
                            this.create_multiple_billing_form.company_id = dataSelect[0].client_id
                            this.create_multiple_billing_form.car_company_id = dataSelect[0].car_company_id;
                            var totalBillingCost = this.calculateTotalExpenseCar(dataSelect[0]);
                            // const totalBilledAmount = dataSelect[0]?.billings?.filter(billing => billing.booking_id === dataSelect[0].id)
                            //     .reduce((sum, billing) => sum + Number(billing.total_price), 0) || 0;
                            if(dataSelect[0]?.billings?.find((item)=>item.billing_action_id == 1)){
                                totalBillingCost = 0;
                            }
                            this.create_multiple_billing_form.remaining_amount = totalBillingCost
                            this.create_multiple_billing_form.billing_amount = this.create_multiple_billing_form.remaining_amount
                        } else {
                            this.create_multiple_billing_form.car_company_id = dataSelect[0].client.car_company_id
                        }
                    }
                    this.create_multiple_billing_form.project = dataSelect[0].project?.id ?? 0
                    this.create_multiple_billing_form.from = timeStart.clone();
                    this.create_multiple_billing_form.to = timeEnd.clone();
                    this.cal_multiple_bill()
                } else {
                    this.clear_create_multiple_billing();
                    this.cal_multiple_bill()
                    let timeStart = dataSelect.reduce((min, current) => {
                        return (moment(current.time_start).isBefore(moment(min)) ? current.time_start : min);
                    }, dataSelect[0].time_start);

                    let timeEnd = dataSelect.reduce((max, current) => {
                        return (moment(current.time_end).isAfter(moment(max)) ? current.time_end : max);
                    }, dataSelect[0].time_end);

                    if (!moment.isMoment(timeStart) && !moment.isMoment(timeEnd)) {
                        timeStart = moment(timeStart);
                        timeEnd = moment(timeEnd);
                    }
                    this.create_multiple_billing_form.car_company_id = dataSelect[0].car_company_id;
                    this.create_multiple_billing_form.from = timeStart.clone();
                    this.create_multiple_billing_form.to = timeEnd.clone();
                    this.create_multiple_billing_form.company_id = dataSelect[0].client_id
                    this.create_multiple_billing_form.project = dataSelect[0].project?.id ?? 0
                    if (step === 2) {
                        if (this.business_a) {
                            for(var dataCheck of dataSelect){
                                if(dataCheck?.billings?.some((blc)=>blc?.billing_action_id == 2)){
                                    this.check_already_create = 2
                                }
                            }
                        }
                    }else if (step === 1) {
                        if (this.business_a) {
                            for(var dataCheck of dataSelect){
                                if(dataCheck?.billings?.some((blc)=>blc?.billing_action_id == 1)){
                                    this.check_already_create = 1
                                }
                            }
                        }
                    }
                }
            }
        },
        clear_create_multiple_billing() {
            this.create_multiple_billing_form.company_id = null
            this.create_multiple_billing_form.car_company_id = null
            this.create_multiple_billing_form.project = null
            this.create_multiple_billing_form.from = null,
            this.create_multiple_billing_form.to = null,
            this.create_multiple_billing_form.remaining_amount = 0
            this.create_multiple_billing_form.billing_amount = 0
            this.create_multiple_billing_form.bill_type = 1
            this.create_multiple_billing_form.billing_date = null
            this.create_multiple_billing_form.credit_duration = 0
            this.create_multiple_billing_form.sale_id = null
        },
        validateBillingAmount(rule, value) {
            return new Promise((resolve, reject) => {
                if (this.select_total_remaining - this.create_billing_form.billing_amount < 0 
                && this.create_billing_form.billing_amount < this.select_remaining_amount) {
                    reject(new Error(`กรุณาใส่ยอดจำนวนเงิน ไม่เกิน ${this.select_total_remaining}`));  // Custom error message when A - B < 0
                } else {
                    resolve();  // Validation passed
                }
            });
        },
        async exportCarExcel(selectedData,typeId){
            var exportData = null
            var headers = {}
            if(selectedData!=null){
                if(typeId == 1){
                    exportData = await BillingService.get_export_billings(this.billings.find((item)=>item.id==selectedData).billing_no);
                    if(this.businessTypeId == 4){
                        headers = {
                        booking_no:'Booking no',
                        pick_up_date:'วันที่รับ',
                        delivery_date:'วันที่ส่ง',
                        customer_name:'ชื่อลูกค้า',
                        project_name:'ชื่อโปรเจค',
                        project_type:'ประเภทโปรเจค',
                        origin:'ต้นทาง (สายงาน)',
                        destination:'ปลายทาง (สายงาน)',
                        trip_distance:'ระยะทางต้นทาง-ปลายทาง',
                        truck_type:'ชนิดรถ',
                        vehicle_plate:'ทะเบียนรถ',
                        supplier:'ชื่อรถซัพ',
                        driver_name:'ชื่อคนขับ',
                        tel_no:'เบอร์โทร',
                        actual_standby:'สแตนบายจริง',
                        out_for_delivery:'เริ่มออกจากต้นทาง',
                        delivered:'ถึงปลายทาง',
                        actual_runtime:'ชั่วโมงวิ่งจริง',
                        late_record:'ชั่วโมงเลท',
                        job_completed_time:'เวลาจบงานจริง',
                        selling_per_trip:'ราคาวางบิลลูกค้า',
                        additional_selling:'ราคาวางบิลอื่นๆ (ถ้ามี)',
                        cancellation_fee:'ยกเลิกการใช้รถ',
                        total_selling:'ราคาวางบิลรวม',
                        remark:'หมายเหตุ',
                        book_by:'จองโดยใคร',
                        booking_date:'วันที่ เวลาจอง',
                        }
                    };
                    if(this.businessTypeId != 4){
                        headers = {
                        billing_no: 'Billing no',
                        booking_no:'Booking no',
                        pick_up_date:'วันที่รับสินค้า',
                        pick_up_time:'เวลารับสินค้า',
                        running_time:'ระยะเวลาวิ่งส่งงาน',
                        delivery_date:'วันที่ส่งสินค้า',
                        delivery_time:'เวลาส่งสินค้า',
                        customer_name:'ชื่อลูกค้า',
                        project_name:'ชื่อโปรเจค',
                        project_type:'ประเภทโปรเจค',
                        custom_order_no:'เลขที่จ๊อบสั่งงานจองลูกค้า(ถ้ามี)',
                        booking_type:'ประเภทของการขนส่ง',
                        origin:'ต้นทาง',
                        destination:'ปลายทาง',
                        trip_distance:'ระยะทางต้นทาง-ปลายทาง',
                        truck_type:'ชนิดรถ',
                        vehicle_plate:'ทะเบียนรถ',
                        dock_plate:'ทะเบียนหาง (ถ้ามี)',
                        supplier:'ชื่อรถซัพ',
                        driver_name:'ชื่อคนขับ',
                        tel_no:'เบอร์โทร',
                        actual_standby:'สแตนบายจริง',
                        out_for_delivery:'เริ่มออกจากต้นทาง',
                        delivered:'ถึงปลายทาง',
                        actual_runtime:'ชั่วโมงวิ่งจริง',
                        late_record:'ชั่วโมงเลท',
                        job_completed_time:'เวลาจบงานจริง',
                        doc_no:'เลขที่ใบงาน (ได้จากต้นทาง)',
                        selling_per_trip:'ราคาวางบิลลูกค้า',
                        additional_selling:'ราคาวางบิลอื่นๆ (ถ้ามี)',
                        return_cost:'ชาร์จสินค้ากลับ',
                        waiting_time_fee:'ชาร์จเพิ่มเสียเวลา',
                        overnight_charge:'ชาร์จค่าค้างคืน',
                        cancellation_fee:'ยกเลิกการใช้รถ',
                        total_selling:'ราคาวางบิลรวม',
                        remark:'หมายเหตุ',
                        book_by:'จองโดยใคร',
                        booking_date:'วันที่ เวลาจอง',
                    };
                    }
                    
                }else if(typeId == 2){
                    exportData = await BillingService.get_export_sub_billings(this.billings.find((item)=>item.id==selectedData).billing_no);
                    if(this.businessTypeId != 4) {
                    headers = {
                        billing_no: 'Billing no',
                        booking_no:'Booking no',
                        pick_up_date:'วันที่รับสินค้า',
                        pick_up_time:'เวลารับสินค้า',
                        running_time:'ระยะเวลาวิ่งส่งงาน',
                        delivery_date:'วันที่ส่งสินค้า',
                        delivery_time:'เวลาส่งสินค้า',
                        customer_name:'ชื่อลูกค้า',
                        project_name:'ชื่อโปรเจค',
                        project_type:'ประเภทโปรเจค',
                        custom_order_no:'เลขที่จ๊อบสั่งงานจองลูกค้า(ถ้ามี)',
                        booking_type:'ประเภทของการขนส่ง',
                        origin:'ต้นทาง',
                        destination:'ปลายทาง',
                        trip_distance:'ระยะทางต้นทาง-ปลายทาง',
                        truck_type:'ชนิดรถ',
                        vehicle_plate:'ทะเบียนรถ',
                        dock_plate:'ทะเบียนหาง (ถ้ามี)',
                        supplier:'ชื่อรถซัพ',
                        driver_name:'ชื่อคนขับ',
                        tel_no:'เบอร์โทร',
                        actual_standby:'สแตนบายจริง',
                        out_for_delivery:'เริ่มออกจากต้นทาง',
                        delivered:'ถึงปลายทาง',
                        actual_runtime:'ชั่วโมงวิ่งจริง',
                        late_record:'ชั่วโมงเลท',
                        job_completed_time:'เวลาจบงานจริง',
                        doc_no:'เลขที่ใบงาน (ได้จากต้นทาง)',
                        cost_per_trip:'ราคาจ่ายรถ',
                        fuel_charge:'ชาร์จค่าน้ำมัน',
                        toll_ways_charge:'ค่าทางด่วน',
                        labor_charge:'ค่าแรงงาน',
                        driver_charge:'ค่าจ้างคนขับ',
                        addition_cost:'ค่าใช้จ่ายอื่นๆ (ถ้ามี)',
                        total_cost:'ราคาจ่ายรวม',
                        remark:'หมายเหตุ',
                        book_by:'จองโดยใคร',
                        booking_date:'วันที่ เวลาจอง',
                    };
                }
                    if(this.businessTypeId == 4){
                        headers = {
                        booking_no:'Booking no',
                        pick_up_date:'วันที่รับ',
                        delivery_date:'วันที่ส่ง',
                        customer_name:'ชื่อลูกค้า',
                        project_name:'ชื่อโปรเจค',
                        project_type:'ประเภทโปรเจค',
                        origin:'ต้นทาง (สายงาน)',
                        destination:'ปลายทาง (สายงาน)',
                        trip_distance:'ระยะทางต้นทาง-ปลายทาง',
                        truck_type:'ชนิดรถ',
                        vehicle_plate:'ทะเบียนรถ',
                        supplier:'ชื่อรถซัพ',
                        driver_name:'ชื่อคนขับ',
                        tel_no:'เบอร์โทร',
                        actual_standby:'สแตนบายจริง',
                        out_for_delivery:'เริ่มออกจากต้นทาง',
                        delivered:'ถึงปลายทาง',
                        actual_runtime:'ชั่วโมงวิ่งจริง',
                        late_record:'ชั่วโมงเลท',
                        job_completed_time:'เวลาจบงานจริง',
                        cost_per_trip:'ราคาจ่ายรถ',
                        fuel_charge:'ชาร์จค่าน้ำมัน',
                        toll_ways_charge:'ค่าทางด่วน',
                        labor_charge:'ค่าแรงงาน',
                        driver_charge:'ค่าจ้างคนขับ',
                        addition_cost:'ค่าใช้จ่ายอื่นๆ (ถ้ามี)',
                        total_cost:'ราคาจ่ายรวม',
                        remark:'หมายเหตุ',
                        book_by:'จองโดยใคร',
                        booking_date:'วันที่ เวลาจอง',
                    };
                    }
                }
            }
            if(exportData){
                try {
                    const ws = xlsx.utils.json_to_sheet(exportData, { header: Object.keys(headers) });

                    const margin = 3;
                    ws["!cols"] = Object.keys(headers).map((key) => {
                        return key === "customer_name"
                            ? { wch: 45 }
                            : key === "origin" || key === "destination"
                            ? { wch: 60 }
                            : key === "supplier" || key === "book_by"
                            ? { wch: 20 }
                            : key === "driver_name" || key === "tel_no"
                            ? { wch: 15 }
                            :{ wch: headers[key].length + margin }; // Use the length of the corresponding value from `headers`
                    });

                    const range = xlsx.utils.decode_range(ws['!ref']);
                    const lastRow = range.e.r;
                    if(this.businessTypeId != 4){
                    for (let row = 2; row <= range.e.r + 1; row++) {
                        ws[`Y${row}`] = {
                            f: `TEXT(IF(X${row}<W${row},X${row}+1-W${row},X${row}-W${row}),"hh:mm")`
                        };
                        ws[`Z${row}`] = {
                            f: `IF(Y${row} - (E${row}/24) >= 0, TEXT(Y${row} - (E${row}/24), "hh:mm"), "-" & TEXT(ABS(Y${row} - (E${row}/24)), "hh:mm"))`
                        };
                        ws[`AI${row}`] = {
                            f: `SUM(AC${row}, AD${row}, AE${row}, AF${row}, AG${row}, AH${row})`
                        };
                    }
                } else if(this.businessTypeId == 4){
                    for (let row = 2; row <= range.e.r + 1; row++) {
                        ws[`R${row}`] = {
                            f: `TEXT(IF(X${row}<W${row},X${row}+1-W${row},X${row}-W${row}),"hh:mm")`
                        };
                        ws[`S${row}`] = {
                            f: `IF(Y${row} - (E${row}/24) >= 0, TEXT(Y${row} - (E${row}/24), "hh:mm"), "-" & TEXT(ABS(Y${row} - (E${row}/24)), "hh:mm"))`
                        };
                        ws[`x${row}`] = {
                            f: `SUM(AC${row}, AD${row}, AE${row}, AF${row}, AG${row}, AH${row})`
                        };
                    }
                }
                    for (let R = 0; R <= lastRow; R++) {
                        for (let C = 0; C <= range.e.c; C++) {
                            const cellAddress = xlsx.utils.encode_cell({ r: R, c: C });

                            // If the cell doesn't exist, create it as a blank cell
                            if (!ws[cellAddress]) {
                                ws[cellAddress] = { v: '' }; // Add a blank value to the cell
                            }

                            // Add border to all cells
                            if (!ws[cellAddress].s) ws[cellAddress].s = {};
                            ws[cellAddress].s.border = {
                                top: { style: "thin", color: { rgb: "000000" } },
                                bottom: { style: "thin", color: { rgb: "000000" } },
                                left: { style: "thin", color: { rgb: "000000" } },
                                right: { style: "thin", color: { rgb: "000000" } }
                            };

                            // Center alignment for all cells
                            ws[cellAddress].s.alignment = { horizontal: 'center', vertical: 'center' };
                        }
                    }

                    Object.values(headers).forEach((value, index) => {
                        const cellAddress = xlsx.utils.encode_cell({ c: index, r: 0 });
                        const cell = ws[cellAddress];
                        cell.v = value;

                        ws[cellAddress].s = {
                            fill: { fgColor: { rgb: 'FFF4CC' } }, // Green background
                            border: {
                                top: { style: "thin", color: { rgb: "000000" } },
                                bottom: { style: "thin", color: { rgb: "000000" } },
                                left: { style: "thin", color: { rgb: "000000" } },
                                right: { style: "thin", color: { rgb: "000000" } }
                            },
                            alignment: { horizontal: "center", vertical: "center" },
                            font: { bold: true }
                        };
                    });

                    ws['!ref'] = xlsx.utils.encode_range({
                        s: { r: 0, c: 0 },
                        e: { r: lastRow, c: range.e.c }
                    });

                    const wb = xlsx.utils.book_new();
                    if(typeId == 1){
                        xlsx.utils.book_append_sheet(wb, ws, 'รายละเอียดใบวางบิล');
                        xlsx.writeFile(wb, 'ตั้งวางบิลลูกค้า.xlsx');
                    }else if(typeId == 2){
                        xlsx.utils.book_append_sheet(wb, ws, 'รายละเอียดใบวางบิล');
                        xlsx.writeFile(wb, 'ตั้งเบิกจ่ายรถซัพ.xlsx');
                    }
                } catch (error) {
                    console.error('Error exporting file:', error);
                }
            }
        },
        formatBillingAmount(value) {
            if (!value) return '';
            return Number(value).toLocaleString('en-GB', { maximumFractionDigits: 0, minimumFractionDigits: 0 });
        },
        parseBillingAmount(value) {
            return value.replace(/,/g, ''); // Removes commas from formatted number
        },
        use_remaining_amount(option) {
            if (option == 1) {
                this.select_remain = this.select_total_remaining
                this.create_billing_form.billing_amount = this.create_billing_form.remaining_amount
            }
        },
        reset_create_multiple_billing_form() {
            this.create_multiple_billing_form = {
                company_id: null,
                from: null,
                to: null,
                remaining_amount: 0,
                billing_amount: 0,
                bill_type: 1,
                billing_date: null,
                credit_duration: 0,
                sale_id: null,
            }
        },
        cal_multiple_bill() {
            let filter_billable = this.billable_bookings;
            if (this.selectedRowKeys.length > 0) {
                filter_billable = filter_billable.filter(({ id }) => this.selectedRowKeys.includes(id));
            }
            if (this.create_multiple_billing_form.company_id) {
                filter_billable = filter_billable.filter(({ client }) => {
                    return client?.id === this.create_multiple_billing_form.company_id;
                });
            }
            
            if (this.create_multiple_billing_form.car_company_id) {
                if(this.business_a){
                    filter_billable = filter_billable.filter((booking) => {
                        return booking?.car_company_id === this.create_multiple_billing_form.car_company_id;
                    });
                }else{
                    filter_billable = filter_billable.filter(({ client }) => {
                        return client?.car_company_id === this.create_multiple_billing_form.car_company_id;
                    });
                }
            }
            if(this.create_multiple_billing_form.project){
                filter_billable = filter_billable.filter(({project})=>{
                    return project?.id === this.create_multiple_billing_form.project;
                })
            }
            if(this.create_multiple_billing_form.project == 0){
                filter_billable = filter_billable.filter(({project})=>{
                    return !project;
                })
            }
            if (this.create_multiple_billing_form.from) {
                const start = dayjs(this.create_multiple_billing_form.from).format('YYYY-MM-DD');
                
                if (this.create_multiple_billing_form.to) {
                    const end = dayjs(this.create_multiple_billing_form.to).format('YYYY-MM-DD');
                    filter_billable = filter_billable.filter(({ created_at, time_start, time_end }) => {
                        const createdAtDate = dayjs(created_at).format('YYYY-MM-DD');
                        const itemStartDate = dayjs(time_start).format('YYYY-MM-DD');
                        const itemEndDate = dayjs(time_end).format('YYYY-MM-DD');
                        return (
                            (createdAtDate >= start && createdAtDate <= end) ||
                            (itemStartDate >= start && itemEndDate <= end) ||
                            (itemStartDate >= start && itemEndDate == end)
                        );
                    });
                } else {
                    filter_billable = filter_billable.filter(({ created_at, time_start}) => {
                        const createdAtDate = dayjs(created_at).format('YYYY-MM-DD');
                        const itemStartDate = dayjs(time_start).format('YYYY-MM-DD');
                        return (
                            (createdAtDate == start) ||
                            (itemStartDate == start)
                        );
                    });
                }
            }
            const result = filter_billable
                .map(record => {
                    const totalPrice = Number(this.sum_car_price(record, 'total'));
                    const discount = Number(record.discount) || 0;
                    const totalBilling = Number(record.total_billing) || 0;
                    const qtSettingTotalPrice = this.display_qt_setting(record, 'total_price');
                    const qtSetting = qtSettingTotalPrice != null ? Number(qtSettingTotalPrice) : 0;

                    const finalValue = Number((totalPrice - discount - qtSetting).toFixed(0)) - Number((totalBilling - qtSetting).toFixed(0));

                    return finalValue;
                })
                .reduce((a, b) => a + b, 0);
            this.multiple_billable_bookings = filter_billable;
            if(this.business_a){
                if(this.steppop == 1){
                    filter_billable = filter_billable.filter((item)=> {
                        return !item.billings?.some((billing) => billing.billing_action_id == 1)
                    })
                }else if (this.steppop == 2){
                    filter_billable = filter_billable.filter((item)=> {
                        return !item.billings?.some((billing) => billing.billing_action_id == 2)
                    })
                }
                var totalBillingCost = filter_billable.reduce((sum, item) => {
                    if(this.steppop == 1 && item?.billings?.find((items)=>items.billing_action_id == 1)){
                        return Number(sum)
                    }else if(this.steppop == 2 && item?.billings?.find((items)=>items.billing_action_id == 2)){
                        return Number(sum)
                    }else if(this.steppop == 1){
                        return Number(sum) + Number(this.calculateTotalExpenseCar(item));
                    }else if(this.steppop == 2){
                        return Number(sum) + Number(this.calculateTotalBilling(item));
                    }else{
                        return Number(sum)
                    }
                }, 0);
                this.create_multiple_billing_form.remaining_amount = Math.round(totalBillingCost);
                this.create_multiple_billing_form.billing_amount = this.create_multiple_billing_form.remaining_amount;
                this.multiple_billable_bookings = filter_billable;
            } else {
                this.create_multiple_billing_form.remaining_amount = Math.round(result);
                this.create_multiple_billing_form.billing_amount = this.create_multiple_billing_form.remaining_amount;
            }
        },
        async create_billings(billing_type, fixed_price) {
            if (billing_type == 1) {
                const { billing_date, credit_duration, sale_id, is_deposit } = this.create_billing_form
                this.extra_form = {
                    issue_date: billing_date,
                    expire_date: credit_duration,
                    sale_id: sale_id,
                    extras_list: [],
                    is_deposit: is_deposit
                }
            }
            var { issue_date, expire_date, sale_id, extras_list } = this.extra_form

            issue_date = issue_date? issue_date : dayjs().format('YYYY-MM-DD HH:mm:ss')

            const sum_bookings = this.billable_bookings.filter(({ id }) => this.selectedRowKeys.includes(id)).map(({ total_price }) => total_price).reduce((a, b) => a + b, 0)
            const sum_expense_list = this.extra_list.map(({ amount, price }) => (amount * price? price:0)).reduce((a, b) => a + b, 0)
            let calculated_total_price = {
                1: sum_bookings,
                2: extras_list.map(({ price }) => price).reduce((a, b) => a + b, 0),
                3: sum_expense_list
            }
            let final_total_price;
            if (this.create_billing_form.bill_type === 1) {
                final_total_price = this.select_remaining_amount;
            } else {
                final_total_price = fixed_price ? fixed_price : calculated_total_price[billing_type];
            }
            let dataSelect = this.billable_bookings.filter((e)=> e.id == this.selectedRowKeys[0])
            const body = {
                booking_id: this.selectedRowKeys,
                billing_type_id: final_total_price > this.select_remaining_amount ? 2 : (dataSelect[0].total_billing != 0 ? 6 : (this.create_billing_form.bill_type == 1 ? 5 : 4)),
                sale_id,
                issue_date: dayjs(issue_date).format('YYYY-MM-DD HH:mm:ss'),
                expire_date: dayjs(issue_date).add(expire_date > 0 ? expire_date : 30, 'days').format('YYYY-MM-DD HH:mm:ss'),
                total_price: final_total_price,
                is_deposit: this.create_billing_form.is_deposit? this.create_billing_form.is_deposit:false,
            }
            
            await BillingService.create_billing(body)
                .then(res => {
                    if (res.err) {
                        Swal.fire({
                            icon: 'error',
                            title: 'เกิดข้อผิดพลาด',
                            text: res.err
                        })
                    } else {
                        Swal.fire({
                            icon: 'success',
                            title: 'สร้างบิลสำเร็จ',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.create_billing_modal = false
                        this.extras_modal = false
                        this.expenses_modal = false
                        this.get_booking()
                        this.clear_extras()
                    }
                })

        },
        async create_multiple_billings() {
            this.create_multiple_billing_modal = false
            let total_billings_price = this.create_multiple_billing_form.remaining_amount;
            //this.cal_multiple_bill()
            const currentDate = dayjs().format('YYYY-MM-DD HH:mm:ss')
            const newBillingList = this.multiple_billable_bookings.map(booking => {
                const totalPrice = Number(this.sum_car_price(booking, 'total'));
                const discount = Number(booking.discount) || 0;
                const totalBilling = Number(booking.total_billing) || 0;
                const qtSettingTotalPrice = this.display_qt_setting(booking, 'total_price');
                const qtSetting = qtSettingTotalPrice != null ? Number(qtSettingTotalPrice) : 0;

                let calTotalPrice = Number((totalPrice - discount - qtSetting).toFixed(0)) - Number((totalBilling - qtSetting).toFixed(0));

                // If remaining total_billings_price can cover this booking's price
                if(this.business_a ){
                    const remainingTotal = total_billings_price;
                    total_billings_price -= total_billings_price;
                    return {
                        booking_id: booking.id,
                        billing_type_id: 5,
                        billing_action_id: this.steppop === 1 ? 1:2,
                        sale_id: this.create_multiple_billing_form.sale_id,
                        issue_date: dayjs(this.create_multiple_billing_form.billing_date).format('YYYY-MM-DD HH:mm:ss'),
                        expire_date: dayjs(this.create_multiple_billing_form.billing_date)
                            .add(this.create_multiple_billing_form.credit_duration > 0 ? this.create_multiple_billing_form.credit_duration : 30, 'days')
                            .format('YYYY-MM-DD HH:mm:ss'),
                        total_price: remainingTotal, 
                        is_deposit: this.create_billing_form.is_deposit || false, 
                    };
                }
                if (total_billings_price > calTotalPrice) {
                    total_billings_price -= calTotalPrice;  
                    return {
                        booking_id: booking.id,
                        billing_type_id: 5,
                        billing_action_id: this.steppop === 2 ? 2:1,
                        sale_id: this.create_multiple_billing_form.sale_id,
                        issue_date: dayjs(this.create_multiple_billing_form.billing_date).format('YYYY-MM-DD HH:mm:ss'),
                        expire_date: dayjs(this.create_multiple_billing_form.billing_date)
                            .add(this.create_multiple_billing_form.credit_duration > 0 ? this.create_multiple_billing_form.credit_duration : 30, 'days')
                            .format('YYYY-MM-DD HH:mm:ss'),
                        total_price: calTotalPrice, 
                        is_deposit: this.create_billing_form.is_deposit || false, 
                    };
                } else {
                    const remainingTotal = total_billings_price;
                    total_billings_price = 0;
                    return {
                        booking_id: booking.id,
                        billing_type_id: 5,
                        billing_action_id: this.steppop === 2 ? 2:1,
                        sale_id: this.create_multiple_billing_form.sale_id,
                        issue_date: dayjs(this.create_multiple_billing_form.billing_date).format('YYYY-MM-DD HH:mm:ss'),
                        expire_date: dayjs(this.create_multiple_billing_form.billing_date)
                            .add(this.create_multiple_billing_form.credit_duration > 0 ? this.create_multiple_billing_form.credit_duration : 30, 'days')
                            .format('YYYY-MM-DD HH:mm:ss'),
                        total_price: remainingTotal, 
                        is_deposit: this.create_billing_form.is_deposit || false, 
                    };
                }
            }).filter(booking => booking.total_price >= 0);

            if(this.check_already_create){
                this.showAlreadyModal = true;
                this.create_multiple_billing_modal = false;
            } else if ( this.create_multiple_billing_form.remaining_amount > 0 ) {
                const booking_ids = newBillingList
                            .map(item => item.booking_id)
                            .filter(id => id !== undefined && id !== null)
                if(this.business_a ){
                    if (this.steppop == 1) {
                        await Promise.all(booking_ids.map(async (booking_id) => {
                            await SystemService.update_all('Booking', { data: { sale_id: this.create_multiple_billing_form.sale_id, sale_stamp: currentDate, steppop1: 1 } }, booking_id);
                        }));
                        await BillingService.create_billing_list(newBillingList)
                        .then(res => {
                            if (res.err) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'เกิดข้อผิดพลาด',
                                    text: res.err
                                })
                                this.create_multiple_billing_modal = true
                            } else {
                                // console.log('aaaaaaa')
                                Swal.fire({
                                    icon: 'success',
                                    title: 'สร้างบิลสำเร็จ',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.reset_create_multiple_billing_form()
                                this.create_billing_modal = false
                                this.get_booking()
                            }
                        })
                    this.clear_extras()
                    }
                    if (this.steppop == 2) {
                        // console.log('bbb',newBillingList)
                        await Promise.all(booking_ids.map(async (booking_id) => {
                            await SystemService.update_all('Booking', { data: { sale_id: this.create_multiple_billing_form.sale_id, sale_stamp: currentDate, steppop2: 1 } }, booking_id);
                        }));
                        await BillingService.create_billing_list(newBillingList)
                        .then(res => {
                            if (res.err) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'เกิดข้อผิดพลาด',
                                    text: res.err
                                })
                                this.create_multiple_billing_modal = true
                            } else {
                                Swal.fire({
                                    icon: 'success',
                                    title: 'สร้างบิลสำเร็จ',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.reset_create_multiple_billing_form()
                                this.create_billing_modal = false
                                this.get_booking()
                            }
                        })
                    this.clear_extras()
                    }
                }
                if (!this.business_a) {
                    await BillingService.create_billing_list(newBillingList)
                        .then(res => {
                            if (res.err) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'เกิดข้อผิดพลาด',
                                    text: res.err
                                })
                                this.create_multiple_billing_modal = true
                            } else {
                                Swal.fire({
                                    icon: 'success',
                                    title: 'สร้างบิลสำเร็จ',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.reset_create_multiple_billing_form()
                                this.create_billing_modal = false
                                this.get_booking()
                            }
                        })
                    this.clear_extras()
                }
            } else {
                this.create_multiple_billing_modal = true
                Swal.fire({
                    icon: 'error',
                    title: 'ไม่พบข้อมูลลูกค้า/บริษัท',
                    text: `วันที่ ${dayjs(this.create_multiple_billing_form.from).format('DD/MM/YYYY')} - ${dayjs(this.create_multiple_billing_form.to).format('DD/MM/YYYY')}
                    กรุณาแก้ไขข้อมูลใหม่`
                })
            }
            
        },
        clear_extras() {
            this.extra_id = null
            this.price = 0
            this.extra_form = {
                extras_list: [],
                issue_date: null,
                expire_date: null,
            }
        },
        async init_modal_options() {
            this.extras_list = await SystemService.get_all('Extra')
            this.users_list = await UserService.get_users()
            const BILLING_STATUSES = [
                { id: 1, name: 'ใหม่' },
                { id: 2, name: 'กำลังดำเนินการ' },
                { id: 3, name: 'จ่ายแล้ว' },
                { id: 4, name: 'ยังไม่จ่าย' },
                { id: 5, name: 'เกินกำหนด' }
            ];
            this.billing_statuses = BILLING_STATUSES;
            // if(this.render_type == 'billing'){
            //     const BILLING_STATUSES = [
            //         { id: 1, name: 'ใหม่' },
            //         { id: 2, name: 'กำลังดำเนินการ' },
            //         { id: 3, name: 'จ่ายแล้ว' },
            //         { id: 4, name: 'ยังไม่ได้จ่าย' },
            //         { id: 5, name: 'เกินกำหนด' }
            //     ];
            //     this.billing_statuses = BILLING_STATUSES;
            // }else{
            //     this.billing_statuses = await SystemService.get_all('BillingStatus')
            // }

        },
        async init_company_list() {
            this.company_list = await SystemService.get_all('Client')
            this.sale_list = await SystemService.get_all('Sale')
            this.car_company_list = await CarCompanyService.get_car_companies();
            this.project_list = await ProjectService.getProjectAll();
        },
        async get_booking(render_type) {
            if (!render_type) {
                render_type = this.render_type
            }
            this.$message.loading({ content: 'Loading...', duration: 0, key: 'get_booking' });
            if (render_type == 'booking') {
                const billabled_booking = await BillingService.get_billable_bookings(this.pagination.onpage, this.pagination.perPage)
                const waiting_for_billing = await BillingService.get_waiting_for_billing(this.pagination.onpage, this.pagination.perPage)
                this.pagination = {
                    total: billabled_booking.total,
                    onpage: billabled_booking.page,
                    perPage: billabled_booking.perPage
                }
                this.billable_bookings = this.business_a ? waiting_for_billing : billabled_booking
                console.log(billabled_booking)
                console.log(waiting_for_billing)
            } else if (render_type == 'billing' || render_type == 'monthly-report') {
                const billings = await BillingService.get_billings(this.pagination.onpage, this.pagination.perPage)
                this.bank_list = await SystemService.get_all('Bank')
                this.pagination = {
                    total: billings.total,
                    onpage: billings.page,
                    perPage: billings.perPage
                }
                this.billings = billings
                this.billings = this.billings.sort((a, b) => b.id - a.id)
            } else if (render_type == 'guarantee') {
                this.guarantee = await SystemService.get_all('Guarantee')
            }
            // update selected bill data everytime that system get bills
            if (this.selected_billing && this.selected_billing.id) {
                if (this.render_type == 'billing') {
                    this.selected_billing = this.multi_billing_list(this.billings).find(({ id }) => id === this.selected_billing.id);
                } else {
                    this.selected_billing = this.billings.find(({ id }) => id === this.selected_billing.id);
                }
            }
            
            this.$message.success({ content: 'โหลดข้อมูลสำเร็จ', key: 'get_booking' });
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        onSaleChanged(v){
            this.selected_sale_id = v
            this.selected_sale = this.sale_list.find(x => x.id == v)
            this.selected_billing.booking[0].sale = this.selected_sale
        },
        async get_car_companies(){
            this.car_companies = await SystemService.get_all('CarCompany')
            // console.log("car_companies",this.car_companies[0]);
        },
    },
    watch: {
        render_type: {
            handler: function (val) {
                if (val) {
                    this.get_booking(val)
                }
                this.init_modal_options()
            },
            deep: true
        },
    },
    mounted() {
        this.init_modal_options()
        this.init_company_list()
        this.get_business_type();
        this.get_booking()
        this.get_car_companies()
        this.get_company();
    }
}
</script>

<style scope>
    .ant-modal-footer {
        display: flex;
        justify-content: flex-ends;
    }
    .ant-table-thead .center-header {
        text-align: center; /* จัดตำแหน่งหัวคอลัมน์ให้อยู่ตรงกลาง */
    }
    .disabled {
        pointer-events: none;
        color: gray;
        cursor: not-allowed;
    }

</style>