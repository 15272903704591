
let price_rate_car_rental_drawer_fields = {
    drawer_title:'ข้อมูลเรทราคา',
    drawer_button_text:'เพิ่มเรทราคา',
    drawer_button_text_edit:'แก้ไขเรทราคา',
    target_modelname:'PriceRate',
    columns: [
        {
            title: 'ลำดับ',
            dataIndex: 'index',
            key: 'index',
            width: 120,
        },
        {
            title: 'ต้นทาง (สายงาน)',
            dataIndex: 'start_place',
            key: 'start_place',
            width: 150,
        },
        {
            title: 'ประเภทเวลางาน',
            dataIndex: 'time_type',
            key: 'time_type',
            width: 150,
        },
        {
            title: 'กะเวลางาน',
            dataIndex: 'time_period',
            key: 'time_period',
            width: 120,
        },
        {
            title: 'วันที่',
            dataIndex: 'price_date',
            key: 'price_date',
            width: 120,
        },
        {
            title: 'เวลา',
            dataIndex: 'price_time',
            key: 'price_time',
            width: 120,
        },
        {
            title: 'ทะเบียนรถ',
            dataIndex: 'plate_no',
            key: 'plate_no',
            width: 120,
        },
        {
            title: 'คนขับ',
            dataIndex: 'driver_name',
            key: 'driver_name',
            width: 120,
        },
        {
            title: 'ราคาวางบิลลูกค้า',
            dataIndex: 'selling_per_trip',
            key: 'selling_per_trip',
            width: 150,
        },
        {
            title: 'ราคาว่าจ้างรถ',
            dataIndex: 'car_price_amount',
            key: 'car_price_amount',
            width: 120,
        },
        {
            title: 'ตัวเลือก',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            align: 'center',
            fixed: 'right'
        },
    ],
    drawer_fields: {
        inputs: {
            start_place: {
                input_type: 'text',
                placeholder: 'ต้นทาง (สายงาน)',
                required: true,
                col_span: 24,
            },
            time_type: {
                input_type: 'text',
                placeholder: 'ประเภทเวลางาน',
                required: false,
                col_span: 24,
            },
            time_period: {
                input_type: 'text',
                placeholder: 'กะเวลางาน',
                required: false,
                col_span: 24,
            },
            price_date: {
                input_type: 'date',
                placeholder: 'วันที่',
                required: false,
                col_span: 24,
            },
            price_time: {
                input_type: 'time',
                placeholder: 'เวลา',
                required: false,
                col_span: 24,
            },
            plate_no: {
                input_type: 'text',
                placeholder: 'ทะเบียนรถ',
                required: false,
                col_span: 24,
            },
            driver_name: {
                input_type: 'text',
                placeholder: 'คนขับ',
                required: false,
                col_span: 24,
            },
            selling_per_trip: {
                input_type: 'number',
                placeholder: 'ราคาวางบิลลูกค้า',
                required: false,
                col_span: 24,
            },
            car_price_amount: {
                input_type: 'number',
                placeholder: 'ราคาว่าจ้างรถ',
                required: false,
                col_span: 24,
            }
        },
    }
};

export default price_rate_car_rental_drawer_fields