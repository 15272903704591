<template>
    <div style="padding: 15px;height: 100vh">
        <div class="app-content">
            <a-card :title="topic[render_type].title">
                <template v-if="!business" #extra>
                    <a-space v-if="editable">
                        <div v-if="topic[render_type].claim" style="display: flex ; margin-right: 5px;">
                            <a-badge :count="countClaim">
                                <a-button v-if="topic[render_type].claim" type="primary" size="large" @click="go_claim()">
                                    {{ topic[render_type].claim }}
                                </a-button>
                            </a-badge>
                        </div>
                        <div v-if="topic[render_type].leave" style="display: flex ; margin-right: 5px;">
                            <a-badge :count="countLeave">
                                <a-button v-if="topic[render_type].leave" type="primary" size="large" @click="go_leave()">
                                    <!-- <template #icon><span class="material-symbols-outlined">Add</span></template> -->
                                    {{ topic[render_type].leave }}
                                </a-button>
                            </a-badge>
                        </div>
                        <a-button v-if="topic[render_type].button_title" style="display: flex"
                            type="primary" size="large" @click="go_create(true_render_type)">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            {{ topic[render_type].button_title }}
                        </a-button>

                        <a-dropdown v-if="topic[render_type].allow_import">
                            <template #overlay>
                                <a-menu @click="import_export_dropdown">
                                    <a-menu-item key="1">
                                        <a-upload
                                            style="display: flex"
                                            v-model:file-list="import_fileList"
                                            :show-upload-list="false"
                                            name="file"
                                            method="GET"
                                            @change="import_change"
                                        >
                                            <a-button ref="import_file">
                                                <span class="material-symbols-outlined">publish</span>Import
                                            </a-button>
                                        </a-upload>
                                    </a-menu-item>
                                    <a-menu-item key="2">
                                        <a-button ref="import_file">
                                            <span class="material-symbols-outlined">download</span>Export
                                        </a-button>
                                    </a-menu-item>
                                </a-menu>
                            </template>
                            <a-button style="display: flex" size="large">
                                <template #icon><span class="material-symbols-outlined">Settings</span></template>
                                ตัวเลือก
                            </a-button>
                        </a-dropdown>
                    </a-space>
                </template>
                <a-row v-if="business" style="display: flex; justify-content: flex-end; margin-bottom: 16px;">
                    <a-space v-if="editable">
                        <div v-if="topic[render_type].claim" style="display: flex ; margin-right: 5px;">
                            <a-badge :count="countClaim">
                                <a-button v-if="topic[render_type].claim" type="primary" size="large" @click="go_claim()">
                                    {{ topic[render_type].claim }}
                                </a-button>
                            </a-badge>
                        </div>
                        <div v-if="topic[render_type].leave" style="display: flex ; margin-right: 5px;">
                            <a-badge :count="countLeave">
                                <a-button v-if="topic[render_type].leave" type="primary" size="large" @click="go_leave()">
                                    <!-- <template #icon><span class="material-symbols-outlined">Add</span></template> -->
                                    {{ topic[render_type].leave }}
                                </a-button>
                            </a-badge>
                        </div>
                        <a-button v-if="topic[render_type].button_title" style="display: flex"
                            type="primary" size="large" @click="go_create(true_render_type)">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            {{ topic[render_type].button_title }}
                        </a-button>

                        <a-dropdown v-if="topic[render_type].allow_import">
                            <template #overlay>
                                <a-menu @click="import_export_dropdown">
                                    <a-menu-item key="1">
                                        <a-upload
                                            style="display: flex"
                                            v-model:file-list="import_fileList"
                                            :show-upload-list="false"
                                            name="file"
                                            method="GET"
                                            @change="import_change"
                                        >
                                            <a-button ref="import_file">
                                                <span class="material-symbols-outlined">publish</span>Import
                                            </a-button>
                                        </a-upload>
                                    </a-menu-item>
                                    <a-menu-item key="2">
                                        <a-button ref="import_file">
                                            <span class="material-symbols-outlined">download</span>Export
                                        </a-button>
                                    </a-menu-item>
                                </a-menu>
                            </template>
                            <a-button style="display: flex" size="large">
                                <template #icon><span class="material-symbols-outlined">Settings</span></template>
                                ตัวเลือก
                            </a-button>
                        </a-dropdown>
                    </a-space>
                </a-row>

                <a-row>
                    <a-space>
                        <a-select v-if="topic[render_type].select_client" style="width: 100%" v-model:value="search_car_company" placeholder="เลือกบริษัทรถ">
                            <a-select-option v-for="{ fullname, id } in car_company_list" :key="id" :value="id">{{ fullname }}
                            </a-select-option>
                        </a-select>
                        
                        <a-input-search v-if="topic[render_type].input" v-model:value="search_input"
                            :placeholder="`ค้นหา ${topic[render_type].input == 'ชื่อผู้ขับ'? 'ชื่อพนักงานขับรถ' :topic[render_type].input}` " enter-button="ค้นหา" style="width: 350px;" />

                        <a-select v-if="topic[render_type].select_expire" style="width: 100%" v-model:value="search_expire_type" placeholder="เลือกประเภทวันหมดอายุ">
                            <a-select-option v-for="{ text, value }, i in [
                                { text: 'วันหมดอายุก่อน 1 เดือน', value: 1 },
                                { text: 'วันหมดอายุก่อน 2 เดือน', value: 2 },
                                { text: 'วันหมดอายุก่อน 3 เดือน', value: 3 },
                            ]" :key="i" :value="value">{{ text }}
                            </a-select-option>
                        </a-select>
                        <a-button @click="search_input = null, search_car_company = null, search_expire_type = null " v-if="topic[render_type].clear" type="dashed">CLEAR</a-button>
                    </a-space>
                </a-row>

                <br>

                <a-row v-if="!business">
                    <a-space>
                        <a-button v-for="button, i in topic[render_type].lower_buttons" :key="i" style="display: flex"
                            type="primary" size="large" @click="button_action(button.name)">
                            {{ button.title }}
                        </a-button>
                    </a-space>

                </a-row>
                <br>
                <a-row>
                    <a-table style="width: 100%" :columns="filteredColumns()"
                        :data-source="filtered_table(show_table_list, { topic, render_type })" 
                        @change="onTableChange"
                        bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                        <template #bodyCell="{ column, record, index }">
                            <template v-if="column.key === 'id'">
                                <div>
                                    <a @click="go_edit(record, record.modelname)">
                                        <span v-if="render_type === 'Driver'" style="font-size: 18px" class="material-symbols-outlined">drive_file_rename_outline</span>
                                        <span v-else style="font-size: 18px" class="material-symbols-outlined">drive_file_rename_outline</span>
                                    </a>
                                    <a-divider type="vertical"></a-divider>
                                    <a @click="go_remove(record.id, record.modelname)"><span style="font-size: 18px" class="material-symbols-outlined">delete</span></a>
                                </div>
                            </template>
                            <template v-else-if="column.key == 'index'" >
                                {{index + 1}}
                            </template>
                            <template v-else-if="column.key == 'firstname_lastname'" >
                                <div>
                                    {{record.firstname }}
                                    <span style="margin-left: 3px;">
                                        {{ record.lastname }}
                                    </span>
                                </div>
                            </template>
                            <template v-else-if="column.key == 'odometer'" >
                                <div style="display: flex; align-items: center; justify-content: end;">
                                    <span style="font-size: 16px;">{{record[column.key]? record.odometer.toLocaleString():0}}</span>
                                    
                                    <a-divider type="vertical"></a-divider>
                                    <a-tooltip placement="topLeft" title="ล้างเลขไมล์">
                                        <a style="height: 16px;color: red" @click="clear_odo(record.id)" >
                                            <span style="font-size: 16px" class="material-symbols-outlined">restart_alt</span>
                                        </a>
                                    </a-tooltip>

                                    <a-divider type="vertical"></a-divider>
                                    <a-popover title="ประวัติเลขไมล์">
                                        <template #content>
                                            <span>รวมระยะทางทั้งหมด: {{ formatted_odos(record.odos).map(({ distance }) => distance()).reduce((a,b) => a+b, 0).toLocaleString() }} กม.</span><br>
                                            <span>การใช้น้ำมัน: {{ record.gas_refills.map(({ refill_amount }) => refill_amount).reduce((a,b) => a+b, 0) }} ลิตร</span><br>
                                            <span>เฉลี่ยระยะทาง/น้ำมัน (ลิตร): {{ 
                                                (formatted_odos(record.odos).map(({ distance }) => distance()).reduce((a,b) => a+b, 0)/
                                                record.gas_refills.map(({ refill_amount }) => refill_amount).reduce((a,b) => a+b, 0)).toLocaleString({ maximumFractionDigits: 2})
                                            }} กม./ลิตร</span><br>
                                            <a-divider></a-divider>
                                            <div style="max-height: 500px;overflow-y: scroll;">
                                                <p v-for="{ start, end, distance }, i in formatted_odos(record.odos)" :key="i">
                                                    <div style="display: flex;">
                                                        {{ render_date(start?.created_at) }}: <a-tag color="green">เริ่มต้น</a-tag> {{ start?.distance.toLocaleString() }} -
                                                        {{ render_date(end?.created_at) }}: <a-tag color="red">สิ้นสุด</a-tag> {{ end?.distance.toLocaleString() }}
                                                    </div>
                                                    <span>ระยะทาง: {{ distance().toLocaleString() }}</span>
                                                    <a-divider></a-divider>
                                                </p>
                                            </div>
                                        </template>
                                        <a style="height: 16px;"><span class="material-symbols-outlined" style="font-size: 16px">history</span></a>
                                    </a-popover>
                                </div>
                            </template>
                            <!-- <template v-else-if="column.key == 'repair_target' && record.repair_target != null" >
                                <a-badge-ribbon v-if="record.odometer >= record.repair_target" text="ซ่อม" color="red">
                                    <a-card :title="record.repair_target" size="small"></a-card>
                                </a-badge-ribbon>
                            </template> -->
                            <template v-else-if="column.key == 'driver'" >
                                {{record.driver?.firstname}} {{record.driver?.lastname}}
                            </template>
                            <!-- <template v-else-if="column.key == 'plate_no'" >
                                 {{record.plate_no1 ? record.plate_no1 : record.plate_no}}
                            </template> -->
                            <template v-else-if="column.key == 'car_company'" >
                                {{record.car_company?.fullname || ''}}
                            </template>
                            <template v-else-if="column.key == 'client'" >
                                {{record.client?.fullname || ''}}
                            </template>
                            <template v-else-if="column.key == 'leave_status'" >
                                {{ driverWorkStatuses[record.id]?.leave?.leaveName || '' }}
                            </template>
                            <template v-else-if="column.key == 'leave_date'" >
                                {{ this.formatDate(driverWorkStatuses[record.id]?.leave?.timeStart) || '' }}
                            </template>
                            <template v-else-if="column.key == 'status'" >
                                {{ driverWorkStatuses[record.id]?.name || 'Loading...' }}
                            </template>
                            <template v-else-if="column.key == 'client_branch'" >
                                {{record.client_branch?.name}}
                            </template>
                            <template v-else-if="column.key == 'car_status'" >
                                <template v-if="record.car_status_id == 1">
                                    <a-badge status="success" /> พร้อมใช้งาน
                                </template>
                                <template v-else>
                                    <a-badge status="error" /> ไม่พร้อมใช้งาน
                                </template>
                            </template>
                            <template v-else-if="column.key == 'car_tax_expire' && record.car_tax_expire">
                                <a-tag :color="render_expire(compare_time(record.car_tax_expire), true)">
                                    {{render_expire(compare_time(record.car_tax_expire), false) }}
                                </a-tag>
                            </template>
                            <template v-else-if="column.key == 'car_tax_expire_parse'">
                                <a-tag :color="render_expire(compare_time(record, 'car_tax_expire'), true)">
                                    {{render_expire(compare_time(record, 'car_tax_expire'), false) }}
                                </a-tag>
                            </template>
                            <template v-else-if="column.key == 'insure_expire_parse'">
                                <a-tag :color="render_expire(compare_time(record, 'insure_expire'), true)">
                                    {{render_expire(compare_time(record, 'insure_expire'), false) }}
                                </a-tag>
                            </template>
                            <template v-else-if="column.key == 'maintenance_expire_parse'">
                                <a-tag :color="render_expire(compare_time(record, 'maintenance_expire'), true)">
                                    {{render_expire(compare_time(record, 'maintenance_expire'), false) }}
                                </a-tag>
                            </template>
                            <template v-else-if="column.key == 'product_expire_parse'">
                                <a-tag :color="render_expire(compare_time(record, 'product_expire'), true)">
                                    {{render_expire(compare_time(record, 'product_expire'), false) }}
                                </a-tag>
                            </template>
                            <template v-else-if="column.key == 'car_type'" >
                                <!-- {{record.car_type? record.car_type.name:record.car_group?.car_type?.name}} -->
                                {{ car_type_list.find((el) => el.id == record.car_type_id)?.name }}
                            </template>
                            <template v-else-if="column.key == 'car_group'" >
                                {{record.car_group?.name}}
                            </template>
                            <template v-else-if="column.key == 'parking'" >
                                {{record.parking?.name}}
                            </template>
                            <template v-else-if="column.key == 'aircon'" >
                                <a-switch v-model:checked="record.aircon" :checked="true" @change="update_car(record.id, record.aircon)" />
                            </template>
                            <template v-else-if="column.key == 'is_active'" > 
                                <a-switch v-model:checked="record.is_active" :checked="true" @change="(event) => update_driver_is_active(event, record.id )" />
                            </template>
                            <template v-else-if="column.key == 'car_company_id'" > 
                                {{ car_company_list.find((carCom) => carCom.id == record.car_company_id)?.fullname || '' }}
                            </template>
                            <template v-else-if="column.key ==  'register_date'">
                                <!-- {{ record[column.key] ? render_date_register(record[column.key]) : '' }}   -->
                                  {{ record[column.key] }}
                            </template>

                        </template>

                    </a-table>
                </a-row>
            </a-card>
        </div>
    </div>
</template>

<script>
import { exportXLSXFile, xlxsBase64toJson } from '@/components/helpers.js'
import { message } from 'ant-design-vue';
import ReportService from '../../api/ReportService'
import SystemService from '../../api/SystemService'
import Utility from '../../utility'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import DriverService from '../../api/DriverService.js'
dayjs.extend(buddhistEra)
import LeaveService from "../../api/LeaveService";
import XLSX from 'sheetjs-style'
import ClaimExpenseService from '../../api/ClaimExpenseService';
import UserService from '../../api/UserService';
import CompanyService from '../../api/CompanyService';
import socketService from '../../api/SocketService.js';

export default {
    name: 'manage',
    data() {
        return {
            socketService,
            editable: false,
            car_company_list: [],
            cars_list: [],
            show_table_list: [],
            driverWorkStatuses: {},
            company_id: JSON.parse(localStorage.getItem("user_profile")).company_id,
            countLeave: 0,
            countClaim: 0,

            /* Search */
            search_car_company: null,
            search_expire_type: null,
            search_input: null,
            show_odohistory: false,
            business: false,
            car_rental_a: false,

            sorter_data: null,
            car_type_list: [],
            topic: {
                CarCompany: {
                    title: 'จัดการบริษัทรถ',
                    button_title: 'เพิ่มบริษัทรถ',
                    input: 'ชื่อบริษัท / รหัสบริษัท',
                    input_key: 'fullname',
                    clear: true,
                    columns: [
                        {
                            title: 'ชื่อบริษัท',
                            dataIndex: 'fullname',
                            key: 'fullname',
                            sorter: true,
                            width: 500,
                            align: 'center',
                        }, {
                            title: 'ที่อยู่',
                            dataIndex: 'address',
                            key: 'address',
                            width: 650,
                            align: 'center',
                        }, {
                            title: 'เบอร์โทร',
                            dataIndex: 'phone_no',
                            key: 'phone_no',
                            width: 120,
                            align: 'center',
                        }, 
                        // {
                        //     title: 'เบอร์แฟกซ์',
                        //     dataIndex: 'fax_no',
                        //     key: 'fax_no',
                        //     width: 120,
                        //     align: 'center',
                        // }, 
                        {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            align: 'center',
                            width: 100,
                            fixed: 'right'
                        }
                    ],
                },
                CarType: {
                    title: 'จัดการชนิดรถ',
                    button_title: 'เพิ่มชนิดรถ',
                    input: 'ชื่อชนิดรถ',
                    input_key: 'name',
                    clear: true,
                    columns: [
                        {
                            title: 'ชื่อชนิดรถ',
                            dataIndex: 'name',
                            key: 'name',
                            sorter: true,
                            width: 620,
                            align: 'center',
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            fixed: 'right',
                            align: 'center',
                        }
                    ]
                },
                CarGroup: {
                    title: 'จัดการประเภทที่นั่งรถ',
                    button_title: 'เพิ่มประเภทที่นั่งรถ',
                    input: 'จัดการประเภทที่นั่งรถ',
                    input_key: 'name',
                    clear: true,
                    columns: [
                        {
                            title: 'ชื่อประเภทที่นั่งรถ',
                            dataIndex: 'name',
                            key: 'name',
                            sorter: true,
                            width: 300,
                            align: 'center',
                        }, {
                            title: 'ชื่อชนิดรถ',
                            dataIndex: 'car_type',
                            key: 'car_type',
                            width: 250,
                            align: 'center',
                        },
                         {
                            title: 'จำนวนที่นั่ง',
                            dataIndex: 'seats',
                            key: 'seats',
                            width: 150,
                            align: 'center',
                        },
                        {
                            title: 'ราคา',
                            dataIndex: 'price',
                            key: 'price',
                            width: 150,
                            align: 'center',
                        }, 
                        {
                            title: 'เป็นรถปรับอากาศด้วยแอร์',
                            dataIndex: 'aircon',
                            key: 'aircon',
                            width: 170,
                            align: 'center',
                        }, 
                        {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            fixed: 'right',
                            align: 'center',
                        }
                    ]
                },
                DriverCost: {
                    title: 'จัดการเบี้ยเลี้ยง',
                    button_title: 'เพิ่มเบี้ยเลี้ยง',
                    columns: [
                        {
                            title: 'ชื่อเบี้ยเลี้ยง',
                            dataIndex: 'name',
                            key: 'name',
                            width: 50,
                            align: 'center'
                        },
                        {
                            title: 'ชื่อประเภทการจอง',
                            dataIndex: 'business_type',
                            key: 'business_type',
                            width: 50,
                            align: 'center'
                        },
                        {
                            title: 'จำนวนเบี้ยเลี้ยง',
                            dataIndex: 'cost_per_day',
                            key: 'cost_per_day',
                            width: 50,
                            align: 'center'
                        },
                        {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 50,
                            align: 'center'
                        }
                    ]
                },
                Driver: {
                    title: 'จัดการข้อมูลพนักงานขับรถ',
                    button_title: 'เพิ่มพนักงานขับรถ',
                    claim: 'แจ้งเบิก',
                    leave: 'แจ้งลา',
                    select_client: true,
                    input: 'ชื่อผู้ขับ',
                    input_key: 'firstname',
                    allow_import: true,
                    clear: true,
                    columns: [
                        {
                            title: 'ลำดับ',
                            dataIndex: 'index',
                            key: 'index',
                            width: 50,
                            align: 'center'
                        }, {
                            title: 'บริษัทรถ',
                            dataIndex: 'car_company_id',
                            key: 'car_company_id',
                            width: 175,
                            align: 'center'
                        }, {
                            title: 'สังกัด',
                            dataIndex: 'affiliation',
                            key: 'affiliation',
                            width: 175,
                            align: 'center'
                        }, {
                            title: 'รหัสพนักงาน',
                            dataIndex: 'driver_no',
                            key: 'driver_no',
                            sorter: true,
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'สถานะลา',
                            dataIndex: 'leave_status',
                            key: 'leave_status',
                            sorter: true,
                            width: 100,
                            align: 'center',
                        }, {
                            title: 'วันที่ลา',
                            dataIndex: 'leave_date',
                            key: 'leave_date',
                            sorter: true,
                            width: 100,
                            align: 'center',
                        }, {
                            title: 'สถานะพนักงาน',
                            dataIndex: 'status',
                            key: 'status',
                            sorter: true,
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'ชื่อ-นามสกุลผู้ขับ',
                            dataIndex: 'fullName',
                            key: 'fullName',
                            sorter: true,
                            width: 175,
                            align: 'center',
                        }, {
                            title: 'สายรถ',
                            dataIndex: 'car_line',
                            key: 'car_line',
                            sorter: true,
                            width: 175,
                            align: 'center',
                        }, {
                            title: 'ทะเบียนรถ',
                            dataIndex: 'plate_no',
                            key: 'plate_no',
                            sorter: true,
                            width: 175,
                            align: 'center',
                        }, {
                            title: 'อายุ',
                            dataIndex: 'age',
                            key: 'age',
                            sorter: true,
                            width: 100,
                            align: 'center',
                        }, {
                            title: 'อายุงาน (วันเดือนปี)',
                            dataIndex: 'workAge',
                            key: 'workAge',
                            sorter: true,
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'วันเดือนปีเริ่มงาน',
                            dataIndex: 'register_date',
                            key: 'register_date',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'วันหมดอายุใบขับขี่',
                            dataIndex: 'driving_license_exp',
                            key: 'driving_license_exp',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'สถานะ',
                            dataIndex: 'is_active',
                            key: 'is_active',
                            width: 120,
                            align: 'center',
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            fixed: 'right',
                            align: 'center',
                        }
                    ]
                },
                Guide: {
                    title: 'จัดการไกด์',
                    button_title: 'เพิ่มไกด์',
                    input: 'ชื่อไกด์ / ชื่อลูกค้า / ชื่อบริษัท',
                    input_key: 'firstname',
                    clear: true,
                    columns: [
                        {
                            title: 'ชื่อไกด์',
                            dataIndex: 'firstname',
                            key: 'firstname',
                            sorter: true,
                            width: 300,
                            align: 'center',
                        }, {
                            title: 'ชื่อเล่น',
                            dataIndex: 'nickname',
                            key: 'nickname',
                            sorter: true,
                            width: 250,
                            align: 'center',
                        }, {
                            title: 'อีเมล',
                            dataIndex: 'email',
                            key: 'email',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'เบอร์มือถือ',
                            dataIndex: 'phone_no',
                            key: 'phone_no',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'เบอร์สำรอง',
                            dataIndex: 'phone_backup',
                            key: 'phone_backup',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            fixed: 'right',
                            align: 'center',
                        }
                    ]
                },
                ClientContact: {
                    title: 'จัดการผู้ประสานงาน',
                    button_title: 'เพิ่มผู้ประสานงาน',
                    input: 'ผู้ประสานงาน / ชื่อบริษัท',
                    input_key: 'firstname',
                    clear: true,
                    columns: [
                        {
                            title: 'ชื่อ-นามสกุล ผู้ประสานงาน',
                            dataIndex: 'firstname_lastname',
                            key: 'firstname_lastname',
                            sorter: true,
                            width: 250,
                            align: 'center',
                        },{
                            title: 'ชื่อบริษัท',
                            dataIndex: 'client',
                            key: 'client',
                            sorter: true,
                            width: 300,
                            align: 'center'
                        },{
                            title: 'ชื่อสาขา',
                            dataIndex: 'client_branch',
                            key: 'client_branch',
                            sorter: true,
                            width: 300,
                            align: 'center'
                        },{
                            title: 'อีเมลผู้ประสานงาน',
                            dataIndex: 'email',
                            key: 'email',
                            width: 150,
                            align: 'center'
                        }, {
                            title: 'เบอร์มือถือ',
                            dataIndex: 'phone_no',
                            key: 'phone_no',
                            width: 150,
                            align: 'center'
                        },{
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            fixed: 'right',
                            align: 'center'
                        }
                    ]
                },
                Car: {
                    title: 'จัดการรถทั้งหมด',
                    button_title: 'เพิ่มรถทั้งหมด',
                    allow_import: true,
                    select_client: true,
                    clear: true,
                    input: 'ทะเบียนรถ เบอร์รถ และชื่อพนักงานขับรถ',
                    input_key: 'plate_no',
                    // lower_buttons: [
                    //     { name: 'vehicle_report', title: 'รายงานการใช้รถ' },
                    //     { name: 'vehicle_use_report', title: 'ออกรายงานรถ' },
                    //     { name: 'show_car_company_id', title: 'แสดงรหัสบริษัทรถ' }
                    // ],
                    columns: [
                        {
                            title: 'ลำดับ',
                            dataIndex: 'index_id',
                            key: 'index_id',
                            width: 50,
                            align: 'center'
                        }, 
                        {
                            title: 'บริษัท',
                            dataIndex: 'car_company',
                            key: 'car_company',
                            width: 150,
                            align: 'center',
                        },
                        {
                            title: 'ทะเบียนรถ',
                            dataIndex: 'plate_no',
                            key: 'plate_no',
                            width: 100,
                            align: 'center',
                        }, 
                        {
                            title: 'เบอร์รถ',
                            dataIndex: 'car_no',
                            key: 'car_no',
                            width: 100,
                            align: 'center',
                        }, 
                        {
                            title: 'สถานะรถ',
                            dataIndex: 'car_status',
                            key: 'car_status',
                            align: 'center',
                            width: 200,
                            align: 'center',
                        }, 
                        {
                            title: 'ชนิดรถ',
                            dataIndex: 'car_type',
                            key: 'car_type',
                            width: 200,
                            align: 'center',
                        }, 
                        {
                            title: 'ยี่ห้อรถ',
                            dataIndex: 'car_brand',
                            key: 'car_brand',
                            width: 200,
                            align: 'center',
                        },
                        {
                            title: 'พรบ.',
                            dataIndex: 'car_tax_expire_parse',
                            key: 'car_tax_expire_parse',
                            width: 150,
                            align: 'center',
                            align: 'center',
                        }, 
                        {
                            title: 'จุดจอด',
                            dataIndex: 'parking',
                            key: 'parking',
                            width: 150,
                            align: 'center',
                        }, 
                        {
                            title: 'เลขไมล์รถ',
                            dataIndex: 'odometer',
                            key: 'odometer',
                            width: 200,
                            align: 'center',
                        }, 
                        {
                            title: 'ชื่อผู้ขับ',
                            dataIndex: 'driver',
                            key: 'driver',
                            width: 150,
                            align: 'center',
                        }, 
                        {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            align: 'center',
                            fixed: 'right',
                        }
                        // {
                        //     title: 'ประเภทที่นั่งรถ',
                        //     dataIndex: 'car_group',
                        //     key: 'car_group',
                        //     width: 200,
                        //     align: 'center',
                        // },  
                        // {
                        //     title: 'เลขไมล์แจ้งซ่อม',
                        //     dataIndex: 'repair_target',
                        //     key: 'repair_target',
                        //     width: 150,
                        // }, 
                    ]
                },
                Parking: {
                    title: 'จัดการจุดจอดยานพาหนะทั้งหมด',
                    button_title: 'เพิ่มจุดจอดยานพาหนะ',
                    lower_buttons: [
                        { name: 'park_report', title: 'ออกรายงานจุดจอดยานพาหนะ' },
                        { name: 'park_booking', title: 'ออกรายการจองของจุดจอด' }
                    ],
                    columns: [
                        {
                            title: 'ชื่อจุดจอดยานพาหนะ',
                            dataIndex: 'name',
                            key: 'name',
                            width: 300,
                            align: 'center',
                        }, {
                            title: 'จำนวนยานพาหนะทั้งหมดในจุดจอด',
                            dataIndex: 'max_lots',
                            key: 'max_lots',
                            width: 250,
                            align: 'center',
                        }, {
                            title: 'Latitude',
                            dataIndex: 'lat',
                            key: 'lat',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'Longitude',
                            dataIndex: 'lng',
                            key: 'lng',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            fixed: 'right',
                            align: 'center',
                        }
                    ]
                },
                Expense: {
                    title: 'จัดการค่าใช้จ่ายเพิ่มเติมในรถ',
                    button_title: 'เพิ่มค่าใช้จ่ายเพิ่มเติมในรถ',
                    columns: [
                        {
                            title: 'ชื่อค่าใช้จ่ายเพิ่มเติม',
                            dataIndex: 'name',
                            key: 'name',
                            align: 'center',
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            align: 'center',
                            fixed: 'right'
                        }
                    ]
                },
                Extra: {
                    title: 'จัดการค่าใช้จ่ายเพิ่มเติม QT',
                    button_title: 'เพิ่มค่าใช้จ่ายเพิ่มเติม QT',
                    input: 'ชื่อรายการ',
                    input_key: 'name',
                    clear: true,
                    columns: [
                        {
                            title: 'ชื่อค่าใช้จ่ายเพิ่มเติม',
                            dataIndex: 'name',
                            key: 'name',
                            width: 300,
                            align: 'center',
                        }, {
                            title: 'ราคาต่อชิ้น',
                            dataIndex: 'price',
                            key: 'price',
                            align: 'center',
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            fixed: 'right',
                            align: 'center',
                        }
                    ]
                },
                Trip: {
                    title: 'จัดการจุดประสงค์การเดินทาง',
                    button_title: 'เพิ่มจุดประสงค์การเดินทาง',
                    input: 'ใบเสนอราคา ชื่อลูกค้า ชื่อ Sale',
                    input_key: 'name',
                    clear: true,
                    columns: [
                        {
                            title: 'ชื่อ',
                            dataIndex: 'name',
                            key: 'name',
                            align: 'center',
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            align: 'center',
                            fixed: 'right'
                        }
                    ]
                },
                Expire: {
                    title: 'รายงานวันหมดอายุรถ',
                    select_client: true,
                    select_expire: true,
                    input: 'ทะเบียนรถ',
                    input_key: 'plate_no',
                    clear: true,
                    columns: [
                        {
                            title: 'ทะเบียนรถ',
                            dataIndex: 'plate_no',
                            key: 'plate_no',
                            align: 'center',
                        }, {
                            title: 'เบอร์รถ',
                            dataIndex: 'car_no',
                            key: 'car_no',
                            align: 'center',
                        }, {
                            title: 'บริษัท',
                            dataIndex: 'car_company',
                            key: 'car_company',
                            align: 'center',
                        }, {
                            title: 'วันหมดอายุกรมธรรม์พรบ.',
                            dataIndex: 'car_tax_expire_parse',
                            key: 'car_tax_expire_parse',
                            align: 'center',
                        },  {
                            title: 'วันหมดอายุกรมธรรม์ประกันภัย',
                            dataIndex: 'insure_expire_parse',
                            key: 'insure_expire_parse',
                            align: 'center',
                        }, {
                            title: 'วันหมดอายุกรมธรรม์ประกันภัยสินค้า',
                            dataIndex: 'product_expire_parse',
                            key: 'product_expire_parse',
                            align: 'center',
                        }, {
                            title: 'วันตรวจสภาพรถกลางปี',
                            dataIndex: 'maintenance_expire_parse',
                            key: 'maintenance_expire_parse',
                            align: 'center',
                        }
                    ]
                }
            },
            import_fileList: [],
            driver_license_type_list: [],
        }
    },
    computed: {
        render_type() {
            return this.$route.params.type
        },
        true_render_type() {
            const { table } = this.$route.query
            return table? table:this.$route.params.type
        }
    },
    created() {
        this.editable = Utility.get_permission_editable_with_key(this.permissionsCheck(this.render_type));
     },
    methods: {
        async get_company() {
     const company = await UserService.get_user_profile();
     const businessType = await CompanyService.getBusinessId(company.company_id);
     if ( businessType.data === 2) {
         this.business = true;
     } else {
         this.business = false;
     }
     if (businessType.data === 4) {
        this.car_rental_a = true;
     }
 },
 filteredColumns() {
    return this.topic[this.render_type].columns.filter((column) => {
        let shouldInclude = 
            (column.key !== 'seats' && column.key !== 'aircon' || !this.business) &&
            (this.editable || !column.key.includes('id'));
        if (this.render_type === 'Driver') {
            shouldInclude = shouldInclude && 
                (column.key !== 'affiliation' || this.car_rental_a) &&
                (column.key !== 'car_line' || this.car_rental_a) 
                // (column.key !== 'plate_no' || this.car_rental_a) &&
                // (column.key !== 'plate_no1' || !this.car_rental_a);
        }

        return shouldInclude;
    });
},
        connectSocket(){
            this.socketService.connect();
            this.socketService.joinRoom('leave');
            this.socketService.onBroadcast((message) => {
                if (message) {
                    this.countLeave = message.filter((item) => item.driver.company_id == this.company_id && item.approve == 0).length;
                }
            },'leave');
        },
        async getClaimExpenseCount(){
            await ClaimExpenseService.getClaimExpense().then((res) => {
                this.countClaim = res.filter((item) => item.driver.company_id == this.company_id && item.approve == 0).length;
            })
        },
        async getLeaveCount(){
            await LeaveService.getLeaveDriver('WAITING').then((res) => {
                this.countLeave = res.filter((item) => item.driver.company_id == this.company_id && item.approve == 0).length;
            })
        },
        async update_driver_is_active(event, id) {
            if (event) {
                await DriverService.updateDriverIsActiveById({ is_active: event }, id)
                .then(() => {
                    message.success({ content: 'เปิดใช้พนักงานขับรถสำเร็จ', key: 'update_driver_is_active', duration: 1.5 });
                })
            } else {
                await DriverService.updateDriverIsActiveById({ is_active: event }, id)
                .then(() => {
                    message.success({ content: 'ปิดใช้พนักงานขับรถสำเร็จ', key: 'update_driver_is_active', duration: 1.5 });
                })
            }
        },
        async fetchDriverWorkStatus(id) {
            try {
                const res = await DriverService.getDriverWorkStatusAndId(id);
                this.driverWorkStatuses[id] =    res.data;
            } catch (error) {
                console.error('Error fetching driver work status:', error);
            }
        },
        async update_car_status(status_id, car_id) {
            return await SystemService.update_all('Car', { data: { car_status_id: status_id } }, car_id)
        },
        filtered_table(array, { topic, render_type }) {
            const { search_input, search_car_company, search_expire_type } = this
            return array
                .filter((el, i) => {
                    if (search_input) {
                        const found_driver = () => {
                            if(render_type == 'Driver'){
                                return el? (String(el?.firstname || '').includes(search_input) 
                                || String(el?.lastname || '').includes(search_input) 
                                || `${el?.firstname} ${el?.lastname}`.includes(search_input)) :false
                            }else{
                                return el? (String(el?.driver?.firstname || '').includes(search_input) 
                                || String(el?.driver?.lastname || '').includes(search_input) 
                                || `${el?.driver?.firstname} ${el?.driver?.lastname}`.includes(search_input)) :false
                            }
                        }

                        const found_car_no = () => {
                            return el.car_no? el.car_no.includes(search_input):false
                        }

                        const found_client = () => {
                            return el.client? el.client.fullname.includes(search_input):false
                        }

                        return el[topic[render_type].input_key].includes(search_input) || found_driver() || found_car_no() || found_client()
                    } else {
                        return true
                    }
                })
                .filter((el, i) => {
                    if (search_car_company) {
                        return el.car_company_id == search_car_company
                    } else {
                        return true
                    }
                })
                .filter((el, i) => {
                    if (search_expire_type) {
                        const amount = this.compare_time(el.car_tax_expire)
                        // console.log(el.car_tax_expire);
                        return amount > (search_expire_type * 30) && amount > 0 && el.car_tax_expire
                    } else {
                        return true
                    }
                })
                // .sort((a, b) => {
                //     if (this.sorter_data) {
                //         const { field, order } = this.sorter_data
                //         if (order == 'ascend') {
                //             return a[field] > b[field] ? 1:-1
                //         } else {
                //             return a[field] < b[field] ? 1:-1
                //         }
                //     } else {
                //         return 0
                //     }
                // })
        },
        render_expire(amount, tag) {
            if (tag) {
                if (amount <= 60 && amount >= 30) return 'orange'
                if (amount <= 30) return 'red'
                else return 'green'
            } else {
                if (amount < 0) return 'หมดอายุไปแล้ว ' + Math.abs(amount) + ' วัน'
                else return'หมดอายุใน ' + Math.abs(amount) + ' วัน'
            }
        },
        compare_time(timeA, is_parse) {
            if (timeA && !is_parse) {
                const timeA_moment = dayjs(timeA)
                const timeB_moment = dayjs()
                return Number(timeA_moment.diff(timeB_moment, 'days'))
                
            } else if (is_parse) {
                const date = timeA.data_raw_text? JSON.parse(timeA.data_raw_text)[is_parse]:null
                const timeA_moment = dayjs(date)
                const timeB_moment = dayjs()
                
                return isNaN(Number(timeA_moment.diff(timeB_moment, 'days')))? null:Number(timeA_moment.diff(timeB_moment, 'days'))

            } else {
                return 0
            }
        },
        formatted_odos(odos) {
            const new_odos = []
            const sorted_odos = odos.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at)
            })
            for (let i = 0; i < sorted_odos.length; i += 2) {
                const end_odo = sorted_odos[i].is_start? null:sorted_odos[i]
                const start_odo = sorted_odos[i + 1].is_start? sorted_odos[i + 1]:null
                const odo_obj = {
                    start: start_odo,
                    end: end_odo,
                    distance: () => {
                        if (start_odo && end_odo) {
                            return end_odo.distance - start_odo.distance
                        } else {
                            return 0
                        }
                    }
                }
                
                new_odos.push(odo_obj)
            }
            return new_odos
        },
        render_date(date) {
            return dayjs(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')
        },
        render_date_register(date) {
            return dayjs(date, 'YYYY-MM-DD HH:mm:ss')
            .add(1, 'day')
            .format('DD/MM/YYYY')
        },
        import_change(event, field) {
            const file = event.file.originFileObj;
 
            // ตรวจสอบประเภทของไฟล์
            const validFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
            if (!validFileTypes.includes(file.type)) {
                message.error({ content: 'โปรดอัปโหลดไฟล์ Excel เท่านั้น', key: 'import', duration: 1.5 });
                return;
            }
 
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
                const result = xlxsBase64toJson(reader.result.split(',')[1]);
                if (result && event.file.status === 'done') {
                   
                    //Check if there is duplicate data
                    let flag_duplicate = false;
                    if(this.render_type == 'Car'){
                        const plateNosInFile = new Set();
                        const carNosInFile = new Set();
                        result.map((el) => {
                            let isDuplicate = false;
                            if (plateNosInFile.has(el['เลขทะเบียนรถ'])) {
                                Swal.fire({
                                    title: 'ข้อมูลในไฟล์มีทะเบียนรถซ้ำกัน',
                                    text: `ทะเบียนรถ ${el['เลขทะเบียนรถ']} มีข้อมูลซ้ำในไฟล์`,
                                    icon: 'warning',
                                    confirmButtonText: 'ตกลง'
                                });
                                isDuplicate = true;
                                return;
                            } else if (carNosInFile.has(el['เบอร์รถ'])) {
                                Swal.fire({
                                    title: 'ข้อมูลในไฟล์มีเบอร์รถซ้ำกัน',
                                    text: `เบอร์รถ ${el['เบอร์รถ']} มีข้อมูลซ้ำในไฟล์`,
                                    icon: 'warning',
                                    confirmButtonText: 'ตกลง'
                                });
                                isDuplicate = true;
                                return;
                            }
                            
                                                        
                            if (isDuplicate) {
                                return;
                            }

                            plateNosInFile.add(el['เลขทะเบียนรถ']);
                            carNosInFile.add(el['เบอร์รถ']);

                            if (this.show_table_list.find((car) => car.plate_no == el['เลขทะเบียนรถ'])) {
                                Swal.fire({
                                    title: 'มีข้อมูลทะเบียนรถซ้ำกัน',
                                    text: `ทะเบียนรถ ${el['เลขทะเบียนรถ']} มีข้อมูลซ้ำกับข้อมูลที่มีอยู่แล้ว`,
                                    icon: 'warning',
                                    confirmButtonText: 'ตกลง'
                                })
                                flag_duplicate = true;
                                return;
                            }else if (this.show_table_list.find((car) => car.car_no == el['เบอร์รถ'])) {
                                Swal.fire({
                                    title: 'มีข้อมูลทะเบียนรถซ้ำกัน',
                                    text: `เบอร์รถ ${el['เบอร์รถ']} มีข้อมูลซ้ำกับข้อมูลที่มีอยู่แล้ว`,
                                    icon: 'warning',
                                    confirmButtonText: 'ตกลง'
                                })
                                flag_duplicate = true;
                                return;
                            }
                        })
                    }else if(this.render_type == 'Driver'){
                        const driverNosInFile = new Set();
                        const firstnamesInFile = new Set();
                        const lastnamesInFile = new Set();
                        const usernamesInFile = new Set();
                        const idNosInFile = new Set();
                        const phoneNosInFile = new Set();
                        result.map((el) => {
                            let isDuplicate = false;

                            if (driverNosInFile.has(el['Driver No.'])) {
                                Swal.fire({
                                    title: 'ข้อมูลในไฟล์มีรหัสพนักงานซ้ำกัน',
                                    text: `รหัสพนักงาน ${el['Driver No.']} มีข้อมูลซ้ำในไฟล์`,
                                    icon: 'warning',
                                    confirmButtonText: 'ตกลง'
                                });
                                isDuplicate = true;
                                return;
                            } else if (firstnamesInFile.has(el['ชื่อ']) && lastnamesInFile.has(el['นามสกุล'])) {
                                Swal.fire({
                                    title: 'ข้อมูลในไฟล์มีชื่อพนักงานซ้ำกัน',
                                    text: `ชื่อพนักงาน ${el['ชื่อ']} ${el['นามสกุล']}  มีข้อมูลซ้ำในไฟล์`,
                                    icon: 'warning',
                                    confirmButtonText: 'ตกลง'
                                });
                                isDuplicate = true;
                                return;
                            } else if (usernamesInFile.has(el['ชื่อผู้ใช้งาน'])) {
                                Swal.fire({
                                    title: 'ข้อมูลในไฟล์มีชื่อผู้ใช้งานซ้ำกัน',
                                    text: `ชื่อผู้ใช้งาน ${el['ชื่อผู้ใช้งาน']} มีข้อมูลซ้ำในไฟล์`,
                                    icon: 'warning',
                                    confirmButtonText: 'ตกลง'
                                });
                                isDuplicate = true;
                                return;
                            } else if (idNosInFile.has(el['เลขบัตรประชาชน'])) {
                                Swal.fire({
                                    title: 'ข้อมูลในไฟล์มีเลขบัตรประชาชนซ้ำกัน',
                                    text: `เลขบัตรประชาชน ${el['เลขบัตรประชาชน']} มีข้อมูลซ้ำในไฟล์`,
                                    icon: 'warning',
                                    confirmButtonText: 'ตกลง'
                                });
                                isDuplicate = true;
                                return;
                            } else if (phoneNosInFile.has(el['เบอร์โทรศัพท์'])) {
                                Swal.fire({
                                    title: 'ข้อมูลในไฟล์มีเบอร์โทรศัพท์ซ้ำกัน',
                                    text: `เบอร์โทรศัพท์ ${el['เบอร์โทรศัพท์']} มีข้อมูลซ้ำในไฟล์`,
                                    icon: 'warning',
                                    confirmButtonText: 'ตกลง'
                                });
                                isDuplicate = true;
                                return;
                            }
                            
                            if (isDuplicate) {
                                return;
                            }

                            driverNosInFile.add(el['Driver No.']);
                            firstnamesInFile.add(el['ชื่อ']);
                            lastnamesInFile.add(el['นามสกุล']);
                            usernamesInFile.add(el['ชื่อผู้ใช้งาน']);
                            idNosInFile.add(el['เลขบัตรประชาชน']);
                            phoneNosInFile.add(el['เบอร์โทรศัพท์']);

                            if (this.show_table_list.find((driver) => driver.driver_no == el['Driver No.'] )) {
                                Swal.fire({
                                    title: 'มีข้อมูลรหัสพนักงานซ้ำกัน',
                                    text: `รหัสพนักงาน ${el['Driver No.']} มีข้อมูลซ้ำกับข้อมูลที่มีอยู่แล้ว`,
                                    icon: 'warning',
                                    confirmButtonText: 'ตกลง'
                                })
                                flag_duplicate = true;
                                return;
                            } else if (this.show_table_list.find((driver) => driver.firstname  == el['ชื่อ'] && driver.lastname == el['นามสกุล'] )) {
                                Swal.fire({
                                    title: 'มีข้อมูลชื่อพนักงานซ้ำกัน',
                                    text: `ชื่อพนักงาน ${el['ชื่อ']} ${el['นามสกุล']} มีข้อมูลซ้ำกับข้อมูลที่มีอยู่แล้ว`,
                                    icon: 'warning',
                                    confirmButtonText: 'ตกลง'
                                })
                                flag_duplicate = true;
                                return;
                            }else if (this.show_table_list.find((driver) => driver.username == el['ชื่อผู้ใช้งาน'] )) {
                                Swal.fire({
                                    title: 'มีข้อมูล Username พนักงานซ้ำกัน',
                                    text: `Username : ${el['ชื่อผู้ใช้งาน']} มีข้อมูลซ้ำกับข้อมูลที่มีอยู่แล้ว`,
                                    icon: 'warning',
                                    confirmButtonText: 'ตกลง'
                                })
                                flag_duplicate = true;
                                return;
                            }else if (this.show_table_list.find((driver) => driver.id_no == el['เลขบัตรประชาชน'] )) {
                                Swal.fire({
                                    title: 'มีข้อมูลรหัสพนักงานซ้ำกัน',
                                    text: `เลขบัตรประชาขน : ${el['เลขบัตรประชาชน']} มีข้อมูลซ้ำกับข้อมูลที่มีอยู่แล้ว`,
                                    icon: 'warning',
                                    confirmButtonText: 'ตกลง'
                                })
                                flag_duplicate = true;
                                return;
                            }else if (this.show_table_list.find((driver) => driver.phone_no == el['เบอร์โทรศัพท์'] )) {
                                Swal.fire({
                                    title: 'มีข้อมูลเบอร์โทรศัพท์ซ้ำกัน',
                                    text: `เบอร์โทรศัพท์ : ${el['เบอร์โทรศัพท์']} มีข้อมูลซ้ำกับข้อมูลที่มีอยู่แล้ว`,
                                    icon: 'warning',
                                    confirmButtonText: 'ตกลง'
                                })
                                flag_duplicate = true;
                                return;
                            }
                        })
                    }
                    try {
                        message.loading({ content: 'กำลังนำเข้าข้อมูล...', key: 'import', duration: 0 });
                        const res = await ReportService[`import_${this.render_type.toLowerCase()}`](result);

                        if (res.status === 201) {
                            await this.init_data_table(this.render_type);
                            message.success({ content: `นำเข้าข้อมูล ${res.data.length} รายการสำเร็จ!`, key: 'import', duration: 1.5 });
                        } else {
                            throw new Error(res.message);
                        }
                    } catch (err) {
                        if (err.response && err.response.data && err.response.data.message) {
                            message.error({ content: err.response.data.message, key: 'import', duration: 1.5 });
                        } else {
                            message.error({ content: 'นำเข้าข้อมูลไม่สำเร็จ!', key: 'import', duration: 1.5 });
                        }
                        console.error(err);
                    }
                } else {
                    message.error({ content: 'นำเข้าข้อมูลไม่สำเร็จ!', key: 'import', duration: 1.5 });
                }
            };
            reader.onerror = () => {
                message.error({ content: 'เกิดข้อผิดพลาดในการอ่านไฟล์', key: 'import', duration: 1.5 });
            };
        },


        export_file() {
            const headers = {
                Driver: {
                    username: 'ชื่อผู้ใช้งาน',
                    password: 'รหัสผ่านใหม่',
                    driver_no: 'Driver No.',
                    id_no: 'เลขบัตรประชาชน',
                    firstname: 'ชื่อ',
                    lastname: 'นามสกุล',
                    bank_accno: 'บัญชีธนาคาร',
                    salary: 'เงินเดือน',
                    phone_no: 'เบอร์โทรศัพท์',
                    register_date: 'วันเริ่มงาน',
                    birth_date:'วันเดือนปีเกิด',
                    age:'อายุ',
                    sex:'เพศ',
                    driver_license_no: 'เลขที่ใบขับขี่',
                    driving_license_exp: 'วันหมดอายุใบขับขี่',
                    driving_license_type: 'ประเภทใบขับขี่',
                },
                Car: {
                    car_type: 'ชนิดรถ',
                    car_group: 'ประเภทที่นั่งรถ',
                    car_no: 'เบอร์รถ',
                    plate_no: 'เลขทะเบียนรถ',
                    car_brand: 'ยี่ห้อรถ',
                    car_year: 'ปีรถ',
                    car_model: 'รุ่นรถ',
                    tax_provider_name: 'บริษัทประกันภัย พรบ.',
                    car_tax_no: 'เลขที่กรมธรรม์ พรบ.',
                    car_tax_start: 'วันเริ่ม พรบ.',
                    car_tax_expire: 'วันสิ้นสุด พรบ.',
                    car_tax_premium_net: 'เบี้ยประกันภัยสุทธิ พรบ.',
                    car_tax_premium_total_added: 'เบี้ยประกันภัยสุทธิรวมภาษีอากร พรบ.',
                    car_tax_stamps: 'อากรแสตมป์ พรบ.',
                    car_tax_vat: 'ภาษีมูลค่าเพิ่ม พรบ.',
                    car_tax_total:'ยอดสุทธิ พรบ',
                    insure_provider_name: 'บริษัทประกันภัย ภาคสมัครใจ',
                    insure_no: 'เลขที่กรมธรรม์ ภาคสมัครใจ',
                    insure_start: 'วันเริ่ม ภาคสมัครใจ',
                    insure_expire: 'วันสิ้นสุด ภาคสมัครใจ',
                    insure_premium_net: 'เบี้ยประกันภัยสุทธิ ภาคสมัครใจ',
                    insure_premium_total_added: 'เบี้ยประกันภัยสุทธิรวมภาษีอากร ภาคสมัครใจ',
                    insure_stamps: 'อากรแสตมป์ ภาคสมัครใจ',
                    insure_vat: 'ภาษีมูลค่าเพิ่ม ภาคสมัครใจ',
                    maintenance_expire: 'วันตรวจสภาพรถประจำปี',
                    repair_target: 'เลขไมล์ซ่อมถัดไป',
                    insure_total: 'ยอดสุทธิ ภาคสมัครใจ'
                }
            }
            const fields = {
                Driver: [
                    'username','password','driver_no','id_no','firstname','lastname','bank_accno','salary','phone_no','register_date','birth_date','age','sex','driver_license_no','driving_license_exp','driving_license_type'
                ],
                Car: [
                    'car_type', 'car_group', 'car_no', 'plate_no', 'car_brand', 'car_year', 'car_model', 
                    'tax_provider_name', 'car_tax_no', 'car_tax_start', 'car_tax_expire', 'car_tax_premium_net', 
                    'car_tax_premium_total_added', 'car_tax_stamps', 'car_tax_vat','car_tax_total', 'insure_provider_name', 
                    'insure_no', 'insure_start', 'insure_expire', 'insure_premium_net', 'insure_premium_total_added', 
                    'insure_stamps', 'insure_vat', 'maintenance_expire', 'repair_target', 'insure_total'
                ]
            }
            let dataList = [];
            if (this.render_type == 'Car') {
                this.show_table_list.forEach((car) => {
                    const filteredCar = {
                        car_type : this.car_type_list.find((el) => el.id == car.car_type_id)?.name??'-',
                        car_group : car.car_group?.name??'-',
                        car_no: car.car_no??'-',
                        plate_no: car.plate_no??'-',
                        car_brand: car.car_brand??'-',
                        car_year: car.car_year??'-',
                        car_model: car.car_model??'-',
                        tax_provider_name : JSON.parse(car.data_raw_text)?.tax_provider_name??'-',
                        car_tax_no : JSON.parse(car.data_raw_text)?.car_tax_no??'-',
                        car_tax_start:dayjs(JSON.parse(car.data_raw_text)?.car_tax_start).isValid()?
                            dayjs(JSON.parse(car.data_raw_text)?.car_tax_start).format('D/MM/YYYY')
                            :'-',
                        car_tax_expire:dayjs(JSON.parse(car.data_raw_text)?.car_tax_expire).isValid()?
                            dayjs(JSON.parse(car.data_raw_text)?.car_tax_expire).format('D/MM/YYYY')
                            :'-',
                        car_tax_premium_net:JSON.parse(car.data_raw_text)?.car_tax_premium_net??'-',
                        car_tax_premium_total_added:JSON.parse(car.data_raw_text)?.car_tax_premium_total_added??'-',
                        car_tax_stamps:JSON.parse(car.data_raw_text)?.car_tax_stamps??'-',
                        car_tax_vat:JSON.parse(car.data_raw_text)?.car_tax_vat??'-',
                        car_tax_total:JSON.parse(car.data_raw_text)?.car_tax_total??'-',
                        insure_provider_name : JSON.parse(car.data_raw_text)?.insure_provider_name??'-',
                        insure_no : JSON.parse(car.data_raw_text)?.insure_no??'-',
                        insure_start : dayjs(JSON.parse(car.data_raw_text)?.insure_start).isValid()?
                            dayjs(JSON.parse(car.data_raw_text)?.insure_start).format('D/MM/YYYY')
                            :'-',
                        insure_expire : dayjs(JSON.parse(car.data_raw_text)?.insure_expire).isValid()?
                            dayjs(JSON.parse(car.data_raw_text)?.insure_expire).format('D/MM/YYYY')
                            :'-',
                        insure_premium_net:JSON.parse(car.data_raw_text)?.insure_premium_net??'-',
                        insure_premium_total_added:JSON.parse(car.data_raw_text)?.insure_premium_total_added??'-',
                        insure_stamps:JSON.parse(car.data_raw_text)?.insure_stamps??'-',
                        insure_vat:JSON.parse(car.data_raw_text)?.insure_vat??'-',
                        maintenance_expire: dayjs(car.maintenance_expire).isValid()?
                            dayjs(car.maintenance_expire).format('D/MM/YYYY')
                            :'-',
                        repair_target: JSON.parse(car.data_raw_text)?.repair_target??'-',
                        insure_total:JSON.parse(car.data_raw_text)?.insure_total??'-',
                    };
                    dataList.push(filteredCar);
                })
            }
            if (this.render_type == 'Driver') {
                this.show_table_list.forEach((driver) => {
                    const filteredDriver = {
                        username: driver.username??'-',
                        password: driver.password??'-',
                        driver_no: driver.driver_no??'-',
                        id_no: driver.id_no??'-',
                        firstname: driver.firstname??'-',
                        lastname: driver.lastname??'-',
                        bank_accno: driver.bank_accno??'-',
                        salary: driver.salary??'-',
                        phone_no: driver.phone_no??'-',
                        register_date: driver.register_date??'-',
                        birth_date: driver.birth_date??'-',
                        age: driver.age??'-',
                        sex: driver.sex??'-',
                        driver_license_no: driver.driver_license_no??'-',
                        driving_license_exp:dayjs(driver.driving_license_exp).isValid()?
                            dayjs(driver.driving_license_exp).format('D/MM/YYYY'):'-',
                        driving_license_type: this.driver_license_type_list.find(
                            (el) => el.id === driver.driving_license_type
                        )?.name??'-',
                    };
                    dataList.push(filteredDriver);
                })
            }
            this.exportToExcel( headers[this.render_type], fields[this.render_type], dataList, this.render_type);
        },
        exportToExcel(headers, fields, dataList, renderType) {
            this.$message.loading({ content: "กำลังโหลด...", key: "loading" });
            const headersColumn = fields.map((field) => ({
                key: field,
                label: headers[field],
            }));
            const workBook = XLSX.utils.book_new();
            const workSheet = XLSX.utils.json_to_sheet(dataList, { header: fields });

            const lastRow = dataList.length + 1;

            XLSX.utils.sheet_add_aoa(workSheet, [headersColumn.map(h => h.label)], { origin: 'A1' });
            // Apply styles to header row
            headersColumn.forEach((header, index) => {
                const cell = workSheet[XLSX.utils.encode_cell({ c: index, r: 0 })];
                if (cell) {
                    let colorHeader = renderType == "Car"?"FCD5B4":"E6B8B7";
                    cell.s = {
                        font: { bold: true },
                        fill: { fgColor: { rgb: colorHeader } },
                        border: {
                            bottom: { style: "thin" },
                            right: { style: "thin" },
                            left: { style: "thin" },
                            top: { style: "thin" },
                        },
                        alignment: { horizontal: "center" },
                    };
                }
            });

            // Apply styles to data rows
            for (let row = 1; row < lastRow; row++) {
                for (let col = 0; col < headersColumn.length; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ c: col, r: row });
                    let cell = workSheet[cellAddress];
                    if (!cell) {
                        cell = { v: "" }; // Initialize empty cells
                        workSheet[cellAddress] = cell;
                    }
                    cell.s = {
                        border: {
                            bottom: { style: "thin" },
                            right: { style: "thin" },
                            left: { style: "thin" },
                            top: { style: "thin" },
                        },
                        alignment: {
                            wrapText: true,
                            horizontal:"center",
                            vertical: "center",
                        },
                    };
                }
            }

            // Set column widths
            workSheet["!cols"] = headersColumn.map(header => {
                const maxLength = Math.max(...header.label.split(' ').map(word => word.length));
                return { wch: maxLength + 15 }; 
            });            
            if (!workSheet['!ref']) {
                workSheet['!ref'] = XLSX.utils.encode_range({
                s: { r: 0, c: 0 },
                e: { r: lastRow + 1, c: headersColumn.length - 1 }
                });
            } else {
                const range = XLSX.utils.decode_range(workSheet['!ref']);
                range.e.r = lastRow + 1;
                workSheet['!ref'] = XLSX.utils.encode_range(range);
            }
            // Generate the Excel file
            XLSX.utils.book_append_sheet(workBook, workSheet, renderType);
            XLSX.writeFile(workBook, `${renderType}.xlsx`);
            this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
        },
        import_export_dropdown(e) {
            const { key } = e
            if (key == '1') {
                
            } else if (key == '2') {
                this.export_file()
            }
        },
        update_car(id, aircon) {
            SystemService.update_all('CarGroup', { data: { aircon } }, id)
        },
        clear_odo(id) {
            Swal.fire({
                title: 'คุณต้องการล้างเลขไมล์ใช่หรือไม่?',
                text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้หากล้างเลขไมล์แล้ว!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'ยกเลิก',
                confirmButtonText: 'ใช่, ล้างเลขไมล์!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.$message.loading({ content: 'กำลังล้างเลขไมล์...', key: 'clear_odo', duration: 0 })
                    await SystemService.update_all('Car', { data: { odometer: 0 } }, id)
                    this.$message.success({ content: 'ล้างเลขไมล์สำเร็จ!', key: 'clear_odo', duration: 1.5 })
                    this.init_data_table(this.render_type)
                }
            })
        },
        async go_remove(id, type) {
            Swal.fire({
                title: `คุณต้องการลบข้อมูลนี้ใช่หรือไม่? [${type}ID: ${id}]`,
                text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้หากลบแล้ว!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
                cancelButtonText: 'ยกเลิก'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await SystemService.delete_all(type, id)
                        .then(() => {
                            this.init_data_table(this.render_type)
                        })
                }
            })
        },
        go_edit(model_data, modelname) {
            this.$emit('apply_model_data', model_data)
            this.$router.push(`/app/${modelname}/${model_data.id}`)
        },
        go_create(modelname) {
            this.$router.push(`/app/${modelname}/create`)
        },
        log(value) {
            console.log("ada",value)
        },
        async init_data_table(path) {
            const { table } = this.$route.query
            this.$message.loading({ content: 'กำลังโหลดข้อมูล...', key: 'init_data_table'  })

            await SystemService.get_all('CarType')
                .then(res => {
                    this.car_type_list = res
                })

            if ((table? table:path) != 'all') {
                await SystemService.get_all(table? table:path)
                    .then(res => {
                        this.$message.success({ content: 'โหลดข้อมูลสำเร็จ!', key: 'init_data_table', duration: 1.5 })
                        if (res) {
                            if ((table ? table : path) == 'Driver') {
                                this.show_table_list = res.map((el, i) => {
                                    console.log(el)
                                return {
                                        index_id: i + 1,
                                        driver_no: el.driver_no,
                                        // leave_status: el.leave_status == 4 ? 'ลากิจ' 
                                        //                                    : el.leave_status == 5 ? 'ลาป่วย' : '', // จะถูกอัพเดทโดย fetchDriverWorkStatus
                                        // leave_date: el.leave_date ? this.formatDate(el.leave_date) : '',
                                        fullName: `${el.firstname} ${el.lastname}`,
                                        affiliation: el.affiliation,
                                        car_line: el.car_line,
                                        age: `${el.age == null ? '' : `${el.age} ปี` }` || this.calculateAge(el.date_of_birth),
                                        workAge: this.calculateWorkAge(el.register_date),
                                        birth_date: this.formatDate(el.date_of_birth),
                                        register_date: this.formatDate(el.register_date),
                                        is_active: el.is_active,
                                        id: el.id,
                                        modelname: el.modelname,
                                        firstname: el.firstname,
                                        lastname: el.lastname,
                                        salary: el.salary,
                                        id_no: el.id_no,
                                        phone_no: el.phone_no,
                                        email: el.email,
                                        username: el.username,
                                        password: el.password,
                                        sex: el.sex,
                                        plate_no: this.cars_list.find((car)=> car.driver_id == el.id)?.plate_no??null,
                                        plate_no1: el.plate_no,
                                        register_date : this.formatDate(el.register_date),
                                        bank_accno: el.bank_accno,
                                        company_id: el.company_id,
                                        car_company_id: el.car_company_id ??this.car_company_list[0].id,
                                        driving_license_type: el.driving_license_type_id,
                                        driver_license_no: el.driver_license_no,
                                        driving_license_exp: this.formatDate(el.driving_license_exp),

                                        driver_job_position: el.driver_job_position,
                                        driver_age_of_work: el.driver_age_of_work,
                                        driver_work_status: el.driver_work_status,
                                        driver_stoped_employment_date: el.driver_stoped_employment_date,
                                        driver_current_car_no: el.driver_current_car_no,
                                        driver_religions: el.driver_religions,
                                        driver_educations: el.driver_educations,
                                        driver_address_no : el.driver_address_no,
                                        driver_address_village: el.driver_address_village,
                                        driver_address_sub_district: el.driver_address_sub_district,
                                        driver_address_alley: el.driver_address_alley,
                                        driver_address_road: el.driver_address_road,
                                        driver_address_district: el.driver_address_district,
                                        driver_address_city: el.driver_address_city,
                                        driver_address_province: el.driver_address_province,
                                        driver_address_postal_code: el.driver_address_postal_code,
                                        driver_bank: el.driver_bank,
                                        driver_tax_rate_service: el.driver_tax_rate_service,

                                    };
                                });
                                //console.log("this.show_table_list", this.show_table_list);
                                
                            
                                // เรียก fetchDriverWorkStatus สำหรับแต่ละคนขับ
                                this.show_table_list.forEach(driver => {
                                    this.fetchDriverWorkStatus(driver.id);
                                });
                            } else if ((table ? table : path) == 'Car')  {
                                this.show_table_list = res.map((el, i) => {
                                    
                                    el.index_id = i + 1;
                                    if (el.aircon == 1) el.aircon = true;
                                    else if (el.aircon == 0) el.aircon = false;

                                    this.fetchDriverWorkStatus(el.id);

                                    return el;
                                });
                                // console.log("this.show_table_list", this.show_table_list);
                                
                            } else {
                                this.show_table_list = res.map((el, i) => {
                                    el.index_id = i + 1;
                                    if (el.aircon == 1) el.aircon = true;
                                    else if (el.aircon == 0) el.aircon = false;

                                    // this.fetchDriverWorkStatus(el.id);

                                    return el;
                                });
                            }
                        }
                    })
            }
            this.driver_license_type_list = await SystemService.get_all('DrivingLicenseType')
            
        },
        calculateAge(birthDate) {
            if (!birthDate) return '';
            const today = new dayjs();
            const birth = new dayjs(birthDate);
            let age = today.year() - birth.year();
            const m = today.month() - birth.month();
            if (m < 0 || (m === 0 && today.date() < birth.date())) {
                age--;
            }
            return `${age} ปี`;
        },

        calculateWorkAge(startDate) {
            if (!startDate) return '';
            const start = dayjs(startDate);
            const today = dayjs();
            const years = today.diff(start, 'year');
            const months = today.diff(start, 'month') % 12;
            const days = today.diff(start.add(years, 'year').add(months, 'month'), 'day');
            return `${days}/${months}/${years}`;
        },

        formatDate(date) {
            if (!date) return '';
            return dayjs(date).format('DD/MM/YYYY');
        },
        async init_company_default() {
            this.car_company_list = await SystemService.get_all('CarCompany')
        },
        async init_car_data() {
            this.cars_list = await SystemService.get_all('Car')
        },
        button_action(type) {
            this[type]()
        },
        vehicle_report() {
            ReportService.vehicle_usage()
                .then(res => {
                    const { headers, data } = res
                    exportXLSXFile( headers, data, `vehicle_report`);
                })
        },
        vehicle_use_report() {
            ReportService.vehicle_use_report()
                .then(res => {
                    const { headers, data } = res
                    exportXLSXFile( headers, data, `vehicle_use_report`);
                })
        },
        show_car_company_id () {
            SystemService.get_all('CarCompany')
                .then(res => {
                    const ids = res.map((el) => {
                        return `ID (${el.id}) ${el.fullname}`
                    })
                    Swal.fire({
                        title: 'บริษัทรถ',
                        html: `${ids.join('<br>')}`
                    })
                })
        },
        park_report() {
            ReportService.park_report()
                .then(res => {
                    const { headers, data } = res
                    exportXLSXFile( headers, data, `park_report`);
                })
        },
        park_booking() {
            ReportService.park_booking()
                .then(res => {
                    const { headers, data } = res
                    exportXLSXFile( headers, data, `park_booking`);
                })
        },
        onSearch(event) {
            if (event) {
                this.show_table_list = this.show_table_list.filter((el) => {
                    return el[this.topic[this.render_type].input_key].includes(this.search_input)
                })
            }
        },
        onTableChange(pagination, filters, sorter, extra) {
            this.sorter_data = sorter
        },
        go_claim(render_type) {
            this.$router.push(`/app/claim`);
        },
        go_leave(render_type) {
            this.$router.push(`/app/leave`);
        },
        permissionsCheck(render_type){
            if(render_type === 'ClientContact' ||render_type === 'Guide') return 'manage_sales'
            else return 'manage_cars'
        },
        leaveRoom(channelId) {
            this.socketService.leaveRoom(channelId);
            this.socketService.offBroadcast(channelId);
        },
    },
    watch: {
        async render_type(newVal) {
            if (newVal) {
                this.show_table_list = []
                await this.init_data_table(this.render_type)
            }
        }
    },
    unmounted() {
        this.leaveRoom('leave');
    },
    async mounted() {
        this.connectSocket();
        await this.init_car_data();
        this.getClaimExpenseCount();
        this.getLeaveCount();
        this.init_company_default()
        this.init_data_table(this.render_type)
        this.get_company();
    }
}
</script>

<style>
.ant-table-thead .center-header {
  text-align: center; /* จัดตำแหน่งหัวคอลัมน์ให้อยู่ตรงกลาง */
}
</style>