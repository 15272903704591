<template>
    <div>
        <a-divider orientation="left">{{ divider }}</a-divider>
        <a-table :columns="table_columns" :data-source="target_object[target_key]" :pagination="false">
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.key === 'index'">
                    {{ index + 1 }}
                </template>
                <template v-if="column.key === 'part_id'">
                    {{ show_name_from_id(this.selectables['Part'], record.part_id) }}
                </template>
                <template v-if="column.key === 'file_name'">
                    <a @click="downloadFile(record.file, record.file_name)" @click.stop>
                        <span class="download-icon">
                            {{ record?.file_name }}
                        </span>
                    </a>
                </template>
                <template v-if="column.key === 'id'">
                    <a @click="go_edit(record, index)">
                        <span style="font-size: 18px" class="material-symbols-outlined">edit</span>
                    </a>

                    <a @click="go_remove(record.id, record.modelname, index)">
                        <span style="font-size: 18px" class="material-symbols-outlined">delete</span>
                    </a>
                </template>
            </template>
        </a-table>
        <a-row justify="end">
            <a-button @click="show_drawer = true" type="primary" size="large" style="margin: 4px">
                <span class="material-symbols-outlined" style="font-size: 16px;margin-right: 4px">Add</span>
                {{ drawer_button_text }}
            </a-button>
        </a-row>
        <a-drawer v-model:visible="show_drawer" :title="drawer_title" :closable="true" :footer="null">
            <a-form :model="drawer_create_data" name="form" autocomplete="off" layout="vertical"
                @finish="drawer_create">
                <a-row>
                    <a-col :span="24"
                        v-for="({ placeholder, required, input_type, options, label_key, modelname, accept }, key_name) in drawer_fields">
                        <a-form-item :label="placeholder" :name="key_name"
                            :rules="required ? [{ required, message: `โปรดระบุ ${placeholder}` }] : null">
                            <div v-if="input_type == 'select'">
                                <a-select v-model:value="drawer_create_data[key_name]"
                                    @change="(value) => handleOtherChange(value, key_name)" show-search
                                    :placeholder="placeholder">
                                    <a-select-option v-for="option in options" :value="option.value">
                                        <span class="truncate-text">{{ option.label }}</span>
                                    </a-select-option>
                                </a-select>
                                <a-modal v-model:visible="showOtherModal" :title="`ระบุ${placeholder}อื่นๆ`"
                                    @ok="handleModalOk" @cancel="handleModalCancel">
                                    <a-input v-model:value="otherInput" :placeholder="`ระบุ${placeholder}อื่นๆ`" />
                                </a-modal>
                            </div>
                            <a-checkbox-group v-else-if="input_type === 'checkbox_result'"
                                v-model="drawer_create_data[key_name]" :placeholder="placeholder">
                                <a-checkbox value="ผ่าน">ผ่าน</a-checkbox>
                                <a-checkbox value="ไม่ผ่าน">ไม่ผ่าน</a-checkbox>
                            </a-checkbox-group>
                            <div v-else-if="input_type == 'file'"
                                style="display: flex; flex-direction: column; align-items: flex-start; gap:1rem">
                                <div>{{ label }}</div>
                                <a v-if="drawer_create_data[key_name]" :href="drawer_create_data[key_name]"
                                    target="_blank" rel="doc">
                                    {{ drawer_create_data[`${key_name}_name`] }}
                                </a>
                                <a-upload v-else v-model:file-list="fileList" method="GET" :show-upload-list="false"
                                    :accept="accept ? accept : '*/*'" @change="upload_files($event, key_name)">
                                    <div style="display: flex; flex-direction: column; align-items: center;">
                                        <p class="ant-upload-drag-icon">
                                            <span class="material-symbols-outlined"
                                                style="font-size: 5rem;">upload_file</span>
                                        </p>
                                        อัพโหลดไฟล์
                                    </div>
                                </a-upload>
                                <a-button danger type="text" @click="(drawer_create_data[key_name] = '')"
                                    style="padding: 0; height: 18px;" v-if="drawer_create_data[key_name]">
                                    <span class="material-symbols-outlined">close</span>
                                </a-button>
                            </div>
                            <a-input v-else-if="input_type != 'none'" v-model:value="drawer_create_data[key_name]"
                                :placeholder="placeholder" :type="input_type" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row justify="end">
                    <a-col>
                        <a-button v-if="drawer_button_text" html-type="submit" type="primary" size="large"
                            style="margin: 4px;text-align: right">
                            <span class="material-symbols-outlined"
                                style="font-size: 16px;margin-right: 4px">Save</span>
                            {{ drawer_button_text }}
                        </a-button>
                        <a-button v-else="drawer_button_text_edit" html-type="submit" type="primary" size="large"
                            style="margin: 4px;text-align: right">
                            <span class="material-symbols-outlined" style="font-size: 16px;margin-right: 4px">Add</span>
                            {{ drawer_button_text_edit }}
                        </a-button>
                    </a-col>
                </a-row>
            </a-form>
        </a-drawer>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import SystemService from '../api/SystemService'
import DriverInfo from '../api/DriverInfo';

export default {
    props: {
        divider: String,
        table_columns: Array,
        target_object: Object,
        target_key: String,
        target_modelname: String,
        drawer_title: String,
        drawer_fields: Object,
        data: Array,
        drawer_button_text: String,
        drawer_button_text_edit: String,
    },
    name: 'table_create_drawer',
    data() {
        return {
            show_drawer: false,
            on_edit: false,
            drawer_create_data: {},
            selectables: {},
            selectedIndex: null,
            fileList: [],
            currentFieldKey: null,
            showOtherModal: false,
            otherInput: ''
        }
    },
    methods: {
        async go_edit(record, index) {
            this.drawer_create_data = record
            this.on_edit = true
            this.show_drawer = true
            this.selectedIndex = index
        },
        async go_remove(id, type, index) {
            if (id) {
                Swal.fire({
                    title: `คุณต้องการลบข้อมูลนี้ใช่หรือไม่? [${type}ID: ${id}]`,
                    text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้หากลบแล้ว!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
                    cancelButtonText: 'ยกเลิก'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await SystemService.delete_all(type, id)
                        this.target_object[this.target_key].splice(index, 1)
                        Swal.fire(
                            'ลบข้อมูลเรียบร้อย!',
                            'ข้อมูลของคุณถูกลบแล้ว',
                            'success'
                        )
                    }
                })
            } else {
                this.target_object[this.target_key].splice(index, 1)
            }
        },
        async drawer_create(data) {
            // console.log("drawer_create: ",data)
            if (this.on_edit && this.selectedIndex != null) {
                const { id } = this.drawer_create_data
                if (id) {
                    await SystemService.update_all(this.target_modelname, { data: data }, id)
                    Swal.fire(
                        'แก้ไขข้อมูลเรียบร้อย!',
                        'ข้อมูลของคุณถูกแก้ไขแล้ว',
                        'success'
                    )
                } else {
                    this.target_object[this.target_key][this.selectedIndex].value = [data]
                }
            } else {
                this.target_object[this.target_key] ? this.target_object[this.target_key].push(data) :
                    this.target_object[this.target_key] = [data]
            }
            // console.log("this.target_object: ", this.target_object)
            this.drawer_create_data = {}
            this.show_drawer = false
            this.on_edit = false
            this.selectedIndex = null
        },
        async get_default_fields() {
            // console.log("this.drawer_fields", this.drawer_fields)
            for (var key in this.drawer_fields) {
                const { input_type, modelname } = this.drawer_fields[key]
                if (input_type == 'select') {
                    this.selectables[modelname] = await SystemService.get_all(modelname)
                }
            }
        },

        show_name_from_id(array, id) {
            return array.find(el => el.id == id)?.name
        },
        upload_files(e, key) {
            try {
                if (e.file.status != 'remove') {
                    this.getBase64(e.file.originFileObj, key)
                    if (e.file.status == 'uploading' && !this.isLoading) {
                        this.$message.loading('กำลังอัปโหลดไฟล์...');
                        this.isLoading = true;
                    }
                    if (e.file.status == 'error') {
                        this.$message.success('อัปโหลดไฟล์สำเร็จ')
                        this.isLoading = false;
                    }
                    return true
                }else{
                    const vue = this
                    vue.drawer_create_data[`${key}_name`] = null
                }
            } catch (error) {
                console.log(error)
            }
        },
        async getBase64(file, key) {
            if (key) {
                const vue = this
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    vue.drawer_create_data[key] = reader.result
                    vue.drawer_create_data[`${key}_name`] = file.name
                };
            } else {
                return await new Promise((resolve, reject) => {
                    if (file) {
                        if (typeof file == 'string') {
                            resolve(file)
                        } else {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = error => reject(error);
                        }
                    }
                });
            }
        },
        async getBase64ImageFromURL(url) {
            const res = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            });
            const blob = await res.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        },
        downloadFile(url, fileName) {
            fetch(url)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.blob();
                })
                .then(blob => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName || 'default_filename.pdf'; // Set default filename if fileName is not valid
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(link.href);
                })
                .catch(error => {
                    console.error('Error downloading file:', error);
                });
        },
        handleOtherChange(value, key) {
            if (value === 'OTHER') {
                this.currentFieldKey = key;
                this.showOtherModal = true;
            }
        },
        handleModalOk() {
            this.drawer_create_data[this.currentFieldKey] = this.otherInput;
            this.showOtherModal = false;
            this.otherInput = '';
        },
        handleModalCancel() {
            this.showOtherModal = false;
            this.otherInput = '';
        },
    },

    async mounted() {
        await this.get_default_fields()
    }
}

</script>

<style scoped>
.ant-row {
    margin: 0
}
.truncate-text {
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

</style>