import httpClient from './httpClient';

const prefix = 'expense'

export default {
    async getExpenseAll () {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getExpenseAll`)
        return data
    },
    async getExpenseByCompany () {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getExpenseByCompany`)
        return data
    },
    async getExpenseByCompanyAndId (id) {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getExpenseByCompanyAndId/${id}`)
        return data
    },
}