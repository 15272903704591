<template>
    <div style="height: 100vh; padding: 15px">
        <div v-if="render_type == 'all'" class="app-content">
            <a-card title="แจ้งอุบัติเหตุ">
                <template v-if="!business" #extra>
                    <a-space>
                        <router-link v-if="editable" :to="`/app/accident/add`">
                            <a-button @click="clear_form()" style="display: flex" type="primary" size="large">
                                <template #icon><span class="material-symbols-outlined">Add</span></template>
                                เพิ่มรายการแจ้งอุบัติเหตุ
                            </a-button>
                        </router-link>
                    </a-space>
                </template>
                <a-row v-if="business" style="display: flex; justify-content: flex-end; margin-bottom: 20px; ">
                    <a-space>
                        <router-link v-if="editable" :to="`/app/accident/add`">
                            <a-button @click="clear_form()" style="display: flex" type="primary" size="large">
                                <template #icon><span class="material-symbols-outlined">Add</span></template>
                                เพิ่มรายการแจ้งอุบัติเหตุ
                            </a-button>
                        </router-link>
                    </a-space>
                </a-row>
                <a-space style="width: 100%; margin-bottom: 16px">
                    <a-col>
                        <a-input-search v-model:value="search_car" :placeholder="`ค้นหา ทะเบียนรถ`" enter-button="ค้นหา"/>
                    </a-col>
                    <a-col>
                        <a-select style="width: 200px" v-model:value="selected_company"
                            placeholder="เลือกบริษัทรถ" :dropdownMatchSelectWidth="false">
                            <a-select-option :value="null">ทั้งหมด</a-select-option>
                            <a-select-option v-for="{ fullname, id }, i in car_company_list" :key="i" :value="id">
                                {{ fullname }}
                            </a-select-option>
                        </a-select>
                    </a-col>

                    <a-button @click="clear_search()" type="dashed">CLEAR</a-button>
                </a-space>
                <br>
                <a-row>
                    <a-table :columns="[
                        {
                            title: 'บริษัท',
                            dataIndex: 'car_company',
                            key: 'car_company',
                            width: 200,
                            align: 'center',
                        }, 
                        {
                            title: 'ทะเบียนรถ',
                            dataIndex: 'car',
                            key: 'car',
                            width: 125,
                            align: 'center',
                        },  
                        {
                            title: 'เบอร์รถ',
                            dataIndex: 'car_no',
                            key: 'car_no',
                            width: 125,
                            align: 'center',
                        },
                        {
                            title: 'อาการ',
                            dataIndex: 'accident_details',
                            key: 'accident_details',
                            width: 125,
                            ellipsis: true,
                            align: 'center',
                        }, 
                        {
                            title: 'วันที่แจ้งอุบัติเหตุ',
                            dataIndex: 'accident_date',
                            key: 'accident_date',
                            width: 175,
                            align: 'center',
                        }, {
                            title: 'วันที่รับแจ้งอุบัติเหตุ',
                            dataIndex: 'issue_start',
                            key: 'issue_start',
                            width: 175,
                            align: 'center',
                        }, 
                        {
                            title: 'วันที่แจ้งอุบัติเหตุเสร็จสิ้น',
                            dataIndex: 'issue_end',
                            key: 'issue_end',
                            width: 175,
                            align: 'center',
                        },
                        {
                            title: 'สถานะ',
                            dataIndex: 'accident_status',
                            key: 'accident_status',
                            width: 150,
                            ellipsis: true,
                            align: 'center',
                        },
                        {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 120,
                            align: 'center',
                            fixed: 'right'
                        }
                    ]" :data-source="filtered_list(accident_list)" bordered size="small"
                        :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'id'">
                                <a-row>
                                    <!-- <a @click="edit_repair_status(record.id, record.accident_status_id)">
                                        <span class="material-symbols-outlined" style="font-size: 14px">queue_play_next</span>
                                    </a> -->
                                    <a @click="edit_repair_status(record.id, record)"><span style="font-size: 14px"
                                        class="material-symbols-outlined">menu</span></a>
                                    <a-divider type="vertical"></a-divider>
                                    <router-link :to="`/app/accident/${record.id}`">
                                        <span style="font-size: 14px" class="material-symbols-outlined">drive_file_rename_outline</span>
                                    </router-link>
                                    <a-divider type="vertical"></a-divider>
                                    <a @click="remove_accident(record.id)">
                                        <span style="font-size: 18px" class="material-symbols-outlined">delete</span>
                                    </a>
                                </a-row>
                            </template>
                            <template v-else-if="column.key === 'accident_details'">
                                {{ record.accident_details?.map(({ detail }) => detail).join(', ')}}
                            </template>
                            <template v-else-if="column.key === 'car_company'">
                                {{ record.car_company?.fullname }}
                            </template>
                            <template v-else-if="column.key === 'car'">
                                {{ record.car?.plate_no }}
                            </template>
                            <template v-else-if="column.key === 'car_no'">
                                {{ record.car?.car_no }}
                            </template>
                            <template v-else-if="column.key == 'accident_date' || column.key == 'issue_start' || column.key == 'issue_end'">
                                {{ render_date(record[column.key]) }}
                            </template>
                            <template v-else-if="column.key === 'accident_status'">
                                <div :style="repairStatusStyle(record?.accident_status?.id)">
                                    {{ accident_status_list.find((statusList)=>statusList.id == record?.accident_status?.id)?.name??'-' }}
                                </div>
                            </template>
                        </template>
                    </a-table>
                </a-row>
            </a-card>
        </div>

        <div v-else class="app-content">
            <a-card title="รายการแจ้งอุบัติเหตุ">
                <template #extra>
                    <a-button style="display: flex" @click="$router.go(-1)" size="large">
                        <template #icon>
                            <span class="material-symbols-outlined">arrow_back_ios</span>
                        </template>
                        ย้อนกลับ
                    </a-button>
                </template>
            </a-card>
            <div style="margin: 8px 0">
                <a-form 
                    :model="create_accident" 
                    name="create_accident" 
                    autocomplete="off" 
                    layout="vertical"
                    @finish="submit_create_accident"
                    @finishFailed="submit_create_accident_failed"
                >
                    <a-collapse v-model:activeKey="activeKey">
                        <a-collapse-panel key="1" :header="`ข้อมูลที่ต้องการแจ้งอุบัติเหตุ`">
                            <template #extra>
                                <p v-if="[
                                    create_accident.car_company_id,
                                    create_accident.car_id,
                                    create_accident.accident_date,
                                    create_accident.issue_start,
                                    create_accident.issue_end,
                                    ...(businessTypeId != 4 ? [create_accident.client_contact_id] : [])
                                ].filter(e => e == null).length" style="margin: 0;color: grey">
                                    {{`(ต้องระบุอีก ${[
                                        create_accident.car_company_id,
                                        create_accident.car_id,
                                        create_accident.accident_date,
                                        create_accident.issue_start,
                                        create_accident.issue_end,
                                        ...(businessTypeId != 4 ? [create_accident.client_contact_id] : [])
                                    ].filter(e => e == null).length})`}}
                                </p>
                            </template>
                            <a-row>
                                <a-col :span="6">
                                    <a-form-item label="บริษัทรถ" name="car_company_id" :rules="[{ required: true, message: 'โปรดเลือกบริษัทรถ' }]">
                                        <a-select v-model:value="create_accident.car_company_id" placeholder="เลือกบริษัท" :disabled="create_accident.accident_status_id == 2 || create_accident.accident_status_id == 3">
                                            <a-select-option v-for="{ id, fullname }, i in car_company_list" :key="i" :value="id">{{ fullname }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                </a-col>
                                <a-col :span="6">
                                    <a-form-item :label="businessTypeId == 4 ? 'รถยนต์ที่ต้องการแจ้งอุบัติเหตุ' : 'รถที่เกิดอุบัติเหตุ'" name="car_id" 
                                        :rules="[{ required: true, message: 'โปรดเลือกรถ' }]">
                                        <a-select v-model:value="create_accident.car_id" placeholder="เลือกรถ" 
                                            :disabled="create_accident.accident_status_id == 2 || create_accident.accident_status_id == 3"
                                            @change="select_car(create_accident.car_id)">
                                            <a-select-option v-for="{ id, plate_no }, i in car_list.filter(e => create_accident.car_company_id? e.car_company_id == create_accident.car_company_id:true)" :key="i" :value="id">{{ plate_no }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                </a-col>
                            </a-row>
                            <a-row>
                                <a-col :span="6">
                                    <a-form-item :label="businessTypeId == 4 ? 'วันที่แจ้งอุบัติเหตุ' : 'วันที่เกิดเหตุ'" name="accident_date" :rules="[{ required: true, message: 'โปรดระบุวันที่เกิดเหตุ' }]">
                                        <a-date-picker v-model:value="create_accident.accident_date" :placeholder="businessTypeId == 4 ? 'วันที่แจ้งอุบัติเหตุ' : 'วันที่เกิดเหตุ'" 
                                            :show-time="{ format: 'HH:mm' }"
                                            format="DD/MM/YYYY HH:mm"
                                            :minuteStep="1"
                                            :locale="lang_th"
                                            :disabled="create_accident.accident_status_id == 2 || create_accident.accident_status_id == 3"
                                        />
                                    </a-form-item>
                                </a-col>
                                <a-col :span="6">
                                    <a-form-item :label="businessTypeId == 4 ? 'วันที่รับแจ้งอุบัติเหตุ' : 'วันที่รับแจ้งเหตุ'" name="issue_start" :rules="[{ required: true, message: 'โปรดระบุวันที่รับแจ้ง' }]">
                                        <a-date-picker v-model:value="create_accident.issue_start" :placeholder="businessTypeId == 4 ? 'วันที่รับแจ้งอุบัติเหตุ' : 'วันที่รับแจ้ง'"
                                            :show-time="{ format: 'HH:mm' }"
                                            format="DD/MM/YYYY HH:mm"
                                            :minuteStep="1"
                                            :locale="lang_th"
                                            :disabled="create_accident.accident_status_id == 2 || create_accident.accident_status_id == 3"
                                        />
                                    </a-form-item>
                                </a-col>
                                <a-col :span="6">
                                    <a-form-item :label="businessTypeId == 4 ? 'วันที่รับแจ้งอุบัติเหตุเสร็จสิ้น' : 'วันที่แจ้งเสร็จสิ้น'" name="issue_end" :rules="[{ required: true, message: 'โปรดระบุวันที่แจ้งเสร็จสิ้น' }]">
                                        <a-date-picker v-model:value="create_accident.issue_end" :placeholder="businessTypeId == 4 ? 'วันที่รับแจ้งอุบัติเหตุเสร็จสิ้น' : 'วันที่แจ้งเสร็จสิ้น'"
                                            :show-time="{ format: 'HH:mm' }"
                                            format="DD/MM/YYYY HH:mm"
                                            :minuteStep="1"
                                            :locale="lang_th"
                                            :disabled="create_accident.accident_status_id == 2 || create_accident.accident_status_id == 3"
                                         />
                                    </a-form-item>
                                </a-col>
                            </a-row>
                            <a-row>
                                <a-col :span="6">
                                    <a-form-item label="ทะเบียนรถ" name="plate_no">
                                        <a-input v-model:value="selected_car.plate_no" disabled placeholder="ทะเบียนรถ" />
                                    </a-form-item>
                                </a-col>
                                <a-col :span="6">
                                    <a-form-item label="ประเภทรถ" name="car_group_name">
                                        <a-input v-model:value="selected_car.car_type_name" disabled placeholder="ประเภทรถ" />
                                    </a-form-item>
                                </a-col>
                                <a-col :span="6">
                                    <a-form-item label="ชื่อพนักงานขับรถ" name="driver_name">
                                        <a-input v-model:value="selected_car.driver_name" disabled placeholder="ชื่อพนักงานขับรถ" />
                                    </a-form-item>
                                </a-col>
                                <a-col :span="6">
                                    <a-form-item label="เบอร์โทรคนขับ" name="driver_phone_no">
                                        <a-input v-model:value="selected_car.driver_phone_no" disabled placeholder="เบอร์โทรคนขับ" />
                                    </a-form-item>
                                </a-col>
                            </a-row>
                            <a-row>
                                <a-col v-if="businessTypeId != 4" :span="6">
                                    <a-form-item label="ลูกค้า / ผู้ประสานงาน" name="client_contact_id" :rules="[{ required: true, message: 'โปรดระบุลูกค้า / ผู้ประสานงาน' }]">
                                        <a-select v-model:value="create_accident.client_contact_id" placeholder="เลือกลูกค้า / ผู้ประสานงาน">
                                            <a-select-option v-for="{ id, firstname, lastname }, i in client_contact_list" :key="i" :value="id">{{ firstname }} {{ lastname }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                </a-col>
                                <a-col v-if="businessTypeId != 4" :span="6">
                                    <a-form-item label="เบอร์โทรผู้ประสานงาน" name="client_phone_no">
                                        <a-input v-model:value="create_accident.client_phone_no" disabled placeholder="เบอร์โทรผู้ประสานงาน" />
                                    </a-form-item>
                                </a-col>
                            </a-row>
                            <a-row>
                                <a-col v-if="businessTypeId != 4" :span="6">
                                    <a-form-item label="ประเภทกลุ่มลูกค้า" name="tour_id" >
                                        <a-input v-model:value="create_accident.tour_id" disabled placeholder="ประเภทกลุ่มลูกค้า" />
                                    </a-form-item>
                                </a-col>
                                <a-col v-if="businessTypeId != 4" :span="6">
                                    <a-form-item label="เลขที่อ้างอิง" name="refno">
                                        <a-input v-model:value="create_accident.refno" placeholder="เลขที่อ้างอิง" />
                                    </a-form-item>
                                </a-col>
                            </a-row>
                        </a-collapse-panel>
                        <a-collapse-panel key="2" header="รายการแจ้งอุบัติเหตุ" >
                            <a-row>
                                <a-col :span="24">
                                    <a-card title="รายการแจ้งอุบัติเหตุ">
                                        <template #extra>
                                            <a-button v-if="create_accident.accident_status_id != 2 && create_accident.accident_status_id != 3" style="display: flex" type="primary" @click="add_accident_detail()" size="large">
                                                <template #icon>
                                                    <span class="material-symbols-outlined">add</span>
                                                </template>
                                                รายการอุบัติเหตุ
                                            </a-button>
                                        </template>
                                        
                                        <a-table :columns="[
                                            {
                                                title: 'ลำดับ',
                                                dataIndex: 'index',
                                                key: 'index',
                                                width: 50,
                                                align: 'center',
                                            }, 
                                            {
                                                title: 'อาการ',
                                                dataIndex: 'detail',
                                                key: 'detail',
                                                width: 125,
                                                align: 'center',
                                            }, 
                                            {
                                                title: 'รายละเอียด',
                                                dataIndex: 'remark',
                                                key: 'remark',
                                                width: 175,
                                                align: 'center',
                                            }, 
                                            {
                                                title: 'สถานที่เกิดเหตุ',
                                                dataIndex: 'location',
                                                key: 'location',
                                                width: 175,
                                                align: 'center',
                                            }, 
                                            {
                                                title: 'ระบุฝ่ายถูก/ผิด',
                                                dataIndex: 'guilty',
                                                key: 'guilty',
                                                width: 175,
                                                align: 'center',
                                            },
                                            {
                                                title: 'ทะเบียนรถคู่กรณี',
                                                dataIndex: 'plate_no',
                                                key: 'plate_no',
                                                width: 175,
                                                align: 'center',
                                            },
                                            {
                                                title: 'ชื่อ-สกุล คู่กรณี',
                                                dataIndex: 'fullname',
                                                key: 'fullname',
                                                width: 175,
                                                align: 'center',
                                            },
                                            {
                                                title: 'เบอร์โทรคู่กรณี',
                                                dataIndex: 'phone_no',
                                                key: 'phone_no',
                                                width: 175,
                                                align: 'center',
                                            },
                                            {
                                                title: 'ชื่อ-สกุล ประกัน',
                                                dataIndex: 'insure_fullname',
                                                key: 'insure_fullname',
                                                width: 175,
                                                align: 'center',
                                            },
                                            {
                                                title: 'เบอร์โทร ประกัน',
                                                dataIndex: 'insure_phone_no',
                                                key: 'insure_phone_no',
                                                width: 175,
                                                align: 'center',
                                            },
                                            {
                                                title: 'ชื่อ-สกุล ตำรวจ',
                                                dataIndex: 'police_fullname',
                                                key: 'police_fullname',
                                                width: 175,
                                                align: 'center',
                                            },
                                            {
                                                title: 'เบอร์โทร ตำรวจ',
                                                dataIndex: 'police_phone_no',
                                                key: 'police_phone_no',
                                                width: 305,
                                                align: 'center',
                                            },
                                            ...(create_accident.accident_status_id != 2 && create_accident.accident_status_id != 3 ? [{
                                                title: 'ตัวเลือก',
                                                dataIndex: 'action',
                                                key: 'action',
                                                width: 100,
                                                align: 'center',
                                                fixed: 'right'
                                            }] : [])
                                        ]" :data-source="create_accident.accident_details" bordered size="small" :scroll="{ x: 2000 }">
                                            <template #bodyCell="{ column, record, index }">
                                                <template v-if="column.key === 'index'">
                                                    {{ index + 1 }}
                                                </template>
                                                <template v-if="column.key === 'guilty'">
                                                    <span>{{ record.guilty === 1 ? 'ถูก' : 'ผิด' }}</span>
                                                </template>
                                                <template v-if="column.key === 'action'">
                                                    <a-row>
                                                        <a @click="edit_accident_details(index, record)"><span style="font-size: 14px"
                                                                class="material-symbols-outlined">drive_file_rename_outline</span></a>
                                                        <a-divider type="vertical"></a-divider>
                                                        <a @click="remove_accident_details(index, record)">
                                                            <span style="font-size: 14px" class="material-symbols-outlined">delete</span>
                                                        </a>
                                                    </a-row>
                                                </template>
                                            </template>
                                        </a-table>
                                    </a-card>
                                </a-col>
                            </a-row>
                            <a-row>
                                <a-col :span="24">
                                    <a-card title="เอกสารที่เกี่ยวข้อง">
                                        <div v-if="create_accident.accident_status_id == 2 && fileList.length < 1" class="image-container">
                                            <a-upload v-model:file-list="fileList" list-type="picture-card"
                                                :disabled="create_accident.accident_status_id == 2"
                                                class="avatar-uploader"
                                                action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                                @change="upload_files($event, 'create_photo')" method="GET"
                                                @remove="remove_uploaded_file($event)">
                                                <div v-if="fileList?.length < 5" style="height: 104px; width: 104px; padding: 2rem">
                                                    <span style="font-size: 20px" class="material-symbols-outlined">add</span>
                                                    <div style="margin-top: 8px">Upload</div>
                                                </div>
                                            </a-upload>
                                        </div>
                                        <div v-if="create_accident.accident_status_id == 3  && fileList.length < 1" class="image-container">
                                            <a-upload v-model:file-list="fileList" list-type="picture-card"
                                                :disabled="create_accident.accident_status_id == 3"
                                                class="avatar-uploader"
                                                action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                                @change="upload_files($event, 'create_photo')" method="GET"
                                                @remove="remove_uploaded_file($event)">
                                                <div v-if="fileList?.length < 5" style="height: 104px; width: 104px; padding: 2rem">
                                                    <span style="font-size: 20px" class="material-symbols-outlined">add</span>
                                                    <div style="margin-top: 8px">Upload</div>
                                                </div>
                                            </a-upload>
                                        </div>

                                        <div v-if="create_accident.accident_status_id != 1 && create_accident.photo_1" class="image-container" style="margin-right: 10px;">
                                            <img :src="create_accident.photo_1" class="uploaded-image-table" alt="uploaded image" />
                                            <div class="icon-container">
                                                <span class="material-symbols-outlined preview-icon" @click="showPreview($event, create_accident.photo_1)">
                                                    visibility
                                                </span>
                                                <span class="material-symbols-outlined download-icon"
                                                    @click="getBase64Image($event, create_accident.photo_1)">
                                                    download
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="create_accident.accident_status_id != 1 && create_accident.photo_2" class="image-container" style="margin-right: 10px;">
                                            <img v-if="create_accident.photo_2" :src="create_accident.photo_2" class="uploaded-image-table" alt="uploaded image" />
                                            <div class="icon-container">
                                                <span class="material-symbols-outlined preview-icon" @click="showPreview($event, create_accident.photo_2)">
                                                    visibility
                                                </span>
                                                <span class="material-symbols-outlined download-icon"
                                                    @click="getBase64Image($event, create_accident.photo_2)">
                                                    download
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="create_accident.accident_status_id != 1 && create_accident.photo_3" class="image-container" style="margin-right: 10px;">
                                            <img v-if="create_accident.photo_3" :src="create_accident.photo_3" class="uploaded-image-table" alt="uploaded image" />
                                            <div class="icon-container">
                                                <span class="material-symbols-outlined preview-icon" @click="showPreview($event, create_accident.photo_3)">
                                                    visibility
                                                </span>
                                                <span class="material-symbols-outlined download-icon"
                                                    @click="getBase64Image($event, create_accident.photo_3)">
                                                    download
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="create_accident.accident_status_id != 1 && create_accident.photo_4" class="image-container" style="margin-right: 10px;">
                                            <img v-if="create_accident.photo_4" :src="create_accident.photo_4" class="uploaded-image-table" alt="uploaded image" />
                                            <div class="icon-container">
                                                <span class="material-symbols-outlined preview-icon" @click="showPreview($event, create_accident.photo_4)">
                                                    visibility
                                                </span>
                                                <span class="material-symbols-outlined download-icon"
                                                    @click="getBase64Image($event, create_accident.photo_4)">
                                                    download
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="create_accident.accident_status_id != 1 && create_accident.photo_5" class="image-container">
                                            <img v-if="create_accident.photo_5" :src="create_accident.photo_5" class="uploaded-image-table" alt="uploaded image" />
                                            <div class="icon-container">
                                                <span class="material-symbols-outlined preview-icon" @click="showPreview($event, create_accident.photo_5)">
                                                    visibility
                                                </span>
                                                <span class="material-symbols-outlined download-icon"
                                                    @click="getBase64Image($event, create_accident.photo_5)">
                                                    download
                                                </span>
                                            </div>
                                        </div>

                                        <a-upload v-if="create_accident.accident_status_id != 2 && create_accident.accident_status_id != 3" 
                                            v-model:file-list="fileList" list-type="picture-card"
                                            :disabled="create_accident.accident_status_id == 2 || create_accident.accident_status_id == 3"
                                            class="avatar-uploader"
                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                            @change="upload_files($event, 'create_photo')" method="GET"
                                            @remove="remove_uploaded_file($event)">
                                            <div v-if="fileList?.length < 5" style="height: 104px; width: 104px; padding: 2rem">
                                                <span style="font-size: 20px" class="material-symbols-outlined">add</span>
                                                <div style="margin-top: 8px">Upload</div>
                                            </div>
                                        </a-upload>
                                        <!-- {{ create_accident.accident_status_id }} -->
                                    </a-card>
                                </a-col>
                            </a-row>
                            <a-row>
                                <a-col :span="24">
                                    <a-card title="หมายเหตุ (ผู้แจ้งอุบัติเหตุ)">
                                        <a-textarea v-model:value="create_accident.remark" placeholder="หมายเหตุ" :disabled="create_accident.accident_status_id == 2 || create_accident.accident_status_id == 3"/>
                                    </a-card>
                                </a-col>
                            </a-row>
                        </a-collapse-panel>
                    </a-collapse>
                    <a-row justify="end" style="width: 100%">
                        <a-button html-type="submit" type="primary" size="large" style="margin: 4px;text-align: right">
                                <span class="material-symbols-outlined"
                                    style="font-size: 16px;margin-right: 4px">Add</span>
                                    บันทึกข้อมูล
                            </a-button>
                    </a-row>
                </a-form>
            </div>
        </div>

        <a-modal v-model:visible="previewVisible" :footer="null" :mask="false" @cancel="previewVisible = false"
            class="custom-modal" style="background-color: none !important">
            <img :src="previewImage" alt="Preview" style="width: 100%;" />
        </a-modal>

        <a-modal v-model:visible="show_modal" title="เลือกสถานะ" ok-text="บันทึก" cancel-text="ยกเลิก" @ok="save_status">
            <a-row type="flex" style="flex-direction: column">
                <a-form-item label="สถานะ" />
                <a-col flex="1 0">
                    <a-select style="width: 100%" v-model:value="computedSelectedStatus" placeholder="เลือกสถานะ"
                        :dropdownMatchSelectWidth="false">
                        <a-select-option v-for="{ id, name } in accident_status_list.filter(status => status.id !== 1)" :key="id" :value="id">
                        {{ name }}
                        </a-select-option>
                    </a-select>
                </a-col>
            </a-row>
        </a-modal>

        <a-modal v-model:visible="show_create_accident_detail" title="เพิ่มรายการอุบัติเหตุ" ok-text="บันทึก" cancel-text="ปิด" @ok="save_create_accident_detail()">
            <a-form
                :model="create_accident_detail"
                name="create_client" autocomplete="off" layout="vertical"
            >
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="อาการ" name="reason">
                            <a-input v-model:value="create_accident_detail.detail" placeholder="อาการ" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="รายละเอียดเพิ่มเติม" name="remark">
                            <a-input v-model:value="create_accident_detail.remark" placeholder="รายละเอียดเพิ่มเติม" />
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form>
            <a-divider orientation="left" >การออกบันทึกการสอบสวน</a-divider>
            <a-form
                :model="create_accident_detail"
                name="create_client" autocomplete="off" layout="horizontal"
            >
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="สถานที่เกิดเหตุ" name="location" :colon="false">
                            <a-input v-model:value="create_accident_detail.location" placeholder="สถานที่เกิดเหตุ" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="ระบุฝ่ายถูก/ผิด" name="guilty" :colon="false">
                            <a-select v-model:value="create_accident_detail.guilty" placeholder="ระบุฝ่ายถูก/ผิด" >
                                <a-select-option :value="1">ถูก</a-select-option>
                                <a-select-option :value="0">ผิด</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="ทะเบียนรถคู่กรณี" name="plate_no" :colon="false">
                            <a-input v-model:value="create_accident_detail.plate_no" placeholder="ทะเบียนรถคู่กรณี" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="ชื่อ-สกุล คู่กรณี" name="fullname" :colon="false">
                            <a-input v-model:value="create_accident_detail.fullname" placeholder="ชื่อ-สกุล คู่กรณี" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="เบอร์โทรคู่กรณี" name="phone_no" :colon="false">
                            <a-input v-model:value="create_accident_detail.phone_no" placeholder="เบอร์โทรคู่กรณี" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="ชื่อ-สกุล ประกัน" name="insure_fullname" :colon="false">
                            <a-input v-model:value="create_accident_detail.insure_fullname" placeholder="ชื่อ-สกุล ประกัน" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="เบอร์โทร ประกัน" name="insure_phone_no" :colon="false">
                            <a-input v-model:value="create_accident_detail.insure_phone_no" placeholder="เบอร์โทร ประกัน" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="ชื่อ-สกุล ตำรวจ" name="police_fullname" :colon="false">
                            <a-input v-model:value="create_accident_detail.police_fullname" placeholder="ชื่อ-สกุล ตำรวจ" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="เบอร์โทร ตำรวจ" name="police_phone_no" :colon="false">
                            <a-input v-model:value="create_accident_detail.police_phone_no" placeholder="เบอร์โทร ตำรวจ" />
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
dayjs.extend(buddhistEra);
import lang_th from 'ant-design-vue/es/date-picker/locale/th_TH';
import Utility from '../../utility'
import SystemService from '../../api/SystemService';
import { message } from 'ant-design-vue';
import Swal from 'sweetalert2';
import CompanyService from '../../api/CompanyService';
import UserService from '../../api/UserService';

export default {
    components: {  },
    name: 'Accident',
    computed: {
        computedSelectedStatus: {
            get() {
                return this.selected_accident_status === 1 ? 'เริ่ม' : this.selected_accident_status;
            },
            set(value) {
                this.selected_accident_status = value;
            }
        },
        render_type() {
            if(this.$route.params.type != 'add' && this.$route.params.type != 'all'){
                this.init_edit(this.$route.params.type)
                return this.$route.params.type
            } else {
                this.clear_form()
            }
            return this.$route.params.type
        }
    },
    data () {
        return {
            selected_car: {
                plate_no: null,
                car_type_name: null,
                car_type_id: null,
                driver_name: null,
                driver_phone_no: null
            },
            business: false,
            editable: Utility.get_permission_editable_with_key('manage_accident'),
            //Default List
            search_car: '',
            selected_company: null,
            car_company_list: [],
            accident_list: [],
            car_list: [],
            client_contact_list: [],
            fileList: [],
            create_photo: [],
            create_accident: {
                accident_details: []
            },
            lang_th: lang_th,
            activeKey: ['1'],
            create_accident_detail: {
                detail: '',
                remark: '',
                location: '',
                guilty: 0,
                plate_no: '',
                fullname: '',
                phone_no: '',
                insure_fullname: '',
                insure_phone_no: '',
                police_fullname: '',
                police_phone_no: '',
            },
            show_create_accident_detail: false,
            businessTypeId: 0,
            accident_status_list: [],
            show_modal: false,
            selected_accident_id: null,
            selected_accident_status: null,
            previewImage: "",
            previewVisible: false,
        }
    },
    watch: {
        'create_accident.car_id': function(newCarId) {
            this.select_car(newCarId);
        }
    },
    methods: {
        async select_car(car_id) {
            await SystemService.get_id('Car', car_id)
                .then(res => {
                    this.selected_car = res
                    this.selected_car.car_type_name = this.selected_car.car_type?.name
                    this.selected_car.car_type_id = this.selected_car.car_type?.id
                    const { driver } = this.selected_car
                    this.selected_car.driver_name = driver?.firstname
                    this.selected_car.driver_phone_no = driver?.phone_no
                })
        },
        showPreview(e, fileUrl) {
            e.stopPropagation();
            this.previewImage = fileUrl;
            this.previewVisible = true;
        },
        async getBase64ImageFromURL(url) {
            const res = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            });
            const blob = await res.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        },
        async getBase64Image(e, file) {
            e.stopPropagation();
            e.preventDefault();

            if (typeof file === 'string') {
                const result = await this.getBase64ImageFromURL(file);
                let a = document.createElement('a');
                a.href = result;
                a.download = 'image.png';
                a.click();
            } else if (file.url) {
                const result = await this.getBase64ImageFromURL(file.url);

                // download
                let a = document.createElement('a');
                a.href = result;
                a.download = 'image.png';
                a.click();
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => {
                    const base64String = reader.result;
                    let a = document.createElement('a');
                    a.href = base64String;
                    a.download = 'image.png';
                    a.click();
                };
            }
        },
        async save_status() {
            const res = await SystemService.update_all('Accident', { data: { accident_status_id: this.selected_accident_status } }, this.selected_accident_id)
            // console.log('333',res)
            this.selected_accident_status = null
            this.show_modal = false
            this.init_options()
            Swal.fire(
                'แก้ไขสถานะสำเร็จ!',
                'แก้ไขสถานะรายการแจ้งอุบัติเหตุสำเร็จแล้ว',
                'success'
            )
        },
        hexToRgba(hex, alpha) {
            hex = hex.replace('#', '');
            const bigint = parseInt(hex, 16);
            const r = (bigint >> 16) & 255;
            const g = (bigint >> 8) & 255;
            const b = bigint & 255;
            return `rgba(${r}, ${g}, ${b}, ${alpha})`;
        },
        repairStatusStyle(statusId) {
            let color = '';
            switch (statusId) {
                case 1:
                    color = '#FFA64D';
                    break;
                case 2:
                    color = '#FD00CF';
                    break;
                case 3:
                    color = '#389E62';
                    break;
                default:
                    color = '#FFA64D';
                    break;
            }
            const opacityBackground = this.hexToRgba(color, 0.3);
            return `border: 1px solid ${color}; 
                    background-color: ${opacityBackground}; 
                    color: ${color};
                    display: inline-block;
                    padding: 4px 8px;`;
        },
        edit_accident_details(index, record) {
            this.create_accident_detail = { ...record };
            this.show_create_accident_detail = true;
        },
        remove_accident_details(index, data){
            if(data.id){
                Swal.fire({
                title: 'คุณต้องการลบรายการนี้ใช่หรือไม่?',
                text: "คุณจะไม่สามารถกู้คืนรายการนี้ได้!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่, ลบรายการนี้!',
                cancelButtonText: 'ยกเลิก'
                }).then((result) => {
                    if (result.isConfirmed) {
                        SystemService.delete_all('AccidentDetail',data.id).then(res => {
                            this.create_accident.accident_details.splice(index, 1)
                            Swal.fire(
                                'ลบรายการสำเร็จ!',
                                'รายการของคุณถูกลบแล้ว',
                                'success'
                            )
                        })
                    }
                })
            }else{
                this.create_accident.accident_details.splice(index, 1)
            }
        },
        async get_business_type(){
            try {
                const data = await UserService.get_user_profile();
                let result = await CompanyService.getBusinessId(data.company_id);
                this.businessTypeId = result.data;
                return this.businessTypeId;
            } catch (error) {
                console.error('Error fetching business type:', error);
            }
        },
        edit_repair_status(id, accident) {
            const { accident_status_id } = accident
            this.show_modal = true
            this.selected_accident_id = id
            this.selected_accident_status = accident_status_id
        },
        render_date(datetime) {
            return dayjs(datetime).format('DD/MM/YYYY HH:mm')
        },
        async remove_accident(id) {
            Swal.fire({
                title: 'คุณต้องการลบข้อมูลนี้ใช่หรือไม่?',
                text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
                cancelButtonText: 'ยกเลิก'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await SystemService.delete_all('Accident', id)
                    this.init_options()
                    Swal.fire(
                        'ลบข้อมูลสำเร็จ!',
                        'ข้อมูลของคุณถูกลบแล้ว',
                        'success'
                    )
                }
            })
        },
        async upload_files(e, key) {
            this.getBase64(e.file.originFileObj, key)
            return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`
        },
        async remove_uploaded_file({ uid }) {
            this.create_accident[uid] = ''
        },
        async getBase64(file, key) {
            try {
                if (key) {
                    const vue = this
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                        vue[key] = reader.result
                    };
                } else {
                    return await new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = error => reject(error);
                    });
                }
            } catch (err) {

            }
        },
        add_accident_detail(data) {
            this.show_create_accident_detail = true
            if (data) {
                this.create_accident_detail = data
            }
        },
        clear_form(detail_only = false) {
            if (!detail_only) {
                this.create_accident = {
                    accident_details: []
                }
            }
            this.create_accident_detail = {
                detail: '',
                remark: '',
                location: '',
                guilty: 0,
                plate_no: '',
                fullname: '',
                phone_no: '',
                insure_fullname: '',
                insure_phone_no: '',
                police_fullname: '',
                police_phone_no: '',
            }
        },
        async save_create_accident_detail() {
            const body = {
                accident_id: this.create_accident.id,
                detail: this.create_accident_detail.detail,
                remark: this.create_accident_detail.remark,
                location: this.create_accident_detail.location,
                guilty: this.create_accident_detail.guilty,
                plate_no: this.create_accident_detail.plate_no,
                fullname: this.create_accident_detail.fullname,
                phone_no: this.create_accident_detail.phone_no,
                insure_fullname: this.create_accident_detail.insure_fullname,
                insure_phone_no: this.create_accident_detail.insure_phone_no,
                police_fullname: this.create_accident_detail.police_fullname,
                police_phone_no: this.create_accident_detail.police_phone_no,
                company_id: JSON.parse(localStorage.getItem('companyData')).id
            }
            const updatedDetails = await SystemService.get_all('AccidentDetail')
            this.create_accident_detail.accident_id = this.create_accident.id

            if (this.create_accident_detail.id) {
                await SystemService.update_all('AccidentDetail', { data: body }, this.create_accident_detail.id).then(res => {
                    this.$message.success('แก้ไขข้อมูลสำเร็จ')
                    if (this.create_accident.id != null) {
                        window.location.reload()
                    }
                    this.create_accident.accident_details = updatedDetails.filter(detail => detail.accident_id === this.create_accident.id)
                })
            } else {
                console.log('222')
                if (this.create_accident.id) {
                    await SystemService.create_all('AccidentDetail', { data: [body] }).then(res => {
                        console.log('kkk',res)
                        this.$message.success('บันทึกข้อมูลสำเร็จ')
                        if (this.create_accident.id != null) {
                            window.location.reload()
                        }
                        // this.create_accident_detail.id = res.data.id
                    })
                }
            }


            if (this.create_accident.id == null) {
                const existingDetailIndex = this.create_accident.accident_details.findIndex(item => item.accident_id === this.create_accident_detail.accident_id)
                console.log('8888', existingDetailIndex)
                console.log('999', this.create_accident.id)
                if (existingDetailIndex !== -1) {
                    console.log('878',this.create_accident_detail)
                    this.create_accident.accident_details.splice(existingDetailIndex, 1, this.create_accident_detail)
                } else {
                    this.create_accident.accident_details.push(this.create_accident_detail)
                }

                this.clear_form(true)
                this.show_create_accident_detail = false
            } else {
                if (this.create_accident.accident_details.map((item) => item.detail).includes(this.create_accident_detail.detail)) {
                    var target = this.create_accident.accident_details.find((item) => item.detail == this.create_accident_detail.detail)
                    target = this.create_accident_detail
                    this.clear_form(true)
                    this.show_create_accident_detail = false
                } else {
                    this.create_accident.accident_details.push(this.create_accident_detail)
                    this.clear_form(true)
                    this.show_create_accident_detail = false
                }
            }
        },
        async submit_create_accident() {
            message.loading({ content: 'กำลังบันทึกข้อมูล...', key: 'create_accident' })
            const result = this.create_accident
            result.company_id = JSON.parse(localStorage.getItem('companyData')).id

            //concat Dates
            // const accident_date = dayjs(this.create_accident.accident_date).format('DD/MM/YYYY HH:mm:ss')
            // const issue_start = dayjs(this.create_accident.issue_start).format('DD/MM/YYYY HH:mm:ss')
            // const issue_end = dayjs(this.create_accident.issue_end).format('DD/MM/YYYY HH:mm:ss')
            // result.accident_date = accident_date
            // result.issue_start = issue_start
            // result.issue_end = issue_end

            //concat Files
            if (this.fileList) {
                for (var i=0;i<this.fileList.length;i++) {
                    const target = this.fileList[i]
                    this.create_accident['photo_' + (i + 1)] = await this.getBase64(target.originFileObj)
                }
            }

            if (result.id) {
                await SystemService.update_all('Accident', { data: result }, result.id)
                    .then((res) => {
                        message.success({ content: 'บันทึกข้อมูลสำเร็จ', key: 'update_accident' })
                        this.$router.go(-1)
                        this.init_options()
                    })
                    .catch((err) => {
                        console.log(err);
                        message.error({ content: 'บันทึกข้อมูลไม่สำเร็จ', key: 'update_accident' })
                    })

            } else {
                await SystemService.create_all('Accident', { data: [result] })
                    .then((res) => {
                        message.success({ content: 'บันทึกข้อมูลสำเร็จ', key: 'create_accident' })
                        this.$router.go(-1)
                        this.init_options()
                    })
                    .catch((err) => {
                        console.log(err);
                        message.error({ content: 'บันทึกข้อมูลไม่สำเร็จ', key: 'create_accident' })
                    })
            }
        },
        submit_create_accident_failed(){
            message.error('กรุณากรอกข้อมูลให้ครบถ้วน')
        },
        clear_data () {
            this.search_car = ''
            this.selected_company = null
            this.car_company_list = []
            this.accident_list = []
        },
        filtered_list (array) {
            return array.filter((item) => {
                if (this.search_car) {
                    return item.car.plate_no.includes(this.search_car)
                }
                if (this.selected_company && item.car_company_id !== this.selected_company) return false
                return true
            }).sort((a,b) => b.id - a.id)
        },
        clear_search () {
            this.search_car = ''
            this.selected_company = null
        },
        async init_options () {
            const car_company = await SystemService.get_all('CarCompany')
            this.car_company_list = car_company
            const businessType = await CompanyService.getBusinessId(this.car_company_list[0].company_id)
            if ( businessType.data === 2){
                this.business = true
            }

            const accident = await SystemService.get_all('Accident')
            this.accident_list = accident

            const car_list = await SystemService.get_all('Car')
            this.car_list = car_list

            const client_contact_list = await SystemService.get_all('ClientContact')
            this.client_contact_list = client_contact_list

            const accident_status = await SystemService.get_all('AccidentStatus')
            this.accident_status_list = accident_status
        },
        async init_edit(id) {
            const res = await SystemService.get_id('Accident', id)
            
            if (res.accident_details[0]) {
                const { photo_1, photo_2, photo_3, photo_4, photo_5 } = res.accident_details[0]
                this.fileList = [photo_1, photo_2, photo_3, photo_4, photo_5].filter(e => e).map((e, i) => {
                    return { 
                        uid: `photo_${i+1}`,
                        name: e.split('/').pop(),
                        status: 'done',
                        url: e
                    }
                })
            }

            const { photo_1, photo_2, photo_3, photo_4, photo_5 } = res
            this.fileList = [photo_1, photo_2, photo_3, photo_4, photo_5].filter(e => e).map((e, i) => {
                return { 
                    uid: `photo_${i+1}`,
                    name: e.split('/').pop(),
                    status: 'done',
                    url: e
                }
            })

            const { accident_date, issue_start, issue_end, car, driver, client_contact } = res
            this.create_accident = res
            this.create_accident.plate_no = car? car.plate_no:null
            this.create_accident.driver_name = driver? `${driver.firstname} ${driver.lastname}`:null
            this.create_accident.driver_phone_no = driver? driver.phone_no:null
            this.create_accident.client_phone_no = client_contact? client_contact.phone_no:null

            this.create_accident.accident_date = dayjs(accident_date, 'YYYY-MM-DD HH:mm:ss')
            this.create_accident.issue_start = dayjs(issue_start, 'YYYY-MM-DD HH:mm:ss')
            this.create_accident.issue_end = dayjs(issue_end, 'YYYY-MM-DD HH:mm:ss')

            
        }
    },
    mounted () {
        this.init_options()
        this.get_business_type();
    },
}
</script>
<style scoped>

.ant-row {
    margin: 5px 0
}
.ant-col-12, .ant-col-24, .ant-col-4, .ant-col-8, .ant-col-6 {
    padding: 0 4px
}
.ant-select, .ant-picker {
    width: 100%
}
.ant-col {
    width: 100%
}
.ant-form-item {
    margin-bottom: 0;
}
.quotation-container {
    padding: 15px;
    height: 100vh;
}
.blue-card {
    background-color: #1890ff;
    color: white;
    border-radius: 5px;
    margin: 5px
}
.red-card {
    background-color: #a2a2a2;
    color: white;
    border-radius: 5px;
    margin: 5px
}
.ant-table-thead .center-header {
  text-align: center; /* จัดตำแหน่งหัวคอลัมน์ให้อยู่ตรงกลาง */
}
.image-container {
    position: relative;
    display: inline-block;
}
.uploaded-image-table {
    display: block;
    width: 12rem;
    height: auto;
}
</style>