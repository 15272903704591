<template>
    <div style="padding: 15px;height: 100vh">
      <a-spin :spinning="loading">
        <div v-if="render_type == 'all'" class="app-content">
          <div class="app-header">
            <h2>ใบงาน</h2>
          </div>
          <a-divider />
          <a-space style="width: 100%; justify-content: end; margin-bottom: 16px">
            <router-link :to="`/app/sheet/add`">
                <a-button
                  @click="init_model"
                    style="display: flex"
                    type="primary"
                    size="large"
                >
                    <template #icon
                    ><span class="material-symbols-outlined">Add</span></template
                    >
                    สร้างใบงาน
                </a-button>
            </router-link>
            <a-button
              style="display: flex"
              type="primary"
              size="large"
              @click="open_create_invoice(selected_quotation)"
              v-if="business == 'car_rental_a'"
            >
              ออกใบเสนอราคา
            </a-button>
          </a-space>
          <a-space style="width: 100%;  margin-bottom: 16px">
            <a-input-search
              v-model:value="search_input"
              placeholder="ค้นหาใบงาน ชื่อลูกค้า/บริษัท"
              enter-button="Search"
            />
  
            <a-select
              :dropdownMatchSelectWidth="false"
              style="width: 160px"
              v-model:value="search_booking_type_id"
              placeholder="ประเภทงานขนส่ง"
              v-if="business != 'car_rental_a'"
            >
              <a-select-option v-for="{ id,name  } in transport_type_list" :key="id" :value="id">
                {{ name }}
              </a-select-option>
            </a-select>
  
            <a-select
              v-model:value="search_sheet_status"
              style="width: 160px"
              placeholder="สถานะของใบงาน"
            >
              <a-select-option :value="1">ใบงานว่าง</a-select-option>
              <a-select-option :value="2">รอรายละเอียด</a-select-option>
              <a-select-option :value="3">มอบหมายงานแล้ว</a-select-option>
            </a-select>
            <a-range-picker
              v-model:value="search_datetime"
              @change="handleTableChange"
            />
            <a-button @click="clear_search()" type="dashed">CLEAR</a-button>
          </a-space>
          <a-table
            rowKey="id"
            :columns="OperationColumn"
            v-bind="business === 'car_rental_a' ? { rowSelection: { type: 'radio', onSelect: select_row } } : {}"
            :data-source="filtered_list(sheets_data)" bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }" :locale="{ emptyText: '' }">
            <template #bodyCell="{ column, record }">
              <!-- <template v-if="column.key === 'booking_type_id' && record[column.key]">
                <span
                  ><a-tag
                    v-if="tag_option.booking_type_id[record.booking_type_id]"
                    :color="tag_option.booking_type_id[record.booking_type_id].color"
                  >
                    {{ tag_option.booking_type_id[record.booking_type_id].txt }}
                  </a-tag></span
                >
              </template> -->
              <template v-if="column.key === 'sheet_status'">
                <span>
                <a-tag v-if="statusText(record)" :color="statusText(record).color">
                  {{ statusText(record).txt }}
                </a-tag>
              </span>
              </template>
              <template v-else-if="column.key === 'car_company' && record[column.key]">
                {{ record.car_company?.fullname }}
              </template>
              <template v-else-if="column.key === 'booking_cancel_text'">
                {{ record.booking_cars[0]?.booking_car_cancels[0]?.remark || record.booking_cars[0]?.booking_turn_downs[0]?.remark  }}
              </template>
              <template v-else-if="column.key === 'data_text_destination'">
                {{ render_des(record) }}
              </template>
              <template v-else-if="column.key === 'client_name'">
                {{ record.client?.fullname }}
              </template>
              <template v-else-if="column.key === 'time_start'">
                {{ render_date(record.time_start,record.time_product) }}
              </template>
              <template v-else-if="column.key === 'updated_at'">
                {{ render_date(record.updated_at,record.updated_at) }}
              </template>
              <template v-else-if="column.key === 'time_end'">
                {{ render_date(record.time_end,record.time_product_finish) }}
              </template>
              <template v-else-if="column.key === 'booking_type'">
                <span>
                <a-tag v-if="transitionText(record)" :color="transitionText(record).color">
                  {{ transitionText(record).txt }}
                </a-tag>
              </span>              
            </template>
              <template v-else-if="column.key === 'operation_name'">
                {{ record.operation?.fullname }}
              </template>
              <template v-else-if="column.key === 'accounting_name'">
                {{ accounting_list.find((item)=>item.id == record.accounting_id)?.fullname }}
              </template>
              <template v-else-if="column.key === 'id'">
                <div>
                  <a :class="{ disabled: record.progress > 0 }" @click=" end_workSheets(record.id, record)"
                    ><span style="font-size: 18px" class="material-symbols-outlined"
                      >send</span
                    ></a
                  >
                  <a-divider type="vertical"></a-divider>
                  <a @click="duplicate_sheet(record.id)"
                    ><span style="font-size: 18px" class="material-symbols-outlined"
                      >difference</span
                    ></a
                  >
                  <a-divider type="vertical"></a-divider>
                  <a @click="go_edit(record.id, record)"
                    ><span style="font-size: 18px" class="material-symbols-outlined"
                      >drive_file_rename_outline</span
                    ></a
                  >
                  <a-divider type="vertical"></a-divider>
                  <a @click="delete_sheet(record.id)"
                    ><span style="font-size: 18px" class="material-symbols-outlined"
                      >delete</span
                    ></a
                  >
                </div>
              </template>
            </template>
          </a-table>
          <ExportPdf
            v-if="show_export_modal"
            :show_export_modal="show_export_modal"
            :booking_detail="booking_detail"
            :user_name="user_name"
            @afterClose="create_export_close"
          />
        </div>
        <div v-else class="app-content">
        <a-card
          :title="
            render_type == 'add'
              ? 'สร้างใบงาน'
              : from_worksheet
              ? `แก้ไข Booking: ${create_sheet.booking_no}`
              : 'แก้ไขใบงาน'
          "
        >
          <template #extra>
            <a-button style="display: flex" @click="$router.go(-1)" size="large">
              <template #icon>
                <span class="material-symbols-outlined">arrow_back_ios</span>
              </template>
              ย้อนกลับ
            </a-button>
          </template>
        </a-card>
        <div style="margin: 8px 0">
          <a-form
            :model="create_sheet"
            name="create_sheet"
            autocomplete="off"
            layout="vertical"
            @finish="render_type === 'add' ? create_multiple_billings():submit_create_sheets()"
          >
          
          <a-collapse style="border-radius: 4px;background-color: #e5e4e2;">
            <a-collapse-panel ref="clientItem" key="1" :header="'โปรเจกต์'">
              <template #extra>
                  <p
                    v-if="[create_sheet.client_id]?.filter((e) => e == null).length"
                    style="margin: 0; color: grey"
                  >
                    {{
                      `(ต้องระบุอีก ${
                        [create_sheet.client_id].filter((e) => e == null).length
                      })`
                    }}
                  </p>
                </template>
              <a-divider orientation="left">บริษัทลูกค้า</a-divider>
              <a-row :gutter="24">
                  <a-col :span="12" v-if="business == 'sjinda_a'">
                    <a-form-item
                      label="บริษัท"
                      name="client_id"
                      :rules="[{ required: true, message: 'โปรดเลือกบริษัท' }]"
                    >
                      <a-select
                        v-model:value="create_sheet.client_id"
                        placeholder="เลือกบริษัท"
                        @change="init_accounting"
                        :disabled="from_worksheet || statusDisable"
                        :options="
                          client_company_list.map(({ id, fullname }) => ({
                            value: id,
                            label: fullname,
                          }))
                        "
                      ></a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" v-if="business == 'sjinda_a'">
                    <a-form-item label="เบอร์โทร" name="sheet_tel">
                      <a-input
                        :disabled="from_worksheet || statusDisable"
                        placeholder="เบอร์โทร"
                        v-model:value="create_sheet.sheet_tel"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="24" v-else>
                    <a-form-item
                      label="บริษัท"
                      name="client_id"
                      :rules="[{ required: true, message: 'โปรดเลือกบริษัท' }]"
                    >
                      <a-select
                        v-model:value="create_sheet.client_id"
                        placeholder="เลือกบริษัท"
                        @change="init_accounting"
                        :disabled="from_worksheet || statusDisable"
                        :options="
                          client_company_list.map(({ id, fullname }) => ({
                            value: id,
                            label: fullname,
                          }))
                        "
                      ></a-select>
                    </a-form-item>
                    <!-- :filter-option="filterOption" -->
                    <!-- show-search -->
                  </a-col>
              </a-row>
              <a-divider orientation="left">โปรเจกต์</a-divider>
              <a-row :gutter="24">
              <a-col :span="12" style="margin-bottom: 20px;">
                <a-form-item name="project">
                        <template #label>
                            <div style="display: flex; align-items: center; gap: 10px;">
                              <a-checkbox-group v-model:value="create_sheet.is_project" :disabled="from_worksheet || statusDisable">
                                <a-checkbox
                                v-model="create_sheet.is_project"
                                value="โปรเจกต์"
                                style="margin-left: 8px;" 
                                @change=enableProject()> 
                            <span>โปรเจกต์</span>

                            </a-checkbox>
                            </a-checkbox-group>
                            </div>
                        </template>
                          <a-select :disabled="isProject || from_worksheet || statusDisable" placeholder="เลือกโปรเจกต์" @change="useProject('change')" v-model:value="create_sheet.project_id"
                          :options="project_list.map(({id,project_name}) => ({
                            value:id,
                            label:project_name,
                          }))">
                          </a-select>
                      </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="ประเภทโปรเจกต์" name="project_type_id">
                  <a-select
                        v-model:value="create_sheet.project_type_id"
                        :disabled="!isProject || from_worksheet || statusDisable"
                        placeholder="เลือกประเภทโปรเจกต์"
                        :options="
                          project_type_list.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))
                        "
                      ></a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12" style="margin-bottom: 15px;" v-if="business != 'car_rental_a'">
                <a-form-item label="ประเภทสินค้า" name="product_type">
                  <a-input
                    :disabled="!isProject || from_worksheet || statusDisable"
                    placeholder="เลือกประเภทสินค้า"
                    v-model:value="create_sheet.product_type"
                  ></a-input>
                </a-form-item>
              </a-col>
              <a-col :span="12" v-if="business != 'car_rental_a'">
                <a-form-item label="ประเภทงานขนส่ง" name="booking_type_id">
                  <a-select
                        v-model:value="create_sheet.booking_type_id"
                        :disabled="!isProject || from_worksheet || statusDisable"
                        placeholder="เลือกประเภทขนส่ง"
                        :options="
                          transport_type_list.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))
                        "
                      ></a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12" v-if="business == 'sjinda_a'">
                <a-form-item label="หมายเลขอ้างอิงใบงาน" name="sheet_no">
                  <a-input
                    :disabled="from_worksheet || statusDisable"
                    placeholder="หมายเลขอ้างอิงใบงาน"
                    v-model:value="create_sheet.sheet_no"
                  ></a-input>
                </a-form-item>
              </a-col>
            </a-row>
            </a-collapse-panel>

            <a-collapse-panel key="2" ref="descriptionPrice" :header="`ตามระยะทาง/เรทราคา`">
              <template #extra>
                  <p
                    v-if="[create_sheet.oil_range,create_sheet.distance_price_rate,create_sheet.origin,create_sheet.datetime,create_sheet.time_product,create_sheet.work_hour,create_sheet.time_product_finish,create_sheet.car_type_id]?.filter((e) => e == null).length && business != 'car_rental_a'"
                    style="margin: 0; color: grey"
                  >
                    {{
                      `(ต้องระบุอีก ${
                        [create_sheet.oil_range,create_sheet.distance_price_rate,create_sheet.origin,create_sheet.datetime,create_sheet.time_product,create_sheet.work_hour,create_sheet.time_product_finish,create_sheet.car_type_id].filter((e) => e == null).length
                      })`
                    }}
                  </p>
                  <p
                    v-if="[this.create_sheet.oil_range,this.create_sheet.distance_price_rate,this.create_sheet.origin,this.create_sheet.time_type_id,this.create_sheet.time_period_id,this.create_sheet.price_date,this.create_sheet.price_time,this.create_sheet.car_type_id,this.create_sheet.driver_id,this.create_sheet.car_id ].filter( (e) => e == null).length && business == 'car_rental_a'"
                    style="margin: 0; color: grey"
                  >
                    {{
                      `(ต้องระบุอีก ${
                        [this.create_sheet.oil_range,this.create_sheet.distance_price_rate,this.create_sheet.origin,this.create_sheet.time_type_id,this.create_sheet.time_period_id,this.create_sheet.price_date,this.create_sheet.price_time,this.create_sheet.car_type_id,this.create_sheet.driver_id,this.create_sheet.car_id].filter( (e) => e == null).length
                      })`
                    }}
                  </p>
                </template>
              <a-divider orientation="left">ช่วงน้ำมัน</a-divider>
              <a-row :gutter="24">
                <a-col :span="24">
                  <a-form-item :label="business == 'car_rental_a' ? 'เปอร์เซ็นต์น้ำมัน' : 'ช่วงน้ำมัน' " name="oil_range" :rules="[{ required: true, message: 'โปรดเลือกช่วงน้ำมัน' }]">
                  <a-select
                        v-model:value="create_sheet.oil_range"
                        :disabled="!isProject || from_worksheet || statusDisable"
                        :placeholder="business == 'car_rental_a' ? 'เลือกเปอร์เซ็นต์น้ำมัน' :'เลือกช่วงน้ำมัน'"
                        :options="
                          oil_range_list.map(({ id, range }) => ({
                            value: id,
                            label: range,
                          }))
                        "
                      >
                  </a-select>
                </a-form-item>
                </a-col>
              </a-row>
              <a-row v-if="business=='car_rental_a'">
                <TableCreateDrawer 
                :divider="'ตารางช่วงน้ำมัน'"
                :table_columns="default_fields.PriceRate.columns" :target_object="create_sheet"
                :target_key="'oilRangeRate'" :drawer_title="'ช่วงน้ำมัน'"
                :drawer_fields="default_fields.PriceRate.drawer_fields.inputs"
                :drawer_button_text="'เพิ่มช่วงน้ำมัน'"
                :drawer_button_text_edit="'แก้ไขช่วงน้ำมัน'"
                :oil_range_choose="index_project"
                :edit_sheet="true"
                :status_disable='statusDisable'
                :target_modelname="'oilRangeRate'" />
              </a-row>
              <a-divider orientation="left">ตามระยะทาง/เรทราคา</a-divider>
              <a-row :gutter="24">
              <a-col :span="24">
                <a-form-item 
                    name="distance_price_rate" 
                    :rules="[{ required: true, message: 'โปรดเลือกตามระยะทาง/เรทราคา' }]"
                    validateTrigger="onSubmit"
                  >
                    <span style="color: red; margin-right: 4px;">*</span>
                    <span>ตามระยะทาง/เรทราคา :</span>
                  <a-radio-group v-model:value="create_sheet.distance_price_rate">
                    <a-radio 
                      :checked="distance_price_rate === 'distance'"
                      @click="toggleOption('distance')"
                      :disabled="!isProject || from_worksheet || statusDisable"
                      value="distance"
                      style="margin-left: 8px;"
                    >
                      <span>ตามระยะทาง</span>
                    </a-radio>

                    <a-radio 
                      :checked="distance_price_rate === 'price'"
                      @click="toggleOption('price')"
                      :disabled="!isProject || from_worksheet || statusDisable"
                      value="price"
                      style="margin-left: 8px;"
                    >
                      <span>เรทราคา</span>
                    </a-radio>
                    </a-radio-group>
                  </a-form-item>
              </a-col>
              <a-col :span="24" v-if="business != 'car_rental_a' || (business == 'car_rental_a' && create_sheet.distance_price_rate)">
                <a-form-item :label="business != 'car_rental_a' ? 'ต้นทาง':'ต้นทาง (สายงาน)'"  name="origin" :rules="[{ required: true, message: 'โปรดเลือกต้นทาง' }]">
                  <!-- <VueGoogleAutocomplete
                  v-if="isInputFocused"
                  ref="origin"
                  id="origin"
                  classname="form-control"
                  :placeholder="create_sheet.origin ? create_sheet.origin : 'เลือกต้นทาง' "
                  v-on:input="create_sheet.origin"
                  v-on:placechanged="select_stop_name($event, 'origin')"
                  types="establishment"
                  country="th"
                  >
                  </VueGoogleAutocomplete> -->
                  <a-input
                    v-if="isProject"
                    :disabled="from_worksheet || statusDisable"
                    v-model:value="create_sheet.origin"
                    ref="origin"
                    id="origin"
                    @change="initMap('origin')"
                    :placeholder="business != 'car_rental_a' ? 'เลือกต้นทาง':'เลือกต้นทาง (สายงาน)'"
                  />
                  <a-select
                  v-if="!isProject"
                    v-model:value="create_sheet.origin"
                    @change="priceCar('origin')"
                    :disabled="from_worksheet || statusDisable"
                    :options="
                      originProjectList.map(({ id, start_place }) => ({
                        value: start_place,
                        label: start_place,
                      }))
                    "
                  ></a-select>
                </a-form-item>
              </a-col>
              <a-col :span="24" v-if="business != 'car_rental_a' || (business == 'car_rental_a' && create_sheet.distance_price_rate)">
                <a-form-item 
                  label="ปลายทาง" 
                  name="destination">
                  <!-- <vue-google-autocomplete
                        id="destination"
                        classname="form-control"
                        placeholder="ปลายทาง"
                        v-on:input="
                          create_sheet.destination = $event.target.value
                        "
                        v-on:placechanged="select_stop_name($event, 'destination')"
                        types="establishment"
                        country="th"
                      >
                      </vue-google-autocomplete> -->
                      <a-input
                        v-if="isProject"
                        :disabled="from_worksheet || statusDisable"
                        v-model:value="create_sheet.destination"
                        ref="destination"
                        id="destination"
                        @change="initMap('destination')"
                        :placeholder="'ปลายทาง'"
                      />
                      <a-select
                      v-if="!isProject"
                      v-model:value="create_sheet.destination"
                      @change="destinationText(create_sheet.destination,'distance')"
                      :disabled="from_worksheet || statusDisable"
                      :options="
                        destinationListSupport.map(({ id, end_place }) => ({
                          value: end_place,
                          label: end_place,
                        }))
                      "
                  ></a-select>
                  </a-form-item>
              </a-col>
              <a-col :span="24" v-if="business != 'car_rental_a' || (business == 'car_rental_a' && create_sheet.distance_price_rate)">
                <!-- <div style="border:1px solid">
                  <span style="display:flex; background-color: #B8BABC;">ปลายทาง</span>
                  <span v-if="create_sheet.data_text_destination">
                    <a class="material-symbols-outlined" @click="moveDown(record,i)"> 
                      arrow_downward
                    </a>
                    <a @click="remove_remark(id,remark_name)"><span style="font-size: 18px; color: red;" class="material-symbols-outlined">delete</span></a>
                    <a class="material-symbols-outlined" @click="moveUp(record,i)"> 
                      arrow_upward
                    </a>
                  </span>
                </div> -->
                <!-- <div v-for="({ id, remark_name, flag_check }, i) in destinations"> -->
                    <div style="margin-bottom:20px;margin-top:20px">
                        <a-table 
                            :columns="[
                            {
                              title: 'ปลายทาง',
                              dataIndex: 'destinations',
                              key: 'destinations',
                              width: 150,
                              align: 'start',
                            },
                          ]" 
                          :data-source="this.destinations" bordered size="small"
                          :pagination="false"
                          >
                            <template #bodyCell="{ column, record, index }">
                                <template v-if="column.key === 'destinations'">
                                    <span style="display: flex; align-items: center;">
                                  <a class="material-symbols-outlined" @click="moveDown(index)" style="cursor: pointer;" :disabled="statusDisable">
                                    arrow_downward
                                  </a>
                                  <a @click="remove_destination(record)" style="font-size: 18px; color: red; vertical-align: middle;" :disabled="statusDisable"
                                  class="material-symbols-outlined">
                                      delete
                                  </a>
                                  <a class="material-symbols-outlined" @click="moveUp(index)" style="cursor: pointer;" :disabled="statusDisable">
                                    arrow_upward
                                  </a>
                                  <span>{{ record }}</span>
                                </span>
                                </template> 
                            </template>
                        </a-table>
                    </div>
                <!-- </div> -->
              </a-col>
              <a-col :span="12" style="margin-bottom: 15px;" v-if="business != 'car_rental_a' || (business == 'car_rental_a' && create_sheet.distance_price_rate)">
                <a-form-item
                    label="วันเวลารับ - ส่ง"
                    name="datetime"
                    :rules="[{ required: true, message: 'โปรดระบุวัน-เวลา รับส่ง' }]"
                    v-if="business != 'car_rental_a'"
                  >
                    <a-range-picker
                      v-model:value="create_sheet.datetime"
                      :disabled="create_sheet.booking_cars && create_sheet.booking_cars[0]?.status != null && create_sheet.booking_cars[0]?.status != 2 && create_sheet.booking_cars[0]?.status != 11 || statusDisable"
                      format="DD/MM/YYYY"
                      :placeholder="['Start Time', 'End Time']"
                      style="width: 100%;"
                      @change="getTotalDate()"
                    />
                  </a-form-item>
                  <a-form-item
                    label="ประเภทเวลางาน"
                    name="time_type_id"
                    :rules="[{ required: true, message: 'โปรดเลือกประเภทเวลางาน' }]"
                    v-else
                  >
                    <a-select
                      :disabled="from_worksheet || statusDisable"
                      v-model:value="create_sheet.time_type_id"
                      placeholder="ประเภทเวลางาน"
                      :options="
                        !isProject && create_sheet.distance_price_rate == 'price'
                        ? priceProjectList.filter((item)=>item.start_place == create_sheet.origin || !create_sheet.origin).map(({ id,time_type }) => ({
                          value: id,
                          label: time_type,
                        })).filter((item, index, self) => 
                        self.findIndex((t) => t.label === item.label) === index)
                        : time_type_list.map(({ id, name }) => ({
                          value: id,
                          label: name,
                        }))
                      "
                    ></a-select>
                  </a-form-item>
              </a-col>
              <a-col :span="6" v-if="business != 'car_rental_a'">
                <div style="display: flex; gap: 20px; width: 100%;">
                  <a-form-item
                    label="จำนวนวัน"
                    name="qulity_date"
                    style="flex: 1;"
                  >
                    <a-input
                      placeholder="จำนวนวัน"
                      v-model:value="totalDays"
                      disabled
                    ></a-input>
                  </a-form-item>
                  <!-- <a-form-item
                    label="เวลารับสินค้า"
                    name="datetime"
                    :rules="[{ required: true, message: 'โปรดระบุวัน-เวลา รับส่ง' }]"
                    style="flex: 1;"
                  >
                    <a-time-picker
                      v-model:value="create_sheet.datetime"
                      @change="auto_fill_days"
                      :show-time="{ format: 'HH:mm' }"
                      format="HH:mm"
                      placeholder="เวลารับสินค้า"
                      :minuteStep="1"
                    />
                  </a-form-item> -->
                </div>
              </a-col>
              <a-col :span="6" v-if="business != 'car_rental_a'">
                <a-form-item
                    label="เวลารับสินค้า"
                    name="time_product"
                    :rules="[{ required: true, message: 'โปรดระบุวัน-เวลา รับส่ง' }]"
                    style="width: 100%;"
                  >
                  <a-time-picker
                    v-model:value="create_sheet.time_product"
                    :disabled="create_sheet.booking_cars && create_sheet.booking_cars[0]?.status != null && create_sheet.booking_cars[0]?.status != 2 && create_sheet.booking_cars[0]?.status != 11 || statusDisable"
                    @change="auto_fill_days"
                    :show-time="{ format: 'HH:mm' }"
                    format="HH:mm"
                    :placeholder="'เวลารับสินค้า'"
                    :minuteStep="1"
                    style="width: 100%;"
                  />
                  </a-form-item>
              </a-col>
              <a-col :span="12" style="margin-bottom: 15px;" v-else-if="create_sheet.distance_price_rate">
                <a-form-item
                    label="กะเวลางาน"
                    name="time_period_id"
                    :rules="[{ required: true, message: 'โปรดเลือกกะเวลางาน' }]"
                  >
                    <a-select
                      :disabled="from_worksheet || statusDisable"
                      v-model:value="create_sheet.time_period_id"
                      placeholder="กะเวลางาน"
                      :options="
                        !isProject && create_sheet.distance_price_rate == 'price'
                        ? priceProjectList.filter((item)=> {
                          var check = true;
                          if(create_sheet.origin){
                            check = item.start_place == create_sheet.origin && check;
                          }
                          if(create_sheet.time_type_id){
                            const nameType  = priceProjectList.find((itemP)=>itemP.id == create_sheet.time_type_id)?.time_type;
                            check = item.time_type == nameType && check;
                          }
                          return check 
                        }).map(({ id,time_period }) => ({
                          value: id,
                          label: time_period,
                        })).filter((item, index, self) => 
                        self.findIndex((t) => t.label === item.label) === index)
                        : time_period_list.map(({ id, name }) => ({
                          value: id,
                          label: name,
                        }))
                      "
                    ></a-select>
                  </a-form-item>
              </a-col>
              <a-col :span="6" style="margin-bottom: 15px;" v-if="business != 'car_rental_a'">
                <a-form-item label="ชั่วโมงวิ่งงาน" name="work_hour" :rules="[{ required: true, message: 'โปรดเลือกชั่วโมงวิ่งงาน' }]">
                  <a-input
                    :disabled="!isProject && !isProjectDistance || create_sheet.booking_cars && create_sheet.booking_cars[0]?.status != null && create_sheet.booking_cars[0]?.status != 2 && create_sheet.booking_cars[0]?.status != 11 || statusDisable"
                    placeholder="ชั่วโมงวิ่งงาน"
                    @change="auto_fill_days"
                    v-model:value="create_sheet.work_hour"
                  ></a-input>
                </a-form-item>
              </a-col>
              <a-col :span="12" style="margin-bottom: 15px;" v-else-if="create_sheet.distance_price_rate">
                <a-form-item label="วันที่" name="price_date" :rules="[{ required: true, message: 'โปรดเลือกวันที่' }]">
                  <a-select
                    :disabled="from_worksheet || statusDisable"
                    v-model:value="create_sheet.price_date"
                    placeholder="วันที่"
                    :options="
                      priceProjectList.filter((item)=> {
                        var check = true;
                        if(create_sheet.origin){
                          check = item.start_place == create_sheet.origin && check;
                        }
                        if(create_sheet.time_type_id){
                          const nameType  = priceProjectList.find((itemP)=>itemP.id == create_sheet.time_type_id)?.time_type;
                          check = item.time_type == nameType && check;
                        }
                        if(create_sheet.time_period_id){
                          const namePeriod  = priceProjectList.find((itemT)=>itemT.id == create_sheet.time_period_id)?.time_period;
                          check = item.time_period == namePeriod && check;
                        }
                        return check 
                      }).map(({ price_date_value, price_date_text }) => ({
                          value: price_date_value,
                          label: price_date_text,
                      })).filter((item, index, self) => 
                      self.findIndex((t) => t.label === item.label) === index)
                    "
                    v-if="!isProject && create_sheet.distance_price_rate == 'price'"
                  ></a-select>
                  <a-date-picker
                    v-model:value="create_sheet.price_date"
                    :disabled="from_worksheet || statusDisable"
                    :placeholder="'วันที่'"
                    format="DD/MM/YYYY"
                    style="width: 100%;"
                    v-else
                  />
                </a-form-item>
              </a-col>
              <a-col :span="6" v-if="business != 'car_rental_a'">
                <a-form-item label="เวลาส่งสินค้า" name="time_product_finish" :rules="[{ required: true, message: 'โปรดเลือกชั่วโมงวิ่งงาน' }]">
                  <a-time-picker
                    v-model:value="create_sheet.time_product_finish"
                    disabled
                    :show-time="{ format: 'HH:mm' }"
                    format="HH:mm"
                    :placeholder="'เวลารับสินค้า'"
                    :minuteStep="1"
                    style="width: 100%;"
                  />
                  </a-form-item>
              </a-col>
              <a-col :span="12" style="margin-bottom: 15px;" v-else-if="create_sheet.distance_price_rate">
                <a-form-item label="เวลา" name="price_time" :rules="[{ required: true, message: 'โปรดเลือกเวลา' }]">
                  <a-select
                    :disabled="from_worksheet || statusDisable"
                    v-model:value="create_sheet.price_time"
                    placeholder="เวลา"
                    :options="
                      priceProjectList.filter((item)=> {
                        var check = true;
                        if(create_sheet.origin){
                          check = item.start_place == create_sheet.origin && check;
                        }
                        if(create_sheet.time_type_id){
                          const nameType  = priceProjectList.find((itemP)=>itemP.id == create_sheet.time_type_id)?.time_type;
                          check = item.time_type == nameType && check;
                        }
                        if(create_sheet.time_period_id){
                          const namePeriod  = priceProjectList.find((itemT)=>itemT.id == create_sheet.time_period_id)?.time_period;
                          check = item.time_period == namePeriod && check;
                        }
                        if(create_sheet.price_date){
                          check = item.price_date_value == create_sheet.price_date && check;
                        }
                        return check 
                      }).map(({ price_time_value, price_time_text }) => ({
                          value: price_time_value,
                          label: price_time_text,
                      })).filter((item, index, self) => 
                      self.findIndex((t) => t.label === item.label) === index)
                    "
                    v-if="!isProject && create_sheet.distance_price_rate == 'price'"
                  ></a-select>
                  <a-time-picker
                    v-model:value="create_sheet.price_time"
                    :disabled="from_worksheet || statusDisable"
                    :show-time="{ format: 'HH:mm' }"
                    format="HH:mm"
                    :placeholder="'เวลา'"
                    :minuteStep="1"
                    style="width: 100%;"
                    v-else
                  />
                </a-form-item>
              </a-col>
              <a-col :span="business != 'car_rental_a'? 6 : 12" v-if="business != 'car_rental_a' || (business == 'car_rental_a' && create_sheet.distance_price_rate)">
                <a-form-item
                  label="ชนิดรถ"
                  name="car_type_id"
                  :rules="[{ required: true, message: 'โปรดเลือกชนิดรถ' }]"
                >
                  <a-select
                    v-if="isProject || (!isProject && isProjectDistance)"
                    :disabled="from_worksheet || statusDisable"
                    v-model:value="create_sheet.car_type_id"
                    placeholder="ชนิดรถ"
                    @change="priceCar('cartype')"
                    :options="
                      car_type_list.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))
                    "
                  ></a-select>
                  <a-select
                  v-if="!isProject && !isProjectDistance && business != 'car_rental_a'"
                    v-model:value="create_sheet.car_type_id"
                    placeholder="ชนิดรถ"
                    @change="priceCar('distance')"
                    :disabled="from_worksheet || statusDisable"
                    :options="
                      priceRateListUsed.map(({ id, name }) => ({
                        value: name,
                        label: name,
                      }))
                    "
                  ></a-select>
                  <a-select
                  v-if="!isProject && !isProjectDistance && business == 'car_rental_a'"
                    v-model:value="create_sheet.car_type_id"
                    placeholder="ชนิดรถ"
                    :disabled="from_worksheet || statusDisable"
                    :options="
                      priceProjectList.filter((item)=> {
                        var check = true;
                        if(create_sheet.origin){
                          check = item.start_place == create_sheet.origin && check;
                        }
                        if(create_sheet.time_type_id){
                          const nameType  = priceProjectList.find((itemP)=>itemP.id == create_sheet.time_type_id)?.time_type;
                          check = item.time_type == nameType && check;
                        }
                        if(create_sheet.time_period_id){
                          const namePeriod  = priceProjectList.find((itemT)=>itemT.id == create_sheet.time_period_id)?.time_period;
                          check = item.time_period == namePeriod && check;
                        }
                        if(create_sheet.price_date){
                          check = item.price_date_value == create_sheet.price_date && check;
                        }
                        if(create_sheet.price_time){
                          check = item.price_time_value == create_sheet.price_time && check;
                        }
                        check = item?.car_type_text && check
                        return check 
                      }).map(({ car_type_text, car_type_id }) => ({
                          value: car_type_id,
                          label: car_type_text,
                      })).filter((item, index, self) => 
                      self.findIndex((t) => t.label === item.label) === index)
                    "
                  ></a-select>
                </a-form-item>
                <!-- :filter-option="filterOption" -->
                <!-- show-search -->
            </a-col>
            <a-col :span="6" v-if="business!='car_rental_a'"> 
              <a-form-item label="จำนวนผู้ช่วยยก" name="assistants">
                <a-input
                  placeholder="จำนวนผู้ช่วยยก"
                  v-model:value="create_sheet.assistants"
                  :disabled="from_worksheet || statusDisable"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12" v-else-if="create_sheet.distance_price_rate">
                <a-form-item
                  label="ทะเบียนรถ"
                  name="car_id"
                  :rules="[{ required: true, message: 'โปรดเลือกทะเบียนรถ' }]"
                >
                  <a-select
                    :disabled="from_worksheet || statusDisable"
                    v-model:value="create_sheet.car_id"
                    @change="driverForCarTwo()"
                    placeholder="ทะเบียนรถ"
                    :options="
                      priceProjectList.filter((item)=> {
                        var check = true;
                        if(create_sheet.origin){
                          check = item.start_place == create_sheet.origin && check;
                        }
                        if(create_sheet.time_type_id){
                          const nameType  = priceProjectList.find((itemP)=>itemP.id == create_sheet.time_type_id)?.time_type;
                          check = item.time_type == nameType && check;
                        }
                        if(create_sheet.time_period_id){
                          const namePeriod  = priceProjectList.find((itemT)=>itemT.id == create_sheet.time_period_id)?.time_period;
                          check = item.time_period == namePeriod && check;
                        }
                        if(create_sheet.price_date){
                          check = item.price_date_value == create_sheet.price_date && check;
                        }
                        if(create_sheet.price_time){
                          check = item.price_time_value == create_sheet.price_time && check;
                        }
                        return check 
                      }).map(({ plate_no ,car_id}) => ({
                          value: car_id,
                          label: plate_no,
                      })).filter((item, index, self) => 
                      self.findIndex((t) => t.label === item.label) === index)
                    "
                    v-if="!isProject && create_sheet.distance_price_rate == 'price'"
                  ></a-select>
                  <a-select
                    :disabled="from_worksheet || statusDisable"
                    v-model:value="create_sheet.car_id"
                    placeholder="ทะเบียนรถ"
                    @change="driverForCar()"
                    :options="
                      getFilteredCarsBy3rd().map(({ id, plate_no, car_no, repair_time_end }) => ({
                      value: id,
                      label: `${plate_no} (${car_no}) ${repair_time_end ? '(แจ้งซ่อม)' : ''}`,
                      disabled: repair_time_end,
                    }))
                    "
                    v-else
                  ></a-select>
                </a-form-item>
            </a-col>
            <a-col :span="6" v-if="business == 'sjinda_a'"> 
              <a-form-item label="ชั่วโมงปฏิเสธงานก่อนเริ่มงาน" name="time_denied">
                <a-input
                  placeholder="ชั่วโมงปฏิเสธงานก่อนเริ่มงาน"
                  v-model:value="create_sheet.time_denied"
                  :disabled="from_worksheet || statusDisable"
                  @input="validateHourInput($event, 'time_denied')"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="6" v-if="business == 'sjinda_a'"> 
              <a-form-item label="จำนวนชิ้นพัสดุ(ชิ้น)" name="product_amount">
                <a-input
                  placeholder="จำนวนชิ้นพัสดุ"
                  v-model:value="create_sheet.product_amount"
                  :disabled="from_worksheet || statusDisable"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="6" v-if="business == 'sjinda_a'"> 
              <a-form-item label="น้ำหนักพัสดุ(กิโลกรัม)" name="product_weight">
                <a-input
                  placeholder="น้ำหนักพัสดุ"
                  v-model:value="create_sheet.product_weight"
                  :disabled="from_worksheet || statusDisable"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12" v-if="business == 'car_rental_a' &&  create_sheet.distance_price_rate">
                <a-form-item
                  name="driver_id"
                  :rules="[{ required: true, message: 'โปรดเลือกคนขับ' }]"
                >
                  <template #label>
                        <div style="display: flex; align-items: center;">
                            <span style="margin-right: 8px;">คนขับ</span>
                            <label class="inline-flex items-center" style="margin-bottom: 1px;">
                                <input
                                  type="checkbox"
                                  v-model="selectedSubDriver"
                                  value="All"
                                  :disabled="from_worksheet || statusDisable"
                                  class="form-checkbox text-blue-600"
                                  style="width: 12px;height: 12px;"
                                  @change="driverSelectChange"
                                />
                                <span style="margin-left: 5px; font-size: 13px;">คนขับสำรอง</span>
                            </label>
                        </div>
                    </template>
                  <a-select
                    v-if="!selectedSubDriver"
                    v-model:value="create_sheet.driver_id"
                    :disabled='true'
                    show-search
                    placeholder="เลือกคนขับรถประจำ"
                    :options="
                    main_and_sub_driver_list.filter((list)=>list.carCompany == create_sheet.car_company_id)
                    "
                  >
                    <template #option="option">
                      <div>
                        <div>{{ option.label }}</div>
                        <div style="color: #888;">{{ option.description }}</div>
                      </div>
                    </template>
                  </a-select>
                  <a-select
                    v-if="selectedSubDriver"
                    v-model:value="create_sheet.driver_id"
                    show-search
                    placeholder="เลือกคนขับรถสำรอง"
                    :disabled="from_worksheet || statusDisable"
                    :options="
                      main_and_sub_driver_list.filter((list)=>list.value != main_driver_id && list.carCompany == create_sheet.car_company_id)
                    "                  >
                    <template #option="option">
                        <div>
                          <div>{{ option.label }}</div>
                          <div style="color: #888;">{{ option.description }}</div>
                        </div>
                    </template>
                  </a-select>
                </a-form-item>
            </a-col>
            <a-col :span="12" style="margin-bottom: 27px;" v-if="business != 'car_rental_a' || (business == 'car_rental_a' && create_sheet.distance_price_rate)">
              <div style="display: flex; gap: 20px; flex:1">
                <div style="display: flex; align-items: center; margin-top:20px; gap:10px ">
                  <a-checkbox-group v-model:value="create_sheet.company_3rd">
                  <a-checkbox  
                        v-model="create_sheet.company_3rd" 
                        :disabled="from_worksheet || statusDisable"
                        style="margin-left: 8px;" 
                        @change="togglethridParty('disable')"
                        value="บริษัทรถร่วม"
                        > 
                    <span>บริษัทรถร่วม</span>
                  </a-checkbox>
                </a-checkbox-group>
                </div>
              <a-form-item
                  label="บริษัทรถ"
                  name="car_company_id"
                  style="flex:1"
                >
                  <a-select
                  :disabled=" from_worksheet || isThird_party || statusDisable"
                  @change="carTypeByCarCompany()"
                    v-model:value="create_sheet.car_company_id"
                    :options="
                      car_company_list.map(({ id, fullname }) => ({
                        value: id,
                        label: fullname,
                      }))
                    "
                  ></a-select>
                </a-form-item>
              </div>
            </a-col>
            <a-col :span=" business != 'car_rental_a' ? 12 : 24" 
            v-if=" business != 'car_rental_a' || (business == 'car_rental_a' && create_sheet.distance_price_rate == 'distance')">
              <a-form-item :label="!isProjectDistance && business != 'car_rental_a' ?'ระยะทางรวม':'ช่วงระยะทาง'" name="distance">
                <a-input
                  :disabled="!isProject || from_worksheet || statusDisable"
                  :placeholder="!isProjectDistance && business != 'car_rental_a' ?'ระยะทางรวม':'ช่วงระยะทาง'"
                  v-model:value="create_sheet.distance"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="24" style="margin-bottom: 15px;" v-if="business != 'car_rental_a'">
              <a-form-item name="price_car" >
                <template #label>
                  <div style="display: flex; align-items: end; gap:10px;">
                    <span>ราคาว่าจ้างรถ</span>
                  <a-button type="primary"
                  :disabled="isProject || statusDisable"
                  @click="priceCar('amount')"
                  >
                    คำนวณ
                  </a-button>
                  </div>
                </template>
                <a-input
                  :disabled="!isProject || from_worksheet || statusDisable"
                  placeholder="จำนวนเงิน (บาท)"
                  v-model:value="create_sheet.price_car"
                ></a-input>
              </a-form-item>
            </a-col>
            </a-row>
            </a-collapse-panel>

            <a-collapse-panel v-if="from_worksheet" key="5" header="รถที่เลือกใช้งาน">
              <a-row>
                <a-col v-if="create_sheet.booking_no">
                  <h1 style="color: red">ข้อมูลอ้างอิงมาจากใบงาน</h1>
                  <div>
                    <h4>1. ข้อมูลการจอง</h4>
                    <div>
                      <span style="font-weight: bold; font-size: 18px; margin-right:5px">ประเภทงานขนส่ง</span>
                      <span style="font-size: 18px; margin-right:5px">{{ getTransportName(create_sheet.booking_type_id) }}</span>
                      <span style="font-weight: bold; font-size: 18px; margin-right:5px">วันที่</span> 
                      <span style="font-size: 18px; margin-right:5px">{{ render_datetime(create_sheet.time_start,create_sheet.time_product) }} - {{ render_datetime(create_sheet.time_end,create_sheet.time_product_finish) }}</span> 
                      <span style="font-weight: bold; font-size: 18px; margin-right:5px">จำนวน</span>
                      <span style="font-size: 18px; margin-right:5px">{{ countDays(create_sheet.time_start, create_sheet.time_end) }}</span>
                      <span style="font-weight: bold; font-size: 18px; margin-right:5px">วัน</span>
                    </div>
                    <div>
                      <span style="font-weight: bold; font-size: 18px; margin-right:5px">ต้นทาง{{ business == 'car_rental_a'? '(สายงาน)' : ''}}</span> 
                      <span style="font-size: 18px; margin-right:5px">{{ create_sheet.origin }}</span>
                    </div>
                    <div>
                      <span style="font-weight: bold; font-size: 18px; margin-right:5px">ปลายทาง</span> 
                      <span style="font-size: 18px; margin-right:5px">{{ render_des(create_sheet) }}</span>
                    </div>
                    <div v-if="business=='car_rental_a'">
                      <span style="font-weight: bold; font-size: 18px; margin-right:5px">ประเภทเวลางาน</span> 
                      <span style="font-size: 18px; margin-right:5px">{{ render_time_type(create_sheet.time_type_id) }}</span>
                      <span style="font-weight: bold; font-size: 18px; margin-right:5px">กะเวลางาน</span> 
                      <span style="font-size: 18px; margin-right:5px">{{ render_period(create_sheet.time_period_id) }}</span>
                    </div>
                    <div v-if="business=='car_rental_a'">
                      <span style="font-weight: bold; font-size: 18px; margin-right:5px">ทะเบียนรถ</span> 
                      <span style="font-size: 18px; margin-right:5px">{{ create_sheet.cars[0]?.plate_no }}</span>
                      <span style="font-weight: bold; font-size: 18px; margin-right:5px">คนขับ</span> 
                      <span style="font-size: 18px; margin-right:5px">{{ get_backup_driver_fullname_with_car(create_sheet.booking_cars[0]?.driver_id)  }}</span>
                    </div>
                    <div style="margin-bottom: 30px;">
                      <span style="font-weight: bold; font-size: 18px; margin-right:5px">ชนิดรถ</span> 
                      <span style="font-size: 18px; margin-right:5px">{{ getCarTypeNames(create_sheet.car_type_id) }}</span>
                      <span style="font-weight: bold; font-size: 18px; margin-right:5px">จำนวน</span> 
                      <span style="font-size: 18px; margin-right:5px">1</span>
                      <span style="font-weight: bold; font-size: 18px; margin-right:5px">คัน</span> 
                    </div>
                  </div>
                </a-col>
              </a-row>
              <a-row>
                <a-col>
                  <a-table
                    rowKey="id"
                    :columns="[
                      {
                        title: 'ลำดับ',
                        dataIndex: 'order_pos',
                        key: 'order_pos',
                        align: 'center',
                        width: 70,
                      },
                      {
                        title: 'บริษัทรถ',
                        dataIndex: 'car_company',
                        key: 'car_company',
                        align: 'center',
                        width: 200,
                      },
                      {
                        title: 'เบอร์รถ',
                        dataIndex: 'car_no',
                        key: 'car_no',
                        width: 100,
                        align: 'center',
                      },
                      {
                        title: 'ทะเบียนรถ',
                        dataIndex: 'plate_no',
                        key: 'plate_no',
                        width: 150,
                        align: 'start',
                      },
                      {
                        title: 'คนขับ',
                        dataIndex: 'driver_id',
                        key: 'driver_id',
                        width: 200,
                        align: 'center',
                      },
                      {
                        title: 'คนขับชั่วคราว',
                        dataIndex: 'temporary_driver',
                        key: 'temporary_driver',
                        width: 200,
                        align: 'center',
                      },
                      {
                        title: 'ชนิดรถ',
                        dataIndex: 'car_type_id',
                        key: 'car_type_id',
                        width: 150,
                        align: 'center',
                      },
                      {
                        title: 'วันเวลารับ-ส่ง',
                        dataIndex: 'start_end',
                        key: 'start_end',
                        width: 300,
                        align: 'center',
                      },
                      {
                        title: 'RollCall',
                        dataIndex: 'rollcall',
                        key: 'rollcall',
                        width: 150,
                        align: 'center',
                      },
                      {
                        title: 'สถานะแจ้งงาน',
                        dataIndex: 'active_car',
                        key: 'active_car',
                        width: 120,
                        align: 'center',
                      },
                      {
                        title: 'สถานะของยานพาหนะ',
                        dataIndex: 'car_status',
                        key: 'car_status',
                        width: 200,
                        align: 'center',
                      },
                      {
                        title: 'สถานะรับงาน',
                        dataIndex: 'last_booking_status',
                        key: 'last_booking_status',
                        width: 200,
                      },
                      ...(business != 'car_rental_a'|| ([15,20,21].includes(create_sheet.booking_cars?.[0]?.status) && business == 'car_rental_a') ? [{
                        title: 'ราคารถ',
                        dataIndex: 'car_payment',
                        key: 'car_payment',
                        align: 'right',
                        width: 150,
                      }] : []),
                      ...(create_sheet.company_3rd != 'บริษัทรถร่วม' ? [{
                        title: 'ค่าจ้างคนขับ',
                        dataIndex: 'driver_salary',
                        key: 'driver_salary',
                        align: 'right',
                        width: 150,
                      }] : []),
                      ...(create_sheet.company_3rd == 'บริษัทรถร่วม' &&  business != 'car_rental_a' ? [{
                        title: 'ค่าแรงงาน (ยกของ)',
                        dataIndex: 'lift_things',
                        key: 'lift_things',
                        align: 'right',
                        width: 150,
                      }] : []),
                      {
                        title: 'รายจ่าย',
                        dataIndex: 'total_cars_expense',
                        key: 'total_cars_expense',
                        align: 'right',
                        width: 150,
                      },
                      {
                        title: 'ตัวเลือก',
                        dataIndex: 'id',
                        align: 'center',
                        width: 150,
                        key: 'id',
                        fixed: 'right',
                      },
                    ]"
                    :data-source="sheetCar"
                    bordered
                    size="small"
                    :pagination="false"
                    :scroll="{ x: 'calc(100px + 50%)', y: '100%' }"
                  >
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key === 'order_pos'">
                        <span> {{ record.booking_cars[0]?.order_pos }} </span>
                      </template>
                      <template v-if="column.key === 'car_company'">
                        <div>{{ gatCarCompanyName(record.car_company_id) }}</div>
                      </template>
                      <template v-if="column.key === 'car_no'">
                        <span> {{ record.booking_cars[0]?.car?.car_no || '' }} </span>
                      </template>
                      <template v-if="column.key === 'plate_no'">
                        <span> {{ record.booking_cars[0]?.car?.plate_no || '' }} </span>
                      </template>
                      <template v-if="column.key === 'driver_id'">
                        <span v-if="get_driver_fullname_with_car(record.booking_cars[0]?.driver_id)">
                          {{ get_driver_fullname_with_car(record.booking_cars[0]?.driver_id) }}
                        </span>
                        <span v-else-if="get_backup_driver_fullname_with_car(record.booking_cars[0]?.driver_id)">
                          {{ get_backup_driver_fullname_with_car(record.booking_cars[0]?.driver_id) }}
                        </span>
                      </template>
                      <template v-if="column.key === 'temporary_driver'">
                        <span> {{ record.booking_cars[0]?.temporary_driver }} </span>
                      </template>
                      <template v-if="column.key === 'car_type_id'">
                        <div>{{ getCarTypeName(record.car_type_id) }}</div>
                      </template>
                      <template v-if="column.key === 'start_end'">
                        <div>{{ render_datetime(record.time_start,record.time_product) }} - {{ render_datetime(record.time_end,record.time_product_finish) }}</div>
                      </template>
                      <template v-else-if="column.key === 'rollcall'">
                        <a-space
                          ><a-tag
                            v-if="record.booking_cars[0].roll_call?.has_roll_call"
                            :color="record.booking_cars[0].roll_call?.allow ? 'green' : 'red'"
                            >{{ record.booking_cars[0].roll_call.allow ? "ผ่าน" : "ไม่ผ่าน" }}</a-tag
                          >
                          <a-tag
                            :color="
                              record.booking_cars[0].roll_call?.has_roll_call ? 'green' : 'yellow'
                            "
                            >{{
                              record.booking_cars[0].roll_call?.has_roll_call ? "เช็คแล้ว" : "รอเช็ค"
                            }}
                          </a-tag>
                        </a-space>
                      </template>
                      <template v-if="column.key === 'active_car'">
                        <a-button
                          type="danger"
                          @click="activate_car(record)"
                          :disabled="record.booking_cars[0]?.active_car == 1"
                        >
                          {{ record.booking_cars[0]?.active_car ? "แจ้งงานแล้ว" : "แจ้งงาน" }}
                        </a-button>
                      </template>
                      <template v-else-if="column.key === 'car_status'">
                        <a-space>
                          <span>{{ record.booking_cars[0]?.car?.car_status || "" }}</span>
                          <a-button
                            type="primary"
                            :disabled="!(record.booking_cars[0]?.car) || record.booking_cars[0]?.car?.car_status_id == 1"
                            @click="confirm_booking_car(record)"
                          >
                            ยืนยัน
                          </a-button>
                        </a-space>
                      </template>
                      <template v-if="column.key === 'driver_salary'">
                        <span v-if="create_sheet.company_3rd == 'บริษัทรถร่วม'">0</span>
                        <span v-else>{{ record.drivers?.[0]?.salary ? formatSalary(record.drivers[0].salary) : '' }}</span>
                      </template>
                      <template v-if="column.key === 'lift_things'">
                        <span>{{ record.lift_things != null ? record.lift_things.toFixed(2) : '' }}</span>
                      </template>
                      <template v-if="column.key === 'car_payment'">
                        <span v-if="create_sheet.company_3rd == 'บริษัทรถร่วม'">{{ record.price_car }}</span>
                        <span v-else>{{ record.cars?.[0]?.car_payment ? formatSalary(record.cars[0].car_payment) : '' }}</span>
                      </template>
                      <template v-else-if="column.key === 'last_booking_status'">
                        <a-tag
                          v-if="record.booking_cars[0]?.booking_car_statuses?.filter(status => status.booking_status === 8).length"
                          color="green"
                        >
                          {{
                            render_time(
                              record.booking_cars[0]?.booking_car_statuses?.[0]?.created_at
                            )
                          }}
                        </a-tag>
                        <a-tag color="red" v-else> ยังไม่ได้รับงาน </a-tag>
                      </template>
                      <template v-if="column.key === 'total_cars_expense'">
                            <span style="font-size: 16px">
                              {{ sum_car_expense(record.booking_cars).toLocaleString() }}
                            </span>
                            <a
                              v-if="record.booking_cars && record.booking_cars.length > 0 && record.cars && record.cars.length > 0"
                              @click="show_total_cars_expense(record, record.id)"
                              :key="record.id"
                              :style="`height: 16px; color: ${
                                allIsApproved(record.booking_cars) ? 'greenyellow' : 'red'
                              }`"
                            >
                              <span style="font-size: 16px; line-height: 0" class="material-symbols-outlined">
                                pie_chart
                              </span>
                            </a>
                          </template>
                      <template v-if="column.key === 'id'">
                        <a-tooltip placement="topLeft" title="ใช้วันเวลาจากการจอง">
                          <a @click="sync_booking_datetime(record, record.id)">
                            <span
                              style="font-size: 18px"
                              class="material-symbols-outlined"
                              >sync</span
                            >
                          </a>
                        </a-tooltip>
                        <a-divider type="vertical" />
                        <a @click="edit_booking_car(record, record.id)">
                          <span
                            style="font-size: 18px"
                            class="material-symbols-outlined"
                            >edit</span
                          >
                        </a>
                        <a-divider type="vertical" />
                        <a
                          @click="
                            remove_booking_car(record.id, record)
                          "
                        >
                          <span
                            style="font-size: 18px"
                            class="material-symbols-outlined"
                            >delete</span
                          >
                        </a>
                      </template>
                    </template>
                  </a-table>
                </a-col>
              </a-row>
            </a-collapse-panel>

            <a-collapse-panel key="3" ref="contactDetail" :header="`รายละเอียดเงื่อนไขการชำระเงินและผู้ติดต่อ`">
              <a-divider orientation="left">เงื่อนไขการชำระเงิน</a-divider>
              <a-row :gutter="24">
                <a-col :span="12" style="margin-bottom: 15px;">
                <a-form-item label="วันที่เก็บเงินลูกค้า ทุกวันที่" name="collect_customer">
                  <a-input
                    :disabled="!isProject || from_worksheet || statusDisable"
                    placeholder="วันที่เก็บเงินลูกค้า ทุกวันที่"
                    type="number"
                    v-model:value="create_sheet.collect_customer"
                    :min="1"
                    :max="31"
                    @input="validateDateInput($event, 'collect_customer')"
                />
                </a-form-item>
                </a-col>
                <a-col :span="12">
                <a-form-item label="วันที่คอนเฟิร์ม ทุกวันที่" name="confirm_day">
                  <a-input
                    :disabled="!isProject || from_worksheet || statusDisable"
                    placeholder="วันที่คอนเฟิร์ม ทุกวันที่"
                    type="number"
                    v-model:value="create_sheet.confirm_day"
                    :min="1"
                    :max="31"
                    @input="validateDateInput($event, 'confirm_day')"
                />
                </a-form-item>
                </a-col>
                <a-col :span="12" style="margin-bottom: 15px;">
                <a-form-item label="วันที่ต้องส่งเอกสาร ทุกวันที่" name="document_time">
                  <a-input
                    :disabled="!isProject || from_worksheet || statusDisable"
                    type="number"
                    placeholder="วันที่ต้องส่งเอกสาร ทุกวันที่"
                    v-model:value="create_sheet.document_time"
                    :min="1"
                    :max="31"
                    @input="validateDateInput($event, 'document_time')"
                />
                </a-form-item>
                </a-col>
                <a-col :span="12">
                <a-form-item label="จำนวนเครดิต" name="credits">
                  <a-input
                    placeholder="จำนวนเครดิต"
                    :disabled="!isProject || from_worksheet || statusDisable"
                    type="number"
                    v-model:value="create_sheet.credits"
                />
                </a-form-item>
                </a-col>
                <a-col :span="12" style="margin-bottom: 15px;">
                <a-form-item label="วิธีการชำระเงิน" name="payment_method_id">
                  <a-select :disabled="!isProject || from_worksheet || statusDisable" v-model:value="create_sheet.payment_method_id" placeholder="เลือกวิธีการชำระเงิน">
                    <a-select-option :value="1">โอน</a-select-option>
                    <a-select-option :value="2">เช็ค/เงินสด</a-select-option>
                  </a-select>
                </a-form-item>
                </a-col>
              </a-row>
              <a-divider orientation="left">ผู้ติดต่อ</a-divider>
              <a-row :gutter="24">
                <a-col :span="12" style="margin-bottom: 15px;">
                <a-form-item label="ชื่อผู้ติดต่อ ฝ่ายดูแลโปรเจกต์" name="project_name">
                  <a-input
                  :disabled="!isProject || from_worksheet || statusDisable"
                    placeholder="ชื่อผู้ติดต่อ ฝ่ายดูแลโปรเจกต์"
                  v-model:value="create_sheet.project_name"
                  ></a-input>
                </a-form-item>
                </a-col>
                <a-col :span="12">
                <a-form-item label="เบอร์โทร" name="project_phone">
                  <a-input
                  :disabled="!isProject || from_worksheet || statusDisable"
                  placeholder="เบอร์โทร"
                  v-model:value="create_sheet.project_phone"
                  ></a-input>
                </a-form-item>
                </a-col>
                <a-col :span="12" style="margin-bottom: 15px;">
                <a-form-item label="อีเมล" name="project_email">
                  <a-input
                  :disabled="!isProject || from_worksheet || statusDisable"
                  placeholder="อีเมล"
                  v-model:value="create_sheet.project_email"
                  ></a-input>
                </a-form-item>
              </a-col>
              </a-row>
                <a-row :gutter="24">
                <a-col :span="12" style="margin-bottom: 15px;">
                <a-form-item label="ชื่อผู้ติดต่อ ฝ่ายบัญชี" name="account_name">
                  <a-input
                  :disabled="!isProject || from_worksheet || statusDisable"
                  placeholder="ชื่อผู้ติดต่อ ฝ่ายบัญชี"
                  v-model:value="create_sheet.account_name"
                  ></a-input>
                </a-form-item>
                </a-col>
                <a-col :span="12">
                <a-form-item label="เบอร์โทร" name="account_tel">
                  <a-input
                  :disabled="!isProject || from_worksheet || statusDisable"
                  placeholder="เบอร์โทร"
                  v-model:value="create_sheet.account_tel"
                  ></a-input>
                </a-form-item>
                </a-col>
                <a-col :span="12" style="margin-bottom: 15px;">
                <a-form-item label="อีเมล" name="account_email">
                  <a-input
                  :disabled="!isProject || from_worksheet || statusDisable"
                  placeholder="อีเมล"
                  v-model:value="create_sheet.account_email"
                  ></a-input>
                </a-form-item>
                </a-col>
                </a-row>

            </a-collapse-panel>

            <a-collapse-panel v-if="from_worksheet" key="6" header="เอกสารที่เกี่ยวข้อง">
              <a-space>
                <div v-if="create_sheet.photo_1 != null && create_sheet.photo_1 != ''" class="image-container">
                  <img :src="create_sheet.photo_1" class="uploaded-image-table" alt="uploaded image" />
                  <div class="icon-container">
                    <span class="material-symbols-outlined preview-icon" @click="showPreview($event, create_sheet.photo_1)">
                      visibility
                    </span>
                    <span class="material-symbols-outlined download-icon"
                      @click="getBase64Image($event, create_sheet.photo_1)">
                      download
                    </span>
                    <span class="material-symbols-outlined download-icon"
                      @click="remove_uploaded_photo_1">
                      Delete
                    </span>
                  </div>
                </div>
                <a-upload 
                  v-else
                  v-model:file-list="create_sheet.booking_files" 
                  list-type="picture-card"
                  class="avatar-uploader"
                  name="photo_1"
                  :action="upload_file"
                  method="GET"
                  @remove="remove_uploaded_photo_1">
                  <div style="height: 104px; width: 104px; padding: 2rem">
                    <span style="font-size: 20px" class="material-symbols-outlined">add</span>
                    <div style="margin-top: 8px">รายละเอียดการใช้รถ</div>
                  </div>
                </a-upload>
              </a-space>

              <a-modal v-model:visible="previewVisible" :footer="null" :mask="false" @cancel="previewVisible = false" class="custom-modal" style="background-color: none !important">
                <img :src="previewImage" alt="Preview" style="width: 100%;" />
              </a-modal>
            </a-collapse-panel>

            <a-collapse-panel key="10" :header="`ผู้ดูแลใบงานฝ่ายขาย`" v-if="business=='car_rental_a'">
              <template #extra>
                <p v-if="!create_sheet.accounting_id" style="margin: 0; color: grey">
                  (ต้องระบุอีก 1)
                </p>
              </template>
              <a-row :gutter="24">
                <a-col :span="24">
                  <a-form-item
                  label="ผู้ดูแลใบงานฝ่ายขาย"
                  name="accounting_id"
                  :rules="[{ required: true, message: 'กรุณาเลือกผู้ดูแลใบงานฝ่ายขาย' }]"
                  >
                  <a-select
                  :disabled="from_worksheet || (business == 'car_rental_a' && [8,9,10,4,5,6,14,15].includes(create_sheet.booking_cars?.[0]?.status))"
                    v-model:value="create_sheet.accounting_id"
                    :options="
                      accounting_list.map(({ id, fullname,nickname }) => ({
                        value: id,
                        label: `${fullname} (${nickname})`,
                      }))
                    "
                  ></a-select>
                </a-form-item>
                </a-col>
              </a-row>
            </a-collapse-panel>

            <a-collapse-panel v-if="(from_worksheet && business == 'car_rental_a')||business!='car_rental_a'" key="4" :header="`ผู้ดูแลใบงานฝ่ายปฏิบัติการ`">
              <template #extra>
                <p v-if="!create_sheet.operation_id" style="margin: 0; color: grey">
                  (ต้องระบุอีก 1)
                </p>
              </template>
              <a-row :gutter="24">
                <a-col :span="24">
                  <a-form-item
                  label="ผู้ดูแลใบงานฝ่ายปฏิบัติการ"
                  name="operation_id"
                  :rules="[{ required: true, message: 'กรุณาเลือกผู้ดูแลใบงานฝ่ายปฏิบัติการ' }]"
                  >
                  <a-select
                  :disabled="statusDisable"
                    v-model:value="create_sheet.operation_id"
                    :options="
                      operation_list.map(({ id, fullname,nickname }) => ({
                        value: id,
                        label: `${fullname} (${nickname})`,
                      }))
                    "
                  ></a-select>
                </a-form-item>
                </a-col>
              </a-row>
            </a-collapse-panel>

            <a-collapse-panel v-if="from_worksheet" key="7" :header="`รายงานสถานะหน้างาน`">
              <a-collapse style="border-radius: 4px" v-model:activeKey="activeSubKeys">
                <a-collapse-panel
                  v-if="create_sheet.booking_cars[0] && create_sheet.booking_cars[0].car"
                  :key="create_sheet.booking_cars[0].id"
                  :header="`ทะเบียนรถ: ${create_sheet.booking_cars[0].car.plate_no} เบอร์รถ : (${create_sheet.booking_cars[0].car.car_no})`"
                >
                  <div
                    style="display: flex; justify-content: space-between; width: 100%"
                  >
                  <div>
                  <div>
                    <a-form-item name="onsite_photo" label="1.ถ่ายเมื่อถึงหน้างาน">
                      <a-upload
                        v-model:file-list="car.service.photo_1_show"
                        list-type="picture-card"
                        class="avatar-uploader"
                        accept="image/*"
                        :show-upload-list="false"
                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                        @change="upload_files($event, 'photo_1_show' , 'services' , index_car)"
                        method="GET"
                        @remove="remove_uploaded_file"
                      >
                        <div v-if="car.service.photo_1_show.length" class="upload-container">
                          <img
                            :src="getUploadedUrl(car.service.photo_1_show[0])"
                            class="uploaded-image"
                            alt="uploaded image"
                            @click="showPreview($event , getUploadedUrl(car.service.photo_1_show[0]))"
                          />
                          <div class="icon-container">
                            <span
                              class="material-symbols-outlined preview-icon"
                              @click="handleIconClick('preview', getUploadedUrl(car.service.photo_1_show[0]), $event)"
                            >
                              visibility
                            </span>
                            <div @click="getBase64Image($event ,car.service.photo_1_show[0])">
                              <span class="material-symbols-outlined download-icon" style="color: black;">
                                download
                              </span>
                            </div>
                            <span
                              class="material-symbols-outlined delete-icon"
                              @click="handleClickDeletePhoto($event , 'photo_1_show' , 'services' , index_car)"
                            >
                              delete
                            </span>
                          </div>
                        </div>
                        <div v-else style="display: flex; flex-direction: column; align-items: center;">
                          <p class="ant-upload-drag-icon">
                            <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                          </p>
                          แนบรูปภาพ
                        </div>
                      </a-upload>
                      <a-modal v-model:visible="previewVisible" :footer="null" :mask="false" @cancel="previewVisible = false" class="custom-modal" style="background-color: none !important">
                        <img :src="previewImage" alt="Preview" style="width: 100%;" />
                      </a-modal>
                    </a-form-item>
                  </div>
                  <div>
                    <a-form-item name="onsite_photo">
                      <a-upload
                        v-model:file-list="car.service.photo_7_show"
                        list-type="picture-card"
                        class="avatar-uploader"
                        accept="image/*"
                        :show-upload-list="false"
                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                        @change="upload_files($event, 'photo_7_show' , 'services' , index_car)"
                        method="GET"
                        @remove="remove_uploaded_file"
                      >
                        <div v-if="car.service.photo_7_show.length" class="upload-container">
                          <img
                            :src="getUploadedUrl(car.service.photo_7_show[0])"
                            class="uploaded-image"
                            alt="uploaded image"
                            @click="showPreview($event , getUploadedUrl(car.service.photo_7_show[0]))"
                          />
                          <div class="icon-container">
                            <span
                              class="material-symbols-outlined preview-icon"
                              @click="handleIconClick('preview', getUploadedUrl(car.service.photo_7_show[0]), $event)"
                            >
                              visibility
                            </span>
                            <div @click="getBase64Image($event ,car.service.photo_7_show[0])">
                              <span class="material-symbols-outlined download-icon" style="color: black;">
                                download
                              </span>
                            </div>
                            <span
                              class="material-symbols-outlined delete-icon"
                              @click="handleClickDeletePhoto($event , 'photo_7_show' , 'services' , index_car)"
                            >
                              delete
                            </span>
                          </div>
                        </div>
                        <div v-else style="display: flex; flex-direction: column; align-items: center;">
                          <p class="ant-upload-drag-icon">
                            <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                          </p>
                          แนบรูปภาพ
                        </div>
                      </a-upload>
                  
                    </a-form-item>
                  </div>
                  </div>
                  <div>
                    <div>
                      <a-form-item name="onsite_photo" label="2.ขึ้นสินค้า">
                      <a-upload
                        v-model:file-list="car.service.photo_2_show"
                        list-type="picture-card"
                        class="avatar-uploader"
                        accept="image/*"
                        :show-upload-list="false"
                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                        @change="upload_files($event, 'photo_2_show' , 'services' , index_car)"
                        method="GET"
                        @remove="remove_uploaded_file"
                      >
                        <div v-if="car.service.photo_2_show.length" class="upload-container">
                          <img
                            :src="getUploadedUrl(car.service.photo_2_show[0])"
                            class="uploaded-image"
                            alt="uploaded image"
                            @click="showPreview($event , getUploadedUrl(car.service.photo_2_show[0]))"
                          />
                          <div class="icon-container">
                            <span
                              class="material-symbols-outlined preview-icon"
                              @click="handleIconClick('preview', getUploadedUrl(car.service.photo_2_show[0]), $event)"
                            >
                              visibility
                            </span>
                            <div @click="getBase64Image($event ,car.service.photo_2_show[0])">
                              <span class="material-symbols-outlined download-icon" style="color: black;">
                                download
                              </span>
                            </div>
                            <span
                              class="material-symbols-outlined delete-icon"
                              @click="handleClickDeletePhoto($event , 'photo_2_show' , 'services' , index_car)"
                            >
                              delete
                            </span>
                          </div>
                        </div>
                        <div v-else style="display: flex; flex-direction: column; align-items: center;">
                          <p class="ant-upload-drag-icon">
                            <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                          </p>
                          แนบรูปภาพ
                        </div>
                      </a-upload>
                      </a-form-item>
                    </div>
                    <div>
                      <a-form-item name="onsite_photo">
                      <a-upload
                        v-model:file-list="car.service.photo_8_show"
                        list-type="picture-card"
                        class="avatar-uploader"
                        accept="image/*"
                        :show-upload-list="false"
                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                        @change="upload_files($event, 'photo_8_show' , 'services' , index_car)"
                        method="GET"
                        @remove="remove_uploaded_file"
                      >
                        <div v-if="car.service.photo_8_show.length" class="upload-container">
                          <img
                            :src="getUploadedUrl(car.service.photo_8_show[0])"
                            class="uploaded-image"
                            alt="uploaded image"
                            @click="showPreview($event , getUploadedUrl(car.service.photo_8_show[0]))"
                          />
                          <div class="icon-container">
                            <span
                              class="material-symbols-outlined preview-icon"
                              @click="handleIconClick('preview', getUploadedUrl(car.service.photo_8_show[0]), $event)"
                            >
                              visibility
                            </span>
                            <div @click="getBase64Image($event ,car.service.photo_8_show[0])">
                              <span class="material-symbols-outlined download-icon" style="color: black;">
                                download
                              </span>
                            </div>
                            <span
                              class="material-symbols-outlined delete-icon"
                              @click="handleClickDeletePhoto($event , 'photo_8_show' , 'services' , index_car)"
                            >
                              delete
                            </span>
                          </div>
                        </div>
                        <div v-else style="display: flex; flex-direction: column; align-items: center;">
                          <p class="ant-upload-drag-icon">
                            <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                          </p>
                          แนบรูปภาพ
                        </div>
                      </a-upload>
                      </a-form-item>
                    </div>
                  </div>
                  <div>
                    <div>
                      <a-form-item name="onsite_photo" label="3.ตรวจสอบสินค้า">
                    <a-upload
                      v-model:file-list="car.service.photo_3_show"
                      list-type="picture-card"
                      class="avatar-uploader"
                      accept="image/*"
                      :show-upload-list="false"
                      action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                      @change="upload_files($event, 'photo_3_show' , 'services' , index_car)"
                      method="GET"
                      @remove="remove_uploaded_file"
                    >
                      <div v-if="car.service.photo_3_show.length" class="upload-container">
                        <img
                          :src="getUploadedUrl(car.service.photo_3_show[0])"
                          class="uploaded-image"
                          alt="uploaded image"
                          @click="showPreview($event , getUploadedUrl(car.service.photo_3_show[0]))"
                        />
                        <div class="icon-container">
                          <span
                            class="material-symbols-outlined preview-icon"
                            @click="handleIconClick('preview', getUploadedUrl(car.service.photo_3_show[0]), $event)"
                          >
                            visibility
                          </span>
                          <div @click="getBase64Image($event ,car.service.photo_3_show[0])">
                            <span class="material-symbols-outlined download-icon" style="color: black;">
                              download
                            </span>
                          </div>
                          <span
                            class="material-symbols-outlined delete-icon"
                            @click="handleClickDeletePhoto($event , 'photo_3_show' , 'services' , index_car)"
                          >
                            delete
                          </span>
                        </div>
                      </div>
                      <div v-else style="display: flex; flex-direction: column; align-items: center;">
                        <p class="ant-upload-drag-icon">
                          <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                        </p>
                        แนบรูปภาพ
                      </div>
                    </a-upload>
                      </a-form-item>
                    </div>
                    <div>
                      <a-form-item name="onsite_photo">
                      <a-upload
                        v-model:file-list="car.service.photo_9_show"
                        list-type="picture-card"
                        class="avatar-uploader"
                        accept="image/*"
                        :show-upload-list="false"
                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                        @change="upload_files($event, 'photo_9_show' , 'services' , index_car)"
                        method="GET"
                        @remove="remove_uploaded_file"
                      >
                        <div v-if="car.service.photo_9_show.length" class="upload-container">
                          <img
                            :src="getUploadedUrl(car.service.photo_9_show[0])"
                            class="uploaded-image"
                            alt="uploaded image"
                            @click="showPreview($event , getUploadedUrl(car.service.photo_9_show[0]))"
                          />
                          <div class="icon-container">
                            <span
                              class="material-symbols-outlined preview-icon"
                              @click="handleIconClick('preview', getUploadedUrl(car.service.photo_9_show[0]), $event)"
                            >
                              visibility
                            </span>
                            <div @click="getBase64Image($event ,car.service.photo_9_show[0])">
                              <span class="material-symbols-outlined download-icon" style="color: black;">
                                download
                              </span>
                            </div>
                            <span
                              class="material-symbols-outlined delete-icon"
                              @click="handleClickDeletePhoto($event , 'photo_9_show' , 'services' , index_car)"
                            >
                              delete
                            </span>
                          </div>
                        </div>
                        <div v-else style="display: flex; flex-direction: column; align-items: center;">
                          <p class="ant-upload-drag-icon">
                            <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                          </p>
                          แนบรูปภาพ
                        </div>
                      </a-upload>
                      </a-form-item>
                    </div>
                  </div>
                  <div>
                    <div>
                      <a-form-item name="onsite_photo" label="4.สินค้าชำรุด(ถ้ามี)">
                      <a-upload
                        v-model:file-list="car.service.photo_4_show"
                        list-type="picture-card"
                        class="avatar-uploader"
                        accept="image/*"
                        :show-upload-list="false"
                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                        @change="upload_files($event, 'photo_4_show' , 'services' , index_car)"
                        method="GET"
                        @remove="remove_uploaded_file"
                      >
                        <div v-if="car.service.photo_4_show.length" class="upload-container">
                          <img
                            :src="getUploadedUrl(car.service.photo_4_show[0])"
                            class="uploaded-image"
                            alt="uploaded image"
                            @click="showPreview($event , getUploadedUrl(car.service.photo_4_show[0]))"
                          />
                          <div class="icon-container">
                            <span
                              class="material-symbols-outlined preview-icon"
                              @click="handleIconClick('preview', getUploadedUrl(car.service.photo_4_show[0]), $event)"
                            >
                              visibility
                            </span>
                            <div @click="getBase64Image($event ,car.service.photo_4_show[0])">
                              <span class="material-symbols-outlined download-icon" style="color: black;">
                                download
                              </span>
                            </div>
                            <span
                              class="material-symbols-outlined delete-icon"
                              @click="handleClickDeletePhoto($event , 'photo_4_show' , 'services' , index_car)"
                            >
                              delete
                            </span>
                          </div>
                        </div>
                        <div v-else style="display: flex; flex-direction: column; align-items: center;">
                          <p class="ant-upload-drag-icon">
                            <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                          </p>
                          แนบรูปภาพ
                        </div>
                      </a-upload>
                      </a-form-item>
                    </div>
                    <div>
                      <a-form-item name="onsite_photo">
                      <a-upload
                        v-model:file-list="car.service.photo_10_show"
                        list-type="picture-card"
                        class="avatar-uploader"
                        accept="image/*"
                        :show-upload-list="false"
                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                        @change="upload_files($event, 'photo_10_show' , 'services' , index_car)"
                        method="GET"
                        @remove="remove_uploaded_file"
                      >
                        <div v-if="car.service.photo_10_show.length" class="upload-container">
                          <img
                            :src="getUploadedUrl(car.service.photo_10_show[0])"
                            class="uploaded-image"
                            alt="uploaded image"
                            @click="showPreview($event , getUploadedUrl(car.service.photo_10_show[0]))"
                          />
                          <div class="icon-container">
                            <span
                              class="material-symbols-outlined preview-icon"
                              @click="handleIconClick('preview', getUploadedUrl(car.service.photo_10_show[0]), $event)"
                            >
                              visibility
                            </span>
                            <div @click="getBase64Image($event ,car.service.photo_10_show[0])">
                              <span class="material-symbols-outlined download-icon" style="color: black;">
                                download
                              </span>
                            </div>
                            <span
                              class="material-symbols-outlined delete-icon"
                              @click="handleClickDeletePhoto($event , 'photo_10_show' , 'services' , index_car)"
                            >
                              delete
                            </span>
                          </div>
                        </div>
                        <div v-else style="display: flex; flex-direction: column; align-items: center;">
                          <p class="ant-upload-drag-icon">
                            <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                          </p>
                          แนบรูปภาพ
                        </div>
                      </a-upload>
                      </a-form-item>
                    </div>
                  </div>
                  <div>
                    <div>
                      <a-form-item name="onsite_photo" label="5.ส่งสินค้า">                      
                      <a-upload
                        v-model:file-list="car.service.photo_5_show"
                        list-type="picture-card"
                        class="avatar-uploader"
                        accept="image/*"
                        :show-upload-list="false"
                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                        @change="upload_files($event, 'photo_5_show' , 'services' , index_car)"
                        method="GET"
                        @remove="remove_uploaded_file"
                      >
                        <div v-if="car.service.photo_5_show.length" class="upload-container">
                          <img
                            :src="getUploadedUrl(car.service.photo_5_show[0])"
                            class="uploaded-image"
                            alt="uploaded image"
                            @click="showPreview($event , getUploadedUrl(car.service.photo_5_show[0]))"
                          />
                          <div class="icon-container">
                            <span
                              class="material-symbols-outlined preview-icon"
                              @click="handleIconClick('preview', getUploadedUrl(car.service.photo_5_show[0]), $event)"
                            >
                              visibility
                            </span>
                            <div @click="getBase64Image($event ,car.service.photo_5_show[0])">
                              <span class="material-symbols-outlined download-icon" style="color: black;">
                                download
                              </span>
                            </div>
                            <span
                              class="material-symbols-outlined delete-icon"
                              @click="handleClickDeletePhoto($event , 'photo_5_show' , 'services' , index_car)"
                            >
                              delete
                            </span>
                          </div>
                        </div>
                        <div v-else style="display: flex; flex-direction: column; align-items: center;">
                          <p class="ant-upload-drag-icon">
                            <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                          </p>
                          แนบรูปภาพ
                        </div>
                      </a-upload>
                      </a-form-item>
                    </div>
                    <div> 
                      <a-form-item
                        name="onsite_photo"
                      >                      
                      <a-upload
                        v-model:file-list="car.service.photo_11_show"
                        list-type="picture-card"
                        class="avatar-uploader"
                        accept="image/*"
                        :show-upload-list="false"
                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                        @change="upload_files($event, 'photo_11_show' , 'services' , index_car)"
                        method="GET"
                        @remove="remove_uploaded_file"
                      >
                        <div v-if="car.service.photo_11_show.length" class="upload-container">
                          <img
                            :src="getUploadedUrl(car.service.photo_11_show[0])"
                            class="uploaded-image"
                            alt="uploaded image"
                            @click="showPreview($event , getUploadedUrl(car.service.photo_11_show[0]))"
                          />
                          <div class="icon-container">
                            <span
                              class="material-symbols-outlined preview-icon"
                              @click="handleIconClick('preview', getUploadedUrl(car.service.photo_11_show[0]), $event)"
                            >
                              visibility
                            </span>
                            <div @click="getBase64Image($event ,car.service.photo_11_show[0])">
                              <span class="material-symbols-outlined download-icon" style="color: black;">
                                download
                              </span>
                            </div>
                            <span
                              class="material-symbols-outlined delete-icon"
                              @click="handleClickDeletePhoto($event , 'photo_11_show' , 'services' , index_car)"
                            >
                              delete
                            </span>
                          </div>
                        </div>
                        <div v-else style="display: flex; flex-direction: column; align-items: center;">
                          <p class="ant-upload-drag-icon">
                            <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                          </p>
                          แนบรูปภาพ
                        </div>
                      </a-upload>
                      </a-form-item>
                    </div>
                  </div>
                  <div>
                    <div>
                      <a-form-item name="assist_driver_photo" label="6.รูปพนักงานคนขับรถที่ 2">                      
                        <a-upload
                          v-model:file-list="car.service.photo_6_show"
                          list-type="picture-card"
                          class="avatar-uploader"
                          accept="image/*"
                          :show-upload-list="false"
                          action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                          @change="upload_files($event, 'photo_6_show', 'services', index_car)"
                          method="GET"
                          @remove="remove_uploaded_file"
                        >
                          <div v-if="car.service.photo_6_show.length" class="upload-container">
                            <img
                              :src="getUploadedUrl(car.service.photo_6_show[0])"
                              class="uploaded-image"
                              alt="uploaded image"
                              @click="showPreview($event, getUploadedUrl(car.service.photo_6_show[0]))"
                            />
                            <div class="icon-container">
                              <span
                                class="material-symbols-outlined preview-icon"
                                @click="handleIconClick('preview', getUploadedUrl(car.service.photo_6_show[0]), $event)"
                              >
                                visibility
                              </span>
                              <div @click="getBase64Image($event, car.service.photo_6_show[0])">
                                <span class="material-symbols-outlined download-icon" style="color: black;">
                                  download
                                </span>
                              </div>
                              <span
                                class="material-symbols-outlined delete-icon"
                                @click="handleClickDeletePhoto($event, 'photo_6_show', 'services', index_car)"
                              >
                                delete
                              </span>
                            </div>
                          </div>
                          <div v-else style="display: flex; flex-direction: column; align-items: center;">
                            <p class="ant-upload-drag-icon">
                              <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                            </p>
                            แนบรูปภาพ
                          </div>
                        </a-upload>
                      </a-form-item>
                    </div>
                    <div>
                      <a-form-item
                        name="assist_driver_photo"
                      >                 
                        <a-upload
                          v-model:file-list="car.service.photo_12_show"
                          list-type="picture-card"
                          class="avatar-uploader"
                          accept="image/*"
                          :show-upload-list="false"
                          action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                          @change="upload_files($event, 'photo_12_show', 'services', index_car)"
                          method="GET"
                          @remove="remove_uploaded_file"
                        >
                          <div v-if="car.service.photo_12_show.length" class="upload-container">
                            <img
                              :src="getUploadedUrl(car.service.photo_12_show[0])"
                              class="uploaded-image"
                              alt="uploaded image"
                              @click="showPreview($event, getUploadedUrl(car.service.photo_12_show[0]))"
                            />
                            <div class="icon-container">
                              <span
                                class="material-symbols-outlined preview-icon"
                                @click="handleIconClick('preview', getUploadedUrl(car.service.photo_12_show[0]), $event)"
                              >
                                visibility
                              </span>
                              <div @click="getBase64Image($event, car.service.photo_12_show[0])">
                                <span class="material-symbols-outlined download-icon" style="color: black;">
                                  download
                                </span>
                              </div>
                              <span
                                class="material-symbols-outlined delete-icon"
                                @click="handleClickDeletePhoto($event, 'photo_12_show', 'services', index_car)"
                              >
                                delete
                              </span>
                            </div>
                          </div>
                          <div v-else style="display: flex; flex-direction: column; align-items: center;">
                            <p class="ant-upload-drag-icon">
                              <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                            </p>
                            แนบรูปภาพ
                          </div>
                        </a-upload>
                      </a-form-item>
                    </div>
                  </div>
                  </div>
                </a-collapse-panel>
              </a-collapse>
            </a-collapse-panel>

            <a-collapse-panel v-if="from_worksheet" key="8" header="บันทึกค่าน้ำมัน">
              <a-collapse style="border-radius: 4px" v-model:activeKey="activeSubKeys2">
                <a-collapse-panel
                  v-if="create_sheet.booking_cars[0] && create_sheet.booking_cars[0].car"
                  :key="create_sheet.booking_cars[0].id"
                  :header="`ทะเบียนรถ: ${create_sheet.booking_cars[0].car.plate_no} เบอร์รถ : (${create_sheet.booking_cars[0].car.car_no})`"
                >
                  <div v-if="car && car.gas_refills && car.gas_refills.length">
                    <a-card
                      v-for="(gas, index_gas_refills) in car.gas_refills"
                      :key="gas.id"
                      :title="`หลักฐานการเติม: #${index_gas_refills + 1} - ${render_date(
                        gas.created_at,gas.created_at
                      )}`   "
                    >
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          width: 100%;
                        "
                      >
                          <a-form-item name="gas1" label="1. รูปหน้าปัดก่อนเติมน้ำมัน">
                            <div class="upload-section">
                              <a-upload
                                v-model:file-list="gas.photo_1_show"
                                list-type="picture-card"
                                class="avatar-uploader"
                                :show-upload-list="false"
                                action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                @change="upload_files($event, 'photo_1_show' , 'gas_refills' , index_car , index_gas_refills)"
                                method="GET"
                              >
                                <div v-if="gas.photo_1_show.length" class="upload-container">
                                  <img
                                    :src="getUploadedUrl(gas.photo_1_show[0])"
                                    class="uploaded-image"
                                    alt="uploaded image"
                                    @click="showPreview($event , getUploadedUrl(gas.photo_1_show[0]))"
                                  />
                                  <div class="icon-container">
                                    <span
                                      class="material-symbols-outlined preview-icon"
                                      @click="handleIconClick('preview', getUploadedUrl(gas.photo_1_show[0]), $event)"
                                    >
                                      visibility
                                    </span>
                                    <div @click="getBase64Image($event ,gas.photo_1_show[0])">
                                      <span class="material-symbols-outlined download-icon" style="color: black;">
                                        download
                                      </span>
                                    </div>
                                    <span
                                      class="material-symbols-outlined delete-icon"
                                      @click="handleClickDeletePhoto($event , 'photo_1_show' , 'gas_refills' , index_car , index_gas_refills)"
                                    >
                                      delete
                                    </span>
                                  </div>
                                </div>
                                <div v-else style="display: flex; flex-direction: column; align-items: center;">
                                  <p class="ant-upload-drag-icon">
                                    <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                                  </p>
                                  แนบรูปภาพ
                                </div>
                              </a-upload>
                              <a-modal v-model:visible="previewVisible" :footer="null" :mask="false" @cancel="previewVisible = false" class="custom-modal" style="background-color: none !important">
                                <img :src="previewImage" alt="Preview" style="width: 100%;" />
                              </a-modal>
                            </div>

                            <div class="upload-section">
                              <a-upload
                                v-model:file-list="gas.photo_9_show"
                                list-type="picture-card"
                                class="avatar-uploader"
                                :show-upload-list="false"
                                action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                @change="upload_files($event, 'photo_9_show' , 'gas_refills' , index_car , index_gas_refills)"
                                method="GET"
                              >
                                <div v-if="gas.photo_9_show.length" class="upload-container">
                                  <img
                                    :src="getUploadedUrl(gas.photo_9_show[0])"
                                    class="uploaded-image"
                                    alt="uploaded image"
                                    @click="showPreview($event ,getUploadedUrl(gas.photo_9_show[0]))"
                                  />
                                  <div class="icon-container">
                                    <span
                                      class="material-symbols-outlined preview-icon"
                                      @click="handleIconClick('preview', getUploadedUrl(gas.photo_9_show[0]), $event)"
                                    >
                                      visibility
                                    </span>
                                    <div @click="getBase64Image($event ,gas.photo_9_show[0])">
                                      <span class="material-symbols-outlined download-icon" style="color: black;">
                                        download
                                      </span>
                                    </div>
                                    <span
                                      class="material-symbols-outlined delete-icon"
                                      @click="handleClickDeletePhoto($event , 'photo_9_show' , 'gas_refills', index_car , index_gas_refills)"
                                    >
                                      delete
                                    </span>
                                  </div>
                                </div>
                                <div v-else style="display: flex; flex-direction: column; align-items: center;">
                                  <p class="ant-upload-drag-icon">
                                    <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                                  </p>
                                  แนบรูปภาพ
                                </div>
                              </a-upload>
                              <a-modal v-model:visible="previewVisible" :footer="null" :mask="false" @cancel="previewVisible = false" class="custom-modal" style="background-color: none !important">
                                <img :src="previewImage" alt="Preview" style="width: 100%;" />
                              </a-modal>
                            </div>
                          </a-form-item>

                        <a-form-item name="gas5" label="2. รูปคอน้ำมัน">
                          <a-upload
                            v-model:file-list="gas.photo_2_show"
                            list-type="picture-card"
                            class="avatar-uploader"
                            :show-upload-list="false"
                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                            @change="upload_files($event, 'photo_2_show' , 'gas_refills' , index_car , index_gas_refills)"
                            method="GET"
                          >
                            <div v-if="gas.photo_2_show.length" class="upload-container">
                              <img
                                :src="getUploadedUrl(gas.photo_2_show[0])"
                                class="uploaded-image"
                                alt="uploaded image"
                                @click="showPreview($event ,getUploadedUrl(gas.photo_2_show[0]))"
                              />
                              <div class="icon-container">
                                <span
                                  class="material-symbols-outlined preview-icon"
                                  @click="handleIconClick('preview', getUploadedUrl(gas.photo_2_show[0]), $event)"
                                >
                                  visibility
                                </span>
                                <div @click="getBase64Image($event ,gas.photo_2_show[0])">
                                  <span class="material-symbols-outlined download-icon" style="color: black;">
                                    download
                                  </span>
                                </div>
                                <span
                                    class="material-symbols-outlined delete-icon"
                                    @click="handleClickDeletePhoto($event , 'photo_2_show' , 'gas_refills', index_car , index_gas_refills)"
                                  >
                                    delete
                                  </span>
                              </div>
                            </div>
                            <div v-else style="display: flex; flex-direction: column; align-items: center;">
                              <p class="ant-upload-drag-icon">
                                <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                              </p>
                              แนบรูปภาพ
                            </div>
                          </a-upload>
                        </a-form-item>

                        <a-modal v-model:visible="previewVisible" :footer="null" :mask="false" @cancel="previewVisible = false" class="custom-modal" style="background-color: none !important">
                          <img :src="previewImage" alt="Preview" style="width: 100%;" />
                        </a-modal>

                          <a-form-item name="gas6" label="3. รูปหัวจ่าย">
                          <a-upload
                            v-model:file-list="gas.photo_3_show"
                            list-type="picture-card"
                            class="avatar-uploader"
                            :show-upload-list="false"
                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                            @change="upload_files($event, 'photo_3_show' , 'gas_refills' , index_car , index_gas_refills)"
                            method="GET"
                          >
                            <div v-if="gas.photo_3_show.length" class="upload-container">
                              <img
                                :src="getUploadedUrl(gas.photo_3_show[0])"
                                class="uploaded-image"
                                alt="uploaded image"
                                @click="showPreview($event ,getUploadedUrl(gas.photo_3_show[0]))"
                              />
                              <div class="icon-container">
                                <span
                                  class="material-symbols-outlined preview-icon"
                                  @click="handleIconClick('preview', getUploadedUrl(gas.photo_3_show[0]), $event)"
                                >
                                  visibility
                                </span>
                                <div @click="getBase64Image($event ,gas.photo_3_show[0])">
                                  <span class="material-symbols-outlined download-icon" style="color: black;">
                                    download
                                  </span>
                                </div>
                                <span
                                    class="material-symbols-outlined delete-icon"
                                    @click="handleClickDeletePhoto($event , 'photo_3_show' , 'gas_refills', index_car , index_gas_refills)"
                                  >
                                    delete
                                  </span>
                              </div>
                            </div>
                            <div v-else style="display: flex; flex-direction: column; align-items: center;">
                              <p class="ant-upload-drag-icon">
                                <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                              </p>
                              แนบรูปภาพ
                            </div>
                          </a-upload>
                        </a-form-item>

                          <a-form-item name="gas3" label="4. รูปรถคู่กับหัวจ่าย">
                          <a-upload
                            v-model:file-list="gas.photo_4_show"
                            list-type="picture-card"
                            class="avatar-uploader"
                            :show-upload-list="false"
                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                            @change="upload_files($event, 'photo_4_show' , 'gas_refills' , index_car , index_gas_refills)"
                            method="GET"
                          >
                            <div v-if="gas.photo_4_show.length" class="upload-container">
                              <img
                                :src="getUploadedUrl(gas.photo_4_show[0])"
                                class="uploaded-image"
                                alt="uploaded image"
                                @click="showPreview($event ,getUploadedUrl(gas.photo_4_show[0]))"
                              />
                              <div class="icon-container">
                                <span
                                  class="material-symbols-outlined preview-icon"
                                  @click="handleIconClick('preview', getUploadedUrl(gas.photo_4_show[0]), $event)"
                                >
                                  visibility
                                </span>
                                <div @click="getBase64Image($event ,gas.photo_4_show[0])">
                                  <span class="material-symbols-outlined download-icon" style="color: black;">
                                    download
                                  </span>
                                </div>
                                <span
                                    class="material-symbols-outlined delete-icon"
                                    @click="handleClickDeletePhoto($event , 'photo_4_show' , 'gas_refills', index_car , index_gas_refills)"
                                  >
                                    delete
                                  </span>
                              </div>
                            </div>
                            <div v-else style="display: flex; flex-direction: column; align-items: center;">
                              <p class="ant-upload-drag-icon">
                                <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                              </p>
                              แนบรูปภาพ
                            </div>
                          </a-upload>
                        </a-form-item>
                      
                          <a-form-item name="gas4" label="5. รูปเติมน้ำมันครั้งที่ 1">
                          <a-upload
                            v-model:file-list="gas.photo_5_show"
                            list-type="picture-card"
                            class="avatar-uploader"
                            :show-upload-list="false"
                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                            @change="upload_files($event, 'photo_5_show' , 'gas_refills' , index_car , index_gas_refills)"
                            method="GET"
                          >
                            <div v-if="gas.photo_5_show.length" class="upload-container">
                              <img
                                :src="getUploadedUrl(gas.photo_5_show[0])"
                                class="uploaded-image"
                                alt="uploaded image"
                                @click="showPreview($event ,getUploadedUrl(gas.photo_5_show[0]))"
                              />
                              <div class="icon-container">
                                <span
                                  class="material-symbols-outlined preview-icon"
                                  @click="handleIconClick('preview', getUploadedUrl(gas.photo_5_show[0]), $event)"
                                >
                                  visibility
                                </span>
                                <div @click="getBase64Image($event ,gas.photo_5_show[0])">
                                  <span class="material-symbols-outlined download-icon" style="color: black;">
                                    download
                                  </span>
                                </div>
                                <span
                                    class="material-symbols-outlined delete-icon"
                                    @click="handleClickDeletePhoto($event , 'photo_5_show' , 'gas_refills', index_car , index_gas_refills)"
                                  >
                                    delete
                                  </span>
                              </div>
                            </div>
                            <div v-else style="display: flex; flex-direction: column; align-items: center;">
                              <p class="ant-upload-drag-icon">
                                <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                              </p>
                              แนบรูปภาพ
                            </div>
                          </a-upload>
                        </a-form-item>

                          <a-form-item name="gas8" label="6. บิลเชื้อเพลิง">
                          <a-upload
                            v-model:file-list="gas.photo_6_show"
                            list-type="picture-card"
                            class="avatar-uploader"
                            :show-upload-list="false"
                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                            @change="upload_files($event, 'photo_6_show' , 'gas_refills' , index_car , index_gas_refills)"
                            method="GET"
                          >
                            <div v-if="gas.photo_6_show.length" class="upload-container">
                              <img
                                :src="getUploadedUrl(gas.photo_6_show[0])"
                                class="uploaded-image"
                                alt="uploaded image"
                                @click="showPreview($event ,getUploadedUrl(gas.photo_6_show[0]))"
                              />
                              <div class="icon-container">
                                <span
                                  class="material-symbols-outlined preview-icon"
                                  @click="handleIconClick('preview', getUploadedUrl(gas.photo_6_show[0]), $event)"
                                >
                                  visibility
                                </span>
                                <div @click="getBase64Image($event ,gas.photo_6_show[0])">
                                  <span class="material-symbols-outlined download-icon" style="color: black;">
                                    download
                                  </span>
                                </div>
                                <span
                                    class="material-symbols-outlined delete-icon"
                                    @click="handleClickDeletePhoto($event , 'photo_6_show' , 'gas_refills', index_car , index_gas_refills)"
                                  >
                                    delete
                                  </span>
                              </div>
                            </div>
                            <div v-else style="display: flex; flex-direction: column; align-items: center;">
                              <p class="ant-upload-drag-icon">
                                <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                              </p>
                              แนบรูปภาพ
                            </div>
                          </a-upload>
                        </a-form-item>

                          <a-form-item name="gas7" label="7. รูปหน้าปัดหลังเติม">
                            <div class="upload-section">
                              <a-upload
                                v-model:file-list="gas.photo_7_show"
                                list-type="picture-card"
                                class="avatar-uploader"
                                :show-upload-list="false"
                                action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                @change="upload_files($event, 'photo_7_show' , 'gas_refills' , index_car , index_gas_refills)"
                                method="GET"
                              >
                                <div v-if="gas.photo_7_show.length" class="upload-container">
                                  <img
                                    :src="getUploadedUrl(gas.photo_7_show[0])"
                                    class="uploaded-image"
                                    alt="uploaded image"
                                    @click="showPreview($event ,getUploadedUrl(gas.photo_7_show[0]))"
                                  />
                                  <div class="icon-container">
                                    <span
                                      class="material-symbols-outlined preview-icon"
                                      @click="handleIconClick('preview', getUploadedUrl(gas.photo_7_show[0]), $event)"
                                    >
                                      visibility
                                    </span>
                                    <div @click="getBase64Image($event ,gas.photo_7_show[0])">
                                      <span class="material-symbols-outlined download-icon" style="color: black;">
                                        download
                                      </span>
                                    </div>
                                    <span
                                      class="material-symbols-outlined delete-icon"
                                      @click="handleClickDeletePhoto($event , 'photo_7_show' , 'gas_refills', index_car , index_gas_refills)"
                                    >
                                      delete
                                    </span>
                                  </div>
                                </div>
                                <div v-else style="display: flex; flex-direction: column; align-items: center;">
                                  <p class="ant-upload-drag-icon">
                                    <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                                  </p>
                                  แนบรูปภาพ
                                </div>
                              </a-upload>
                            </div>

                            <div class="upload-section">
                              <a-upload
                                v-model:file-list="gas.photo_10_show"
                                list-type="picture-card"
                                class="avatar-uploader"
                                :show-upload-list="false"
                                action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                @change="upload_files($event, 'photo_10_show' , 'gas_refills' , index_car , index_gas_refills)"
                                method="GET"
                              >
                                <div v-if="gas.photo_10_show.length" class="upload-container">
                                  <img
                                    :src="getUploadedUrl(gas.photo_10_show[0])"
                                    class="uploaded-image"
                                    alt="uploaded image"
                                    @click="showPreview($event ,getUploadedUrl(gas.photo_10_show[0]))"
                                  />
                                  <div class="icon-container">
                                    <span
                                      class="material-symbols-outlined preview-icon"
                                      @click="handleIconClick('preview', getUploadedUrl(gas.photo_10_show[0]), $event)"
                                    >
                                      visibility
                                    </span>
                                    <div @click="getBase64Image($event ,gas.photo_10_show[0])">
                                      <span class="material-symbols-outlined download-icon" style="color: black;">
                                        download
                                      </span>
                                    </div>
                                    <span
                                      class="material-symbols-outlined delete-icon"
                                      @click="handleClickDeletePhoto($event , 'photo_10_show' , 'gas_refills', index_car , index_gas_refills)"
                                    >
                                      delete
                                    </span>
                                  </div>
                                </div>
                                <div v-else style="display: flex; flex-direction: column; align-items: center;">
                                  <p class="ant-upload-drag-icon">
                                    <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                                  </p>
                                  แนบรูปภาพ
                                </div>
                              </a-upload>
                            </div>
                          </a-form-item>
                      
                          <a-form-item name="gas1" label="8. ใบเสร็จ">
                          <a-upload
                            v-model:file-list="gas.photo_8_show"
                            list-type="picture-card"
                            class="avatar-uploader"
                            :show-upload-list="false"
                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                            @change="upload_files($event, 'photo_8_show' , 'gas_refills' , index_car , index_gas_refills)"
                            method="GET"
                          >
                            <div v-if="gas.photo_8_show.length" class="upload-container">
                              <img
                                :src="getUploadedUrl(gas.photo_8_show[0])"
                                class="uploaded-image"
                                alt="uploaded image"
                                @click="showPreview($event , getUploadedUrl(gas.photo_8_show[0]))"
                              />
                              <div class="icon-container">
                                <span
                                  class="material-symbols-outlined preview-icon"
                                  @click="handleIconClick('preview', getUploadedUrl(gas.photo_8_show[0]), $event)"
                                >
                                  visibility
                                </span>
                                <div @click="getBase64Image($event ,gas.photo_8_show[0])">
                                  <span class="material-symbols-outlined download-icon" style="color: black;">
                                    download
                                  </span>
                                </div>
                                <span
                                    class="material-symbols-outlined delete-icon"
                                    @click="handleClickDeletePhoto($event , 'photo_8_show' , 'gas_refills', index_car , index_gas_refills)"
                                  >
                                    delete
                                  </span>
                              </div>
                            </div>
                            <div v-else style="display: flex; flex-direction: column; align-items: center;">
                              <p class="ant-upload-drag-icon">
                                <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                              </p>
                              แนบรูปภาพ
                            </div>
                          </a-upload>
                        </a-form-item>
                        </div>
                    </a-card>
                  </div>
                  <a-empty description="ยังไม่มีการเติมน้ำมันจากคนขับ" v-else />
                </a-collapse-panel>
              </a-collapse>
            </a-collapse-panel>
            <a-collapse-panel v-if="checkBill" key="9" header="รายละเอียดค่าใช้จ่ายลูกค้า">
                <a-divider orientation="left">ค่าใช้จ่าย</a-divider>
                
                <a-form :layout="'vertical'" style="padding: 16px;">
                  <a-row :gutter="16">
                    <!-- ราคาวางบิลลูกค้า -->
                    <a-col :span="12">
                      <a-form-item label="ราคาวางบิลลูกค้า" name="selling_per_trip">
                        <a-input 
                        :disabled="!isProject||create_sheet.finished == 1"
                          v-model:value="create_sheet.selling_per_trip" 
                          placeholder="ราคาวางบิลลูกค้า" 
                          style="width: 100%;" 
                          
                        />
                      </a-form-item>
                    </a-col>

                    <!-- ราคาวางบิลอื่นๆ -->
                    <a-col :span="12">
                      <a-form-item label="ราคาวางบิลอื่นๆ" name="additional_selling">
                        <a-input 
                          :disabled="create_sheet.finished == 1"
                          v-model:value="create_sheet.additional_selling" 
                          placeholder="ราคาวางบิลอื่นๆ" 
                        />
                      </a-form-item>
                    </a-col>
                  </a-row>

                  <a-row :gutter="16">
                    <!-- ชาร์จสินค้ากลับ -->
                    <a-col :span="12">
                      <a-form-item label="ชาร์จสินค้ากลับ" name="return_cost">
                        <a-input 
                          :disabled="create_sheet.finished == 1"
                          v-model:value="create_sheet.return_cost" 
                          placeholder="ชาร์จสินค้ากลับ" 
                        />
                      </a-form-item>
                    </a-col>

                    <!-- ชาร์จเพิ่มเสียเวลา -->
                    <a-col :span="12">
                      <a-form-item label="ชาร์จเพิ่มเสียเวลา"  name="waiting_time_fee">
                        <a-input 
                          :disabled="create_sheet.finished == 1"
                          v-model:value="create_sheet.waiting_time_fee" 
                          placeholder="ชาร์จเพิ่มเสียเวลา" 
                        />
                      </a-form-item>
                    </a-col>
                  </a-row>

                  <a-row :gutter="16">
                    <!-- ชาร์จค่าค้างคืน -->
                    <a-col :span="12">
                      <a-form-item label="ชาร์จค่าค้างคืน" name="overnight_charge">
                        <a-input 
                          :disabled="create_sheet.finished == 1"
                          v-model:value="create_sheet.overnight_charge" 
                          placeholder="ชาร์จค่าค้างคืน" 
                        />
                      </a-form-item>
                    </a-col>

                    <!-- ยกเลิกการใช้รถ -->
                    <a-col :span="12">
                      <a-form-item label="ยกเลิกการใช้รถ" name="cancellation_fee">
                        <a-input 
                          :disabled="create_sheet.finished == 1"
                          v-model:value="create_sheet.cancellation_fee" 
                          placeholder="ยกเลิกการใช้รถ" 
                        />
                      </a-form-item>
                    </a-col>
                  </a-row>

                  <a-col :span="24" style="margin-bottom: 15px;">
                      <a-form-item name="total_selling">
                        <template #label>
                          <div style="display: flex; align-items: end; gap: 10px;">
                            <span>ราคาวางบิลรวม</span>
                            <a-button type="primary" @click="calculateTotalBilling">
                              คำนวณ
                            </a-button>
                          </div>
                        </template>
                        <a-input 
                          v-model:value="create_sheet.total_selling"
                          :formatter="formatterCurrency" 
                          :parser="parserCurrency" 
                          placeholder="ราคาวางบิลรวม" 
                          style="width: 100%;" 
                          disabled 
                        />
                      </a-form-item>
                    </a-col>
                </a-form>
              </a-collapse-panel>
          </a-collapse>
          <a-row justify="end">
              <a-button
                html-type="submit"
                type="primary"
                size="large"
                style="margin: 4px; text-align: right"
              >
                <span
                  class="material-symbols-outlined"
                  style="font-size: 16px; margin-right: 4px"
                  >Edit_Square</span
                >
                {{render_type == 'add' ? 'สร้างใบงาน':'บันทึกข้อมูล'}}
              </a-button>
            </a-row>
        </a-form>
      </div>
      <a-modal v-model:visible="model_qulity" title="สร้างใบงาน" :footer="null" centered>
            <a-form :model="submit_model" name="submit_model" autocomplete="off" layout="vertical"
                @finish="submit_create_sheets()()"
            >
            <a-col>
              <a-form-item name="qulity"
              :rules="[{ required: true, message: 'โปรดเลือกจำนวนรถ' }]"
              >
                        <template #label>
                            <div style="display: flex; align-items: center;">
                            <span>จำนวนรถ</span>
                            <a-checkbox  
                              v-model="submit_model.qulity" 
                              style="margin-left: 5px;" 
                              @change=enableQulity()> 
                            <span>กำหนดจำนวนรถ</span>
                            </a-checkbox>
                          </div>
                        </template>
                        <a-input
                          :disabled=isQulity
                          v-model:value="submit_model.qulity"
                          >
                        </a-input>
                      </a-form-item>
            </a-col>
            <a-row justify="end">
                <a-space>
                    <a-button key="back" @click="model_qulity = false">ปิด</a-button>
                    <a-button type="primary" @click="submit_create_sheets()" :loading="loading">ตกลง</a-button>
                </a-space>
            </a-row>
        </a-form>
        </a-modal>
        <a-modal v-model:visible="showModalRequireInput" @ok="showModalRequireInput=false" >
          <div style="display: flex; flex-direction: column ;justify-items:center;align-items: center">
            <span class="material-symbols-outlined" style="font-size: 50px;margin-right: 4px;color:red;margin-bottom: 5px">Info</span>
            <p style="text-align: center"> กรุณากรอกข้อมูลที่ระบุ
            </p>
          </div>
          <template #footer>
            <a-button type="primary" @click="validateAndClose">
              ตกลง
            </a-button>
          </template> 
        </a-modal>

        <a-modal v-model:visible="showModalActiveCar" @ok="showModalActiveCar=false" >
          <div style="display: flex; flex-direction: column ;justify-items:center;align-items: center">
              <span class="material-symbols-outlined" style="font-size: 50px;margin-right: 4px;color:red;margin-bottom: 5px">Info</span>
              <p>กรุณาระบุชื่อคนขับ ก่อนกดแจ้งงาน</p>
          </div>
          <template #footer>
            <a-button type="primary" @click="showModalActiveCar=false">
              ตกลง
            </a-button>
          </template> 
        </a-modal> 

        <a-drawer
          v-model:visible="assign_car_drawer"
          @close="handleClose"
          class="custom-class"
          style="color: red"
          title="ข้อมูลรถ"
          placement="right"
          width="650"
        >
          <a-form
            name="assign_car"
            autocomplete="off"
            layout="vertical"
            :model="assign_car_form"
            @finish="submit_assign_car"
          >
            <a-divider orientation="left" orientation-margin="0px">
              <a-space>ข้อมูลการจองรถ</a-space>
            </a-divider>
          
            <a-col :span="24">
              <div style="display: flex; gap: 20px; flex:1">
                <div style="display: flex; align-items: center; margin-top:20px; gap:10px ">
                  <a-checkbox-group v-model:value="create_sheet.company_3rd">
                  <a-checkbox  
                        v-model="create_sheet.company_3rd"
                        disabled 
                        style="margin-left: 8px;" 
                        @change="togglethridParty('disable')"
                        value="บริษัทรถร่วม"
                        > 
                    <span>บริษัทรถร่วม</span>
                  </a-checkbox>
                </a-checkbox-group>
                </div>
              <a-form-item
                  label="บริษัทรถ"
                  name="car_company_id"
                  style="flex:1"
                >
                  <a-select
                    v-model:value="create_sheet.car_company_id"
                    disabled
                    :options="
                      car_company_list.map(({ id, fullname }) => ({
                        value: id,
                        label: fullname,
                      }))
                    "
                  ></a-select>
                </a-form-item>
              </div>
            </a-col>

            <a-col :span="24" style="margin-top: 2%;" v-if="business != 'car_rental_a'">
              <a-form-item
                label="ประเภทงานขนส่ง"
                name="booking_type_id"
              >
              <a-select
                v-model:value="create_sheet.booking_type_id"
                :disabled="!isProject || create_sheet.is_project == 'ไม่เลือก'"
                placeholder="เลือกประเภทขนส่ง"
                :options="
                  transport_type_list.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))
                "
              ></a-select>
              </a-form-item>
            </a-col>
            <a-col :span="24" v-if="business != 'car_rental_a'">
              <a-form-item
                label="วันเวลา รับ - ส่ง"
                name="datetime"
              >
              <a-range-picker
                v-model:value="create_sheet.datetime"
                :disabled="create_sheet.booking_cars[0]?.status == 6 || create_sheet.booking_cars[0]?.status == 8 || create_sheet.booking_cars[0]?.status == 9 || create_sheet.booking_cars[0]?.status == 14
                 || create_sheet.booking_cars[0]?.status == 16 || create_sheet.booking_cars[0]?.status == 10 || create_sheet.booking_cars[0]?.status == 17 
                 || create_sheet.booking_cars[0]?.status == 19 || create_sheet.booking_cars[0]?.status == 18 || create_sheet.booking_cars[0]?.status == 5 
                 || create_sheet.booking_cars[0]?.status == 15 || create_sheet.booking_cars[0]?.status == 20"
                format="DD/MM/YYYY"
                :placeholder="['Start Time', 'End Time']"
                style="width: 100%;"
                @change="getTotalDate()"
              />
              </a-form-item>
            </a-col>
            <a-col :span="24" v-else>
              <a-form-item label="วันที่" name="price_date" :rules="[{ required: true, message: 'โปรดเลือกวันที่' }]">
                <a-select
                  v-model:value="create_sheet.price_date"
                  placeholder="วันที่"
                  :disabled="create_sheet.booking_cars[0]?.status == 8 || create_sheet.booking_cars[0]?.status == 9 
                  || create_sheet.booking_cars[0]?.status == 4|| create_sheet.booking_cars[0]?.status == 16 || create_sheet.booking_cars[0]?.status == 10 
                  || create_sheet.booking_cars[0]?.status == 17 || create_sheet.booking_cars[0]?.status == 19 
                  || create_sheet.booking_cars[0]?.status == 18 || create_sheet.booking_cars[0]?.status == 5 
                  || create_sheet.booking_cars[0]?.status == 15 || create_sheet.booking_cars[0]?.status == 20 
                  || create_sheet.booking_cars[0]?.status == 14 || create_sheet.booking_cars[0]?.status == 6"
                  :options="
                    priceProjectList.filter((item)=> {
                      var check = true;
                      if(create_sheet.origin){
                        check = item.start_place == create_sheet.origin && check;
                      }
                      if(create_sheet.time_type_id){
                        const nameType  = priceProjectList.find((itemP)=>itemP.id == create_sheet.time_type_id)?.time_type;
                        check = item.time_type == nameType && check;
                      }
                      if(create_sheet.time_period_id){
                        const namePeriod  = priceProjectList.find((itemT)=>itemT.id == create_sheet.time_period_id)?.time_period;
                        check = item.time_period == namePeriod && check;
                      }
                      return check 
                    }).map(({ price_date_value, price_date_text }) => ({
                        value: price_date_value,
                        label: price_date_text,
                    })).filter((item, index, self) => 
                    self.findIndex((t) => t.label === item.label) === index)
                  "
                  v-if="!isProject && create_sheet.distance_price_rate == 'price'"
                ></a-select>
                <a-date-picker
                  v-model:value="create_sheet.price_date"
                  :disabled=" create_sheet.booking_cars[0]?.status == 4 || create_sheet.booking_cars[0]?.status == 8 || create_sheet.booking_cars[0]?.status == 9 || create_sheet.booking_cars[0]?.status == 16 || create_sheet.booking_cars[0]?.status == 10 || create_sheet.booking_cars[0]?.status == 17 || create_sheet.booking_cars[0]?.status == 19 || create_sheet.booking_cars[0]?.status == 18 || create_sheet.booking_cars[0]?.status == 5 || create_sheet.booking_cars[0]?.status == 15 || create_sheet.booking_cars[0]?.status == 20"
                  :placeholder="'วันที่'"
                  format="DD/MM/YYYY"
                  style="width: 100%;"
                  v-else
                />
              </a-form-item>
            </a-col>
            <a-row :gutter="16" v-if="business != 'car_rental_a'">
              <a-col :span="12" >
                <a-form-item label="จำนวนวัน" name="total_days">
                  <a-input
                    placeholder="จำนวนวัน"
                    v-model:value="totalDays"
                    disabled
                  ></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item label="เวลารับสินค้า" name="total_days">
                  <a-time-picker
                    v-model:value="create_sheet.time_product"
                    :disabled="create_sheet.booking_cars[0]?.status == 6 || create_sheet.booking_cars[0]?.status == 8 || create_sheet.booking_cars[0]?.status == 9 
                    || create_sheet.booking_cars[0]?.status == 16 || create_sheet.booking_cars[0]?.status == 10 
                    || create_sheet.booking_cars[0]?.status == 14 || create_sheet.booking_cars[0]?.status == 17 || create_sheet.booking_cars[0]?.status == 19 
                    || create_sheet.booking_cars[0]?.status == 18 || create_sheet.booking_cars[0]?.status == 5  
                    || create_sheet.booking_cars[0]?.status == 15 || create_sheet.booking_cars[0]?.status == 20"
                    @change="auto_fill_days"
                    :show-time="{ format: 'HH:mm' }"
                    format="HH:mm"
                    :placeholder="'เวลารับสินค้า'"
                    :minuteStep="1"
                    style="width: 100%;"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <a-col :span="24" v-else>
              <a-form-item label="เวลา" name="price_time" :rules="[{ required: true, message: 'โปรดเลือกเวลา' }]">
                <a-select
                  :disabled="create_sheet.booking_cars[0]?.status == 8 || create_sheet.booking_cars[0]?.status == 9 
                  || create_sheet.booking_cars[0]?.status == 4 || create_sheet.booking_cars[0]?.status == 16 || create_sheet.booking_cars[0]?.status == 10 
                  || create_sheet.booking_cars[0]?.status == 17 || create_sheet.booking_cars[0]?.status == 19 
                  || create_sheet.booking_cars[0]?.status == 18 || create_sheet.booking_cars[0]?.status == 5  
                  || create_sheet.booking_cars[0]?.status == 15 || create_sheet.booking_cars[0]?.status == 20
                  || create_sheet.booking_cars[0]?.status == 14 || create_sheet.booking_cars[0]?.status == 6"
                  v-model:value="create_sheet.price_time"
                  placeholder="เวลา"
                  :options="
                    priceProjectList.filter((item)=> {
                      var check = true;
                      if(create_sheet.origin){
                        check = item.start_place == create_sheet.origin && check;
                      }
                      if(create_sheet.time_type_id){
                        const nameType  = priceProjectList.find((itemP)=>itemP.id == create_sheet.time_type_id)?.time_type;
                        check = item.time_type == nameType && check;
                      }
                      if(create_sheet.time_period_id){
                        const namePeriod  = priceProjectList.find((itemT)=>itemT.id == create_sheet.time_period_id)?.time_period;
                        check = item.time_period == namePeriod && check;
                      }
                      if(create_sheet.price_date){
                        check = item.price_date_value == create_sheet.price_date && check;
                      }
                      return check 
                    }).map(({ price_time_value, price_time_text }) => ({
                        value: price_time_value,
                        label: price_time_text,
                    })).filter((item, index, self) => 
                    self.findIndex((t) => t.label === item.label) === index)
                  "
                  v-if="!isProject && create_sheet.distance_price_rate == 'price'"
                ></a-select>
                <a-time-picker
                  v-model:value="create_sheet.price_time"
                  :disabled=" create_sheet.booking_cars[0]?.status == 4 || create_sheet.booking_cars[0]?.status == 8 || create_sheet.booking_cars[0]?.status == 9 || create_sheet.booking_cars[0]?.status == 16 || create_sheet.booking_cars[0]?.status == 10 || create_sheet.booking_cars[0]?.status == 17 || create_sheet.booking_cars[0]?.status == 19 || create_sheet.booking_cars[0]?.status == 18 || create_sheet.booking_cars[0]?.status == 5  || create_sheet.booking_cars[0]?.status == 15 || create_sheet.booking_cars[0]?.status == 20"
                  :show-time="{ format: 'HH:mm' }"
                  format="HH:mm"
                  :placeholder="'เวลา'"
                  :minuteStep="1"
                  style="width: 100%;"
                  v-else
                />
              </a-form-item>
            </a-col>

            <a-row :gutter="16" v-if="business != 'car_rental_a'">
              <a-col :span="12">
                <a-form-item label="ชั่วโมงวิ่งงาน" name="total_days">
                  <a-input
                    :disabled="create_sheet.booking_cars[0]?.status == 6 || create_sheet.booking_cars[0]?.status == 8 
                    || create_sheet.booking_cars[0]?.status == 9 || create_sheet.booking_cars[0]?.status == 16 
                    || create_sheet.booking_cars[0]?.status == 10 || create_sheet.booking_cars[0]?.status == 14 || create_sheet.booking_cars[0]?.status == 17 
                    || create_sheet.booking_cars[0]?.status == 19 || create_sheet.booking_cars[0]?.status == 18 
                    || create_sheet.booking_cars[0]?.status == 5  || create_sheet.booking_cars[0]?.status == 15 
                    || create_sheet.booking_cars[0]?.status == 20"
                    placeholder="ชั่วโมงวิ่งงาน"
                    @change="auto_fill_days"
                    v-model:value="create_sheet.work_hour"
                  ></a-input>   
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item label="เวลาส่งสินค้า" name="total_days">
                  <a-time-picker
                    v-model:value="create_sheet.time_product_finish"
                    disabled
                    :show-time="{ format: 'HH:mm' }"
                    format="HH:mm"
                    :placeholder="'เวลารับสินค้า'"
                    :minuteStep="1"
                    style="width: 100%;"
                  />    
                </a-form-item>
              </a-col>
            </a-row>

            <a-col :span="24">
              <a-form-item
                  label="ชนิดรถ"
                  name="car_type_id"
                >
                  <a-select
                  v-if="isProject || (!isProject && isProjectDistance)"
                    v-model:value="create_sheet.car_type_id"
                    placeholder="ชนิดรถ"
                    @change="priceCar('cartype')"
                    :disabled="from_worksheet || (business == 'car_rental_a' && [8,9,10,4,5,6,14,15].includes(create_sheet.booking_cars?.[0]?.status))"
                    :options="
                      car_type_list.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))
                    "
                  ></a-select>
                  <a-select
                  v-if="!isProject && !isProjectDistance"
                    v-model:value="create_sheet.car_type_id"
                    placeholder="ชนิดรถ"
                    @change="priceCar('distance')"
                    :disabled="from_worksheet || (business == 'car_rental_a' && [8,9,10,4,5,6,14,15].includes(create_sheet.booking_cars?.[0]?.status))"
                    :options="
                      priceRateListUsed.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))
                    "
                  ></a-select>
              </a-form-item>
            </a-col>

            <a-col :span="24">
              <a-form-item label="ทะเบียนรถ" name="plate_no">
                
                <a-select
                  :disabled="from_worksheet || statusDisable"
                  v-model:value="create_sheet.car_id"
                  @change="driverForCarTwo()"
                  placeholder="ทะเบียนรถ"
                  :options="
                    priceProjectList.filter((item)=> {
                      var check = true;
                      if(create_sheet.origin){
                        check = item.start_place == create_sheet.origin && check;
                      }
                      if(create_sheet.time_type_id){
                        const nameType  = priceProjectList.find((itemP)=>itemP.id == create_sheet.time_type_id)?.time_type;
                        check = item.time_type == nameType && check;
                      }
                      if(create_sheet.time_period_id){
                        const namePeriod  = priceProjectList.find((itemT)=>itemT.id == create_sheet.time_period_id)?.time_period;
                        check = item.time_period == namePeriod && check;
                      }
                      if(create_sheet.price_date){
                        check = item.price_date_value == create_sheet.price_date && check;
                      }
                      if(create_sheet.price_time){
                        check = item.price_time_value == create_sheet.price_time && check;
                      }
                      return check 
                    }).map(({ plate_no ,car_id}) => ({
                        value: car_id,
                        label: plate_no,
                    })).filter((item, index, self) => 
                    self.findIndex((t) => t.label === item.label) === index)
                  "
                  v-if="!isProject && create_sheet.distance_price_rate == 'price' && business == 'car_rental_a'"
                ></a-select>
                <a-select
                  v-else-if="create_sheet.is_project == 'โปรเจกต์' && create_sheet.distance_price_rate == 'distance'"
                  v-model:value="assign_car_form.car_id"
                  :disabled="create_sheet.booking_cars[0]?.status == 6 || create_sheet.booking_cars[0]?.status == 8 || create_sheet.booking_cars[0]?.status == 9 || create_sheet.booking_cars[0]?.status == 16 || create_sheet.booking_cars[0]?.status == 10 
                  || create_sheet.booking_cars[0]?.status == 14 || create_sheet.booking_cars[0]?.status == 17 || create_sheet.booking_cars[0]?.status == 19 || create_sheet.booking_cars[0]?.status == 18 || create_sheet.booking_cars[0]?.status == 5 
                  || create_sheet.booking_cars[0]?.status == 15 || create_sheet.booking_cars[0]?.status == 20
                  || (business=='car_rental_a' && from_worksheet)"
                  @change="handleCarChange"
                  :options="
                  getFilteredCarsBy3rd()
                  .map(({ id, plate_no, car_no, repair_time_end }) => ({
                      value: id,
                      label: `${plate_no} (${car_no}) ${repair_time_end ? '(แจ้งซ่อม)' : ''}`,
                      disabled: repair_time_end,
                    }))
                  "
                >
                <template #option="option">
                    <div>
                      <div :style="{ color: option.disabled ? 'red' : 'black' }">{{ option.label }}</div>
                    </div>
                  </template>
                </a-select>
                <a-select
                  v-else-if="create_sheet.is_project != 'โปรเจกต์'"
                  v-model:value="assign_car_form.car_id" 
                  :disabled="create_sheet.booking_cars[0]?.status == 6 || create_sheet.booking_cars[0]?.status == 8 || create_sheet.booking_cars[0]?.status == 9 || create_sheet.booking_cars[0]?.status == 16 
                  || create_sheet.booking_cars[0]?.status == 10 || create_sheet.booking_cars[0]?.status == 17 || create_sheet.booking_cars[0]?.status == 19 
                  || create_sheet.booking_cars[0]?.status == 14 || create_sheet.booking_cars[0]?.status == 18 || create_sheet.booking_cars[0]?.status == 5 || create_sheet.booking_cars[0]?.status == 15 
                  || create_sheet.booking_cars[0]?.status == 20 || (business=='car_rental_a' && from_worksheet)"
                  @change="handleCarChange"
                  :options="
                  getFilteredCarsBy3rd()
                  .map(({ id, plate_no, car_no, repair_time_end }) => ({
                      value: id,
                      label: `${plate_no} (${car_no}) ${repair_time_end ? '(แจ้งซ่อม)' : ''}`,
                      disabled: repair_time_end,
                    }))
                  "
                >
                <template #option="option">
                    <div>
                      <div :style="{ color: option.disabled ? 'red' : 'black' }">{{ option.label }}</div>
                    </div>
                  </template>
                </a-select>
                <a-select
                  v-else-if="create_sheet.is_project == 'โปรเจกต์' && create_sheet.distance_price_rate != 'distance'"
                  v-model:value="assign_car_form.car_id"
                  :disabled="create_sheet.booking_cars[0]?.status == 6 || create_sheet.booking_cars[0]?.status == 8 || create_sheet.booking_cars[0]?.status == 9 || create_sheet.booking_cars[0]?.status == 16 
                  || create_sheet.booking_cars[0]?.status == 10 || create_sheet.booking_cars[0]?.status == 17 || create_sheet.booking_cars[0]?.status == 19 
                  || create_sheet.booking_cars[0]?.status == 14 || create_sheet.booking_cars[0]?.status == 18 || create_sheet.booking_cars[0]?.status == 5 || create_sheet.booking_cars[0]?.status == 15 
                  || create_sheet.booking_cars[0]?.status == 20 || (business=='car_rental_a' && from_worksheet)"
                  @change="handleCarChange"
                  :options="
                  getFilteredCarsBy3rd()
                  .map(({ id, plate_no, car_no, repair_time_end }) => ({
                      value: id,
                      label: `${plate_no} (${car_no}) ${repair_time_end ? '(แจ้งซ่อม)' : ''}`,
                      disabled: repair_time_end,
                    }))
                  "
                >
                <template #option="option">
                    <div>
                      <div :style="{ color: option.disabled ? 'red' : 'black' }">{{ option.label }}</div>
                    </div>
                  </template>
                </a-select>
              </a-form-item>
            </a-col>

            <a-form-item name="driver_id">
              <div style="display: flex; align-items: center">
                <span style="margin-right: 8px; margin-bottom: 8px;">คนขับรถประจำ</span>
                <a-checkbox
                  style="margin-bottom: 8px;"
                  v-model:checked="assign_car_form.main_driver_enabled"
                  :disabled="create_sheet.booking_cars[0]?.status == 6 || create_sheet.booking_cars[0]?.status == 8 || create_sheet.booking_cars[0]?.status == 9 || create_sheet.booking_cars[0]?.status == 14 || create_sheet.booking_cars[0]?.status == 16 || create_sheet.booking_cars[0]?.status == 10 || create_sheet.booking_cars[0]?.status == 17 || create_sheet.booking_cars[0]?.status == 19 || create_sheet.booking_cars[0]?.status == 18 || create_sheet.booking_cars[0]?.status == 5 || create_sheet.booking_cars[0]?.status == 15 || create_sheet.booking_cars[0]?.status == 20"
                ></a-checkbox>
                <span style="margin-right: 8px; margin-left: 15px; margin-bottom: 8px;">คนขับรถสำรอง</span>
                <a-checkbox
                  style="margin-bottom: 8px;"
                  v-model:checked="assign_car_form.second_driver_enabled"
                  :disabled="create_sheet.booking_cars[0]?.status == 6 || create_sheet.booking_cars[0]?.status == 8 || create_sheet.booking_cars[0]?.status == 9 || create_sheet.booking_cars[0]?.status == 14 || create_sheet.booking_cars[0]?.status == 16 || create_sheet.booking_cars[0]?.status == 10 || create_sheet.booking_cars[0]?.status == 17 || create_sheet.booking_cars[0]?.status == 19 || create_sheet.booking_cars[0]?.status == 18 || create_sheet.booking_cars[0]?.status == 5 || create_sheet.booking_cars[0]?.status == 15 || create_sheet.booking_cars[0]?.status == 20"
                ></a-checkbox>
              </div>

              <a-input
                v-if="!assign_car_form.main_driver_enabled && !assign_car_form.second_driver_enabled"
                :value="getDriverFullName"
                :disabled="!assign_car_form.default_driver_enabled"
                placeholder="เลือกคนขับรถ"
              />

              <a-select
                v-if="assign_car_form.main_driver_enabled"
                v-model:value="assign_car_form.driver_id"
                show-search
                :disabled="!assign_car_form.main_driver_enabled"
                placeholder="เลือกคนขับรถประจำ"
                :options="
                // cars_with_backup_driver.filter((fd, index, self) => 
                //       filteredDrivers.some(bd => bd.id === fd.id) && 
                //       index === self.findIndex((t) => t.id === fd.id) &&
                //       (
                //         // ถ้าเป็น status จะเช็ค time_end
                //         ([8, 9, 16, 10, 17, 19, 18].includes(fd.status) && 
                //         render_datetime(fd.time_end,fd.time_product_finish) < render_datetime()) ||
                //         // ถ้าเป็น status อื่น จะแสดงทั้งหมด
                //         !([8, 9, 16, 10, 17, 19, 18].includes(fd.status))
                //       )
                //     )
                // .map(({ id, firstname, lastname, car_no }) => ({
                //     value: id,
                //     label: `${firstname} ${lastname}`,
                //     description: car_no !== null ? `เบอร์รถ: ${car_no}` : 'ไม่มีเบอร์รถ',
                //   }))
                car_with_backup_driver_list.filter((fd)=>filteredDrivers.some(bd => bd.id === fd.value))
                "
                @change="handleDriverChange"
              >
              <template #option="option">
                <div>
                  <div>{{ option.label }}</div>
                  <div style="color: #888;">{{ option.description }}</div>
                </div>
              </template>
            </a-select>

              <!-- <a-select
                v-if="assign_car_form.second_driver_enabled"
                v-model:value="assign_car_form.driver_id"
                show-search
                :disabled="!assign_car_form.second_driver_enabled"
                placeholder="เลือกคนขับรถสำรอง"
                :options="
                getDriversBy3rd().map(({ id, firstname, lastname, car_no }) => ({
                    value: id,
                    label: `${firstname} ${lastname}`,
                    description: car_no !== null ? `เบอร์รถ: ${car_no}` : 'ไม่มีเบอร์รถ',
                  }))
                "
                @change="check_driver_is_booked"
              >
              <template #option="option">
                  <div>
                    <div>{{ option.label }}</div>
                    <div style="color: #888;">{{ option.description }}</div>
                  </div>
                </template>
              </a-select> -->
              <a-select
                v-if="assign_car_form.second_driver_enabled"
                v-model:value="assign_car_form.driver_id"
                show-search
                :disabled="!assign_car_form.second_driver_enabled"
                placeholder="เลือกคนขับรถสำรอง"
                :options="
                //  cars_with_backup_driver.filter((fd, index, self) => 
                //       !filteredDrivers.some(bd => bd.id === fd.id) && 
                //       index === self.findIndex((t) => t.id === fd.id) &&
                //       fd.car_company_id == create_sheet.car_company_id &&
                //       (
                //         // ถ้าเป็น status จะเช็ค time_end
                //         ([8, 9, 16, 10, 17, 19, 18].includes(fd.status) && 
                //         render_datetime(fd.time_end,fd.time_product_finish) < render_datetime()) ||
                //         // ถ้าเป็น status อื่น จะแสดงทั้งหมด
                //         !([8, 9, 16, 10, 17, 19, 18].includes(fd.status))
                //       )
                //     )
                //   .map(({ id, firstname, lastname, car_no }) => ({
                //     value: id,
                //     label: `${firstname} ${lastname}`,
                //     description: car_no !== null ? `เบอร์รถ: ${car_no}` : 'ไม่มีเบอร์รถ',
                //   }))
                  car_with_backup_driver_list.filter((fd)=>!filteredDrivers.some(bd => bd.id === fd.value) && fd.carCompany == this.create_sheet.car_company_id)
                "
                @change="handleDriverChange"
              >
              <template #option="option">
                  <div>
                    <div>{{ option.label }}</div>
                    <div style="color: #888;">{{ option.description }}</div>
                  </div>
                </template>
              </a-select>
            </a-form-item>

            <a-form-item name="temporary_driver">
              <div style="display: flex; align-items: center">
                <span style="margin-right: 8px; margin-bottom: 8px;">คนขับรถชั่วคราว</span>
                <a-checkbox
                  style="margin-bottom: 8px;"
                  v-model:checked="assign_car_form.temporary_driver_enabled"
                  :disabled="create_sheet.booking_cars[0]?.status == 6 || create_sheet.booking_cars[0]?.status == 8 || create_sheet.booking_cars[0]?.status == 9 || create_sheet.booking_cars[0]?.status == 14 || create_sheet.booking_cars[0]?.status == 16 || create_sheet.booking_cars[0]?.status == 10 || create_sheet.booking_cars[0]?.status == 17 || create_sheet.booking_cars[0]?.status == 19 || create_sheet.booking_cars[0]?.status == 18 || create_sheet.booking_cars[0]?.status == 5 || create_sheet.booking_cars[0]?.status == 15 || create_sheet.booking_cars[0]?.status == 20"
                ></a-checkbox>
              </div>
              <a-input 
                v-model:value="assign_car_form.temporary_driver"
                :disabled="!assign_car_form.temporary_driver_enabled"
                placeholder="ชื่อคนขับรถชั่วคราว"
                @change="reMoveDriverId"
              />
            </a-form-item>

            <a-col :span="24" v-if="business != 'car_rental_a' || (business == 'car_rental_a' && [15,20,21].includes(create_sheet.booking_cars?.[0]?.status))">
              <a-form-item label="ราคารถ" name="total_days">
                <a-input
                  v-if="create_sheet.company_3rd == 'บริษัทรถร่วม'"
                  v-model:value="create_sheet.price_car"
                  disabled
                  placeholder="ราคารถ"
                />
                <a-input
                  v-else
                  v-model:value="carPayment"
                  disabled
                  placeholder="ราคารถ"
                />
              </a-form-item>
            </a-col>

            <a-divider orientation="left" orientation-margin="0px">
              <a-space v-if="create_sheet.company_3rd != 'บริษัทรถร่วม'">ค่าจ้างคนขับ</a-space>
              <a-space 
                v-if="
                  (create_sheet.company_3rd =='บริษัทรถร่วม' 
                  && business != 'car_rental_a') "
                  >ค่าแรงงาน</a-space>
            </a-divider>
            <a-col :span="24" v-if="create_sheet.company_3rd != 'บริษัทรถร่วม'">
              <a-form-item label="ค่าจ้างคนขับ" name="total_days">
                <a-input
                  v-model:value="driverSalary"
                  disabled
                  placeholder="ค่าจ้างคนขับ"
                />
              </a-form-item>
            </a-col>
            <a-col v-if="(create_sheet.company_3rd =='บริษัทรถร่วม' 
                        && business != 'car_rental_a')" 
                        :span="24">
              <a-form-item label="ค่าแรงงาน (ยกของ)" name="total_days">
                <a-input-number
                  v-model:value="create_sheet.lift_things"
                  :disabled="create_sheet.booking_cars[0]?.status == 8 || create_sheet.booking_cars[0]?.status == 9 || create_sheet.booking_cars[0]?.status == 16 || create_sheet.booking_cars[0]?.status == 10 || create_sheet.booking_cars[0]?.status == 17 || create_sheet.booking_cars[0]?.status == 19 || create_sheet.booking_cars[0]?.status == 18 || create_sheet.booking_cars[0]?.status == 5 || create_sheet.booking_cars[0]?.status == 15 || create_sheet.booking_cars[0]?.status == 20"
                  placeholder="ค่าแรงงาน (ยกของ)"
                />
              </a-form-item>
            </a-col>

            <a-row>
              <a-col>
                <a-form
                  :model="add_stop"
                  layout="vertical"
                  @finish="add_stop_to_table"
                >
                  <a-row justify="space-between">
                    <a-divider style="margin-bottom: 2px;" orientation="left" orientation-margin="0px">
                      <a-space>จุดแวะ(ระบบนำทาง)</a-space>
                    </a-divider>
                    <a-col>
                      <p style="margin-bottom: 1px;">ใช้ระบุจุดรับ-จุดส่ง หรือจุดที่ต้องการให้แวะระหว่างทาง เพื่อให้พนักงานขับรถทราบรายละเอียดสถานที่ที่ต้องไป</p>
                      <p>โดยข้อมูลนี้จะไปแสดงอยู่ที่ส่วนของใบงาน(Job Order) และแสดงที่ Vecabo Driver Application</p>
                    </a-col>
                  </a-row>
                  <p>
                    วัน เวลา สถานที่ ส่วนนี้จะแสดงให้คนขับเห็นผ่านทาง Vecabo
                    Driver Application หรือในใบงาน (Job Order)
                  </p>

                  <table style="border-collapse: collapse; width: 100%;">
                    <tr>
                      <td style="border: 1px solid #f0f0f0; font-size: 15px; padding: 7px; text-align: center;">ประเภทจุดแวะ</td>
                      <td style="border: 1px solid #f0f0f0; font-size: 15px; padding: 7px; text-align: center; width:50%">ชื่อสถานที่</td>
                      <td style="border: 1px solid #f0f0f0; font-size: 15px; padding: 7px; text-align: center;">เวลา</td>
                    </tr>
                    <tr v-if="create_sheet.origin">
                      <td style="border: 1px solid #f0f0f0; font-size: 14px; padding: 7px; text-align: center;">
                        <a-tag color="green">ต้นทาง</a-tag>
                      </td>
                      <td style="border: 1px solid #f0f0f0; font-size: 14px; padding: 7px; text-align: center;">{{ create_sheet.origin }}</td>
                      <td style="border: 1px solid #f0f0f0; font-size: 14px; padding: 7px; text-align: center;">{{ render_datetime(create_sheet.time_start,create_sheet.time_product) }}</td>
                    </tr>
                    <tr v-if="create_sheet.data_text_destination">
                      <td style="border: 1px solid #f0f0f0; font-size: 14px; padding: 7px; text-align: center;">
                        <a-tag color="green">ปลายทาง</a-tag>
                      </td>
                      <td style="border: 1px solid #f0f0f0; font-size: 14px; padding: 7px; text-align: center;">{{ render_des(create_sheet) }}</td>
                      <td style="border: 1px solid #f0f0f0; font-size: 14px; padding: 7px; text-align: center;">{{ render_datetime(create_sheet.time_end,create_sheet.time_product_finish) }}</td>
                    </tr>
                  </table>
                </a-form>
              </a-col>
            </a-row>
            <a-divider orientation="left" orientation-margin="0px">
              <a-space>
                รายการใช้น้ำมัน
                <a-button v-if="create_sheet?.booking_cars[0]?.status == 5 && create_sheet.company_3rd !='บริษัทรถร่วม' 
                || (business == 'car_rental_a' && create_sheet.company_3rd !='บริษัทรถร่วม' && [5].includes(create_sheet?.booking_cars[0]?.status) )" @click="init_gas_refillIn()" type="primary"
                  >เพิ่มรายการใช้น้ำมัน</a-button
                >
              </a-space>
            </a-divider>

            <a-row>
              <a-col>
                <a-table
                  :columns="[
                    {
                      title: 'วันเวลา',
                      dataIndex: 'created_at',
                      key: 'created_at',
                      align: 'center',
                    },
                    {
                      title: 'จำนวนลิตร(กำกับ)',
                      dataIndex: 'refill_amount',
                      key: 'refill_amount',
                      align: 'center',
                    },
                    {
                      title: 'ยอดจ่าย',
                      dataIndex: 'total_amount',
                      key: 'total_amount',
                      align: 'center',
                    },
                    {
                      title: 'จำนวนลิตรที่เติม',
                      dataIndex: 'total_refill_amount',
                      key: 'total_refill_amount',
                      align: 'center',
                    },
                    {
                      title: 'ประเภทการจ่ายเงิน',
                      dataIndex: 'pay_method_id',
                      key: 'pay_method_id',
                      align: 'center',
                    },
                    {
                      title: 'รูปหลักฐานการโอน',
                      dataIndex: 'bill_photo',
                      key: 'bill_photo',
                      align: 'center',
                    },
                    {
                      title: 'หมายเหตุ',
                      dataIndex: 'remark',
                      key: 'remark',
                      align: 'center',
                    },
                    ...(create_sheet?.booking_cars[0]?.status == 5 && create_sheet.company_3rd != 'บริษัทรถร่วม' ? [{
                      title: 'Action',
                      key: 'action',
                      width: 100,
                      align: 'center',
                      fixed: 'right',
                    }] : [])
                  ]"
                  :data-source="assign_car_form.gas_refills"
                  :scroll="{ x: 1200 , y: 500 }"
                  :pagination="false"
                >
                  <template #bodyCell="{ record, index, column }">
                    <template v-if="column.key === 'created_at'">
                      {{ render_time(record.created_at) }}
                    </template>
                    <template v-if="column.key === 'bill_photo'" >
                      <div v-if="record.bill_photo != null&&record.bill_photo !=''">
                        <img
                          :src="record.bill_photo"
                          class="uploaded-image-table"
                          alt="uploaded image"
                          @click="showPreview($event , record.bill_photo)"
                        />
                        <div class="icon-container-gas">
                          <span
                            class="material-symbols-outlined preview-icon"
                            style="font-size: 15px;"
                            @click="handleIconClick('preview', record.bill_photo, $event)"
                          >
                            visibility
                          </span>
                          <div @click="getBase64Image($event ,record.bill_photo)">
                              <span class="material-symbols-outlined download-icon" 
                              style="color: black;font-size: 15px;">
                                download
                              </span>
                          </div>
                        </div>
                      </div>  
                    </template>
                    <template v-if="column.key === 'action'">
                      <a
                        @click="
                          edit_list_in_table(
                            record,
                            'create_gas_refill',
                            'add_gas_refill_drawer',
                            index
                          ),
                            init_gas_refill_edit()
                        "
                      >
                        <span
                          style="font-size: 18px"
                          class="material-symbols-outlined"
                          >edit</span
                        >
                      </a>
                      <a-divider type="vertical" />
                      <a
                        @click="
                          remove_list_from_table(
                            index,
                            'gas_refills',
                            'GasRefill'
                          )
                        "
                      >
                        <span
                          style="font-size: 18px"
                          class="material-symbols-outlined"
                          >delete</span
                        >
                      </a>
                    </template>
                    <template v-else-if="column.key === 'pay_method_id'">
                      {{
                        pay_method_list.find(
                          (pay_method) => pay_method.id === record.pay_method_id
                        )?.name
                      }}
                    </template>
                    <template v-else-if="column.key === 'refill_amount'">
                      {{ Number(record.refill_amount).toLocaleString() }}
                    </template>
                    <template v-else-if="column.key === 'amount'">
                      {{ Number(record.amount).toLocaleString() }}
                    </template>
                  </template>
                </a-table>
              </a-col>
            </a-row>

            <a-divider orientation="left" orientation-margin="0px">
              <a-space>
                รายการค่าทางด่วน
                <a-button v-if="create_sheet?.booking_cars[0]?.status == 5 && create_sheet.company_3rd !='บริษัทรถร่วม'
                || (business == 'car_rental_a' && create_sheet.company_3rd !='บริษัทรถร่วม' && [5].includes(create_sheet?.booking_cars[0]?.status) )" @click="init_toll_wayIn()" type="primary">
                  เพิ่มรายการค่าทางด่วน
                </a-button>
              </a-space>
            </a-divider>

            <a-row>
              <a-col>
                <a-table
                  :columns="[
                    {
                      title: 'วันเวลา',
                      dataIndex: 'created_at',
                      key: 'created_at',
                      align: 'center',
                      width: 150,
                    },
                    {
                      title: 'ผู้ชำระ',
                      dataIndex: 'driver_paid',
                      key: 'driver_paid',
                      align: 'center',
                    },
                    {
                      title: 'ยอดจ่าย',
                      dataIndex: 'paid_amount',
                      key: 'amount',
                      align: 'center',
                    },
                    {
                      title: 'สถานะงาน',
                      key: 'booking_status',
                      dataIndex: 'booking_status',
                      align: 'center',
                    },
                    {
                      title: 'รูปใบเสร็จ/สลิป',
                      key: 'pay_slip_photo',
                      dataIndex: 'pay_slip_photo',
                      width: 120,
                      align: 'center',
                    },
                    {
                      title: 'หมายเหตุ',
                      key: 'remark',
                      dataIndex: 'remark',
                      align: 'center',
                    },
                    ...(create_sheet?.booking_cars[0]?.status == 5 && create_sheet.company_3rd != 'บริษัทรถร่วม' ? [{
                      title: 'Action',
                      key: 'action',
                      width: 100,
                      align: 'center',
                      fixed: 'right',
                    }] : [])
                  ]"
                  :data-source="assign_car_form.toll_ways"
                  :pagination="false"
                  :scroll="{ x: 1200 , y: 500 }"
                >
                  <template #bodyCell="{ column, record, index }">
                    <template v-if="column.key === 'created_at'">
                      {{ render_time(record.created_at) }}
                    </template>
                    <template v-if="column.key === 'action'">
                      <a
                        @click="
                          edit_list_in_table(
                            record,
                            'create_toll_way',
                            'show_create_toll_way',
                            index
                          )
                        "
                      >
                        <span
                          style="font-size: 18px"
                          class="material-symbols-outlined"
                          >edit</span
                        >
                      </a>
                      <a-divider type="vertical" />
                      <a
                        @click="
                          remove_list_from_table(index, 'toll_ways', 'TollWay')
                        "
                      >
                        <span
                          style="font-size: 18px"
                          class="material-symbols-outlined"
                          >delete</span
                        >
                      </a>
                    </template>
                    <template v-else-if="column.key === 'datetime'">
                      {{ render_time(record.datetime) }}
                    </template>
                    <template v-else-if="column.key === 'paid_amount'">
                      {{ Number(record.paid_amount).toLocaleString() }}
                    </template>
                    <template v-else-if="column.key == 'booking_status'">
                      <a-tag
                        v-if="
                          this.assign_car_form.logs?.filter(
                            ({ model_name }) => model_name == 'BookingCarStatus'
                          ).length
                        "
                        color="green"
                      >
                        {{
                          render_date(
                            this.assign_car_form.logs?.filter(
                              ({ model_name }) => model_name == "BookingCarStatus"
                            )[0].created_at,
                            "DD/MM/YYYY HH:mm:ss"
                          )
                        }}
                      </a-tag>
                      <a-tag color="red" v-else> ยังไม่ได้รับงาน </a-tag>
                    </template>
                    <template v-else-if="column.key === 'driver_paid'">
                      {{ record.driver_paid == "1" ? "คนขับ" : "บริษัท" }}
                    </template>
                    <template v-if="column.key === 'pay_slip_photo'">
                      <div v-if="record.pay_slip_photo" class="upload-container">
                        <img
                          :src="record.pay_slip_photo"
                          style="max-width: 50px; max-height: 50px;"
                          alt="uploaded image"
                          @click="showPreview($event , record.pay_slip_photo)"
                        />
                        <div class="icon-container">
                          <span
                            class="material-symbols-outlined preview-icon"
                            @click="handleIconClick('preview', record.pay_slip_photo, $event)"
                          >
                            visibility
                          </span>
                          <div @click="getBase64Image($event , { url: record.pay_slip_photo})">
                          <span class="material-symbols-outlined download-icon" style="color: black;">
                            download
                          </span>
                          </div>
                        </div>
                      </div>
                      
                    </template>
                  </template>
                </a-table>
              </a-col>
            </a-row>

            <a-divider orientation="left" orientation-margin="0px">
              <a-space
                >ค่าใช้จ่ายอื่นๆ
                <a-button v-if="create_sheet?.booking_cars[0]?.status == 5 
                || (business == 'car_rental_a' && create_sheet.company_3rd !='บริษัทรถร่วม' && [5].includes(create_sheet?.booking_cars[0]?.status) )" @click="handleAddBookingExpenseIn()" type="primary">
                  เพิ่มค่าใช้จ่ายอื่นๆ
                </a-button>
              </a-space>
            </a-divider>

            <a-row>
              <a-col>
                <a-table
                  :columns="[
                    {
                      title: 'ผู้ชำระ',
                      key: 'driver_paid',
                      dataIndex: 'driver_paid',
                      align: 'center',
                    },
                    {
                      title: 'ประเภทการจ่ายเงิน',
                      dataIndex: 'pay_method_id',
                      key: 'pay_method_id',
                      align: 'center',
                    },
                    {
                      title: 'รายการ',
                      dataIndex: 'expense_id',
                      key: 'expense_id',
                      align: 'center',
                    },
                    {
                      title: 'ยอดจ่าย',
                      dataIndex: 'total_payment',
                      key: 'total_payment',
                      align: 'center',
                    },
                    {
                      title: 'รูปใบเสร็จ/สลิป',
                      dataIndex: 'photo_slip',
                      key: 'photo_slip',
                      align: 'center',
                    },
                    {
                      title: 'หมายเหตุ',
                      key: 'remark',
                      dataIndex: 'remark',
                      align: 'center',
                    },
                    ...(create_sheet?.booking_cars[0]?.status == 5 && create_sheet.company_3rd == 'บริษัทรถร่วม' || create_sheet?.booking_cars[0]?.status == 5 && create_sheet.company_3rd != 'บริษัทรถร่วม' ? [{
                      title: 'Action',
                      key: 'action',
                      width: 100,
                      align: 'center',
                      fixed: 'right',
                    }] : [])
                  ]"
                  :data-source="assign_car_form.expenses"
                  :pagination="false"
                  :scroll="{ x: 1200 , y: 500 }"
                >
                  <template #bodyCell="{ index, column, record}">
                    <template v-if="column.key === 'action'">
                      <a
                        @click="
                          edit_list_in_table(
                            record,
                            'create_booking_car_expense',
                            'show_create_booking_car_expense',
                            index
                          )
                        "
                      >
                        <span
                          style="font-size: 18px"
                          class="material-symbols-outlined"
                          >edit</span
                        >
                      </a>
                      <a-divider type="vertical" />
                      <a
                        @click="remove_list_from_table(
                            index,
                            'expenses',
                            'BookingCarExpense'
                          )
                        "
                      >
                        <span
                          style="font-size: 18px"
                          class="material-symbols-outlined"
                          >delete</span
                        >
                      </a>
                    </template>
                    <template v-else-if="column.key === 'pay_method_id'">
                      {{
                        pay_method_list.find(
                          (pay_method) => pay_method.id === record.pay_method_id
                        )?.name
                      }}
                    </template>
                    <template v-else-if="column.key === 'expense_id'">
                      {{
                        expense_list.find(
                          (expense) => expense.id === record.expense_id
                        )?.name
                      }}
                    </template>
                    <template v-else-if="column.key === 'amount'">
                      {{ record.amount ? Number(record.amount).toLocaleString() : "" }}
                    </template>
                    <template v-else-if="column.key === 'driver_paid'">
                      {{ record.driver_paid == 1 ? "คนขับ" : "บริษัท" }}
                    </template>
                    <template v-else-if="column.key === 'photo_slip'">
                      <div v-if="record.photo_slip" class="upload-container">
                        <img
                          :src="record.photo_slip"
                          style="max-width: 50px; max-height: 50px;"
                          alt="uploaded image"
                          @click="showPreview($event , record.photo_slip)"
                        />
                        <div class="icon-container">
                          <span
                            class="material-symbols-outlined preview-icon"
                            @click="handleIconClick('preview', record.photo_slip, $event)"
                          >
                            visibility
                          </span>
                          <div @click="getBase64Image($event , { url: record.photo_slip})">
                          <span class="material-symbols-outlined download-icon" style="color: black;">
                            download
                          </span>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'total_payment'">
                      {{ record.total_payment ? Number(Number(record.total_payment).toFixed(0)).toLocaleString() : "" }}
                    </template>
                  </template>
                </a-table>
              </a-col>
            </a-row>

            <a-divider orientation="left" orientation-margin="0px">
              <a-space>เลขไมล์</a-space>
            </a-divider>
              <div v-if="business == 'sjinda_a'" style="display: flex; align-items: center; justify-content: center; gap:10px">
                <a-form-item>
                  <p style = "font-weight: 500;">เลขไมล์รถออกจากลานจอด</p>
                  <span style="font-size:medium">{{ startDistance }} km</span>
                </a-form-item>
                  <div style="border-right: 1px solid #D3D3D3; padding: 1px; height: 70px;"/>
                <a-form-item>
                  <p style = "font-weight: 500">เลขไมล์รถถึงต้นทาง</p>
                  <span style="font-size:medium">{{ create_sheet?.odos.find(odo=>odo.booking_status_id == 16) 
                    ? create_sheet?.odos?.slice()?.reverse()?.find(odo=>odo.booking_status_id == 16).distance
                    : create_sheet?.odos[1]?.distance }} km</span>
                </a-form-item>
                  <div style="border-right: 1.5px solid #D3D3D3; padding: 1px; height: 70px;"/>
                <a-form-item>
                  <p style = "font-weight: 500">เลขไมล์รถถึงปลายทาง</p>
                  <span style="font-size:medium">{{ endDistance }} km</span>
                </a-form-item>
                  <div style="border-right: 1.5px solid #D3D3D3; padding: 1px; height: 70px;"/>
                <a-form-item>
                  <p style = "font-weight: 500">รวมระยะทาง</p>
                  <span style="font-size:medium; font-weight: bold;">{{ startDistance && endDistance && create_sheet?.odos[1]?.distance ?  Number((endDistance - create_sheet?.odos[1]?.distance) + (create_sheet?.odos[1]?.distance - startDistance)).toLocaleString() : '' }} km</span>                        
                </a-form-item>
              </div>
              <div v-else style="display: flex; align-items: center; justify-content: center; gap:50px">
                <a-form-item>
                  <p style = "font-weight: 500;">เลขไมล์ก่อนเริ่มงาน</p>
                  <span style="font-size:medium">{{ startDistance }} km</span>
                </a-form-item>
                  <div style="border-right: 1px solid #D3D3D3; padding: 10px; height: 70px;"/>
                <a-form-item>
                  <p style = "font-weight: 500">เลขไมล์หลังเริ่มงาน</p>
                  <span style="font-size:medium">{{ endDistance }} km</span>
                </a-form-item>
                  <div style="border-right: 1.5px solid #D3D3D3; padding: 10px; height: 70px;"/>
                <a-form-item>
                  <p style = "font-weight: 500">รวมระยะทาง</p>
                  <span style="font-size:medium; font-weight: bold;">{{ Number(endDistance - startDistance).toLocaleString() }} km</span>                        </a-form-item>
              </div>

            <a-row justify="end" style="margin-top: 5%;">
              <a-button
                html-type="submit"
                :disabled="loading"
                type="primary"
                size="large"
                style="margin: 4px; text-align: right"
              >
                <span
                  class="material-symbols-outlined"
                  style="font-size: 16px; margin-right: 4px"
                  >Edit</span
                >
                บันทึกข้อมูล
              </a-button>
            </a-row>
          </a-form>
        </a-drawer>

        <a-drawer
          v-model:visible="show_create_booking_car_expense"
          placement="right"
          title="เพิ่มค่าใช้จ่ายอื่นๆ"
          :style="{ zIndex: 1050 }"
        >
          <a-form
            :model="create_booking_car_expense"
            layout="vertical"
            @finish="
              submit_drawer(
                'create_booking_car_expense',
                'expenses',
                'show_create_booking_car_expense',
                'BookingCarExpense'
              )
            "
          >
            <a-row>
              <a-col :span="12">
                <a-form-item
                  label="ผู้ชำระ"
                  name="driver_paid"
                  :rules="[{ required: true, message: 'กรุณาเลือกผู้ชำระ' }]"
                >
                  <a-radio-group
                    v-model:value="create_booking_car_expense.driver_paid"
                  >
                    <a-radio-button value="1">คนขับ</a-radio-button>
                    <a-radio-button value="0">บริษัท</a-radio-button>
                  </a-radio-group>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item
                  label="ประเภทการจ่ายเงิน"
                  name="pay_method_id"
                  :rules="[
                    {
                      required: true,
                      message: 'เลือกประเภทการจ่ายเงิน',
                    },
                  ]"
                >
                  <a-select
                    v-model:value="create_booking_car_expense.pay_method_id"
                    placeholder="เลือกประเภทการจ่ายเงิน"
                  >
                  <a-select-option
                    v-for="{ id, name } in pay_method_list"
                    :key="id"
                    :value="id"
                  >
                    {{ name }}
                  </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>

            <a-form-item
              label="รายการ"
              name="expense_id"
              :rules="[{ required: true, message: 'กรุณากรอกรายการ' }]"
            >
              <a-select v-model:value="create_booking_car_expense.expense_id">
                <a-select-option
                  v-for="expense in expense_list"
                  :key="expense.id"
                  :value="expense.id"
                >
                  {{ expense.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item
              label="จำนวนเงิน"
              name="amount"
              :rules="[{ required: true, message: 'กรุณากรอกจำนวนเงิน' }]"
            >
              <a-input-number
                v-model:value="create_booking_car_expense.amount"
              />
            </a-form-item>

            <a-form-item label="รูปหลักฐานการโอน" name="remark">
              <a-upload
                v-model:file-list="create_booking_car_expense.fileList"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                @change="handleUpload($event, 'create_booking_car_expense')"
                method="GET"
              >
                <img
                  v-if="create_booking_car_expense.photo"
                  :src="create_booking_car_expense.photo"
                  :style="`width: 100%;object-fit: cover`"
                  alt="avatar"
                />
                <div >
                  <p class="ant-upload-drag-icon">
                    <span
                      class="material-symbols-outlined"
                      style="font-size: 5rem"
                      >upload_file</span
                    >
                  </p>
                  คลิกเพื่ออัพโหลดรูปภาพ
                </div>
              </a-upload>
            </a-form-item>

            <a-row justify="end">
              <a-button
                html-type="submit"
                type="primary"
                size="large"
                style="margin: 4px; text-align: right"
              >
                <span
                  class="material-symbols-outlined"
                  style="font-size: 16px; margin-right: 4px"
                  >Edit</span
                >
                บันทึกข้อมูล
              </a-button>
            </a-row>
          </a-form>
        </a-drawer>

        <a-drawer
          v-model:visible="show_create_toll_way"
          placement="right"
          title="เพิ่มรายการค่าทางด่วน"
          :style="{ zIndex: 1050 }"
        >
          <a-form
          :model="create_toll_way"
          layout="vertical"
          @finish="handleFinishTollWay"
          >
            <a-form-item
              label="ผู้ชำระ"
              name="driver_paid"
              :rules="[{ required: true, message: 'กรุณากรอกผู้ชำระ' }]"
            >
              <a-radio-group v-model:value="create_toll_way.driver_paid">
                <a-radio-button value="1">คนขับ</a-radio-button>
                <a-radio-button value="0">บริษัท</a-radio-button>
              </a-radio-group>
            </a-form-item>

            <a-form-item
              label="สถานะงาน"
              name="booking_status"
              :rules="[{ required: true, message: 'เลือกสถานะงาน' }]"
            >
              <a-select
                v-model:value="create_toll_way.booking_status"
                placeholder="เลือกสถานะงาน"
              >
                <a-select-option :value="8">ก่อนเริ่มงาน</a-select-option>
                <a-select-option :value="7">ระหว่างงาน</a-select-option>
                <a-select-option :value="11">หลังจบงาน</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item
              label="จำนวนเงิน"
              name="amount"
              :rules="[{ required: true, message: 'กรุณากรอกจำนวนเงิน' }]"
            >
              <a-input-number
                v-model:value="create_toll_way.amount"
                :min="0"
                :max="1000000"
              />
            </a-form-item>

            <a-form-item
              label="วันเวลา"
              name="datetime"
              :rules="[{ required: true, message: 'กรุณากรอกวันเวลา' }]"
            >
              <a-date-picker
                v-model:value="create_toll_way.datetime"
                :show-time="{ format: 'HH:mm' }"
                format="DD/MM/YYYY HH:mm"
                placeholder="เลือกเวลา"
                :minuteStep="1"
              />
            </a-form-item>

            <a-form-item label="ใบรูปหลักฐานการโอน" name="photo_1">
              <div class="upload-section">
                <a-upload
                  v-model:file-list="tollWay.photo_1_show"
                  list-type="picture-card"
                  class="avatar-uploader"
                  accept="image/*"
                  :show-upload-list="false"
                  action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                  method="GET"
                >
                  <img
                    v-if="create_toll_way.photo_1"
                    :src="create_toll_way.photo_1"
                    :style="`width: 100%;object-fit: cover`"
                    alt="avatar"
                  />
                  <div>
                    <p class="ant-upload-drag-icon">
                      <span
                        class="material-symbols-outlined"
                        style="font-size: 5rem"
                        >upload_file</span
                      >
                    </p>
                    คลิกเพื่ออัปโหลดรูปภาพ
                  </div>
                </a-upload>
              </div>
            </a-form-item>

            <a-row justify="end">
              <a-button
                html-type="submit"
                type="primary"
                size="large"
                style="margin: 4px; text-align: right"
              >
                <span
                  class="material-symbols-outlined"
                  style="font-size: 16px; margin-right: 4px"
                  >Edit</span
                >
                บันทึกข้อมูล
              </a-button>
            </a-row>
          </a-form>
        </a-drawer>

        <a-drawer
          v-model:visible="add_gas_refill_drawer"
          placement="right"
          title="บันทึกค่าน้ำมัน"
          :style="{ zIndex: 1050 }"
          @close="create_gas_refill = {}"
        >
          <a-form
            name="create_gas_refill"
            autocomplete="off"
            layout="vertical"
            :model="create_gas_refill"
            @finish="handleFinishGas"
          >
            <a-switch
              v-model:checked="create_gas_refill.use_gas_station"
              checkedChildren="ปั้มที่ร่วมกับบริษัท"
              unCheckedChildren="ปั้มนอก"
            />
            <a-form-item
              v-if="create_gas_refill.use_gas_station"
              label="เลือกปั้มน้ำมัน"
              name="gas_station_id"
              :rules="[{ required: true, message: 'เลือกปั้มน้ำมัน' }]"
            >
              <a-select
                v-model:value="create_gas_refill.gas_station_id"
                placeholder="เลือกปั้มน้ำมัน"
              >
              <a-select-option
                v-for="{ id, name } in gas_station_list"
                :key="id"
                :value="id"
              >
                {{ name }}
              </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              v-else
              label="ระบุชื่อปั้ม"
              name="station_name"
              :rules="[{ required: true, message: 'เลือกปั้มน้ำมัน' }]"
            >
              <a-textarea v-model:value="create_gas_refill.station_name" />
            </a-form-item>
            <a-form-item
              label="ชนิดน้ำมัน"
              name="gas_type"
              :rules="[
                { required: true, message: 'เลือกชนิดน้ำมัน' },
              ]"
            >
              <a-select
                v-model:value="create_gas_refill.gas_type"
                placeholder="เลือกชนิดน้ำมัน"
              >
                <a-select-option :value="'Diesel'"
                  >Diesel</a-select-option
                >
                <a-select-option :value="'Diesel B7'"
                  >Diesel B7</a-select-option
                >
                <a-select-option :value="'Vepower Diesel'"
                  >Vepower Diesel</a-select-option
                >
                <a-select-option :value="'EV'"
                  >EV</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-row>
              <a-col :span="12">
                <a-form-item
                  label="จำนวนลิตร"
                  name="refill_amount"
                  :rules="[
                    {
                      required: true,
                      message: 'กรุณากรอกจำนวนลิตร',
                    },
                  ]"
                >
                  <a-input-number
                    v-model:value="create_gas_refill.refill_amount"
                    :min="0"
                    :max="1000"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item
                  label="จำนวนเงิน"
                  name="amount"
                  :rules="[{ required: true, message: 'กรุณาจำนวนเงิน' }]"
                >
                  <a-input-number
                    v-model:value="create_gas_refill.amount"
                    :min="0"
                    :max="100000"
                  />
                </a-form-item>
              </a-col>
            </a-row>

            <a-form-item
              label="ประเภทการจ่ายเงิน"
              name="pay_method_id"
              :rules="[
                {
                  required: true,
                  message: 'เลือกประเภทการจ่ายเงิน',
                },
              ]"
            >
              <a-select
                v-model:value="create_gas_refill.pay_method_id"
                placeholder="เลือกประเภทการจ่ายเงิน"
              >
              <a-select-option
                v-for="{ id, name } in pay_method_list"
                :key="id"
                :value="id"
              >
                {{ name }}
              </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item
              label="วันเวลา"
              name="datetime"
              :rules="[{ required: true, message: 'กรุณากรอกวันเวลา' }]"
            >
              <a-date-picker
                v-model:value="create_gas_refill.datetime"
                :show-time="{ format: 'HH:mm' }"
                format="DD/MM/YYYY HH:mm"
                placeholder="เลือกเวลา"
                :minuteStep="1"
              />
            </a-form-item>
            <a-form-item name="bill_photo" label="รูปหลักฐานการโอน">
              <a-upload
                v-model:file-list="gas.bill_photo_show"
                list-type="picture-card"
                class="avatar-uploader"
                accept="image/*"
                :show-upload-list="false"
                action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                @change="upload_files($event, 'bill_photo_show','gas_refills',gas.orderPos,gas.indexGas)"
                method="GET"
                @remove="remove_uploaded_file"
              >
              <div v-if="bill_photo_show_source != ''" class="upload-container-gas">
                <img
                  :src="bill_photo_show_source"
                  class="uploaded-image"
                  alt="uploaded image"
                />
              </div>
                <div style="display: flex; flex-direction: column; align-items: center;">
                  <p class="ant-upload-drag-icon">
                    <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                  </p>
                  คลิกเพื่ออัพโหลดรูปภาพ
                </div>
              </a-upload>
            </a-form-item>

            <a-row justify="end">
              <a-button
                html-type="submit"
                type="primary"
                size="large"
                style="margin: 4px; text-align: right"
              >
                <span
                  class="material-symbols-outlined"
                  style="font-size: 16px; margin-right: 4px"
                  >Edit</span
                >
                บันทึกข้อมูล
              </a-button>
            </a-row>
          </a-form>
        </a-drawer>       
    </div>
  </a-spin>
  </div>
                    <a-modal
                      v-model:visible="total_cars_expense_modal"
                      width="80%"
                      :footer="null"
                    >
                      <template #title>
                        <div style="margin-bottom: 5px;">ต้นทุนค่าใช้จ่าย ใบจองจัดรถ ID : {{ create_sheet.booking_no}}</div>
                      </template>
                      <p style="color: black; margin-left: 10px;">ทะเบียนรถ : {{ selected_booking[0]?.car.plate_no }} </p>
                      <div v-if="total_car_expenses">
                      <a-collapse
                        v-model:activeKey="total_car_expenses_collapse"
                        expand-icon-position="right"
                        style="background-color: white; font-size: 18px; font-weight: 500"
                      >
                        <a-collapse-panel key="1"  v-if="create_sheet.company_3rd !== 'บริษัทรถร่วม'">
                          <template #header>
                            <div>
                              1. ค่าทางด่วน - 
                              {{ total_car_expenses.toll_ways?.filter((i) => i.granted)?.length }} /
                              {{ total_car_expenses.toll_ways?.length }}
                              <!-- <a-tag v-if="showWarningTollWays" bordered="false" color="error">!</a-tag> -->
                            </div>
                          </template>
                          <template #extra>
                            {{ totaTollWays }}บาท
                          </template>
                          
                          <a-button
                            @click="init_toll_way()"
                            type="primary"
                            style="margin-bottom: 10px"
                            v-if="this.create_sheet.finished != 1"
                            >เพิ่มค่าทางด่วน</a-button
                          >
                          <a-table
                            :dataSource="filteredTollWays"
                            :pagination="false"
                            :columns="tollwayColumn"
                          >
                            <template #bodyCell="{ column, record }">
                              <template v-if="column.key === 'plate_no'">
                                {{ this.selected_booking[0]?.car.plate_no }}
                              </template>
                              <template v-if="column.key === 'driver_paid'">
                                {{ record.driver_paid ? "คนขับรถ" : "บริษัท" }}
                              </template>
                              <template v-if="column.key === 'created_at'">
                                {{ render_time(record.created_at) }}
                              </template>
                              <template v-if="column.key === 'granted'">
                                <a-select
                                  v-model:value="record.granted"
                                  @change="
                                    update_granted(
                                      record,
                                      'toll_ways',
                                      $event,
                                      total_car_expenses.toll_ways
                                    )
                                  "
                                  style="width: 120px"
                                  placeholder="เลือกสถานะ"
                                >
                                  <a-select-option :value="null">รออนุมัต</a-select-option>
                                  <a-select-option
                                    v-for="{ id, name } in expense_logs"
                                    :key="id"
                                    :value="id"
                                  >
                                    {{ name }}
                                  </a-select-option>
                                </a-select>
                              </template>
                              <template v-if="column.key === 'id' && record[column.key]">
                                <div>
                                  <a-tooltip>
                                    <template #title>แก้ไข</template>
                                    <a @click="edit_expense_log(record, 'toll_ways')"
                                      ><span style="font-size: 14px" class="material-symbols-outlined"
                                        >drive_file_rename_outline</span
                                      ></a
                                    >
                                  </a-tooltip>
                                  <a-divider type="vertical"></a-divider>
                                  <a-tooltip>
                                    <template #title>ยกเลิก</template>
                                    <a @click="remove_expense_log(record.id, 'toll_ways')"
                                      ><span style="font-size: 18px" class="material-symbols-outlined"
                                        >delete</span
                                      ></a
                                    >
                                  </a-tooltip>
                                </div>
                              </template>
                            </template>
                          </a-table>
                        </a-collapse-panel>

                        <a-collapse-panel key="2"  v-if="create_sheet.company_3rd !== 'บริษัทรถร่วม'">
                            <template #header>
                              <div>
                                2. ค่าน้ำมัน - 
                                {{ total_car_expenses.gas_refills?.filter((i) => i.granted)?.length }} /
                                {{ total_car_expenses.gas_refills?.length }}
                                <a-tag v-if="showWarningGasRefills" bordered="false" color="error">!</a-tag>
                              </div>
                            </template>
                            <template #extra>
                              {{ totalGasRefills }}บาท
                            </template>

                            <a-button
                              @click="init_gas_refill()"
                              type="primary"
                              style="margin-bottom: 10px"
                              v-if="this.create_sheet.finished != 1"
                              >เพิ่มค่าน้ำมัน</a-button
                            >
                            <a-table
                              :dataSource="filteredGasRefills"
                              :pagination="false"
                              :columns="gasRefillColumn"
                            >
                              <template #bodyCell="{ column, record }">
                                <template v-if="column.key === 'pay_method_id'">
                                  {{ pay_method_list.find((e) => record.pay_method_id == e.id)?.name }}
                                </template>
                                <template v-if="column.key === 'created_at'">
                                  {{ render_time(record.created_at) }}
                                </template>
                                <template v-if="column.key === 'granted'">
                                  <a-select
                                    v-model:value="record.granted"
                                    @change="
                                      update_granted(
                                        record,
                                        'gas_refills',
                                        $event,
                                        total_car_expenses.gas_refills
                                      )
                                    "
                                    style="width: 120px"
                                    placeholder="เลือกสถานะ"
                                  >
                                    <a-select-option :value="null">รออนุมัต</a-select-option>
                                    <a-select-option
                                      v-for="{ id, name } in expense_logs"
                                      :key="id"
                                      :value="id"
                                    >
                                      {{ name }}
                                    </a-select-option>
                                  </a-select>
                                </template>
                                <template v-if="column.key === 'id' && record[column.key]">
                                  <div>
                                    <a-tooltip>
                                      <template #title>แก้ไข</template>
                                      <a @click="edit_expense_log(record, 'gas_refills')"
                                        ><span style="font-size: 14px" class="material-symbols-outlined"
                                          >drive_file_rename_outline</span
                                        ></a
                                      >
                                    </a-tooltip>
                                    <a-divider type="vertical"></a-divider>
                                    <a-tooltip>
                                      <template #title>ยกเลิก</template>
                                      <a @click="remove_expense_log(record.id, 'gas_refills')"
                                        ><span style="font-size: 18px" class="material-symbols-outlined"
                                          >delete</span
                                        ></a
                                      >
                                    </a-tooltip>
                                  </div>
                                </template>
                              </template>
                            </a-table>
                          </a-collapse-panel>

                          <a-collapse-panel key="3"  v-if="create_sheet.company_3rd !== 'บริษัทรถร่วม'">
                            <template #header>
                              <div>
                                3. ค่าใช้จ่ายอื่นๆ - 
                                {{ total_car_expenses.expenses?.filter((i) => i.granted)?.length }} /
                                {{ total_car_expenses.expenses?.length }}
                                <a-tag v-if="showWarningExpenses" bordered="false" color="error">!</a-tag>
                              </div>
                            </template>
                            <template #extra>
                              {{ totalExpense }}บาท
                            </template>
                            <a-button
                              @click="handleAddBookingExpense()"
                              type="primary"
                              style="margin-bottom: 10px"
                              v-if="this.create_sheet.finished != 1"
                              >ค่าใช้จ่ายอื่นๆ</a-button
                            >
                            <a-table
                              :dataSource="filteredExpense"
                              :pagination="false"
                              :columns="expenseColumn"
                            >
                              <template #bodyCell="{ column, record }">
                                <template v-if="column.key === 'driver_paid'">
                                  {{ record.driver_paid ? "คนขับรถ" : "บริษัท" }}
                                </template>
                                <template v-else-if="column.key === 'expense_id'">
                                  {{
                                    expense_list.find(
                                      (expense) => expense.id === record.expense_id
                                    )?.name
                                  }}
                                </template>
                                <template v-if="column.key === 'granted'">
                                  <a-select
                                    v-model:value="record.granted"
                                    @change="
                                      update_granted(
                                        record,
                                        'expenses',
                                        $event,
                                        total_car_expenses.expenses
                                      )
                                    "
                                    style="width: 120px"
                                    placeholder="เลือกสถานะ"
                                  >
                                    <a-select-option :value="null">รออนุมัต</a-select-option>
                                    <a-select-option
                                      v-for="{ id, name } in expense_logs"
                                      :key="id"
                                      :value="id"
                                    >
                                      {{ name }}
                                    </a-select-option>
                                  </a-select>
                                </template>
                                <template v-if="column.key === 'id' && record[column.key]">
                                  <div>
                                    <a-tooltip>
                                      <template #title>แก้ไข</template>
                                      <a @click="edit_expense_log(record, 'expenses')"
                                        ><span style="font-size: 14px" class="material-symbols-outlined"
                                          >drive_file_rename_outline</span
                                        ></a
                                      >
                                    </a-tooltip>
                                    <a-divider type="vertical"></a-divider>
                                    <a-tooltip>
                                      <template #title>ยกเลิก</template>
                                      <a @click="remove_expense_log(record.id, 'expenses')"
                                        ><span style="font-size: 18px" class="material-symbols-outlined"
                                          >delete</span
                                        ></a
                                      >
                                    </a-tooltip>
                                  </div>
                                </template>
                              </template>
                            </a-table>
                          </a-collapse-panel>
                          
                          <a-collapse-panel key="4" :header="`4. ราคารถ`"  v-if="(create_sheet.company_3rd !== 'บริษัทรถร่วม' 
                              && business != 'car_rental_a')
                              || (create_sheet.company_3rd !== 'บริษัทรถร่วม' && business == 'car_rental_a' 
                                  && [15,20,21].includes(create_sheet.booking_cars?.[0]?.status))">
                            <template #extra>
                              {{ carPayment }} บาท</template>
                              <span v-for="{ car, driver_cost, rental_price } in filteredBookingCars" :key="car.plate_no">
                                ทะเบียนรถ: {{ car?.plate_no }} :
                                {{ carPayment }} บาท<br />
                              </span>
                          </a-collapse-panel>

                          <a-collapse-panel :key="business != 'car_rental_a' ? 5 
                            : business == 'car_rental_a' && [15,20,21].includes(create_sheet.booking_cars?.[0]?.status) ? 5 : 4 " 
                            :header=" business != 'car_rental_a' ?`5. ค่าจ้างคนขับ`
                            : business == 'car_rental_a' && [15,20,21].includes(create_sheet.booking_cars?.[0]?.status) ? '5. ค่าจ้างคนขับ' : '4. ค่าจ้างคนขับ'"  
                            v-if="create_sheet.company_3rd !== 'บริษัทรถร่วม'">
                            <template #extra>
                              {{ driverSalary }} บาท</template>
                              <span v-for="{ car, driver_cost, rental_price } in filteredBookingCars" :key="car.plate_no">
                                ค่าจ้างคนขับ: {{ car?.plate_no }} :
                                {{ driverSalary }} บาท<br />
                              </span>
                          </a-collapse-panel> 

                          <a-collapse-panel key="6" :header="`1. ราคารถ`"  v-if="(create_sheet.company_3rd == 'บริษัทรถร่วม' 
                              && business != 'car_rental_a')
                              || (create_sheet.company_3rd == 'บริษัทรถร่วม' && business == 'car_rental_a' 
                                  && [15,20,21].includes(create_sheet.booking_cars?.[0]?.status))">
                            <template #extra>
                              {{ create_sheet.price_car }} บาท</template>
                              <span v-for="{ car, driver_cost, rental_price } in filteredBookingCars" :key="car.plate_no">
                                ทะเบียนรถ: {{ car?.plate_no }} :
                                {{ create_sheet.price_car }} บาท<br />
                              </span>
                          </a-collapse-panel>

                          <a-collapse-panel key="7" :header="`2. ค่าแรงงาน`"  v-if="(create_sheet.company_3rd == 'บริษัทรถร่วม' 
                              && business != 'car_rental_a')">
                            <template #extra>
                              {{ create_sheet.lift_things }} บาท</template>
                              <span v-for="{ car, driver_cost, rental_price } in filteredBookingCars" :key="car.plate_no">
                                ทะเบียนรถ: {{ car?.plate_no }} :
                                {{ create_sheet.lift_things }} บาท<br />
                              </span>
                          </a-collapse-panel> 

                          <a-collapse-panel key="8"  v-if="create_sheet.company_3rd == 'บริษัทรถร่วม'">
                            <template #header>
                              <div>
                                {{business == 'car_rental_a' && ![15,20,21].includes(create_sheet.booking_cars?.[0]?.status) ? '1.' : business == 'car_rental_a' ? '2. ' :'3.' }} ค่าใช้จ่ายอื่นๆ - 
                                {{ total_car_expenses.expenses?.filter((i) => i.granted)?.length }} /
                                {{ total_car_expenses.expenses?.length }}
                                <a-tag v-if="showWarningExpenses" bordered="false" color="error">!</a-tag>
                              </div>
                            </template>
                            <template #extra>
                              {{ totalExpense }}บาท
                            </template>
                            <a-button
                              @click="handleAddBookingExpense()"
                              type="primary"
                              style="margin-bottom: 10px"
                              v-if="this.create_sheet.finished != 1"
                              >ค่าใช้จ่ายอื่นๆ</a-button
                            >
                            <a-table
                              :dataSource="filteredExpense"
                              :pagination="false"
                              :columns="expenseColumn"
                            >
                              <template #bodyCell="{ column, record }">
                                <template v-if="column.key === 'driver_paid'">
                                  {{ record.driver_paid ? "คนขับรถ" : "บริษัท" }}
                                </template>
                                <template v-else-if="column.key === 'expense_id'">
                                  {{
                                    expense_list.find(
                                      (expense) => expense.id === record.expense_id
                                    )?.name
                                  }}
                                </template>
                                <template v-if="column.key === 'granted'">
                                  <a-select
                                    v-model:value="record.granted"
                                    @change="
                                      update_granted(
                                        record,
                                        'expenses',
                                        $event,
                                        total_car_expenses.expenses
                                      )
                                    "
                                    style="width: 120px"
                                    placeholder="เลือกสถานะ"
                                  >
                                    <a-select-option :value="null">รออนุมัต</a-select-option>
                                    <a-select-option
                                      v-for="{ id, name } in expense_logs"
                                      :key="id"
                                      :value="id"
                                    >
                                      {{ name }}
                                    </a-select-option>
                                  </a-select>
                                </template>
                                <template v-if="column.key === 'id' && record[column.key]">
                                  <div>
                                    <a-tooltip>
                                      <template #title>แก้ไข</template>
                                      <a @click="edit_expense_log(record, 'expenses')"
                                        ><span style="font-size: 14px" class="material-symbols-outlined"
                                          >drive_file_rename_outline</span
                                        ></a
                                      >
                                    </a-tooltip>
                                    <a-divider type="vertical"></a-divider>
                                    <a-tooltip>
                                      <template #title>ยกเลิก</template>
                                      <a @click="remove_expense_log(record.id, 'expenses')"
                                        ><span style="font-size: 18px" class="material-symbols-outlined"
                                          >delete</span
                                        ></a
                                      >
                                    </a-tooltip>
                                  </div>
                                </template>
                              </template>
                            </a-table>
                          </a-collapse-panel>

                      </a-collapse>

                      <a-card>
          <div style="display: flex; justify-content: space-between">
            <h4>รวมต้นทุนค่าเช่ารถ</h4>
            <a-space>
              <!-- <a-button type="primary" :disabled="selected_booking.paid_expense" @click="on_expense_paided">{{ selected_booking.paid_expense?'จ่ายแล้ว':'จ่าย'}}</a-button> -->
              <h4 style="margin: auto">
                {{ sum_expense(selected_booking.booking_cars).toLocaleString() }} บาท
              </h4>
            </a-space>
          </div>
        </a-card>
                      </div>
                    </a-modal> 




</template>
<script>

import Swal from 'sweetalert2'
import SystemService from '../../api/SystemService'
import Utility from '../../utility'
import UserService from '../../api/UserService';
import CompanyService from '../../api/CompanyService';
import VueGoogleAutocomplete from "vue-google-autocomplete";
import OperationService from '../../api/OperationService';
import CarCompanyService from '../../api/CarCompanyService';
import BookingService from "../../api/BookingService.js";
import SheetService from '../../api/SheetService';
import dayjs from 'dayjs';
import { message } from "ant-design-vue";
import ProjectService from '../../api/ProjectService';
import ExportPdf from "../../components/modals/export_modal.vue";
import TableCreateDrawer from '@/components/table_create_drawer_project.vue'
import { co, s } from '@fullcalendar/core/internal-common';
import { t } from '@fullcalendar/resource/internal-common';
import DriverService from '../../api/DriverService';
import AccountingService from '../../api/AccountingService';
import SaleService from '../../api/SaleService';

export default {

  name: 'sheets',
  computed: {
    driverId() {
      const driver = this.cars_with_backup_driver.find(fd => fd.id === this.assign_car_form.driver_id);
      if (driver && [8, 9, 16, 10, 17, 19, 18].includes(driver.status)) {
        return null;
      } else if (driver) {
        this.handleDriverChange()
      }
      return this.assign_car_form.driver_id;
    },
    totalDays() {
      if(this.create_sheet.time_start != null && this.create_sheet.time_end != null){
        return this.countDays(this.create_sheet.time_start, this.create_sheet.time_end);
      }else{
        return 0;
      }
    },
    carPayment() {
      if (this.create_sheet.cars && this.create_sheet.cars.length > 0) {
        return (this.create_sheet.cars[0].car_payment / 26).toFixed(2);
      }
      return '';
    },
    driverSalary() {
      if(this.create_sheet.company_3rd == "บริษัทรถร่วม") {
        return 0
      }
      if (this.create_sheet.driver_id) {
        return (parseFloat(this.all_driver_list.find((item)=>item.id == this.create_sheet.driver_id).salary||0) / 26).toFixed(2);
      }
      return '';
    },
    getDriverFullName() {
      return this.get_backup_driver_fullname_with_car(this.assign_car_form.driver_id);
    },
    filteredDrivers() {
      if (this.assign_car_form?.car_id > 0) {
        const car = this.cars_with_backup_driver?.find(car => car.car_id == this.assign_car_form.car_id);
        if (car && car.car_no && car.car_company_id) {
          return this.cars_with_driver?.filter(driver => driver.car_no == car.car_no && driver.car_company_id == car.car_company_id);
        }
      }
      return [];
    },
    OperationColumn(){
      let columns = [
              {
                title: 'QTC',
                dataIndex: 'booking_no',
                key: 'booking_no',
                width: 150,
                align: 'center',
              },
              {
                title: 'สถานะใบงาน',
                dataIndex: 'sheet_status',
                key: 'sheet_status',
                width: 150,
                align: 'center',
                sorter: {
                compare: (a, b) => a.sheet_status - b.sheet_status,
                multiple: 1,
              },
              },
              {
                title: 'แก้ไขล่าสุด',
                dataIndex: 'updated_at',
                key: 'updated_at',
                width: 175,
                align: 'center',
              },
              {
                title: 'บริษัทรถ',
                dataIndex: 'car_company',
                key: 'car_company',
                width: 200,
                align: 'center',
              },
              {
                title: 'ลูกค้า/บริษัท',
                dataIndex: 'client_name',
                key: 'client_name',
                width: 220,
                align: 'center',
              },
              {
                title: 'วันที่ - เวลา รับ',
                dataIndex: 'time_start',
                key: 'time_start',
                width: 150,
                align: 'center',
              },
              {
                title: 'วันที่ - เวลา ส่ง',
                dataIndex: 'time_end',
                key: 'time_end',
                width: 150,
                align: 'center',
              },
            ]
          if(this.business == "car_rental_a") {
            columns.push(
              {
                title: 'ต้นทาง (สายงาน)',
                dataIndex: 'origin',
                key: 'origin',
                width: 150,
                align: 'center',
                // background: 'lightblue',
                align: 'center',
              },
              {
                title: 'ปลายทาง',
                dataIndex: 'data_text_destination',
                key: 'data_text_destination',
                width: 150,
                align: 'center',
                align: 'center',
              },  
              {
                title: 'เหตุผลในการยกเลิก/ปฏิเสธ',
                dataIndex: 'booking_cancel_text',
                key: 'booking_cancel_text',
                width: 200,
                align: 'center',
              },                          
              {
                title: 'ผู้ดูแลใบงานฝ่ายขาย',
                dataIndex: 'accounting_name',
                key: 'accounting_name',
                width: 200,
                align: 'center',
              },
              {
                title: 'ตัวเลือก',
                dataIndex: 'id',
                key: 'id',
                width: 180,
                align: 'center',
                fixed: 'right',
              },)
          }else{        
            columns.push(    
              {
                title: 'ต้นทาง',
                dataIndex: 'origin',
                key: 'origin',
                width: 150,
                align: 'center',
                // background: 'lightblue',
                align: 'center',
              },
              {
                title: 'ปลายทาง',
                dataIndex: 'data_text_destination',
                key: 'data_text_destination',
                width: 150,
                align: 'center',
                align: 'center',
              },
              {
                title: 'ประเภทงานขนส่ง',
                dataIndex: 'booking_type',
                key: 'booking_type',
                width: 200,
                align: 'center',
              },
              {
                title: 'เหตุผลในการยกเลิก/ปฏิเสธ',
                dataIndex: 'booking_cancel_text',
                key: 'booking_cancel_text',
                width: 200,
                align: 'center',
              },          
              {
                title: 'ผู้ดูแลใบงานฝ่ายปฏิบัติการ',
                dataIndex: 'operation_name',
                key: 'operation_name',
                width: 200,
                align: 'center',
              },
              {
                title: 'ตัวเลือก',
                dataIndex: 'id',
                key: 'id',
                width: 180,
                align: 'center',
                fixed: 'right',
              },)
          }
      return columns
    },
    gasRefillColumn(){
      let columns = [
              {
                title: 'ทะเบียนรถ',
                dataIndex: 'plate_no',
                key: 'plate_no',
              },
              {
                title: 'การชำระเงิน',
                dataIndex: 'pay_method_id',
                key: 'pay_method_id',
                align: 'center',
                width: 120,
              },
              {
                title: 'จำนวนเงิน',
                dataIndex: 'amount',
                key: 'amount',
                align: 'center',
                width: 150,
              },
              {
                title: 'จำนวนเงินที่จ่าย',
                dataIndex: 'total_amount',
                key: 'total_amount',
                align: 'center',
                width: 150,
              },
              {
                title: 'วัน-เวลา',
                dataIndex: 'created_at',
                key: 'created_at',
                align: 'center',
                width: 180,
              },
              {
                title: 'หมายเหตุกรณีไม่อนุมัติ',
                dataIndex: 'approve_remark',
                key: 'approve_remark',
              },
            ]
          if (this.create_sheet.finished != 1) {
              columns.push(              
              {
                title: 'สถานะ',
                dataIndex: 'granted',
                key: 'granted',
                align: 'center',
                width: 150,
              },
              {
                title: 'Action',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                width: 100,
              },)
          }
      return columns
    },
    expenseColumn(){
      let columns = [
              {
                title: 'ทะเบียนรถ',
                dataIndex: 'plate_no',
                key: 'plate_no',
              },
              {
                title: 'ผู้ชำระ',
                dataIndex: 'driver_paid',
                key: 'driver_paid',
                align: 'center',
                width: 100,
              },
              {
                title: 'จำนวนเงิน',
                dataIndex: 'amount',
                key: 'amount',
                align: 'center',
                width: 150,
              },
              {
                title: 'จำนวนเงินที่จ่าย',
                dataIndex: 'total_payment',
                key: 'total_payment',
                align: 'center',
                width: 150,
              },
              {
                title: 'รายการ',
                dataIndex: 'name',
                key: 'name',
                align: 'center',
              },
              {
                title: 'หมายเหตุกรณีไม่อนุมัติ',
                dataIndex: 'approve_remark',
                key: 'approve_remark',
              },
            ]
            if (this.create_sheet.finished != 1) {
              columns.push(
                {
                  title: 'สถานะ',
                  dataIndex: 'granted',
                  key: 'granted',
                  align: 'center',
                  width: 150,
                },
                {
                  title: 'Action',
                  dataIndex: 'id',
                  key: 'id',
                  align: 'center',
                  width: 100,
                }
              );
            }
            return columns
    },
    tollwayColumn(){
      let columns = [
        {
          title: 'ทะเบียนรถ',
          dataIndex: 'plate_no',
          key: 'plate_no',
        },
        {
          title: 'ผู้ชำระ',
          dataIndex: 'driver_paid',
          key: 'driver_paid',
          align: 'center',
          width: 100,
        },
        {
          title: 'จำนวนเงิน',
          dataIndex: 'amount',
          key: 'amount',
          align: 'center',
          width: 150,
        },
        {
          title: 'จำนวนเงินที่จ่าย',
          dataIndex: 'paid_amount',
          key: 'paid_amount',
          align: 'center',
          width: 150,
        },
        {
          title: 'วัน-เวลา',
          dataIndex: 'created_at',
          key: 'created_at',
          align: 'center',
          width: 180,
        },
        {
          title: 'หมายเหตุกรณีไม่อนุมัติ',
          dataIndex: 'approve_remark',
          key: 'approve_remark',
        },
      ];
      if (this.create_sheet.finished != 1) {
        columns.push(
          {
            title: 'สถานะ',
            dataIndex: 'granted',
            key: 'granted',
            align: 'center',
            width: 150,
          },
          {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 100,
          }
        );
      }
      return columns
    },
    startDistance() {
      const startOdo = this.create_sheet?.odos.find(odo => odo.booking_status_id == 9) 
        ? this.create_sheet?.odos?.slice()?.reverse()?.find(odo => odo.booking_status_id == 9) 
        :this.create_sheet?.odos?.find(odo => odo.is_start == 1);
      return startOdo ? startOdo.distance : null;
    },
    endDistance() {
      const startOdo = this.create_sheet?.odos.find(odo => odo.booking_status_id == 19) 
        ? this.create_sheet?.odos?.slice()?.reverse()?.find(odo => odo.booking_status_id == 19) 
        : this.create_sheet?.odos?.find(odo => odo.is_start == 0);
      return startOdo ? startOdo.distance : null;
    },
      from_worksheet() {
          return this.$route.query.manage_worksheet == "true" || this.$route.query.checkBill == "true";
      },
      checkBill() {
        return this.$route.query.checkBill == "true";
      },
      render_type() {
        return this.$route.params?.type;
      },

      total_car_expenses() {
      const cars = this.selected_booking[0];
      const car = this.selected_booking[0];
      const tollways_list = [];
      const gas_refills_list = [];
      const expenses_list = [];

      if (car && typeof car === 'object') {
        const { toll_ways, gas_refills, expenses } = car;

        if (Array.isArray(toll_ways)) {
          toll_ways.forEach((tollway) => {
            tollway.plate_no = car.car.plate_no;
            tollways_list.push(tollway);
          });
        }

        if (Array.isArray(gas_refills)) {
          gas_refills.forEach((gas_refill) => {
            gas_refill.plate_no = car.car.plate_no;
            gas_refills_list.push(gas_refill);
          });
        }
        if (Array.isArray(expenses)) {
          expenses.forEach((expense) => {
            const {
              // expense: { name },
              booking_car_id,
              amount,
              id,
              total_payment,
              granted,
              driver_paid,
              expense_id,
              approve_remark,
              edit_expenses_status,
              pay_method_id,
              photo,
            } = expense;
            expenses_list.push({
              id,
              booking_car_id,
              amount,
              plate_no: car.car.plate_no,
              total_payment,
              granted,
              approve_remark,
              driver_paid,
              expense_id,
              edit_expenses_status,
              pay_method_id,
              photo,
              photoList: [],
            });
          });
        }
      }

      return {
        toll_ways: tollways_list,
        gas_refills: gas_refills_list,
        expenses: expenses_list,
      };
    },
    totalGasRefills() {
      return this.filteredGasRefills
        .filter(({ granted }) => granted == 1)
        .reduce((a, b) => a + (Number(b.total_amount) || 0), 0)
        .toLocaleString();
    },
    filteredGasRefills() {
      const selectedPlateNo = this.selected_booking[0]?.car.plate_no;
      if (!selectedPlateNo || !this.total_car_expenses.gas_refills) {
        return []; 
      }
      return this.total_car_expenses.gas_refills.filter(item => item.plate_no === selectedPlateNo);
    },
    totalGasRefills() {
      return this.filteredGasRefills
        .filter(({ granted }) => granted == 1)
        .reduce((a, b) => a + (Number(b.total_amount) || 0), 0)
        .toLocaleString();
    },
    filteredTollWays() {
      const selectedPlateNo = this.selected_booking[0]?.car.plate_no ;
      if (!selectedPlateNo || !this.total_car_expenses.toll_ways) {
        return []; 
      }
      return this.total_car_expenses.toll_ways
    },
    totaTollWays() {
    return this.filteredTollWays
      .filter(({ granted }) => granted == 1)
      .reduce((a, b) => a + (Number(b.paid_amount) || 0), 0)
      .toLocaleString();
    },
    totalExpense() {
      return this.filteredExpense
        .filter(({ granted }) => granted == 1)
        .reduce((a, b) => a + (Number(b.total_payment) || 0), 0)
        .toLocaleString();
    },
    filteredExpense() {
      const selectedPlateNo = this.selected_booking[0]?.car.plate_no;
      if (!selectedPlateNo || !this.total_car_expenses.expenses) {
        return []; 
      }
      return this.total_car_expenses.expenses.filter(item => item.plate_no === selectedPlateNo);
    },

    totalRentalCost() {
      return this.filteredBookingCars
        .map(({ rental_price }) => {
        const rentalPriceNum = parseFloat(rental_price);
        if (!isNaN(rentalPriceNum)) {
          return rentalPriceNum;
        } else {
          return 0;
        }
      })
      .reduce((total, amount) => total + amount, 0)
      .toLocaleString(undefined, { maximumFractionDigits: 2 });
    },
    totalDriverCost() {
  const totalCost = this.filteredBookingCars
    .map(({ driver_cost }) => {
      const driverCostNum = parseFloat(driver_cost);
      return isNaN(driverCostNum) ? 0 : driverCostNum;
    })
    .reduce((total, amount) => total + amount, 0);

  return totalCost.toLocaleString(undefined, { maximumFractionDigits: 2 });
},

    filteredBookingCars() {
      const selectedPlateNo = this.selected_booking[0]?.car.plate_no;
      if (!selectedPlateNo) {
        return [];
      }
      return this.selected_booking.filter(({ car }) => car.plate_no === selectedPlateNo);
    },
    },
    components: {
      VueGoogleAutocomplete,
      ExportPdf,
      TableCreateDrawer,
    },
    data() {
      return {
        default_fields: {
          PriceRate:{
              columns:
              [
                  {
                      title: 'ลำดับ',
                      dataIndex: 'index',
                      key: 'index',
                      align: 'center',
                      width: 75,
                  },
                  {
                      title: 'ช่วงน้ำมันเริ่มต้น',
                      dataIndex: 'start_range',
                      key: 'start_range',
                      align: 'center',
                      width: 250,
                  },
                  {
                      title: 'ช่วงน้ำมันสิ้นสุด',
                      dataIndex: 'end_range',
                      key: 'end_range',
                      align: 'center',
                      width: 150,
                  },
                  {
                      title: 'ตัวเลือก',
                      dataIndex: 'id',
                      key: 'id',
                      width: 110,
                      align: 'center',
                      fixed: 'right'
                  },
              ],
              drawer_fields: {
                  inputs: {
                      start_range: {
                          input_type: 'text',
                          placeholder: 'ช่วงน้ำมันเริ่มต้น',
                          required: true,
                          col_span: 23
                      },
                      end_range: {
                          input_type: 'text',
                          placeholder: 'ช่วงน้ำมันสิ้นสุด',
                          required: true,
                          col_span: 23
                      },
                  },
              },
              inputs:{
                  divider_3: {
                      input_type: 'divider',
                      label: 'ช่วงน้ำมัน',
                      col_span: 22
                  },
                  oil_range_id: {
                      input_type: 'select',
                      modelname: 'OilRange',
                      label_key: 'name',
                      placeholder: 'ช่วงน้ำมัน',
                      required: true,
                      percent_type: false,
                      col_span: 23
                  },
                  divider_4: {
                      input_type: 'divider',
                      label: 'ตามระยะทาง/เรทราคา',
                      col_span: 23
                  },
                  price_type_id: {
                      input_type: 'radio',
                      placeholder: 'ตามระยะทาง/เรทราคา :',
                      required: true,
                      price_type: true,
                      col_span: 23,
                  },
              }
          },
        },
        showModalActiveCar: false,
        statusDisable: false,
        car_list: [],
        drawerCreate:false,
        create_booking_car_expense: {
          fileList: [],
        },
        expense_list: [],
        fileList: [],
        total_cars_expense_modal: false,
        selected_booking: null,
        bookingUpdated: {
          booking_no: '', 
          time_start: null, 
          time_end: null, 
          total_days: null, 
          start_location_name: null, 
          end_location_name: null, 
          booking_type: null, 
          status: null, 
          booking_cars_status: null, 
          booking_car_types: null, 
          car_late_amount: null, 
          billing_status: null, 
          client_name: null, 
          person_qty: null, 
          net_price: null, 
          total_cars_expense: null, 
          profit: null, 
          driverCost: null, 
        },
        is_edit_expense: false,
        edit_expense_index: null,
        pay_method_list: [],
        gas_station_list: [],
        create_gas_refill: {
          bill_photo:[]
        },
        create_photo: [],
        activeSubKeys: null,
        activeSubKeys2: null,
        gas: {
          photo_1_show: [],
          photo_2_show: [],
          photo_3_show: [],
          photo_4_show: [],
          photo_5_show: [],
          photo_6_show: [],
          photo_7_show: [],
          photo_8_show: [],
          photo_9_show: [],
          photo_10_show: [],
          bill_photo_show: [],
          indexGas:null,
          orderPos:null,
        },
        car: {
          service: {
            photo_1_show: [],
            photo_2_show: [],
            photo_3_show: [],
            photo_4_show: [],
            photo_5_show: [],
            photo_6_show: [],
            photo_7_show: [],
            photo_8_show: [],
            photo_9_show: [],
            photo_10_show: [],
            photo_11_show: [],
            photo_12_show: [],
          },
        },
        tollWay: {
          photo_1_show:[]
        },
        model_form: {
          key: null 
        },
        previewVisible: false,
        previewImage: "",
        add_stop: {},
        stop_type_list: [],
        assign_car_form: {},
        driver_id: null,
        main_driver_enabled: false,

        second_driver_id: null,
        second_driver_enabled: false,
        total_car_expenses_collapse: null,
        show_gas_refill_drawer: false,
        create_toll_way: {},

        expense_logs: [
        {
          id: 1,
          name: "อนุมัติ",
        },
        {
          id: 2,
          name: "ไม่อนุมัติ",
        },
      ],
        total_cars_expense_modal: false,
        selected_booking: {
        booking_cars: [
              {
                plate_no: null,
              },
            ],
          },
          bookingUpdated: {
            booking_no: '',
            total_cars_expense: null,
          },
          bookingCarExpenseModal: false,
          
        temporary_driver: null,
        temporary_driver_enabled: false,
        add_gas_refill_drawer: false,
        edit_inside: false,
        show_create_toll_way: false,
        create_toll_way: {},
        bill_photo_show_source:'',
        show_create_booking_car_expense: false,
        assign_car_drawer: false,
        editable: Utility.get_permission_editable_with_key('manage_sales'),
        show_table_list: [],
        button_title: 'เพิ่มคนขาย',
        input: 'รหัส / ชื่อคนขาย',
        activeKey: [],
        showModalRequireInput:false,
        pageEdit:false,
        client_company_list: [],
        car_company_list:[],
        transport_type_list: [
          {id:1,name:"ขาเดียว"},
          {id:2,name:"ไปกลับ"},
          {id:3,name:"การขนส่งแบบวิ่งเก็บ (Milkrun)"},],
        priceRatesList: [
            {id:1,name:"C_4WD"},
            {id:2,name:"C_4WH"},
            {id:3,name:"C_4WJ"},
            {id:4,name:"C_4WT"},
            {id:5,name:"C_6WH"},
            {id:6,name:"C_10WD"},
            {id:7,name:"C_10WH"},
            {id:8,name:"C_10WT"},
            {id:9,name:"C_18WH"},
            {id:10,name:"C_22WH"},
          ],
        priceRateListUsed:[],
        projectCarPriceList:[],
        oil_range_list:[],
        project_list:[],
        mockProject: null,
        project_type_list:[],
        model_qulity:false,
        car_type_list:[],
        time_type_list:[],
        time_period_list:[],
        main_and_sub_driver_list:[],
        car_for_type_list:[],
        operation_list:[],
        accounting_list:[],
        priceProjectList:[],
        originProjectList: [],
        destinationProjectList: [],
        destinationListSupport: [],
        mockDestination: null,
        isProjectDistance: false,
        destinations:[],
        descriptionDestination:[],
        descriptionOrigin:[],
        sheetCar:[],
        booking_detail: null,
        show_export_modal: false,
        isQulity:true,
        isInputFocused: false,
        isProject: true,
        isThird_party: true,
        allow_import: false,
        business: null,
        selectedSubDriver: false,
        loading:false,
        distance_price_rate:null,
        search_input:null,
        search_booking_type_id:null,
        search_sheet_status:null,
        search_datetime:null,
        remark_types:[],
        submit_model:{
          qulity:1,
        },
        cars_with_backup_driver: [],
        car_available_list:[],
        all_car_list:[],
        all_driver_list:[],
        cars_with_driver: [],
        user_name:null,
        car_with_backup_driver_list:[],
        cars_with_repair: [],
        sheets_data:[],
        work_sheets:[],
        create_sheet: {},
        index_project:null,
        main_driver_id:null,
        booking_files: [],
        delete_dest:[],
      }
    },
    watch: {
      'assign_car_form.main_driver_enabled'(newVal) {
        if (newVal) {
          this.assign_car_form.second_driver_enabled = false;
          this.assign_car_form.temporary_driver_enabled = false;
          this.assign_car_form.driver_id = null;

          const filteredDrivers = this.cars_with_backup_driver.filter((fd, index, self) => 
            this.filteredDrivers.some(bd => bd.id === fd.id) && 
            index === self.findIndex((t) => t.id === fd.id) &&
            (
              // ถ้าเป็น status จะเช็ค time_end
              ([8, 9, 16, 10, 17, 19, 18].includes(fd.status) && 
              new Date(fd.time_end) < new Date(this.create_sheet.datetime[0])) ||
              // ถ้าเป็น status อื่น จะแสดงทั้งหมด
              !([8, 9, 16, 10, 17, 19, 18].includes(fd.status))
            )
          );

          if (filteredDrivers.length > 0) {
            this.assign_car_form.driver_id = filteredDrivers[0].id;
            this.create_sheet.driver_id  = filteredDrivers[0].id;
          }
        }
      },
      'assign_car_form.second_driver_enabled'(newVal) {
        if (newVal) {
          this.assign_car_form.main_driver_enabled = false;
          this.assign_car_form.temporary_driver_enabled = false;
          this.assign_car_form.driver_id = null;
        }
      },
      'assign_car_form.temporary_driver_enabled'(newVal) {
        if (newVal) {
          this.assign_car_form.main_driver_enabled = false;
          this.assign_car_form.second_driver_enabled = false;
          this.assign_car_form.driver_id = null;
          this.assign_car_form.temporary_driver = null;
        }
      },
      'create_sheet.is_project'(newValue) {
          if (newValue === "โปรเจกต์") {
            this.sellingper();
          }
      },

      $route(to, from) {
        if (this.render_type != 'all') {
          this.init_model();
        } else if(this.render_type == 'all') {
          this.init_data();
        }
      },
    },
    methods: {
      printCheck(data){
        console.log("sadasd",data)
      },
      open_create_invoice(quotation, alter_title) {
        if(quotation != null){
          this.show_export_modal = true;
          this.booking_detail = quotation;
          this.booking_detail.alter_title = alter_title;
        }
      },
      create_export_close() {
        this.booking_detail = null;
        this.show_export_modal = false;
      },
      driverSelectChange(){
          if(this.selectedSubDriver == true){
            this.create_sheet.driver_id = null;
          }else{
            this.create_sheet.driver_id = this.main_driver_id;
          }
      },
      async handleCarChange() {
        const booking_car_id = this.sheetCar[0].booking_cars[0].id;
        await SystemService.update_all("BookingCar", { data: {car_id: this.assign_car_form.car_id} }, booking_car_id)
        await this.getModel(this.render_type);
      },
      async handleDriverChange() {
        const booking_car_id = this.sheetCar[0].booking_cars[0].id;
        await SystemService.update_all("BookingCar", { data: {driver_id: this.assign_car_form.driver_id} }, booking_car_id)
        await this.getModel(this.render_type);
      },
      getDriversBy3rd() {
        if (!this.assign_car_form.car || !this.assign_car_form.car.car_company_id) {
          return [];
        }
        return this.cars_with_backup_driver.filter(car => car.car_company_id == this.assign_car_form.car.car_company_id);
      },
      getFilteredCarsBy3rd() {
        if(this.create_sheet.is_project == 'โปรเจกต์' && this.create_sheet.distance_price_rate != 'distance' && this.business != 'car_rental_a') {
          const car_type_id = this.car_type_list.find(item => `C_${item.name}` === this.create_sheet.car_type_id)?.id
          return this.car_available_list.filter(car => 
            car.car_type_id == car_type_id && 
            car.car_company_id == this.create_sheet.car_company_id
          );
        }else if(this.create_sheet.is_project == 'โปรเจกต์' && this.create_sheet.distance_price_rate != 'distance' && this.business == 'car_rental_a'){
          return this.projectCarPriceList.filter(car => 
            car.car_type_id == this.create_sheet.car_type_id && 
            car.car_company_id == this.create_sheet.car_company_id
          );
        }else if(this.create_sheet.is_project != 'โปรเจกต์' && this.business == 'car_rental_a'){
          const statusFilterList = [8, 9, 10, 16, 17, 18, 19];
          const currentTime = new Date();
          const filteredCarsUseable = this.car_available_list.filter(car => {
              const statusMatch = statusFilterList.includes(car.status);

              var timeEnd = null
              // Parse time_end (full date)
              if(car.time_end && car.time_product_finish){
                const renderTime = this.render_datetime(car.time_end,car.time_product_finish);
                timeEnd = this.parseDate(renderTime);
              }
              // Check if time_end (date) is in the future
              const isTimeEndValid = timeEnd && timeEnd > currentTime;

              return statusMatch && isTimeEndValid;
          });
          const filteredCarIds = new Set(filteredCarsUseable.map(car => car.id));
          const finalResultCar = this.car_available_list
              .filter(car => !filteredCarIds.has(car.id)|| this.assign_car_form.car_id == car.id)
              .reduce((uniqueCars, car) => {
                  if (!uniqueCars.has(car.id)) {
                      uniqueCars.set(car.id, car); // Store only one instance per id
                  }
                  return uniqueCars;
              }, new Map()) // Use Map to store unique cars by id
              .values();
          return [...finalResultCar].filter(car => 
            car.car_type_id == this.create_sheet.car_type_id && 
            car.car_company_id == this.create_sheet.car_company_id
          );
        }else {
          return this.car_available_list.filter(car => 
            car.car_type_id == this.create_sheet.car_type_id && 
            car.car_company_id == this.create_sheet.car_company_id
          ).reduce((unique, car) => {
            if (!unique.some(c => c.id === car.id)) {
              unique.push(car);
            }
            return unique;
          }, []);
        }
        
      },
      getFilteredCars() {
        return this.cars_with_repair.filter(car => car.car_type_id == this.create_sheet.car_type_id);
      },
      getTransportName(transport_id) {
        const transport = this.transport_type_list.find(transport => transport.id === transport_id);
        return transport ? transport.name : '';
      },
      async assign_car(booking_car, { status_id, booking_car_id }) {
        const car_id = booking_car_id ? booking_car.car_id : booking_car.id;
        this.add_car_drawer = false;
        this.car_list = await SystemService.get_all("Car");
        if (!booking_car.driver_id) booking_car.driver_id = null;
        if (status_id == 1 || status_id == 7) {
          this.assign_car_drawer = true;
          this.assign_car_form = this.create_sheet.booking_cars[0];
        } else if (status_id) {
          message.info("รถไม่สามารถใช้งานได้", 0.5);
        } else {
          this.assign_car_drawer = true;
          const BookingCarValue = this.create_sheet.booking_cars[0];
          this.assign_car_form =  BookingCarValue;

          if (booking_car_id) {
            this.assign_car_form.booking_car_id = booking_car_id;
          } else {
            booking_car.gas_refills = [];
          }
        }

        if (!this.assign_car_form.remark) {
          const bookType = this.booking_types?.find(
            ({ id }) => id == this.create_sheet.booking_type_id[0]
          ).name;
          this.assign_car_form.remark = `ค่าขนส่งโดยสารปรับอากาศ\nเดินทาง\n${bookType}\nวันที่`;
        }

        if (booking_car.car) this.assign_car_form.plate_no = booking_car.car.plate_no;
        if (this.create_booking?.datetime?.length) {
          const bookingCar = this.create_sheet.booking_cars[0].find(
            car => car.id == booking_car.id
          );
          if (booking_car_id) {
            const startDate = dayjs(bookingCar.time_start)
            const endDate = dayjs(bookingCar.time_end)
            this.assign_car_form.datetime = [startDate, endDate];
            if (bookingCar.flight_pick) {
              this.assign_car_form.flight_pick = dayjs(bookingCar.flight_pick);
            }
            if (bookingCar.flight_drop) {
              this.assign_car_form.flight_drop = dayjs(bookingCar.flight_drop);
            }
          }
        }
        if (isNaN(this.assign_car_form.flight_pick)) {
          this.assign_car_form.flight_pick = "";
        }
        if (isNaN(this.assign_car_form.flight_drop)) {
          this.assign_car_form.flight_drop = "";
        }
        if (!this.assign_car_form.driver_cost) this.calculate_driver_cost();
      },
      remove_booking_car(id, record) {
        Swal.fire({
          title: "คุณต้องการลบรายการนี้ใช่หรือไม่?",
          text: "คุณจะไม่สามารถกู้คืนรายการนี้ได้หากลบแล้ว!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ใช่, ลบเดี๋ยวนี้!",
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            SystemService.delete_all("BookingCar", id).then((res) => {
              if (res) {
                this.create_sheet.booking_cars = this.create_sheet.booking_cars[0].filter(
                  (e) => e.id != id
                );
                for (let i = 0; i < this.create_sheet.booking_cars[0].length; i++) {
                  this.create_sheet.booking_cars[i].id = i + 1;
                  this.create_sheet.booking_cars[i].order_pos = i + 1;
                }
                this.init_data();
                Swal.fire("ลบเรียบร้อย!", "รายการของคุณถูกลบแล้ว", "success");
              }
            });
          }
        });
      },
      getUploadedUrl(file) {
        if (file?.response && file?.response.url) {
            return file.response.url;
        }
        return file.url || URL.createObjectURL(file.originFileObj);
      },
      async submit_drawer(form_model, array_name, drawer_name, modelname) {
    try {
        var formReq = JSON.parse(JSON.stringify(this[form_model]));
        if (formReq.id) {
            const id = formReq.id;
            if (form_model === "create_booking_car_expense") {
                delete formReq.index;
                delete formReq.photoList;
                delete formReq.plate_no;
            }
            await SystemService.update_all(modelname, { data: formReq }, id);
            await SystemService.update_all("BookingCarExpense", { data: { edit_expenses_status: 1 }}, id);
            formReq = {};
            this[form_model] = {};
        } else {
            if (this.assign_car_form[array_name]) {
                if (!formReq.index) {
                    if (array_name === "expenses" && this.is_edit_expense) {
                        this.assign_car_form[array_name][this.edit_expense_index] = formReq;
                        this.is_edit_expense = false;
                    } else {
                        if (this.drawerCreate) {
                            formReq.booking_car_id = this.selected_booking?.[0]?.id || null;
                            if (!formReq.booking_car_id) {
                                console.warn("⚠️ booking_car_id is missing!");
                            } else {
                            }

                            this.loading = true;

                            try {
                                const result = await SystemService.create_all(modelname, { data: [formReq] });
                                this.loading = false;
                                this.assign_car_form[array_name].push(result.data);
                                Swal.fire({
                                    icon: "success",
                                    title: "บันทึกข้อมูลสำเร็จ",
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                            } catch (error) {
                                this.loading = false;
                                console.error("❌ API Error while creating:", error);
                                Swal.fire({
                                    icon: "error",
                                    title: "เกิดข้อผิดพลาด",
                                    text: error.message,
                                });
                            }
                        } else {
                            this.assign_car_form[array_name].push(formReq);
                        }
                    }
                }
            } else {
                this.assign_car_form[array_name] = [formReq];
            }

            formReq = {};
            this[form_model] = {};
        }
        this.init_data();
        this[drawer_name] = false;

    } catch (error) {
        console.error("❌ Unexpected Error in submit_drawer:", error);
        Swal.fire({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
            text: "กรุณาลองใหม่อีกครั้ง",
        });
    }
},

      handleUpload(e, key) {
        const file = e.file.originFileObj;
        const vue = this;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          vue[key].photo = reader.result;
        };
      },
      handleIconClick(action, payload, event) {
        event.stopPropagation();
        if (action === 'preview') {
            this.showPreview(event , payload);
        } else if (action === 'delete') {
          this.fileList = [];
        }
      },
      submit_create_toll_wayIn() {
        this.edit_inside = false;
        const e = this.create_toll_way;
        const { id } = e;
        e.created_at = e.datetime.format("YYYY-MM-DD HH:mm:ss");
        const datetime = e.datetime
        delete e.datetime;
        delete e.plate_no;
        delete e.modelname;
        let currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
        this.bookingUpdated["total_cars_expense"] = currentTime
        // BookingService.booking_update_timestamp(this.bookingUpdated)
        if (id) {
          SystemService.update_all("TollWay", { data: e }, id).then(() => {
            SystemService.update_all("TollWay", { data: {edit_toll_ways_status: 1} }, id).then(() => {
              message.success("อัพเดทข้อมูลสำเร็จ");
              e.datetime = datetime
              this.show_create_toll_way = false;
              this.create_toll_way = {};
              this.model_form.photo_1_show = null
              this.init_data();
                if (e.edit_toll_ways_status == 1 ){
                this.newTollWay = true;
                }
              this.showWarningTollWays = true;
            });
          });
        } else {
          if(!this.assign_car_form.toll_ways){
            this.assign_car_form.toll_ways = []
          }
          if(this.drawerCreate){
            e.booking_car_id = this.selected_booking[0].id;
            SystemService.create_all("TollWay", { data: [e] }).then((result) => {
              message.success("เพิ่มข้อมูลสำเร็จ");
              this.create_toll_way = {};
              this.model_form.photo_1_show = null
              this.init_data();
              result.data.datetime = datetime
              this.assign_car_form.toll_ways.push(result.data)
            });
          }else if(!e.index && e.index != 0){
            this.model_form.photo_1_show = null
            e.datetime = datetime
            this.assign_car_form.toll_ways.push(e)
          }
          e.datetime = datetime
          this.show_create_toll_way = false;

        }
        if(this.total_cars_expense_modal){
          setTimeout(() => {
          }, 1000);
        }
      },
      async remove_list_from_table(index, key, modelname) {
        if (this.assign_car_form[key][index].id) {
          Swal.fire({
            title: "คุณต้องการลบรายการนี้ใช่หรือไม่?",
            text: "คุณจะไม่สามารถกู้คืนรายการนี้ได้หากลบแล้ว!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ใช่, ลบเดี๋ยวนี้!",
            cancelButtonText: "ยกเลิก",
          }).then((result) => {
            if (result.isConfirmed) {
              SystemService.delete_all(modelname, this.assign_car_form[key][index].id).then(
                (res) => {
                  if (res) {
                    this.init_data();
                    Swal.fire("ลบเรียบร้อย!", "รายการของคุณถูกลบแล้ว", "success");
                    this.assign_car_form[key].splice(index, 1);
                  }
                }
              );
            }
          });
        } else {
          this.assign_car_form[key].splice(index, 1);
        }
      },
      init_gas_refill_edit() {
        this.add_gas_refill_drawer = true;
        this.create_gas_refill.use_gas_station = this.create_gas_refill.gas_station_id
          ? true
          : false;
      },
      edit_list_in_table(obj, instance, drawer, index) {
        obj.index = index;
        obj.create
        this[instance] = obj;
        this[drawer] = true;
        if(instance == "create_gas_refill"){
          this.create_gas_refill.datetime = dayjs(this.create_gas_refill.created_at)
          this.gas.bill_photo_show = obj.bill_photo_show
          if(obj.bill_photo != null){
            this.bill_photo_show_source = obj.bill_photo
          }else if(obj.bill_photo_show.length){
            this.bill_photo_show_source = obj.bill_photo_show[0].url
          }else{
            this.bill_photo_show_source = ''
          }
          this.gas.indexGas = obj.index
          if(this.create_sheet.booking_cars.find(oc=>oc.id == obj.booking_car_id)){
            this.gas.orderPos = this.create_sheet.booking_cars.find(oc=>oc.id == obj.booking_car_id).order_pos-1
          }else{
            this.gas.orderPos = 0
          }
        }else if( instance == "create_toll_way"){
          this.create_toll_way.datetime = dayjs(this.create_toll_way.created_at)
        }else if( instance == "create_mileage"){
          this.create_mileage.datetime = dayjs(this.create_mileage.created_at)
        }else if( instance == "create_booking_car_expense"){
          this.edit_expense_index = index
          this.is_edit_expense = true;
        }
      },
      handleFinishGas(){
        if (this.edit_inside) {
          this.submit_create_gas_refillIn();
        }else{
          this.submit_create_gas_refill();
        }
      },
      handleFinishTollWay(){
        if (this.edit_inside) {
          this.submit_create_toll_wayIn();
        }else{
          this.submit_create_toll_way();
        }
      },
      async submit_create_gas_refillIn() {
        this.edit_inside = false;
        this.create_gas_refill.created_at = dayjs(this.create_gas_refill.datetime)
        var {
          pay_method_id,
          refill_amount,
          amount,
          remark,
          gas_station_id,
          station_name,
          gas_type,
          datetime,
          bill_photo,
        } = this.create_gas_refill;
        if (this.create_gas_refill.id) {
          const id = this.create_gas_refill.id;
          if(this.gas.orderPos == null || this.gas.indexGas == null){
            bill_photo = this.model_form.bill_photo_show !== undefined 
                          ? this.model_form.bill_photo_show 
                          :bill_photo
          }
          SystemService.update_all("GasRefill",{data: {pay_method_id, refill_amount, amount, remark, gas_station_id, station_name,gas_type,created_at : datetime, bill_photo},},id);
          SystemService.update_all("GasRefill", { data: { edit_gas_status: 1 }}, id); 
          this.add_gas_refill_drawer = false;
          if(this.create_gas_refill.index){
            this.assign_car_form.gas_refills[this.create_gas_refill.index].created_at = datetime
          }
          this.init_data();
        } else {
          if (!this.assign_car_form.gas_refills) {
            this.assign_car_form.gas_refills = [];
          }
          if (this.create_gas_refill.index === undefined) {
            if(this.drawerCreate){
              var formReq  = JSON.parse(JSON.stringify(this.create_gas_refill));
              formReq.booking_id = this.selected_car_booking[0].id
              delete formReq.datetime
              delete formReq.use_gas_station
              this.loading = true;
              formReq.bill_photo = this.model_form.bill_photo_show;
              await SystemService.create_all("GasRefill", { data: [formReq] }).then((result)=>{
                this.assign_car_form.gas_refills.push(result.data);
              });
              this.loading = false;
              Swal.fire({
                icon: "success",
                title: "บันทึกข้อมูลสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              });
              this.init_data();
            } else {
              this.assign_car_form.gas_refills.push({
                pay_method_id,
                refill_amount,
                amount,
                remark,
                gas_station_id,
                station_name,
                gas_type,
                photo_1_show: [],
                photo_2_show: [],
                photo_3_show: [],
                photo_4_show: [],
                photo_5_show: [],
                photo_6_show: [],
                photo_7_show: [],
                photo_8_show: [],
                photo_9_show: [],
                photo_10_show: [],
                bill_photo: this.model_form.bill_photo_show,
                bill_photo_show: [],
                booking_car_id: this.assign_car_form.booking_car_id,
                created_at: dayjs(this.create_gas_refill.datetime),
                datetime:dayjs(this.create_gas_refill.datetime)
              });
            }
          } else {
            this.assign_car_form.gas_refills[this.create_gas_refill.index].created_at = datetime
          }
        }
        this.create_gas_refill = {};
        this.add_gas_refill_drawer = false;
      },
      async getBase64ImageFromURL(url) {
        const res = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        });
        const blob = await res.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
      },
      async getBase64Image(e, file) {
        e.stopPropagation();
        e.preventDefault();
        if (typeof file === 'string') {
            const result = await this.getBase64ImageFromURL(file);
            let a = document.createElement('a');
            a.href = result;
            a.download = 'image.png';
            a.click();
        } else if (file.url) {
            const result = await this.getBase64ImageFromURL(file.url);

            // download
            let a = document.createElement('a');
            a.href = result;
            a.download = 'image.png';
            a.click();
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => {
                const base64String = reader.result;
                let a = document.createElement('a');
                a.href = base64String;
                a.download = 'image.png';
                a.click();
            };
        }
      },
      showPreview(e, fileUrl) {
        e.stopPropagation();
        this.previewImage = fileUrl;
        this.previewVisible = true;
      },
      async remove_uploaded_photo_1() {
            this.create_sheet.photo_1 = null
            this.init_data();
        },
      async remove_uploaded_file({ file, index, uid }) {
        if (uid) {
          const fileIndex = this.gas.photo_2_show.findIndex(f => f.uid === file.uid);
          if (fileIndex !== -1) {
            this.gas.photo_2_show.splice(fileIndex, 1);
          }
        } else if (index !== undefined) {
          this.gas.photo_1_show.splice(index, 1);
          this.gas.photo_2_show.splice(index, 1);
          this.gas.photo_3_show.splice(index, 1);
          this.gas.photo_4_show.splice(index, 1);
          this.gas.photo_5_show.splice(index, 1);
          this.gas.photo_6_show.splice(index, 1);
          this.gas.photo_7_show.splice(index, 1);
          this.gas.photo_8_show.splice(index, 1);
          this.gas.photo_9_show.splice(index, 1);
          this.gas.photo_10_show.splice(index, 1);
          this.gas.bill_photo_show.splice(index, 1);
        } else {
          const fileIndex = this.gas.photo_2_show.findIndex(f => f.uid === file.uid);
          if (fileIndex !== -1) {
            this.gas.photo_2_show.splice(fileIndex, 1);
          }
        }
      },
      getBase64(file) {
        return new Promise((resolve, reject) =>{
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        })
      },
      async getBase64Two(file, key) {
        if (key) {
          const vue = this
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
              vue.model_form[key] = reader.result
              vue.model_form[`${key}_file_name`] = file.name
          };
        } else {
          return await new Promise((resolve, reject) => {
              if (file) {
                  if (typeof file == 'string') {
                      resolve(file)
                  } else {
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = () => resolve(reader.result);
                      reader.onerror = error => reject(error);
                  }
              }else{
                reject('error')
              }
          });
        }
      },
      async upload_file(e) {
          try {
              const base64 = await this.getBase64(e);
              this.create_sheet.photo_1 = base64;
              const response = await fetch('https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6');
              if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
              return response.url;
          } catch (error) {
              console.error('Error uploading files:', error);
          }
      },
      upload_files(e, key , model_name = null , index = null , sub_index = null) {
        if (e.file.status != 'remove') {
          this.getBase64Two(e.file.originFileObj, key)
          if (model_name == 'gas_refills') {
            if (key == 'photo_1_show') {
              this.create_sheet.booking_cars[0].gas_refills[sub_index].photo_1 = this.model_form.photo_1_show
            } else if (key == 'photo_2_show') {
              this.create_sheet.booking_cars[0].gas_refills[sub_index].photo_2 = this.model_form.photo_2_show
            } else if (key == 'photo_3_show') {
              this.create_sheet.booking_cars[0].gas_refills[sub_index].photo_3 = this.model_form.photo_3_show
            } else if (key == 'photo_4_show') {
              this.create_sheet.booking_cars[0].gas_refills[sub_index].photo_4 = this.model_form.photo_4_show
            } else if (key == 'photo_5_show') {
              this.create_sheet.booking_cars[0].gas_refills[sub_index].photo_5 = this.model_form.photo_5_show
            } else if (key == 'photo_6_show') {
                this.create_sheet.booking_cars[0].gas_refills[sub_index].photo_6 = this.model_form.photo_6_show
            } else if (key == 'photo_7_show') {
                this.create_sheet.booking_cars[0].gas_refills[sub_index].photo_7 = this.model_form.photo_7_show
            } else if (key == 'photo_8_show') {
                this.create_sheet.booking_cars[0].gas_refills[sub_index].photo_8 = this.model_form.photo_8_show
            } else if (key == 'photo_9_show') {
                this.create_sheet.booking_cars[0].gas_refills[sub_index].photo_9 = this.model_form.photo_9_show
            } else if (key == 'photo_10_show') {
                this.create_sheet.booking_cars[0].gas_refills[sub_index].photo_10 = this.model_form.photo_10_show
            } else if (key == 'bill_photo_show') {
              if(index != null && sub_index != null){
                this.create_sheet.booking_cars[0].gas_refills[sub_index].bill_photo = this.model_form.bill_photo_show
              }
              this.bill_photo_show_source = this.model_form.bill_photo_show
            }
          }else if (model_name == 'services') {
            if (this.create_sheet.booking_cars && this.create_sheet.booking_cars[0] && this.create_sheet.booking_cars[0].service) {
              if (key == 'photo_1_show') {
                this.create_sheet.booking_cars[0].service.photo_1 = this.model_form.photo_1_show;
              } else if (key == 'photo_2_show') {
                this.create_sheet.booking_cars[0].service.photo_2 = this.model_form.photo_2_show;
              } else if (key == 'photo_3_show') {
                this.create_sheet.booking_cars[0].service.photo_3 = this.model_form.photo_3_show;
              } else if (key == 'photo_4_show') {
                this.create_sheet.booking_cars[0].service.photo_4 = this.model_form.photo_4_show;
              } else if (key == 'photo_5_show') {
                this.create_sheet.booking_cars[0].service.photo_5 = this.model_form.photo_5_show;
              } else if (key == 'photo_6_show') {
                this.create_sheet.booking_cars[0].service.photo_6 = this.model_form.photo_6_show;
              } else if (key == 'photo_7_show') {
                this.create_sheet.booking_cars[0].service.photo_7 = this.model_form.photo_7_show;
              } else if (key == 'photo_8_show') {
                this.create_sheet.booking_cars[0].service.photo_8 = this.model_form.photo_8_show;
              } else if (key == 'photo_9_show') {
                this.create_sheet.booking_cars[0].service.photo_9 = this.model_form.photo_9_show;
              } else if (key == 'photo_10_show') {
                this.create_sheet.booking_cars[0].service.photo_10 = this.model_form.photo_10_show;
              } else if (key == 'photo_11_show') {
                this.create_sheet.booking_cars[0].service.photo_11 = this.model_form.photo_11_show;
              } else if (key == 'photo_12_show') {
                this.create_sheet.booking_cars[0].service.photo_12 = this.model_form.photo_12_show;
              }
            }
          } else if (model_name == 'toll_ways') {
            if (key == 'photo_1_show') {
              // this.assign_car_form.toll_ways[index].photo_1 = this.model_form.photo_1_show
              this.create_toll_way.photo_1 = this.model_form.photo_1_show
            }
          }
          return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`
        }
      },
      handlePreview(file) {
        this.previewImage = file.url || file.thumbUrl;
        this.previewVisible = true;
        // window.open(this.previewImage);
      },
      handleClose() {
        // console.log('handleClose ถูกเรียกใช้งาน');
        // this.assign_car_drawer = false;
        // this.assign_car_form = {};
        // this.main_driver_enabled = false; 
        // this.second_driver_enabled = false; 
        // this.temporary_driver_enabled = false;
        // this.init_data();
      },
      formatSalary(salary) {
        return (salary / 26).toFixed(2);
      },
      select_row(selectedRowKeys) {
          this.selected_quotation = selectedRowKeys;
      },
      async activate_car(record) {
        try {
          const bookingCarId = record.booking_cars[0]?.id
          const bookingId = record.id;
          const driver_id = record.booking_cars[0]?.driver_id;
          if (!driver_id) {
            this.showModalActiveCar = true;
            return;
          }
          const response = await SystemService.update_all("BookingCar", { data: { active_car: 1, status: 11, booking_type_id: record.booking_type_id } }, bookingCarId);
          // console.log('5678',response)
          if (response) {
            await SystemService.update_all("Booking", { data: { active_job: 1, status: 11 } }, bookingId);
            Swal.fire("แจ้งงานสำเร็จ!", "ระบบได้แจ้งงานสำเร็จแล้ว", "success");
            setTimeout(() => {
              location.reload();
            }, 1500);
          }
        } catch (error) {
          this.$message.error("เกิดข้อผิดพลาดในการอัพเดทสถานะ");
          console.error("Error updating BookingCar:", error);
        }
      },
      get_driver_fullname_with_car(id) {
        const target = this.filteredDrivers.find(({ id: driver_id }) => driver_id === id);
        return target ? `${target.firstname} ${target.lastname}` : "";
      },
      get_backup_driver_fullname_with_car(id) {
        const target = this.cars_with_backup_driver.find(({ id: driver_id }) => driver_id === id);
        return target ? `${target.firstname} ${target.lastname}` : "";
      },
      add_stop_to_table() {
        if (this.add_stop.stop_type_id && this.add_stop.expected_timestamp && this.add_stop.name) {
          this.create_sheet.stops = this.create_sheet.stops || [];
          this.create_sheet.stops.push({
            stop_type_id: this.add_stop.stop_type_id,
            expected_timestamp: this.add_stop.expected_timestamp,
            name: this.add_stop.name,
          });
          this.add_stop = {}; // Clear the form
        } else {
          this.$message.error("กรุณากรอกข้อมูลให้ครบถ้วน");
        }
      },
      async submit_assign_car() {
        try {
          const booking_car_id = this.create_sheet.booking_cars[0].id;
          // const latlng = JSON.parse(this.sheetCar[0].data_ori_description).map(item => JSON.parse(item))
          // const stopTest = [{
          //   booking_car_id : booking_car_id,
          //   booking_id : this.sheetCar[0].id,
          //   lat: latlng[0].lat.toString(),
          //   lng: latlng[0].lng.toString(),
          //   name: this.sheetCar[0].origin,
          //   stop_type_id: 12,
          // }]
          const { 
            create_at, driver_id, stops, car_id, car_status_id, driver_cost, rental_price, guides, gas_refills, toll_ways, mileages, remark, expenses, pickup_items, deposit, temporary_driver, booking_type_id, person_qty, flight_pick, flight_drop, total_days, full_day_amount, trip_id,
          } = this.assign_car_form;
          const { car_company_id, company_3rd, lift_things, datetime, time_product, work_hour, time_product_finish, price_date, price_time} = this.create_sheet
          const bookingBody = {
            car_company_id: car_company_id,
            company_3rd: company_3rd,
            lift_things: lift_things,
            time_start: this.business == 'car_rental_a' ?  dayjs(price_date).format('YYYY-MM-DD HH:mm') : datetime[0],
            time_end: this.business == 'car_rental_a' ? dayjs(price_date).format('YYYY-MM-DD HH:mm') : datetime[1],
            time_product: this.business == 'car_rental_a' ? dayjs(price_time).format('YYYY-MM-DD HH:mm') : time_product,
            work_hour:  work_hour,
            time_product_finish: this.business == 'car_rental_a' ? dayjs(price_time).format('YYYY-MM-DD HH:mm') : time_product_finish,
          }
          // console.log('aaa',this.create_sheet.id)
          // console.log('bbb',company_3rd)
          const body = {
            car_id,
            driver_id,
            temporary_driver: temporary_driver,
            time_start: this.business == 'car_rental_a' ?  dayjs(price_date).format('YYYY-MM-DD HH:mm') : datetime[0],
            time_end: this.business == 'car_rental_a' ? dayjs(price_date).format('YYYY-MM-DD HH:mm') : datetime[1],
            time_product: this.business == 'car_rental_a' ? dayjs(price_time).format('YYYY-MM-DD HH:mm') : time_product,
            time_product_finish: this.business == 'car_rental_a' ? dayjs(price_time).format('YYYY-MM-DD HH:mm') : time_product_finish,
            //stops:stopTest,
            gas_refills: gas_refills
              ? gas_refills.map(
                  ({
                    id,
                    amount,
                    booking_car_id,
                    created_at,
                    gas_station_id,
                    pay_method_id,
                    refill_amount,
                    remark,
                    station_name,
                    gas_type,
                    bill_photo
                  }) => ({
                    id,
                    amount,
                    booking_car_id,
                    created_at,
                    gas_station_id,
                    pay_method_id,
                    refill_amount,
                    remark,
                    station_name,
                    gas_type,
                    bill_photo
                  })
                )
              : [],
            toll_ways: toll_ways
              ? toll_ways.map(
                  ({
                    id,
                    amount,
                    driver_paid,
                    booking_status,
                    created_at,
                    photo_1,
                    booking_car_id,
                  }) => ({
                    id,
                    amount,
                    driver_paid,
                    booking_status,
                    created_at,
                    photo_1,
                    booking_car_id,
                  })
                )
              : [],
            expenses: expenses
              ? expenses.map(({ id, amount, driver_paid, expense_id, pay_method_id, remark , photo}) => ({
                  id,
                  amount,
                  driver_paid,
                  expense_id,
                  pay_method_id,
                  remark,
                  photo
                }))
              : [],
          };
          if(this.business == 'car_rental_a'){
            bookingBody.price_date = dayjs(price_date).format('YYYY-MM-DD HH:mm');
            bookingBody.price_time = dayjs(price_time).format('YYYY-MM-DD HH:mm');
          }
          const aaa = await SystemService.update_all("Booking", { data: bookingBody }, this.create_sheet.id);
          const response = await SystemService.update_all("BookingCar", { data: body }, booking_car_id);
          if (response) {
            this.$message.success("บันทึกข้อมูลสำเร็จ");
            this.assign_car_drawer = false;
            this.assign_car_form = {};
            this.init_data();
            await this.getModel(this.render_type);
          }
        } catch (error) {
          this.$message.error("เกิดข้อผิดพลาดในการบันทึกข้อมูล");
          console.error("Error creating BookingCar:", error);
        }
      },
      validateDateInput(event, key) {
            const value = event.target.value;

            if (value === '' || value === null) {
              event.target.value = null;
              this.create_sheet[key] = null;
          } else {
              let numericValue = Number(value);

              if (numericValue < 1) {
                  numericValue = 1;  
              } else if (numericValue > 31) {
                  numericValue = 31;
              }

              event.target.value = numericValue;
              this.create_sheet[key] = numericValue;
          }
      },
      validateHourInput(event, key) {
            const value = event.target.value;

            if (value === '' || value === null) {
              event.target.value = null;
              this.create_sheet[key] = null;
          } else {
              let numericValue = value;

              if (numericValue < 0) {
                  numericValue = 0;  
              } else if (numericValue > 24) {
                  numericValue = 24;
              }

              event.target.value = numericValue;
              this.create_sheet[key] = numericValue;
          }
      },
      init_gas_refill() {
        this.add_gas_refill_drawer = true;
      },
      init_gas_refillIn(){
        this.add_gas_refill_drawer = true;
        this.edit_inside = true;
      },
      init_toll_way() {
        this.show_create_toll_way = false; 
        this.create_toll_way = {};
        this.$nextTick(() => {
          this.show_create_toll_way = true;
        });
      },
      init_toll_wayIn() {
        this.show_create_toll_way = true; 
        this.edit_inside = true;
      },
      handleAddBookingExpenseIn(){
        this.show_create_booking_car_expense = true;
        this.edit_inside = true;
      },
      handleAddBookingExpense(){
        this.show_create_booking_car_expense = true
        this.create_booking_car_expense = {
        fileList: [],
      }
      },
      async edit_booking_car(obj, id) {
        await this.assign_car(obj, { booking_car_id: id });
        this.selected_booking = this.create_sheet.booking_cars.filter(car => car.id == id);
        const { time_end, time_start, flight_pick, flight_drop } = obj;

        this.assign_car_form.plate_no = obj.car?.plate_no || '';
        this.assign_car_form.car_id = obj.car?.id || null;
        this.preselect_car_type_id = this.assign_car_form.type_car_id;
        this.assign_car_form.type_car_id = null;
        let typeIdCar = this.create_sheet.booking_cars.find((car) => car.id == id);
        if (typeIdCar && typeIdCar.car.car_type) {
          this.assign_car_form.type_car_id = typeIdCar.car.car_type?.id ?? null;
        }
        this.preselect_car_type_id = this.assign_car_form.type_car_id;
        if (time_start != "Invalid date" && time_end != "Invalid date") {
          this.assign_car_form.datetime = [dayjs(time_start), dayjs(time_end)];
          this.assign_car_form.flight_pick = dayjs(flight_pick);
          this.assign_car_form.flight_drop = dayjs(flight_drop);
        } else {
          this.assign_car_form.datetime = this.create_sheet.datetime;
          this.assign_car_form.flight_pick = dayjs(this.create_sheet.flight_pick);
          this.assign_car_form.flight_drop = dayjs(this.create_sheet.flight_drop);
        }

        if(this.business == 'car_rental_a'){
          this.assign_car_form.price_date = dayjs(this.create_sheet.price_date);
          this.assign_car_form.price_time = dayjs(this.create_sheet.price_time);
        }
        this.assign_car_form.car_id = this.create_sheet.car_id;
        this.assign_car_form.car_status_id = obj.car?.car_status_id || null;
        //this.assign_car_form.id = id;
      },
      countDays(time_start, time_end) {
        const start = dayjs(time_start);
        const end = dayjs(time_end);
        return end.diff(start, 'day') + 1;
      },
      getCarTypeName(carTypeId) {
        if (this.isProjectDistance || this.isProject) {
          const carType = this.car_type_list ? this.car_type_list.find(item => item.id == carTypeId) : null;
          return carType ? carType.name : '';
        } else {
          const cartypeId = this.car_type_list.find(item => item.id == `${carTypeId}`)?.name
          const carType = this.priceRateListUsed ? this.priceRateListUsed.find(item => this.business != 'car_rental_a' ? item.name == `C_${cartypeId}` : item.name == cartypeId  ) : null;
          return carType ? carType.name : '';
        }
      },
      getCarTypeNames(carTypeId) {
        if (this.isProjectDistance || this.isProject) {
          const carType = this.car_type_list ? this.car_type_list.find(item => item.id == carTypeId) : null;
          return carType ? carType.name : '';
        } else {
          const carType = this.priceRateListUsed ? this.priceRateListUsed.find(item => item.name == `${carTypeId}`) : null;
          return carType ? carType.name : '';
        }
      },
      gatCarCompanyName(carCompanyId){
        const company = this.car_company_list ? this.car_company_list.find(item => item.id == carCompanyId) : null;
        return company ? company.fullname : '';
      },
      getClientpeName(clientId) {
        const clientName = this.client_company_list ? this.client_company_list.find(item => item.id == clientId) : null;
        return clientName ? clientName.fullname : '';
      },
      render_time(date) {
        return dayjs(date).format("DD/MM/YYYY HH:mm");
      },
      async enableProject() {
        if (this.isProject){
          const clientId = this.create_sheet.client_id;
          this.create_sheet = {};
          this.create_sheet.client_id = clientId;
          const tel = this.client_company_list.find((cl)=>cl.id == clientId)?.phone_no;
          this.create_sheet.sheet_tel = tel;
          this.create_sheet.is_project = "โปรเจกต์"
          this.create_sheet.price_car = 0
          const support_accounting = this.client_company_list.find(item => item.id === this.create_sheet.client_id)
          this.destinations = []
          this.isThird_party = true;
          this.car_company_list = await SystemService.get_all("CarCompany")
          this.create_sheet.car_company_id = this.car_company_list[0].id
          this.create_sheet.account_name = support_accounting?.accounting_contact
          this.create_sheet.account_tel = support_accounting?.accounting_phone
          this.create_sheet.account_email = support_accounting?.accounting_email
          this.index_project = null
        }
        if (!this.isProject){
          this.isProjectDistance = false
          this.create_sheet.is_project = "ไม่เลือกโปรเจกต์"
          if (this.create_sheet.project_id) {
            const clientId = this.create_sheet.client_id;
            this.create_sheet = {}
            this.destinations = []
            this.descriptionDestination = []
            this.descriptionOrigin = []
            this.isThird_party = true;
            this.car_company_list = await SystemService.get_all("CarCompany")
            this.create_sheet.company_3rd = "บริษัทรถ"
            this.create_sheet.car_company_id = this.car_company_list[0].id
            this.create_sheet.client_id = clientId;
            const tel = this.client_company_list.find((cl)=>cl.id == clientId)?.phone_no;
            this.create_sheet.sheet_tel = tel;
            const support_accounting = this.client_company_list.find(item => item.id === this.create_sheet.client_id)
            this.create_sheet.account_name = support_accounting?.accounting_contact
            this.create_sheet.account_tel = support_accounting?.accounting_phone
            this.create_sheet.account_email = support_accounting?.accounting_email
            this.index_project = null
          }
        }
        this.isProject = !this.isProject
      },
      statusText(record) {
        // if (record.status == 0) {
        //   return { txt: "ใหม่", color: "blue" };
        // }
        if (record.sheet_status == 1 || record.sheet_status == 0) {
          return { txt: "ใบงานว่าง", color: "yellow" };
        }
        if (record.sheet_status == 2) {
          return { txt: "รอรายละเอียด", color: "orange" };
        } else {
          return { txt: "มอบหมายงานแล้ว", color: "green" };
        }
    },
      transitionText(record) {
        if (record.booking_type_id == 1) {
          return { txt: "ขาเดียว", color: "pink" };
        }
        if (record.booking_type_id == 2) {
          return { txt: "ไปกลับ", color: "red" };
        }
        if (record.booking_type_id == 3) {
          return { txt: "การขนส่งแบบวิ่งเก็บ (Milkrun)", color: "orange" };
        }
    },
      enableQulity() {
        this.isQulity = !this.isQulity
        this.submit_model.qulity = 1
      },
      async carTypeByCarCompany(){
        if(this.isProject){
          this.create_sheet.car_type_id = null
          const car_type_id_list = this.car_list
          .filter(item => item.car_company_id === this.create_sheet.car_company_id)
          .map(item => item.car_type_id);
        this.car_type_list = await SystemService.get_all("CarType")
        this.car_type_list = this.car_type_list.filter(item => car_type_id_list.includes(item.id))
        }
      },
      async togglethridParty(type) {
        if (type === 'disable') {
            this.isThird_party = !this.isThird_party;
            if (this.create_sheet.company_3rd == "บริษัทรถร่วม") {
                this.car_company_list = await SystemService.get_all("CarCompany")
                this.create_sheet.company_3rd = "บริษัทรถ"
                this.create_sheet.car_company_id = this.car_company_list[0].id
            } else {
                this.car_company_list = await CarCompanyService.get_car_companies_3rd();
                this.create_sheet.company_3rd = "บริษัทรถร่วม"
                if(this.create_sheet.distance_price_rate == "distance"){
                  const resultCarList = this.all_car_list
                  const uniqueCarCompany = resultCarList.reduce((acc, car) => {
                    if (!acc.some(item => item.car_company_id === car.car_company_id)) {
                      acc.push(car);
                    }
                    return acc;
                  }, []);
                  this.car_company_list = this.car_company_list.filter(carList => {
                    return uniqueCarCompany.find(unCar => unCar.car_company_id == carList.id) !=null
                  });
                }else if(this.create_sheet.distance_price_rate == "price"){                  
                  const  resultCarList = this.all_car_list
                  const uniqueCarCompany = resultCarList.reduce((acc, car) => {
                    if (!acc.some(item => item.car_company_id === car.car_company_id)) {
                      acc.push(car);
                    }
                    return acc;
                  }, []);
                  this.car_company_list = this.car_company_list.filter(carList => {
                    return uniqueCarCompany.find(unCar => unCar.car_company_id == carList.id) !=null
                  });
                }
                this.create_sheet.car_company_id = this.car_company_list[0]?.id ?? null;
            }
            if (this.isProject) {
              this.create_sheet.car_type_id = null;
              this.create_sheet.driver_id = null;
              this.create_sheet.car_id = null;
              const car_type_id_list = this.car_list
              .filter(item => item.car_company_id === this.create_sheet.car_company_id)
              .map(item => item.car_type_id);
            this.car_type_list = await SystemService.get_all("CarType")
            this.car_type_list = this.car_type_list.filter(item => car_type_id_list.includes(item.id))
            }
        }
      },

      async initcompany3rd() {
        if(this.create_sheet.company_3rd == "บริษัทรถร่วม"){
            this.car_company = await CarCompanyService.get_car_companies_3rd()
          }
      },
      toggleOption(option) {
      // หากตัวเลือกเดิมถูกเลือกอยู่ ให้ unselect (เป็น null)
      if (this.distance_price_rate === option) {
        this.distance_price_rate = null;
      } else {
        this.create_sheet.distance_price_rate = option
        this.distance_price_rate = option; // เลือกตัวเลือกใหม่
      }
    },
    auto_fill_days() {
      if (this.create_sheet.work_hour && this.create_sheet.time_product) {
        this.create_sheet.time_product_finish = dayjs(this.create_sheet.time_product);
        let workHour = this.create_sheet.work_hour;

        let hours, minutes;

        if (typeof workHour === "string" && workHour.includes(":")) {
          [hours, minutes] = workHour.split(":");
            let minutesStr = minutes.toString(); 
            if (minutesStr.length === 1) {
                minutes = minutesStr + "0"; 
            } else {
                minutes = Number(minutesStr);
            }
              if (minutes < 10 && minutesStr.length === 1) {
                minutes *= 10; 
              }
        } else if (typeof workHour === "string" && workHour.includes(".")) {
          [hours, minutes] = workHour.split(".");
          let minutesStr = minutes.toString(); 
          if (minutesStr.length === 1) {
              minutes = minutesStr + "0"; 
          } else {
              minutes = Number(minutesStr);
          }
          if (minutes < 10 && minutesStr.length === 1) {
            minutes *= 10; 
          }
        } else {
            hours = Math.floor(workHour);
            minutes = Math.round((workHour % 1) * 60);
        }
        this.create_sheet.time_product_finish = this.create_sheet.time_product_finish
            .add(hours, 'hour')
            .add(minutes, 'minute');
    }
    },
    async select_stop_name(e, target,) {
      const { latitude, longitude } = e;
      if (target) {
        this.create_sheet[target] = document.getElementById(target).value.split(",")[0];
      } 
    },
    initMap(target) {
      if (window.google && window.google.maps) {
        const input = this.$refs[target].$el;
        const options = {
          types: ['establishment'],
          componentRestrictions: { country: 'th' },
        };

        // สร้าง Google Autocomplete instance
        const googleMap = new google.maps.places.Autocomplete(input, options);

        // Listener สำหรับการเลือกสถานที่
        googleMap.addListener('place_changed', () => {
          const place = googleMap.getPlace(); 
          if (place && place.name) {
            this.create_sheet[target] = place.name;
          }
          if (target === "destination") {
            this.destinationText(place.name)
            this.destinationDescription(place)
          }
          else if(target === "origin"){
            this.originDescription(place)
          }
        });
      }
    },
    destinationDescription(place){
      const lat = place?.geometry?.location.lat();
      const lng = place?.geometry?.location.lng();
      this.descriptionDestination.push(JSON.stringify({ lat, lng }));
    },
    originDescription(place){
      const lat = place?.geometry?.location.lat();
      const lng = place?.geometry?.location.lng();
      this.descriptionOrigin = [JSON.stringify({ lat, lng })];
    },
    async destinationText(name,type) {
      this.destinations.push(`${name}`);
      this.destinationListSupport = this.destinationProjectList.filter(item => !this.destinations.includes(item.end_place))
      await this.priceCar(type);
    },
    moveUp(index) {
      if(this.statusDisable) {
        return ;
      }
      if (index > 0) {
        const item = this.destinations.splice(index, 1)[0];
        this.destinations.splice(index - 1, 0, item);
        const item2 = this.descriptionDestination.splice(index,1)[0];
        this.descriptionDestination.splice(index - 1,0,item2);
      }
    },
    // ฟังก์ชันสำหรับเลื่อนรายการลง
    moveDown(index) {
      if(this.statusDisable) {
        return ;
      }
      if (index < this.destinations.length - 1) {
        const item = this.destinations.splice(index, 1)[0];
        this.destinations.splice(index + 1, 0, item);
        const item2 = this.descriptionDestination.splice(index,1)[0];
        this.descriptionDestination.splice(index + 1,0,item2);

      }
    },
    remove_destination(id) {
      if(this.statusDisable) {
        return ;
      }
      const indexDelete = this.destinations.findIndex((item) => item === id);
      this.delete_dest.push(this.destinations.find((item) => item == id));
      this.destinations = this.destinations.filter((item) => item !== id);
      this.descriptionDestination = this.descriptionDestination.filter((item,index) => index !== indexDelete);
      if (id == this.create_sheet.destination) {
        this.create_sheet.destination = null
      }
      this.destinationListSupport = this.destinationProjectList.filter(item => !this.destinations.includes(item.end_place))
    },
    filterOption (input, option) {
          return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      },
      async get_car_data(){
        this.all_car_list = await SystemService.get_all('Car')
        this.all_driver_list = await DriverService.getDriverByCompany();
      },
      async get_company() {
          const company = await UserService.get_user_profile();
          this.user_name = company.username
          const businessType = await CompanyService.getBusinessId(company.company_id);
          if (businessType.data === 1||( businessType.data === null)) {
              this.business = "thanawit";
          }      
          if ( businessType.data === 2) {
              this.business = "sjinda";
          }
          if ( businessType.data === 3) {
              this.business = "sjinda_a";
          }
          if( businessType.data === 4){
            this.business = "car_rental_a"
          }

      },
      async delete_sheet(id) {
          Swal.fire({
              title: `คุณต้องการลบข้อมูลนี้ใช่หรือไม่?`,
              text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้หากลบแล้ว!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
              cancelButtonText: 'ยกเลิก'
          }).then(async (result) => {
              if (result.isConfirmed) {
                  await SheetService.deleteSheet(id)
                  this.init_data()
                  Swal.fire(
                      'ลบข้อมูลเรียบร้อย!',
                      'ข้อมูลของคุณถูกลบแล้ว',
                      'success'
                  )
              }
          })
      },

      async duplicate_sheet(id) {
        message.loading({ content: "กำลังโหลด...", key: "loading" });
        await SheetService.duplicate_sheet(id).then((res) => {
          if (res) {
            this.init_data();
            message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
          }
        })
      },
      async end_workSheets(id) {
          message.loading({ content: "กำลังส่ง", key: "loading" });

          try {
              const res = await SheetService.updateStatus(id); // Wait for the result here

              if (res) {
                  await this.init_data(); // Ensure this is awaited as well
                  message.success({ content: "ส่งสำเร็จ", key: "loading" });
              }
          } catch (error) {
              message.error({ content: "มีข้อผิดพลาดเกิดขึ้น", key: "loading" });
          }
      },

      go_edit(id) {
          this.$router.push(`/app/sheet/${id}`)
      },
      render_datetime(date, time) {
        const datetime = dayjs(date).format("YYYY-MM-DD") + " " + dayjs(time).format("HH:mm");
        return dayjs(datetime).format("DD/MM/YYYY HH:mm");
      },
      render_date(date,time) {
        if (!date || !time || typeof date !== "string" || typeof time !== "string") {
            return "-";
        }

        try {
            const datetime = `${date.slice(0, 10)} ${time.slice(11, 16)}`;
            return dayjs(datetime).format("DD/MM/YYYY HH:mm");
        } catch (error) {
            return "-";
        }
      },
      clear_search() {
      this.search_input = null;
      this.search_sheet_status = null;
      this.search_datetime = null;
      this.search_booking_type_id = null;
      this.init_data();
    },
    render_period(record){
      return this.time_period_list.find((item)=>item.id == record)?.name
    },
    render_time_type(record){
      return this.time_type_list.find((item)=>item.id == record)?.name
    },
    render_des(record){
      if (record.data_text_destination && record.data_text_destination !== "null") {
        const response = JSON.parse(record.data_text_destination);
        return response.join(',')
      }
    },
    filtered_list(array) {
        return array.filter(({ booking_no, client, booking_type_id,sheet_status,time_start,time_end }) => {
            const searchInputLower = this.search_input ? this.search_input.toLowerCase() : "";
            const searchInput = !this.search_input || 
                (booking_no && booking_no.toLowerCase().includes(searchInputLower)) ||
                (client.fullname && client.fullname.toLowerCase().includes(searchInputLower))
            const searchTransport = !this.search_booking_type_id ||
              (booking_type_id && booking_type_id === this.search_booking_type_id);
            const searchDate = !this.search_datetime ||
              (time_start && dayjs(time_start).format("YYYY/MM/DD") >= (dayjs(this.search_datetime[0]).format("YYYY/MM/DD"))) &&
              (time_end && dayjs(time_end).format("YYYY/MM/DD") <= (dayjs(this.search_datetime[1]).format("YYYY/MM/DD")));
            const searchStatus = !this.search_sheet_status ||
              (sheet_status && sheet_status === this.search_sheet_status);
            return searchInput && searchTransport && searchDate && searchStatus
        }).sort((a,b) => b.id - a.id)
    },
    async getTotalDate() {
      if (this.create_sheet.datetime.length === 2) {
        const booking_id = this.sheetCar[0]?.id;
        const start = this.create_sheet.datetime[0];
        const end = this.create_sheet.datetime[1];
        const totalDays = end.diff(start, 'days');
        this.create_sheet.qulity_date = totalDays;
        this.create_sheet.time_start = start;
        this.create_sheet.time_end = end;
        //await SystemService.update_all("Booking", { data: {time_start: this.create_sheet.datetime[0], time_end: this.create_sheet.datetime[1]} }, booking_id)
        //await this.getModel(this.render_type);
      }
    },
    async validateAndClose() {
        this.showModalRequireInput = false;
    },
    async create_multiple_billings() {
      if([this.create_sheet.client_id].filter((e) => e == null).length){
        this.activeKey.push("1");
        this.$nextTick(() => {
          this.$refs.clientItem.$el.scrollIntoView({ behavior: "smooth", block: "start" });
        });
        this.showModalRequireInput = true
      }else if( this.business != 'car_rental_a' && [this.create_sheet.oil_range,this.create_sheet.distance_price_rate,this.create_sheet.origin,this.create_sheet.datetime,this.create_sheet.time_product,this.create_sheet.work_hour,this.create_sheet.time_product_finish,this.create_sheet.car_type_id,this.create_sheet.operation_id].filter( (e) => e == null).length){
        this.activeKey.push("2");
        this.$nextTick(() => {
          this.$refs.descriptionPrice.$el.scrollIntoView({ behavior: "smooth", block: "start" });
        });
        this.showModalRequireInput = true
      }else if( this.business == 'car_rental_a' && [this.create_sheet.oil_range,this.create_sheet.distance_price_rate,this.create_sheet.origin,this.create_sheet.time_type_id,this.create_sheet.time_period_id,this.create_sheet.price_date,this.create_sheet.price_time,this.create_sheet.car_type_id,this.create_sheet.driver_id,this.create_sheet.car_id ,this.create_sheet.accounting_id].filter( (e) => e == null).length){
        this.activeKey.push("2");
        this.$nextTick(() => {
          this.$refs.descriptionPrice.$el.scrollIntoView({ behavior: "smooth", block: "start" });
        });
        this.showModalRequireInput = true
      }else if(this.business == 'car_rental_a' && this.from_worksheet && [this.create_sheet.operation_id].filter( (e) => e == null).length){
        this.activeKey.push("2");
        this.$nextTick(() => {
          this.$refs.descriptionPrice.$el.scrollIntoView({ behavior: "smooth", block: "start" });
        });
        this.showModalRequireInput = true
      }
      if (!this.showModalRequireInput) {
        this.model_qulity = true
      }
    },
      async submit_create_sheets() {
        const sheets = JSON.parse(JSON.stringify(this.create_sheet));
        sheets.data_text_destination = JSON.stringify(this.destinations);
        sheets.data_dest_description = JSON.stringify(this.descriptionDestination);
        sheets.data_ori_description = JSON.stringify(this.descriptionOrigin);
        if(this.business == 'car_rental_a'){
          const timeUse = dayjs(this.create_sheet.price_date)
                          .set('hour', dayjs(this.create_sheet.price_time).hour())
                          .set('minute', dayjs(this.create_sheet.price_time).minute())
                          .format('YYYY-MM-DD HH:mm');
          sheets.time_start = timeUse;
          sheets.time_end = timeUse;
          sheets.time_product = timeUse;
          sheets.time_product_finish = timeUse;
        }else{
          sheets.time_start = this.create_sheet.datetime ? this.create_sheet.datetime[0] : null;
          sheets.time_end = this.create_sheet.datetime? this.create_sheet.datetime[1] : null;
        }
        delete sheets.destination
        delete sheets.datetime
        // console.log('888',this.render_type)
        if (!this.isProject) {
          sheets.is_project = "โปรเจกต์"
        } else {
          sheets.is_project = "ไม่เลือก"
        }
        if (sheets.distance_price_rate === "price" && sheets.is_project === "โปรเจกต์" && this.business != 'car_rental_a') {
          sheets.car_type_id = this.car_type_list.find(item => `C_${item.name}` === this.create_sheet.car_type_id)?.id
        }
        if (!this.isThird_party) {
          sheets.company_3rd = "บริษัทรถร่วม"
        } else {
          sheets.company_3rd = "บริษัทรถ"
        }
        if (this.create_sheet.photo_1 == null) {
          sheets.photo_1 = null;
        } else {
          sheets.photo_1 = this.create_sheet.photo_1;
        }
        if(((this.create_sheet.distance_price_rate == "distance" && !this.isProject) || this.isProject) && this.business == 'car_rental_a'){
          sheets.price_date =  dayjs(this.create_sheet.price_date).format('YYYY-MM-DD HH:mm');
          sheets.price_time =  dayjs(this.create_sheet.price_time).format('YYYY-MM-DD HH:mm');
        }
        if (this.render_type == 'add') {
          sheets.booking_no = await SystemService.get_qtc()
          await SheetService.create_multi_sheets(sheets,this.submit_model.qulity).then((res) => {
          if(res) {
            Swal.fire({
              icon: "success",
              title: "บันทึกข้อมูลสำเร็จ",
              showConfirmButton: false,
              timer: 1500,
            });
          this.model_qulity = false
          this.$router.push(`/app/sheet/all`)
            // this.init_data()
          }
        })
        } else if (this.render_type != 'add') {
          if(sheets.modelname) {
            delete sheets.modelname
          }
          const hideLoading = message.loading({
            content: "กำลังบันทึกข้อมูล...",
            key: "detail",
            duration: 0, 
          });
          for (const car of this.create_sheet.booking_cars) {
            if (car.gas_refills) {
              for (const gas of car.gas_refills) {
                const data = {
                  photo_1: gas.photo_1_show && gas.photo_1_show.length != 0 ? gas.photo_1 : null,
                  photo_2: gas.photo_2_show && gas.photo_2_show.length != 0 ? gas.photo_2 : null,
                  photo_3: gas.photo_3_show && gas.photo_3_show.length != 0 ? gas.photo_3 : null,
                  photo_4: gas.photo_4_show && gas.photo_4_show.length != 0 ? gas.photo_4 : null,
                  photo_5: gas.photo_5_show && gas.photo_5_show.length != 0 ? gas.photo_5 : null,
                  photo_6: gas.photo_6_show && gas.photo_6_show.length != 0 ? gas.photo_6 : null,
                  photo_7: gas.photo_7_show && gas.photo_7_show.length != 0 ? gas.photo_7 : null,
                  photo_8: gas.photo_8_show && gas.photo_8_show.length != 0 ? gas.photo_8 : null,
                  photo_9: gas.photo_9_show && gas.photo_9_show.length != 0 ? gas.photo_9 : null,
                  photo_10: gas.photo_10_show && gas.photo_10_show.length != 0 ? gas.photo_10 : null,
                };
                await SystemService.update_all("GasRefill", { data: data }, gas.id);
              }
            }
          }
          for (const car of this.create_sheet.booking_cars) {
            if (car.service) {
              const data =  { 
                photo_1: this.model_form?.photo_1_show?.length != 0 ? car.service.photo_1 : null,
                photo_2: this.model_form?.photo_2_show?.length != 0 ? car.service.photo_2 : null,
                photo_3: this.model_form?.photo_3_show?.length != 0 ? car.service.photo_3 : null,
                photo_4: this.model_form?.photo_4_show?.length != 0 ? car.service.photo_4 : null,
                photo_5: this.model_form?.photo_5_show?.length != 0 ? car.service.photo_5 : null,
                photo_6: this.model_form?.photo_6_show?.length != 0 ? car.service.photo_6 : null,
                photo_7: this.model_form?.photo_7_show?.length != 0 ? car.service.photo_7 : null,
                photo_8: this.model_form?.photo_8_show?.length != 0 ? car.service.photo_8 : null,
                photo_9: this.model_form?.photo_9_show?.length != 0 ? car.service.photo_9 : null,
                photo_10: this.model_form?.photo_10_show?.length != 0 ? car.service.photo_10 : null,
                photo_11: this.model_form?.photo_11_show?.length != 0 ? car.service.photo_11 : null,
                photo_12: this.model_form?.photo_12_show?.length != 0 ? car.service.photo_12 : null,
              }
              await SystemService.update_all("Service", { data: data }, car.service.id);
            };
          }
          if(this.delete_dest.length > 0){
            sheets.delete_dest = this.delete_dest;
          }
          await SheetService.update_Sheet(this.render_type,sheets).then((res) => {
          if(res) {
            hideLoading()
            Swal.fire({
              icon: "success",
              title: "บันทึกข้อมูลสำเร็จ",
              showConfirmButton: false,
              timer: 1500,
            });
            // this.init_data()
            if (this.from_worksheet) {
              if (this.$route.query.manage_worksheet == "true") {
                this.$router.push(`/app/manageWorksheet/all`);
                return; 
              } else if (this.$route.query.checkBill == "true") {
                this.$router.push(`/app/checkBill/all`);
                return; 
              }
            }
            this.$router.push(`/app/sheet/all`);
          }
        })
        }
      },
      async init_model() {
        this.create_sheet = {
        };
        this.selectedSubDriver = false;
        this.booking_files = [];
        this.destinations = [];
        this.descriptionDestination = [];
        this.descriptionOrigin = [];
        this.isProject = true;
        this.isQulity = true;
        this.isThird_party = true
        this.submit_model = {
              qulity:1,
            }

        const [client_company_list] = await Promise.all([SystemService.get_all("Client")]);
        this.project_type_list = await ProjectService.getProjectTypeAll();
        this.project_list = await ProjectService.getProjectAll();
        this.time_period_list = await BookingService.getTimePeriodAll();
        this.time_type_list = await BookingService.getTimeTypeAll();
        this.car_company_list = await SystemService.get_all("CarCompany");
        this.car_type_list = await SystemService.get_all("CarType")
        this.car_list = await SystemService.get_all("Car")
        this.operation_list = await OperationService.getOperationByCompany();
        const accountData = await AccountingService.getAccountingByCompany();
        const FilterAccount = accountData.filter((item)=> item.department == 'SalesAndService')
        this.accounting_list = FilterAccount.map(admin => {
            if (admin.firstname && admin.lastname) {
                return {
                ...admin,
                fullname: `${admin.firstname} ${admin.lastname}`
                };
            }
            return admin;
        });
        this.create_sheet.car_company_id = this.car_company_list[0].id
        this.client_company_list = client_company_list
        this.statusDisable = false
        if(this.render_type != 'all' && this.render_type != 'add') {
          this.pageEdit = true
          if(this.business == 'car_rental_a'){
            this.car_available_list = await SystemService.get_core("cars_with_repair_booking");
            const finalResultCar = this.car_available_list
                .reduce((uniqueCars, car) => {
                    if (!uniqueCars.has(car.id)) {
                        uniqueCars.set(car.id, car); // Store only one instance per id
                    }
                    return uniqueCars;
                }, new Map()) // Use Map to store unique cars by id
                .values(); // Extract unique cars
            this.car_available_list = [...finalResultCar];
            this.oil_range_list = [
                    {id:1, range:1}, 
                    {id:2, range:2},
                    {id:3, range:3},
                    {id:4, range:4},
                    {id:5, range:5},
                    {id:6, range:6},
                    {id:7, range:7},
                    {id:8, range:8},
                    {id:9, range:9},
                    {id:10, range:10},
                    {id:11, range:11},
                    {id:12, range:12},
                    {id:13, range:13},
                    {id:14, range:14},
                    {id:15, range:15},
                ];
            this.main_and_sub_driver_list = await DriverService.getDriverByCompany();
            this.main_and_sub_driver_list = this.main_and_sub_driver_list.map(({ id, firstname, lastname, car_company_id ,car_id }) => ({
                          value: id,
                          label: `${firstname} ${lastname}`,
                          description: this.car_available_list.find((car)=> car.id == car_id)?.car_no ? `เบอร์รถ: ${this.car_available_list.find((car)=> car.id == car_id)?.car_no}` : 'ไม่มีเบอร์รถ',
                          carCompany: car_company_id
                        }));
          }
          await this.getModel(this.render_type)
        }if (this.business == 'car_rental_a') {
          this.car_available_list = await SystemService.get_core("cars_with_repair_booking");
          // const finalResultCar = this.car_available_list.reduce((uniqueCars, car) => {
          //     if (!uniqueCars.has(car.id)) {
          //         uniqueCars.set(car.id, car);
          //     }
          //     return uniqueCars;
          // }, new Map()).values();
          // this.car_available_list = [...finalResultCar];

          this.oil_range_list = Array.from({ length: 15 }, (_, i) => ({
              id: i + 1,
              range: i + 1,
          }));

          this.main_and_sub_driver_list = await SystemService.get_core("cars_with_backup_status");
          const currentTime = new Date();
          const statusFilterList = [8, 9, 10, 16, 17, 18, 19];
          const filteredCars = this.main_and_sub_driver_list.filter(car => {
              const statusMatch = statusFilterList.includes(car.status);

              var timeEnd = null
              if(car.time_end && car.time_product_finish){
                const renderTime = this.render_datetime(car.time_end,car.time_product_finish);
                timeEnd = this.parseDate(renderTime);
              }
              const isTimeEndValid = timeEnd && timeEnd > currentTime;

              return statusMatch && isTimeEndValid;
          });
          const filteredIds = new Set(filteredCars.map(car => car.id));
          const finalResult = this.main_and_sub_driver_list
              .filter(car => !filteredIds.has(car.id))
              .reduce((uniqueCars, car) => {
                  if (!uniqueCars.has(car.id)) {
                      uniqueCars.set(car.id, car); // Store only one instance per id
                  }
                  return uniqueCars;
              }, new Map()) // Use Map to store unique cars by id
              .values(); // Extract unique cars
          this.main_and_sub_driver_list = [...finalResult].map(({ id, firstname, lastname, car_no, car_company_id }) => ({
            value: id,
            label: `${firstname} ${lastname}`,
            description: car_no !== null ? `เบอร์รถ: ${car_no}` : 'ไม่มีเบอร์รถ',
            carCompany: car_company_id
          }));

          await this.getModel(this.render_type);
      }
        if(this.business != 'car_rental_a'){
          this.oil_range_list = await ProjectService.getOillRangeAll();
        }
        this.pageEdit = false
        if (this.isProject) {
          const car_type_id_list = this.car_list
          .filter(item => item.car_company_id === this.create_sheet.car_company_id)
          .map(item => item.car_type_id);
        this.car_type_list = this.car_type_list.filter(item => car_type_id_list.includes(item.id))
        }
      },
      async init_accounting() {
        if (!this.mockProject) {
          this.mockProject = [...this.project_list]
        }
        const clinet_id = this.create_sheet.client_id
        this.create_sheet = {}
        this.create_sheet.client_id = clinet_id
        const tel = this.client_company_list.find((cl)=>cl.id == clinet_id)?.phone_no;
        this.create_sheet.sheet_tel = tel
        this.destinations = []
        this.index_project = null
        this.isThird_party = true;
        this.isProject = true
        this.car_company_list = await SystemService.get_all("CarCompany")
        this.create_sheet.company_3rd = "บริษัทรถ"
        this.create_sheet.car_company_id = this.car_company_list[0].id
        this.project_list = this.mockProject.filter(item => item.client_id === this.create_sheet.client_id)
        const support_accounting = this.client_company_list.find(item => item.id === this.create_sheet.client_id)
        this.create_sheet.account_name = support_accounting?.accounting_contact
        this.create_sheet.account_tel = support_accounting?.accounting_phone
        this.create_sheet.account_email = support_accounting?.accounting_email
      },
      async getDestinatiion() {
        this.destinations = this.create_sheet.destination

      },
      async useProject(type) {
        const dataProject = this.project_list.find(project => project.id === this.create_sheet.project_id)
        const dataProjectbyId = await ProjectService.getProjectById(dataProject.id)
        if (dataProject.price_type_id === 1) {
          this.isProjectDistance = true
          if(this.business == "car_rental_a"){
            this.priceProjectList = dataProjectbyId.distanceRate.map((item) => ({
                ...item,
                price_date_text: item.price_date 
                    ? dayjs(item.price_date).format('DD/MM/YYYY') 
                    : null,
                price_date_value: item.price_date 
                    ? dayjs(item.price_date).format('YYYY-MM-DD HH:mm') 
                    : null,
                price_time_text : item.price_time 
                    ? dayjs(item.price_time).format('HH:mm') 
                    : null,
                price_time_value : item.price_time 
                    ? dayjs(item.price_time).format('YYYY-MM-DD HH:mm') 
                    : null,
                car_id : this.car_available_list.find((itemC)=>itemC.plate_no == item.plate_no) 
                    ? this.car_available_list.find((itemC)=>itemC.plate_no == item.plate_no).id
                    : item.id,
                car_type_text : this.car_available_list.find((itemC)=>itemC.plate_no == item.plate_no) 
                    ? this.car_type_list.find((itemTC) => itemTC.id == this.car_available_list.find((itemC)=>itemC.plate_no == item.plate_no)?.car_type_id)?.name
                    : null,
            }));
          }else{
            this.priceProjectList = dataProjectbyId.distanceRate
          }
          this.destinationProjectList = []
          this.destinationListSupport = []
          this.originProjectList = dataProjectbyId.distanceRate.filter((item, index, self) => 
          self.findIndex((t) => t.start_place === item.start_place) === index);
          const carTypeIds = this.priceProjectList.map(item => item.car_type_id);
          this.car_type_list = await SystemService.get_all("CarType")
          this.car_type_list = this.car_type_list.filter(car => carTypeIds.includes(car.id));
        } else {
          this.car_type_list = await SystemService.get_all("CarType")
          if (this.create_sheet.car_type_id && dataProject.price_type_id === 2 && this.business != 'car_rental_a') {
            const car_type = this.car_type_list.find(item => item.id === this.create_sheet.car_type_id)?.name
            this.create_sheet.car_type_id = this.priceRateListUsed.find(item => item.name === `C_${car_type}`)?.name
          }
          this.isProjectDistance = false
          if(this.business == "car_rental_a"){
            this.priceProjectList = dataProjectbyId.priceRate.map((item) => ({
                ...item,
                price_date_text: item.price_date 
                    ? dayjs(item.price_date).format('DD/MM/YYYY') 
                    : null,
                price_date_value: item.price_date 
                    ? dayjs(item.price_date).format('YYYY-MM-DD HH:mm') 
                    : null,
                price_time_text : item.price_time 
                    ? dayjs(item.price_time).format('HH:mm') 
                    : null,
                price_time_value : item.price_time 
                    ? dayjs(item.price_time).format('YYYY-MM-DD HH:mm') 
                    : null,
                car_id : this.car_available_list.find((itemC)=>itemC.plate_no == item.plate_no) 
                    ? this.car_available_list.find((itemC)=>itemC.plate_no == item.plate_no).id
                    : item.id,
                car_type_text : this.car_available_list.find((itemC)=>itemC.plate_no == item.plate_no) 
                    ? this.car_type_list.find((itemTC) => itemTC.id == this.car_available_list.find((itemC)=>itemC.plate_no == item.plate_no)?.car_type_id)?.name
                    : null,
                car_type_id : this.car_available_list.find((itemC)=>itemC.plate_no == item.plate_no) 
                    ? this.car_available_list.find((itemC)=>itemC.plate_no == item.plate_no)?.car_type_id
                    : null,
            }));
          }else{
            this.priceProjectList = dataProjectbyId.priceRate
          }
          this.originProjectList = dataProjectbyId.priceRate.filter((item, index, self) => 
          self.findIndex((t) => t.start_place === item.start_place) === index);
          this.destinationProjectList = dataProjectbyId.priceRate.filter((item, index, self) => 
          self.findIndex((t) => t.end_place === item.end_place) === index);
        }
        this.create_sheet.project_type_id = dataProject.project_type_id
        this.create_sheet.product_type = dataProject.product_type
        this.create_sheet.booking_type_id = dataProject.booking_type_id
        this.create_sheet.oil_range = dataProject.oil_range_id
        if (type === 'change') {
          this.destinations = [];
          this.descriptionDestination = [];
          this.descriptionOrigin = [];
          this.create_sheet.origin = null;
          this.create_sheet.destination = null;
          this.create_sheet.datetime = null;
          this.create_sheet.time_start = null;
          this.create_sheet.time_end = null;
          this.create_sheet.time_product = null;
          this.create_sheet.assistants = null;
          this.create_sheet.company_3rd = "บริษัทรถร่วม";
          this.isThird_party = false;
          this.togglethridParty('disable');
          this.create_sheet.car_type_id = null;
          this.create_sheet.work_hour = null;
          this.create_sheet.distance = null;
          this.create_sheet.price_car = null;
          this.create_sheet.time_product_finish = null;
          this.create_sheet.operation_id = null;
          this.create_sheet.time_type_id = null;
          this.create_sheet.time_period_id = null;
          this.create_sheet.driver_id = null;
          this.create_sheet.car_id = null;
          this.create_sheet.price_date = null;
          this.create_sheet.price_time = null;
          const mergedData = dataProjectbyId.priceRate.reduce((result, current) => {
            for (const key in current) {
              if (result[key] === undefined || result[key] === null) {
                result[key] = current[key];
              }
            }
            return result;
          }, {});
          if(dataProjectbyId.priceRate.length > 0){
            if(this.business != 'car_rental_a'){
              this.priceRateListUsed = this.priceRatesList
              this.priceRateListUsed = this.priceRateListUsed.filter(item => {
                return mergedData[item.name] != undefined && mergedData[item.name] != null
              })
            }else{
              this.priceRateListUsed = this.car_type_list;
            }
          }
          if(dataProjectbyId.priceRate[0] && dataProject.price_type_id == 2) {
            this.create_sheet.work_hour = dataProjectbyId.priceRate[0].total_hours
            if (this.create_sheet.work_hour && this.create_sheet.time_product) {
              this.auto_fill_days()
            }
          }
        }
        this.mockDestination = null

        if (dataProject.price_type_id === 1) {
          this.create_sheet.distance_price_rate = "distance"
          if(this.business == 'car_rental_a'){
            this.priceCar('origin')
          }
        } else {
          this.create_sheet.distance_price_rate = "price"
          this.priceCar('origin')
        }
        if(this.business == 'car_rental_a'){
          this.create_sheet['oilRangeRate'] = dataProjectbyId.oilRangeRate.filter((oilRate)=>oilRate.selected_index == 1);
          this.index_project = dataProjectbyId.oilRangeRate.findIndex((oilRate)=>oilRate.selected_index == 1);
        }
        this.create_sheet.collect_customer = dataProjectbyId[0].collect_date
        this.create_sheet.confirm_day = dataProjectbyId[0].confirm_date
        this.create_sheet.document_time = dataProjectbyId[0].document_date
        this.create_sheet.credits = dataProjectbyId[0].credit_amount
        this.create_sheet.payment_method_id = dataProjectbyId[0].payment_method_id
        this.create_sheet.project_name = dataProjectbyId[0].contact_name
        this.create_sheet.project_phone = dataProjectbyId[0].contact_tel
        this.create_sheet.project_email = dataProjectbyId[0].contact_email
        this.create_sheet.account_name = dataProjectbyId[0].account_name
        this.create_sheet.account_tel = dataProjectbyId[0].account_tel
        this.create_sheet.account_email = dataProjectbyId[0].account_email
      },

      async driverForCarTwo(){
        const carChoose = this.priceProjectList.find((item)=> {
          var check = true;
          if(this.create_sheet.origin){
            check = item.start_place == this.create_sheet.origin && check;
          }
          if(this.create_sheet.time_type_id){
            const nameType  = this.priceProjectList.find((itemP)=>itemP.id == this.create_sheet.time_type_id)?.time_type;
            check = item.time_type == nameType && check;
          }
          if(this.create_sheet.time_period_id){
            const namePeriod  = this.priceProjectList.find((itemT)=>itemT.id == this.create_sheet.time_period_id)?.time_period;
            check = item.time_period == namePeriod && check;
          }
          if(this.create_sheet.price_date){
            check = item.price_date_value == this.create_sheet.price_date && check;
          }
          if(this.create_sheet.price_time){
            check = item.price_time_value == this.create_sheet.price_time && check;
          }
          if(this.create_sheet.car_type_id){
            check = this.create_sheet.car_type_id == item.car_type_id && check;
          }
          return check 
        })
        this.main_driver_id = this.main_and_sub_driver_list.find((driver)=> driver.label.replace(/\s+/g, '') == carChoose?.driver_name.replace(/\s+/g, ''))?.value
        if(this.selectedSubDriver == false){
          this.create_sheet.driver_id = this.main_driver_id;
        }else{
          this.create_sheet.driver_id = null;
        }
      },

      async driverForCar() {
        const carChoose = this.getFilteredCarsBy3rd().find((car)=> car.id == this.create_sheet.car_id)
        this.main_driver_id = this.main_and_sub_driver_list.find((driver)=> driver.value == carChoose?.main_driver_id)?.value
        if(this.isProject || this.create_sheet.distance_price_rate != "price"){
          if(this.selectedSubDriver == false){
            this.create_sheet.driver_id = this.main_driver_id;
          }else{
            this.create_sheet.driver_id = null;
          }
        }
      },

      async priceCar(type) {
        if (type == 'amount') {
          this.create_sheet.price_car = 0 
        }
        if (type === 'origin' && this.create_sheet.distance_price_rate == "price") {
          if (!this.mockDestination) {
            this.mockDestination = [...this.destinationProjectList]
          }
          this.destinationProjectList = this.mockDestination.filter(item => item.start_place === this.create_sheet.origin)
          this.destinationListSupport = [...this.destinationProjectList]
          if (this.pageEdit){
            this.destinationListSupport = this.destinationListSupport.filter(item => !this.destinations.includes(item.end_place))
          }else{
              this.destinations = []
              this.descriptionDestination = []
              this.descriptionOrigin = []
              this.create_sheet.destination = null
          }
        }
        if(!this.isProject && this.create_sheet.origin && type === 'origin' && this.business == 'car_rental_a'){
          const chooseProject = this.priceProjectList.filter(item => item.start_place === this.create_sheet.origin);
          const chooseCar = this.car_available_list.find((item)=>item.plate_no == chooseProject[0].plate_no)
          this.priceRateListUsed = this.car_type_list.filter((typeCar)=>typeCar.id == chooseCar?.car_type_id)
          this.projectCarPriceList = this.car_available_list.filter((item)=>item.plate_no == chooseProject[0].plate_no)
          if(this.create_sheet.distance_price_rate == "price"){
            // this.create_sheet.time_type_id = this.time_type_list.find((item)=>item.name == chooseProject[0].time_type)?.id
            // this.create_sheet.time_period_id = this.time_period_list.find((item)=>item.name == chooseProject[0].time_period)?.id
            // this.create_sheet.driver_id = this.main_and_sub_driver_list.find((subDriver)=>subDriver.label == chooseProject[0].driver_name)?.value;
            // this.create_sheet.price_date = dayjs(chooseProject[0].price_date)
            // this.create_sheet.price_time = dayjs(chooseProject[0].price_time)
          }
        }
        if (this.isProjectDistance && !this.isProject && (type === 'cartype' || type === 'origin') && this.create_sheet.origin && this.create_sheet.car_type_id) {
          const distance = this.priceProjectList.filter(item => item.start_place === this.create_sheet.origin && item.car_type_id === this.create_sheet.car_type_id)
          this.create_sheet.distance = `${distance[0].distance_1} - ${distance[0].distance_2}`
        } else {
          if(this.isProjectDistance && type == 'amount' && this.create_sheet.origin && this.create_sheet.car_type_id) {
            if(this.isThird_party) {
              this.create_sheet.price_car = 0 
            } else {
              this.create_sheet.price_car = this.priceProjectList.filter(item => item.start_place === this.create_sheet.origin && item.car_type_id === this.create_sheet.car_type_id)[0].car_price_amount
            }
          } else if (!this.isProjectDistance) {
            const price = this.priceRateListUsed.filter((item) => item.name === this.create_sheet.car_type_id)
            if(price && type == "amount" && this.create_sheet.origin && this.destinations && this.create_sheet.car_type_id) {
              if(this.isThird_party) {
                this.create_sheet.price_car = 0 
              } else {
                const result = this.priceProjectList
                .filter(item => item.start_place === this.create_sheet.origin
                && this.destinations.some(destination => item.end_place.includes(destination)))
                .map(item => item[price[0].name])
                .reduce((acc, curr) => acc + curr, 0);
                this.create_sheet.price_car = result
              }
            } else if (type == "distance" && this.create_sheet.origin && this.destinations && this.create_sheet.car_type_id ) {
              this.create_sheet.distance = this.priceProjectList
              .filter(item => item.start_place === this.create_sheet.origin
              && this.destinations.some(destination => item.end_place.includes(destination)))
              .map(item => item["total_distance"]).reduce((acc, curr) => acc + curr, 0);
            }
          }
        }
        if(this.create_sheet.company_3rd == "บริษัทรถร่วม"){
          if(type == "cartype"){
            this.car_company_list = await CarCompanyService.get_car_companies_3rd();
            const resultCarList = this.all_car_list.filter(car => {
                return car.car_type_id == this.create_sheet.car_type_id;
              }
            );
            const uniqueCarCompany = resultCarList.reduce((acc, car) => {
              if (!acc.some(item => item.car_company_id === car.car_company_id)) {
                acc.push(car);
              }
              return acc;
            }, []);
            this.car_company_list = this.car_company_list.filter(carList => {
              return uniqueCarCompany.find(unCar => unCar.car_company_id == carList.id) !=null
            });
            this.create_sheet.car_company_id = this.car_company_list[0]?.id ?? null;
          }else if(type== "distance"){
            this.car_company_list = await CarCompanyService.get_car_companies_3rd();
            const resultCarList = this.all_car_list.filter(car => {
                const nameType = this.car_type_list.find(ct => ct.id == car.car_type_id)?.name;
                return "C_"+nameType == this.create_sheet.car_type_id;
              }
            );
            const uniqueCarCompany = resultCarList.reduce((acc, car) => {
              if (!acc.some(item => item.car_company_id === car.car_company_id)) {
                acc.push(car);
              }
              return acc;
            }, []);
            this.car_company_list = this.car_company_list.filter(carList => {
              return uniqueCarCompany.find(unCar => unCar.car_company_id == carList.id) !=null
            });
            this.create_sheet.car_company_id = this.car_company_list[0]?.id ?? null;
          }
        }
      },
      parseDate(dateStr) {
          const [datePart, timePart] = dateStr.split(" ");
          const [day, month, year] = datePart.split("/").map(Number);
          const [hours, minutes] = timePart.split(":").map(Number);

          return new Date(year, month - 1, day, hours, minutes); // Month is zero-based
      },
      async getModel(id) {
        const data = await SheetService.getSheetById(id)
        if(!data){
          return
        }
        if([5,6,18,19,17,10,14,16,9,8].includes(data.booking_cars[0]?.status) && this.business != 'car_rental_a') {
          this.statusDisable = true
        }
        if([8,9,10,4,5,6,14,15,20].includes(data.booking_cars[0]?.status)){
          this.statusDisable = true
        }
        if(data.oil_range_projects){
          const ORP = data.oil_range_projects;
          data.oilRangeRate = [ORP];
          delete data.oil_range_projects;
        }
        if(data.company_3rd == "บริษัทรถร่วม"){
          this.isThird_party = false;
          this.car_company_list = await CarCompanyService.get_car_companies_3rd()
          this.sheetCar = data;
        }
        data.time_start = dayjs(data.time_start, "YYYY-MM-DD HH:mm:ss");
        data.time_end = dayjs(data.time_end, "YYYY-MM-DD HH:mm:ss");
        data.time_product = dayjs(data.time_product);
        data.time_product_finish = dayjs(data.time_product_finish);
        this.destinations = JSON.parse(data.data_text_destination);
        this.descriptionDestination = data.data_dest_description? JSON.parse(data.data_dest_description):[];
        this.descriptionOrigin = data.data_ori_description ? JSON.parse(data.data_ori_description):[];
        if(this.create_sheet?.operation_id){
          data.operation_id = this.create_sheet?.operation_id;
        }
        if(this.business == 'car_rental_a'){
          if(data.price_date && data.price_time){
            data.price_date = dayjs(data.price_date);
            data.price_time = dayjs(data.price_time);
            if(!this.isProject && data.distance_price_rate == "price"){
              data.price_date = dayjs(data.price_date).format('YYYY-MM-DD HH:mm');
              data.price_time = dayjs(data.price_time).format('YYYY-MM-DD HH:mm');
            }
          }
        }
        this.create_sheet = {...data}
        if(this.create_sheet.booking_cars.length > 0){
          this.create_sheet.car_id = this.create_sheet.booking_cars[0].car_id;
          this.create_sheet.driver_id = this.create_sheet.booking_cars[0].driver_id;
          this.main_driver_id = this.create_sheet.cars[0]?.driver_id;
          if(this.create_sheet.booking_cars[0]?.driver_id == this.create_sheet.cars[0]?.driver_id){
            this.selectedSubDriver = false;
          }else{
            this.selectedSubDriver = true;
          }
        }
        if(this.create_sheet.cars[0] && data.company_3rd != "บริษัทรถร่วม"){
          this.create_sheet.price_car = Number((this.create_sheet.cars[0].car_payment / 26).toFixed(2))
        }
        this.create_sheet.datetime = [
          this.create_sheet.time_start, // แปลง time_start
          this.create_sheet.time_end,  // แปลง time_end
        ];
        this.create_sheet.oil_range = parseInt(this.create_sheet.oil_range)
        if(this.create_sheet.project_id){
          const dataProject = this.project_list.find(project => project.id === this.create_sheet.project_id)
          const dataProjectbyId = await ProjectService.getProjectById(dataProject.id)
          const mergedData = dataProjectbyId.priceRate.reduce((result, current) => {
            for (const key in current) {
              if (result[key] === undefined || result[key] === null) {
                result[key] = current[key];
              }
            }
            return result;
          }, {});
          if(dataProjectbyId.priceRate.length > 0){
            this.priceRateListUsed = this.priceRatesList
            this.priceRateListUsed = this.priceRateListUsed.filter(item => {
              return mergedData[item.name] != undefined && mergedData[item.name] != null
            })
          }
        }

        if(data.is_project == "โปรเจกต์") {
          this.isProject = false
          this.useProject()
        }
        // console.log('5678',this.create_sheet)
        this.sheetCar = [
          {
            ...data
          },
        ];
        if(data?.booking_cars[0]?.service){
          this.car.service.photo_1_show = data.booking_cars[0].service.photo_1 ? [{"url":data.booking_cars[0].service.photo_1}]: []; 
          this.car.service.photo_2_show = data.booking_cars[0].service.photo_2 ? [{"url":data.booking_cars[0].service.photo_2}]: [];
          this.car.service.photo_3_show = data.booking_cars[0].service.photo_3 ? [{"url":data.booking_cars[0].service.photo_3}]: [];
          this.car.service.photo_4_show = data.booking_cars[0].service.photo_4 ? [{"url":data.booking_cars[0].service.photo_4}]: [];
          this.car.service.photo_5_show = data.booking_cars[0].service.photo_5 ? [{"url":data.booking_cars[0].service.photo_5}]: [];
          this.car.service.photo_6_show = data.booking_cars[0].service.photo_6 ? [{"url":data.booking_cars[0].service.photo_6}]: [];
          this.car.service.photo_7_show = data.booking_cars[0].service.photo_7 ? [{"url":data.booking_cars[0].service.photo_7}]: [];
          this.car.service.photo_8_show = data.booking_cars[0].service.photo_8 ? [{"url":data.booking_cars[0].service.photo_8}]: [];
          this.car.service.photo_9_show = data.booking_cars[0].service.photo_9 ? [{"url":data.booking_cars[0].service.photo_9}]: [];
          this.car.service.photo_10_show = data.booking_cars[0].service.photo_10 ? [{"url":data.booking_cars[0].service.photo_10}]: [];
          this.car.service.photo_11_show = data.booking_cars[0].service.photo_11 ? [{"url":data.booking_cars[0].service.photo_11}]: [];
          this.car.service.photo_12_show = data.booking_cars[0].service.photo_12 ? [{"url":data.booking_cars[0].service.photo_12}]: [];
        }
        if(data?.booking_cars[0]?.gas_refills){
          this.car.gas_refills = data?.booking_cars[0]?.gas_refills||[];
          this.car.gas_refills = this.car.gas_refills.map((item)=>
          {
            return {
              ...item,
              photo_1_show : item.photo_1 ? [{"url":item.photo_1}]: [], 
              photo_2_show : item.photo_2 ? [{"url":item.photo_2}]: [],
              photo_3_show : item.photo_3 ? [{"url":item.photo_3}]: [],
              photo_4_show : item.photo_4 ? [{"url":item.photo_4}]: [],
              photo_5_show : item.photo_5 ? [{"url":item.photo_5}]: [],
              photo_6_show : item.photo_6 ? [{"url":item.photo_6}]: [],
              photo_7_show : item.photo_7 ? [{"url":item.photo_7}]: [],
              photo_8_show : item.photo_8 ? [{"url":item.photo_8}]: [],
              photo_9_show : item.photo_9 ? [{"url":item.photo_9}]: [],
              photo_10_show : item.photo_10 ? [{"url":item.photo_10}]: [],
            }
          })       
        }
        this.cars_with_repair = await SystemService.get_core("cars_with_repair")
        this.cars_with_driver = await SystemService.get_core("cars_with_driver"),
        this.cars_with_backup_driver = await SystemService.get_core("cars_with_backup_status"),
        this.car_available_list = await SystemService.get_core("cars_with_repair_booking");
        this.stop_type_list = await SystemService.get_all("StopType")
        this.gas_station_list = await SystemService.get_all("GasStation")
        this.pay_method_list = await SystemService.get_all("PayMethod")
        this.expense_list = await SystemService.get_all("Expense"),

        this.assign_car_form = {
          car_id: data.booking_cars[0].car_id,
          driver_id: data.booking_cars[0].driver_id,
          temporary_driver: data.booking_cars[0].temporary_driver,
        };
        const currentTime = new Date(); // Get current date and time

        const statusFilterList = [8, 9, 10, 16, 17, 18, 19];

        const filteredCars = this.cars_with_backup_driver.filter(car => {
            // Check if the status is in the allowed list
            const statusMatch = statusFilterList.includes(car.status);

            var timeEnd = null
            // Parse time_end (full date)
            if(car.time_end && car.time_product_finish){
              const renderTime = this.render_datetime(car.time_end,car.time_product_finish);
              timeEnd = this.parseDate(renderTime);
            }
            // Check if time_end (date) is in the future
            const isTimeEndValid = timeEnd && timeEnd > currentTime;

            return statusMatch && isTimeEndValid;
        });
        // Remove all cars that have the same id as the filtered ones
        const filteredIds = new Set(filteredCars.map(car => car.id));
        const finalResult = this.cars_with_backup_driver
            .filter(car => !filteredIds.has(car.id))
            .reduce((uniqueCars, car) => {
                if (!uniqueCars.has(car.id)) {
                    uniqueCars.set(car.id, car); // Store only one instance per id
                }
                return uniqueCars;
            }, new Map()) // Use Map to store unique cars by id
            .values(); // Extract unique cars
        this.car_with_backup_driver_list = [...finalResult].map(({ id, firstname, lastname, car_no, car_company_id }) => ({
                                                            value: id,
                                                            label: `${firstname} ${lastname}`,
                                                            description: car_no !== null ? `เบอร์รถ: ${car_no}` : 'ไม่มีเบอร์รถ',
                                                            carCompany: car_company_id
                                                          }));
                                                          
        const filteredCarsUseable = this.car_available_list.filter(car => {
            // Check if the status is in the allowed list
            const statusMatch = statusFilterList.includes(car.status);

            var timeEnd = null
            // Parse time_end (full date)
            if(car.time_end && car.time_product_finish){
              const renderTime = this.render_datetime(car.time_end,car.time_product_finish);
              timeEnd = this.parseDate(renderTime);
            }
            // Check if time_end (date) is in the future
            const isTimeEndValid = timeEnd && timeEnd > currentTime;

            return statusMatch && isTimeEndValid;
        });
        const filteredCarIds = new Set(filteredCarsUseable.map(car => car.id));
        const finalResultCar = this.car_available_list
            .filter(car => !filteredCarIds.has(car.id)|| this.assign_car_form.car_id == car.id)
            .reduce((uniqueCars, car) => {
                if (!uniqueCars.has(car.id)) {
                    uniqueCars.set(car.id, car); // Store only one instance per id
                }
                return uniqueCars;
            }, new Map()) // Use Map to store unique cars by id
            .values(); // Extract unique cars
        this.car_available_list = [...finalResultCar];
        if(this.create_sheet.company_3rd == "บริษัทรถร่วม"){
          if(this.create_sheet.distance_price_rate == "distance" && !this.from_worksheet){
            this.car_company_list = await CarCompanyService.get_car_companies_3rd();
            const resultCarList = this.all_car_list.filter(car => {
                return car.car_type_id == this.create_sheet.car_type_id;
              }
            );
            const uniqueCarCompany = resultCarList.reduce((acc, car) => {
              if (!acc.some(item => item.car_company_id === car.car_company_id)) {
                acc.push(car);
              }
              return acc;
            }, []);
            this.car_company_list = this.car_company_list.filter(carList => {
              return uniqueCarCompany.find(unCar => unCar.car_company_id == carList.id) !=null
            });
          }else if(this.create_sheet.distance_price_rate == "price" && !this.from_worksheet){
            this.car_company_list = await CarCompanyService.get_car_companies_3rd();
            const resultCarList = this.all_car_list.filter(car => {
                const nameType = this.car_type_list.find(ct => ct.id == car.car_type_id)?.name;
                return "C_"+nameType == this.create_sheet.car_type_id;
              }
            );
            const uniqueCarCompany = resultCarList.reduce((acc, car) => {
              if (!acc.some(item => item.car_company_id === car.car_company_id)) {
                acc.push(car);
              }
              return acc;
            }, []);
            this.car_company_list = this.car_company_list.filter(carList => {
              return uniqueCarCompany.find(unCar => unCar.car_company_id == carList.id) !=null
            });
          }
        }
      },

      submit_create_expense() {
      const e = { ...this.create_expense };
      const { id } = e;
      if (this.bookingCarExpenseModal) {
        e.booking_car_id = this.selected_booking.booking_cars[0]?.id
      }
      delete e.datetime;
      delete e.plate_no;
      delete e.photoList;
      delete e.name
      let currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      this.bookingUpdated["total_cars_expense"] = currentTime
      BookingService.booking_update_timestamp(this.bookingUpdated)

      if (id) {
        SystemService.update_all("BookingCarExpense", { data: e }, id).then(() => {
          SystemService.update_all("BookingCarExpense", { data: { edit_expenses_status: 1 } }, id).then(() => {
            message.success("อัพเดทข้อมูลสำเร็จ");
            this.show_create_expense = false;
            this.create_expense = {};
            this.refresh_total_cars_expense_modal();
            if (e.edit_expenses_status == 1) {
              this.newExpense = true;
            }
            this.showWarningExpenses = true;
          });
        });
      } else {
        SystemService.create_all("BookingCarExpense", { data: [e] }).then(() => {
          message.success("เพิ่มข้อมูลสำเร็จ");
          this.show_create_expense = false;

          this.create_expense = {};
          this.refresh_total_cars_expense_modal();
        });
      }
      // this.total_cars_expense_modal = false;
      setTimeout(() => {
        if (this.bookingCarExpenseModal) {
          this.show_total_cars_expense(this.bookingCarExpenseData, this.bookingCarExpenseData.id)
        } else {
          this.total_cars_expense_modal = true;
        }
      }, 1000);
    },
    submit_create_toll_way() {
      const e = this.create_toll_way;
      const { id } = e;
      e.created_at = e.datetime.format("YYYY-MM-DD HH:mm:ss");
      const datetime = e.datetime
      delete e.datetime;
      delete e.plate_no;
      delete e.modelname;
      let currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");

      this.bookingUpdated["total_cars_expense"] = currentTime
      BookingService.booking_update_timestamp(this.bookingUpdated)
      if (id) {
        SystemService.update_all("TollWay", { data: e }, id).then(() => {
          SystemService.update_all("TollWay", { data: {edit_toll_ways_status: 1} }, id).then(() => {
            message.success("อัพเดทข้อมูลสำเร็จ");
            e.datetime = datetime
            this.show_create_toll_way = false;
            this.create_toll_way = {};
            this.model_form.photo_1_show = null
            this.refresh_total_cars_expense_modal();
              if (e.edit_toll_ways_status == 1 ){
              this.newTollWay = true;
              }
            this.showWarningTollWays = true;
          });
        });
      } else {
        if(!this.assign_car_form.toll_ways){
          this.assign_car_form.toll_ways = []
        }
        if(this.drawerCreate){
          e.booking_car_id = this.selected_booking[0].id;
          SystemService.create_all("TollWay", { data: [e] }).then((result) => {
            message.success("เพิ่มข้อมูลสำเร็จ");
            this.create_toll_way = {};
            this.model_form.photo_1_show = null
            this.refresh_total_cars_expense_modal();
            result.data.datetime = datetime
            this.assign_car_form.toll_ways.push(result.data)
          });
        }else if(!e.index && e.index != 0){
          this.model_form.photo_1_show = null
          e.datetime = datetime
          this.assign_car_form.toll_ways.push(e)
        }
        e.datetime = datetime
        this.show_create_toll_way = false;

      }
      if(this.total_cars_expense_modal){
        // this.total_cars_expense_modal = false;
        setTimeout(() => {
          // this.total_cars_expense_modal = true;
        }, 1000);
      }
    },
    submit_create_gas_refill() {
      this.create_gas_refill.bill_photo = this.model_form.bill_photo_show
      const e = { ...this.create_gas_refill };
      if (this.selected_booking && this.selected_booking.length > 0) {
          e.booking_car_id = this.selected_booking[0].id;
      }

      const { id } = e;
      e.created_at = e.datetime.format("YYYY-MM-DD HH:mm:ss");
      delete e.datetime;
      delete e.use_gas_station;
      delete e.plate_no;
      delete e.modelname;
      let currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      if(this.business != 'car_rental_a'){
        this.bookingUpdated["total_cars_expense"] = currentTime
        BookingService.booking_update_timestamp(this.bookingUpdated)
      }
      if (id) {
        SystemService.update_all("GasRefill", { data: e }, id).then(() => {
          SystemService.update_all("GasRefill", { data: { edit_gas_status: 1 } }, id).then(() => {
            message.success("อัพเดทข้อมูลสำเร็จ");
            this.show_gas_refill_drawer = false;
            this.create_gas_refill = {};
            this.refresh_total_cars_expense_modal();
            if (e.edit_gas_status == 1) {
              this.newGas = true;
            }
            this.showWarningGasRefills = true;
          });
        });
      } else {
        SystemService.create_all("GasRefill", { data: [e] }).then(() => {
          message.success("เพิ่มข้อมูลสำเร็จ");
          this.show_gas_refill_drawer = false;
          this.create_gas_refill = {};
          this.refresh_total_cars_expense_modal();
        });
      }
      // this.total_cars_expense_modal = false;
      setTimeout(() => {
        if (this.bookingCarExpenseModal) {
          this.show_total_cars_expense(this.bookingCarExpenseData, this.bookingCarExpenseData.id)
        } else {
          this.total_cars_expense_modal = true;
        }
      }, 1000);
    },

    save_car_expenses() { },
    async show_total_cars_expense(obj, id) {
        if (!obj || !obj.cars || obj.cars.length === 0) {
          console.warn("❌ ไม่มีข้อมูล cars ไม่สามารถเปิด modal ได้");
          return; // ออกจากฟังก์ชันทันที
        }
        this.drawerCreate = true;
        this.total_cars_expense_modal = true;
        this.selected_booking = this.create_sheet.booking_cars.filter(car => car.booking_id == id);
      },

    handleCloseCarExpenses() {
      this.total_cars_expense_modal = false;
      this.showWarningExpenses = false;
      this.showWarningGasRefills = false;
      this.showWarningTollWays = false;
    },
    sum_car_expense(booking_cars) {
    // ค่าใช้จ่ายพื้นฐานที่ต้องคำนวณเสมอ
    let expensesSum = booking_cars
      .map(({ expenses }) => 
        Array.isArray(expenses)
          ? expenses.reduce((a, b) => a + (b.granted === 1 ? Number(b.total_payment) || 0 : 0), 0)
          : 0
      )
      .reduce((a, b) => a + b, 0);

    // ถ้าเป็น "บริษัทรถร่วม" → ใช้ "ราคารถ + ค่าแรงงาน + expenses"
    if (this.create_sheet.company_3rd === 'บริษัทรถร่วม') {
      const priceCar = Number(this.create_sheet.price_car) || 0;
      const liftThings = Number(this.create_sheet.lift_things) || 0;
      return priceCar + liftThings + expensesSum;
    }

    // ถ้าไม่ใช่ "บริษัทรถร่วม" → ใช้ "toll_ways + gas_refills + expenses + carPayment + driverSalary"
    let otherCosts = booking_cars
      .map(({ toll_ways, gas_refills }) => {
        const tollWaysSum = Array.isArray(toll_ways)
          ? toll_ways.reduce((a, b) => a + (b.granted === 1 ? Number(b.paid_amount) || 0 : 0), 0)
          : 0;
        const gasRefillsSum = Array.isArray(gas_refills)
          ? gas_refills.reduce((a, b) => a + (b.granted === 1 ? Number(b.total_amount) || 0 : 0), 0)
          : 0;
        
        return tollWaysSum + gasRefillsSum;
      })
      .reduce((a, b) => a + b, 0);

    // เพิ่มค่า this.carPayment และ this.driverSalary เฉพาะกรณีที่ไม่ใช่ "บริษัทรถร่วม"
    const driverCost = Number(this.carPayment) || 0;
    const rentalPrice = Number(this.driverSalary) || 0;

    return otherCosts + expensesSum + driverCost + rentalPrice;
  },

      sum_expense() {
    const plateNo = this.selected_booking[0]?.car?.plate_no;
    if (!plateNo) {
      return 0;
    }

    const bookingCars = this.filteredBookingCars.filter(({ car }) => car.plate_no === plateNo);

    // ค่าใช้จ่ายพื้นฐานที่ต้องคำนวณเสมอ
    let expensesSum = bookingCars
      .map(({ expenses }) => 
        Array.isArray(expenses)
          ? expenses.reduce((a, b) => a + (b.granted === 1 ? (Number(b.total_payment) || 0) : 0), 0)
          : 0
      )
      .reduce((a, b) => a + b, 0);

    // ถ้าเป็น "บริษัทรถร่วม" → ใช้ "ราคารถ + ค่าแรงงาน + expenses"
    if (this.create_sheet.company_3rd === 'บริษัทรถร่วม') {
      const priceCar = Number(this.create_sheet.price_car) || 0;
      const liftThings = Number(this.create_sheet.lift_things) || 0;
      return priceCar + liftThings + expensesSum;
    }

    // ถ้าไม่ใช่ "บริษัทรถร่วม" → ใช้ "toll_ways + gas_refills + expenses + carPayment + driverSalary"
    let otherCosts = bookingCars
      .map(({ toll_ways, gas_refills }) => {
        const tollWaysSum = Array.isArray(toll_ways)
          ? toll_ways.reduce((a, b) => a + (b.granted === 1 ? (Number(b.paid_amount) || 0) : 0), 0)
          : 0;
        const gasRefillsSum = Array.isArray(gas_refills)
          ? gas_refills.reduce((a, b) => a + (b.granted === 1 ? (Number(b.total_amount) || 0) : 0), 0)
          : 0;
        
        return tollWaysSum + gasRefillsSum;
      })
      .reduce((a, b) => a + b, 0);

    // เพิ่มค่า this.carPayment และ this.driverSalary เฉพาะกรณีที่ไม่ใช่ "บริษัทรถร่วม"
    const driverCost = Number(this.carPayment) || 0;
    const rentalPrice = Number(this.driverSalary) || 0;

    return otherCosts + expensesSum + driverCost + rentalPrice;
  },

    edit_expense_log(data, type) {
      switch (type) {
        case "toll_ways":
          this.show_create_toll_way = true;
          this.create_toll_way = data;
          this.create_toll_way.datetime = dayjs(data.created_at, "YYYY-MM-DD HH:mm:ss");
          break;
        case "gas_refills":
          for (const [key, value] of Object.entries(data)) {
            if(key == 'bill_photo' && value != null){
              this.bill_photo_show_source = value
            }else if(key == 'bill_photo' && value == null){
              this.bill_photo_show_source = ''
            }
          }
          this.add_gas_refill_drawer = true;
          this.create_gas_refill = data;
          this.create_gas_refill.datetime = dayjs(data.created_at, "YYYY-MM-DD HH:mm:ss");
          this.create_gas_refill.use_gas_station = data.gas_station_id != null;
          break;
        case "expenses":
          this.show_create_booking_car_expense = true;
          this.create_booking_car_expense = data;
          this.create_booking_car_expense.datetime = dayjs(data.created_at, "YYYY-MM-DD HH:mm:ss");
          break;
      }
    },
    async update_granted({ id, approve_remark }, type, value, list) {
      try {
        const result = {
          approve_remark,
          granted: value,
        };
        if (value == 2) {
          await Swal.fire({
            title: "กรุณาระบุเหตุผลที่ไม่อนุมัติ",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            showLoaderOnConfirm: true,
            preConfirm: (value) => {
              return value;
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }).then(({ isConfirmed, value }) => {
            // console.log({ isConfirmed, value, type });
            if (value && isConfirmed) {
              if (result.approve_remark?.includes("ไม่อนุมัติ")) {
                result.approve_remark = result.approve_remark.split(" (ไม่อนุมัติ")[0];
                result.approve_remark = `${result.approve_remark} (ไม่อนุมัติ: ${value})`;
              } else {
                result.approve_remark = `${
                  result.approve_remark ? result.approve_remark : ""
                } (ไม่อนุมัติ: ${value})`;
              }

              list.forEach((item) => {
                if (item.id === id) {
                  item.approve_remark = result.approve_remark;
                }
              });
            } else {
              list.forEach((item) => {
                if (item.id === id) {
                  item.granted = null;
                }
              });
              throw "ยกเลิกการอัพเดท";
            }
          });
        }

        switch (type) {
          case "toll_ways":
            SystemService.update_all("TollWay", { data: result }, id).then(() => {
              this.refresh_total_cars_expense_modal();
              message.success("อัพเดทข้อมูลสำเร็จ");
            });
            break;
          case "gas_refills":
            SystemService.update_all("GasRefill", { data: result }, id).then(() => {
              this.refresh_total_cars_expense_modal();
              message.success("อัพเดทข้อมูลสำเร็จ");
            });
            break;
          case "expenses":
            SystemService.update_all("BookingCarExpense", { data: result }, id).then(
              () => {
                this.refresh_total_cars_expense_modal();
                message.success("อัพเดทข้อมูลสำเร็จ");
              }
            );
            this.selected_booking[0].expenses.forEach((expense) => {
              if (expense.id === id) {
                expense.granted = value;
                expense.approve_remark = result.approve_remark;
              }
            });
            break;
        }
            let currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
            this.bookingUpdated["total_cars_expense"] = currentTime
            BookingService.booking_update_timestamp(this.bookingUpdated)
      } catch (err) {
        console.log(err);
      }
    },
    async refresh_total_cars_expense_modal() {
      message.loading({
        content: "กำลังโหลดข้อมูลใหม่...",
        key: "refresh_total_cars_expense_modal",
      });
      await this.init_data();
      message.success({
        content: "โหลดข้อมูลเรียบร้อยแล้ว",
        key: "refresh_total_cars_expense_modal",
      });
    },
    async remove_uploaded_file({ file, index, uid }) {
      if (uid) {
        const fileIndex = this.gas.photo_2_show.findIndex(f => f.uid === file.uid);
        if (fileIndex !== -1) {
          this.gas.photo_2_show.splice(fileIndex, 1);
        }
      } else if (index !== undefined) {
        this.gas.bill_photo_show.splice(index, 1);
      } else {
        const fileIndex = this.gas.photo_2_show.findIndex(f => f.uid === file.uid);
        if (fileIndex !== -1) {
          this.gas.photo_2_show.splice(fileIndex, 1);
        }
      }
    },
    upload_files(e, key, model_name = null, index = null, sub_index = null) {
      if (e.file.status != 'remove') {
        this.getBase64(e.file.originFileObj, key)
        if (model_name == 'gas_refills') {
          if (key == 'bill_photo_show') {
            //this.create_booking.booking_cars[index].gas_refills[sub_index].bill_photo = this.model_form.bill_photo_show
            this.bill_photo_show_source = this.model_form.bill_photo_show
          }
        } else if (model_name == 'toll_ways') {
          if (key == 'photo_1_show') {
            // this.assign_car_form.toll_ways[index].photo_1 = this.model_form.photo_1_show
            this.create_toll_way.photo_1 = this.model_form.photo_1_show
          }
        } else if (model_name == 'create_expense') {
          if (key == 'photo') {
            this.create_expense.photo = this.model_form.photo
          }
        }
        return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`
      }
    },
    remove_expense_log(model_id, type) {
      const old_active_key = this.total_car_expenses_collapse;
      let currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      this.bookingUpdated["total_cars_expense"] = currentTime
      BookingService.booking_update_timestamp(this.bookingUpdated)
      switch (type) {
        case "toll_ways":
          SystemService.delete_all("TollWay", model_id).then(() => {
            message.success("ลบข้อมูลสำเร็จ");
            this.total_car_expenses_collapse = null;
            this.total_car_expenses.toll_ways.splice(
              this.total_car_expenses.toll_ways.findIndex(({ id }) => id == model_id),
              1
            );
            this.selected_booking.forEach((car) => {
              car.toll_ways = car.toll_ways?.filter(({ id }) => id != model_id);
            });
            this.total_car_expenses_collapse = old_active_key;
          });
          break;
        case "gas_refills":
          SystemService.delete_all("GasRefill", model_id).then(() => {
            message.success("ลบข้อมูลสำเร็จ");
            this.total_car_expenses_collapse = null;
            this.total_car_expenses.gas_refills.splice(
              this.total_car_expenses.gas_refills.findIndex(({ id }) => id == model_id),
              1
            );
            this.selected_booking.forEach((car) => {
              car.gas_refills = car.gas_refills?.filter(({ id }) => id != model_id);
            });
            this.total_car_expenses_collapse = old_active_key;
          });
          break;
        case "expenses":
          SystemService.delete_all("BookingCarExpense", model_id).then(() => {
            message.success("ลบข้อมูลสำเร็จ");
            this.total_car_expenses_collapse = null;
            this.total_car_expenses.expenses.splice(
              this.total_car_expenses.expenses.findIndex(({ id }) => id == model_id),
              1
            );
            this.selected_booking.forEach((car) => {
              car.expenses = car.expenses?.filter(({ id }) => id != model_id);
            });
            this.total_car_expenses_collapse = old_active_key;
          });
          break;
      }
      this.refresh_total_cars_expense_modal();
    },
    allIsApproved(booking_cars) {
      if (booking_cars.every(({ toll_ways, gas_refills, expenses, driver_cost, rental_price }) => {
        return (
          (!toll_ways || toll_ways.length === 0) &&
          (!gas_refills || gas_refills.length === 0) &&
          (!expenses || expenses.length === 0) &&
          (driver_cost === "0.00") &&
          (rental_price == null)
        );
      })) {
        SystemService.update_all("Booking", { data: { all_approved: 1 } }, booking_cars?.[0]?.booking_id);
        return false;
      } else if (
        booking_cars.every(({ toll_ways, gas_refills, expenses }) =>
          (!toll_ways || toll_ways.length === 0) &&
          (!gas_refills || gas_refills.length === 0) &&
          (!expenses || expenses.length === 0)
        ) &&
        booking_cars.some(({ driver_cost, rental_price }) =>
          driver_cost !== "0.00" || rental_price != null
        )
      ) {
        SystemService.update_all("Booking", { data: { all_approved: 1 } }, booking_cars?.[0]?.booking_id);
        return true;
      } else if (booking_cars.some(({ toll_ways, gas_refills, expenses }) => {
        const tollwaysNotGranted = toll_ways?.some(({ granted }) => granted == null);
        const gasRefillsNotGranted = gas_refills?.some(({ granted }) => granted == null);
        const expensesNotGranted = expenses?.some(({ granted }) => granted == null);

        return tollwaysNotGranted || gasRefillsNotGranted || expensesNotGranted;
      })) {
        SystemService.update_all("Booking", { data: { all_approved: 2 } }, booking_cars?.[0]?.booking_id);
        return false;
      } else {
        SystemService.update_all("Booking", { data: { all_approved: 1 } }, booking_cars?.[0]?.booking_id);
        return true;
      }
    },
      async init_data() {
        this.sheets_data = await SheetService.get_work_sheets()
        const accountData = await AccountingService.getAccountingByCompany();
        this.statusDisable = false;
        const FilterAccount = accountData.filter((item)=> item.department == 'SalesAndService')
        this.accounting_list = FilterAccount.map(admin => {
            if (admin.firstname && admin.lastname) {
                return {
                ...admin,
                fullname: `${admin.firstname} ${admin.lastname}`
                };
            }
            return admin;
        });
        if (this.bookingCarExpenseModal) {
        this.bookingCarExpenseData = this.selected_booking;
        this.bookingCarExpenseData.booking_cars = this.selected_booking.booking_cars.filter((book) => book.id == this.bookingCarExpenseData.booking_cars[0].id)
      }
      },
      // async init_manage_work_sheet() {
      //   this.work_sheets = await SheetService.get_work_sheets()
      // },

      formatterCurrency(value) {
      if (!value) return '';
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    parserCurrency(value) {
      return value.replace(/\D/g, '');
    },
    calculateTotalBilling() {
      const { 
        selling_per_trip, 
        additional_selling, 
        return_cost, 
        waiting_time_fee, 
        overnight_charge, 
        cancellation_fee 
      } = this.create_sheet;

      const total_selling = 
        parseFloat(selling_per_trip || 0) + 
        parseFloat(additional_selling || 0) + 
        parseFloat(return_cost || 0) + 
        parseFloat(waiting_time_fee || 0) + 
        parseFloat(overnight_charge || 0) + 
        parseFloat(cancellation_fee || 0);

      this.create_sheet.total_selling = total_selling;
    },
    async sellingper() {

      if (!this.checkBill === true) {
        return;
    } 
      


    try {
        const project_id = this.create_sheet.project_id;
        let car_type_id = this.create_sheet.car_type_id;
        const distance = this.create_sheet.distance;
        const distance_price_rate = this.create_sheet.distance_price_rate;
        let odometer = 1; // กำหนดค่าเริ่มต้นเป็น 1
            if (this.create_sheet.cars && this.create_sheet.cars.length > 0) {
                odometer = this.create_sheet.cars[0].odometer ?? 1;
            } else if (this.create_sheet.booking_cars && this.create_sheet.booking_cars.length > 0 && this.create_sheet.booking_cars[0].car) {
                odometer = this.create_sheet.booking_cars[0].car.odometer ?? 1;
            } else {
                console.warn("No valid odometer data found! Setting odometer to 1.");
            }

        if (distance_price_rate === 'distance') {
            const response = await SystemService.sellingper(project_id, car_type_id, odometer);
            if (response && response.price_amount) {
                this.create_sheet.selling_per_trip = response.price_amount;
            } else {
                console.warn("No data received from API");
            }
        } else {
          const price_rate = this.create_sheet.price_rate;
            if(this.priceRateListUsed.length == 0){
              this.priceRateListUsed = this.priceRatesList;
            }
            const priceRatesList = this.priceRateListUsed;
            car_type_id = this.car_type_list.find(item => item.id === car_type_id)?.name
            const carType = priceRatesList.find(item => item.name === "C_"+car_type_id);
            const origin = this.create_sheet.origin;
            let carName = carType?.name;
          if (carName.startsWith("C_")) {
              carName = carName.replace("C_", "H_");
            }
            const response = await SystemService.selling(project_id, carName, origin);
            if (response?.price_amount) {
            this.create_sheet.selling_per_trip = response.price_amount;
        } else {
            console.warn("No data received from API, ใช้ค่าเดิม:", this.create_sheet.selling_per_trip);
        }
        }
    } catch (error) {
        console.error("Error in sellingper:", error);
    }
},
    handleClickDeletePhoto(e , key , model_name , index = null , sub_index = null) {
        e.stopPropagation(); // ป้องกันการกระทำอื่นๆ ที่เกิดขึ้น
        if (/^photo_(\d+)_show$/.test(key)) {
            let index = Number(key.match(/^photo_(\d+)_show$/)[1]); 
            if (index >= 1 && index <= 12) {
                this.car.service[`photo_${index}`] = [];
                this.car.service[`photo_${index}_show`] = [];
                this.create_sheet.booking_cars[0].service[`photo_${index}`] = null;
            }
        }
    },

    },


    
  
    
    async mounted() {
      message.loading({
        content: "กำลังโหลดข้อมูลเริ่มต้น...",
      });
      await this.get_company();
      await this.get_car_data();
      if (this.render_type != 'all') {
        await this.init_model();
      } else if(this.render_type == 'all') {
        await this.init_data();
      }
      message.success({
        content: "โหลดข้อมูลเริ่มต้นเสร็จสิ้น",
      });
    },
    
}
</script>

<style scoped>
/* .ant-form-item-control-input {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 0px;
} */
.disabled {
  pointer-events: none;
  color: gray;
  cursor: not-allowed;
}
.uploaded-image {
  width: 100%;
  object-fit: cover;
  transition: filter 0.3s ease;
  cursor: pointer;
}
.uploaded-image-table {
  width: 50px;
  height: 50px;
  transition: filter 0.3s ease;
  cursor: pointer;
}
.upload-container:hover .uploaded-image-table {
  filter: grayscale(100%);
}
.upload-container:hover .uploaded-image {
  filter: grayscale(100%);
}
</style>