
import httpClient from './httpClient';
const prefix = 'core'

export default {

  async CheckBill_report(company_id, client_company, company_car, car_status, time_start, time_end, type_report_client,type_report_carCompany) {
    const params = {
      company_id:company_id,
      timeStart: time_start,
      timeEnd: time_end,
      clientCompany: client_company || "All",
      companyCar: company_car || "All",
      typeReportClient: type_report_client || "All",
      typeReportCarCompany: type_report_carCompany || "All",
      carStatus: car_status || "All",
    };
  
    const queryString = new URLSearchParams(params).toString();
    const { data } = await httpClient({ requiresAuth: true }).get(`/${prefix}/report/CheckBill-report?${queryString}`);
  
    return data;
  },
    async get_CheckBill(onpage = 1, perpage = 20,  searchBody = null) {
      var query = '';
      if (searchBody != null) {
        for (var key in searchBody) {
          if (searchBody[key]) {
            query = query != '' ? `${query}&${key}=${searchBody[key]}` : `${key}=${searchBody[key]}`;
          }
        }
      }
      const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/get_CheckBill?onpage=${onpage}&perpage=${perpage}&${query}`);
      return data;
    },
  async sellingper(project_id, car_type_id, odometer) {
    const { data } = await httpClient({ requiresAuth: true })
        .get(`${prefix}/sellingper/${project_id}?car_type_id=${car_type_id}&distance=${odometer}`);
    return data;
},
  async selling(project_id, carName, origin) {
    const { data } = await httpClient({ requiresAuth: true })
        .get(`${prefix}/selling/${project_id}?carName=${carName}&origin=${origin}`);
    return data;
},
  async leavequota(company_id,username) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/leave/leavequota/${company_id}?username=${username}`);
    return data
  },
  async leavequotaused(company_id,username,leaveUserNo) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/leave/leavequotaused/${company_id}?username=${username}&leaveUserNo=${leaveUserNo}`);
    return data
  },
  async bookingStatus(company_id) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/bookings/status/${company_id}`)
    return data
  },
  async bookingCarStatus(company_id, business) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/booking-cars/status/${company_id}`,{ params: { business }, });
    return data;
},
async CheckBillstatus(company_id, business) {
  const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/CheckBillstatus/${company_id}`,{ params: { business }, });
  return data;
},
  async get_client_no() {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/client-no`)

    return data
  },
  async get_qtc() {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/qtc`)

    return data
  },
  async check_duplicate_username(modelname, query) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/check-duplicate-username/${modelname}?${query}`)

    return data
  },
  async check_duplicate(modelname, query) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/check-duplicate/${modelname}?${query}`)

    return data
  },
  async get_all (modelname, query) {
    const { data } = await httpClient({ requiresAuth: true }).get(`/all/${modelname}${query? '?'+query : ''}`)

    return data
  },
  async get_core (modelname, query) {
    const { data } = await httpClient({ requiresAuth: true }).get(`/core/${modelname}${query? '?'+query : ''}`)

    return data
  },
  async get_id (modelname, id) {
    const { data } = await httpClient({ requiresAuth: true }).get(`/id/${modelname}?id=${id}`)

    return data
  },
  async create_all (modelname, body) {
    const data  = await httpClient({ requiresAuth: true }).post(prefix+`/all/${modelname}`, body)

    return data
  },
  async update_all (modelname, body, id) {
    const data = await httpClient({ requiresAuth: true }).put(prefix+`/all/${modelname}/${id}`, body)

    return data
  },
  async delete_all (modelname, id) {
    const { data } = await httpClient({ requiresAuth: true }).delete(prefix+`/all/${modelname}/${id}`)

    return data
  },
  async delete (modelname, id) {
    const { data } = await httpClient({ requiresAuth: true }).delete(prefix+`/${modelname}/${id}`)

    return data
  },
  async resource_car () {
    const { data } = await httpClient({ requiresAuth: true }).get('/cars')

    return data
  },
  async places( query ) {
    const { data } = await httpClient({ requiresAuth: true }).get(`/places?name=${query}`)

    return data
  },
  async savePassword(body) {
		const { data } = await httpClient({ requiresAuth: true }).post(prefix + `/save_password`, body);

		return data;
	},
  async updateColorById(id) {
    const { data } = await httpClient({ requiresAuth: true }).put(prefix +`/colors/${id}`);
    return data;
  },
  async getCardId() {
    const { data } = await httpClient({ requiresAuth: true }).get(prefix +`/cardId`);
    return data;
  },
}