<template>
    <div class="claimcontainer" style="padding: 15px;height: 100vh">
        <a-spin :spinning="loading">
        <div v-if="render_type == 'history'" class="app-content">
            <a-card title="ประวัติการแจ้งเบิก">
                <template #extra>
                    <a-space>
                        <a-button style="display: flex" type="primary" size="large" @click="goLeaveHistory()">
                            ย้อนกลับ
                        </a-button>
                    </a-space>
                </template>
            </a-card>

            <a-space style="width: 100%; margin-top: 20px">
                <a-input-search
                    v-model:value="search_input"
                    placeholder="ค้นหาชื่อผู้ขับ"
                    enter-button="Search"
                    style="width: 350px;"
                />

                <a-range-picker
                    v-model:value="datetime"
                />

                <a-button @click="clear_search()" type="dashed">CLEAR</a-button>
            </a-space>
            <div style="display: flex; margin-top: 20px;">
                <a-table :columns="[
                    {
                        title: 'ชื่อ-นามสกุลผู้ขับ',
                        dataIndex: 'name',
                        key: 'name',
                        width: 175,
                        align: 'center',
                    },
                    ...(car_rental_a ? [{  
                            title: 'วันที่แจ้งเบิก',  
                            dataIndex: 'created_at',  
                            key: 'created_at',  
                            width: 175,  
                            align: 'center'  
                        }] : []),  
                    {
                        title: 'วันที่ขอเบิก',
                        dataIndex: 'claim_date',
                        key: 'claim_date',
                        width: 175,
                        align: 'center'
                    },
                    {
                        title: 'จำนวนขอเบิก',
                        dataIndex: 'amount',
                        key: 'amount',
                        width: 150,
                        align: 'center'
                    },
                    {
                        title: 'ประเภทการเบิกเงิน',
                        dataIndex: 'pay_method',
                        key: 'pay_method',
                        width: 125,
                        align: 'center'
                    },
                    {
                        title: 'การอนุมัติ',
                        dataIndex: 'approve',
                        key: 'approve',
                        width: 175,
                        align: 'center',
                    },
                    {
                        title: 'หมายเหตุ',
                        dataIndex: 'approve_remark',
                        key: 'approve_remark',
                        width: 175,
                        align: 'center'
                    },
                    ...(car_rental_a ? [{  
                        title: 'ชื่อผู้อนุมัติการเบิก',
                        dataIndex: 'operation_id',
                        key: 'operation_id',
                        width: 175,
                        align: 'center',
                        }] : []),  
                    {
                        title: 'ตัวเลือก',
                        key: 'action',
                        width: 100,
                        align: 'center',
                    },
                ]" 
                :dataSource="filtered_table(history_data)"
                bordered
                size="small"
                :scroll="{ x: 'calc(100px + 50%)', y: '500px' }"
                >
                <template #bodyCell="{ column, record }">

                    <template v-if="column.key === 'approve'">
                        <span :style="{ color: record.approve == 1 ? '#389e0d' : '#ff4d4f' }">
                            {{ record.approve == 1 ? 'อนุมัติ' : 'ไม่อนุมัติ' }}
                        </span>
                    </template>
                    <template v-if="column.key === 'operation_id'">
                        {{ record.fullname ? ` ${record.fullname} (${record.nickname})` : ''  }}
                    </template>

                    <template v-if="column.key === 'pay_method'">
                        {{  record.pay_method_id == 1 ? 'เงินสด' : 'เงินโอน' }}
                    </template>

                    <template v-if="column.key === 'amount'">
                        {{ Number(record.amount)?.toLocaleString() }}
                    </template>

                    <template v-if="column.key === 'action'">
                        <div>
                            <a-tooltip>
                            <a-divider type="vertical"></a-divider>
                            
                            <a @click="show_detail_modal(record.id)">
                                <span class="material-symbols-outlined preview-icon">
                                    visibility
                                </span>
                            </a>
                            
                            </a-tooltip>
                            <a-divider type="vertical"></a-divider>
                        </div>
                        
                    </template>
              </template>
            </a-table>
            </div>
            <a-modal
            v-if="previewVisible"
            :visible="previewVisible"
            footer=""
            :width="800"
            @cancel="preview_close"
            >
                <img :src="previewImage" style="width: 100%" />
            </a-modal>
        </div>
        <div v-else class="app-content">
            <a-card :title="'การแจ้งเบิก'">
                <!-- <a-row style="justify-content: space-between;">
                    <h3>การแจ้งเบิก</h3>
                </a-row> -->
                <template #extra>
                    <a-space>
                        <div style="display: flex ; margin-right: 5px;">
                            <a-badge :count="countLeave">
                                <a-button type="primary" size="large" @click="go_history()">
                                    {{ 'ประวัติการแจ้งเบิก' }}
                                </a-button>
                            </a-badge>
                        </div>
                    </a-space>
                </template>
            </a-card>

            <a-space style="width: 100%; margin-top: 20px">
                <a-input-search
                    v-model:value="search_input"
                    placeholder="ค้นหาชื่อผู้ขับ"
                    enter-button="Search"
                    style="width: 350px;"
                />

                <a-range-picker
                    v-model:value="datetime"
                />

                <a-button @click="clear_search()" type="dashed">CLEAR</a-button>
            </a-space>
            <div style="display: flex; margin-top: 20px;">
                <a-table :columns="[
                    {
                        title: 'ชื่อ-นามสกุลผู้ขับ',
                        dataIndex: 'name',
                        key: 'name',
                        align: 'center',
                        width: 175,
                    },
                    ...(car_rental_a ? [{  
                            title: 'วันที่แจ้งเบิก',  
                            dataIndex: 'created_at',  
                            key: 'created_at',  
                            width: 175,  
                            align: 'center'  
                        }] : []),  
                    {
                        title: 'วันที่ขอเบิก',
                        dataIndex: 'claim_date',
                        key: 'claim_date',
                        width: 175,
                        align: 'center'
                    },
                    {
                        title: 'จำนวนขอเบิก',
                        dataIndex: 'amount',
                        key: 'amount',
                        width: 150,
                        align: 'center'
                    },
                    {
                        title: 'ประเภทการเบิกเงิน',
                        dataIndex: 'pay_method',
                        key: 'pay_method',
                        width: 125,
                        align: 'center'
                    },
                    {
                        title: 'การอนุมัติ',
                        dataIndex: 'approve',
                        key: 'approve',
                        width: 175,
                        align: 'center',
                    },
                    {
                        title: 'หมายเหตุ',
                        dataIndex: 'approve_remark',
                        key: 'approve_remark',
                        width: 175,
                        align: 'center'
                    },
                    ...(car_rental_a ? [{  
                        title: 'ชื่อผู้อนุมัติการเบิก',
                        dataIndex: 'operation_id',
                        key: 'operation_id',
                        width: 175,
                        align: 'center',
                        }] : []),  
                    {
                        title: 'ตัวเลือก',
                        key: 'action',
                        align: 'center',
                        width: 140,
                    },
                ]" 
                :dataSource="filtered_table(data)"
                bordered
                size="small"
                :scroll="{ x: 'calc(100px + 50%)', y: '500px' }"
                >
                <template #bodyCell="{ column, record }">

                    <template v-if="column.key === 'approve'">
                        <a-select
                            style="width: 130px;"
                            placeholder="การอนุมัติ"
                            @change="(value) => handleChange(record.id,value)"
                        >
                            <a-select-option value=1>อนุมัติ</a-select-option>
                            <a-select-option value=2>ไม่อนุมัติ</a-select-option>
                        </a-select>
                    </template>

                    <template v-if="column.key === 'operation_id'">
                        <a-select
                            style="width: 130px;"
                            placeholder="ชื่อผู้อนุมัติ"
                            @change="(value) => handleChangeOperation(record.id,value)"
                            :value="record.operation_id"
                        >
                            <a-select-option v-for="option in operation_list" :key="option.operation_id" :value="Number(option.id)">
                                {{ `${option.fullname} (${option.nickname})` }}
                            </a-select-option>
                        </a-select>
                    </template>
                    <template v-if="column.key === 'pay_method'">
                        {{  record.pay_method_id == 1 ? 'เงินสด' : 'เงินโอน' }}
                    </template>

                    <template v-if="column.key === 'amount'">
                        {{ Number(record.amount)?.toLocaleString() }}
                    </template>

                    <template v-if="column.key === 'action'">
                        <div>
                            <a-tooltip>
                                <template #title>ส่งการแจ้งเตือนอนุมัติ</template>
                                <a @click="send_approve(record.id)"
                                ><span style="font-size: 14px" class="material-symbols-outlined"
                                >send</span
                                ></a>
                            </a-tooltip>
                            <a-tooltip>
                            <a-divider type="vertical"></a-divider>
                            <template #title>แก้ไข</template>
                            <a @click="show_modal(record.id)"
                                ><span style="font-size: 14px" class="material-symbols-outlined"
                                >drive_file_rename_outline</span
                                ></a
                            >
                            
                            </a-tooltip>
                            <a-divider type="vertical"></a-divider>
                            <a-tooltip>
                            <template #title>ยกเลิก</template>
                            <a @click="remove_claim(record.id)"
                                ><span style="font-size: 18px" class="material-symbols-outlined"
                                >delete</span
                                ></a
                            >
                            </a-tooltip>
                        </div>
                        
                    </template>
              </template>
            </a-table>
            </div>
        </div>
        <a-modal
          v-if="previewVisible"
          :visible="previewVisible"
          footer=""
          :width="800"
          @cancel="preview_close"
        >
          <img :src="previewImage" style="width: 100%" />
        </a-modal>
    </a-spin>
    </div>
    
    <a-modal v-model:visible="modal_visible" 
         title="รายละเอียดการแจ้งเบิก" 
         :closable="false" 
         footer
         :width="600">
        <a-form :model="modal_form" :layout="vertical">
            <a-row v-if="car_rental_a" class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">วันที่แจ้งเบิก:</p>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="18">
                    <div class="gutter-box value-container">
                        <a-form-item :style="{ width: '100%', display: 'flex', justifyContent: 'flex-end' }">
                            <a-date-picker
                                v-model:value="modal_form.created_at"
                                format="DD/MM/YYYY"
                                placeholder="เลือกเวลา"
                                :minuteStep="1"
                                disabled = "true"
                                style="width: 100%;"
                            />
                        </a-form-item>
                    </div>
                </a-col>
            </a-row>
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">วันที่ขอเบิก:</p>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="18">
                    <div class="gutter-box value-container">
                        <a-form-item :style="{ width: '100%', display: 'flex', justifyContent: 'flex-end' }">
                            <a-date-picker
                                v-model:value="modal_form.time_end"
                                format="DD/MM/YYYY"
                                placeholder="เลือกเวลา"
                                :minuteStep="1"
                                disabled = "true"
                                style="width: 100%;"
                            />
                        </a-form-item>
                    </div>
                </a-col>
            </a-row>
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">ประเภทการเบิก:</p>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="18">
                    <div class="gutter-box value-container">
                        <a-form-item :style="{ width: '100%'}">
                            <a-select v-model:value="modal_form.pay_method_id" 
                                placeholder="ประเภทการเบิก"
                                disabled = "true"
                                style="width: 100%;"
                            >
                                <a-select-option value="1">เงินสด</a-select-option>
                                <a-select-option value="2">เงินโอน</a-select-option>
                            </a-select>
                        </a-form-item>
                    </div>
                </a-col>
            </a-row>
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">จำนวนขอเบิก:</p>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="18">
                    <div class="gutter-box value-container">
                        <a-form-item :style="{ width: '100%'}">
                            <a-input v-model:value="modal_form.amount" :disabled = true style="width: 100%;"></a-input>
                        </a-form-item>
                    </div>
                </a-col>
            </a-row>
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">ผลอนุมัติ:</p>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="18">
                    <div class="gutter-box value-container">
                        <a-form-item :style="{ width: '100%'}">
                            <a-select v-model:value="modal_form.approve" 
                                style="width: 100%;"
                                placeholder="ผลอนุมัติ"
                                :disabled="isHistory()" 
                            >
                                <a-select-option value="1">อนุมัติ</a-select-option>
                                <a-select-option value="2">ไม่อนุมัติ</a-select-option>
                            </a-select>
                        </a-form-item>
                    </div>
                </a-col>
            </a-row>
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">เหตุผลการขอเบิก:</p>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="18">
                    <div class="gutter-box value-container">
                        <a-form-item :style="{ width: '100%'}">
                            <a-input v-model:value="modal_form.remark" disabled style="width: 100%;"></a-input>
                        </a-form-item>
                    </div>
                </a-col>
            </a-row>
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">ใบรูปหลักฐานการโอน:</p>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="18">
                    <div class="gutter-box value-container">
                        <a-form-item name="photo_1">
                        <div v-if="!isHistory()" class="upload-section">
                            <a-upload
                              v-model:file-list="claim_expense.photo_1_show"
                              list-type="picture-card"
                              class="avatar-uploader"
                              accept="image/*"
                              :show-upload-list="false"
                              @change="upload_files($event, 'photo_1_show' , 'claim_expense', modal_form.index)"
                              method="GET"
                            >
                                <div v-if="modal_form.photo_1" class="upload-container">
                                    <img
                                        :src="modal_form.photo_1"
                                        style="width: 70px; height: 70px;"
                                        alt="uploaded image"
                                    />
                                    <div class="icon-container">
                                        <span
                                            class="material-symbols-outlined preview-icon"
                                            @click="handleIconClick('preview', modal_form.photo_1, $event)"
                                        >
                                            visibility
                                        </span>
                                        <div @click="getBase64Image($event , { url: modal_form.photo_1})">
                                            <span class="material-symbols-outlined download-icon" style="color: black;">
                                                download
                                            </span>
                                        </div>
                                        <span
                                            class="material-symbols-outlined delete-icon"
                                            @click="handleClickDeletePhoto($event , 'photo_1_show' , 'claim_expense' , modal_form.index )"
                                        >
                                            delete
                                        </span>
                                    </div>
                                </div>
                                <div v-else style="display: flex; flex-direction: column; align-items: center;">
                                    <p class="ant-upload-drag-icon">
                                    <span
                                        class="material-symbols-outlined"
                                        style="font-size: 5rem"
                                        >upload_file</span
                                    >
                                    </p>
                                    คลิกเพื่ออัปโหลดรูปภาพ
                                </div>
                            </a-upload>
                        </div>
                        <div v-else class="upload-section">
                            <a-upload
                                :disabled="true"
                                style="cursor: default;" 
                                class="avatar-uploader"
                                :show-upload-list="false"
                            >
                                <div v-if="modal_form.photo_1" class="upload-container">
                                    <img
                                        :src="modal_form.photo_1"
                                        style="max-width: 70px; max-height: 70px;"
                                        alt="uploaded image"
                                    />
                                    <div class="icon-container" style="cursor: pointer; pointer-events: auto;"> <!-- Enable pointer events for icons -->
                                        <span
                                            class="material-symbols-outlined preview-icon"
                                            @click="handleIconClick('preview', modal_form.photo_1, $event)"
                                        >
                                            visibility
                                        </span>
                                        <div @click="getBase64Image($event, { url: modal_form.photo_1 })">
                                            <span class="material-symbols-outlined download-icon" style="color: black;">
                                                download
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="upload-container">
                                    {{ 'ไม่มีรูปภาพ' }}
                                </div>
                            </a-upload>

                        </div>
                    </a-form-item>
                    </div>
                </a-col>
            </a-row>
            
                    <a-row class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">หมายเหตุ:</p>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="18">
                    <div class="gutter-box value-container">
                        <a-form-item :style="{ width: '100%'}">
                            <a-input v-model:value="modal_form.approve_remark" :disabled="isHistory()" style="width: 100%;"></a-input>
                        </a-form-item>
                    </div>
                </a-col>
            </a-row>
            <a-row v-if="car_rental_a" class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">ชื่อผู้อนุมัติการเบิก:</p>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="18">
                    <div class="gutter-box value-container">
                        <a-form-item :style="{ width: '100%'}">
                            <a-select v-model:value="modal_form.operation_id" 
                                style="width: 100%;"
                                :disabled="isHistory()"
                            >
                            <a-select-option v-for="option in operation_list" :key="option.id" :value="option.id">
                                {{ `${option.fullname} (${option.nickname})` }}
                            </a-select-option>
                            </a-select>
                        </a-form-item>
                    </div>
                </a-col>
            </a-row>
            <div v-if="!isHistory()" style="display: flex; width: 100%; justify-content: end; gap: 5px;">
                <a-form-item>
                    <a-button @click="on_submit(modal_form.id)" type="primary">ยืนยัน</a-button>
                </a-form-item>
                <a-form-item>
                    <a-button @click="close_modal(modal_form.id)" type="">ยกเลิก</a-button>
                </a-form-item>
            </div>
        </a-form>
    </a-modal>

    <a-modal v-model:visible="detail_modal_visible" 
         :title="รายละเอียดการแจ้งเบิก" 
         :closable="false" 
         footer
         :width="600">
        <a-form :model="modal_form">
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">ชื่อ-นามสกุลผู้ขับ:</p>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="18">
                    <div class="gutter-box value-container">
                        <p class="value-text">{{ modal_form.name }}</p>
                    </div>
                </a-col>
            </a-row>
            <a-row v-if="car_rental_a" class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">วันที่แจ้งเบิก:</p>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="18">
                    <div class="gutter-box value-container">
                        <p class="value-text">{{ modal_form.created_at }}</p>
                    </div>
                </a-col>
            </a-row>
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">วันที่ขอเบิก:</p>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="18">
                    <div class="gutter-box value-container">
                        <p class="value-text">{{ modal_form.time_end }}</p>
                    </div>
                </a-col>
            </a-row>
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">ประเภทการเบิก:</p>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="18">
                    <div class="gutter-box value-container">
                        <p class="value-text">{{ modal_form.pay_method_id }}</p>
                    </div>
                </a-col>
            </a-row>
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">จำนวนขอเบิก:</p>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="18">
                    <div class="gutter-box value-container">
                        <p class="value-text">{{ modal_form.amount }}</p>
                    </div>
                </a-col>
            </a-row>
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">ผลอนุมัติ:</p>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="18">
                    <div class="gutter-box value-container">
                        <p class="value-text">{{ modal_form.approve }}</p>
                    </div>
                </a-col>
            </a-row>
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">เหตุผลการขอเบิก:</p>
                    </div>
                </a-col>
            </a-row>
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="24">
                    <div class="gutter-box value-container">
                        <p class="textarea-like">{{ modal_form.remark }}</p>
                    </div>
                </a-col>
            </a-row>
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">ใบรูปหลักฐานการโอน:</p>
                    </div>
                </a-col>
            </a-row>
            <div v-if="modal_form.photo_1 != null && modal_form.photo_1 != ''" class="image-container">
                <img :src="modal_form.photo_1" class="uploaded-image-table" alt="uploaded image" />
                <div class="icon-history-container">
                    <span class="material-symbols-outlined detail-preview-icon" @click="handleIconClick('preview', modal_form.photo_1, $event)">
                        visibility
                    </span>
                    <span class="material-symbols-outlined detail-download-icon"
                        @click="getBase64Image($event, { url: modal_form.photo_1})">
                        download
                    </span>
                </div>
            </div>
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">หมายเหตุ:</p>
                    </div>
                </a-col>
            </a-row>
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="24">
                    <div class="gutter-box value-container">
                        <p class="textarea-like">{{ modal_form.approve_remark }}</p>
                    </div>
                </a-col>
            </a-row>
            <a-row v-if="car_rental_a" class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">ชื่อผู้อนุมัติการเบิก:</p>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="18">
                    <div class="gutter-box value-container">
                        <p class="value-text">{{ modal_form.fullname }}</p>
                    </div>
                </a-col>
            </a-row>
            <div style="display: flex; width: 100%; justify-content: end; gap: 5px;">
                <a-form-item>
                    <a-button @click="detail_modal_visible = false" type="">ปิด</a-button>
                </a-form-item>
            </div>
            <a-modal v-model:visible="previewVisible" :footer="null" :mask="false" @cancel="previewVisible = false"
                class="custom-modal" style="background-color: none !important">
                <img :src="previewImage" alt="Preview" style="width: 100%;" />
            </a-modal>
        </a-form>
    </a-modal>

</template>

<script>
import Swal from 'sweetalert2';
import dayjs from "dayjs";
const duration = require('dayjs/plugin/duration')
dayjs.extend(duration)
import SystemService from "../../api/SystemService";
import ClaimExpenseService from '../../api/ClaimExpenseService';
import UserService from '../../api/UserService';
import CompanyService from '../../api/CompanyService';
import OperationService from '../../api/OperationService';

export default ({
    name: 'claim',
    computed: {
        render_type() {
            return this.$route.name
        },
        
    },
  data() {
    return {
        search_input: null,
        datetime: null,
        car_rental_a: false,
        operation_list: [],
        data: [],
        history_data: [],
        claim_response: [],
        approve: null,
        modal_visible: false,
        detail_modal_visible: false,
        modal_form:{},
        claim_expense: {
            photo_1_show:[]
        },
        model_form: {
            key: null // Initialize with your appropriate key
        },
        loading: false,

        // image 
        previewImage: "",
        previewVisible: false,
    };
  },
  methods: {
    show_modal(id){
        const data = this.claim_response.find((item) => item.id == id);
        const operationdata = this.operation_list.find(item => item.id == data.operation_id)
        this.modal_form = {
            id: data.id,
            name: data.name,
            claim_date: [dayjs(data.time_start), dayjs(data.time_end)],
            pay_method_id: data.pay_method_id == 1 ? 'เงินสด' : 'เงินโอน',
            amount: data.amount,
            remark: data.remark,
            photo_1: data.photo_1,
            approve_remark: data.approve_remark,
            approve: data.approve == 0 ? 'รออนุมัติ' : data.approve == 1 ? 'อนุมัติ' : data.approve == 2 ? 'ไม่อนุมัติ' :'เลือกการอนุมัติ',
            time_end: dayjs(data.time_end),
            created_at: dayjs(data.created_at),
            operation_id: data.operation_id
        }
        this.modal_visible = true;
    },
    show_detail_modal(id){
        const data = this.history_data.find((item) => item.id == id);
        this.modal_form = {
            id: data.id,
            name: data.name,
            pay_method_id: data.pay_method_id == 1 ? 'เงินสด' : 'เงินโอน',
            amount: data.amount,
            remark: data.remark,
            photo_1: data.photo_1,
            approve_remark: data.approve_remark,
            approve: data.approve == 1 ? 'อนุมัติ' : 'ไม่อนุมัติ',
            created_at: dayjs(data.created_at).format("YYYY/MM/DD"),
            time_end: dayjs(data.time_end).format("YYYY/MM/DD"),
            fullname: data.fullname,
            nickname: data.nickname,
        }
        this.detail_modal_visible = true;
    },
    close_modal(id){
        this.modal_visible = false;
    },
    // image file 
    async getBase64(file, key) {
            if (key) {
                const vue = this
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    vue.model_form[key] = reader.result
                    vue.model_form[`${key}_file_name`] = file.name
                };
            } else {
                return await new Promise((resolve, reject) => {
                    if (file) {
                        if (typeof file == 'string') {
                            resolve(file)
                        } else {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = error => reject(error);
                        }
                    }else{
                      reject('error')
                    }
                });
            }
        },

    getUploadedUrl(file) {
        // ตรวจสอบว่าการอัปโหลดสำเร็จและมี URL หรือไม่
        if (file.response && file.response.url) {
            return file.response.url;
        }
        return file.url || URL.createObjectURL(file.originFileObj);
    },
    async getBase64ImageFromURL(url) {
        const res = await fetch(url , {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        });
        const blob = await res.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
            resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    },
    async getBase64Image(e, file) {
        e.stopPropagation();
        e.preventDefault();

        if(typeof file === 'string'){
            const result = await this.getBase64ImageFromURL(file);
            let a = document.createElement('a');
            a.href = result;
            a.download = 'image.png';
            a.click();
        }else if(file.url){
            const result = await this.getBase64ImageFromURL(file.url);
    
            // download
            let a = document.createElement('a');
            a.href = result;
            a.download = 'image.png';
            a.click();
        }else{
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => {
            const base64String = reader.result;
            let a = document.createElement('a');
            a.href = base64String;
            a.download = 'image.png';
            a.click();
            };
        }
    },
    handleIconClick(action, payload, event) {
        event.stopPropagation();
        if (action === 'preview') {
            this.showPreview(event , payload);
        } else if (action === 'delete') {
            // this.model_form[payload] = '';
            this.fileList = [];
        }
    },
    handleClickDeletePhoto(e , key , model_name , index = null , sub_index = null) {
        e.stopPropagation(); // ป้องกันการกระทำอื่นๆ ที่เกิดขึ้น
        if(model_name == 'claim_expense'){
            if(key == 'photo_1_show'){
                this.modal_form.photo_1 = null
            }
        }
    },
    upload_files(e, key , model_name = null , index = null , sub_index = null) {
        this.getBase64(e.file.originFileObj, key)
              
        if (model_name == 'claim_expense') {
            if (key == 'photo_1_show') {
                this.modal_form.photo_1 = this.model_form.photo_1_show
            }
        }

    },
    showPreview(e , fileUrl) {
        e.stopPropagation();
        this.previewImage = fileUrl;
        this.previewVisible = true;
    },
    handleDownload(file){
        this.previewImage = file.url || file.thumbUrl;
        window.open(this.previewImage);
    },

    async on_submit(id){
        
        // if(this.modal_form.claim_type == "เงินสด") this.modal_form.claim_type = 1
        // else if(this.modal_form.claim_type == "เงินโอน") this.modal_form.claim_type = 2
        console.log("adada",this.modal_form.approve)
        const approve = this.modal_form.approve === 'อนุมัติ' 
            ? 1 
            : this.modal_form.approve === 'ไม่อนุมัติ' 
            ? 2 
            : this.modal_form.approve;

        const data = {
            // time_start: this.modal_form.claim_date[0],
            // time_end: this.modal_form.claim_date[1],
            pay_method_id: this.modal_form.pay_method_id == 'เงินสด' ? 1 : this.modal_form.pay_method_id == 'เงินโอน' ? 2 : this.modal_form.pay_method_id,
            amount: this.modal_form.amount,
            approve_remark: this.modal_form.approve_remark ,
            operation_id: this.modal_form.operation_id,
            approve: this.modal_form.approve == 'รออนุมัติ' ? 0 : approve,
            photo_1: this.modal_form.photo_1,
        }
        this.loading = true
        await SystemService.update_all('ClaimExpense', { data: data }, id).then(() => {
            Swal.fire({
                icon: 'success',
                title: 'แก้ไขการแจ้งลาสำเร็จ',
                showConfirmButton: false,
                timer: 1000
            });
            this.get_claims();
        })
        this.loading = false
        this.modal_visible = false;
    },
    handleChange (id,value) {
        const index = this.data.findIndex((item) => item.id === id);
        this.data[index].approve = parseInt(value);
        const indexClaim = this.claim_response.findIndex((item) => item.id === id);
        this.claim_response[indexClaim].approve = parseInt(value);
    },
    handleChangeOperation (id,value) {
        const index = this.data.findIndex((item) => item.id === id);
        this.data[index].operation_id = parseInt(value);
        const indexClaim = this.claim_response.findIndex((item) => item.id === id);
        this.claim_response[indexClaim].operation_id = parseInt(value);

    },
    async send_approve(id) {
        const approve_data = this.data.find((item) => item.id === id);
        if(approve_data.approve === 0){
            Swal.fire({
                icon: 'error',
                title: 'กรุณาเลือกการอนุมัติ',
                showConfirmButton: false,
                timer: 1000
            });
        }else{
            // const driver = this.data.find((item) => item.id === id)
        console.log(approve_data)
            await SystemService.update_all('ClaimExpense', { data: {approve : approve_data.approve,approved_date : dayjs().format('YYYY-MM-DD HH:mm:ss'),operation_id : approve_data.operation_id} }, id).then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'ส่งแจ้งเตือนการอนุมัติสำเร็จ',
                    showConfirmButton: false,
                    timer: 1500
                });
            });
            this.get_claims();
        }
    },
    remove_claim(id) {
        Swal.fire({
            title: `คุณต้องการลบใช่หรือไม่?`,
            text: `คุณจะไม่สามารถกู้คืนได้`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `ใช่`,
            cancelButtonText: 'ยกเลิก'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await SystemService.delete_all('ClaimExpense', id).then(() => {
                    Swal.fire('ลบข้อมูลสำเร็จ', '', 'success');
                    this.get_claims();
                });
            } else if (result.isDenied) {
                Swal.fire('ข้อมูลไม่ถูกลบ', '', 'info');
            }
        });
    },
    edit_claim_type(value) {
      this.modal_form.approve = parseInt(value)
    },
    clear_search() {
      this.search_input = null;
      this.datetime = null;
      this.data = [];
      this.get_claims();
    },
    preview_close() {
      this.previewVisible = false;
    },
    filtered_table(array) {
            return array.filter((item) => {
                    if (this.search_input) {
                        return item.name.includes(this.search_input);
                    }
                    return true;
                })?.filter((item) => {
                    if (this.datetime) {
                        const [time_start, time_end] = this.datetime.map(item => dayjs(item).startOf('day').unix())
                        const start = dayjs(item.time_start).startOf('day').unix();
                        const end = dayjs(item.time_end).startOf('day').unix();
                        return end >= time_start && end <= time_end;  
                    }
                    return true;
                });
        
        
    },
    async get_claims() {
        this.data = [];
        this.history_data = [];
        this.operation_list = await OperationService.getOperationByCompany()
        this.claim_response = await ClaimExpenseService.getClaimExpense();
        const response = this.claim_response.filter((item) => item.approve == 0);
        for (let i = 0; i < response.length; i++) {
            this.data.push({
                id: response[i].id,
                driver_id: response[i].driver_id,
                name: response[i].driver.firstname + ' ' + response[i].driver.lastname,
                time_end: response[i].time_end,
                created_at: dayjs(response[i].created_at).format("YYYY/MM/DD"),
                claim_date: dayjs(response[i].time_end).format("YYYY/MM/DD"),
                amount: response[i].amount,
                pay_method_id: response[i].pay_method_id,
                approve: response[i].approve,
                remark: response[i].remark,
                photo_1: response[i].photo_1,
                approve_remark: response[i].approve_remark,
                operation_id: response[i].operation_id,
            });
        }
        const history = this.claim_response.filter((item) => item.approve != 0);
        for (let i = 0; i < history.length; i++) {
            this.history_data.push({
                id: history[i].id,
                driver_id: history[i].driver_id,
                name: history[i].driver.firstname + ' ' + history[i].driver.lastname,
                created_at: dayjs(history[i].created_at).format("YYYY/MM/DD"),
                time_end: history[i].time_end,
                claim_date: dayjs(history[i].time_end).format("YYYY/MM/DD"),
                amount: history[i].amount,
                pay_method_id: history[i].pay_method_id,
                approve: history[i].approve,
                remark: history[i].remark,
                photo_1: history[i].photo_1,
                operation_id: history[i].operation_id,
                approve_remark: history[i].approve_remark,
                fullname: history[i].operation?.fullname,
                nickname: history[i].operation?.nickname
            });
        }
    },
    isHistory() {
        if (this.render_type == 'history') {
            return true
        } else {
            return false
        }
    },
    goLeaveHistory() {
        this.$router.back();
        this.search_input = null;
        this.datetime = null;
        this.get_claims();
    },
    go_history(modelname) {
        this.$router.push(`/app/claim/history`)
        this.search_input = null;
        this.datetime = null;
        this.get_claims();
    },
    async get_businessType() {
        const company = await UserService.get_user_profile();
            const businessType = await CompanyService.getBusinessId(company.company_id)
            if (businessType.data === 4)
            {
                this.car_rental_a = true
            }
    },
  },
  mounted() {
    this.get_claims();
    this.get_businessType();
  },
});
</script>

<style scoped>
.upload-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.icon-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.label-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}
.value-container {
    display: flex;
    align-items: center;
    height: 100%;
}
.row-spacing {
    margin-bottom: 16px;
}
.uploaded-image-table {
    display: block;
    width: 10rem;
    height: auto;
}
.icon-history-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    padding: 5px;
}
.detail-preview-icon,
.detail-download-icon {
    cursor: pointer;
    font-size: 20px;
    color: black;
}
.label-text {
    margin: 0;
    padding-right: 1.5rem;
}
.value-text {
    /* background-color: #f5f5f5; */
    border: 1px solid #d9d9d9;
    color: #000000;
    padding: 4px 12px;
    border-radius: 16px;
    box-sizing: border-box;
    flex: 1;
    text-align: center;
    margin: 0;
}
.textarea-like {
    /* background-color: #f5f5f5; */
    border: 1px solid #d9d9d9;
    color: #000000;
    padding: 8px 12px;
    border-radius: 4px;
    min-height: 90px;
    line-height: 1.5;
    white-space: pre-wrap;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
}
.image-container {
    position: relative;
    display: inline-block;
    margin-bottom: 16px
}
</style>

