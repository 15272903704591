<template>
    <div style="padding: 15px;height: 100vh">
      <a-spin :spinning="loading">
        <div class="app-content">
        <a-card
          :title="
            render_type == 'create'
              ? 'เพิ่มค่าใช้จ่าย'
              : 'แก้ไขค่าใช้จ่าย'
          "
        >
          <template #extra>
            <a-button style="display: flex" @click="$router.go(-1)" size="large">
              <template #icon>
                <span class="material-symbols-outlined">arrow_back_ios</span>
              </template>
              ย้อนกลับ
            </a-button>
          </template>
        </a-card>
        <div style="margin: 8px 0">
          <a-form
            :model="create_expenses"
            name="create_expenses"
            autocomplete="off"
            layout="vertical"
            @finish="submit()"
          >
          
          <a-collapse style="border-radius: 4px;background-color: #e5e4e2;">
            <a-collapse-panel ref="clientItem" key="1" :header="'ข้อมูลค่าใช้จ่าย'">
              <template #extra>
                  <p
                    v-if="[create_expenses.expense_category_id,create_expenses.expense_detail,create_expenses.payment_type,create_expenses.payment_format,create_expenses.period_no,create_expenses.amount,create_expenses.tax,create_expenses.total_net,create_expenses.set_payment_date,create_expenses.payment_date,create_expenses.cotton,create_expenses.setter]?.filter((e) => e == null).length"
                    style="margin: 0; color: grey"
                  >
                  <!-- create_expenses.expense_category_id,create_expenses.expense_detail,create_expenses.payment_type,create_expenses.bank_name,create_expenses.branch_name,create_expenses.account_name,create_expenses.account_number,create_expenses.account_type,create_expenses.quantity_amount,create_expenses.payment_format,create_expenses.period_no,create_expenses.amount,create_expenses.tax,create_expenses.total_net,create_expenses.set_payment_date,create_expenses.payment_date,create_expenses.cotton,create_expenses.setter -->
                    {{
                      `(ต้องระบุอีก ${
                        [create_expenses.expense_category_id,create_expenses.expense_detail,create_expenses.payment_type,create_expenses.payment_format,create_expenses.period_no,create_expenses.amount,create_expenses.tax,create_expenses.total_net,create_expenses.set_payment_date,create_expenses.payment_date,create_expenses.cotton,create_expenses.setter].filter((e) => e == null).length
                      })`
                    }}
                  </p>
                </template>
              <a-divider orientation="left">ข้อมูลค่าใช้จ่าย</a-divider>
              <a-row :gutter="24">
                  <a-col :span="12">
                    <a-form-item label="หมวดหมู่ค่าใช้จ่าย" name="expense_category_id" :rules="[{required: true, message: 'โปรดเลือกหมวดหมู่ค่าใช้จ่าย'}]">
                    <a-select
                        v-model:value="create_expenses.expense_category_id"
                        placeholder="หมวดหมู่ค่าใช้จ่าย"
                        :disabled="fieldDisable"
                        :options="
                            expenseCategory.map(({ id, name }) => ({
                            value: id,
                            label: name,
                            }))
                        "
                    ></a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="12" style="margin-bottom: 15px;">
                <a-form-item label="รายละเอียดค่าใช้จ่าย" name="expense_detail" :rules="[{required: true, message: 'โปรดเลือกหมวดหมู่ค่าใช้จ่าย'}]">
                  <a-input
                    placeholder="รายละเอียดค่าใช้จ่าย"
                        :disabled="fieldDisable"
                    v-model:value="create_expenses.expense_detail"
                  ></a-input>
                </a-form-item>
            </a-col>
            <a-col :span="24" style="margin-bottom: 15px;">
                <a-form-item label="รหัสโครงการ" name="project_code">
                  <a-input
                    placeholder="รหัสโครงการ"
                        :disabled="fieldDisable"
                        v-model:value="create_expenses.project_code"
                  ></a-input>
                </a-form-item>
            </a-col>
            </a-row>
            <a-divider orientation="left">รายละเอียดการชำระเงิน</a-divider>
            <a-row :gutter="24">
              <a-col :span="24" style="margin-bottom: 15px;">
                <a-form-item 
                    name="payment_type" 
                    :rules="[{ required: true, message: 'โปรดเลือกตามระยะทาง/เรทราคา' }]"
                    validateTrigger="onSubmit"
                  >
                    <span style="color: red; margin-right: 4px;">*</span>
                    <span>ประเภทการชำระเงิน :</span>
                  <a-radio-group v-model:value="create_expenses.payment_type">
                    <a-radio 
                      :checked="distance_price_rate === 'distance'"
                      @click="toggleOption(1)"
                      value="โอน"
                        :disabled="fieldDisable"
                        style="margin-left: 8px;"
                    >
                      <span>โอน</span>
                    </a-radio>

                    <a-radio 
                      :checked="distance_price_rate === 'price'"
                      @click="toggleOption(2)"
                      value="เงินสด/เช็ค"
                        :disabled="fieldDisable"
                        style="margin-left: 8px;"
                    >
                      <span>เงินสด/เช็ค</span>
                    </a-radio>
                    </a-radio-group>
                  </a-form-item>
              </a-col>
              <a-col v-if='transfer' :span="12" style="margin-bottom: 15px;">
                <a-form-item label="ชื่อธนาคาร" name="bank_name" :rules="[{required: true, message: 'โปรดเลือกชื่อธนาคาร'}]">
                    <a-select
                        v-model:value="create_expenses.bank_name"
                        :disabled="fieldDisable"
                        :options="
                            bankList.data.map(({ id, name }) => ({
                            value: name,
                            label: name,
                            }))
                        "
                    ></a-select>
                </a-form-item>
            </a-col>
            
            <a-col v-if='transfer' :span="12" style="margin-bottom: 15px;">
                <a-form-item label="ชื่อสาขาของธนาคาร" name="branch_bank" :rules="[{required: true, message: 'โปรดเลือกชื่อสาขาของธนาคาร'}]">
                    <a-input
                    placeholder="สาขาของธนาคาร"
                        :disabled="fieldDisable"
                    v-model:value="create_expenses.branch_bank"
                  ></a-input>
                </a-form-item>
            </a-col>
            <a-col v-if='transfer' :span="12" style="margin-bottom: 15px;">
                <a-form-item label="ชื่อบัญชี" name="account_name" :rules="[{required: true, message: 'โปรดเลือกชื่อบัญชี'}]">
                    <a-input
                    placeholder="ชื่อบัญชี"
                        :disabled="fieldDisable"
                        v-model:value="create_expenses.account_name"
                  ></a-input>
                </a-form-item>
            </a-col>
            <a-col v-if='transfer' :span="12" style="margin-bottom: 15px;">
                <a-form-item label="เลขที่บัญชี" name="account_number" :rules="[{required: true, message: 'โปรดเลือกเลขที่บัญชี'}]">
                    <a-input
                    placeholder="เลขที่บัญชี"
                        :disabled="fieldDisable"
                    v-model:value="create_expenses.account_number"
                  ></a-input>
                </a-form-item>
            </a-col>
            <a-col v-if='transfer' :span="24" style="margin-bottom: 15px;">
                <a-form-item label="ประเภทบัญชี" name="account_type" :rules="[{required: true, message: 'โปรดเลือกประเภทบัญชี'}]">
                    <a-select
                        v-model:value="create_expenses.account_type"
                        placeholder="ประเภทบัญชี"
                        :disabled="fieldDisable"
                        :options="
                            accountTypes.map(({ id, label }) => ({
                            value: label,
                            label: label,
                            }))
                        "
                    ></a-select>
                </a-form-item>
            </a-col>
            <a-col v-if='fresh' :span="24" style="margin-bottom: 15px;">
                <a-form-item label="จำนวนเงินสด/เช็ค" name="quantity_amount" :rules="[{required: true, message: 'โปรดเลือกจำนวนเงินสด/เช็ค'}]">
                    <a-input
                    placeholder="จำนวนเงินสด/เช็ค"
                        :disabled="fieldDisable"
                        v-model:value="create_expenses.quantity_amount"
                  ></a-input>
                </a-form-item>
            </a-col>
              <a-col :span="24" style="margin-bottom: 15px;">
                <a-form-item label="รูปแบบการชำระเงิน" name="payment_format" :rules="[{required: true, message: 'โปรดเลือกรูปแบบการชำระเงิน'}]">
                    <a-select
                        v-model:value="create_expenses.payment_format"
                        placeholder="เลือกรูปแบบการชำระเงิน"
                        :disabled="fieldDisable"
                        :options="
                            lubbab.map(({ id, name }) => ({
                            value: name,
                            label: name,
                            }))
                        "
                    ></a-select>
                </a-form-item>
            </a-col>
            <a-col :span="12" style="margin-bottom: 15px;">
                <a-form-item label="งวดที่" name="period_no" :rules="[{required: true, message: 'โปรดเลือกงวดที่'}]">
                  <a-input
                    placeholder="งวดที่"
                        :disabled="fieldDisable"
                        v-model:value="create_expenses.period_no"
                  ></a-input>
                </a-form-item>
            </a-col>
            <a-col :span="12" style="margin-bottom: 15px;">
                <a-form-item label="จำนวนเงิน" name="amount" :rules="[{required: true, message: 'โปรดเลือกจำนวนเงิน'}]">
                  <a-input
                    placeholder="จำนวนเงิน"
                    @change="actionNet()"
                    type="number"
                        :disabled="fieldDisable"
                        v-model:value="create_expenses.amount"
                  ></a-input>
                </a-form-item>
            </a-col>
            <a-col :span="12" style="margin-bottom: 15px;">
                <a-form-item label="ภาษี" name="tax" :rules="[{required: true, message: 'โปรดเลือกภาษี'}]">
                    <a-select
                        v-model:value="create_expenses.tax"
                        placeholder="เลือกภาษี"
                        @change="actionNet()"
                        :disabled="fieldDisable"
                        :options="
                            pasee.map(({ id, name }) => ({
                            value: name,
                            label: name,
                            }))
                        "
                    ></a-select>
                </a-form-item>
            </a-col>
            <a-col :span="12" style="margin-bottom: 15px;">
                <a-form-item label="ยอดรวมสุทธิ" name="total_net" :rules="[{required: true, message: 'โปรดเลือกยอดรวมสุทธิ'}]">
                  <a-input
                    placeholder="ยอดรวมสุทธิ"
                    :disabled="true"
                    v-model:value="create_expenses.total_net"
                  ></a-input>
                </a-form-item>
            </a-col>
            <a-col :span="12" style="margin-bottom: 15px;">
                <a-form-item label="วันที่ตั้งจ่าย" name="set_payment_date" :rules="[{required: true, message: 'โปรดเลือกวันที่ตั้งจ่าย'}]">
                    <a-date-picker
                        :disabled="fieldDisable"
                        v-model:value="create_expenses.set_payment_date"
                    format="YYYY-MM-DD"
                    :placeholder="'วันที่ตั้งจ่าย'"
                    :minuteStep="1"
                    style="width: 100%;"
                  />
                </a-form-item>
            </a-col>
            <a-col :span="12" style="margin-bottom: 15px;">
                <a-form-item label="วันที่จ่าย" name="payment_date" :rules="[{required: true, message: 'โปรดเลือกวันที่จ่าย'}]">
                    <a-date-picker
                        :disabled="fieldDisable"
                        v-model:value="create_expenses.payment_date"
                    format="YYYY-MM-DD"
                    :placeholder="'วันที่จ่าย'"
                    :minuteStep="1"
                    style="width: 100%;"
                  />
                </a-form-item>
            </a-col>
            <a-col :span="24" style="margin-bottom: 15px;">
                <a-form-item label="ผู้ขายร้านค้า" name="shop_sell">
                  <a-input
                    placeholder="ผู้ขายร้านค้า"
                        :disabled="fieldDisable"
                        v-model:value="create_expenses.shop_sell"
                  ></a-input>
                </a-form-item>
            </a-col>
            <a-col :span="12" style="margin-bottom: 15px;">
                <a-form-item label="ฝ่าย" name="cotton" :rules="[{required: true, message: 'โปรดเลือกฝ่าย'}]">
                  <a-select
                        v-model:value="create_expenses.cotton"
                        placeholder="ฝ่าย"
                        :disabled="fieldDisable"
                        :options="
                            cuttonName.map(({ id, name }) => ({
                            value: name,
                            label: name,
                            }))
                        "
                    ></a-select>
                </a-form-item>
            </a-col>
            <a-col :span="12" style="margin-bottom: 15px;">
                <a-form-item label="ผู้ตั้งเบิก" name="setter" :rules="[{required: true, message: 'โปรดเลือกผู้ตั้งเบิก'}]">
                  <a-select
                        v-model:value="create_expenses.setter"
                        placeholder="ผู้ตั้งเบิก"
                        :disabled="fieldDisable"
                        :options="
                            administrativeAndAccouting.map(({ id, fullname }) => ({
                            value: fullname,
                            label: fullname,
                            }))
                        "
                    ></a-select>
                </a-form-item>
            </a-col>
            </a-row>
            </a-collapse-panel>
            <a-collapse-panel v-if="render_type != 'create'" key="2" header="ข้อมูลการอนุมัติและเอกสารต่างๆ">
                <template #extra>
                  <p
                    v-if="[create_expenses.status_approve,create_expenses.approver]?.filter((e) => e == null).length"
                    style="margin: 0; color: grey"
                  >
                    {{
                      `(ต้องระบุอีก ${
                        [create_expenses.status_approve,create_expenses.approver].filter((e) => e == null).length
                      })`
                    }}
                  </p>
                </template>
              <a-divider orientation="left">ข้อมูลการอนุมัติ</a-divider>
              <a-row :gutter="24">
                <a-col :span="12" style="margin-bottom: 15px;">
                <a-form-item label="สถานะอนุมัติ" name="status_approve" :rules="[{required: true, message: 'โปรดเลือกสถานะอนุมัติ'}]">
                    <a-select
                        v-model:value="create_expenses.status_approve"
                        placeholder="เลือกสถานะอนุมัติ"
                        :disabled="fieldDisable"
                        @change="actionAprrove()"
                        :options="
                            approveList.map(({ id, name }) => ({
                            value: id,
                            label: name,
                            }))
                        "
                    ></a-select>
                </a-form-item>
            </a-col>
            <a-col :span="12" style="margin-bottom: 15px;">
                <a-form-item label="ผู้อนุมัติ" name="approver" :rules="[{required: true, message: 'โปรดเลือกผู้อนุมัติ'}]">
                  <a-select
                        v-model:value="create_expenses.approver"
                        :disabled="fieldDisable"
                        placeholder="ผู้อนุมัติ"
                        :options="
                            administrativeAndAccouting.map(({ id, fullname }) => ({
                            value: fullname,
                            label: fullname,
                            }))
                        "
                    ></a-select>
                </a-form-item>
            </a-col>
                </a-row>
                <div v-if="showApprove">
                    <a-divider orientation="left">ไฟล์แนบ</a-divider>
              <a-row :gutter="24">
                <a-col :span="24" style="margin-bottom: 15px;">
                    <a-table
                            :columns="[
                                {
                                    title: 'ชื่อเอกสาร',
                                    dataIndex: 'name',
                                    key: 'name',
                                    width: 200,
                                },
                                {
                                    title: 'ไฟล์เอกสาร',
                                    dataIndex: 'file',
                                    width: 100,
                                    key: 'file',
                                },
                                ...(!fieldDisable
                              ? [
                                  {
                                    title: 'ลบ',
                                    dataIndex: 'id',
                                    key: 'id',
                                    width: 100,
                                    align: 'center',
                                  }
                                ]
                              : []
                            )
                            ]"
                            :dataSource="data_file_destination"
                            :pagination="false"
                            :scroll="{ y: 240 }"
                        >
                            <template #bodyCell="{ column, record, index}" >
                                <template v-if="column.key === 'name'">
                                    <a-form-item>
                                        <a-input v-model:value="record.name"
                                        :disabled="fieldDisable"
                                        />
                                    </a-form-item>
                                </template>
                                <template v-if="column.key === 'file'">
                                    <a-form-item>
                                        <a :href="record.file" target="_blank" rel="doc"> 
                                            {{ record.file_name }}
                                        </a>
                                    </a-form-item>
                                </template>
                                <template v-if="column.key === 'id'">
                                    <a-form-item>
                                        <a-button danger type="text" @click="(data_file_destination.splice(index, 1))">
                                            <span class="material-symbols-outlined">delete</span>
                                        </a-button>
                                    </a-form-item>
                                </template>
                            </template>
                        </a-table>
                        <a-upload 
                            v-model:file-list="fileList" 
                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                            method="GET"
                            :show-upload-list="false"
                            @change="upload_file_multiple($event, 'data_file_destination')"
                        >
                            <a-button style="margin-top: 10px;" :disabled="fieldDisable">
                                <span class="material-symbols-outlined">add</span>
                                เพิ่มไฟล์
                            </a-button>
                        </a-upload>
                    </a-col>
                <a-col :span="12" style="margin-bottom: 15px;">
                <a-form-item label="หมายเหตุ" name="remark">
                  <a-input
                    placeholder="หมายเหตุ"
                    :disabled="fieldDisable"
                    v-model:value="create_expenses.remark"
                  ></a-input>
                </a-form-item>
            </a-col>
            <a-col :span="12" style="margin-bottom: 15px;">
                <a-form-item label="วันที่จ่ายจริง" name="real_payment_date">
                    <a-date-picker
                    v-model:value="create_expenses.real_payment_date"
                    format="YYYY-MM-DD"
                    :disabled="fieldDisable"
                    :placeholder="'วันที่จ่ายจริง'"
                    :minuteStep="1"
                    style="width: 100%;"
                  />
                </a-form-item>
            </a-col>
              </a-row>
              <a-divider orientation="left">เลขที่เอกสารอ้างอิง/ใบเสร็จรับเงิน</a-divider>
              <a-row :gutter="24">
                <a-col :span="24" style="margin-bottom: 15px;">
                    <a-table
                            :columns="[
                                {
                                    title: 'ชื่อเอกสาร',
                                    dataIndex: 'name',
                                    key: 'name',
                                    width: 200,
                                },
                                {
                                    title: 'ไฟล์เอกสาร',
                                    dataIndex: 'file',
                                    width: 100,
                                    key: 'file',
                                },
                                ...(!fieldDisable
                              ? [
                                  {
                                    title: 'ลบ',
                                    dataIndex: 'id',
                                    key: 'id',
                                    width: 100,
                                    align: 'center',
                                  }
                                ]
                              : []
                            )
                            ]"
                            :dataSource="data_document_destination"
                            :pagination="false"
                            :scroll="{ y: 240 }"
                        >
                            <template #bodyCell="{ column, record, index}" >
                                <template v-if="column.key === 'name'">
                                    <a-form-item>
                                        <a-input 
                                        :disabled="fieldDisable"
                                        v-model:value="record.name"
                                        />
                                    </a-form-item>
                                </template>
                                <template v-if="column.key === 'file'">
                                    <a-form-item>
                                        <a :href="record.file" target="_blank" rel="doc"> 
                                            {{ record.file_name }}
                                        </a>
                                    </a-form-item>
                                </template>
                                <template v-if="column.key === 'id'">
                                    <a-form-item>
                                        <a-button danger type="text" @click="(data_document_destination.splice(index, 1))">
                                            <span class="material-symbols-outlined">delete</span>
                                        </a-button>
                                    </a-form-item>
                                </template>
                            </template>
                        </a-table>
                        <a-upload 
                            v-model:file-list="fileList" 
                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                            method="GET"
                            :show-upload-list="false"
                        
                        @change="upload_file_multiple($event, 'data_document_destination')"
                        >
                            <a-button style="margin-top: 10px;" :disabled="fieldDisable">
                                <span class="material-symbols-outlined">add</span>
                                เพิ่มไฟล์
                            </a-button>
                        </a-upload>
                    </a-col>
                    </a-row>
                </div>
            </a-collapse-panel>
            </a-collapse>
            <a-row justify="end">
              <a-button
                html-type="submit"
                type="primary"
                size="large"
                style="margin: 4px; text-align: right"
              >
                <span
                  class="material-symbols-outlined"
                  style="font-size: 16px; margin-right: 4px"
                  >Edit_Square</span
                >
                {{render_type == 'add' ? 'สร้างใบงาน':'บันทึกข้อมูล'}}
              </a-button>
            </a-row>
                    </a-form>
                </div>
            </div>
        </a-spin>
    </div>
</template>
<script>
import Swal from 'sweetalert2'
import dayjs from 'dayjs';
import CompanyExpenseService from '../../api/CompanyExpenseService';
import ExpenseService from '../../api/ExpenseService';
import BankService from '../../api/BankService';


export default {
  name: 'expenses_create_edit',
  computed: {
    render_type() {
        return this.$route.params?.type;
      },
      
  },
    data() {
      return {
        create_expenses: {},
        loading: false,
        transfer: false,
        fresh: false,
        showApprove: false,
        fieldDisable: false,
        expenseCategory: [],
        administrativeAndAccouting: [],
        data_file_destination:[],
        data_document_destination:[],
        bankList: [],
        cuttonName:[
          {
            id: 1,
            name: "ฝ่ายบริหาร"
          },
          {
            id: 2,
            name: "ฝ่ายปฏิบัติการ"
          },
          {
            id: 3,
            name: "ฝ่ายสนับสนุนองค์กร"
          }
        ],
        pasee: [
        {
          id: 1,
          name: "VAT 7%",
        },
        {
          id: 2,
          name: "หัก ณ ที่จ่าย",
        },
        {
          id: 3,
          name: "ไม่มี",
        },
      ],
      lubbab: [
        {
          id: 1,
          name: "แบ่งจ่ายเป็นงวด",
        },
        {
          id: 2,
          name: "จ่ายเต็มจำนวน",
        },
      ],
      approveList: [
        {
          id: 1,
          name: "รอดำเนินการ",
        },
        {
          id: 2,
          name: "อนุมัติ",
        },
        {
          id: 3,
          name: "ไม่อนุมัติ",
        },
      ],
      accountTypes: [
          { value: 1, label: "บัญชีเงินฝากออมทรัพย์" },
          { value: 2, label: "บัญชีเงินฝากประจำ" },
          { value: 3, label: "บัญชีเงินฝากประจำรายเดือนแบบปลอดภาษี" },
          { value: 4, label: "บัญชีเงินฝากกระแสรายวัน" },
          { value: 5, label: "บัญชีเงินตราระหว่างประเทศ" },
          { value: 6, label: "บัญชีเงินฝากแบบขั้นบันได" },
      ],
      }
    },
    watch: {
    //   $route(to, from) {
    //     if (this.render_type != 'all') {
    //       this.init_model();
    //     } else if(this.render_type == 'all') {
    //       this.init_data();
    //     }
    //   },
    },
    methods: {
        toggleOption(id) {
            if(id === 1) {
                this.transfer = true
                this.fresh = false
            } else {
                this.transfer = false
                this.fresh = true
            }
        },
        async upload_file_multiple(e, key) {
            if (e.file.status != 'remove') {
                var file = e.file.originFileObj
                if (key) {
                        const vue = this
                        if (!vue[key]) {
                            vue[key] = [];
                        }
                        var reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function () {
                            if(vue[key].map(e => e.file_name).includes(file.name)) return
                            vue[key].push({
                                file_name: file.name,
                                name: '',
                                file: reader.result,
                            })
                        };
                } else {
                    return await new Promise((resolve, reject) => {
                        if (file) {
                            if (typeof file == 'string') {
                                resolve(file)
                            } else {
                                const reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = () => resolve(reader.result);
                                reader.onerror = error => reject(error);
                            }
                        }
                    });
                }
                return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`
            }
        },
        async actionAprrove() {
            if(this.create_expenses.status_approve === 2) {
                this.showApprove = true
            } else {
                this.showApprove = false
                this.data_document_destination = []
                this.data_file_destination = []
                this.create_expenses.remark = null
                this.create_expenses.real_payment_date = null
            }
        },
        safeParseJSON(jsonString) {
            try {
                return jsonString ? JSON.parse(jsonString) : null;
            } catch (error) {
                console.error('Error parsing JSON:', jsonString, error);
                return null;
            }
        },
        actionNet() {
          const amount = parseFloat(this.create_expenses.amount) || 0;
          let total = amount;

          if (this.create_expenses.tax === "VAT 7%") {
            total = amount - (amount * 0.07);
          } else if (this.create_expenses.tax === "หัก ณ ที่จ่าย") {
            total = amount - (amount * 0.01);
          }

          this.create_expenses.total_net = total.toString();
        },
        async submit(){
            const data = JSON.parse(JSON.stringify(this.create_expenses));
            const { password, ...newData } = data;
            const lastestData = (password === this.oldPassword || password === null) ? newData : data
            const result = { ...lastestData, 
              data_file_destination: (this.data_file_destination !== null) ? JSON.stringify(this.data_file_destination) : null,
              data_document_destination: (this.data_document_destination !== null) ? JSON.stringify(this.data_document_destination) : null,
            }
          if (this.render_type == 'create') {
          if(this.create_expenses.modelname){
            delete this.create_expenses.modelname
            delete this.create_expenses.id
          }
          this.create_expenses.payment_status = 4
          await CompanyExpenseService.createCompanyExpenses(this.create_expenses).then((res) => {
          if(res) {
            Swal.fire({
              icon: "success",
              title: "บันทึกข้อมูลสำเร็จ",
              showConfirmButton: false,
              timer: 1500,
            });
          this.model_qulity = false
          this.$router.push(`/app/expenses/all`)
            // this.init_data()
          }
        })
        } else {
          await CompanyExpenseService.updateCompanyExpenses(this.render_type,result).then((res) => {
          if(res) {
            Swal.fire({
              icon: "success",
              title: "บันทึกข้อมูลสำเร็จ",
              showConfirmButton: false,
              timer: 1500,
            });
            // this.init_data()
            this.$router.push(`/app/expenses/all`);
          }
        })
        }},
        async init_model() {
          this.expenseCategory = await CompanyExpenseService.getExpenseCategoryAll()
          this.administrativeAndAccouting = await CompanyExpenseService.getAdministrativeAndAccoutingAll()
          this.bankList = await BankService.getAll()
          if(this.render_type != "create") {
            this.init_data()
          }
        },
        async init_data() {
          const data = await CompanyExpenseService.getCompanyExpensesById(this.render_type)
          
          data.set_payment_date = dayjs(data.set_payment_date);
          data.payment_date = dayjs(data.payment_date);
          if (data.real_payment_date) {
            data.real_payment_date = dayjs(data.real_payment_date);
          }
          if (data.payment_type === 'โอน') {
            this.transfer = true
          } else {
            this.fresh = true
          }
          if (data.status_approve === 2) {
            this.showApprove = true
          }
          if ((data.status_approve === 3 && data.payment_status === 0) || (data.status_approve === 2 && data.payment_status === 1)) {
            this.fieldDisable = true
          }
            data.data_file_destination = this.safeParseJSON(data.data_file_destination);
            this.data_file_destination = data.data_file_destination
            data.data_document_destination = this.safeParseJSON(data.data_document_destination);
            this.data_document_destination = data.data_document_destination
            this.create_expenses = {...data}
        }
    },
    async mounted() {
    //   await this.get_car_data();
        await this.init_model();
    //   } else if(this.render_type == 'all') {
    //     await this.init_data();
    //   }
    //   await this.get_company();
    },
}
</script>

<style scoped>
/* .ant-form-item-control-input {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 0px;
} */
.disabled {
  pointer-events: none;
  color: gray;
  cursor: not-allowed;
}
.uploaded-image {
  width: 100%;
  object-fit: cover;
  transition: filter 0.3s ease;
  cursor: pointer;
}
.uploaded-image-table {
  width: 50px;
  height: 50px;
  transition: filter 0.3s ease;
  cursor: pointer;
}
.upload-container:hover .uploaded-image-table {
  filter: grayscale(100%);
}
.upload-container:hover .uploaded-image {
  filter: grayscale(100%);
}
</style>