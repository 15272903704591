<template>
    <div style="padding: 15px;height: 100vh">
        <div v-if="render_type != 'edit_user_admin'" class="app-content">
            <a-spin :spinning="loading">
                <a-card :title="title">
                    <template #extra>
                        <a-space>
                            <router-link :to="`/app/leaveAdmin/history`">
                                <a-button v-if="button_title" style="display: flex;"
                                    type="primary" size="large">
                                    {{ button_title }}
                                </a-button>
                            </router-link>
                        </a-space>
                    </template>

                    <a-row>
                        <a-space>
                            <a-date-picker style="width: 150px;" placeholder="Start Date" v-model:value="timeStart" format="DD/MM/YYYY" />
                            <span class="material-symbols-outlined icon" style="color: rgba(0, 0, 0, 0.25);">East</span>
                            <a-date-picker style="width: 150px;" placeholder="End Date" v-model:value="timeEnd" format="DD/MM/YYYY" />
                        </a-space>
                        <a-button @click="clear_search" type="dashed" style="margin-left: 1rem">CLEAR</a-button>
                    </a-row>

                    <br>
                    <a-row>
                        <a-table :columns="filteredColumns" :data-source="filtered_list(leave_progress1)" bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                            <template #bodyCell="{ column, record }">
                                <template v-if="column.key === 'fullname'">
                                    <div> {{ record.fullname }} </div>
                                </template>
                                <template v-if="column.key === 'leave_user_timestamp'">
                                    <div> {{ render_date(record.leave_user_timestamp) }} </div>
                                </template>
                                <template v-if="column.key === 'leave_user_date'">
                                    <div> {{ render_date(record.time_start) }} - {{ render_date(record.time_end) }} </div>
                                </template>
                                <template v-if="column.key === 'leave_user_no'">
                                    <div> {{record.leave_user_no}} </div>
                                </template>
                                <template v-if="column.key === 'leave_type_id'">
                                    <div> {{ getLeaveTypeName(record.leave_type_id) }} </div>
                                </template>
                                <template v-if="column.key === 'leave_pattern_id'">
                                    <div> {{ getLeavePatternTypeName(record.leave_pattern_id) }} </div>
                                </template>
                                <template v-if="column.key === 'leave_user_amount'">
                                    <div> {{record.leave_user_amount}} </div>
                                </template>
                                <template v-if="column.key === 'approve_status'">
                                    <a-select style="width: 120px;" placeholder="การอนุมัติ" v-model:value="record.approve_status"
                                        @change="(value) => handleChangeApproveStatus(record.id, value)">
                                        <a-select-option value=1>อนุมัติ</a-select-option>
                                        <a-select-option value=2>ไม่อนุมัติ</a-select-option>
                                    </a-select>
                                </template>
                                <template v-if="column.key === 'approver'">
                                    <a-select style="width: 180px;" placeholder="ชื่อผู้อนุมัติ" v-model:value="record.approver"
                                        @change="(value) => handleChangeApprover(record.id, value)">
                                        <a-select-option
                                            v-for="({ operation_id, fullname }, i) in operations"
                                            :key="i"
                                            :value="fullname"
                                            >
                                            {{ fullname }}
                                        </a-select-option>
                                    </a-select>
                                </template>
                                <template v-if="column.key === 'id'">
                                    <div>
                                        <a @click="handleApprove(record.id)"><span class="material-symbols-outlined">
                                                Send
                                            </span>
                                        </a>
                                        <a-divider type="vertical"></a-divider>
                                        <a><span style="font-size: 14px" class="material-symbols-outlined" @click="showLeaveDetails(record)">Visibility</span></a>
                                        <a-divider type="vertical"></a-divider>
                                        <a @click="remove_leave_user(record.id)">
                                            <span class="material-symbols-outlined">
                                                delete
                                            </span>
                                        </a>
                                    </div>
                                </template>
                            </template>
                        </a-table>
                    </a-row>
                    <a-modal v-model:visible="showModalLeaveUserData" @ok="showModalLeaveUserData=false" >
                        <div style="display: flex; flex-direction: column ;justify-items:start;align-items: start">
                            <a-form-item label="ชื่อ-นามสกุล ผู้ส่งคำขอ" name="leave_user_no" style="width: 98%; margin-top: 5%;">
                                <a-input :value="selectedLeave.fullname" placeholder="ชื่อ-นามสกุล ผู้ส่งคำขอ"></a-input>
                            </a-form-item>
                            <a-form-item label="เลขกำกับใบลา" name="leave_user_no" style="width: 98%;">
                                <a-input :value="selectedLeave.leave_user_no" placeholder="เลขกำกับใบลา"></a-input>
                            </a-form-item>
                            <a-form-item label="วันที่แจ้งลา" name="leave_user_no" style="width: 98%;">
                                <a-input :value="render_date(selectedLeave.leave_user_timestamp)" placeholder="วันที่แจ้งลา"></a-input>
                            </a-form-item>
                            <a-form-item label="วันที่ลาเริ่มต้น" name="leave_user_no" style="width: 98%;">
                                <a-input :value="render_date(selectedLeave.time_start)" placeholder="วันที่ลาเริ่มต้น"></a-input>
                            </a-form-item>
                            <a-form-item label="วันที่ลาสิ้นสุด" name="leave_user_no" style="width: 98%;">
                                <a-input :value="render_date(selectedLeave.time_end)" placeholder="วันที่ลาสิ้นสุด"></a-input>
                            </a-form-item>
                            <a-form-item label="จำนวนวันลา" name="leave_user_no" style="width: 98%;">
                                <a-input :value="selectedLeave.leave_user_amount" placeholder="จำนวนวันลา"></a-input>
                            </a-form-item>
                            <a-form-item label="ประเภทวันลา" name="leave_user_no" style="width: 98%;">
                                <a-input :value="getLeaveTypeName(selectedLeave.leave_type_id)" placeholder="ประเภทวันลา"></a-input>
                            </a-form-item>
                            <a-form-item label="การอนุมัติ" name="leave_user_no" style="width: 98%;">
                                <!-- <a-input :value="getApproveStatusName(selectedLeave.approve_status)" placeholder="การอนุมัติ"></a-input> -->
                                <a-select style="width: 100%;" placeholder="การอนุมัติ" v-model:value="selectedLeave.approve_status"
                                        @change="(value) => handleChangeApproveStatus(selectedLeave.id, value)">
                                        <a-select-option value=1>อนุมัติ</a-select-option>
                                        <a-select-option value=2>ไม่อนุมัติ</a-select-option>
                                    </a-select>
                            </a-form-item>
                            <a-form-item v-if="business" label="โควตาการลา" name="leave_quota" style="width: 98%;">
                                <a-input :value="selectedLeave.leave_quota" placeholder="โควตาการลา" disabled></a-input>
                            </a-form-item>
                            <a-form-item v-if="this.businessTypeId == 2 || this.businessTypeId == 3" label="รูปแบบการลา" name="leave_pattern_id" style="width: 98%;">
                                <a-input :value="getLeavePatternTypeName(selectedLeave.leave_pattern_id)" placeholder="รูปแบบการลา" disabled></a-input>
                            </a-form-item>
                            <p>เอกสารที่เกี่ยวข้อง:</p>
                            <div v-if="selectedLeave.photo_1 != null && selectedLeave.photo_1 != ''" class="image-container" style="margin-top: -5px; margin-bottom: 15px;">
                                <img :src="selectedLeave.photo_1" class="uploaded-image-table" alt="uploaded image" />
                                <div class="icon-container">
                                    <span class="material-symbols-outlined preview-icon" @click="showPreview($event, selectedLeave.photo_1)">
                                        visibility
                                    </span>
                                    <span class="material-symbols-outlined download-icon"
                                        @click="getBase64Image($event, selectedLeave.photo_1)">
                                        download
                                    </span>
                                </div>
                            </div>
                            <a-form-item label="หมายเหตุ" name="leave_user_remark" style="width: 98%;">
                                <a-textarea :value="selectedLeave.leave_user_remark" placeholder="หมายเหตุ" :rows="3"></a-textarea>
                            </a-form-item>
                            <a-form-item label="ชื่อผู้อนุมัติการลา" name="approver" style="width: 98%; margin-top: 6%;">
                                <a-select style="width: 100%;" placeholder="ชื่อผู้อนุมัติ" v-model:value="selectedLeave.approver"
                                    @change="(value) => handleChangeApprover(selectedLeave.id, value)">
                                    <a-select-option
                                        v-for="({ operation_id, fullname }, i) in operations"
                                        :key="i"
                                        :value="fullname"
                                        >
                                        {{ fullname }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </div>
                        <template #footer>
                            <a-button type="primary" @click="showModalLeaveUserData=false">
                                ปิด
                            </a-button>
                        </template> 
                    </a-modal> 
                    <a-modal v-model:visible="previewVisible" :footer="null" :mask="false" @cancel="previewVisible = false"
                        class="custom-modal" style="background-color: none !important">
                        <img :src="previewImage" alt="Preview" style="width: 100%;" />
                    </a-modal>
                </a-card>
            </a-spin>
        </div>
    </div>
</template>

<script>
import Utility from '../../utility'
import Swal from 'sweetalert2'
import SystemService from '../../api/SystemService'
import AdminService from '../../api/AdminService';
import LeaveUserService from '../../api/LeaveUserService';
import dayjs from 'dayjs'
import CompanyService from '../../api/CompanyService'
import UserService from '../../api/UserService';
import LeaveService from '../../api/LeaveService';

export default {
    name: 'LeaveAdmin',
    data() {
        return {
            previewImage: "",
            previewVisible: false,
            businessTypeId:null,
            leave_user: [],
            leave_progress1: [],
            selectedLeave: {},
            showModalLeaveUserData: false,
            timeStart: null,
            timeEnd: null,
            leave_types: [],
            loading: false,
            search_business_type: null,
            business: false,
            leave_pattern_list:[],
            leave_quota_data: [], // ดึงข้อมูลโควตาจาก API
            img_placeholder: require('@/assets/images/place-holder/placeholder.jpg'),
            show_table_list: [],
            leave_types: [],
            operations: [],
            fileList: [],
            title: 'จัดการคำขอลา',
            button_title: 'ประวัติการแจ้งลา',
            input: 'Username / ชื่อบริษัท',
            search_input: null,
            columns: [
                {
                    title: 'ชื่อ-นามสกุล ผู้ส่งคำขอ',
                    dataIndex: 'fullname',
                    key: 'fullname',
                    width: 200,
                    align: 'center',
                },
                {
                    title: 'วันที่แจ้งลา',
                    dataIndex: 'leave_user_timestamp',
                    key: 'leave_user_timestamp',
                    width: 120,
                    align: 'center',
                }, 
                {
                    title: 'วันที่ลา',
                    dataIndex: 'leave_user_date',
                    key: 'leave_user_date',
                    width: 180,
                    align: 'center',
                }, 
                {
                    title: 'เลขกำกับใบลา',
                    dataIndex: 'leave_user_no',
                    key: 'leave_user_no',
                    width: 150,
                    align: 'center',
                }, 
                {
                    title: 'ปรเภทการลา',
                    dataIndex: 'leave_type_id',
                    key: 'leave_type_id',
                    width: 120,
                    align: 'center',
                }, 
                {
                    title: 'โควตาการลา',
                    dataIndex: 'leave_quota',
                    key: 'leave_quota',
                    width: 120,
                    align: 'center',
                }, 
                {
                    title: 'จำนวนวันลา',
                    dataIndex: 'leave_user_amount',
                    key: 'leave_user_amount',
                    width: 90,
                    align: 'center',
                }, 
                {
                    title: 'รูปแบบการลา',
                    dataIndex: 'leave_pattern_id',
                    key: 'leave_pattern_id',
                    width: 120,
                    align: 'center',
                },
                {
                    title: 'การอนุมัติ',
                    dataIndex: 'approve_status',
                    key: 'approve_status',
                    width: 150,
                    align: 'center',
                }, 
                {
                    title: 'ชื่อผู้อนุมัติการลา',
                    dataIndex: 'approver',
                    key: 'approver',
                    width: 210,
                    align: 'center',
                }, 
                {
                    title: 'ตัวเลือก',
                    dataIndex: 'id',
                    key: 'id',
                    width: 130,
                    align: 'center',
                    fixed: 'right'
                }
            ],
        }
    },
    watch: {

    },
    methods: {
        async getBase64Image(e, file) {
            e.stopPropagation();
            e.preventDefault();

            if (typeof file === 'string') {
                const result = await this.getBase64ImageFromURL(file);
                let a = document.createElement('a');
                a.href = result;
                a.download = 'image.png';
                a.click();
            } else if (file.url) {
                const result = await this.getBase64ImageFromURL(file.url);

                // download
                let a = document.createElement('a');
                a.href = result;
                a.download = 'image.png';
                a.click();
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => {
                    const base64String = reader.result;
                    let a = document.createElement('a');
                    a.href = base64String;
                    a.download = 'image.png';
                    a.click();
                };
            }
        },
        showPreview(e, fileUrl) {
            e.stopPropagation();
            this.previewImage = fileUrl;
            this.previewVisible = true;
        },
        async getBase64ImageFromURL(url) {
            const res = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            });
            const blob = await res.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        },
        render_date(datetime) {
            return dayjs(datetime).format('YYYY/MM/DD')
        },
        getApproveStatusName(status) {
            if (status == 1) {
                return 'อนุมัติ';
            } else if (status == 2) {
                return 'ไม่อนุมัติ';
            } else {
                return '';
            }
        },
        remove_leave_user(id){
            Swal.fire({
                title: 'คุณต้องการลบใช่หรือไม่??',
                text: `คุณจะไม่สามารถกู้คืนได้`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ยกเลิก'
                }).then(async (result) => {
                if (result.isConfirmed) {
                    await SystemService.delete_all('LeaveAdmin', id)
                    this.onRefresh()
                }
            })
        },
        showLeaveDetails(record) {
            this.selectedLeave = record;
            this.showModalLeaveUserData = true;
        },
        onRefresh() {
             this.init_leave_admins()
        },
        async handleChangeApproveStatus(id, value) {
            await SystemService.update_all('LeaveAdmin', { data: { approve_status: value } }, id);
            this.onRefresh();
        },
        async handleChangeApprover(id, fullname) {
            await SystemService.update_all('LeaveAdmin', { data: { approver: fullname} }, id);
            // this.onRefresh();
        },
        async handleApprove(id) {
            const leaveUser = await await SystemService.get_all('LeaveUser');
            this.leave_progress1 = await SystemService.get_core('getLeaveAdminProgressOne');
            const leaveAdmin = this.leave_progress1.find(item => item.id === id);
            const leaveUserByUsernameAndCompany = leaveUser.find(user => user.id == leaveAdmin.leave_user_id && user.company_id == leaveAdmin.company_id);
            // console.log('5678',leaveAdmin.approve_status)
            if (!leaveAdmin || leaveAdmin.approve_status != 1 && leaveAdmin.approve_status != 2) {
                Swal.fire({
                    title: 'กรุณาอนุมัติการลา',
                    icon: 'warning',
                });
                return;
            }
            if (!leaveAdmin || !leaveAdmin.approver) {
                Swal.fire({
                    title: 'กรุณาระบุชื่อผู้อนุมัติการลา',
                    icon: 'warning',
                });
                return;
            }
            let leaveUserStatus = '';
            if (leaveAdmin.approve_status == 1) {
                leaveUserStatus = 'อนุมัติ';
            } else if (leaveAdmin.approve_status == 2) {
                leaveUserStatus = 'ไม่อนุมัติ';
            }console.log('111', leaveUserByUsernameAndCompany.id);
            const res = await SystemService.update_all('LeaveUser', { data: { leave_user_status: leaveUserStatus } }, leaveUserByUsernameAndCompany.id);
            console.log('5678', res);
            await SystemService.update_all('LeaveAdmin', { data: { leave_progress: 2, approved_date: dayjs().format('YYYY-MM-DD HH:mm:ss') } }, id);
            Swal.fire({
                title: 'ส่งแจ้งเตือนการอนุมัติสำเร็จ',
                icon: 'success',
            });
            this.onRefresh();                   
        },
        clear_search() {
            this.search_input = null;
            this.timeStart = null;
            this.timeEnd = null;
        },
        render_date(datetime) {
            return dayjs(datetime).format('YYYY/MM/DD')
        },
        filtered_list(array) {
            return array.filter(({ name, time_start, time_end }) => {
                let matchesName = true;
                let matchesDateRange = true;
                if (this.search_input) {
                    matchesName = name?.toLowerCase().includes(this.search_input.toLowerCase());
                }
                // ตรวจสอบช่วงวันที่ (ไม่สนใจเวลา)
                if (this.timeStart && this.timeEnd) {
                    const filterStart = dayjs(this.timeStart).startOf('day');
                    const filterEnd = dayjs(this.timeEnd).startOf('day');
                    const start = dayjs(time_start).startOf('day');
                    const end = dayjs(time_end).startOf('day');
                    matchesDateRange = start.isSame(filterStart) && end.isSame(filterEnd);
                }
                return matchesName && matchesDateRange;
            });
        },
        async init_leave_pattern(){
            this.leave_pattern_list = await LeaveService.getLeavePatternAll();
        },
        async init_leave_admins() {
            try {
                const company_id = JSON.parse(localStorage.getItem('companyData')).id;
                const leaveProgressData = await SystemService.get_core('getLeaveAdminProgressOne');
                // console.log("leaveProgressData:", leaveProgressData);
                this.leave_progress1 = [];
                for (const item of leaveProgressData) {
                    const username = item.user_name;
                    // console.log("Processing user:", username);
                    let leaveQuotaData = await SystemService.leavequota(company_id, username);
                    leaveQuotaData = leaveQuotaData.data;
                    const quota = leaveQuotaData.find(q => q.leave_type_id === item.leave_type_id);
                    const usedQuota = quota ? quota.count : 0;
                    const totalQuota = this.leave_quota_limits[item.leave_type_id] || '-';
                    this.leave_progress1.push({
                        ...item,
                        leave_quota: totalQuota !== '-' ? `${usedQuota}/${totalQuota}` : '-',
                    });
                    // console.log("leave_progress1:", this.leave_progress1);
                }
                // console.log("Final leave_progress1:", this.leave_progress1);
                const [leaveTypes, operations] = await Promise.all([
                    SystemService.get_core('getLeaveType'),
                    SystemService.get_core('getOperations')
                ]);
                this.leave_types = leaveTypes;
                this.operations = operations;
            } catch (error) {
                console.error('Error initializing leave admins:', error);
            }
        },


        async get_company() {
            try {
                const company = await UserService.get_user_profile();
                const businessType = await CompanyService.getBusinessId(company.company_id);
                this.businessTypeId = businessType.data;
                if (businessType.data === 2 || businessType.data === 3 || businessType.data === 4) {
                    this.business = true;
                }

            } catch (error) {
                console.error("Error in get_company:", error);
            }
        },
        async getBusinessType(){
            const company = await UserService.get_user_profile();
            const businessType = await CompanyService.getBusinessId(company.company_id)
            this.businessTypeId = businessType.data
        },
        getLeaveTypeName(id) {
            const leaveType = this.leave_types.find(type => type.id === id);
            return leaveType ? leaveType.name : '';
        },
        getLeavePatternTypeName(id) {
            const leaveType = this.leave_pattern_list.find(type => type.id === id);
            return leaveType ? leaveType.name : '';
        },
    },
    computed: {
        render_type() {
            return this.$route.params.type
        },

        filteredColumns() {
            if (Array.isArray(this.columns)) {
                return this.columns.filter(column => {
                    if(this.businessTypeId != 3 && this.businesssTypeId != 2){
                        return column.key !== 'leave_pattern_id'
                    }
                    return column.key !== 'leave_quota' || this.business;
                });
            }
            return [];
        
         },
         leave_quota_limits() {
            return this.businessTypeId === 4
                ? {
                    1: 7,  // ลากิจ: 7 ครั้ง/ปี
                    2: 12,  // ลาพักร้อน: 12 ครั้ง/ปี
                    4: 30, // ลาป่วย: 30 ครั้ง/ปี
                    6: null, // ลาชาปนกิจ: ไม่มีเงื่อนไข
                }
                : {
                    1: 3,  // ลากิจ: 3 ครั้ง/ปี
                    2: 8,  // ลาพักร้อน: 8 ครั้ง/ปี
                    7: 4,  // ลาฝึกอบรม: 4 ครั้ง/ปี
                    4: 30, // ลาป่วย: 30 ครั้ง/ปี
                    5: null, // ลาแบบไม่รับค่าจ้าง: ไม่มีเงื่อนไข
                    6: null, // ลาชาปนกิจ: ไม่มีเงื่อนไข
                };
        },
    },
    async mounted() {
        await this.getBusinessType();
        await this.init_leave_pattern();
        this.init_leave_admins().then(() => {
        this.get_company().then();

    });
    }
}
</script>

<style>
.preview-icon,
.download-icon {
    cursor: pointer;
    font-size: 20px;
    color: black;
}
.image-container {
    position: relative;
    display: inline-block;
}
.uploaded-image-table {
    display: block;
    width: 15rem;
    height: auto;
}
.icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    padding: 5px;
}

.permission-table .ant-table-row-level-0 td:first-child,
.permission-table .ant-table-row-level-0 td:nth-child(2),
.permission-table .ant-table-row-level-0 td:nth-child(3) {
    background-color: #f9f9f9 !important;
}
.permission-table .ant-table-row-expand-icon::before,
.permission-table .ant-table-row-expand-icon::after 
{
    content: '' !important;
    border: none !important;
    background: none; /* ปิด background */
    transform: none !important;
    transition: none !important;
    top: auto !important
}
.permission-table .ant-table-row-expand-icon:not(.ant-table-row-expand-icon-expanded)::before {
    content: '>' !important;
}

.permission-table .ant-table-row-expand-icon.ant-table-row-expand-icon-expanded::before {
    content: 'v' !important;
}
</style>