<template>
    <div style="padding: 15px;height: 100vh">
        <div class="app-content">
            <a-spin :spinning="loading">
                <a-card :title="'จัดการค่าใช้จ่ายบริษัท'">
                    <a-row style="display: flex; justify-content: flex-end; margin-bottom: 16px;">
                        <a-space>
                            <router-link :to="`/app/expenses/create`">
                                <a-button style="display: flex;"
                                    type="primary" size="large">
                                    <template #icon
                                    ><span class="material-symbols-outlined">Add</span></template
                                    >
                                    เพิ่มค่าใช้จ่าย
                                </a-button>
                            </router-link>
                            <a-button @click="open_show_monthly_report" type="primary" size="large">
                              <img
                                  src="https://img.icons8.com/?size=100&id=3NeB_j4SNg2G&format=png&color=000000"
                                  alt="billAndDebt"
                                  width="16"
                                  height="16"
                                  :style="{ margin: '0', filter: 'invert(100%)' }"/>
                                  ออกรายงานค่าใช้จ่ายบริษัท
                          </a-button>
                        </a-space>
                    </a-row>

                    <a-space style="width: 100%;  margin-bottom: 16px">
                        <a-select
                        :dropdownMatchSelectWidth="false"
                        style="width: 160px"
                        v-model:value="search_payment_id"
                        placeholder="สถานะการชำระเงิน"
                        >
                        <a-select-option :value="4">รอดำเนินการ</a-select-option>
                        <a-select-option :value="1">ชำระแล้ว</a-select-option>
                        <a-select-option :value="2">ยังไม่ชำระ</a-select-option>
                        <a-select-option :value="3">เกินกำหนด</a-select-option>
                        <!-- <a-select-option v-for="{ id,name  } in transport_type_list" :key="id" :value="id">
                            {{ name }}
                        </a-select-option> -->
                        </a-select>
                            <a-select
                            :dropdownMatchSelectWidth="false"
                            style="width: 160px"
                            v-model:value="search_approve_id"
                            placeholder="สถานะการอนุมัติ"
                            >
                            <a-select-option :value="1">รอดำเนินการ</a-select-option>
                            <a-select-option :value="2">อนุมัติ</a-select-option>
                            <a-select-option :value="3">ไม่อนุมัติ</a-select-option>
                            </a-select>
                    <a-range-picker
                        v-model:value="search_datetime"
                        @change="handleTableChange"
                        />
                        <a-button @click="clear_search()" type="dashed">CLEAR</a-button>
                    </a-space>
                    <a-table
                    rowKey="id"
                    :columns="[
                    {
                        title: 'รหัสค่าใช้จ่าย',
                        dataIndex: 'expense_code',
                        key: 'expense_code',
                        width: 150,
                        align: 'center',
                    },
                    {
                        title: 'วันที่บันทึกค่าใช้จ่าย',
                        dataIndex: 'updated_at',
                        key: 'updated_at',
                        width: 150,
                        align: 'center',
                    //     sorter: {
                    //     compare: (a, b) => a.sheet_status - b.sheet_status,
                    //     multiple: 1,
                    // },
                    },
                    {
                        title: 'หมวดหมู่ค่าใช้จ่าย',
                        dataIndex: 'expense_category_id',
                        key: 'expense_category_id',
                        width: 175,
                        align: 'center',
                    },
                    {
                        title: 'รายละเอียดค่าใช้จ่าย',
                        dataIndex: 'expense_detail',
                        key: 'expense_detail',
                        width: 200,
                        align: 'center',
                    },
                    {
                        title: 'รหัสโครงการ',
                        dataIndex: 'project_code',
                        key: 'project_code',
                        width: 220,
                        align: 'center',
                    },
                    {
                        title: 'วิธีชำระเงิน',
                        dataIndex: 'payment_type',
                        key: 'payment_type',
                        width: 150,
                        align: 'center',
                    },
                    {
                        title: 'รายละเอียดบัญชีธนาคาร',
                        dataIndex: 'bank',
                        key: 'bank',
                        width: 200,
                        align: 'center',
                    },
                    {
                        title: 'รูปแบบการจ่ายเงิน',
                        dataIndex: 'payment_format',
                        key: 'payment_format',
                        width: 150,
                        align: 'center',
                        // background: 'lightblue',
                        align: 'center',
                    },
                    {
                        title: 'งวดที่',
                        dataIndex: 'period_no',
                        key: 'period_no',
                        width: 150,
                        align: 'center',
                        align: 'center',
                    },
                    {
                        title: 'จำนวนเงิน',
                        dataIndex: 'amount',
                        key: 'amount',
                        width: 200,
                        align: 'center',
                    },
                    {
                        title: 'ภาษี',
                        dataIndex: 'tax',
                        key: 'tax',
                        width: 200,
                        align: 'center',
                    },
                    {
                        title: 'ยอดรวมสุทธิ',
                        dataIndex: 'total_net',
                        key: 'total_net',
                        width: 200,
                        align: 'center',
                    },
                    {
                        title: 'วันที่ตั้งจ่าย',
                        dataIndex: 'set_payment_date',
                        key: 'set_payment_date',
                        width: 200,
                        align: 'center',
                    },
                    {
                        title: 'วันที่จ่าย',
                        dataIndex: 'payment_date',
                        key: 'payment_date',
                        width: 200,
                        align: 'center',
                    },
                    {
                        title: 'ผู้ขาย/ร้านค้า',
                        dataIndex: 'shop_sell',
                        key: 'shop_sell',
                        width: 200,
                        align: 'center',
                    },
                    {
                        title: 'ฝ่าย',
                        dataIndex: 'cotton',
                        key: 'cotton',
                        width: 200,
                        align: 'center',
                    },
                    {
                        title: 'ผู้ตั้งเบิก',
                        dataIndex: 'setter',
                        key: 'setter',
                        width: 200,
                        align: 'center',
                    },
                    {
                        title: 'สถานะการอนุมัติ',
                        dataIndex: 'status_approve',
                        key: 'status_approve',
                        width: 200,
                        align: 'center',
                    },
                    {
                        title: 'ผู้อนุมัติ',
                        dataIndex: 'approver',
                        key: 'approver',
                        width: 200,
                        align: 'center',
                    },
                    {
                        title: 'ไฟล์แนบ',
                        dataIndex: 'file_name',
                        key: 'file_name',
                        width: 200,
                        align: 'center',
                    },
                    {
                        title: 'หมายเหตุ',
                        dataIndex: 'remark',
                        key: 'remark',
                        width: 200,
                        align: 'center',
                    },
                    {
                        title: 'วันที่จ่ายจริง',
                        dataIndex: 'real_payment_date',
                        key: 'real_payment_date',
                        width: 200,
                        align: 'center',
                    },
                    {
                        title: 'สถานะการชำระเงิน',
                        dataIndex: 'payment_status',
                        key: 'payment_status',
                        width: 200,
                        align: 'center',
                    },
                    {
                        title: 'เลขที่เอกสารอ้างอิง/ใบเสร็จรับเงิน',
                        dataIndex: 'doc_name',
                        key: 'doc_name',
                        width: 200,
                        align: 'center',
                    },
                    {
                        title: 'ตัวเลือก',
                        dataIndex: 'id',
                        key: 'id',
                        width: 180,
                        align: 'center',
                        fixed: 'right',
                    },
                    ]"
                    :data-source="filtered_list(expenses_data)"
                    :row-selection="{ type: 'checkbox', selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                    bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }" :locale="{ emptyText: '' }">
            <template #bodyCell="{ column, record }">
              <!-- <template v-if="column.key === 'booking_type_id' && record[column.key]">
                <span
                  ><a-tag
                    v-if="tag_option.booking_type_id[record.booking_type_id]"
                    :color="tag_option.booking_type_id[record.booking_type_id].color"
                  >
                    {{ tag_option.booking_type_id[record.booking_type_id].txt }}
                  </a-tag></span
                >
              </template> -->
              <template v-if="column.key === 'expense_category_id'">
                <span>
                  {{ record.expense_category?.name }}
              </span>
              </template>
              <template v-else-if="column.key === 'bank'">
                <div v-if="record.payment_type == 'โอน'" style="display: flex; flex-direction: column; gap: 5px;">
                  <span>{{ "ชื่อธนาคาร: " + record?.bank_name }}</span>
                  <span>{{ "เลขที่บัญชี: " + record?.account_number }}</span>
                  <span>{{ "ชื่อบัญชี: " + record?.account_name }}</span>
                </div>
                <div v-if="record.payment_type == 'เงินสด/เช็ค'">
                  {{ Math.round(Number(record.quantity_amount || 0)).toLocaleString() }}
                </div>
              </template>
              <template v-else-if="column.key === 'payment_type'">
              <span >
                <a-tag v-if="statusText(record)" :color="statusText(record).color">
                  {{ statusText(record).txt }}
                </a-tag>
              </span>
            </template>
            <template v-else-if="(column.key == 'amount')">
                {{ record?.amount % 1 === 0
            ? Number(record?.amount || 0).toLocaleString()
            : Number(record?.amount || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
            </template>
            <template v-else-if="(column.key == 'total_net')">
              {{ record?.total_net % 1 === 0
            ? Number(record?.total_net || 0).toLocaleString()
            : Number(record?.total_net || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
            </template>
            <template v-else-if="column.key === 'file_name'">
              <span >
                {{ safeParseJSON(record.data_file_destination) }}
              </span>
            </template>
            <template v-else-if="column.key === 'updated_at'">
              <span >
                {{ dateFormate(record) }}
              </span>
            </template>
            <template v-else-if="column.key === 'doc_name'">
              <span >
                {{ safeParseJSON(record.data_document_destination) }}
              </span>
            </template>
            <template v-else-if="column.key === 'payment_format'">
              <span >
                <a-tag v-if="statusTextAmount(record)" :color="statusTextAmount(record).color">
                  {{ statusTextAmount(record).txt }}
                </a-tag>
              </span>
            </template>
            <template v-else-if="column.key === 'real_payment_date'">
              <span >
                <a-tag v-if="dateSetText(record?.real_payment_date)" :color="dateSetText(record?.real_payment_date).color">
                  {{ dateSetText(record?.real_payment_date).txt ?? null }}
                </a-tag>
              </span>
            </template>
            <template v-else-if="column.key === 'set_payment_date'">
              <span >
                <a-tag v-if="dateSetText(record?.set_payment_date)" :color="dateSetText(record?.set_payment_date).color">
                  {{ dateSetText(record?.set_payment_date).txt }}
                </a-tag>
              </span>
            </template>
            <template v-else-if="column.key === 'payment_date'">
              <span >
                <a-tag v-if="dateSetText(record?.payment_date)" :color="dateSetText(record?.payment_date).color">
                  {{ dateSetText(record?.payment_date).txt }}
                </a-tag>
              </span>
            </template>
            <template v-else-if="column.key === 'status_approve'">
              <span >
                <a-tag v-if="statusApprove(record)" :color="statusApprove(record).color">
                  {{ statusApprove(record).txt }}
                </a-tag>
              </span>
            </template>
            <template v-else-if="column.key === 'payment_status'">
              <span >
                <a-tag v-if="statusAmountText(record)" :color="statusAmountText(record).color">
                  {{ statusAmountText(record).txt }}
                </a-tag>
              </span>
            </template>
              <template v-else-if="column.key === 'id'">
                <div>
                  <a-tooltip>
                  <template #title>อัพเดทข้อมูล</template>
                  <a @click="show_update_progress(record.id)"
                    ><span style="font-size: 14px" class="material-symbols-outlined"
                      >update</span
                    ></a
                  >
                </a-tooltip>
                  <a-divider type="vertical"></a-divider>
                  <a @click="go_edit(record.id, record)"
                    ><span style="font-size: 18px" class="material-symbols-outlined"
                      >drive_file_rename_outline</span
                    ></a
                  >
                  <a-divider type="vertical"></a-divider>
                  <a @click="delete_company_expense(record.id)"
                    ><span style="font-size: 18px" class="material-symbols-outlined"
                      >delete</span
                    ></a
                  >
                </div>
              </template>
            </template>
              </a-table>
          <a-modal
          v-model:visible="update_progress_modal"
          @ok="update_progress"
        >
        <template #title>
          เลือกสถานะการชำระเงิน
        </template>
          <a-card>
            <a-form layout="vertical">
              <a-form-item label="สถานะการชำระเงิน :">
                  <a-select
                    v-model:value="select_status.payment_status"
                    :options="
                        payment_status.map(({ id, name }) => ({
                        value: id,
                        label: name,
                        }))
                    "
                    ></a-select>
              </a-form-item>
            </a-form>
          </a-card>
        </a-modal>
        
          <a-modal v-model:visible="show_monthly_report" title="ออกรายงานค่าใช้จ่ายบริษัท" :footer="null">
          <a-form :model="monthly_report_date" layout="vertical" ref="formMonthly" @finish="submit_monthly_report()">
            <a-row justify="center">
              <a-col :span="20">
                <a-form-item name="payment_status" :rules="!this.disablePopupPayment ? [{ required: true, message: 'โปรดเลือกสถานะชำระเงิน'}] :  [{ required: false}]">
                  <template #label>
                    <div style="display: flex; align-items: center;">
                      <span style="margin-right: 8px;">สถานะการชำระเงิน</span>
                      <label class="inline-flex items-center" style="margin-bottom: 1px;">
                        <input type="checkbox" v-model="selectedTypeReportClient" value="All"
                          class="form-checkbox text-blue-600" @change="handleCheckboxChangePayment"
                          style="width: 12px;height: 12px;" :disabled="disablePopup" />
                        <span style="margin-left: 5px; font-size: 13px;">ทั้งหมด</span>
                      </label>
                    </div>
                  </template>
                  <a-select v-model:value="monthly_report_date.payment_status" placeholder="เลือกสถานะการชำระเงิน"
                    style="width: 300px" show-search :disabled="disablePopup || disablePopupPayment">
                    <a-select-option v-for="{ id,name } in payment_popup_status" :value="id">{{ name
                      }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row justify="center">
              <a-col :span="20">
                <a-form-item name="status_approve" :rules="!disablePopupApprove ? [{ required: true, message: 'โปรดเลือกสถานะอนุมัติ'}] :  [{ required: false}]">
                  <template #label>
                    <div style="display: flex; align-items: center;">
                      <span style="margin-right: 8px;">สถานะการอนุมัติ</span>
                      <label class="inline-flex items-center" style="margin-bottom: 1px;">
                        <input type="checkbox" v-model="selectedTypeReportClient" value="All"
                          class="form-checkbox text-blue-600" @change="handleCheckboxChangeApprove"
                          style="width: 12px;height: 12px;" :disabled="disablePopup "/>
                        <span style="margin-left: 5px; font-size: 13px;">ทั้งหมด</span>
                      </label>
                    </div>
                  </template>
                  <a-select v-model:value="monthly_report_date.status_approve" placeholder="เลือกสถานะการอนุมัติ"
                    style="width: 300px" show-search :disabled="disablePopup || disablePopupApprove">
                    <a-select-option v-for="{ id,name } in approve_popup_status" :value="id">{{ name
                      }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row justify="center">
              <a-col :span="20">
                <a-form-item label="วันที่ตั้งจ่าย" name="datetime"
                  :rules="[{ required: true, message: 'โปรดระบุวัน-เวลา รับส่ง' }]">
                  <a-range-picker v-model:value="monthly_report_date.datetime" format="YYYY/MM/DD" style="width: 300px" :disabled="disablePopup"
                    :placeholder="['Start Date', 'End Date']" :minuteStep="1" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row justify="end">
              <a-button type="primary" html-type="submit">บันทึก</a-button>
            </a-row>
          </a-form>
        </a-modal>
                    </a-card>
                    </a-spin>
                    </div>
                    </div>
                </template>
<script>
import dayjs from 'dayjs';
import CompanyExpenseService from '../../api/CompanyExpenseService';
import SystemService from '../../api/SystemService';
import Swal from 'sweetalert2';
import * as xlsx from "xlsx-js-style";
import moment from 'moment';

export default ({
    name: 'claim',
    computed: {
        render_type() {
            return this.$route.name
        },
        
    },
  data() {
    return {
        loading: false,
        search_payment_id: null,
        search_approve_id: null,
        search_datetime: null,
        expenses_data: [],
        selectedRowKeys: [],
        show_monthly_report: false,
        monthly_report: [],
        data_file_description: [],
        data_document_description: [],
        select_status:[],
        monthly_report_date: {
          datetime: [null, null],
          payment_status: null,
          status_approve: null,
        },
        disablePopupPayment:false,
        disablePopupApprove:false,
        disablePopup:false,
        update_progress_modal:false,
        approve_popup_status:[
          {id: 1, name: "รอดำเนินการ" },
          {id: 2, name: "อนุมัติ" },
          {id: 3, name: "ไม่อนุมัติ" },
        ],
        payment_popup_status:[
          {id: 1, name: "ชำระแล้ว" },
          {id: 2, name: "ยังไม่ชำระ" },
          {id: 3, name: "เกินกำหนด" },
          {id: 4, name: "รอดำเนินการ" },
          {id: 0, name: "ไม่มี" },
        ],
        payment_status:[
          {id: 1, name: "ชำระแล้ว" },
          {id: 2, name: "ยังไม่ชำระ" },
          {id: 3, name: "เกินกำหนด" },
          {id: 0, name: "ไม่มี" },
        ]
    }
    },
    methods: {
      log() {
        console.log(this.monthly_report_date.datetime)
      },
      go_edit(id) {
        this.$router.push(`/app/expenses/${id}`)
      },
      show_update_progress(id) {
        this.update_progress_modal = true;
        this.select_status = this.expenses_data.find((item) => item.id === id);
      },
      async update_progress() {
        await SystemService.update_all("CompanyExpenses", { data: { payment_status: this.select_status.payment_status } }, this.select_status.id).then(async (res) => {
          if (res) {
            this.update_progress_modal = false;
            this.init_data();
            Swal.fire("อัพเดทเรียบร้อย", "อัพเดทสถานะเรียบร้อย", "success");
          }
        });
      },
      handleCheckboxChangePayment() {
        // this.monthly_report_date.payment_status = 0
        this.disablePopupPayment = !this.disablePopupPayment
      },
      handleCheckboxChangeApprove() {
        // this.monthly_report_date.status_approve = 0
        this.disablePopupApprove = !this.disablePopupApprove
      },
      async submit_monthly_report() {
        this.show_monthly_report = false;
        this.$message.loading({ content: "กำลังโหลด...", key: "loading" });
        const time_start = dayjs(this.monthly_report_date.datetime[0]).format("YYYY-MM-DD");
        const time_end = dayjs(this.monthly_report_date.datetime[1]).format("YYYY-MM-DD");
        console.log("aaaa",this.selectedRowKeys)
        const payment_status = this.monthly_report_date.payment_status;
        const status_approve = this.monthly_report_date.status_approve;
        const { data } = await CompanyExpenseService.reportCompanyExpense(payment_status,status_approve,time_start, time_end,this.selectedRowKeys);
        
        if (!data || data.length === 0) {
        this.$message.warning("ไม่มีข้อมูลที่สามารถสร้างรายงานได้");
        return;
      }
     
      const headersColumn = [
        { header: "รหัสค่าใช้จ่าย", key: "expense_code" },
        { header: "วันที่บันทึกค่าใช้จ่าย", key: "updated_at" }, 
        { header: "หมวดหมู่ค่าใช้จ่าย", key: "expense_category" },
        { header: "รายละเอียดค่าใช้จ่าย", key: "expense_detail" },
        { header: "รหัสโครงการ", key: "project_code" },
        { header: "วิธีการชำระเงิน", key: "payment_type" },
        { header: "รายละเอียดบัญชีธนาคาร", key: "bank" },
        { header: "รูปแบบการจ่ายเงิน", key: "payment_format" },
        { header: "งวดที่", key: "period_no" },
        { header: "จำนวนเงิน", key: "amount" },
        { header: "ภาษี", key: "tax" },
        { header: "ยอดรวมสุทธิ", key: "total_net" },
        { header: "วันที่ตั้งจ่าย", key: "set_payment_date" },
        { header: "วันที่จ่าย", key: "payment_date" },
        { header: "ผู้ขาย/ร้านค้า", key: "shop_sell" },
        { header: "ฝ่าย", key: "cotton" },
        { header: "ผู้ตั้งเบิก", key: "setter" },
        { header: "สถานะการอนุมัติ", key: "status_approve" },
        { header: "ผู้อนุมัติ", key: "approver" },
        { header: "ไฟล์แนบ", key: "file_name" },
        { header: "หมายเหตุ", key: "remark" },
        { header: "วันที่จ่ายจริง", key: "real_payment_date" },
        { header: "สถานะการชำระเงิน", key: "payment_status" },
        { header: "เลขที่เอกสารอ้างอิง/ใบเสร็จ", key: "doc_name" }
      ];

      const workBook = xlsx.utils.book_new();
      const workSheet = xlsx.utils.json_to_sheet(data, { header: headersColumn.map(h => h.key) });

      xlsx.utils.sheet_add_aoa(workSheet, [headersColumn.map(h => h.header)], { origin: 'A1' });

      let fillColor = { rgb: "FFF4CC" };
        headersColumn.forEach((header, colIndex) => {
            const cellAddress = xlsx.utils.encode_cell({ c: colIndex, r: 0 });
            if (!workSheet[cellAddress]) workSheet[cellAddress] = { v: header.header };
            if (colIndex === 21) {
                fillColor = { rgb: "7CFC00" };
            } if (colIndex === 22) {
                fillColor = { rgb: "7CFC00" };
            } if (colIndex === 23) { 
                fillColor = { rgb: "7CFC00" };
            }
            workSheet[cellAddress].s = {
                font: { bold: true },  // ตัวหนา
                fill: { fgColor: fillColor },  // สีพื้นหลัง
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" }
                },
                alignment: { horizontal: "center", vertical: "middle" } // กึ่งกลาง
            };
            workSheet[cellAddress].s = {
                font: { bold: true },  // ตัวหนา
                fill: { fgColor: fillColor },  // สีพื้นหลัง
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" }
                },
                alignment: { horizontal: "center", vertical: "middle" } // กึ่งกลาง
            };
        });

        const lastRow = data.length + 1  ;
        for (let row = 1; row <= lastRow; row++) {
            for (let col = 0; col < headersColumn.length; col++) {
                const cellAddress = xlsx.utils.encode_cell({ c: col, r: row });
                if (!workSheet[cellAddress]) {
                    workSheet[cellAddress] = { v: "" }; // กำหนดค่าให้เซลล์ว่าง
                }
                // กำหนดเส้นกรอบทุกเซลล์
                workSheet[cellAddress].s = {
                    border: {
                        top: { style: "thin" },
                        bottom: { style: "thin" },
                        left: { style: "thin" },
                        right: { style: "thin" }
                    },
                    alignment: { horizontal: "center", vertical: "middle", wrapText: true }
                };

                for (let row = 2; row <= lastRow; row++) {
                  const borderStyle = {
                      border: {
                          top: { style: "thin" },
                          bottom: { style: "thin" },
                          left: { style: "thin" },
                          right: { style: "thin" }
                      },
                      alignment: { horizontal: "center", vertical: "middle" }
                  };
              }

            }
        }
        workSheet['!cols'] = headersColumn.map(header => ({
            wch: Math.max(...data.map(row => (row[header.key] ? row[header.key].toString().length : 10)), header.header.length)
        }));

        // workSheet['!cols'][14] = { wch: 20 }; // คอลัมน์ O เป็นคอลัมน์ที่ 15 (index เริ่มที่ 0)
        // workSheet['!cols'][11] = { wch: 20 }; 
        // workSheet['!cols'][19] = { wch: 20 }; 


      xlsx.utils.book_append_sheet(workBook, workSheet, "Report");
      xlsx.writeFile(workBook, "ออกค่าใช้จ่ายบริษัท.xlsx");
      this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
    },
      statusText(record) {
          if (record?.payment_type === 'โอน') {
            return { txt: "โอนเงิน", color: "blue" };
          } 
          if (record?.payment_type === 'เงินสด/เช็ค') {
            return { txt: "เงินสด", color: "pink" };
          }
        },
        dateFormate(record) {
          return dayjs(record.updated_at).format("YYYY/MM/DD HH:mm")
        },
        statusTextAmount(record) {
          if (record?.payment_format === 'แบ่งจ่ายเป็นงวด') {
            return { txt: "แบ่งจ่ายเป็นงวด", color: "red" };
          }
          if (record?.payment_format === 'จ่ายเต็มจำนวน') {
            return { txt: "จ่ายเต็มจำนวน", color: "green" };
          }
        },
        dateSetText(record){
          if(!record){
            return 
          }
          const setPaymentDate = dayjs(record).format("YYYY/MM/DD")
            return { txt: setPaymentDate, color: "green" };
        },
        statusApprove(record) {
          if (record?.status_approve === 1) {
            return { txt: "รอดำเนินการ", color: "yellow" };
          }
          if (record?.status_approve === 2) {
            return { txt: "อนุมัติ", color: "green" };
          }
          if (record?.status_approve === 3) {
            return { txt: "ไม่อนุมัติ", color: "red" };
          }
        },
        statusAmountText(record) {
          if (record?.payment_status === 0) {
            return { txt: "ไม่มี", color: "gray" };
          }
          if (record?.payment_status === 2) {
            return { txt: "ยังไม่ชำระ", color: "red" };
          }
          if (record?.payment_status === 1) {
            return { txt: "ชำระแล้ว", color: "green" };
          }
          if (record?.payment_status === 4) {
            return { txt: "รอดำเนินการ", color: "yellow" };
          }
          if (record?.payment_status === 3) {
            return { txt: "เกินกำหนด", color: "orange" };
          }
        },
        clear_search() {
          this.search_payment_id = null
          this.search_approve_id = null
          this.search_datetime = null
          this.init_data()
        },
        filtered_list(array) {
        return array.filter(({ payment_status, status_approve, set_payment_date }) => {
            // const searchInputLower = this.search_input ? this.search_input.toLowerCase() : "";
            // const searchInput = !this.search_input || 
            //     (booking_no && booking_no.toLowerCase().includes(searchInputLower)) ||
            //     (client.fullname && client.fullname.toLowerCase().includes(searchInputLower))
            const searchPaymentId = !this.search_payment_id ||
              (payment_status === this.search_payment_id);
              const searchApproveId = !this.search_approve_id ||
              (status_approve === this.search_approve_id);
            const searchDate = !this.search_datetime ||
              (set_payment_date && dayjs(set_payment_date).format("YYYY/MM/DD") >= (dayjs(this.search_datetime[0]).format("YYYY/MM/DD"))) &&
              (set_payment_date && dayjs(set_payment_date).format("YYYY/MM/DD") <= (dayjs(this.search_datetime[1]).format("YYYY/MM/DD")));
            return  searchPaymentId && searchDate && searchApproveId
        })
        // .sort((a,b) => b.id - a.id)
    },
    async delete_company_expense(id) {
          Swal.fire({
              title: `คุณต้องการลบข้อมูลนี้ใช่หรือไม่?`,
              text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้หากลบแล้ว!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
              cancelButtonText: 'ยกเลิก'
          }).then(async (result) => {
              if (result.isConfirmed) {
                  await CompanyExpenseService.deleteCompanyExpenseById(id)
                  this.init_data()
                  Swal.fire(
                      'ลบข้อมูลเรียบร้อย!',
                      'ข้อมูลของคุณถูกลบแล้ว',
                      'success'
                  )
              }
          })
      },
    onSelectChange(selectedRowKeys) {
        this.selectedRowKeys = selectedRowKeys;
    },
    async open_show_monthly_report(){
      this.show_monthly_report = true
      let dataSelect = this.expenses_data.filter((e)=> this.selectedRowKeys.includes(e.id))
      if (dataSelect.length === 0) {
        this.disablePopup = false
        this.monthly_report_date = {
          datetime: [null, null],
          payment_status: null,
          status_approve: null,
        }
        return; 
      }
        this.disablePopup = true
        let timeStart = dataSelect.reduce((min, current) => {
    const currentDate = dayjs(current.set_payment_date);  // Use dayjs for date
    return currentDate.isBefore(min) ? currentDate : min;
  }, dayjs(dataSelect[0].set_payment_date));  // Ensure the first date is a dayjs object

  // Calculate timeEnd using dayjs
  let timeEnd = dataSelect.reduce((max, current) => {
    const currentDate = dayjs(current.set_payment_date);  // Use dayjs for date
    return currentDate.isAfter(max) ? currentDate : max;
  }, dayjs(dataSelect[0].set_payment_date));  // Ensure the first date is a dayjs object

  // Set datetime with dayjs objects
  this.monthly_report_date.datetime = [timeStart.clone(), timeEnd.clone()];
        this.monthly_report_date.payment_status = dataSelect[0].payment_status
      this.monthly_report_date.status_approve = dataSelect[0].status_approve
    },
    safeParseJSON(jsonString) {
      try {
        if (!jsonString) return "";

        const parsed = JSON.parse(jsonString);
        if (!Array.isArray(parsed)) return "";

        return parsed.map(item => item.name).filter(Boolean).join(", ");
      } catch (error) {
        return "";
      }
    },
    async init_data() {
      this.expenses_data = await CompanyExpenseService.getCompanyExpensesByCompany()
    }
    },
    async mounted() {
        await this.init_data();
    },
    }
)
</script>