<template>
    <div style="padding: 15px;height: 100vh">
        <div v-if="render_type == 'all'" class="app-content">
            <a-card title="จัดการแจ้งลา">
                <template #extra>
                    <a-space>
                        <router-link :to="`/app/leaveUser/add`">
                            <a-button style="display: flex;"
                                type="primary" size="large">
                                <template #icon><span class="material-symbols-outlined">Add</span></template>
                                    สร้างคำขอลา
                            </a-button>
                        </router-link>
                    </a-space>
                </template>

                <a-row>
                    <a-space>
                        <a-date-picker style="width: 150px;" placeholder="Start Date" v-model:value="timeStart" format="DD/MM/YYYY" />
                        <span class="material-symbols-outlined icon" style="color: rgba(0, 0, 0, 0.25);">East</span>
                        <a-date-picker style="width: 150px;" placeholder="End Date" v-model:value="timeEnd" format="DD/MM/YYYY" />
                    </a-space>
                    <a-button @click="clear_search" type="dashed" style="margin-left: 1rem">CLEAR</a-button>
                </a-row>

                <br>
                <a-row>
                    <a-table :columns="filteredColumns" :data-source="filtered_list(show_table_list)" bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'leave_user_timestamp'">
                                <div> {{ render_date(record.leave_user_timestamp) }} </div>
                            </template>
                            <template v-if="column.key === 'leave_user_date'">
                                <div> {{ render_date(record.time_start) }} - {{ render_date(record.time_end) }} </div>
                            </template>
                            <template v-if="column.key === 'leave_user_no'">
                                <div> {{record.leave_user_no}} </div>
                            </template>
                            <template v-if="column.key === 'phone_no'">
                                <div> {{record.admin_phone_no}} </div>
                            </template>
                            <template v-if="column.key === 'leave_type_id'">
                                <div> {{ getLeaveTypeName(record.leave_type_id) }} </div>
                            </template>
                            <template v-if="column.key === 'leave_pattern_id'">
                                <div> {{ getLeavePatternTypeName(record.leave_pattern_id) }} </div>
                            </template>
                            <template v-if="column.key === 'leave_user_amount'">
                                <div> {{record.leave_user_amount}} </div>
                            </template>
                            <template v-if="column.key === 'leave_user_status'">
                                <div> {{record.leave_user_status}} </div>
                            </template>
                            <template v-if="column.key === 'leave_user_remark'">
                                <div> {{record.leave_user_remark}} </div>
                            </template>
                            <template v-if="column.key === 'id'">
                                <div>
                                    <a @click="send_leave_user(record.id)" 
                                        :style="{ 
                                            pointerEvents: 
                                            record.leave_user_status === 'รออนุมัติ' || 
                                            record.leave_user_status === 'อนุมัติ' || 
                                            record.leave_user_status === 'ไม่อนุมัติ' 
                                                ? 'none' 
                                                : 'auto' 
                                        }"
                                    >
                                        <span 
                                            :style="{ fontSize: '18px', color: record.leave_user_status !== 'ฉบับร่าง' ? 'gray' : 'inherit' }" 
                                            class="material-symbols-outlined">
                                            Send
                                        </span>
                                    </a>
                                    <a-divider type="vertical"></a-divider>
                                    <router-link :to="`/app/leaveUser/${record.id}`">
                                        <span style="font-size: 14px"
                                            class="material-symbols-outlined">drive_file_rename_outline</span>
                                    </router-link>
                                    <a-divider type="vertical"></a-divider>
                                    <a @click="remove_leave_user(record.id)" 
                                        :style="{ 
                                            pointerEvents: 
                                            record.leave_user_status === 'รออนุมัติ' || 
                                            record.leave_user_status === 'อนุมัติ' || 
                                            record.leave_user_status === 'ไม่อนุมัติ' 
                                                ? 'none' 
                                                : 'auto' 
                                        }"
                                    >
                                        <span 
                                            :style="{ fontSize: '18px', color: record.leave_user_status !== 'ฉบับร่าง' ? 'gray' : 'inherit' }" 
                                            class="material-symbols-outlined">
                                            delete
                                        </span>
                                    </a>
                                </div>
                            </template>
                        </template>
                    </a-table>
                </a-row>
            </a-card>
        </div>

        <div v-else-if="render_type == 'add'" class="app-content">
            <a-card :title="'สร้างคำขอลา'">
                <template #extra>
                    <a-button style="display: flex" @click="$router.go(-1)" size="large">
                        <template #icon>
                            <span class="material-symbols-outlined">arrow_back_ios</span>
                        </template>
                        ย้อนกลับ
                    </a-button>
                </template>

                <a-form layout="vertical" :model="detail" name="detail-form" autocomplete="off" @finish="submitLeaveUser">
                    <a-divider orientation="left">
                        <h>ข้อมูลการแจ้งลา</h>
                    </a-divider>
                    <a-row type="flex">
                        <a-col :span="8">
                            <a-form-item label="ประเภทการลา" name="leave_type_id" style="width: 98%;"
                                :rules="[{ required: true, message: 'กรุณากรอกประเภทการลา' }]">
                                <a-select v-model:value="detail.leave_type_id" placeholder="ประเภทการลา">
                                    <a-select-option
                                        v-for="({ id, name }, i) in filtered_leave_types"
                                        :key="i"
                                        :value="id"
                                        >{{ name }}
                                        </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8" v-if="business">
                            <a-form-item  label="โควตาการลา" name="leave_quota">
                                <a-input v-model:value="detail.leave_quota" placeholder="โควตาการลา" style="width: 98%;" disabled></a-input>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item label="วันที่แจ้งลา" name="leave_user_timestamp">
                                <a-date-picker v-model:value="detail.leave_user_timestamp" placeholder="วันที่แจ้งลา" style="width: 98%;" disabled></a-date-picker>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item label="วันที่เริ่มลา" name="time_start" :rules="[{ required: true, message: 'กรุณากรอกวันที่เริ่มลา' }]">
                                <a-date-picker v-model:value="detail.time_start" placeholder="วันที่เริ่มลา" style="width: 98%;" @change="validateDates"></a-date-picker>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item label="วันที่สิ้นสุดการลา" name="time_end" :rules="[{ required: true, message: 'กรุณากรอกวันที่สิ้นสุดการลา' }]">
                                <a-date-picker v-model:value="detail.time_end" placeholder="วันที่สิ้นสุดการลา" style="width: 98%;" @change="validateDates"></a-date-picker>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item label="จำนวนวันลา" name="leave_user_amount">
                                <a-input v-model:value="leaveUserAmount" placeholder="จำนวนวันลา" disabled></a-input>
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row type="flex" v-if="businessTypeId == 3 || businessTypeId == 2">
                        <a-col :span="8">
                            <a-form-item label="รูปแบบการลา" name="leave_pattern_id" style="width: 98%;"
                                :rules="[{ required: true, message: 'กรุณากรอกรูปแบบการลา' }]">
                                <a-select v-model:value="detail.leave_pattern_id" placeholder="รูปแบบการลา">
                                    <a-select-option
                                        v-for="({ id, name }, i) in leave_pattern_list"
                                        :key="i"
                                        :value="id"
                                        >{{ name }}
                                        </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-divider orientation="left">
                        <h>เอกสารที่เกี่ยวข้อง</h>
                    </a-divider>

                    <div v-if="detail.photo_1 != null && detail.photo_1 != ''" class="image-container">
                        <img :src="detail.photo_1" class="uploaded-image-table" alt="uploaded image" />
                        <div class="icon-container">
                            <span class="material-symbols-outlined preview-icon" @click="showPreview($event, detail.photo_1)">
                                visibility
                            </span>
                            <span class="material-symbols-outlined download-icon"
                                @click="getBase64Image($event, detail.photo_1)">
                                download
                            </span>
                            <span v-if="detail.send_status != 1" class="material-symbols-outlined download-icon"
                                @click="remove_uploaded_file">
                                Delete
                            </span>
                        </div>
                    </div>

                    <a-upload 
                        v-else
                        v-model:file-list="fileList" 
                        list-type="picture-card"
                        class="avatar-uploader"
                        name="photo_1"
                        :action="upload_files"
                        method="GET"
                        @remove="remove_uploaded_file">
                        <div style="height: 104px; width: 104px; padding: 2rem">
                            <span style="font-size: 20px" class="material-symbols-outlined">add</span>
                            <div style="margin-top: 8px">Upload</div>
                        </div>
                    </a-upload>

                    <!-- <a-upload 
                        v-model:file-list="fileList" 
                        list-type="picture-card"
                        class="avatar-uploader"
                        name="photo_1"
                        :action="upload_files"
                        method="GET"
                        @remove="remove_uploaded_file">
                        <div v-if="!detail.photo_1" style="height: 104px; width: 104px; padding: 2rem">
                            <span style="font-size: 20px" class="material-symbols-outlined">add</span>
                            <div style="margin-top: 8px">Upload</div>
                        </div>
                    </a-upload> -->
               
                    <!-- <a-upload 
                        v-model:file-list="fileList"
                        list-type="picture-card"
                        name="photo"
                        class="avatar-uploader"
                        method="GET" accept="image/png, image/jpeg, image/jpg"
                        style="max-height: 200px"
                        :action="upload_files"
                        :showUploadList="false">
                        <img v-if="detail.photo_1" :src="detail.photo_1" style="max-height: 100px" alt="photo"/>
                        <div v-if="!detail.photo_1" style="height: 104px; width: 104px; padding: 2rem">
                            <span style="font-size: 20px" class="material-symbols-outlined">add</span>
                            <div style="margin-top: 8px">Upload</div>
                        </div>
                    </a-upload> -->

                    <a-divider orientation="left">
                        <h>หมายเหตุ ( ผู้แจ้งลา )</h>
                    </a-divider>

                    <a-form-item name="leave_user_remark">
                        <a-textarea v-model:value="detail.leave_user_remark" placeholder="หมายเหตุ" :rows="2"></a-textarea>
                    </a-form-item>

                    <a-row justify="end">
                        <a-col>
                            <a-button type="primary" html-type="submit" size="large"
                                style="margin: 4px;text-align: right">
                                    <span class="material-symbols-outlined" style="font-size: 16px;margin-right: 4px">Edit</span>
                                    บันทึกข้อมูล
                            </a-button>
                        </a-col>
                    </a-row>
                </a-form>
            </a-card>
        </div>

        <div v-else class="app-content">
            <a-card title="แก้ไขคำขอลา">
                <template #extra>
                    <a-button style="display: flex" @click="$router.go(-1)" size="large">
                        <template #icon>
                            <span class="material-symbols-outlined">arrow_back_ios</span>
                        </template>
                        ย้อนกลับ
                    </a-button>
                </template>

                <a-form layout="vertical" :model="detail" name="detail-form" autocomplete="off" @finish="submitUpdateLeaveUser">
                    <a-divider orientation="left">
                        <h>ข้อมูลการแจ้งลา</h>
                    </a-divider>
                    <a-row type="flex">
                        <a-col :span="8">
                            <a-form-item label="ประเภทการลา" name="leave_type_id" style="width: 98%;"
                                :rules="[{ required: true, message: 'กรุณากรอกประเภทการลา' }]">
                                <a-select :disabled="detail.leave_user_status == 'รออนุมัติ' || detail.leave_user_status == 'อนุมัติ' || detail.leave_user_status == 'ไม่อนุมัติ'" v-model:value="detail.leave_type_id" placeholder="ประเภทการลา">
                                    <a-select-option
                                        v-for="({ id, name }, i) in filtered_leave_types"
                                        :key="i"
                                        :value="id"
                                        >{{ name }}
                                        </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8" v-if="business">
                            <a-form-item label="โควตาการลา" name="leave_quota">
                                <a-input v-model:value="detail.leave_quota" placeholder="โควตาการลา" style="width: 98%;" disabled></a-input>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item label="วันที่แจ้งลา" name="leave_user_timestamp">
                                <a-date-picker v-model:value="detail.leave_user_timestamp" placeholder="วันที่แจ้งลา" style="width: 98%;" disabled></a-date-picker>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item label="วันที่เริ่มลา" name="time_start" :rules="[{ required: true, message: 'กรุณากรอกวันที่เริ่มลา' }]">
                                <a-date-picker :disabled="detail.leave_user_status == 'รออนุมัติ' || detail.leave_user_status == 'อนุมัติ' || detail.leave_user_status == 'ไม่อนุมัติ'" v-model:value="detail.time_start" placeholder="วันที่เริ่มลา" style="width: 98%;" @change="validateDates"></a-date-picker>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item label="วันที่สิ้นสุดการลา" name="time_end" :rules="[{ required: true, message: 'กรุณากรอกวันที่สิ้นสุดการลา' }]">
                                <a-date-picker :disabled="detail.leave_user_status == 'รออนุมัติ' || detail.leave_user_status == 'อนุมัติ' || detail.leave_user_status == 'ไม่อนุมัติ'" v-model:value="detail.time_end" placeholder="วันที่สิ้นสุดการลา" style="width: 98%;" @change="validateDates"></a-date-picker>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item label="จำนวนวันลา" name="leave_user_amount">
                                <a-input v-model:value="detail.leave_user_amount" placeholder="จำนวนวันลา" disabled></a-input>
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row type="flex" v-if="businessTypeId == 3 || businessTypeId == 2">
                        <a-col :span="8">
                            <a-form-item label="รูปแบบการลา" name="leave_pattern_id" style="width: 98%;"
                                :rules="[{ required: true, message: 'กรุณากรอกรูปแบบการลา' }]">
                                <a-select v-model:value="detail.leave_pattern_id" 
                                :disabled="detail.leave_user_status == 'รออนุมัติ' || detail.leave_user_status == 'อนุมัติ' || detail.leave_user_status == 'ไม่อนุมัติ'"
                                placeholder="รูปแบบการลา">
                                    <a-select-option
                                        v-for="({ id, name }, i) in leave_pattern_list"
                                        :key="i"
                                        :value="id"
                                        >{{ name }}
                                        </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-divider orientation="left">
                        <h>เอกสารที่เกี่ยวข้อง</h>
                    </a-divider>

                    <div v-if="detail.photo_1 != null && detail.photo_1 != ''" class="image-container">
                        <img :src="detail.photo_1" class="uploaded-image-table" alt="uploaded image" />
                        <div class="icon-container">
                            <span class="material-symbols-outlined preview-icon" @click="showPreview($event, detail.photo_1)">
                                visibility
                            </span>
                            <span class="material-symbols-outlined download-icon"
                                @click="getBase64Image($event, detail.photo_1)">
                                download
                            </span>
                            <span v-if="detail.send_status != 1" class="material-symbols-outlined download-icon"
                                @click="remove_uploaded_file">
                                Delete
                            </span>
                        </div>
                    </div>

                    <a-upload 
                        v-else
                        :disabled="detail.leave_user_status == 'รออนุมัติ' || detail.leave_user_status == 'อนุมัติ' || detail.leave_user_status == 'ไม่อนุมัติ'"
                        v-model:file-list="fileList" 
                        list-type="picture-card"
                        class="avatar-uploader"
                        name="photo_1"
                        :action="upload_files"
                        method="GET"
                        @remove="remove_uploaded_file">
                        <div style="height: 104px; width: 104px; padding: 2rem">
                            <span style="font-size: 20px" class="material-symbols-outlined">add</span>
                            <div style="margin-top: 8px">Upload</div>
                        </div>
                    </a-upload>

                    <!-- <a-form-item label="รูปภาพ" name="photo_1">
                        <a-upload v-model:file-list="fileList" list-type="picture-card"
                                class="avatar-uploader"
                                action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                @change="upload_files($event, 'photo_1')" method="GET"
                                @remove="remove_edit_uploaded_file($event)">
                            <div v-if="fileList?.length < 5" style="height: 104px; width: 104px; padding: 2rem">
                                <span style="font-size: 20px" class="material-symbols-outlined">add</span>
                                <div style="margin-top: 8px">Upload</div>
                            </div>
                        </a-upload>
                    </a-form-item> -->

                    <a-divider orientation="left">
                        <h>หมายเหตุ ( ผู้แจ้งลา )</h>
                    </a-divider>

                    <a-form-item name="leave_user_remark">
                        <a-textarea :disabled="detail.leave_user_status == 'รออนุมัติ' || detail.leave_user_status == 'อนุมัติ' || detail.leave_user_status == 'ไม่อนุมัติ'" v-model:value="detail.leave_user_remark" placeholder="หมายเหตุ" :rows="2"></a-textarea>
                    </a-form-item>

                    <a-row justify="end">
                        <a-col>
                            <a-button type="primary" html-type="submit" size="large"
                                style="margin: 4px;text-align: right">
                                    <span class="material-symbols-outlined" style="font-size: 16px;margin-right: 4px">Edit</span>
                                    บันทึกข้อมูล
                            </a-button>
                        </a-col>
                    </a-row>
                </a-form>
                <a-modal v-model:visible="previewVisible" :footer="null" :mask="false" @cancel="previewVisible = false"
                    class="custom-modal" style="background-color: none !important">
                    <img :src="previewImage" alt="Preview" style="width: 100%;" />
                </a-modal>
            </a-card>
        </div>

    </div>
</template>

<script>
import Utility from '../../utility'
import Swal from 'sweetalert2'
import SystemService from '../../api/SystemService'
import AdminService from '../../api/AdminService';
import UserService from '../../api/UserService';
import LeaveUserService from '../../api/LeaveUserService';
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import { message } from "ant-design-vue";
import { h } from 'vue';
import CompanyService from '../../api/CompanyService'
import LeaveService from '../../api/LeaveService';

dayjs.locale('th')
export default {
    name: 'LeaveUser',
    computed: {
        leave_quota_limits() {
            return this.businessTypeId == 4
                ? {
                    1: 7,  // ลากิจ: 7 ครั้ง/ปี
                    2: 12,  // ลาพักร้อน: 12 ครั้ง/ปี
                    4: 30, // ลาป่วย: 30 ครั้ง/ปี
                    6: null, // ลาชาปนกิจ: ไม่มีเงื่อนไข
                }
                : {
                    1: 3,  // ลากิจ: 3 ครั้ง/ปี
                    2: 8,  // ลาพักร้อน: 8 ครั้ง/ปี
                    7: 4,  // ลาฝึกอบรม: 4 ครั้ง/ปี
                    4: 30, // ลาป่วย: 30 ครั้ง/ปี
                    5: null, // ลาแบบไม่รับค่าจ้าง: ไม่มีเงื่อนไข
                    6: null, // ลาชาปนกิจ: ไม่มีเงื่อนไข
                };
        },
        leaveUserAmount() {
            if (this.detail.time_start && this.detail.time_end) {
                const start = dayjs(this.detail.time_start).startOf('day');
                const end = dayjs(this.detail.time_end).startOf('day');
                
                // คำนวณจำนวนวันลา
                const diffDays = end.diff(start, 'day') + 1;
                this.detail.leave_user_amount = diffDays;

                console.log("🔹 [คำนวณจำนวนวันลา]");
                console.log("📅 วันที่เริ่มลา:", start.format("YYYY-MM-DD"));
                console.log("📅 วันที่สิ้นสุดการลา:", end.format("YYYY-MM-DD"));
                console.log("📊 จำนวนวันลา:", diffDays);

                return diffDays;
            }
            return 0;
        },
        render_type() {
            this.clear_leave_user()
            if(this.$route.params.type != 'add' && this.$route.params.type != 'all'){
                this.init_edit_leave_user()
            }
            return this.$route.params.type
        },
        filteredColumns() {
            if (Array.isArray(this.columns)) {
                return this.columns.filter(column => {
                    if(this.businessTypeId != 3 && this.businesssTypeId != 2){
                        return column.key !== 'leave_pattern_id'
                    }
                    return column.key !== 'leave_quota' || this.business;
                });
            }
            return [];
        
        },
    },
    data() {
        return {
            leave_quota_data: [], // ดึงข้อมูลโควตาจาก API
            isLeaveUserSent: false,
            businessTypeId:null,
            leave_pattern_list:[],
            previewImage: "",
            previewVisible: false,
            previousTimeEnd: null,
            create_leave_user: [],
            create_photo: [],
            dataById: null,
            detail: {
                id: null,
                leave_type_id: null,
                leave_pattern_id:null,
                leave_quota: null,
                leave_user_no: null,
                leave_user_timestamp: dayjs(),
                time_start: null,
                time_end: null,
                leave_user_amount: null,
                leave_user_remark: null,
                company_id: null,
                leave_user_status: 'ฉบับร่าง',
                user_name: null,
                fullname: null,
                photo_1: null,
                photo_2: null,
                photo_3: null,
                photo_4: null,
                photo_5: null,
            },
            // editable: Utility.get_permission_editable_with_key('leave_user'),
            timeStart: null,
            timeEnd: null,
            leave_types: [],
            business: false,
            filtered_leave_types: [],
            loading: false,
            search_business_type: null,
            img_placeholder: require('@/assets/images/place-holder/placeholder.jpg'),
            show_table_list: [],
            selected_leave_user: null,
            fileList: [],
            input: 'Username / ชื่อบริษัท',
            search_input: null,
            columns: [
                {
                    title: 'วันที่แจ้งลา',
                    dataIndex: 'leave_user_timestamp',
                    key: 'leave_user_timestamp',
                    width: 120,
                    align: 'center',
                },
                {
                    title: 'วันที่ลา',
                    dataIndex: 'leave_user_date',
                    key: 'leave_user_date',
                    width: 180,
                    align: 'center',
                }, 
                {
                    title: 'เลขกำกับใบลา',
                    dataIndex: 'leave_user_no',
                    key: 'leave_user_no',
                    width: 150,
                    align: 'center',
                }, 
                {
                    title: 'ปรเภทการลา',
                    dataIndex: 'leave_type_id',
                    key: 'leave_type_id',
                    width: 120,
                    align: 'center',
                }, 
                {
                    title: 'โควตาการลา',
                    dataIndex: 'leave_quota',
                    key: 'leave_quota',
                    width: 120,
                    align: 'center',
                }, 
                {
                    title: 'จำนวนวันลา',
                    dataIndex: 'leave_user_amount',
                    key: 'leave_user_amount',
                    width: 100,
                    align: 'center',
                },
                {
                    title: 'รูปแบบการลา',
                    dataIndex: 'leave_pattern_id',
                    key: 'leave_pattern_id',
                    width: 120,
                    align: 'center',
                }, 
                {
                    title: 'สถานะใบลา',
                    dataIndex: 'leave_user_status',
                    key: 'leave_user_status',
                    width: 100,
                    align: 'center',
                }, 
                {
                    title: 'หมายเหตุ',
                    dataIndex: 'leave_user_remark',
                    key: 'leave_user_remark',
                    width: 150,
                    align: 'center',
                }, 
                {
                    title: 'ตัวเลือก',
                    dataIndex: 'id',
                    key: 'id',
                    width: 130,
                    align: 'center',
                    fixed: 'right'
                }
            ],
        }
    },
    watch: {
    // 'detail.time_start': function(newVal) {
    //             console.log("🕵️‍♂️ [watch] วันที่เริ่มลาเปลี่ยน:", newVal);
    //         },
    //         'detail.time_end': function(newVal) {
    //             console.log("🕵️‍♂️ [watch] วันที่สิ้นสุดการลาเปลี่ยน:", newVal);
    //         }
        },
    methods: {
        async getBase64Image(e, file) {
            e.stopPropagation();
            e.preventDefault();

            if (typeof file === 'string') {
                const result = await this.getBase64ImageFromURL(file);
                let a = document.createElement('a');
                a.href = result;
                a.download = 'image.png';
                a.click();
            } else if (file.url) {
                const result = await this.getBase64ImageFromURL(file.url);

                // download
                let a = document.createElement('a');
                a.href = result;
                a.download = 'image.png';
                a.click();
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => {
                    const base64String = reader.result;
                    let a = document.createElement('a');
                    a.href = base64String;
                    a.download = 'image.png';
                    a.click();
                };
            }
        },
        showPreview(e, fileUrl) {
            e.stopPropagation();
            this.previewImage = fileUrl;
            this.previewVisible = true;
        },
        async getBase64ImageFromURL(url) {
            const res = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            });
            const blob = await res.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        },
        validateDates() {
            if (this.detail.time_start && this.detail.time_end) {
                const start = dayjs(this.detail.time_start).startOf('day');
                const end = dayjs(this.detail.time_end).startOf('day');
                // console.log("✅ [validateDates] ตรวจสอบวันที่");
                // console.log("📅 วันที่เริ่มลา:", start.format("YYYY-MM-DD"));
                // console.log("📅 วันที่สิ้นสุดการลา:", end.format("YYYY-MM-DD"));
                if (end.isBefore(start)) {
                    // console.log("❌ [Error] วันที่สิ้นสุดต้องไม่น้อยกว่าวันที่เริ่ม");
                    this.$message.error('กรุณากรอกวันที่สิ้นสุดให้มากกว่าวันที่เริ่มลา');
                    this.detail.time_end = null;
                } else {
                    const diffDays = end.diff(start, 'day') + 1;
                    this.detail.leave_user_amount = diffDays;
                    // console.log("✅ [Success] จำนวนวันลา:", diffDays);
                }
            }
        },
        clear_leave_user() {
            this.detail = {
                id: null,
                leave_type_id: null,
                leave_user_no: null,
                leave_user_timestamp: dayjs(),
                time_start: null,
                time_end: null,
                leave_user_amount: null,
                leave_user_remark: null,
                company_id: null,
                leave_user_status: 'ฉบับร่าง',
                user_name: null,
                fullname: null,
                photo_1: null,
                photo_2: null,
                photo_3: null,
                photo_4: null,
                photo_5: null,
            }
            this.create_leave_user = []
            this.create_photo = []
            this.fileList = []
        },
        async upload_files(e) {
            try {
                const base64 = await this.getBase64(e);
                this.detail.photo_1 = base64;
                const response = await fetch('https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6');
                if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
                return response.url;
            } catch (error) {
                console.error('Error uploading files:', error);
            }
        },
        getBase64(file) {
            return new Promise((resolve, reject) =>{
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result)
                reader.onerror = error => reject(error)
            })
        },
        async remove_uploaded_file() {
            this.fileList = [];
            this.detail.photo_1 = null
            this.onRefresh();
        },
        async remove_edit_uploaded_file({ uid }) {
            const { id } = this.detail
            this.create_leave_user[uid] = ''
            await SystemService.update_all('LeaveUser', { data: { photo_1: null } }, id);
            this.onRefresh()
        },
        onResetData(){
             this.detail =  {
                id: null,
                leave_type_id: null,
                leave_user_no: null,
                leave_user_timestamp: dayjs(),
                time_start: null,
                time_end: null,
                leave_user_amount: null,
                leave_user_remark: null,
                company_id: null,
                leave_user_status: 'ฉบับร่าง',
                user_name: null,
                fullname: null,
                photo_1: null,
                photo_2: null,
                photo_3: null,
                photo_4: null,
                photo_5: null,
            }
        },
        async init_edit_leave_user() {
            this.selected_leave_user = await LeaveUserService.getLeaveUserByCompanyAndId(this.$route.params.type)
            this.detail = this.selected_leave_user[0];
            this.fileList = this.selected_leave_user
                .filter(item => item.photo_1)
                .map((item, index) => {
                    return {
                        uid: index,
                        name: item.photo_1,
                        status: 'done',
                        url: item.photo_1,
                    };
                });
            this.detail.leave_user_timestamp = dayjs(this.selected_leave_user[0].created_at)
            this.detail.time_start = dayjs(this.selected_leave_user[0].time_start)
            this.detail.time_end = dayjs(this.selected_leave_user[0].time_end)
        },
        clear_search() {
            this.search_input = null;
            this.timeStart = null;
            this.timeEnd = null;
        },
        onRefresh() {
             this.init_leave_users()
        },
        remove_leave_user(id){
            Swal.fire({
                title: 'คุณต้องการลบใช่หรือไม่??',
                text: `คุณจะไม่สามารถกู้คืนได้`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ยกเลิก'
                }).then(async (result) => {
                if (result.isConfirmed) {
                    await SystemService.delete_all('LeaveUser', id)
                    this.onRefresh()
                }
            })
        },
        async send_leave_user(id) {
            try {
                const leaveUserData = await LeaveUserService.getLeaveUserByCompanyAndId(id);
                if (!leaveUserData || leaveUserData.length === 0) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'ไม่พบข้อมูลคำขอลา กรุณาลองใหม่',
                        icon: 'error',
                        confirmButtonText: 'ตกลง'
                    });
                    return;
                }

                const startDate = dayjs(leaveUserData[0].time_start).startOf('day');
                const endDate = dayjs(leaveUserData[0].time_end).endOf('day');

                const existingLeaves = await LeaveUserService.getLeaveUserByCompany();

                // ตรวจสอบว่ามีคำขอที่ซ้ำกันและมีสถานะ "อนุมัติ" หรือ "รออนุมัติ" หรือไม่
                const overlappingLeave = existingLeaves.find(leave => {
                    const existingStart = dayjs(leave.time_start).startOf('day');
                    const existingEnd = dayjs(leave.time_end).endOf('day');

                    return (
                        startDate.isBefore(existingEnd) && 
                        endDate.isAfter(existingStart) && 
                        (leave.leave_user_status === "อนุมัติ" || leave.leave_user_status === "รออนุมัติ") &&
                        leave.id !== id
                    );
                });

                if (overlappingLeave) {
                    const statusText = overlappingLeave.leave_user_status; // ดึงสถานะจริงของคำขอที่ทับซ้อน

                    Swal.fire({
                        title: 'ไม่สามารถลาวันที่ซ้ำได้',
                        html: `มีคำขอลาที่ <b>"${statusText}"</b> แล้วในช่วงเวลาที่คุณเลือก <br> กรุณาเลือกช่วงเวลาอื่น`,
                        icon: 'error',
                        confirmButtonText: 'ตกลง'
                    });
                    return;
                }
                await SystemService.update_all('LeaveUser', { data: { leave_user_status: 'รออนุมัติ', send_status: 1 } }, id);
                const selectedData = {
                    leave_user_id: leaveUserData[0].id,
                    user_name: leaveUserData[0].user_name,
                    leave_user_timestamp: this.render_date(leaveUserData[0].leave_user_timestamp),
                    time_start: this.render_date(leaveUserData[0].time_start),
                    time_end: this.render_date(leaveUserData[0].time_end),
                    leave_user_no: leaveUserData[0].leave_user_no,
                    leave_type_id: leaveUserData[0].leave_type_id,
                    leave_pattern_id: leaveUserData[0].leave_pattern_id,
                    leave_user_amount: leaveUserData[0].leave_user_amount,
                    leave_user_remark: leaveUserData[0].leave_user_remark,
                    fullname: leaveUserData[0].fullname,
                    photo_1: leaveUserData[0].photo_1,
                    photo_2: leaveUserData[0].photo_2,
                    photo_3: leaveUserData[0].photo_3,
                    photo_4: leaveUserData[0].photo_4,
                    photo_5: leaveUserData[0].photo_5,
                    leave_progress: 1,
                    created_at: dayjs(),
                    company_id: leaveUserData[0].company_id,
                };
                const response = await SystemService.create_all('LeaveAdmin', { data: [selectedData] });

                message.success("ส่งการแจ้งลาสำเร็จ");
                this.isLeaveUserSent = true;
                this.onRefresh();
            } catch (error) {
                console.error("Error sending leave user:", error);
                message.error("เกิดข้อผิดพลาดในการส่งการแจ้งลา");
            }
        },
        async edit_leave_user(id){
            this.$router.push(`/app/leaveUser/edit/${id}`)
        },
        getLeaveTypeName(id) {
            const leaveType = this.leave_types.find(type => type.id === id);
            return leaveType ? leaveType.name : '';
        },
        getLeavePatternTypeName(id) {
            const leaveType = this.leave_pattern_list.find(type => type.id === id);
            return leaveType ? leaveType.name : '';
        },
        filtered_list(array) {
            return array.filter(({ name, time_start, time_end }) => {
                let matchesName = true;
                let matchesDateRange = true;
                if (this.search_input) {
                    matchesName = name?.toLowerCase().includes(this.search_input.toLowerCase());
                }
                // ตรวจสอบช่วงวันที่ (ไม่สนใจเวลา)
                if (this.timeStart && this.timeEnd) {
                    const filterStart = dayjs(this.timeStart).startOf('day');
                    const filterEnd = dayjs(this.timeEnd).startOf('day');
                    const start = dayjs(time_start).startOf('day');
                    const end = dayjs(time_end).startOf('day');
                    matchesDateRange = start.isSame(filterStart) && end.isSame(filterEnd);
                }
                return matchesName && matchesDateRange;
            });
        },
        async init_leave_pattern(){
            this.leave_pattern_list = await LeaveService.getLeavePatternAll();
        },
        async init_leave_users() {
            try {
                const companyData = localStorage.getItem('companyData');
                if (!companyData) {
                    console.error("Missing company data in localStorage");
                    return;
                }
                const company_id = JSON.parse(companyData).id;
                this.show_table_list = await LeaveUserService.getLeaveUserByCompany();
                if (!this.show_table_list || !Array.isArray(this.show_table_list)) {
                    console.error("show_table_list is undefined or not an array.");
                    return;
                }
                const leaveProgressData = this.show_table_list;
                // console.log(" Loaded leave user list:", leaveProgressData);
                const leaveQuotaPromises = leaveProgressData.map(async (item) => {
                    const username = item.user_name;
                    // console.log(" Processing user:", username);
                    let leaveQuotaData = []
                    if(item.leave_user_status == "รออนุมัติ"||item.leave_user_status == "ฉบับร่าง"){
                        leaveQuotaData = await SystemService.leavequota(company_id, username);
                    }else{
                        leaveQuotaData = await SystemService.leavequotaused(company_id,username,item.leave_user_no);
                    }
                    leaveQuotaData = leaveQuotaData.data;
                    const quota = leaveQuotaData.find(q => q.leave_type_id === item.leave_type_id);
                    const usedQuota = quota ? quota.count : 0;
                    const totalQuota = this.leave_quota_limits[item.leave_type_id] || '-';

                    return {
                        ...item,
                        leave_quota: totalQuota !== '-' ? `${usedQuota}/${totalQuota}` : '-',
                    };
                });
                this.show_table_list = await Promise.all(leaveQuotaPromises);
                // console.log(" Final show_table_list:", this.show_table_list);
                this.leave_types = await SystemService.get_core('getLeaveType');
            } catch (error) {
                console.error(" Error initializing leave users:", error);
            }
        },
        render_date(datetime) {
            return dayjs(datetime).format('YYYY/MM/DD')
        },
        async submitLeaveUser() {
            const today = dayjs();
            const startDate = dayjs(this.detail.time_start).startOf('day'); // แปลงเป็นช่วงเริ่มต้นของวัน
            const endDate = dayjs(this.detail.time_end).endOf('day');
            const existingLeaves = await LeaveUserService.getLeaveUserByCompany();
            // console.log('5678',existingLeaves)
            const isOverlap = existingLeaves.some(leave => {
                const existingStart = dayjs(leave.time_start).startOf('day'); // เปรียบเทียบเฉพาะวันที่
                const existingEnd = dayjs(leave.time_end).endOf('day');

                // เงื่อนไขตรวจสอบการทับซ้อน
                return (
                    startDate.isBefore(existingEnd) && // วันที่เริ่มต้นต้องน้อยกว่าวันสิ้นสุดที่มีอยู่
                    endDate.isAfter(existingStart)    // วันที่สิ้นสุดต้องมากกว่าวันเริ่มต้นที่มีอยู่
                );
            }); 

            // if (isOverlap) {
            //     Swal.fire({
            //         title: 'ไม่สามารถลาวันที่ซ้ำได้',
            //         text: 'ช่วงเวลาที่คุณเลือกมีคำขอลาอยู่แล้ว กรุณาเลือกช่วงเวลาอื่น',
            //         icon: 'error',
            //         confirmButtonText: 'ตกลง'
            //     });
            //     return;
            // }

            if (this.detail.leave_type_id == 1) {
                const diffDays = startDate.diff(today, 'day');
                if (this.business) { 
                    if (diffDays < 1 && this.businessTypeId != 4) {
                        Swal.fire({
                            title: 'กรุณาเปลี่ยนช่วงเวลาในการลา',
                            text: 'เนื่องจากการลากิจต้องลาล่วงหน้า 2 วัน เท่านั้น',
                            icon: 'warning',
                            confirmButtonText: 'ตกลง'
                        });
                        return;
                    }else if(diffDays < 2 && this.businessTypeId == 4){
                        Swal.fire({
                            title: 'กรุณาเปลี่ยนช่วงเวลาในการลา',
                            text: 'เนื่องจากการลากิจต้องลาล่วงหน้า 3 วัน เท่านั้น',
                            icon: 'warning',
                            confirmButtonText: 'ตกลง'
                        });
                        return;
                    }
                } else { // กรณีที่ไม่ใช่ business
                    if (diffDays < 6) {
                        Swal.fire({
                            title: 'กรุณาเปลี่ยนช่วงเวลาในการลา',
                            text: 'เนื่องจากการลากิจต้องลาล่วงหน้า 7 วัน เท่านั้น',
                            icon: 'warning',
                            confirmButtonText: 'ตกลง'
                        });
                        return;
                    }
                }
            }
            if (this.detail.leave_type_id == 2) {
                const diffDays = startDate.diff(today, 'day');
                if ( diffDays < 1 && this.businessTypeId != 4) {
                    Swal.fire({
                        title:'กรุณาเปลี่ยนช่วงเวลาในการลา',
                        text: `เนื่องจากการลาพักร้อนต้องลาล่วงหน้า 2 วัน เท่านั้น`,
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    });
                    return;
                }else if(diffDays < 2 && this.businessTypeId == 4){
                    Swal.fire({
                        title:'กรุณาเปลี่ยนช่วงเวลาในการลา',
                        text: `เนื่องจากการลาพักร้อนต้องลาล่วงหน้า 3 วัน เท่านั้น`,
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    });
                    return;
                }
            }
            if (this.detail.leave_type_id == 7) {
                const diffDays = startDate.diff(today, 'day');
                if (this.business && diffDays < 2) {
                    Swal.fire({
                        title: 'กรุณาเปลี่ยนช่วงเวลาในการลา',
                        text: 'เนื่องจากการลาฝึกอบรมต้องลาล่วงหน้า 3 วัน เท่านั้น',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    });
                    return;
                }
            }
            if (this.detail.leave_type_id == 3) {
                const diffDays = startDate.diff(today, 'day');
                if (diffDays < 1) {
                    Swal.fire({
                        title: 'กรุณาเปลี่ยนช่วงเวลาในการลา',
                        text: 'เนื่องจากการลาครึ่งวันต้องลาล่วงหน้า 2 วัน เท่านั้น',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    });
                    return;
                }
            }
            if (this.detail.leave_type_id == 4 && this.businessTypeId != 4) {
                const diffDays = startDate.diff(today, 'day');
                const leaveDays = endDate.diff(startDate, 'day');

                if (leaveDays >= 3 && this.detail.photo_1 == null) {
                    Swal.fire({
                        title: 'กรุณาใส่รูปเอกสารที่เกี่ยวข้อง',
                        text: 'เนื่องจากการลาป่วยมากกว่า 3 วันต้องมีใบรับรองแพทย์',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    });
                    return;
                }
            }
            if (this.detail.leave_type_id == 4 && this.businessTypeId == 4) {
                const diffDays = startDate.diff(today, 'day');
                const leaveDays = endDate.diff(startDate, 'day');

                if (leaveDays >= 2 && this.detail.photo_1 == null) {
                    Swal.fire({
                        title: 'กรุณาใส่รูปเอกสารที่เกี่ยวข้อง',
                        text: 'เนื่องจากการลาป่วยมากกว่า 2 วันต้องมีใบรับรองแพทย์',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    });
                    return;
                }
            }
            if (this.detail.leave_type_id == 6) {
                const leaveDays = endDate.diff(startDate, 'day');
                if (leaveDays > 2 && this.businessTypeId != 4) {
                    Swal.fire({
                        title: 'กรุณาเปลี่ยนช่วงเวลาในการลา',
                        text: 'เนื่องจากการลาฌาปนกิจต้องลาได้ไม่เกิน 3 วัน เท่านั้น',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    });
                    return;
                }else if (leaveDays > 4 && this.businessTypeId == 4) {
                    Swal.fire({
                        title: 'กรุณาเปลี่ยนช่วงเวลาในการลา',
                        text: 'เนื่องจากการลาฌาปนกิจต้องลาได้ไม่เกิน 5 วัน เท่านั้น',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    });
                    return;
                }
            }
            const hideLoading = message.loading({
                content: "กำลังบันทึกข้อมูล...",
                key: "detail",
                duration: 0, 
            });
            if (this.fileList) {
                for (var i = 0; i < this.fileList.length; i++) {
                    const target = this.fileList[i];
                    this.detail['photo_' + (i + 1)] = await this.getBase64(target.originFileObj);
                }
            }
            this.detail.company_id = JSON.parse(localStorage.getItem('companyData')).id;
            this.detail.user_name = JSON.parse(localStorage.getItem('user_profile')).username;
            this.detail.fullname = JSON.parse(localStorage.getItem('user_profile')).fullname;
            await SystemService.create_all('LeaveUser', { data: [this.detail] });
            hideLoading();
            Swal.fire(
                'เพิ่มคำขอลาสำเร็จ!',
                'เพิ่มคำขอลาสำเร็จแล้ว',
                'success'
            );
            this.onResetData();
            this.$router.go(-1);
            this.onRefresh();
        },
        async submitUpdateLeaveUser() {
            const today = dayjs();  // เพิ่มกำหนดค่า today
            const startDate = dayjs(this.detail.time_start);  // เพิ่มกำหนดค่า startDate
            const endDate = dayjs(this.detail.time_end);
            const { id } = this.detail
            if (this.detail.leave_type_id == 1) {
                const diffDays = startDate.diff(today, 'day');
                if (this.business) { 
                    if (diffDays < 1 && this.businessTypeId != 4) {
                        Swal.fire({
                            title: 'กรุณาเปลี่ยนช่วงเวลาในการลา',
                            text: 'เนื่องจากการลากิจต้องลาล่วงหน้า 2 วัน เท่านั้น',
                            icon: 'warning',
                            confirmButtonText: 'ตกลง'
                        });
                        return;
                    }else if(diffDays < 2 && this.businessTypeId == 4){
                        Swal.fire({
                            title: 'กรุณาเปลี่ยนช่วงเวลาในการลา',
                            text: 'เนื่องจากการลากิจต้องลาล่วงหน้า 3 วัน เท่านั้น',
                            icon: 'warning',
                            confirmButtonText: 'ตกลง'
                        });
                        return;
                    }
                } else { // กรณีที่ไม่ใช่ business
                    if (diffDays < 6) {
                        Swal.fire({
                            title: 'กรุณาเปลี่ยนช่วงเวลาในการลา',
                            text: 'เนื่องจากการลากิจต้องลาล่วงหน้า 7 วัน เท่านั้น',
                            icon: 'warning',
                            confirmButtonText: 'ตกลง'
                        });
                        return;
                    }
                }
            }
            if (this.detail.leave_type_id == 2) {
                const today = dayjs();
                const startDate = dayjs(this.detail.time_start);
                const diffDays = startDate.diff(today, 'day');
                if ( diffDays < 1 && this.businessTypeId != 4) {
                    Swal.fire({
                        title:'กรุณาเปลี่ยนช่วงเวลาในการลา',
                        text: `เนื่องจากการลาพักร้อนต้องลาล่วงหน้า 2 วัน เท่านั้น`,
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    });
                    return;
                }else if(diffDays < 2 && this.businessTypeId == 4){
                    Swal.fire({
                        title:'กรุณาเปลี่ยนช่วงเวลาในการลา',
                        text: `เนื่องจากการลาพักร้อนต้องลาล่วงหน้า 3 วัน เท่านั้น`,
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    });
                    return;
                }
            }
            if (this.detail.leave_type_id == 7) {
                const diffDays = startDate.diff(today, 'day');
                if (this.business && diffDays < 2) {
                    Swal.fire({
                        title: 'กรุณาเปลี่ยนช่วงเวลาในการลา',
                        text: 'เนื่องจากการลาฝึกอบรมต้องลาล่วงหน้า 3 วัน เท่านั้น',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    });
                    return;
                }
            }
            if (this.detail.leave_type_id == 3) {
                const today = dayjs();
                const startDate = dayjs(this.detail.time_start);
                const diffDays = startDate.diff(today, 'day');
                if (diffDays < 1) {
                    Swal.fire({
                        title:'กรุณาเปลี่ยนช่วงเวลาในการลา',
                        text: `เนื่องจากการลาครึ่งวันต้องลาล่วงหน้า 2 วัน เท่านั้น`,
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    });
                    return;
                }
            }
            if (this.detail.leave_type_id == 4 && this.businessTypeId != 4) {
                const diffDays = startDate.diff(today, 'day');
                const leaveDays = endDate.diff(startDate, 'day');

                if (leaveDays >= 3 && this.detail.photo_1 == null) {
                    Swal.fire({
                        title: 'กรุณาใส่รูปเอกสารที่เกี่ยวข้อง',
                        text: 'เนื่องจากการลาป่วยมากกว่า 3 วันต้องมีใบรับรองแพทย์',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    });
                    return;
                }
            }
            if (this.detail.leave_type_id == 4 && this.businessTypeId == 4) {
                const diffDays = startDate.diff(today, 'day');
                const leaveDays = endDate.diff(startDate, 'day');

                if (leaveDays >= 2 && this.detail.photo_1 == null) {
                    Swal.fire({
                        title: 'กรุณาใส่รูปเอกสารที่เกี่ยวข้อง',
                        text: 'เนื่องจากการลาป่วยมากกว่า 2 วันต้องมีใบรับรองแพทย์',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    });
                    return;
                }
            }
            if (this.detail.leave_type_id == 6) {
                const startDate = dayjs(this.detail.time_start);
                const endDate = dayjs(this.detail.time_end);
                const diffDays = endDate.diff(startDate, 'day') + 1;
                if (diffDays > 2 && this.businessTypeId != 4) {
                    Swal.fire({
                        title: 'กรุณาเปลี่ยนช่วงเวลาในการลา',
                        text: 'เนื่องจากการลาฌาปนกิจต้องลาได้ไม่เกิน 3 วัน เท่านั้น',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    });
                    return;
                }else if (diffDays > 4 && this.businessTypeId == 4) {
                    Swal.fire({
                        title: 'กรุณาเปลี่ยนช่วงเวลาในการลา',
                        text: 'เนื่องจากการลาฌาปนกิจต้องลาได้ไม่เกิน 5 วัน เท่านั้น',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    });
                    return;
                }
            }
            const hideLoading = message.loading({
                content: "กำลังบันทึกข้อมูล...",
                key: "detail",
                duration: 0, 
            });
            // if (this.fileList) {
            //     for (var i=0;i<this.fileList.length;i++) {
            //         const target = this.fileList[i]
            //         this.detail['photo_' + (i + 1)] = await this.getBase64(target.originFileObj)
            //     }
            // }
            if (this.fileList) {
                for (var i=0;i<this.fileList.length;i++) {
                    const target = this.fileList[i];
                    if (target.originFileObj instanceof Blob) {
                        this.detail['photo_' + (i + 1)] = await this.getBase64(target.originFileObj);
                    } else {
                        console.error('File is not of type Blob');
                    }
                }
            }
            this.detail.company_id = JSON.parse(localStorage.getItem('companyData')).id
            this.detail.user_name = JSON.parse(localStorage.getItem('user_profile')).username
            await SystemService.update_all('LeaveUser', { data: this.detail }, id)
            hideLoading()
            Swal.fire(
                'เพิ่มคำขอลาสำเร็จ!',
                'เพิ่มคำขอลาสำเร็จแล้ว',
                'success'
            )
            this.onResetData()
            this.$router.go(-1)
            this.onRefresh()
        },

        async get_company() {
			const company = await UserService.get_user_profile();
            const businessType = await CompanyService.getBusinessId(company.company_id)
            if ( businessType.data === 2 || businessType.data === 3 || businessType.data === 4)
            {
                this.business = true
            }
        },
        async get_businessType() {
            const company = await UserService.get_user_profile();
            const businessType = await CompanyService.getBusinessId(company.company_id)
            this.businessTypeId = businessType.data
        },
        filter_leave_types() {
            // กรองประเภทการลาตาม business
            if (this.business) {
                var order = []
                if(this.businessTypeId == 4){
                    this.filtered_leave_types = this.leave_types.filter(
                        (type) => ![3, 5, 7].includes(type.id) 
                    );

                    order = [1, 2, 4, 6];
                }else{
                    this.filtered_leave_types = this.leave_types.filter(
                        (type) => ![ 3].includes(type.id) 
                    );

                    order = [1, 2, 7, 4, 5, 6];
                }

                this.filtered_leave_types.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));
                
            } else {
                this.filtered_leave_types = this.leave_types.filter(
                    (type) => ![ 7].includes(type.id) 
                );
            }
            console.log("filtered_leave_types");
        },
        async fetchLeaveQuota() {
        const company_id = JSON.parse(localStorage.getItem('companyData')).id;
        const username = JSON.parse(localStorage.getItem('user_profile')).username;
        const response = await SystemService.leavequota(company_id, username);
        if (response.success) {
            this.leave_quota_data = response.data;
        }
    },
    async updateLeaveQuota() {
        if (this.detail.leave_type_id) {
            if(this.detail.leave_user_status == "อนุมัติ"||this.detail.leave_user_status == "ไม่อนุมัติ"){
                const leaveQuotaData = await SystemService.leavequotaused(this.detail.company_id,this.detail.user_name,this.detail.leave_user_no);
                this.leave_quota_data = leaveQuotaData.data
            }else{
                const company_id = JSON.parse(localStorage.getItem('companyData')).id;
                const username = JSON.parse(localStorage.getItem('user_profile')).username;
                const response = await SystemService.leavequota(company_id, username);
                this.leave_quota_data = response.data;
            }
            const quota = this.leave_quota_data.find(
                (item) => item.leave_type_id === this.detail.leave_type_id
            );
            const usedQuota = quota ? quota.count : 0; // จำนวนที่ใช้ไป
            const totalQuota = this.leave_quota_limits[this.detail.leave_type_id]; // โควตาทั้งหมด

            if (totalQuota !== null) {
                this.detail.leave_quota = `${usedQuota}/${totalQuota}`; // เช่น "1/3"
            } else {
                this.detail.leave_quota = `-`; // ไม่มีเงื่อนไข
            }
        }
    },
    },
    watch: {
    'detail.leave_type_id': function () {
        this.updateLeaveQuota();
    },
},



    async mounted() {
        // console.log('5678',this.editable);
        await this.get_businessType();
        await this.init_leave_pattern()
        this.init_leave_users().then(() => {
        this.get_company().then(() => {
            this.filter_leave_types();
        });
    });
    this.fetchLeaveQuota();
    }
}
</script>

<style>
.preview-icon,
.download-icon {
    cursor: pointer;
    font-size: 2px;
    color: black;
}
.image-container {
    position: relative;
    display: inline-block;
}
.uploaded-image-table {
    display: block;
    width: 20rem;
    height: auto;
}
.icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    padding: 5px;
}

.permission-table .ant-table-row-level-0 td:first-child,
.permission-table .ant-table-row-level-0 td:nth-child(2),
.permission-table .ant-table-row-level-0 td:nth-child(3) {
    background-color: #f9f9f9 !important;
}
.permission-table .ant-table-row-expand-icon::before,
.permission-table .ant-table-row-expand-icon::after 
{
    content: '' !important;
    border: none !important;
    background: none; /* ปิด background */
    transform: none !important;
    transition: none !important;
    top: auto !important
}
.permission-table .ant-table-row-expand-icon:not(.ant-table-row-expand-icon-expanded)::before {
    content: '>' !important;
}

.permission-table .ant-table-row-expand-icon.ant-table-row-expand-icon-expanded::before {
    content: 'v' !important;
}
</style>