<template>
    <div>
        <a-row>
            <a-col>
                <span style="font-weight: bold;">{{ divider }}</span>
            </a-col>
            <a-col style="margin-left: auto;">
                <a-button :disabled="target_object[target_key] && edit_sheet || status_disable" @click="show_drawer = true, drawer_create_data = []" type="primary" size="large" style="margin: 4px">
                    <span class="material-symbols-outlined" style="font-size: 16px; margin-right: 4px">Add</span>
                    {{ drawer_button_text }}
                </a-button>
            </a-col>
        </a-row>
        <a-table :columns="computedColumns" :data-source="target_object[target_key]" :pagination="false"
        :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.key === 'radio'">
                    <a-radio :value="index" @change="handleChangeRadio(index)" :checked="target_object[target_key][index].selected_index === 1" />
                </template>
                <template v-if="column.key === 'index'">
                    {{ oil_range_choose ? oil_range_choose + 1 :index + 1 }}
                </template>
                <template v-if="column.key === 'part_id'">
                    {{ show_name_from_id(this.selectables['Part'], record.part_id) }}
                </template>
                <template v-if="column.key === 'price_amount'">
                    {{ changeToDecimal(record.price_amount) }}
                </template>
                <template v-if="column.key === 'car_price_amount'">
                    {{ changeToDecimal(record.car_price_amount) }}
                </template>
                <template v-if="column.key === 'distance'">
                    {{ record.distance_1 }} - {{ record.distance_2 }}
                </template>
                <template v-if="column.key === 'car_type_id'">
                    {{ drawer_fields.car_type_id.options.find((data)=>data.value == record.car_type_id)?.label }}
                </template>
                <template v-if="column.key === 'file_name'">
                    <a @click="downloadFile(record.file, record.file_name)" @click.stop>
                        <span class="download-icon">
                            {{ record?.file_name }}
                        </span>
                    </a>
                </template>
                <template v-if="column.key === 'id'">
                    <a @click="go_edit(record, index)">
                        <span style="font-size: 18px" class="material-symbols-outlined">edit</span>
                    </a>

                    <a @click="go_remove(record.id, target_modelname, index)">
                        <span style="font-size: 18px" class="material-symbols-outlined">delete</span>
                    </a>
                </template>
            </template>
        </a-table>
        <a-drawer v-model:visible="show_drawer" @afterVisibleChange="onDrawerOpen" :title="drawer_title" :closable="true" :footer="null">
            <a-divider
                v-if="drawer_title == 'ช่วงน้ำมัน'"
                orientation="left"
                >ช่วงน้ำมัน
            </a-divider>
            <a-form :model="drawer_create_data" name="form" autocomplete="off" layout="vertical"
                @finish="drawer_create">
                <a-row>
                    <a-col :span="col_span"
                        v-for="({ placeholder, required, input_type, options, label_key, modelname, accept ,col_span}, key_name) in drawer_fields">
                        <a-form-item :label="placeholder" :name="key_name"
                            :rules="required ? [{ required, message: `โปรดระบุ ${placeholder}` }] : null">
                            <div v-if="input_type == 'select'">
                                <a-select v-model:value="drawer_create_data[key_name]"
                                    @change="(value) => handleOtherChange(value, key_name)" show-search
                                    :placeholder="placeholder">
                                    <a-select-option v-for="option in options" :value="option.value">
                                        {{ option.label }}
                                    </a-select-option>
                                </a-select>
                                <a-modal v-model:visible="showOtherModal" :title="`ระบุ${placeholder}อื่นๆ`"
                                    @ok="handleModalOk" @cancel="handleModalCancel">
                                    <a-input v-model:value="otherInput" :placeholder="`ระบุ${placeholder}อื่นๆ`" />
                                </a-modal>
                            </div>
                            <a-checkbox-group v-else-if="input_type === 'checkbox_result'"
                                v-model="drawer_create_data[key_name]" :placeholder="placeholder">
                                <a-checkbox value="ผ่าน">ผ่าน</a-checkbox>
                                <a-checkbox value="ไม่ผ่าน">ไม่ผ่าน</a-checkbox>
                            </a-checkbox-group>
                            <div v-else-if="input_type == 'file'"
                                style="display: flex; flex-direction: column; align-items: flex-start; gap:1rem">
                                <div>{{ label }}</div>
                                <a v-if="drawer_create_data[key_name]" :href="drawer_create_data[key_name]"
                                    target="_blank" rel="doc">
                                    {{ drawer_create_data[`${key_name}_name`] }}
                                </a>
                                <a-upload v-else v-model:file-list="fileList" method="GET" :show-upload-list="false"
                                    :accept="accept ? accept : '*/*'" @change="upload_files($event, key_name)">
                                    <div style="display: flex; flex-direction: column; align-items: center;">
                                        <p class="ant-upload-drag-icon">
                                            <span class="material-symbols-outlined"
                                                style="font-size: 5rem;">upload_file</span>
                                        </p>
                                        อัพโหลดไฟล์
                                    </div>
                                </a-upload>
                                <a-button danger type="text" @click="(drawer_create_data[key_name] = '')"
                                    style="padding: 0; height: 18px;" v-if="drawer_create_data[key_name]">
                                    <span class="material-symbols-outlined">close</span>
                                </a-button>
                            </div>
                            <div v-else-if="key_name == 'distance_1'">
                                <a-row :gutter="0">
                                    <a-col :span="19">
                                        <a-input
                                            v-model:value="drawer_create_data[key_name]"
                                            :placeholder="placeholder"
                                            :type="input_type"
                                            style="width: 100%;"
                                        />
                                    </a-col>
                                    <a-col :span="5" style="text-align: center;">
                                        <span> - </span>
                                    </a-col>
                                </a-row>
                            </div>
                            <div v-else-if="key_name == 'start_place'">
                                    <vue-google-autocomplete
                                        :ref="key_name + selectedIndex"
                                        :id="key_name + selectedIndex"
                                        classname="form-control"
                                        :placeholder="placeholder"
                                        v-on:input="
                                            drawer_create_data[key_name] = $event.target.value
                                        "
                                        v-on:placechanged="
                                        select_stop_name($event, key_name)
                                        "
                                        types="establishment"
                                        country="th"
                                    >
                                    </vue-google-autocomplete>
                            </div>
                            <a-input v-else-if="input_type != 'none'" v-model:value="drawer_create_data[key_name]"
                                :placeholder="placeholder" :type="input_type" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row justify="end">
                    <a-col>
                        <a-button v-if="drawer_button_text" html-type="submit" type="primary" size="large"
                            style="margin: 4px;text-align: right">
                            <span class="material-symbols-outlined"
                                style="font-size: 16px;margin-right: 4px">Save</span>
                            บันทึกข้อมูล
                        </a-button>
                        <a-button v-else="drawer_button_text_edit" html-type="submit" type="primary" size="large"
                            style="margin: 4px;text-align: right">
                            <span class="material-symbols-outlined" style="font-size: 16px;margin-right: 4px">Add</span>
                            บันทึกข้อมูล
                        </a-button>
                    </a-col>
                </a-row>
            </a-form>
        </a-drawer>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import SystemService from '../api/SystemService'
import DriverInfo from '../api/DriverInfo';
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { nextTick } from 'vue';


export default {
    props: {
        divider: String,
        table_columns: Array,
        target_object: Object,
        target_key: String,
        target_modelname: String,
        drawer_title: String,
        drawer_fields: Object,
        data: Array,
        drawer_button_text: String,
        drawer_button_text_edit: String,
        oil_range_choose: Number,
        edit_data: String,
        edit_sheet: Boolean,
        status_disable: Boolean,
    },
    name: 'table_create_drawer_project',
    data() {
        return {
            show_drawer: false,
            on_edit: false,
            drawer_create_data: {},
            selectables: {},
            selectedIndex: null,
            fileList: [],
            currentFieldKey: null,
            showOtherModal: false,
            otherInput: '',
            distanceRate: [],
            originalDataDrawer: {},
        }
    },
    computed: {
        computedColumns() {
            let baseColumns = [...this.table_columns];

            if (this.drawer_title === 'ช่วงน้ำมัน' && !this.edit_sheet) {
                baseColumns.unshift({
                title: '',
                dataIndex: 'radio',
                key: 'radio',
                align: 'center',
                width: 50,
                });
            }
            if (this.edit_sheet) {
                baseColumns.pop();
            }

            return baseColumns;
        },
    },
    components: {
        VueGoogleAutocomplete,
    },
    watch: {
        show_drawer(newVal) {
            if (newVal) {
                // เมื่อเปิด drawer เก็บค่าเดิมของ start_place
                this.originalDataDrawer = this.drawer_create_data.start_place;
            } else {
                // ถ้าปิด drawer และยังไม่ได้กดบันทึก ให้คืนค่าเดิม
                if (!this.on_edit) {
                    this.drawer_create_data.start_place = this.originalDataDrawer;
                }
            }
        }
    },
    methods: {
        changeToDecimal(amount) {
            if (typeof amount === "string" || typeof amount === "number") {
                return parseFloat(amount).toLocaleString('en-US');
            }
            return amount;
        },
        onDrawerOpen() {
            this.$nextTick(() => {
                const startPlaceInput = document.getElementById('start_place' + this.selectedIndex);
                if (startPlaceInput && this.drawer_create_data.start_place != null) {
                    startPlaceInput.value = this.drawer_create_data.start_place;
                }else if(startPlaceInput != null){
                    startPlaceInput.value = '';
                    this.on_edit = false;
                }
            });
        },
        async go_edit(record, index) {
            this.drawer_create_data =  { ...record };
            this.on_edit = true
            this.selectedIndex = index

            this.show_drawer = true;
        },
        async go_remove(id, type, index) {
            if (id) {
                Swal.fire({
                    title: `คุณต้องการลบข้อมูลนี้ใช่หรือไม่?`,
                    text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้หากลบแล้ว!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
                    cancelButtonText: 'ยกเลิก'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await SystemService.delete_all(type, id)
                        this.target_object[this.target_key].splice(index, 1)
                        Swal.fire(
                            'ลบข้อมูลเรียบร้อย!',
                            'ข้อมูลของคุณถูกลบแล้ว',
                            'success'
                        )
                    }
                })
            } else {
                Swal.fire({
                    title: `คุณต้องการลบข้อมูลนี้ใช่หรือไม่?`,
                    text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้หากลบแล้ว!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
                    cancelButtonText: 'ยกเลิก'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.target_object[this.target_key].splice(index, 1)
                        Swal.fire(
                            'ลบข้อมูลเรียบร้อย!',
                            'ข้อมูลของคุณถูกลบแล้ว',
                            'success'
                        )
                    }
                })
            }
        },
        handleChangeRadio(index){
            const oldIndex = this.target_object[this.target_key].findIndex((idx)=>idx.selected_index == 1);
            if(oldIndex != -1){
                Swal.fire({
                    title: `ยืนยันการเปลี่ยนช่วงน้ำมัน?`,
                    text: "คุณต้องการเปลี่ยนช่วงน้ำมันใช่หรือไม่!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ยืนยัน',
                    cancelButtonText: 'ยกเลิก'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        var applyDiff;
                        const diffValue = this.edit_data.oil_range_id;
                        const steps = index - oldIndex;
                        if(steps < 0){
                            applyDiff = (value, diffValue, steps) => Math.round(value / Math.pow((1 + diffValue / 100), steps*-1));
                        }else{
                            applyDiff = (value, diffValue, steps) => Math.round(value * Math.pow((1 + diffValue / 100), steps));
                        }
                        if(index > oldIndex || index < oldIndex){
                            if(this.target_object['priceRate']){
                                this.target_object['priceRate'] = this.target_object['priceRate'].map((item)=>{
                                    return{
                                        ...item,
                                        selling_per_trip : applyDiff(item.selling_per_trip, diffValue, steps),
                                        car_price_amount : applyDiff(item.car_price_amount, diffValue, steps)
                                    }
                                });
                            }
                            if(this.target_object['distanceRate']){
                                this.target_object['distanceRate'] = this.target_object['distanceRate'].map((item)=>{
                                    return{
                                        ...item,
                                        price_amount : applyDiff(item.price_amount, diffValue, steps),
                                        car_price_amount : applyDiff(item.car_price_amount, diffValue, steps)
                                    }
                                });
                            }
                        }
                        this.target_object[this.target_key].forEach((item, i) => {
                            if (i === index) {
                                item.selected_index = 1;
                            } else {
                                item.selected_index = null;
                            }
                        });
                        Swal.fire(
                            'เปลี่ยนช่วงน้ำมันเรียบร้อย!',
                            'ช่วงน้ำมันของคุณถูกเปลี่ยนแล้ว',
                            'success'
                        )
                    }
                })
            }else{
                this.target_object[this.target_key].forEach((item, i) => {
                    if (i === index) {
                        item.selected_index = 1;
                    } else {
                        item.selected_index = null;
                    }
                });
            }
        },
        async drawer_create(data) {
            const { id } = this.drawer_create_data;
            if (!this.on_edit) {
                console.log('111')
                if (!Array.isArray(this.target_object[this.target_key])) {
                    this.target_object[this.target_key] = [];
                }
                const filteredList = this.target_object[this.target_key].filter((_, index) => index !== this.selectedIndex);
                const isDuplicate = filteredList.some(item => item.start_place === data.start_place && item.distance_1 == data.distance_1  && item.distance_2 == data.distance_2 && item.car_type_id == data.car_type_id);
                if (isDuplicate && this.drawer_title!='ช่วงน้ำมัน') {
                    Swal.fire(
                        'ข้อมูลซ้ำ!',
                        'ข้อมูล ต้นทาง, ช่วงระยะทาง และ ชนิดรถนี้มีอยู่แล้ว',
                        'error'
                    );
                    return;
                }
                this.target_object[this.target_key].some(item => {
                    const project = this.distanceRate.find(project => project.project_id == item.project_id);
                    data.project_id = item.project_id;
                });
                // const res = await SystemService.create_all(this.target_modelname, { data: [data] });
                // console.log('bb',res)
                    Swal.fire(
                        'แก้ไขข้อมูลเรียบร้อย!',
                        'ข้อมูลของคุณถูกแก้ไขแล้ว',
                        'success'
                    ).then(() => {
                        //window.location.reload();
                    }
                );
            }

            if (this.on_edit && this.selectedIndex != null) {
                console.log('222')
                if (!Array.isArray(this.target_object[this.target_key])) {
                    this.target_object[this.target_key] = [];
                }
                const filteredList = this.target_object[this.target_key].filter((_, index) => index !== this.selectedIndex);

                const isDuplicate = filteredList.some(item => item.start_place === data.start_place&& item.distance_1 == data.distance_1  && item.distance_2 == data.distance_2 && item.car_type_id == data.car_type_id);
                if (isDuplicate && this.drawer_title!='ช่วงน้ำมัน') {
                    Swal.fire(
                        'ข้อมูลซ้ำ!',
                        'ข้อมูล ต้นทาง, ช่วงระยะทาง และ ชนิดรถนี้มีอยู่แล้ว',
                        'error'
                    );
                    return;
                }
                this.target_object[this.target_key].some(item => {
                    const project = this.distanceRate.find(project => project.project_id == item.project_id);
                    // console.log('item:', item.project_id);
                    // console.log('project:', project.project_id);
                    data.project_id = item.project_id;
                });
                // if (!id) {
                //     console.log('no id')
                //     const res = await SystemService.create_all(this.target_modelname, { data: [data] });
                //     console.log('bb',res)
                //         Swal.fire(
                //             'แก้ไขข้อมูลเรียบร้อย!',
                //             'ข้อมูลของคุณถูกแก้ไขแล้ว',
                //             'success'
                //         ).then(() => {
                //             window.location.reload();
                //         }
                //     );
                // }
                console.log('222')
                // const { id } = this.drawer_create_data;
                if (id) {
                    console.log('333')
                    if (Array.isArray(this.target_object[this.target_key])) {
                        const filteredList = this.target_object[this.target_key].filter((_, index) => index !== this.selectedIndex);
                        const isDuplicateFirst = filteredList.some(item => item.start_place === data.start_place && item.distance_1 == data.distance_1  && item.distance_2 == data.distance_2 && item.car_type_id == data.car_type_id);
                        const isDuplicateSecond = filteredList.some(item => {
                            const distanceRateItem = this.distanceRate.find(project => project.id == item.id);
                            return distanceRateItem && distanceRateItem.start_place == data.start_place;
                        });
                        if ((isDuplicateFirst || isDuplicateSecond) && this.drawer_title!='ช่วงน้ำมัน') {
                            Swal.fire(
                                'ข้อมูลซ้ำ!',
                                'ข้อมูล ต้นทาง, ช่วงระยะทาง และ ชนิดรถนี้มีอยู่แล้ว',
                                'error'
                            );
                            return;
                        }
                    } else {
                        this.target_object[this.target_key] = [];
                    }
                    await SystemService.update_all(this.target_modelname, { data: data }, id);
                    Swal.fire(
                        'แก้ไขข้อมูลเรียบร้อย!',
                        'ข้อมูลของคุณถูกแก้ไขแล้ว',
                        'success'
                    ).then(() => {
                        window.location.reload();
                    });
                } else {
                    this.target_object[this.target_key][this.selectedIndex] = data;
                }
            } else {
                this.target_object[this.target_key] ? this.target_object[this.target_key].push(data) :
                    this.target_object[this.target_key] = [data];
            }
            this.drawer_create_data = {};
            this.show_drawer = false;
            this.on_edit = false;
            this.selectedIndex = null;
        },
        async get_default_fields() {
            // console.log("this.drawer_fields", this.drawer_fields)
            for (var key in this.drawer_fields) {
                const { input_type, modelname } = this.drawer_fields[key]
                if (input_type == 'select') {
                    this.selectables[modelname] = await SystemService.get_all(modelname)
                }
            }
            this.distanceRate = await SystemService.get_all('DistanceRate')
            // console.log('888', this.distanceRate)
        },
        show_name_from_id(array, id) {
            return array.find(el => el.id == id)?.name
        },
        async select_stop_name(e, target, is_car) {
            const { latitude, longitude } = e;
            if (target && !is_car) {
                this.drawer_create_data[target] = document.getElementById(target+this.selectedIndex).value.split(",")[0];
            } else if (is_car) {
                this.drawer_create_data[target]  = document
                .getElementById(target)
                .value.split(",")[0];
            } else {
                this.add_stop.lat = latitude;
                this.add_stop.lng = longitude;
                this.add_stop.name = document.getElementById("map").value.split(",")[0];
            }
        },
        upload_files(e, key) {
            try {
                if (e.file.status != 'remove') {
                    this.getBase64(e.file.originFileObj, key)
                    if (e.file.status == 'uploading' && !this.isLoading) {
                        this.$message.loading('กำลังอัปโหลดไฟล์...');
                        this.isLoading = true;
                    }
                    if (e.file.status == 'error') {
                        this.$message.success('อัปโหลดไฟล์สำเร็จ')
                        this.isLoading = false;
                    }
                    return true
                }else{
                    const vue = this
                    vue.drawer_create_data[`${key}_name`] = null
                }
            } catch (error) {
                console.log(error)
            }
        },
        async getBase64(file, key) {
            if (key) {
                const vue = this
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    vue.drawer_create_data[key] = reader.result
                    vue.drawer_create_data[`${key}_name`] = file.name
                };
            } else {
                return await new Promise((resolve, reject) => {
                    if (file) {
                        if (typeof file == 'string') {
                            resolve(file)
                        } else {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = error => reject(error);
                        }
                    }
                });
            }
        },
        async getBase64ImageFromURL(url) {
            const res = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            });
            const blob = await res.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        },
        downloadFile(url, fileName) {
            fetch(url)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.blob();
                })
                .then(blob => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName || 'default_filename.pdf'; // Set default filename if fileName is not valid
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(link.href);
                })
                .catch(error => {
                    console.error('Error downloading file:', error);
                });
        },
        handleOtherChange(value, key) {
            if (value === 'OTHER') {
                this.currentFieldKey = key;
                this.showOtherModal = true;
            }
        },
        handleModalOk() {
            this.drawer_create_data[this.currentFieldKey] = this.otherInput;
            this.showOtherModal = false;
            this.otherInput = '';
        },
        handleModalCancel() {
            this.showOtherModal = false;
            this.otherInput = '';
        },
    },

    async mounted() {
        await this.get_default_fields()
    }
}

</script>

<style scoped>
.ant-row {
    margin: 0
}
</style>