import httpClient from './httpClient';

const prefix = 'core'

export default {
    async getExpenseCategoryAll () {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getExpenseCategory`)
        return data
    },
    async reportCompanyExpense(payment_status, status_approve,time_start, time_end, selectedRowKeys) {
        const params = {
          timeStart: time_start,
          timeEnd: time_end,
          payment_status: payment_status || "All",
          status_approve: status_approve || "All",
          selectedRowKeys,
        }
        const queryString = new URLSearchParams(params).toString();
            const { data } = await httpClient({ requiresAuth: true }).get(`/${prefix}/reportCompanyExpense?${queryString}`);
          
            return data;
    },
    async getCompanyExpensesByCompany () {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getCompanyExpensesByCompany`)
        return data
    },
    async getAdministrativeAndAccoutingAll () {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getAdministrativeAndAccoutingAll`)
        return data
    },
    async getCompanyExpensesById(id) {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getCompanyExpensesById/${id}`)
        return data
    },
    async deleteCompanyExpenseById(id) {
        const { data } = await httpClient({ requiresAuth: true }).delete(`${prefix}/deleteCompanyExpenseById/${id}`)
        return data
    },
    async createCompanyExpenses(datas) {
        const { data } = await httpClient({ requiresAuth: true }).post(`${prefix}/createCompanyExpenses`, datas);
        return data
    },
    async updateCompanyExpenses(id,datas) {
        const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/updateCompanyExpenses/${id}`, datas);
        return data
    },
}